const parseColumnIdentifier = (columnId: string) => columnId.replace(/[_.\\]/g, '-');

export const computeTableHeaderCellCSSVariableName = (headerId: string) => `--header-${parseColumnIdentifier(headerId)}-size`;
export const computeTableBodyCellCSSVariableName = (columnId: string) => `--col-${parseColumnIdentifier(columnId)}-size`;

// this logic is needed because react-table has a bug when sorting on a columnId with certain values such as 'value'
// in order to avoid having this issue, we can compute our own key for columns that have dynamic ids
const REACT_TABLE_KEY = 'react-table';
export const computeTableColumnIdFromColumnName = (columnName: string) => `${columnName}${REACT_TABLE_KEY}`;
export const extractColumnNameFromTableColumnId = (columnId: string) => columnId.split(REACT_TABLE_KEY)[0];
