import { Tooltip, Typography } from 'antd';
import { Pie, PieChart } from 'recharts';
import styles from './PiePercent.module.css';
import { formatNumber } from '../../../../../../utils/numeral';
import { InfoCircleOutlined } from '@ant-design/icons';

const PIE_WIDTH = 60;
const PIE_HEIGHT = 60;
const PIE_LINE_THICKNESS = 3;
const PADDING_ANGLE = 50;

const ACCURACY_START_ANGLE = 270 - PADDING_ANGLE / 2;
const CIRCLE_FULL_LENGTH = 360 - PADDING_ANGLE;

export default function PiePercent({
  title,
  percent,
  info,
  infoCircle,
  dataTestId
}: {
  title: string;
  percent: number;
  info: string;
  infoCircle: string;
  dataTestId: string;
}) {
  const endAngle = ACCURACY_START_ANGLE - (CIRCLE_FULL_LENGTH * percent) / 100;
  const rotationPercent = Math.min(-110 + percent, -40); // used for rotating the linear-gradient

  return (
    <div className={styles.piePercentContainer}>
      <div>
        <Typography.Text className={styles.title}>{title}</Typography.Text>
        <Tooltip title={infoCircle}>
          <InfoCircleOutlined className="color-nb-300 m-l-4" />
        </Tooltip>

        <Tooltip placement="bottom" title={info}>
          {percent == null ? (
            <div className={styles.percentContainer}>
              <Typography.Text className={styles.percent}>-</Typography.Text>
            </div>
          ) : (
            <div className={styles.percentContainer}>
              <Typography.Text className={styles.percent} data-tst-id={dataTestId}>
                {formatNumber(percent, 2)}
              </Typography.Text>
              <Typography.Text className={styles.percentSign}>%</Typography.Text>
            </div>
          )}
        </Tooltip>
      </div>
      <Tooltip placement="bottom" title={info}>
        <PieChart width={PIE_WIDTH} height={PIE_HEIGHT}>
          <defs>
            <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0" gradientTransform={`rotate(${rotationPercent})`}>
              <stop offset="35%" stopColor="#0AED5A" />
              <stop offset="50%" stopColor="#FFC727" />
              <stop offset="75%" stopColor="#ED0A2C" />
            </linearGradient>
          </defs>
          <Pie
            data={[{ value: 1 }]}
            innerRadius={PIE_HEIGHT / 2 - PIE_LINE_THICKNESS}
            outerRadius={PIE_HEIGHT / 2}
            fill="var(--secondary-color-nb-700)"
            paddingAngle={PADDING_ANGLE}
            dataKey="value"
            startAngle={-90 + PADDING_ANGLE / 2}
            endAngle={270 + PADDING_ANGLE / 2}
            stroke="none"
            cornerRadius={0}
          ></Pie>
          <Pie
            data={[{ value: 1 }]}
            innerRadius={PIE_HEIGHT / 2 - PIE_LINE_THICKNESS}
            outerRadius={PIE_HEIGHT / 2}
            fill="url(#colorUv)"
            paddingAngle={PADDING_ANGLE}
            dataKey="value"
            startAngle={270 - PADDING_ANGLE / 2}
            endAngle={endAngle}
            stroke="none"
            cornerRadius={0}
          ></Pie>
        </PieChart>
      </Tooltip>
    </div>
  );
}
