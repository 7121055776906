import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { TextBox } from '../Visualisation/TextBox';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useVisualizationsContext } from './Visualizations.context';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';

export function QueryTextBox(props: VisualizationRecord) {
  const visualizationsContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualizationsContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualizationsContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }
    setLoading(false);
    visualizationsContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div>
          <div className="usecase-card-title">{props.name}</div>
          <div>
            {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
            <TextBox key={props.name + Math.random()} body={props.style.body} style={props.style} />
          </div>
        </div>
      </Spin>
    </>
  );
}
