import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { config } from '../../../config';

export function useJobNavigate() {
  const history = useHistory();

  const navigateToJobs = useCallback(() => {
    history.push(config.routes.jobsAll);
    // eslint-disable-next-line
  }, []);

  // This builds from the current state variables the URLSearchParams
  // For the moment we have the search term
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  const buildSearchParams = useCallback((categoryId: string, subcategoryId: string, searchTerm: string = null) => {
    const urlParams = new URLSearchParams();
    if (categoryId != null && categoryId.length > 0) {
      urlParams.append('category', categoryId);
    }
    if (subcategoryId != null && subcategoryId.length > 0) {
      urlParams.append('subcategory', subcategoryId);
    }
    if (searchTerm != null && searchTerm.length > 0) {
      urlParams.append('search', searchTerm);
    }
    let urlParamsStr = urlParams.toString();
    if (urlParamsStr !== '') {
      urlParamsStr = `?${urlParamsStr}`;
    }
    return urlParamsStr;
  }, []);

  const navigateToJobsByCategoryAndSearch = useCallback(
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    (categoryId: string = null, subcategoryId: string = null, searchTerm: string = null) => {
      let jobsPath = config.routes.jobsAll;
      jobsPath = jobsPath.concat(buildSearchParams(categoryId, subcategoryId, searchTerm));
      history.push(jobsPath);
    },
    [history, buildSearchParams]
  );

  const navigateToJobOverview = useCallback(
    (jobId: string) => {
      history.push({
        pathname: config.routes.jobOverView.replace(':id', jobId),
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | { ...
        search: null
      });
      window.scrollTo(0, 0);
    },
    [history]
  );

  const navigateToJobFeatureSelection = useCallback(
    (jobId: string, fromPageName?: string) => {
      history.push({
        pathname: config.routes.featureSelectionJob.replace(':id', jobId),
        state: { fromPageName: fromPageName }
      });
    },
    [history]
  );

  const navigateToJobRetrain = useCallback(
    (jobId: string) => {
      history.push({
        pathname: config.routes.retrainJob.replace(':id', jobId),
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | { ...
        search: null
      });
    },
    [history]
  );

  const navigateToFeedbackColumnSelection = useCallback(
    (jobId: string) => {
      history.push({
        pathname: config.routes.columnsSelection.replace(':id', jobId)
      });
    },
    [history]
  );

  const navigateToEventExplorerColumnSelection = useCallback(
    (jobId: string) => {
      history.push({
        pathname: config.routes.eventExplorerColumnsSelection.replace(':id', jobId)
      });
    },
    [history]
  );

  const navigateToJobEventExplorer = useCallback(
    (jobId: string) => {
      history.push({
        pathname: config.routes.eventExplorer.replace(':id', jobId)
      });
      window.scrollTo(0, 0);
    },
    [history]
  );

  const navigateToJobFeedbackFlow = useCallback(
    (jobId: string) => {
      history.push({
        pathname: config.routes.feedbackFlow.replace(':id', jobId)
      });
      window.scrollTo(0, 0);
    },
    [history]
  );

  const navigateToJobFeedbackUnified = useCallback(
    (jobIds: (string | number)[]) => {
      history.push({
        pathname: config.routes.feedbackUnified.replace(':ids', jobIds.toString())
      });
      window.scrollTo(0, 0);
    },
    [history]
  );

  const navigateToJobDecisionPointsUnified = useCallback(
    (jobIds: string[]) => {
      history.push({
        pathname: config.routes.decisionPointsUnified.replace(':ids', jobIds.toString())
      });
      window.scrollTo(0, 0);
    },
    [history]
  );

  const goBackFromFeatureSelection = useCallback(() => {
    history.goBack();
  }, [history]);

  const navigateToCreateJob = () => {
    history.push(config.routes.jobCreate);
  };

  const navigateToNotFound = useCallback(() => {
    history.push(config.routes.page404);
  }, [history]);

  const navigateToEditJob = useCallback(
    (jobId: string) => {
      history.push(config.routes.editJob.replace(':id', jobId));
    },
    [history]
  );

  const navigateToJobClusters = useCallback(
    (jobId: string) => {
      history.push(config.routes.jobClusters.replace(':id', jobId));
    },
    [history]
  );

  return {
    goBackFromFeatureSelection,
    navigateToJobsByCategoryAndSearch,
    navigateToJobOverview,
    navigateToJobFeatureSelection,
    navigateToJobRetrain,
    navigateToFeedbackColumnSelection,
    navigateToEventExplorerColumnSelection,
    navigateToJobFeedbackFlow,
    navigateToJobFeedbackUnified,
    navigateToJobDecisionPointsUnified,
    navigateToJobEventExplorer,
    navigateToCreateJob,
    navigateToNotFound,
    navigateToEditJob,
    navigateToJobClusters,
    navigateToJobs
  };
}
