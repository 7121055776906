import { TableBody as MuiTableBody, useTheme } from '@mui/material';
import { Row, Table } from '@tanstack/react-table';
import { memo, useState } from 'react';
import { useDndMonitor } from '@dnd-kit/core';
import RowComponent from './Row';

type TTableBodyProps<T> = {
  tableInstance: Table<T>;
  onRowClick?: (row: Row<T>, index: number) => void;
  isLoading?: boolean;
  emphasizedRows?: number[];
  isActionsColumnVisible: boolean;
  tableSize: string;
  actionsColumnSize: number;
  isStrippedTable: boolean;
  getIsRowEmphasized?: (row: Row<T>) => boolean;
  isMemoized: boolean;
  customRowSizeValue: number;
};

function TableBody<T>({
  isActionsColumnVisible,
  tableInstance,
  onRowClick,
  isLoading,
  emphasizedRows = [],
  tableSize,
  actionsColumnSize,
  isStrippedTable,
  getIsRowEmphasized,
  customRowSizeValue
}: TTableBodyProps<T>) {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const tableRows = tableInstance.getRowModel().rows;

  const theme = useTheme();

  useDndMonitor({
    onDragStart: () => setIsDragging(true),
    onDragEnd: () => setIsDragging(false)
  });

  return (
    <MuiTableBody
      sx={{
        background: theme.palette.secondary[900],
        opacity: isDragging ? 0.4 : 1,
        position: 'relative',
        height: isLoading ? '140px' : 0
      }}
    >
      {!isLoading &&
        tableRows.map((row, index) => (
          <RowComponent<T>
            key={index}
            actionsColumnSize={actionsColumnSize}
            row={row}
            index={index}
            emphasizedRows={emphasizedRows}
            getIsRowEmphasized={getIsRowEmphasized}
            onRowClick={onRowClick}
            tableSize={tableSize}
            isRowSelectionEnabled={row.getCanSelect()}
            isActionsColumnVisible={isActionsColumnVisible}
            isStrippedTable={isStrippedTable}
            customRowSizeValue={customRowSizeValue}
          />
        ))}
    </MuiTableBody>
  );
}

export const MemoizedTableBody = memo(TableBody, (_, next) => next.isMemoized) as typeof TableBody;

export default TableBody;
