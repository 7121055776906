import { useTranslation } from 'react-i18next';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import IdeaBoxDialog from './components/IdeaBoxDialog';
import { useState } from 'react';
import { Icon, EIcon } from '@arcanna/generic';

function IdeaBox() {
  const { t } = useTranslation('idea-box');
  const { palette } = useTheme();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Box>
      <Tooltip title={t('tooltip')} placement="bottom" arrow>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          endIcon={<Icon name={EIcon.MessageAlertSquare} fontSize="small" />}
          onClick={() => setIsDialogOpen(true)}
        >
          <Typography color={palette.grey.A200} fontSize="14px" fontWeight={600} lineHeight="16px">
            {t('ideaBox')}
          </Typography>
        </Button>
      </Tooltip>
      <IdeaBoxDialog setIsDialogOpen={setIsDialogOpen} isDialogOpen={isDialogOpen} />
    </Box>
  );
}

export default IdeaBox;
