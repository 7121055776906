import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EventExplorerStructureColumnRecord')
export class EventExplorerStructureColumnRecord {
  @JsonProperty('id', String)
  id: string | undefined;

  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('caption', String)
  caption: string | undefined;

  @JsonProperty('type', String)
  type: string | undefined;

  @JsonProperty('searchable', Boolean)
  searchable?: boolean | undefined;

  @JsonProperty('filterable', Boolean)
  filterable?: boolean | undefined;

  @JsonProperty('filter_operators', Array<string>())
  filter_operators?: Array<string> | undefined;
}
