import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('FeedbackStatisticsBucketsCountResponse')
export class FeedbackStatisticsBucketsCountResponse extends CommonRequest {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('buckets', Number)
  buckets?: number;

  @JsonProperty('events', Number)
  events?: number;
}
