import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobMetricsRequest')
export class JobMetricsRequest extends CommonRequest {
  @JsonProperty('model_id', String)
  model_id?: string;

  constructor(model_id: string) {
    super();
    this.model_id = model_id;
  }
}
