export function SettingsIcon({ color }: { color?: string }) {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line
        d="M6.68659 3.28846L7.24859 1.83692C7.34337 1.59116 7.51013 1.37977 7.72701 1.23043C7.9439 1.0811 8.20081 1.00078 8.46409 1H9.53581C9.79909 1.00078 10.056 1.0811 10.2729 1.23043C10.4898 1.37977 10.6565 1.59116 10.7513 1.83692L11.3133 3.28846L13.2215 4.38692L14.7638 4.15154C15.0206 4.11666 15.2819 4.15896 15.5146 4.27305C15.7474 4.38715 15.9409 4.56789 16.0707 4.79231L16.5935 5.70769C16.7275 5.93569 16.7892 6.19897 16.7705 6.46278C16.7519 6.7266 16.6537 6.97855 16.489 7.18538L15.5349 8.40154V10.5985L16.5151 11.8146C16.6798 12.0215 16.778 12.2734 16.7967 12.5372C16.8154 12.801 16.7536 13.0643 16.6197 13.2923L16.0969 14.2077C15.9671 14.4321 15.7735 14.6129 15.5408 14.7269C15.3081 14.841 15.0467 14.8833 14.7899 14.8485L13.2477 14.6131L11.3395 15.7115L10.7774 17.1631C10.6827 17.4088 10.5159 17.6202 10.299 17.7696C10.0821 17.9189 9.82523 17.9992 9.56195 18H8.46409C8.20081 17.9992 7.9439 17.9189 7.72701 17.7696C7.51013 17.6202 7.34337 17.4088 7.24859 17.1631L6.68659 15.7115L4.77839 14.6131L3.23615 14.8485C2.97935 14.8833 2.71798 14.841 2.48527 14.7269C2.25255 14.6129 2.05898 14.4321 1.92917 14.2077L1.40637 13.2923C1.27241 13.0643 1.21069 12.801 1.22935 12.5372C1.24802 12.2734 1.3462 12.0215 1.51093 11.8146L2.46503 10.5985V8.40154L1.48479 7.18538C1.32006 6.97855 1.22188 6.7266 1.20322 6.46278C1.18455 6.19897 1.24627 5.93569 1.38023 5.70769L1.90303 4.79231C2.03284 4.56789 2.22641 4.38715 2.45913 4.27305C2.69184 4.15896 2.95321 4.11666 3.21001 4.15154L4.75225 4.38692L6.68659 3.28846ZM6.38598 9.5C6.38598 10.0173 6.53929 10.5229 6.82652 10.953C7.11374 11.3831 7.52199 11.7183 7.99963 11.9163C8.47727 12.1143 9.00285 12.166 9.50991 12.0651C10.017 11.9642 10.4827 11.7151 10.8483 11.3494C11.2139 10.9836 11.4628 10.5176 11.5637 10.0102C11.6646 9.5029 11.6128 8.97703 11.4149 8.49914C11.2171 8.02124 10.8821 7.61277 10.4522 7.32539C10.0223 7.03801 9.51695 6.88462 8.99995 6.88462C8.30668 6.88462 7.64181 7.16016 7.1516 7.65064C6.66138 8.14112 6.38598 8.80636 6.38598 9.5Z"
        stroke={color ? color : 'var(--secondary-color-nb-300)'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
