import { useState, createContext, useContext } from 'react';

import { useUtils } from '../../hooks/useUtils';

export interface LegendTooltipProps {
  values: { [id: string]: string };
}

// eslint-disable-next-line
export const LegendTooltipContext = createContext<LegendTooltipContextType>(null as any);

export type LegendTooltipStateType = {
  topOffset: number;
  displayMode: string;
  value: string;
};

export type LegendTooltipContextType = {
  state: LegendTooltipStateType;
  setTopOffset: (topOffset: number) => void;
  setDisplayMode: (displayMode: string) => void;
  setValue: (value: string) => void;
};

// eslint-disable-next-line
export function LegendTooltipProvider(props: any) {
  const [state, setState] = useState<LegendTooltipStateType>({
    topOffset: 0,
    displayMode: 'none',
    value: ''
  });

  const setTopOffset = (topOffset: number) => {
    setState((current) => ({
      ...current,
      topOffset
    }));
  };

  const setDisplayMode = (displayMode: string) => {
    setState((current) => ({
      ...current,
      displayMode
    }));
  };

  const setValue = (value: string) => {
    setState((current) => ({
      ...current,
      value
    }));
  };

  const value: LegendTooltipContextType = {
    state,
    setTopOffset,
    setDisplayMode,
    setValue
  };

  return <LegendTooltipContext.Provider value={value}>{props.children}</LegendTooltipContext.Provider>;
}

export function useLegendTooltipContext(): LegendTooltipContextType {
  return useContext(LegendTooltipContext);
}

export function useLegendTooltipHook() {
  const legendContext = useLegendTooltipContext();

  // eslint-disable-next-line
  function handleMouseEnter(ev: any) {
    const { value } = ev;
    legendContext.setValue(value);
    legendContext.setDisplayMode('block');
    legendContext.setTopOffset(ev.coordinate + 80);
  }

  function handleMouseLeave() {
    legendContext.setDisplayMode('none');
  }

  return {
    handleMouseEnter,
    handleMouseLeave
  };
}

export function LegendTooltip(props: LegendTooltipProps) {
  const { state: legendState } = useLegendTooltipContext();
  const utils = useUtils();

  function getColorClassName(): string {
    return utils.colorClassNameForKey(props.values[legendState.value]);
  }

  if (props.values[legendState.value] != null) {
    return (
      <div
        style={{
          display: legendState.displayMode,
          position: 'absolute',
          zIndex: 99,
          left: 0,
          top: legendState.topOffset,
          width: 'auto',
          color: '#fff',
          padding: '10px'
        }}
        className={`${getColorClassName()} recharts-default-tooltip`}
      >
        {props.values[legendState.value]}
      </div>
    );
  }

  return <></>;
}
