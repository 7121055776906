import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { ReactComponent as Comments } from './icons/comments-alt.svg';
import { ReactComponent as Books } from './icons/books.svg';
import { ReactComponent as LifeRing } from './icons/life-ring.svg';
import { ReactComponent as CompressAlt } from './icons/compress-alt.svg';
import { ReactComponent as UnExpandAlt } from './icons/unexpand-alt.svg';
import { ReactComponent as Sample } from './icons/sample.svg';
import { ReactComponent as Pencil } from './icons/pencil.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as JsonFile } from './icons/jsonFile.svg';
import { ReactComponent as Warning } from './icons/warning.svg';
import { ReactComponent as Error } from './icons/error.svg';
import { ReactComponent as LogoLight } from './icons/logo-light-arcanna.svg';
import { ReactComponent as Change } from './icons/change.svg';
import { ReactComponent as LoaderSmall } from './icons/loader-small.svg';
import { ReactComponent as AngleDoubleRight } from './icons/angle-double-right.svg';
import { ReactComponent as CircleSmall } from './icons/circle-small.svg';
import { ReactComponent as Pause } from './icons/pauseIcon.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as EditClosed } from './icons/editIconClosed.svg';
import { ReactComponent as Hourglass } from './icons/hourglass.svg';
import { ReactComponent as Miscelaneous } from './icons/miscelaneous.svg';
import { ReactComponent as Unexpand } from './icons/unexpand.svg';
import { ReactComponent as Expand } from './icons/expand.svg';
import { ReactComponent as Copy } from './icons/copy.svg';
import { ReactComponent as ChevronDown } from './icons/ChevronDown.svg';
import { ReactComponent as Integration } from './icons/integration.svg';
import { ReactComponent as Folder } from './icons/folder.svg';
import { ReactComponent as Columns } from './icons/columns.svg';
import { ReactComponent as Brain } from './icons/brain.svg';
import { ReactComponent as Puzzle } from './icons/puzzle.svg';
import { ReactComponent as FolderPlus } from './icons/folderPlus.svg';
import { ReactComponent as Info } from './icons/infoIcon.svg';

export const CommentsIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={Comments} {...props} />;
export const BooksIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={Books} {...props} />;
export const LifeRingIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={LifeRing} {...props} />;
export const CompressAltIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={CompressAlt} {...props} />;
export const UnExpandAltIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={UnExpandAlt} {...props} />;
export const SampleIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={Sample} {...props} />;
export const PencilIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Pencil} {...props} style={{ color: 'transparent' }} />
);
export const UploadIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={Upload} {...props} />;
export const JsonFileIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={JsonFile} {...props} />;
export const WarningIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={Warning} {...props} />;
export const ErrorIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={Error} {...props} />;
export const LogoLightIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={LogoLight} {...props} />;
export const ChangeIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Change} {...props} style={{ color: 'transparent' }} />
);
export const LoaderSmallIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={LoaderSmall} {...props} />;
export const AngleDoubleRightIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AngleDoubleRight} {...props} />
);
export const CircleSmallIcon = (props: Partial<CustomIconComponentProps>) => <Icon component={CircleSmall} {...props} />;
export const PauseIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Pause} style={{ ...style, color: 'transparent' }} {...props} />
);
export const EditIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Edit} style={{ color: 'transparent', ...style }} {...props} />
);
export const EditIconClosed = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={EditClosed} {...props} style={{ color: 'transparent', ...style }} />
);
export const DeleteIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Delete} style={{ color: 'transparent', ...style }} {...props} />
);
export const HourglassIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Hourglass} style={{ color: 'transparent', ...style }} {...props} />
);
export const MiscelaneousIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Miscelaneous} style={{ color: 'transparent', ...style }} {...props} />
);
export const UnexpandIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Unexpand} style={{ color: 'transparent', width: '20px', height: '20px', ...style }} {...props} />
);
export const ExpandIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Expand} style={{ color: 'transparent', width: '20px', height: '20px', ...style }} {...props} />
);
export const CopyIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Copy} style={{ color: 'transparent', ...style }} {...props} />
);
export const ChevronDownIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={ChevronDown} style={{ color: 'transparent', ...style }} {...props} />
);
export const ChevronUpIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ChevronDown} {...props} style={{ color: 'transparent', transform: 'rotate(180deg)' }} />
);

export const IntegrationIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Integration} style={{ ...style }} {...props} />
);

export const EmptyFolderIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={Folder} {...props} style={{ fontSize: '80px' }} />
);

export const ColumnsIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Columns} style={{ ...style }} {...props} />
);

export const BrainIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Brain} style={{ ...style }} {...props} />
);

export const PuzzleIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Puzzle} style={{ ...style }} {...props} />
);

export const EmptyFolderPlusIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={FolderPlus} {...props} style={{ fontSize: '80px' }} />
);

export const InfoIcon = ({ style, ...props }: Partial<CustomIconComponentProps>) => (
  <Icon component={Info} {...props} style={{ ...style }} />
);

export * from './icons/StartIcon';
export * from './icons/KeyIcon';
export * from './icons/SettingsIcon';
export * from './icons/LifeBuoyIcon';
export * from './icons/MessageChatIcon';
export * from './icons/ExternalIcon';
export * from './icons/FileIcon';
export * from './icons/FlashIcon';
export * from './icons/MessageAlertSquareIcon';
export * from './icons/ChipIcon';
export * from './icons/CloneIcon';
export * from './icons/DotDotDotVerticalIcon';
export * from './icons/ShrinkIcon';
export * from './icons/ExpandArrowsIcon';
export * from './icons/CalendarIcon';
export * from './icons/ForbiddenIcon';
