export enum EJobTrainingState {
  AUTOMATIC_RETRAIN = 'AUTOMATIC_RETRAIN',
  RETRAIN_IN_PROGRESS = 'RETRAIN_IN_PROGRESS',
  STARTED = 'STARTED',
  COLLECTING_FEEDBACK = 'COLLECTING_FEEDBACK',
  ERROR = 'ERROR',
  FINISHED = 'RETRAIN_FINISHED'
}

export const CAN_NOT_START_ANOTHER_RETRAIN = [
  EJobTrainingState.STARTED,
  EJobTrainingState.RETRAIN_IN_PROGRESS,
  EJobTrainingState.COLLECTING_FEEDBACK
];
