import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobConfigValidationRecord')
export class JobConfigValidationRecord {
  @JsonProperty('index', Number)
  index: number;

  @JsonProperty('step', String)
  step: string;

  @JsonProperty('validation_level', String)
  validation_level: string;

  @JsonProperty('validation_message', String)
  validation_message: string;
}
