import { Box, styled, BoxProps } from '@mui/material';

const StyledLabelCircle = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'size';
  }
})<BoxProps & { size: 'small' | 'medium' }>(({ size }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '4px',
  '.root': {
    width: size === 'small' ? '12px' : '16px',
    height: size === 'small' ? '12px' : '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px'
  },
  '.point': {
    width: size === 'small' ? '6px' : '8px',
    height: size === 'small' ? '6px' : '8px',
    display: 'flex',
    borderRadius: '6px'
  }
}));

export default StyledLabelCircle;
