import { useTranslation } from 'react-i18next';
import { EIcon, Icon, StateChip } from '@arcanna/generic';
import { Stack, Tooltip, useTheme } from '@mui/material';
import { formatDateTime } from 'src/components/shared/utilities/date';

type JobInvalidTagProps = {
  message?: string;
  invalidTimestamp?: Date;
};

export function JobInvalidTag({ message, invalidTimestamp }: JobInvalidTagProps) {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return (
    <>
      <StateChip
        color={theme.palette.error[600]}
        text={t('job:invalidState.tagText', { date: formatDateTime(invalidTimestamp) })}
      />
      <Tooltip title={message} placement="top" arrow>
        <Stack>
          <Icon htmlColor={theme.palette.error[700]} name={EIcon.AlertTriangle} />
        </Stack>
      </Tooltip>
    </>
  );
}
