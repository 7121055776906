import { useCallback, useMemo, useState } from 'react';
import { EStepStatus, TStep } from '../ModalSteps.types';

function useModalSteps(initialSteps: TStep[]) {
  const [steps, setSteps] = useState<TStep[]>(initialSteps);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const setStepStatus = useCallback(
    (status: EStepStatus, stepIndex: number) => {
      setSteps((prevSteps) => prevSteps.map((s, i) => (i === stepIndex ? { ...s, status } : s)));
    },
    [setSteps]
  );

  const setFollowingStepsStatus = useCallback(
    (status: EStepStatus, stepIndex: number) => {
      setSteps((prevSteps) => prevSteps.map((s, i) => (i >= stepIndex ? { ...s, status } : s)));
    },
    [setSteps]
  );

  return useMemo(
    () => ({
      steps,
      currentStep,
      setCurrentStep,
      setStepStatus,
      setFollowingStepsStatus
    }),
    [steps, currentStep, setCurrentStep, setStepStatus, setFollowingStepsStatus]
  );
}

export default useModalSteps;
