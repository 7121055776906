import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('FeedbackStatisticsKbIncreaseResponse')
export class FeedbackStatisticsKbIncreaseResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('percent', Number)
  percent?: number;
}
