import { useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { BarChartProps } from '../Visualisation/Barchart';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useVisualizationsContext } from './Visualizations.context';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';
import { BarchartStacked } from '../Visualisation/BarchartStacked';
import { useUtils } from '../../hooks/useUtils';
import { extractTooltips } from './QueryBarchart';

export function QueryBarChartStacked(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const [barData, setBarData] = useState<BarChartProps>(undefined);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const utils = useUtils();

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }
    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    let slices: Array<any> = [];
    // eslint-disable-next-line
    let allChildSlices: Array<any> = [];
    for (const key in aggregations) {
      slices = slices.concat(aggregations[key].buckets);
    }

    const keys = Array<string>();

    // eslint-disable-next-line
    const data = Array<any>();
    slices.forEach((slice) => {
      let childBuckets;
      for (const key in slice) {
        if (key !== 'key' && key !== 'doc_count') {
          childBuckets = slice[key].buckets;
          allChildSlices = allChildSlices.concat(childBuckets);
          break;
        }
      }

      // eslint-disable-next-line
      const element: any = { name: slice.key };
      for (let i = 0; i < childBuckets.length; i += 1) {
        const childKey = childBuckets[i].key;
        const value = childBuckets[i].doc_count;
        element[childKey] = value;
        if (!keys.includes(childKey)) {
          keys.push(childKey);
        }
      }

      data.push(element);
    });

    const tooltips = extractTooltips(allChildSlices);

    const colors = utils.nColorArray(keys.length, visualizationRecord.style['color-seed']);

    setBarData({
      data,
      keys,
      colors,
      style: props.style,
      filterFields: VisualizationRecord.getFilterFieldsFromQuery(visualizationRecord.query, []),
      tooltips
    });
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [setBarData, visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <p>&nbsp;</p>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        <BarchartStacked key={props.name + Math.random()} {...barData} />
      </Spin>
    </>
  );
}
