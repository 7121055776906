import { useState, useEffect } from 'react';
import { RadialBarChart, RadialBar, ResponsiveContainer } from 'recharts';
import { useMetricsContext } from '../../../../shared/components/QueryVisualisation/QueryMetric.context';

export function CirclePercentageBackground() {
  const { state: metricsState } = useMetricsContext();

  // eslint-disable-next-line
  const [data, setData] = useState<Array<any>>([]);

  useEffect(() => {
    if (metricsState.buckets.length > 0) {
      let percentString = `${metricsState.buckets[0].doc_count}`;
      percentString = percentString.replace('%', '');
      setData([
        // the first entry is just a refence for the second RadialBar
        // the second one is the one that represents the percent
        { name: 'full', value: 100, fill: '#141414' },
        { name: 'current', value: Number(percentString), fill: '#4BB4E6' }
      ]);
    }
  }, [metricsState.buckets]);

  if (data.length > 0) {
    return (
      <>
        <ResponsiveContainer width="99%" height="99.9%">
          <RadialBarChart startAngle={90} endAngle={450} innerRadius="50%" outerRadius="130%" data={data}>
            <RadialBar isAnimationActive={false} dataKey="value" />
          </RadialBarChart>
        </ResponsiveContainer>
      </>
    );
  }

  return <></>;
}
