export const customLabelColors = [
  '#FB7185',
  '#34D399',
  '#60A5FA',
  '#FBBF24',
  '#E879F9',
  '#2DD4BF',
  '#A78BFA',
  '#E2E8F0',
  '#E11D48',
  '#059669',
  '#2563EB',
  '#D97706',
  '#A21CAF',
  '#0D9488',
  '#7C3AED',
  '#94A3B8'
];
