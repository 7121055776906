import * as React from 'react';
import { useQuery } from 'react-query';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { fetchJobInfo } from './useJobInfo';
import { JobStatusRecord } from '../../components/shared/models/job/JobStatusRecord';

const BULK_UPDATE_REFRESH_INTERVAL_SECONDS = 3;
const MILLISECONDS_FACTOR = 1000;

export function useJobStatus(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, JobStatusRecord | null>(
    // Use different QueryKey from useJobInfo() to avoid refetching of useJobInfo also.
    [config.api.jobInfo.replace(':id', `${jobId}-status`)],
    () => fetchJobInfo(jobId, jsonConvert),
    {
      // @ts-expect-error TS(2322): Type '(jobStatus: JobStatusRecord) => number | fal...
      refetchInterval: (jobStatus: JobStatusRecord) => {
        if (jobStatus?.processedDocumentsCount === 0) {
          return BULK_UPDATE_REFRESH_INTERVAL_SECONDS * MILLISECONDS_FACTOR;
        }
        return false;
      },
      // @ts-expect-error TS(2322): Type '(data: JobInfoResponse) => JobStatusRecord |...
      select: (data: JobInfoResponse) => {
        return data.info?.status;
      },
      enabled: Boolean(jobId),
      staleTime: Infinity
    }
  );
}
