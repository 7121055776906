import { JsonObject, JsonProperty } from 'json2typescript';
import { LOCAL_STORAGE_TOKEN_NAME } from '../../../pages/Main/Login/AuthConstants';

@JsonObject('ActionAggregationsRequest')
export class ActionAggregationsRequest {
  @JsonProperty('user_session_id', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  user_session_id: string = undefined;

  constructor() {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    this.user_session_id = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
  }
}
