import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PeriodOptions } from 'src/components/shared/models/filters/period';

type FilterByPeriodProps = {
  period: PeriodOptions;
  setPeriod: (period: PeriodOptions) => void;
};

function FilterByPeriod({ period, setPeriod }: FilterByPeriodProps) {
  const { t } = useTranslation(['common']);

  const periods = [
    {
      key: PeriodOptions.LAST_24H,
      dataTestId: 'period-option-last24h',
      label: t('common:timeSelectorOptions.last24h')
    },
    {
      key: PeriodOptions.LAST_7DAYS,
      dataTestId: 'period-option-last7days',
      label: t('common:timeSelectorOptions.last7Days')
    },
    {
      key: PeriodOptions.LAST_30DAYS,
      dataTestId: 'period-option-last30days',
      label: t('common:timeSelectorOptions.last30Days')
    },
    {
      key: PeriodOptions.ALL_TIME,
      dataTestId: 'period-option-alltime',
      label: t('common:timeSelectorOptions.allTime')
    }
  ];
  return (
    <ToggleButtonGroup value={period} color="primary" exclusive>
      {periods.map((currentPeriod) => (
        <ToggleButton
          key={currentPeriod.key}
          value={currentPeriod.key}
          data-test-id={currentPeriod.dataTestId}
          onClick={() => setPeriod(currentPeriod.key)}
        >
          {currentPeriod.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export default FilterByPeriod;
