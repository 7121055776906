import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BucketAlertsEventsHistogram')
export class BucketAlertsEventsHistogram {
  @JsonProperty('date', String)
  date: string;

  @JsonProperty('date_middle', String)
  date_middle: string;

  @JsonProperty('date_end', String)
  date_end: string;

  @JsonProperty('value', Number)
  value: number;
}
