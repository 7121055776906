import { Redirect, Route, Switch } from 'react-router-dom';
import { useApp } from '../App/App.context';
import { RouteType } from '../shared/models/route/RouteType';
import { RouteItem } from './RouteItem';
import { PrivateRoute } from '../shared/components/PrivateRoute/PrivateRoute';
import { useRoutesHook } from './Routes.hook';
import { routeShouldBeGenerated, useLicenseFeatures } from '../../data-access';
import { Fragment } from 'react';
import { useRouteMatch } from './useRouteMatch';
import { useCheckLoginSession } from '../shared/hooks/useCheckLoginSession';
import { config } from '../../config';
import { useAuth } from '../pages/Main/Login/Auth.context';
import Unavailable from '../pages/Unavailable';
import useRefetchLicense from '../../data-access/license/useRefetchLicense';
import Login from '@arcanna/pages/Login/Login';

export function Routes() {
  useRoutesHook();
  useCheckLoginSession();

  const { state: authState } = useAuth();
  const { state: appState } = useApp();

  const licenseFeaturesQuery = useLicenseFeatures();
  useRefetchLicense(authState.userSessionId);
  const { isRouteMatch } = useRouteMatch();

  return (
    <Switch>
      <Route path={config.routes.login}>
        <Login />
      </Route>

      <Route path={config.routes.unavailable}>
        <Unavailable />
      </Route>

      {appState.routes.length > 0 &&
        appState.userPermissions.length > 0 &&
        licenseFeaturesQuery.data &&
        appState.dynamicRoutesLoaded &&
        appState.routes
          .filter(
            (route: RouteType) =>
              !(route.onlyForDev && process.env.NODE_ENV === 'production') &&
              // @ts-expect-error TS(2345): Argument of type 'LicenseFeature | undefined' is n...
              routeShouldBeGenerated(licenseFeaturesQuery.data, route.requiredLicenseFeature)
          )
          .map((route: RouteType) => {
            // eslint-disable-next-line
            let CustomRoute: any = Route;
            let childrenRoutes: JSX.Element[] = [];

            if (route.isPrivate) {
              CustomRoute = PrivateRoute;
            }

            if (route.children) {
              childrenRoutes = route.children
                .map((routeChild: RouteType) => {
                  if (routeChild.children) {
                    return routeChild.children.map((routeSubChild: RouteType) => (
                      <CustomRoute key={routeSubChild.path} path={routeSubChild.path} exact={routeSubChild.exact ?? false}>
                        <RouteItem key={routeSubChild.path} route={routeSubChild} />
                      </CustomRoute>
                    ));
                  }

                  return (
                    <CustomRoute key={routeChild.path} path={routeChild.path} exact={routeChild.exact ?? false}>
                      <RouteItem key={routeChild.path} route={routeChild} />
                    </CustomRoute>
                  );
                })
                .flat();

              if (!route.component && !route.renderOnlyChildren) {
                return childrenRoutes;
              }
            }

            let RouteProvider = route.provider;
            if (!RouteProvider) {
              RouteProvider = Fragment;
            }

            return (
              <CustomRoute key={route.path} path={route.path} exact={route.exact ?? false}>
                <RouteProvider>
                  {isRouteMatch(window.location.pathname, route.path) && !route.renderOnlyChildren ? (
                    <RouteItem key={route.path} route={route} />
                  ) : null}
                  {childrenRoutes.length > 0 && window.location.pathname !== route.path && !route.renderOnlyChildren ? (
                    <Switch>{childrenRoutes.map((childrenRoute) => childrenRoute)}</Switch>
                  ) : null}
                  {route.renderOnlyChildren && childrenRoutes.length > 0
                    ? childrenRoutes.map((childrenRoute) => childrenRoute)
                    : null}
                </RouteProvider>
              </CustomRoute>
            );
          })}
      <Route path="*">{!authState.isLoggedIn ? <Redirect to={config.routes.login} /> : null}</Route>
    </Switch>
  );
}
