import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('IntegrationReadRequest')
export class IntegrationReadRequest extends CommonRequest {
  @JsonProperty('event_count', Number)
  event_count?: number = undefined;

  constructor(eventCount: number) {
    super();
    this.event_count = eventCount;
  }
}
