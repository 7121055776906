import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiDividerStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: palette.secondary[800]
      }
    }
  }
});
