import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TransitionMulticlassParams')
export class TransitionMulticlassParams {
  @JsonProperty('field', String)
  field?: string = undefined;

  @JsonProperty('value', String || Object)
  value?: string | object = undefined;

  @JsonProperty('multiclass_mapping', Object)
  // eslint-disable-next-line
  multiclass_mapping?: Record<string, any> = undefined;

  // eslint-disable-next-line
  constructor(field: string, value: string, multiclass_mapping: Record<string, any>) {
    this.field = field;
    this.value = value;
    this.multiclass_mapping = multiclass_mapping;
  }
}

JsonObject('TransitionMulticlassMappingRecord');
export default class TransitionMulticlassMappingRecord {
  @JsonProperty('from', String)
  from?: string = undefined;

  @JsonProperty('to', String)
  to?: string = undefined;

  @JsonProperty('params', [TransitionMulticlassParams])
  params?: TransitionMulticlassParams[];

  constructor(from?: string, to?: string, params?: TransitionMulticlassParams[]) {
    this.from = from;
    this.to = to;
    this.params = params;
  }
}
