import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('AccuracyGraphPoint')
export class AccuracyGraphPoint {
  @JsonProperty('date', String)
  date: string;

  @JsonProperty('accuracy', Number)
  accuracy: number;

  @JsonProperty('confirmed', Number)
  confirmed: number;

  @JsonProperty('changed', Number)
  changed: number;

  @JsonProperty('consensus', Number)
  consensus: number;
}

@JsonObject('AccuracyData')
export class AccuracyData {
  @JsonProperty('job_title', String)
  jobTitle: string;

  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('has_data', Boolean)
  hasData: boolean;

  @JsonProperty('graph_points', [AccuracyGraphPoint])
  graphPoints: AccuracyGraphPoint[] | null;
}

@JsonObject('AccuracyPerModelResponse')
export class AccuracyPerModelResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('average_accuracy', Number)
  averageAccuracy: number;

  @JsonProperty('data', [AccuracyData])
  data: AccuracyData[];
}
