import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiBreadcrumbStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        color: palette.secondary[300],
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px'
      },
      separator: {
        color: palette.secondary[500],
        margin: '0 8px',

        '& svg': {
          fontSize: '12px'
        }
      },
      li: {
        '& a': {
          color: palette.secondary[300],
          textDecoration: 'underline',
          textUnderlineOffset: '2px'
        },

        '& span': {
          fontSize: '12px',
          lineHeight: '20px',
          color: palette.secondary[300]
        },

        '&:last-child span': {
          color: palette.secondary[100]
        }
      }
    }
  }
});
