import { ReactNode } from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

type ArcannaChipSize = 'medium' | 'small' | 'tiny';
type ArcannaChipColor = 'grey' | 'green' | 'orange' | 'blue' | 'red' | 'light-blue';

type ArcannaChipProps = {
  size?: ArcannaChipSize;
  color?: ArcannaChipColor;
  children: ReactNode | string;
};
export function ArcannaChip({ size = 'small', color = 'grey', children }: ArcannaChipProps) {
  return <div className={cx([styles.chip, styles[`chip--${size}`], styles[`chip--${color}`]])}>{children}</div>;
}

export default ArcannaChip;
