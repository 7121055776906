import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EventExplorerBucketInfoRecord')
export class EventExplorerBucketInfoRecord {
  @JsonProperty('bucket_id', String)
  bucket_id?: string;

  @JsonProperty('state', String)
  state?: string;

  @JsonProperty('consensus', String, true)
  consensus?: string;
}
