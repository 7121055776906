import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('FeedbackUsersRequest')
export class FeedbackUsersRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  constructor(job_id: number) {
    super();
    this.job_id = job_id;
  }
}
