import { JobStatusToggle } from '@arcanna/components';
import { EIcon, Icon } from '@arcanna/generic';
import { PageLayout } from '@arcanna/layouts';
import { withTransaction } from '@elastic/apm-rum-react';
import { Breadcrumbs } from '@mui/material';
import { config } from 'src/config';
import { useJobInfo } from 'src/data-access';
import { useJobTitle } from '../Retrain/hooks/usePageTitle';
import { Link, useParams } from 'react-router-dom';
import JobSummary from './JobSummary/JobSummary';
import JobFeedbackSection from './JobFeedbackSection';
import { JobOverviewActions } from './components/JobOverviewActions';
import JobPerformance from './JobPerformance';
import { useTranslation } from 'react-i18next';
import { useEstablishStartNotificationType } from '../components/JobNotifications/hooks/useEstablishStartNotificationType';
import { useOverviewReload } from './hooks/useOverviewReload';
import JobNotifications from '../components/JobNotifications';

const JobOverview = () => {
  const { t } = useTranslation(['job', 'homepage']);
  const { id } = useParams<{ id: string }>();
  const jobId = +id;
  useJobTitle(jobId);
  const { notificationType } = useEstablishStartNotificationType(jobId, t('job:navigation.overview'));
  const reload = useOverviewReload();
  const { data: jobInfo } = useJobInfo(+jobId);

  return (
    <PageLayout
      breadcrumbContent={
        <Breadcrumbs separator={<Icon name={EIcon.ChevronRight} />}>
          <Link to={config.routes.jobsAll}>{t('homepage:AIJobsCardTitle')}</Link>
          {jobInfo?.info?.title && <span>{jobInfo?.info?.title}</span>}
          <span>{t('job:navigation.overview')}</span>
        </Breadcrumbs>
      }
      actions={<JobStatusToggle jobId={jobId} />}
      notificationsContent={
        <JobNotifications
          fromPage={t('job:navigation.overview')}
          reload={reload}
          startNotificationType={notificationType}
          className="notificationsWrapper"
        />
      }
    >
      <div className="flex flex-col" style={{ gap: 24, marginBottom: 100 }}>
        <JobSummary jobId={jobId} />
        <JobFeedbackSection jobId={jobId} />
        <JobPerformance jobId={jobId} />
      </div>
      <JobOverviewActions jobId={jobId} />
    </PageLayout>
  );
};
export default withTransaction('JobEventExplorer', 'component')(JobOverview);
