import { JsonObject, JsonProperty } from 'json2typescript';
import { Filter } from '../usecase/Filter';

@JsonObject('ChartRecord')
export class ChartRecord {
  @JsonProperty('id', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  id: string = undefined;

  @JsonProperty('start', Date)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Date'.
  start: Date = undefined;

  @JsonProperty('end', Date)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Date'.
  end: Date = undefined;

  @JsonProperty('time_zone', Date)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  time_zone: string = undefined;

  @JsonProperty('filters', Array<Filter>())
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Filter...
  filters: Array<Filter> = undefined;

  constructor(id: string, start: Date, end: Date, time_zone: string, filters: Array<Filter>) {
    this.id = id;
    this.start = start;
    this.end = end;
    this.time_zone = time_zone;
    this.filters = filters;
  }
}
