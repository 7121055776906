export function shortenNumber(n: number) {
  if (n < 1000) {
    return n.toString();
  }

  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' }
  ];

  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (n >= si[index].v) {
      break;
    }
  }

  const shortenedNumber = (n / si[index].v).toFixed(2);
  const roundedNumber = parseFloat(shortenedNumber);

  if (roundedNumber === Math.floor(roundedNumber)) {
    return roundedNumber.toString() + si[index].s;
  } else {
    return shortenedNumber + si[index].s;
  }
}
