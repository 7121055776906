import { Any, JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobAlertHistoryArtifactRecord')
export class JobAlertHistoryArtifactRecord {
  autoId: number | undefined;

  @JsonProperty('component', String)
  component: string | undefined;

  @JsonProperty('component_subcategory', String)
  component_subcategory: string | undefined;

  @JsonProperty('type', String)
  type: string | undefined;

  @JsonProperty('metadata', Any)
  // eslint-disable-next-line
  metadata: any | undefined;

  constructor() {
    this.autoId = Math.random();
  }
}
