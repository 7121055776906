import { JsonObject, JsonProperty } from 'json2typescript';
import { EventExplorerBatchRecord } from './EventExplorerBatchRecord';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('EventExplorerBatchResponse')
export class EventExplorerBatchResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('structure', [EventExplorerBatchRecord])
  structure?: EventExplorerBatchRecord[] = [];

  @JsonProperty('total', Number)
  total?: number | undefined;
}
