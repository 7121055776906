import ModalSteps from './ModalSteps/ModalSteps';
import useModalSteps from './ModalSteps/hooks/useModalSteps';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { EStepStatus } from './ModalSteps/ModalSteps.types';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

type TIntegrationCreateHealthCheckProps = {
  onClose: () => void;
  onConnectionCheck?: () => Promise<unknown>;
  onCredentialsCheck: () => Promise<unknown>;
  onSuccessCheck?: () => void;
};

export default function IntegrationCreateHealthCheck({
  onClose,
  onConnectionCheck,
  onCredentialsCheck,
  onSuccessCheck
}: TIntegrationCreateHealthCheckProps) {
  const { t } = useTranslation(['integration', 'common', 'firstBatchTest']);

  useEffect(() => {
    validateAndSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateAndSubmit = async () => {
    try {
      setFollowingStepsStatus(EStepStatus.Validating, 0);
      await onConnectionCheck?.();
      // the sleeps are used to simulate the expected behaviour from figma, where the loading state is shown for a few seconds
      await sleep(1000);
      await onCredentialsCheck();
      setStepStatus(EStepStatus.Success, 0);
      await sleep(1000);
      setStepStatus(EStepStatus.Success, 1);
      await sleep(2000);
      onSuccessCheck?.();
    } catch (e) {
      setFollowingStepsStatus(EStepStatus.Error, 0);
    }
  };

  const { steps, currentStep, setStepStatus, setFollowingStepsStatus, setCurrentStep } = useModalSteps([
    {
      title: t('firstBatchTest:steps.connection.title'),
      status: EStepStatus.Validating,
      validationDescription: t('firstBatchTest:steps.connection.validating'),
      successDescription: t('firstBatchTest:steps.connection.success'),
      errorDescription: t('firstBatchTest:steps.connection.error')
    },
    {
      title: t('firstBatchTest:steps.credentials.title'),
      status: EStepStatus.Validating,
      validationDescription: t('firstBatchTest:steps.credentials.validating'),
      successDescription: t('firstBatchTest:steps.credentials.success'),
      errorDescription: t('firstBatchTest:steps.credentials.error')
    }
  ]);

  const handleClose = () => {
    setFollowingStepsStatus(EStepStatus.Validating, 0);
    setCurrentStep(0);
    onClose();
  };

  return (
    <ModalSteps
      onClose={handleClose}
      // control the modal display from outside the component
      isModalOpen={true}
      title={t('firstBatchTest:integrationCheckModalTitle')}
      steps={steps}
      currentStep={currentStep}
      zIndex={1300}
      footerConfig={{
        successConfig: {
          successMessage: t('firstBatchTest:successNotifEventsV2'),
          successDescription: t('firstBatchTest:successNotifDetailV2')
        },
        errorConfig: {
          errorMessage: t('firstBatchTest:invalidCheckModalNotifTitleV2'),
          closeButtonText: t('common:close')
        }
      }}
    />
  );
}
