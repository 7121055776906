import { JsonObject, JsonProperty } from 'json2typescript';
import { EventExplorerStructureColumnRecord } from './EventExplorerStructureColumnRecord';

@JsonObject('EventExplorerStructureRecord')
export class EventExplorerStructureRecord {
  @JsonProperty('labels', [String])
  labels: string[] = [];

  @JsonProperty('columns', [EventExplorerStructureColumnRecord])
  columns: EventExplorerStructureColumnRecord[] = [];
}
