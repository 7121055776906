import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { useJobContext } from '../Job.context';
import { getJsonConvert } from '../../../../shared/utilities/json-convert';
import { REQUEST_STATUS_NOK, useFetch } from '../../../../shared/hooks/useFetch';
import { getBackendEndpoint } from '../../../../shared/utilities/api';
import { CommonRequest } from '../../../../shared/models/common/CommonRequest';
import { JobInfoResponse } from '../../../../shared/models/job/JobInfoResponse';
import { config } from '../../../../../config';
import { useApp } from '../../../../App/App.context';
import { getCurrentRoute } from '../../../../../utils';
import style from './JobFeedbackColumns.module.css';
import { JobFeedbackColumnsSummaryRequest } from '../../../../shared/models/job/JobFeedbackColumnsSummaryRequest';
import { JobFeedbackColumnsSummaryDetails } from '../../../../shared/models/job/JobFeedbackColumnsSummaryDetails';
import { JobFeedbackColumnsSummaryResponse } from '../../../../shared/models/job/JobFeedbackColumnsSummaryResponse';
import { JobUpdateResponse } from '../../../../shared/models/job/JobUpdateResponse';
import { showErrorNotification, showSuccessNotification } from '../../../../shared/utilities/notification';
import { JobFeedbackColumnsUpdateRequest } from '../../../../shared/models/job/JobFeedbackColumnsUpdateRequest';
import { JobFeedbackColumnsUpdateDetails } from '../../../../shared/models/job/JobFeedbackColumnsUpdateDetails';
import { searchText } from '../../../../../utils/searchText';
import { FeedbackColumnType } from '../../../../../constants';

export function useJobFeedbackColumns() {
  const { state: appState, setAppHeaderTitle } = useApp();
  const location = useLocation();
  const currentRoute = getCurrentRoute(location.pathname, appState.routes);
  const { setSelectedJob, getJobAppHeaderTitle, navigateToJobs } = useJobContext();
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const [jobInfoError, setJobInfoError] = useState('');
  const [initialStructureData, setInitialStructureData] = useState([] as JobFeedbackColumnsSummaryDetails[]);
  const [structureData, setStructureData] = useState([] as JobFeedbackColumnsSummaryDetails[]);
  const [searchTextValue, setSearchTextValue] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [reloadFeedbackColumns, setReloadFeedbackColumns] = useState(true);
  const isCalledJobInfo = React.useRef(false);
  const { t } = useTranslation(['common', 'job']);
  const { id: jobId } = useParams<{ id: string }>();
  const getJobInfoPath = useCallback(() => config.api.jobInfo.replace(':id', jobId), [jobId]);

  const { post: postJobInfo, loading: jobInfoLoading } = useFetch({
    path: getBackendEndpoint(getJobInfoPath()),
    initialResponseData: null,
    load: false
  });

  const {
    loading: loadingUpdateFeedbackColumns,
    post: postUpdateFeedbackColumns,
    error: errorUpdateFeedbackColumns
  } = useFetch({
    path: getBackendEndpoint(config.api.jobColumnsSelectionUpdate),
    load: false
  });

  const handleUpdateFeedbackColumns = useCallback(() => {
    const updatedRows: JobFeedbackColumnsUpdateDetails[] = [];
    initialStructureData.forEach((row: JobFeedbackColumnsSummaryDetails) => {
      const foundSelectedRow = selectedRowKeys.find((selectedRowKey: string) => row.name === selectedRowKey);
      if (foundSelectedRow) {
        updatedRows.push({ ...row, active: true });
      }
    });
    const updateRequest = new JobFeedbackColumnsUpdateRequest(
      parseInt(jobId, 10),
      FeedbackColumnType.FEEDBACK_COLUMNS,
      updatedRows
    );
    postUpdateFeedbackColumns(updateRequest)
      // eslint-disable-next-line
      .then((response: any) => {
        const jobUpdate = jsonConvert.deserializeObject(response.data.resource, JobUpdateResponse);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (jobUpdate.request.status === REQUEST_STATUS_NOK) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          setJobInfoError(jobUpdate.request.reason);
          return;
        }
        setReloadFeedbackColumns(true);
        showSuccessNotification(t('common:actionCompleted'), t('job:updateSuccessfully'));
      })
      .catch(() => {
        showErrorNotification(t('common:error'), t('common:updateError'));
      });
  }, [jobId, selectedRowKeys, initialStructureData, postUpdateFeedbackColumns, jsonConvert, t, setReloadFeedbackColumns]);

  useEffect(() => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    setAppHeaderTitle(getJobAppHeaderTitle(currentRoute.title), location.pathname);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
  }, [setAppHeaderTitle, getJobAppHeaderTitle, currentRoute.title, location.pathname]);

  // Get job information
  useEffect(() => {
    if (isCalledJobInfo.current) {
      return;
    }
    isCalledJobInfo.current = true;
    postJobInfo(new CommonRequest())
      // eslint-disable-next-line
      .then((response: any) => {
        const jobInfo = jsonConvert.deserializeObject(response.data.resource, JobInfoResponse);

        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (jobInfo.request.status !== 'OK') {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          setJobInfoError(jobInfo.request.reason);
          return;
        }
        // @ts-expect-error TS(2345): Argument of type 'JobInfoRecord | undefined' is no...
        setSelectedJob(jobInfo.info);
      })
      .catch(() => {
        setJobInfoError(t('job:FeedbackColumns.loadJobInfoError'));
      });
  }, [jobId, setSelectedJob, t, postJobInfo, jsonConvert]);

  const {
    responseData: feedbackColumnsResponseData,
    post: feedbackColumnsPost,
    loading: feedbackColumnsLoading
  } = useFetch({
    path: getBackendEndpoint(config.api.jobColumnsSelectionSummary),
    initialResponseData: null,
    load: false
  });

  useEffect(() => {
    if (jobId !== null && reloadFeedbackColumns) {
      feedbackColumnsPost(new JobFeedbackColumnsSummaryRequest(parseInt(jobId, 10), FeedbackColumnType.FEEDBACK_COLUMNS));
      setReloadFeedbackColumns(false);
    }
  }, [jobId, feedbackColumnsPost, reloadFeedbackColumns]);

  useEffect(() => {
    if (feedbackColumnsResponseData !== null) {
      const structureResponse = jsonConvert.deserializeObject(
        feedbackColumnsResponseData.resource,
        JobFeedbackColumnsSummaryResponse
      );
      const { columns } = structureResponse;
      // @ts-expect-error TS(2345): Argument of type 'JobFeedbackColumnsSummaryDetails...
      setInitialStructureData(columns);

      const selectedRowsToOverwrite: JobFeedbackColumnsSummaryDetails[] = [];
      const selectedRowKeysToOverwrite: string[] = [];
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      columns.forEach((row: JobFeedbackColumnsSummaryDetails) => {
        if (row.active) {
          selectedRowsToOverwrite.push({ ...row });
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
          selectedRowKeysToOverwrite.push(row.name);
        }
      });
      // @ts-expect-error TS(2345): Argument of type 'JobFeedbackColumnsSummaryDetails...
      setSelectedRows(selectedRowsToOverwrite);
      // @ts-expect-error TS(2345): Argument of type 'string[]' is not assignable to p...
      setSelectedRowKeys(selectedRowKeysToOverwrite);
    }
  }, [jsonConvert, setSelectedRows, setSelectedRowKeys, setInitialStructureData, feedbackColumnsResponseData]);

  useEffect(() => {
    if (feedbackColumnsResponseData !== null) {
      if (searchTextValue) {
        setStructureData(searchText<JobFeedbackColumnsSummaryDetails>(initialStructureData, searchTextValue, 'name'));
      } else {
        setStructureData(initialStructureData);
      }
    }
  }, [searchTextValue, initialStructureData, setStructureData, feedbackColumnsResponseData]);

  const getFeedbackColumnsColumns = () => [
    {
      title: t('common:name'),
      width: 250,
      dataIndex: 'name',
      key: 'name',
      sorter: (item1: JobFeedbackColumnsSummaryDetails, item2: JobFeedbackColumnsSummaryDetails) =>
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        item1.name.localeCompare(item2.name)
    },
    {
      title: t('common:type'),
      width: 100,
      dataIndex: 'type',
      key: 'type',
      sorter: (item1: JobFeedbackColumnsSummaryDetails, item2: JobFeedbackColumnsSummaryDetails) =>
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        item1.type.localeCompare(item2.type)
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    selectedRows,
    // eslint-disable-next-line
    onChange: (selectedRowKeysChanged: any, selectedRowsChanged: any) => {
      const missingRowKeys = selectedRowKeys.filter(
        (key: string) => structureData.filter((item: JobFeedbackColumnsSummaryDetails) => item.name === key).length === 0
      );
      const tmpSelectedRowKeys = selectedRowKeysChanged.concat(missingRowKeys);
      setSelectedRowKeys(tmpSelectedRowKeys);
      setSelectedRows(selectedRowsChanged);
    }
  };

  // eslint-disable-next-line
  const getTableRowClassName = (record: any, index: number) => (index % 2 === 0 ? style.tableRowLight : style.tableRowDark);

  return {
    jobInfoError,
    jobInfoLoading,
    handleUpdateFeedbackColumns,
    navigateToJobs,
    jobId,
    structureData,
    initialStructureData,
    setStructureData,
    setSearchTextValue,
    jobStructureLoading: feedbackColumnsLoading,
    getFeedbackColumnsColumns,
    rowSelection,
    getTableRowClassName,
    loadingUpdateFeedbackColumns,
    errorUpdateFeedbackColumns
  };
}
