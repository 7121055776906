import * as React from 'react';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { config } from '../../../config';
import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { IntegrationSubcategoryListResponse } from '../../../components/shared/models/integration/subcategory/IntegrationSubcategoryListResponse';
import { IntegrationSubcategoryListRequest } from '../../../components/shared/models/integration/subcategory/IntegrationSubcategoryListRequest';
import { IntegrationSubcategoryRecord } from '../../../components/shared/models/integration/subcategory/IntegrationSubcategoryRecord';

type useIntegrationSubcategoriesProps = {
  searchPhrase?: string;
  edit?: boolean;
  eventCount?: number;
};

export function useIntegrationSubcategoriesList({ searchPhrase, eventCount, edit }: useIntegrationSubcategoriesProps) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<IntegrationSubcategoryListResponse, Error>(
    [config.api.integrationSubcategoryList.replace('/:subcategoryId', '') + searchPhrase],
    () =>
      axios
        .post<{
          resource: IntegrationSubcategoryListResponse;
        }>(
          config.api.integrationSubcategoryList.replace('/:subcategoryId', ''),
          // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
          new IntegrationSubcategoryListRequest(eventCount, edit, searchPhrase)
        )
        .then((responseData: AxiosResponse<{ resource: IntegrationSubcategoryListResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, IntegrationSubcategoryListResponse)
        )
  );
}

type UseIntegrationSubcategoryListId = {
  id: number;
};
export function useIntegrationSubcategoryListId({ id }: UseIntegrationSubcategoryListId) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<IntegrationSubcategoryListResponse, Error, IntegrationSubcategoryRecord>(
    [config.api.integrationSubcategoryList.replace('/:subcategoryId', '')],
    () =>
      axios
        .post<{
          resource: IntegrationSubcategoryListResponse;
        }>(
          config.api.integrationSubcategoryList.replace('/:subcategoryId', ''),
          // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
          new IntegrationSubcategoryListRequest(undefined, undefined)
        )
        .then((responseData: AxiosResponse<{ resource: IntegrationSubcategoryListResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, IntegrationSubcategoryListResponse)
        ),
    {
      // @ts-expect-error TS(2322): Type '(response: IntegrationSubcategoryListRespons...
      select: (response: IntegrationSubcategoryListResponse) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return response.entries.find((item) => item.id === id);
      }
    }
  );
}
