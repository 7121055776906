import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobFeatureSelectionSummaryDetails')
export class JobFeatureSelectionSummaryDetails {
  @JsonProperty('id', String)
  id?: string = undefined;

  @JsonProperty('name', String)
  name?: string = undefined;

  @JsonProperty('type', String)
  type?: string = undefined;

  @JsonProperty('caption', String)
  caption?: string = undefined;

  @JsonProperty('active', Boolean)
  active?: boolean = undefined;

  @JsonProperty('is_generated', Boolean)
  is_generated?: boolean = undefined;

  @JsonProperty('disabled', Boolean)
  disabled?: boolean = undefined;
}
