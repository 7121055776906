import { useEffect, useState } from 'react';
import { PeriodOptions } from '../models/filters/period';

export function useStartDateByPeriod(period: PeriodOptions = PeriodOptions.ALL_TIME) {
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
  const [startDate, setStartDate] = useState<Date>(null);

  useEffect(
    function calculateStartDateBasedOnPeriod() {
      switch (period) {
        case PeriodOptions.LAST_24H:
          setStartDate(new Date(Date.now() - 24 * 3600 * 1000));
          break;
        case PeriodOptions.LAST_30DAYS:
          setStartDate(new Date(Date.now() - 24 * 30 * 3600 * 1000));
          break;
        case PeriodOptions.LAST_7DAYS:
          setStartDate(new Date(Date.now() - 24 * 7 * 3600 * 1000));
          break;
        case PeriodOptions.ALL_TIME:
          // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
          setStartDate(null);
          break;
      }
    },
    [period]
  );

  return startDate;
}
