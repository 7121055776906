import { JsonCustomConvert, JsonConverter } from 'json2typescript';
import moment from 'moment';

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  // eslint-disable-next-line
  serialize = (date: Date): any => {
    if (!date) return null;
    return moment(date).toISOString();
  };

  // eslint-disable-next-line
  deserialize = (date: any): Date => {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    if (!date) return null;
    return moment(date).toDate();
  };
}
