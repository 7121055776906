import { SVGProps } from 'react';

type TSuccessIconProps = SVGProps<SVGSVGElement> & {
  fill?: string;
};
export default function Success({ fill = 'none', ...props }: TSuccessIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M16.9407 8.86358L16.9415 8.86244C17.2671 8.40836 16.9435 7.77344 16.3828 7.77344H15.2836C14.8791 7.77344 14.5044 7.9698 14.2714 8.29223L14.271 8.29271L10.9923 12.8395L9.7303 11.0883C9.73013 11.088 9.72995 11.0878 9.72977 11.0875C9.49409 10.7581 9.11463 10.5672 8.71641 10.5672H7.61719C7.05654 10.5672 6.73293 11.2021 7.05849 11.6562L7.05939 11.6574L9.98105 15.7061C9.98125 15.7063 9.98143 15.7066 9.98163 15.7068C10.0968 15.8674 10.2486 15.9982 10.4243 16.0885C10.6004 16.1789 10.7954 16.2261 10.9934 16.2261C11.1913 16.2261 11.3863 16.1789 11.5624 16.0885C11.7382 15.9982 11.8899 15.8673 12.0051 15.7068C12.0053 15.7065 12.0055 15.7063 12.0057 15.7061L16.9407 8.86358ZM2 12C2 6.47771 6.47771 2 12 2C17.5223 2 22 6.47771 22 12C22 17.5223 17.5223 22 12 22C6.47771 22 2 17.5223 2 12Z"
        fill="#10B981"
        stroke="#10B981"
      />
    </svg>
  );
}
