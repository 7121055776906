import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { EventExplorerEventInfoRecord } from './EventExplorerEventInfoRecord';

@JsonObject('EventExplorerEventInfoResponse')
export class EventExplorerEventInfoResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('entries', [EventExplorerEventInfoRecord])
  entries?: EventExplorerEventInfoRecord[];
}
