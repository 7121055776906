import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { FeedbackStatisticsRequest } from '../../components/shared/models/feedback/FeedbackStatisticsRequest';
import { FeedbackStatisticsUserResponse } from '../../components/shared/models/feedback/FeedbackStatisticsUserResponse';
import { JobRetrainFilters } from '../../components/shared/models/retrain-statistics/JobRetrain';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

export function useFeedbackStatisticsSplitByUser({ jobId, retrainSession, startDate, endDate, filters }: JobRetrainFilters) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery(
    [
      config.api.feedbackStatisticsUsers,
      {
        jobId,
        retrainSession,
        startDate,
        endDate,
        filters
      }
    ],
    () =>
      axios
        .post<{
          resource: FeedbackStatisticsUserResponse;
        }>(config.api.feedbackStatisticsUsers, new FeedbackStatisticsRequest(jobId, retrainSession, startDate, endDate, filters))
        .then((responseData: AxiosResponse<{ resource: FeedbackStatisticsUserResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, FeedbackStatisticsUserResponse)
        )
  );
}
