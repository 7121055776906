import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('FatigueRiskGraphPoint')
export class FatigueRiskGraphPoint {
  @JsonProperty('date', String)
  date: string;

  @JsonProperty('risk', Number)
  risk: number;

  @JsonProperty('confirmed', Number)
  confirmed: number;

  @JsonProperty('changed', Number)
  changed: number;

  @JsonProperty('consensus', Number)
  consensus: number;
}

@JsonObject('FatigueRiskData')
export class FatigueRiskData {
  @JsonProperty('job_title', String)
  jobTitle: string;

  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('has_data', Boolean)
  hasData: boolean;

  @JsonProperty('graph_points', [FatigueRiskGraphPoint])
  graphPoints: FatigueRiskGraphPoint[] | null;
}

@JsonObject('FatigueRiskResponse')
export class FatigueRiskResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('average_risk', Number)
  averageRisk: number;

  @JsonProperty('data', [FatigueRiskData])
  data: FatigueRiskData[];
}
