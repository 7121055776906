import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { FeedbackUsersRequest } from '../../components/shared/models/feedback/FeedbackUsersRequest';
import { FeedbackUsersResponse } from '../../components/shared/models/feedback/FeedbackUsersResponse';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

export function useFeedbackUsers(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery([config.api.feedbackUsers, { jobId }], () =>
    axios
      .post<{ resource: FeedbackUsersResponse }>(config.api.feedbackUsers, new FeedbackUsersRequest(jobId))
      .then(
        (responseData: AxiosResponse<{ resource: FeedbackUsersResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, FeedbackUsersResponse).feedback_users
      )
  );
}
