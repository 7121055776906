import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { MetricCircle } from '../Visualisation/MetricCircle';
import { useVisualizationsContext } from './Visualizations.context';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { getJsonConvert } from '../../utilities/json-convert';
import { ChartsRequest } from '../../models/charts/ChartsRequest';

export function QueryMetricCircle(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  const [value, setValue] = useState<number>();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    let responseValue = 0;
    const aggregations = visualizationRecord.aggs;
    for (const key in aggregations) {
      responseValue = aggregations[key].value;
    }

    setValue(responseValue);
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div>
          <div className="usecase-card-title">{props.name}</div>
          <p>&nbsp;</p>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          <MetricCircle key={props.name + Math.random()} value={value} label={props.name} color={props.style.color} />
        </div>
      </Spin>
    </>
  );
}
