import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PlatformFeaturesRecord')
export class PlatformFeaturesRecord {
  @JsonProperty('decision_intelligence', Boolean)
  decisionIntelligence?: boolean;

  @JsonProperty('rca', Boolean)
  rca?: boolean;
}
