import { useState, useEffect, useMemo, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { ApiService } from '../services/api.service';
import { FetchErrorType } from './fetch-error.type';
import { FetchOptionsType } from './fetch-options.type';
import { RefreshTokenRequest } from '../models/user/RefreshTokenRequest';
import { getJsonConvert } from '../utilities/json-convert';
import { LOCAL_STORAGE_REFRESH_TOKEN_NAME, LOCAL_STORAGE_TOKEN_NAME, BEARER_PREFIX } from '../../pages/Main/Login/AuthConstants';
import { RefreshTokenResponse } from '../models/user/RefreshTokenResponse';
import { logout, rotateAndLogoutOnServerDown } from '../../../data-access/api';

export const REQUEST_STATUS_OK = 'OK';
export const REQUEST_STATUS_NOK = 'NOK';
const INVALID_SESSION = 'Invalid session';

const OPENID_TOKEN_EXPIRED = 'token expired';
const OPENID_REFRESH_TOKEN_ENDPOINT = '/user/openid/refresh_token';

export const useFetch = (options: FetchOptionsType = {}) => {
  const defaultErr: FetchErrorType = null;

  const [response, setResponse] = useState({});
  const [responseData, setResponseData] = useState(options.initialResponseData);
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [error, setError] = useState<any>(defaultErr);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const apiService = useMemo(() => new ApiService(), []);
  const apiInstance = useMemo(() => apiService.getInstance(), [apiService]);

  // eslint-disable-next-line
  const buildRequestParams: any = useCallback(
    // eslint-disable-next-line
    (args: any) => {
      if (args.length === 1) {
        return [options.path, args[0]];
      }
      return [args[0], args[1]];
    },
    [options.path]
  );

  const put = useCallback(
    // eslint-disable-next-line
    (...args: any[]) => {
      const requestParams = buildRequestParams(args);
      return apiInstance.put(requestParams[0], requestParams[1]);
    },
    [apiInstance, buildRequestParams]
  );

  const patch = useCallback(
    // eslint-disable-next-line
    (...args: any[]) => {
      const requestParams = buildRequestParams(args);
      return apiInstance.patch(requestParams[0], requestParams[1]);
    },
    [apiInstance, buildRequestParams]
  );

  const post = useCallback(
    // eslint-disable-next-line
    (...args: any[]) => {
      const requestParams = buildRequestParams(args);
      return apiInstance.post(requestParams[0], requestParams[1]);
    },
    [apiInstance, buildRequestParams]
  );

  const postWithConfig = useCallback(
    // eslint-disable-next-line
    (config: any, ...args: any[]) => {
      const requestParams = buildRequestParams(args);
      return apiInstance.post(requestParams[0], requestParams[1], config);
    },
    [apiInstance, buildRequestParams]
  );

  const remove = useCallback(
    // eslint-disable-next-line
    (...args: any[]) => {
      const requestParams = buildRequestParams(args);
      return apiInstance.delete(requestParams[0], requestParams[1]);
    },
    [apiInstance, buildRequestParams]
  );

  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
  const get = useCallback((url = options.path) => apiInstance.get(url), [apiInstance, options.path]);

  // eslint-disable-next-line
  const buildError = (givenError: any) => {
    let errorResponse = {
      message: givenError.toJSON ? givenError.toJSON().message : 'An unexpected error appeared! Please try again later.',
      code: '0'
    };

    if (givenError.response) {
      errorResponse = {
        ...givenError.response.data,
        code: givenError.response.status
      };
    }
    return errorResponse;
  };

  // eslint-disable-next-line
  const logoutIfSessionInvalid = useCallback((data: any) => {
    const reason = data?.resource?.request?.reason;
    if (reason != null && reason.indexOf(INVALID_SESSION) >= 0) {
      // If we have an invalid session response, probabily the session expired
      // We force a page refresh to make the app call /user/auth_status and do
      // the actual logout.
      logout();
    }
  }, []);

  const tryTokenIfExpired = useCallback(
    // eslint-disable-next-line
    (interceptedError: any, data: any) => {
      const interceptedResponseData = JSON.stringify(data);
      const originalRequest = interceptedError.config;
      if (interceptedResponseData.indexOf(OPENID_TOKEN_EXPIRED) >= 0 && !originalRequest._retry) {
        const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
        if (refreshToken != null) {
          originalRequest._retry = true;

          // eslint-disable-next-line
          return apiInstance.post(OPENID_REFRESH_TOKEN_ENDPOINT, new RefreshTokenRequest()).then((responseRaw: any) => {
            const refreshResponse = jsonConvert.deserializeObject(responseRaw.data.resource, RefreshTokenResponse);
            localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, BEARER_PREFIX + refreshResponse.access_token);
            localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME, refreshResponse.refresh_token);

            // Add new Bearer token in request body
            const dataStr = originalRequest.data;
            // eslint-disable-next-line
            const originalData: any = JSON.parse(dataStr);
            data.user_session_id = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
            originalRequest.data = JSON.stringify(originalData);

            return apiInstance(originalRequest);
          });
        }
        return null;
      }
      return null;
    },
    [apiInstance, jsonConvert]
  );

  // eslint-disable-next-line
  const errorInterceptorHandler = useCallback(async (interceptedError: any) => {
    await rotateAndLogoutOnServerDown();

    const { response: interceptedResponse } = interceptedError;

    if (interceptedResponse != null) {
      const { data } = interceptedResponse;
      // If regular session check if invalid
      logoutIfSessionInvalid(interceptedResponse.data);

      // If oauth session refresh token if expired
      const shouldRefreshToken = tryTokenIfExpired(interceptedError, data);
      if (shouldRefreshToken !== null) {
        return shouldRefreshToken;
      }
    }

    setError(buildError(interceptedError));
    setLoading(false);
    return Promise.reject(interceptedError);
    // eslint-disable-next-line
  }, []);

  useMemo(() => {
    apiService.loadRequestInterceptor(
      () => {
        setLoading(true);
      },
      // eslint-disable-next-line
      () => {}
    );

    apiService.loadResponseInterceptor(
      (interceptedResponse: AxiosResponse) => {
        setError(null);
        setLoading(false);
        setResponse(interceptedResponse);
        setResponseData(interceptedResponse.data);
      },
      // eslint-disable-next-line
      (interceptedError: any) => errorInterceptorHandler(interceptedError)
    );
    // eslint-disable-next-line
  }, [apiService]);

  const initRequestOptions = useCallback(async () => {
    const method = options.method || 'GET';
    const load = options.load !== undefined ? options.load : true;

    if (load) {
      try {
        setLoading(true);
        setError(null);
        await apiInstance.request({
          url: options.path,
          data: options.requestData,
          method
        });
      } catch (optionsError) {
        setLoading(false);
        setError(buildError(optionsError));
      }
    }
  }, [apiInstance, options]);

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      initRequestOptions();
    }
  }, [options, initRequestOptions]);

  return {
    responseData,
    loading,
    response,
    error,
    put,
    get,
    post,
    postWithConfig,
    patch,
    remove
  };
};
