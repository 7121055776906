import { Tag } from 'antd';
import styles from './Visualisation.module.css';

import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import { FilterProperty } from './FilterProps';

export function FiltersClickable() {
  const visualizationContext = useVisualizationsContext();

  function removeFilter(filterKey: string) {
    // eslint-disable-next-line
    return (e: any) => {
      e.preventDefault();
      visualizationContext.removeClickableFilters(filterKey);
      visualizationContext.updateApplyTrigger();
    };
  }

  return (
    <>
      <div>
        {visualizationContext.state.clickableFilters.filters.map((filter: FilterProperty) => (
          <Tag key={filter.key} className={styles.filterClickable} closable onClose={removeFilter(filter.key)}>
            {filter.key}
            {': '}
            {filter.selected}
          </Tag>
        ))}
      </div>
    </>
  );
}
