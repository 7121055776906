import { Form, Select, Space, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { EmptyFolderPlusIcon } from '../../../../../../themes/icons';
import styles from './styles.module.css';
import { useJobAllRetrainHistory, useJobInfo, useJobMetrics } from '../../../../../../data-access';
import { ModelHistory } from '../../../../../shared/models/retrain-history/JobAllRetrainHistoryResponse';
import { useQueryClient } from 'react-query';
import { config } from '../../../../../../config';
import { Link } from 'react-router-dom';
import { RefreshButton } from '@arcanna/components';
import JobAiModelInsights from './JobAiModelInsights';
import { EmptyState } from '@arcanna/generic';
import JobAiModelAvanced from './JobAiModelAdvanced';

type JobPerformanceProps = {
  jobId: number;
};
export default function JobPerformance({ jobId }: JobPerformanceProps) {
  const { t } = useTranslation(['overview']);
  const [selectedModel, setSelectedModel] = useState<string | undefined>(undefined);
  const jobInfoQuery = useJobInfo(jobId);
  const modelHistoryQuery = useJobAllRetrainHistory(jobId);
  const queryClient = useQueryClient();
  const jobMetricsQuery = useJobMetrics(jobId, selectedModel);

  useEffect(
    function setInitialModel() {
      if (jobInfoQuery.data !== undefined && selectedModel === undefined) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        setSelectedModel(jobInfoQuery.data.info.processor.lastModelPath?.split('.')[0]);
      }
    },
    // eslint-disable-next-line
    [jobInfoQuery.data]
  );

  const refreshAllData = () => {
    queryClient.invalidateQueries(config.api.jobRetrainMetrics.replace(':jobId', jobId.toString()));
  };

  const missingFeedbackSessionMetricks =
    jobMetricsQuery.isSuccess &&
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    (!jobMetricsQuery.data.feedbackSessionMetrics.binaryConfusionMatrix.entries ||
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      !jobMetricsQuery.data.feedbackSessionMetrics.confusionMatrix.entries);

  return (
    <div className="flex flex-col">
      <div className="w-100 flex flex-sp flex-align-center m-b-16">
        <Typography.Title level={3} className="color-nb-0 m-b-0">
          {t('overview:performance')}
        </Typography.Title>
        <RefreshButton onRefresh={refreshAllData} />
      </div>

      <div className={styles.root}>
        <Form layout="vertical">
          <Form.Item label={t('overview:aiModel')}>
            <Select
              loading={modelHistoryQuery.isLoading}
              style={{ width: 400 }}
              value={selectedModel}
              onChange={(value: string) => setSelectedModel(value)}
              options={modelHistoryQuery.data?.models?.map((model: ModelHistory) => ({
                value: model.modelId,
                label: model.modelId
              }))}
            />
          </Form.Item>
        </Form>
        {selectedModel === undefined && (
          <EmptyState title={t('overview:noModelAvailable')} subtitle={t('overview:nothing')} isCard />
        )}
        {selectedModel !== undefined && missingFeedbackSessionMetricks && (
          <div className={styles.emptyTableRoot}>
            <EmptyFolderPlusIcon />

            <Space direction="vertical" size={4} className="flex flex-col flex-center flex-align-start">
              <span className="color-nb-100 body-1">{t('overview:startGivingFeedback')}</span>
              <Space size={3} align="center">
                <span className="body-2 color-nb-300">{t('overview:youCanGiveFeedbackFrom')}</span>
                <Link className="body-2 color-nb-0 underlined" to={config.routes.feedbackFlow.replace(':id', `${jobId}`)}>
                  {t('overview:feedbackSection')}
                </Link>
              </Space>
            </Space>
          </div>
        )}
        {selectedModel !== undefined && !missingFeedbackSessionMetricks && (
          <Tabs
            defaultActiveKey="insights"
            items={[
              {
                label: t('overview:insights'),
                key: 'insights',
                children: <JobAiModelInsights jobId={jobId} modelId={selectedModel} />
              },
              {
                label: t('overview:advanced'),
                key: 'advanced',
                children: <JobAiModelAvanced jobId={jobId} modelId={selectedModel || ''} />
              }
            ]}
          />
        )}
      </div>
    </div>
  );
}
