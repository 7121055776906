/* eslint-disable max-len */
function DotsDrag() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5.5" cy="3" r="1.5" fill="currentColor" />
      <circle cx="5.5" cy="8" r="1.5" fill="currentColor" />
      <circle cx="5.5" cy="13" r="1.5" fill="currentColor" />
      <circle cx="10.5" cy="3" r="1.5" fill="currentColor" />
      <circle cx="10.5" cy="8" r="1.5" fill="currentColor" />
      <circle cx="10.5" cy="13" r="1.5" fill="currentColor" />
    </svg>
  );
}

export default DotsDrag;
