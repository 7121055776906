import { Stack, styled } from '@mui/material';

const StyledErrorPage = styled(Stack)(({ theme }) => ({
  '.pageNotFound': {
    alignSelf: 'stretch',
    color: theme.palette.secondary[100],
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '48px',
    letterSpacing: '-0.2px'
  },

  '.homeButton': {
    borderRadius: '4px',
    padding: '14px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '24px',
    textTransform: 'none'
  },
  '.detailMessage': {
    fontWeight: 400,
    color: theme.palette.secondary[300]
  }
}));

export default StyledErrorPage;
