import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { type BatchTableSort } from '../../../pages/Main/Jobs/Feedback/Flow/FeedbackFlow.context';
import { FeedbackQuickFilters } from './FeedbackQuickFilters';

@JsonObject('FeedbackBatchRequest')
export class FeedbackBatchRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('size', Number)
  size?: number;

  @JsonProperty('from', Number)
  from?: number;

  @JsonProperty('sort_by_column', String)
  sort_by_column?: string;

  @JsonProperty('sort_order', Number)
  sort_order?: string;

  @JsonProperty('start_date', String)
  start_date?: string;

  @JsonProperty('end_date', String)
  end_date?: string;

  @JsonProperty('exclude_processed', Boolean)
  exclude_processed?: boolean;

  @JsonProperty('include_processed', Boolean)
  include_processed?: boolean;

  @JsonProperty('filters', Any)
  // eslint-disable-next-line
  filters?: any;

  @JsonProperty('quick_filters', FeedbackQuickFilters)
  quick_filters?: FeedbackQuickFilters = new FeedbackQuickFilters();

  @JsonProperty('decision_filters', [String])
  decision_filters?: string[] = [];

  @JsonProperty('consensus_filters', [String])
  consensus_filters?: string[] = [];

  constructor(
    job_id: number,
    size = 10,
    from = 0,
    start_date: string | undefined = undefined,
    end_date: string | undefined = undefined,
    exclude_processed = false,
    include_processed = false,
    tableSort: BatchTableSort = { column: 'timestamp', order: 'desc' },
    // eslint-disable-next-line
    filters: any = [],
    quick_filters: FeedbackQuickFilters = {},
    decision_filters: string[] = [],
    consensus_filters: string[] = []
  ) {
    super();
    this.job_id = job_id;
    this.size = size;
    this.from = from;
    this.start_date = start_date;
    this.end_date = end_date;
    this.exclude_processed = exclude_processed;
    this.include_processed = include_processed;
    this.sort_by_column = tableSort.column;
    this.sort_order = tableSort.order;
    this.filters = filters;
    this.quick_filters = quick_filters;
    this.decision_filters = decision_filters;
    this.consensus_filters = consensus_filters;
  }
}
