import { Col, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './JobOverviewMetrics.module.css';
import cx from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import TimeMetricsInfo from './TimeMetricsInfo';
import MedianDecisionTimeMetric from './MedianDecisionTimeMetric';
import TimeMetric from './TimeMetric';

type TimeMetricsFeedbackNeededProps = {
  jobId: number;
};

export default function TimeMetricsFeedbackNeeded({ jobId }: TimeMetricsFeedbackNeededProps) {
  const { t } = useTranslation(['job']);

  return (
    <Row>
      <Col className={'p-r-20'}>
        <TimeMetricsInfo jobId={jobId} />
      </Col>
      <Col className={'p-r-20 p-t-32'}>
        <TimeMetric
          text={t('job:jobTimeMetrics:timeSavedByArcanna')}
          tooltipText={t('job:jobTimeMetrics:timeSavedByArcannaInfo')}
        />
        <Row>
          <div className={cx([style.noTrainModelWarn])}>
            <Space align="start">
              <ExclamationCircleOutlined className={style.exclamationCircle} />
              <Typography.Text className="color-nb-200 caption">{t('job:jobTimeMetrics:emptyStateFirstTrain')}</Typography.Text>
            </Space>
          </div>
        </Row>
      </Col>
      <Col className={'p-r-20 p-t-32'}>
        <TimeMetric text={t('job:jobTimeMetrics:timeGainedBack')} tooltipText={t('job:jobTimeMetrics:timeGainedBackInfo')} />
        <Row>
          <div className={cx([style.noTrainModelWarn])}>
            <Space align="start">
              <ExclamationCircleOutlined className={style.exclamationCircle} />
              <Typography.Text className="color-nb-200 caption">{t('job:jobTimeMetrics:emptyStateFirstTrain')}</Typography.Text>
            </Space>
          </div>
        </Row>
      </Col>
      <MedianDecisionTimeMetric jobId={jobId} />
    </Row>
  );
}
