import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { BucketsRequiringAttentionRequest } from '../../components/shared/models/retrain-statistics/BucketsRequiringAttentionRequest';
import { BucketsRequiringAttentionResponse } from '../../components/shared/models/retrain-statistics/BucketsRequiringAttentionResponse';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

export function useBucketsRequiringAttention(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery([config.api.bucketsRequiringFeedback, jobId], () =>
    axios
      .post<{
        resource: BucketsRequiringAttentionResponse;
      }>(config.api.bucketsRequiringFeedback, new BucketsRequiringAttentionRequest(jobId))
      .then(
        (
          responseData: AxiosResponse<{
            resource: BucketsRequiringAttentionResponse;
          }>
        ) => jsonConvert.deserializeObject(responseData.data.resource, BucketsRequiringAttentionResponse)
      )
  );
}
