import { useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import { useUtils } from '../../hooks/useUtils';

export interface MetricProps {
  value: number;
  label: string;
  // eslint-disable-next-line
  style: any;
  tooltip?: string;
}

export function Metric(props: MetricProps) {
  const { overrideOwnStyleAttributes } = useUtils();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const tooltipWidth = 200;

  // eslint-disable-next-line
  const [ownStyleAttributes, setOwnStyleAttributes] = useState<any>({
    backgroundColor: 'transparent',
    color: '#f16e00',
    descriptionColor: 'rgba(255, 255, 255, 0.65)',
    paddingTop: '24px'
  });

  useMemo(() => {
    if (props.style !== undefined) {
      if (props.style.colorMap != null) {
        props.style.color = props.style.colorMap[props.label];
      }

      overrideOwnStyleAttributes(ownStyleAttributes, props);
      setOwnStyleAttributes(ownStyleAttributes);
    }
    // eslint-disable-next-line
  }, [props.style]);

  // Position the tooltip on screen depending on the position of the cursor.
  // This does a DOM analysis and a change based on the size of the parents.
  function handleTooltipPosition() {
    const elements = document.getElementsByClassName('metric-tooltip') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      const rect = element.parentElement.parentElement.getBoundingClientRect();
      let offsetLeft = 0;
      if (rect.x + rect.width + tooltipWidth > window.outerWidth) {
        offsetLeft = -(tooltipWidth / 2);
      } else {
        offsetLeft = Math.round(rect.width / 2);
      }
      element.style.setProperty('left', `${offsetLeft}px`);
    }
  }

  function onMetricHover() {
    if (props.tooltip != null) {
      handleTooltipPosition();
      setIsHovered(true);
    }
  }

  function onMetricHoverEnd() {
    setIsHovered(false);
  }

  return (
    <>
      <div
        style={{
          backgroundColor: ownStyleAttributes.backgroundColor,
          paddingTop: ownStyleAttributes.paddingTop,
          height: ownStyleAttributes.height,
          cursor: 'default'
        }}
        onMouseEnter={onMetricHover}
        onMouseLeave={onMetricHoverEnd}
      >
        <Row>
          <Col span={24} className="flex flex-center flex-col flex-align-center">
            <h2 style={{ color: ownStyleAttributes.color, fontSize: ownStyleAttributes.fontSize }}>{props.value}</h2>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="flex flex-center flex-col flex-align-center">
            <span style={{ color: ownStyleAttributes.descriptionColor, fontSize: ownStyleAttributes.fontSize }}>
              {props.label}
            </span>
          </Col>
        </Row>
        {props.tooltip != null && (
          <div className="metric-tooltip" style={{ display: isHovered ? 'block' : 'none' }}>
            {props.tooltip}
          </div>
        )}
        <br />
      </div>
    </>
  );
}
