import { useState, MouseEvent, ReactNode } from 'react';
import { MenuItem, MenuList } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import StyledMenuDropdown from './StyledMenuDropdown.styles';
import { ChevronDownIcon, ChevronUpIcon } from '../../../../themes/icons';

type TMenuItem = {
  onClick?: () => void;
  label: ReactNode;
  className?: string;
  isDisabled?: boolean;
};

type TMenuDropdownProps = {
  children: ReactNode;
  menuItems: TMenuItem[];
  hasIcon?: boolean;
  className?: string;
  anchorXPosition?: 'left' | 'right';
};

function MenuDropdown({ children, menuItems, hasIcon = true, className, anchorXPosition = 'right' }: TMenuDropdownProps) {
  const [menuId] = useState<string>(`menu-${uuidv4()}`);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event: MouseEvent) {
    if (anchorEl !== event.currentTarget) {
      // @ts-expect-error TS(2345): Argument of type 'EventTarget & Element' is not as...
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const isOpen = Boolean(anchorEl) && Boolean(menuItems?.length);

  return (
    <StyledMenuDropdown isActive={isOpen} onMouseLeave={handleClose} className={className} anchorXPosition={anchorXPosition}>
      <div className="menu-dropdown-trigger" onMouseOver={handleClick}>
        {children}
        {hasIcon && <>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</>}
      </div>
      <MenuList>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={`${menuId}-item-${index}`}
            onClick={menuItem.onClick}
            className={menuItem.className}
            disabled={menuItem.isDisabled}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </MenuList>
    </StyledMenuDropdown>
  );
}

export default MenuDropdown;
