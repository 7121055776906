import * as React from 'react';

import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { IntegrationSubcategoryListRequest } from '../../components/shared/models/integration/subcategory/IntegrationSubcategoryListRequest';
import { IntegrationSubcategoryListResponse } from '../../components/shared/models/integration/subcategory/IntegrationSubcategoryListResponse';
import { IntegrationTypes } from '../../components/shared/models/integration/IntegrationTypes';
import { JsonConvert } from 'json2typescript';

export function getIntegrationParametersUrl(subcategoryId: number) {
  return config.api.integrationSubcategoryList.replace(':subcategoryId', `${subcategoryId}`);
}

export function getIntegrationParametersQueryKey(subcategoryId: number) {
  const url = getIntegrationParametersUrl(subcategoryId);
  return [url];
}

export function fetchIntegrationParameters(url: string, jsonConvert: JsonConvert) {
  return axios
    .post<{ resource: IntegrationSubcategoryListResponse }>(url, new IntegrationSubcategoryListRequest(1, true, undefined, false))
    .then((responseData: AxiosResponse<{ resource: IntegrationSubcategoryListResponse }>) =>
      jsonConvert.deserializeObject(responseData.data.resource, IntegrationSubcategoryListResponse)
    );
}

type UseIntegrationSubcategoryList = {
  integrationType: IntegrationTypes;
  subcategoryId: number | undefined;
  isValid: boolean | undefined;
};
export function useIntegrationParameters({ subcategoryId, integrationType, isValid }: UseIntegrationSubcategoryList) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<IntegrationSubcategoryListResponse, Error>(
    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
    getIntegrationParametersQueryKey(subcategoryId),
    // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
    () => fetchIntegrationParameters(getIntegrationParametersUrl(subcategoryId), jsonConvert),
    {
      enabled: Boolean(subcategoryId) && integrationType !== IntegrationTypes.OUTPUT && isValid
    }
  );
}
