import { EIcon, Icon } from '@arcanna/generic';
import { Button, ButtonProps } from '@mui/material';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

type TDocsButtonProps = {
  url: string;
  buttonProps?: ButtonProps;
  buttonTitle?: string;
};

function DocsButton({ url, buttonProps, buttonTitle }: TDocsButtonProps) {
  const { t } = useTranslation(['common']);

  return (
    <Button
      size="small"
      variant="contained"
      color="secondary"
      onClick={() => window.open(url, '_blank')}
      endIcon={<Icon name={EIcon.LinkExternal01} fontSize="inherit" />}
      {...buttonProps}
    >
      {buttonTitle ?? t('common:docs')}
    </Button>
  );
}

export default memo(DocsButton);
