import { RetrainButton } from '../../../../../../shared/components';
import styles from './styles.module.css';

type JobOverviewActionsProps = {
  jobId: number;
};
export function JobOverviewActions({ jobId }: JobOverviewActionsProps) {
  return (
    <div className={styles.root}>
      <RetrainButton jobId={jobId} />
    </div>
  );
}
