/* eslint-disable max-len */
function RadioUncheckedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="0.833252" y="0.5" width="19" height="19" rx="9.5" stroke="#2A3247" />
    </svg>
  );
}

export default RadioUncheckedIcon;
