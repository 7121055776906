import * as React from 'react';
import { useQuery } from 'react-query';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { MigrationStatusResponse } from '../../components/shared/models/migration/MigrationStatusResponse';
import { MigrationStatus } from '../../components/shared/models/migration/MigrationStatus';
import { JsonConvert } from 'json2typescript';
import axios from 'axios';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';

const MIGRATION_REFRESH_INTERVAL_MILLISECONDS = 10000;

export async function fetchMigrationStatus(jsonConvert: JsonConvert) {
  const responseData = await axios.post<{ resource: JobInfoResponse }>(config.api.migrationStatus, new CommonRequest());
  return jsonConvert.deserializeObject(responseData.data.resource, MigrationStatusResponse);
}

export function useMigrationStatus() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<MigrationStatusResponse, Error, MigrationStatus | null>(
    [config.api.migrationStatus],
    () => fetchMigrationStatus(jsonConvert),
    {
      refetchInterval: (migrationStatus: MigrationStatus | null | undefined) => {
        if (migrationStatus?.upgradeInProgress) {
          return MIGRATION_REFRESH_INTERVAL_MILLISECONDS;
        }
        return false;
      },
      select: (data: MigrationStatusResponse) => {
        return data.migrationStatus || null;
      }
    }
  );
}
