import { useJobInfo } from '../../../../../../data-access';
import { useJobRetrainHistory } from '../../../../../../data-access/model-history';
import { useEffect, useState } from 'react';
import { DecisionBreakdown } from '../../../../../shared/models/retrain-history/JobAllRetrainHistoryResponse';
import { useTranslation } from 'react-i18next';

export class DecisionBreakdownCardProps extends DecisionBreakdown {
  eventsPercentage: number;
  hexColor: string;

  constructor(events: number, buckets: number, label: string, eventsPercentage: number, hexColor: string) {
    super(events, buckets, label);
    this.eventsPercentage = eventsPercentage;
    this.hexColor = hexColor;
  }
}

export default function useDecisionBreakdown(jobId: number, modelId: string) {
  const { t } = useTranslation(['overview']);

  const [decisionBreakdown, setDecisionBreakdown] = useState<DecisionBreakdownCardProps[]>([]);

  const modelHistoryQuery = useJobRetrainHistory(jobId, modelId);
  const jobInfoQuery = useJobInfo(+jobId);

  useEffect(() => {
    const modelHistoryDecisionBreakdown = modelHistoryQuery.data?.decisionBreakdown;
    let totalEvents = 0;
    let totalBuckets = 0;

    if (!modelHistoryDecisionBreakdown) {
      return;
    }

    if (modelHistoryDecisionBreakdown.length === 0 || !jobInfoQuery.data) {
      setDecisionBreakdown(
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        jobInfoQuery.data?.info.advancedSettings.customLabels.map((label) => {
          return new DecisionBreakdownCardProps(0, 0, label.name, 0, label.hexColor);
        })
      );
      return;
    }

    modelHistoryDecisionBreakdown.forEach((decision) => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      totalEvents += decision.events;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      totalBuckets += decision.buckets;
    });

    setDecisionBreakdown([
      new DecisionBreakdownCardProps(totalEvents, totalBuckets, t('all'), 100, 'var(--secondary-color-nb-800)'),
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      ...jobInfoQuery.data.info.advancedSettings.customLabels.map((label) => {
        const decisionBreakdownForLabel = modelHistoryDecisionBreakdown.find((decision) => decision.label === label.id);
        return new DecisionBreakdownCardProps(
          // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
          decisionBreakdownForLabel?.events,
          decisionBreakdownForLabel?.buckets,
          label.name,
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          (decisionBreakdownForLabel?.events / totalEvents) * 100,
          label.hexColor
        );
      })
    ]);
    // eslint-disable-next-line
  }, [modelHistoryQuery.data, jobInfoQuery.data]);

  return { decisionBreakdown };
}
