import { JsonProperty } from 'json2typescript';
import { LOCAL_STORAGE_TOKEN_NAME } from '../../../pages/Main/Login/AuthConstants';

export class CommonRequest {
  @JsonProperty('user_session_id', String)
  user_session_id?: string | undefined;

  constructor() {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    this.user_session_id = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
  }
}
