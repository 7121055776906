import { Stack, Switch, Typography, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import useRollbackInProgress from 'src/components/pages/Main/Jobs/OverviewRevamp/JobSummary/useRollbackInProgress';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import { JobStateEnum } from 'src/components/shared/models';
import { EJobTrainingState } from 'src/components/shared/models/job/EJobTrainingState';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import { config } from 'src/config';
import { useJobInfo } from 'src/data-access';
import { JobStatusAction, useJobChangeStatus } from 'src/data-access/job/useJobChangeStatus';

type TJobStatusToggleProps = {
  jobId: number;
};

function JobStatusToggle({ jobId }: TJobStatusToggleProps) {
  const { t } = useTranslation(['job']);
  const { palette } = useTheme();
  const queryClient = useQueryClient();

  const { data: jobInfo, isLoading } = useJobInfo(jobId);
  const { mutateAsync: changeJobStatusAsync } = useJobChangeStatus(jobId);

  const [optimisticJobStatus, setOptimisticJobStatus] = useState<boolean | null>(null);

  const currentJobStatus = useMemo(() => jobInfo?.info?.status?.state ?? '', [jobInfo]);

  const isJobEnabled = useMemo(
    () =>
      optimisticJobStatus !== null
        ? optimisticJobStatus
        : currentJobStatus === JobStateEnum.ENABLED ||
          currentJobStatus === JobStateEnum.COLLECTING_DATA ||
          currentJobStatus === JobStateEnum.READY_TO_SELECT_FEATURES,
    [currentJobStatus, optimisticJobStatus]
  );
  const status = useMemo(() => (isJobEnabled ? t('job:activeStatus') : t('job:inactiveStatus')), [isJobEnabled, t]);

  const handleToggle = useCallback(async () => {
    setOptimisticJobStatus(!isJobEnabled);
    const action = isJobEnabled ? JobStatusAction.STOP : JobStatusAction.START;
    await changeJobStatusAsync(action, {
      onError: () => {
        setOptimisticJobStatus(null);
      }
    });
    await queryClient.invalidateQueries([
      config.api.jobInfo.replace(':id', `${jobId}`),
      config.api.jobInfo.replace(':id', `${jobId}-status`)
    ]);
  }, [isJobEnabled, queryClient, jobId, changeJobStatusAsync]);

  const { isRollbackInProgress } = useRollbackInProgress({ jobId });
  const isMigrationAutomaticRetrainRunning = useMemo(
    () => jobInfo?.info?.status?.retrain_state === EJobTrainingState.AUTOMATIC_RETRAIN,
    [jobInfo]
  );

  const isToggleDisabled = useMemo(
    () => isLoading || isRollbackInProgress || isMigrationAutomaticRetrainRunning,
    [isLoading, isRollbackInProgress, isMigrationAutomaticRetrainRunning]
  );

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography variant="subtitle2" color={palette.secondary[300]} mr="8px">
        {t('job:statusLabel')}
      </Typography>
      <PermissionsCheck permissions={[permissions.jobAction]} action={permissions.action.disabledAction}>
        <Stack flexDirection="row" alignItems="center" justifyContent="center">
          <Switch
            size="small"
            checked={isJobEnabled}
            onChange={handleToggle}
            disabled={isToggleDisabled}
            data-test-id="job-status-toggle"
          ></Switch>
        </Stack>
      </PermissionsCheck>
      <Typography
        variant="subtitle2"
        color={isJobEnabled ? palette.success.light : palette.secondary[300]}
        width="54px"
        textAlign="right"
      >
        {status}
      </Typography>
    </Stack>
  );
}

export default JobStatusToggle;
