import { ConfirmPopover } from '@arcanna/generic';
import { Button, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDPUnifiedContext } from '../DPUnifiedContext/useDPUnifiedContext';

function DPUnifiedSaveButton() {
  const anchorRef = useRef(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const { t } = useTranslation(['decisionPointsUnified']);
  const { handleUpdateDP, isUpdateDisabled, isUpdateLoading } = useDPUnifiedContext();

  const handleClick = () => {
    setIsConfirmOpen((prev) => !prev);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleClick}
        color="primary"
        variant="contained"
        size="small"
        disabled={isUpdateDisabled || isUpdateLoading}
      >
        {t('decisionPointsUnified:saveButton.text')}
      </Button>
      <ConfirmPopover
        anchor={isConfirmOpen ? anchorRef?.current : null}
        onConfirm={handleUpdateDP}
        onClose={() => setIsConfirmOpen(false)}
        text={
          <Stack>
            <span>{t('decisionPointsUnified:saveButton.warningTitle')}</span>
            <Stack component="ul" sx={{ paddingLeft: 2 }}>
              <li>{t('decisionPointsUnified:saveButton.warningReason1')}</li>
              <li>{t('decisionPointsUnified:saveButton.warningReason2')}</li>
              <li>{t('decisionPointsUnified:saveButton.warningReason3')}</li>
            </Stack>
            <span>{t('decisionPointsUnified:saveButton.warningQuestion')}</span>
          </Stack>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </>
  );
}

export default DPUnifiedSaveButton;
