import { JsonObject, JsonProperty } from 'json2typescript';

export type TLLMTextGenerationState = 'automatic' | 'disabled' | 'on_demand';

export enum ETextGenerationState {
  AUTOMATIC = 'automatic',
  DISABLED = 'disabled',
  ON_DEMAND = 'on_demand'
}

@JsonObject('BucketsSummarization')
export class BucketsSummarization {
  @JsonProperty('text_generation', String)
  text_generation: TLLMTextGenerationState | null;
}

@JsonObject('LLMSummarization')
export class LLMSummarization {
  @JsonProperty('buckets', BucketsSummarization)
  buckets: BucketsSummarization | null;
}

@JsonObject('LLMSettings')
export class LLMSettings {
  @JsonProperty('summarization', LLMSummarization)
  summarization: LLMSummarization | null;
}
