import { useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { Barchart, BarChartProps } from '../Visualisation/Barchart';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useVisualizationsContext } from './Visualizations.context';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';
import { LegendTooltipProvider } from '../Tooltips/LegendTooltip';
import { useUtils } from '../../hooks/useUtils';

// eslint-disable-next-line
export function extractTooltips(slices: Array<any>) {
  const tooltips: { [id: string]: string } = {};
  slices.forEach((slice) => {
    const { key } = slice;
    for (const prop in slice) {
      if (slice[prop].buckets != null) {
        if (slice[prop].buckets.length > 0) {
          tooltips[key] = `${prop}: ${slice[prop].buckets[0].key}`;
        } else {
          tooltips[key] = `${prop}: N/A`;
        }
      }
    }
  });
  return tooltips;
}

export function QueryBarChart(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const [barData, setBarData] = useState<BarChartProps>(undefined);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const utils = useUtils();

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    let slices: Array<any> = [];
    for (const key in aggregations) {
      slices = slices.concat(aggregations[key].buckets);
    }

    // eslint-disable-next-line
    const data: Array<any> = [];
    const keys: Array<string> = [];
    slices.forEach((slice) => {
      // eslint-disable-next-line
      const element = {} as any;
      const { key } = slice;
      if (slice.slice_count) {
        element.name = key;
        element[key] = slice.slice_count.value;
      } else {
        element.name = key;
        element[key] = slice.doc_count;
      }
      data.push(element);
      keys.push(key);
    });

    const colors = utils.nColorArray(data.length, visualizationRecord.style['color-seed']);

    const tooltips = extractTooltips(slices);

    setBarData({
      data,
      keys,
      colors,
      style: props.style,
      filterFields: VisualizationRecord.getFilterFieldsFromQuery(visualizationRecord.query, []),
      tooltips
    });
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [setBarData, visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <p>&nbsp;</p>
        <LegendTooltipProvider>
          <Barchart {...barData} />
        </LegendTooltipProvider>
      </Spin>
    </>
  );
}
