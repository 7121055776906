import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { FeedbackStatisticsBucketsCountRequest } from '../../components/shared/models/feedback/FeedbackStatisticsBucketsCountRequest';
import { FeedbackStatisticsBucketsCountResponse } from '../../components/shared/models/feedback/FeedbackStatisticsBucketsCountResponse';
import { JobTrainingStatusBreakdownEntry } from '../../components/shared/models/job/JobTrainingStatusBreakdownEntry';

export function useFeedbackBucketsCount(jobId: number, trainingStatusBreakdown: JobTrainingStatusBreakdownEntry[]) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const requestPayload = new FeedbackStatisticsBucketsCountRequest(jobId);

  return useQuery(
    [config.api.feedbackStatisticsBucketsCount, requestPayload],
    () =>
      axios
        .post<{
          resource: FeedbackStatisticsBucketsCountResponse;
        }>(config.api.feedbackStatisticsBucketsCount, requestPayload)
        .then((responseData: AxiosResponse<{ resource: FeedbackStatisticsBucketsCountResponse }>) =>
          jsonConvert.deserializeObject(responseData.data?.resource, FeedbackStatisticsBucketsCountResponse)
        ),
    {
      enabled: Boolean(jobId) && !trainingStatusBreakdown
    }
  );
}
