import { createContext, useContext, useState } from 'react';
import { CsvSummaryRecord } from '../../../shared/models/csv-editor/CsvSummaryRecord';

// eslint-disable-next-line
export const CsvContext = createContext<CsvContextType>(null as any);

export type CsvStateType = {
  id: number;
  loading: boolean;
  reloadCsvData: boolean;
  selectedCsv: CsvSummaryRecord;
  navigateTo: string;
};

export type CsvContextType = {
  state: CsvStateType;
  setLoading: (loading: boolean) => void;
  setReloadCsvData: (reloadCsvData: boolean) => void;
  setSelectedCsv: (selectedCsv: CsvSummaryRecord, navigateTo: string) => void;
  setNavigateTo: (navigateTo: string) => void;
};

// eslint-disable-next-line
export function CsvProvider(props: any) {
  const [state, setState] = useState<CsvStateType>({
    id: Math.random(),
    loading: false,
    reloadCsvData: false,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'CsvSummaryR...
    selectedCsv: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    navigateTo: null
  });

  const setLoading = (loading: boolean) => {
    setState({
      ...state,
      loading
    });
  };

  const setReloadCsvData = (reloadCsvData: boolean) => {
    setState({
      ...state,
      reloadCsvData
    });
  };

  const setSelectedCsv = (selectedCsv: CsvSummaryRecord, navigateTo: string) => {
    setState({
      ...state,
      selectedCsv,
      navigateTo
    });
  };

  const setNavigateTo = (navigateTo: string) => {
    setState({
      ...state,
      navigateTo
    });
  };

  const value: CsvContextType = {
    state,
    setLoading,
    setReloadCsvData,
    setSelectedCsv,
    setNavigateTo
  };

  const { children } = props;
  return <CsvContext.Provider value={value}>{children}</CsvContext.Provider>;
}

export function useCsvContext(): CsvContextType {
  return useContext(CsvContext);
}
