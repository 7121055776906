import { Palette } from '@mui/material/styles';
import { TFunction } from 'i18next';
import { EIcon } from '@arcanna/generic';
import { BucketTrainingStatus } from 'src/constants/BucketTrainingStatus';

const CONFIDENCE_SCORE_THRESHOLDS = {
  LOW: 50,
  MEDIUM: 75
};

const getConfidenceScoreColor = (palette: Palette, score: number) => {
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.LOW) return palette.error[400];
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.MEDIUM) return palette.warning[400];
  return palette.success[300];
};

const getConfidenceScoreLabel = (t: TFunction, score: number) => {
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.LOW) return t('feedback:confidenceScoreLow');
  if (score <= CONFIDENCE_SCORE_THRESHOLDS.MEDIUM) return t('feedback:confidenceScoreMedium');
  return t('feedback:confidenceScoreHigh');
};

const getIconName = (noAttentionRequired: boolean, confidenceScore: number, trainingStatus: BucketTrainingStatus) => {
  if (noAttentionRequired && trainingStatus === BucketTrainingStatus.IN_MODEL) return null;
  // edge case when the default value of confidence_score without a model is 0.0
  if (noAttentionRequired && confidenceScore === 0) return null;
  if (!noAttentionRequired || confidenceScore <= CONFIDENCE_SCORE_THRESHOLDS.MEDIUM) return EIcon.AlertTriangle;
  return EIcon.FaceSmile;
};

export { CONFIDENCE_SCORE_THRESHOLDS, getConfidenceScoreColor, getConfidenceScoreLabel, getIconName };
