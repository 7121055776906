import { useCallback, useMemo } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Avatar, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getStringInitials } from '@arcanna/utils';
import { useAuth } from 'src/components/pages/Main/Login/Auth.context';
import { KeyIcon, SettingsIcon } from 'src/themes/icons';
import { useApp } from 'src/components/App/App.context';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import { Link } from 'react-router-dom';
import { config } from 'src/config';
import { EIcon, Icon } from '@arcanna/generic';

export const useUserMenuItems = () => {
  const { t } = useTranslation(['user', 'userManagement', 'apiKeys']);
  const { hasAccessFor } = useApp();
  const { state: authState, setIsLoggingOut, logout } = useAuth();

  const logoutUser = useCallback(async () => {
    if (authState.readyToLogOut) {
      logout();
    } else {
      setIsLoggingOut(true);
    }
  }, [authState.readyToLogOut, logout, setIsLoggingOut]);

  const menuItems = useMemo(
    () => [
      {
        label: (
          <Stack direction="row" alignItems="center" gap={1}>
            <Avatar sx={{ height: '32px', width: '32px' }}>{getStringInitials(authState.customerName)}</Avatar>
            <span>{authState.customerName}</span>
          </Stack>
        ),
        permissions: [],
        isDisabled: true
      },
      {
        label: (
          <Link to={config.routes.apiKeys}>
            <Stack direction="row" alignItems="center" gap={1}>
              <KeyIcon data-test-id="api-keys" />
              <span>{t('apiKeys:title')}</span>
            </Stack>
          </Link>
        ),
        permissions: [permissions.userKeyRead, permissions.userKeyWrite]
      },
      {
        label: (
          <Link to={config.routes.userManagement}>
            <Stack direction="row" alignItems="center" gap={1}>
              <TeamOutlined style={{ fontSize: '20px' }} />
              <span>{t('userManagement:title')}</span>
            </Stack>
          </Link>
        ),
        permissions: [permissions.userRead]
      },
      {
        label: (
          <Link to={config.routes.platformManagement}>
            <Stack direction="row" alignItems="center" gap={1}>
              <SettingsIcon data-test-id="platform-mgt" />
              <span>{t('user:platformManagement')}</span>
            </Stack>
          </Link>
        ),
        permissions: [permissions.licenseUpdate]
      },
      {
        label: (
          <Link to={config.routes.monitoring}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Icon name={EIcon.Activity} />
              <span>{t('user:platformMonitoring')}</span>
            </Stack>
          </Link>
        ),
        permissions: [permissions.userRead]
      },
      {
        label: (
          <Stack direction="row" alignItems="center" gap={1}>
            <span data-test-id="logout">{t('user:logout')}</span>
          </Stack>
        ),
        onClick: () => logoutUser(),
        permissions: [],
        className: 'MuiMenuItem-root--simple'
      }
    ],
    [t, authState.customerName, logoutUser]
  );

  return useMemo(() => menuItems.filter((menuItem) => hasAccessFor(menuItem.permissions as string[])), [menuItems, hasAccessFor]);
};
