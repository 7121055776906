import { Spinner } from '@arcanna/generic';
import { ELinkGeneratorPages } from '../RedirectLinkGenerator.types';
import { useHistory } from 'react-router-dom';
import { useEventClusterId } from '../hooks/useEventClusterId';
import { useEffect } from 'react';
import {
  constructClusterClusterIdRedirectURL,
  constructExplorerClusterIdRedirectURL,
  constructExplorerEventIdRedirectURL
} from '../RedirectLinkGenerator.utils';

type TRedirectRCAFromJobIdAndEventIdProps = {
  jobId: string;
  pageId: ELinkGeneratorPages;
  eventId: string;
};

function RedirectRCAFromJobIdAndEventId({ jobId, pageId, eventId }: TRedirectRCAFromJobIdAndEventIdProps) {
  const history = useHistory();

  const { clusterId } = useEventClusterId(eventId, jobId);

  useEffect(() => {
    if (!clusterId) {
      return;
    }

    if (pageId === ELinkGeneratorPages.clusterWithClusterId) {
      return history.replace(constructClusterClusterIdRedirectURL({ jobId: jobId, clusterId }));
    }

    if (pageId === ELinkGeneratorPages.explorerWithClusterId) {
      return history.replace(constructExplorerClusterIdRedirectURL({ jobId: jobId, clusterId }));
    }

    if (pageId === ELinkGeneratorPages.explorerWithEventId) {
      return history.replace(constructExplorerEventIdRedirectURL({ jobId: jobId, eventId }));
    }
  }, [pageId, jobId, history, clusterId, eventId]);

  return <Spinner isOverlay />;
}

export default RedirectRCAFromJobIdAndEventId;
