import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { JobInfoRecord } from './JobInfoRecord';

@JsonObject('JobInfoResponse')
export class JobInfoResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('info', JobInfoRecord)
  info?: JobInfoRecord = undefined;
}
