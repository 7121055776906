import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../../common/CommonRequest';

@JsonObject('IntegrationSubcategoryListRequest')
export class IntegrationSubcategoryListRequest extends CommonRequest {
  @JsonProperty('event_count', Number)
  event_count?: number = undefined;

  @JsonProperty('edit', Boolean)
  edit?: boolean = undefined;

  @JsonProperty('search_phrase', String)
  search_phrase?: string = '';

  @JsonProperty('exclude_creatable', Boolean)
  exclude_creatable?: boolean = undefined;

  constructor(eventCount: number, edit: boolean, search_phrase?: string, exclude_creatable?: boolean) {
    super();
    this.event_count = eventCount;
    this.edit = edit;
    this.search_phrase = search_phrase;
    this.exclude_creatable = exclude_creatable;
  }
}
