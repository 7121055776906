import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CommonSortedRequest')
export class CommonSortedRequest {
  @JsonProperty('sort_by', String)
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  sort_by: string = null;

  @JsonProperty('order', String)
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  order: string = null;

  constructor(sort_by: string, order: string) {
    this.sort_by = sort_by;
    this.order = order;
  }
}
