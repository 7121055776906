import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { JobCreationRequest } from '../../components/shared/models/job/JobCreationRequest';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

type Response = AxiosResponse<{ resource: { request: CommonResponse } }>;

export function useCreateJob() {
  const { t } = useTranslation(['job', 'common']);
  const queryClient = useQueryClient();
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useMutation<Response, Response, JobCreationRequest>(
    (payload: JobCreationRequest) =>
      axios.post<{
        resource: { request: CommonResponse };
      }>(config.api.jobCreate, payload),
    {
      onSuccess: (responseData: Response) => {
        if (!responseData.data) {
          return;
        }
        if (!responseData.data.resource) {
          showErrorNotification(t('common:actionCompleted'), t('job:createError'));
          return;
        }

        const jobCreateResponse = jsonConvert.deserializeObject(responseData.data.resource.request, CommonResponse);

        if (jobCreateResponse.status === 'OK') {
          showSuccessNotification(t('common:actionCompleted'), t('job:createdSuccessfully'));
        } else if (jobCreateResponse.status === 'STARTING_FAILED') {
          showErrorNotification(t('common:actionCompleted'), t('job:startedError') + jobCreateResponse.reason);
        } else {
          showErrorNotification(t('common:actionCompleted'), t('job:createError') + jobCreateResponse.reason);
        }
      },
      onError: (error: AxiosResponse) => {
        if (error?.data?.resource?.request?.reason) {
          const jobCreateResponse = jsonConvert.deserializeObject(error?.data?.resource?.request, CommonResponse);
          if (jobCreateResponse.reason) {
            showErrorNotification(t('common:error'), jobCreateResponse.reason);
            return;
          }
        }
        showErrorNotification(t('common:error'), t('job:createError'));
      },
      onSettled: () => {
        queryClient.invalidateQueries([config.api.jobsSummary]);
      }
    }
  );
}
