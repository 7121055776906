import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobOverviewSplitStatsTotal')
export class JobOverviewSplitStatsTotal {
  @JsonProperty('alerts_count', Number)
  alerts_count: number;

  @JsonProperty('count', Number)
  count: number;
}
