import styles from './DecisionBreakdownCard.module.css';
import { Card, Col, Row, Typography } from 'antd';
import { ReactNode } from 'react';
import cx from 'classnames';
import { formatNumber } from '../../../../../../utils/numeral';
import { useTranslation } from 'react-i18next';

type DecisionBreakdownCardProps = {
  label: ReactNode;
  events: number;
  eventsPercentage: number;
  buckets: number;
  borderColor: string;
};
export default function DecisionBreakdownCard({
  label,
  events,
  eventsPercentage,
  buckets,
  borderColor
}: DecisionBreakdownCardProps) {
  const { t } = useTranslation(['overview']);
  return (
    <Card
      style={{ border: `1px solid ${borderColor}` }}
      bodyStyle={{
        padding: '16px 8px',
        width: 255,
        height: 110
      }}
    >
      <Row className="m-b-12">
        <Col span={24}>{label}</Col>
      </Row>
      <Row gutter={[8, 0]}>
        <Col className={styles.eventsValue}>
          <Typography.Text className={styles.metricsTitle}>{t('events')}</Typography.Text>
          <Typography.Text className={cx(styles.metricsValue)}>{formatNumber(events)}</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.metricsTitle}>{t('evPercentage')}</Typography.Text>
          <Typography.Text className={cx(styles.metricsValue)}>{formatNumber(eventsPercentage, 2)}%</Typography.Text>
        </Col>
        <Col>
          <Typography.Text className={styles.metricsTitle}>{t('buckets')}</Typography.Text>
          <Typography.Text className={cx(styles.metricsValue)}>{formatNumber(buckets)}</Typography.Text>
        </Col>
      </Row>
    </Card>
  );
}
