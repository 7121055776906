import { Drawer, DrawerProps, styled } from '@mui/material';

const StyledDrawer = styled(Drawer)<DrawerProps & { width: number | string; type: 'light' | 'dark' }>(
  ({ theme, width, type }) => ({
    '& > .MuiPaper-root': {
      width: width,
      borderLeft: `1px solid ${theme.palette.secondary[700]}`,
      height: '100vh',
      overflow: 'unset',
      display: 'flex',
      flexDirection: 'column'
    },
    '& .drawerHeader': {
      padding: '0 76px 0 16px',
      gap: '24px',
      display: 'flex',
      alignItems: 'center',
      height: '63px',
      backgroundColor: type === 'dark' ? theme.palette.secondary[1000] : theme.palette.secondary[900],
      borderBottom: `1px solid ${theme.palette.secondary[800]}`,
      color: theme.palette.secondary[200]
    },
    '& .closeButton': {
      width: '32px',
      height: '32px',
      minWidth: '32px',
      padding: '0'
    },
    '& .drawerBody': {
      padding: '14px 76px 0px 16px',
      height: 'calc(100% - 65px)',
      overflowY: 'auto',
      backgroundColor: type === 'dark' ? theme.palette.secondary[1000] : theme.palette.secondary[900]
    },
    '& .MuiTabs-root': {
      overflow: 'visible'
    },
    startIcon: {
      margin: 0
    }
  })
);

export default StyledDrawer;
