import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ResponseCommonRequestFields')
class ResponseCommonRequestFields {
  @JsonProperty('status', String)
  status: string | null;

  @JsonProperty('reason', String)
  reason: string | null;
}

@JsonObject('ResponseCommon')
class ResponseCommon {
  @JsonProperty('request', ResponseCommonRequestFields)
  request: ResponseCommonRequestFields | undefined;
}

export default ResponseCommon;
