import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('ConvergencePlot')
export class ConvergencePlot {
  @JsonProperty('accuracy', [Number])
  accuracy?: number[];

  @JsonProperty('loss', [Number])
  loss?: number[];

  @JsonProperty('lr', [Number])
  lr?: number[];

  @JsonProperty('val_accuracy', [Number])
  valAccuracy?: number[];

  @JsonProperty('val_loss', [Number])
  valLoss?: number[];
}

@JsonObject('FeatureOccurence')
export class FeatureOccurence {
  @JsonProperty('used_count', Number)
  usedCount?: number;

  @JsonProperty('total_count', Number)
  totalCount?: number;

  @JsonProperty('value', String)
  value?: string;
}

@JsonObject('FeatureDistribution')
export class FeatureDistribution {
  @JsonProperty('feature_name', String)
  featureName?: string;

  @JsonProperty('occurences', [FeatureOccurence])
  occurences?: FeatureOccurence[];
}

@JsonObject('ScoreRecord')
export class ScoreRecord {
  @JsonProperty('label', String)
  label?: string;

  @JsonProperty('value', Number)
  value?: number;
}

@JsonObject('ConfusionMatrixEntry')
export class ConfusionMatrixEntry {
  @JsonProperty('consensus', String)
  consensus?: string;

  @JsonProperty('result', String)
  result?: string;

  @JsonProperty('count', Number)
  count?: number;
}

@JsonObject('ConfusionMatrix')
export class ConfusionMatrix {
  @JsonProperty('entries', [ConfusionMatrixEntry])
  entries?: ConfusionMatrixEntry[];
}

@JsonObject('BinaryConfusionMatrixEntry')
export class BinaryConfusionMatrixEntry {
  @JsonProperty('label', String)
  label?: string;

  @JsonProperty('tp', Number)
  tp?: number;

  @JsonProperty('fp', Number)
  fp?: number;

  @JsonProperty('tn', Number)
  tn?: number;

  @JsonProperty('fn', Number)
  fn?: number;
}

@JsonObject('BinaryConfusionMatrix')
export class BinaryConfusionMatrix {
  @JsonProperty('entries', [BinaryConfusionMatrixEntry])
  entries?: BinaryConfusionMatrixEntry[];
}

@JsonObject('RocCurveEntry')
export class RocCurveEntry {
  @JsonProperty('label', String)
  label?: string;

  @JsonProperty('thresholds', [Number])
  thresholds?: number[];

  @JsonProperty('tp', [Number])
  tp?: number[];

  @JsonProperty('fp', [Number])
  fp?: number[];

  @JsonProperty('tpr', [Number])
  tpr?: number[];

  @JsonProperty('fpr', [Number])
  fpr?: number[];

  @JsonProperty('tn', [Number])
  tn?: number[];

  @JsonProperty('fn', [Number])
  fn?: number[];
}

@JsonObject('RocCurve')
export class RocCurve {
  @JsonProperty('entries', [RocCurveEntry])
  entries?: RocCurveEntry[];
}

@JsonObject('FeedbackSessionMetrics')
export class FeedbackSessionMetrics {
  @JsonProperty('accuracy', Number)
  accuracy?: number;

  @JsonProperty('precision', [ScoreRecord])
  precision?: ScoreRecord[];

  @JsonProperty('recall', [ScoreRecord])
  recall?: ScoreRecord[];

  @JsonProperty('f_score', [ScoreRecord])
  fScore?: ScoreRecord[];

  @JsonProperty('f_score_mean', Number)
  fScoreMean?: number;

  @JsonProperty('f_score_weighted_average', Number)
  fScoreWeightedAverage?: number;

  @JsonProperty('confusion_matrix', ConfusionMatrix)
  confusionMatrix?: ConfusionMatrix;

  @JsonProperty('binary_confusion_matrix', BinaryConfusionMatrix)
  binaryConfusionMatrix?: BinaryConfusionMatrix;

  @JsonProperty('roc_curve', RocCurve)
  rocCurve?: RocCurve;
}

@JsonObject('JobMetricsResponse')
export class JobMetricsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('convergence_plot', ConvergencePlot)
  convergencePlot?: ConvergencePlot;

  @JsonProperty('feature_distribution', [FeatureDistribution])
  featureDistribution?: FeatureDistribution[];

  @JsonProperty('feedback_session_metrics', FeedbackSessionMetrics)
  feedbackSessionMetrics?: FeedbackSessionMetrics;
}
