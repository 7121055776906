import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { IntegrationSummaryRequest } from '../../components/shared/models/integration/IntegrationSummaryRequest';
import { IntegrationSummaryResponse } from '../../components/shared/models/integration/IntegrationSummaryResponse';
import { IntegrationTypes } from '../../components/shared/models/integration/IntegrationTypes';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';

type UseIntegrationsSummary = {
  integrationType: IntegrationTypes;
  shouldIncludeInternal: boolean;
  jobCategoryId?: number;
};

export function useIntegrationsSummaryByType({ integrationType, shouldIncludeInternal, jobCategoryId }: UseIntegrationsSummary) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.integrationSummary.replace(':parameterType', `${integrationType}`);

  return useQuery<IntegrationSummaryResponse, Error>(
    [
      url,
      {
        event_count: config.defaultEventsCount,
        include_internal: shouldIncludeInternal,
        job_category_id: jobCategoryId
      }
    ],
    () =>
      axios
        .post<{
          resource: IntegrationSummaryResponse;
        }>(url, new IntegrationSummaryRequest(config.defaultEventsCount, shouldIncludeInternal, jobCategoryId))
        .then((responseData: AxiosResponse<{ resource: IntegrationSummaryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, IntegrationSummaryResponse)
        ),
    {
      enabled: Boolean(jobCategoryId)
    }
  );
}
