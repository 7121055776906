import { useTranslation } from 'react-i18next';
import { Spinner, StateChip } from '@arcanna/generic';
import { useTheme } from '@mui/material';

export function JobAutomaticRetrainTag() {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return (
    <StateChip color={theme.palette.warning[600]} text={t('job:jobRetrainState.automaticRetrainInProgress')} icon={<Spinner />} />
  );
}
