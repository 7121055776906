import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { type DrawerProps } from 'antd/es';
import styles from './styles.module.css';

type ArcannaDrawerProps = DrawerProps;
export function ArcannaDrawer({ ...props }: ArcannaDrawerProps) {
  return (
    <Drawer
      closeIcon={<CloseOutlined className={styles.drawerCloseIcon} size={16} />}
      headerStyle={{
        padding: '13.5px 76px 13.5px 40px',
        backgroundColor: 'var(--secondary-color-nb-1000)',
        borderBottom: '1px solid var(--secondary-color-nb-800)'
      }}
      bodyStyle={{
        padding: '20px 40px',
        backgroundColor: 'var(--secondary-color-nb-1000)'
      }}
      drawerStyle={{
        borderLeft: '1px solid var(--secondary-color-nb-700)'
      }}
      {...props}
    />
  );
}

export default ArcannaDrawer;
