/* eslint-disable max-len */
function ExternalLink() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V10"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      <path d="M14 6L14 2.2C14 2.08954 13.9105 2 13.8 2L10 2" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" />
      <line x1="13" y1="2.98995" x2="4.98995" y2="11" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" />
    </svg>
  );
}

export default ExternalLink;
