import { FeedbackStatisticsConsensusData } from '../components/shared/models/feedback/FeedbackStatisticsConsensusData';

export enum ConsensusTypes {
  SIMILAR = 'similar',
  DIFFERENT = 'different'
}

export const statisticsConsensusMetaData: FeedbackStatisticsConsensusData[] = [
  {
    code: ConsensusTypes.SIMILAR,
    name: 'Similar',
    percent: 0,
    color: 'var(--secondary-color)'
  },
  {
    code: ConsensusTypes.DIFFERENT,
    name: 'Different',
    percent: 0,
    color: 'var(--warning-100-color)'
  }
];
