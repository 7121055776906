import { JsonObject, JsonProperty } from 'json2typescript';
import { JobAlertsTimeseriesRecordLabelSplit } from './JobAlertsTimeseriesRecordLabelSplit';

@JsonObject('JobAlertsTimeseriesRecordLabel')
export class JobAlertsTimeseriesRecordLabel {
  @JsonProperty('total', Number)
  total: number;

  @JsonProperty('split', [JobAlertsTimeseriesRecordLabelSplit])
  split: JobAlertsTimeseriesRecordLabelSplit[];
}
