import { Button, ButtonProps, styled } from '@mui/material';

const StyledRHFSubmitButton = styled(Button, {
  shouldForwardProp(propName) {
    return propName !== 'isSubmitting';
  }
})<ButtonProps & { isSubmitting: boolean }>(({ isSubmitting }) => ({
  ...(isSubmitting && {
    pointerEvents: 'none'
  }),

  '& .MuiButton-endIcon': {
    margin: '-4px -4px -4px 8px'
  }
}));

export default StyledRHFSubmitButton;
