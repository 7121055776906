import { EIcon, Icon, Spinner } from '@arcanna/generic';
import { TNotificationType } from 'src/_srcMUI/shared/components/Notifications/Notifications.types';
import { Palette } from '@mui/material/styles/createPalette';

const mapNotificationTypeToIconAndColors = (
  type: TNotificationType,
  palette: Palette
): {
  iconName: EIcon | 'spinner';
  colors: {
    borderColor: string;
    backgroundColor: string;
    titleColor: string;
    subtitleColor: string;
    closeColor: string;
  };
} => {
  switch (type) {
    case 'error':
      return {
        iconName: EIcon.Error,
        colors: {
          borderColor: palette.error[600],
          backgroundColor: palette.error[600],
          titleColor: palette.error.contrastText,
          subtitleColor: palette.error.contrastText,
          closeColor: palette.secondary[900]
        }
      };
    case 'success':
      return {
        iconName: EIcon.Success,
        colors: {
          borderColor: palette.success[400],
          backgroundColor: palette.success[400],
          titleColor: palette.secondary[900],
          subtitleColor: palette.secondary[900],
          closeColor: palette.secondary[900]
        }
      };
    case 'warning':
      return {
        iconName: EIcon.Warning,
        colors: {
          borderColor: palette.warning[600],
          backgroundColor: palette.warning[600],
          titleColor: palette.secondary[900],
          subtitleColor: palette.secondary[900],
          closeColor: palette.secondary[900]
        }
      };
    case 'info':
      return {
        iconName: EIcon.Info,
        colors: {
          borderColor: palette.info[600],
          backgroundColor: palette.secondary[800],
          titleColor: palette.secondary[100],
          subtitleColor: palette.secondary[300],
          closeColor: palette.secondary[400]
        }
      };
    case 'loading':
      return {
        iconName: 'spinner',
        colors: {
          borderColor: palette.secondary[400],
          backgroundColor: palette.secondary[800],
          titleColor: palette.secondary[100],
          subtitleColor: palette.secondary[300],
          closeColor: palette.secondary[900]
        }
      };
    default:
      return {
        iconName: EIcon.Info,
        colors: {
          borderColor: palette.info[600],
          backgroundColor: palette.secondary[800],
          titleColor: palette.secondary[100],
          subtitleColor: palette.secondary[300],
          closeColor: palette.secondary[900]
        }
      };
  }
};

export function getNotificationColorsByType(type: TNotificationType, palette: Palette) {
  return mapNotificationTypeToIconAndColors(type, palette).colors;
}

export function getNotificationIconByType(type: TNotificationType, palette: Palette) {
  const iconName = mapNotificationTypeToIconAndColors(type, palette).iconName;

  if (iconName === 'spinner') {
    return <Spinner />;
  }

  return <Icon name={iconName} fontSize="inherit" />;
}
