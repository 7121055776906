/* eslint-disable max-len */
function ClipboardPlus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M10.6667 2.66671C11.2867 2.66671 11.5967 2.66671 11.851 2.73486C12.5412 2.91979 13.0803 3.45889 13.2652 4.14907C13.3334 4.4034 13.3334 4.71339 13.3334 5.33337V11.4667C13.3334 12.5868 13.3334 13.1469 13.1154 13.5747C12.9236 13.951 12.6177 14.257 12.2413 14.4487C11.8135 14.6667 11.2535 14.6667 10.1334 14.6667H5.86669C4.74658 14.6667 4.18653 14.6667 3.75871 14.4487C3.38238 14.257 3.07642 13.951 2.88467 13.5747C2.66669 13.1469 2.66669 12.5868 2.66669 11.4667V5.33337C2.66669 4.71339 2.66669 4.4034 2.73484 4.14907C2.91977 3.45889 3.45886 2.91979 4.14905 2.73486C4.40338 2.66671 4.71337 2.66671 5.33335 2.66671M8.00002 11.3334V7.33337M6.00002 9.33337H10M6.40002 4.00004H9.60002C9.97339 4.00004 10.1601 4.00004 10.3027 3.92738C10.4281 3.86346 10.5301 3.76148 10.594 3.63603C10.6667 3.49343 10.6667 3.30674 10.6667 2.93337V2.40004C10.6667 2.02667 10.6667 1.83999 10.594 1.69738C10.5301 1.57194 10.4281 1.46995 10.3027 1.40604C10.1601 1.33337 9.97339 1.33337 9.60002 1.33337H6.40002C6.02665 1.33337 5.83997 1.33337 5.69736 1.40604C5.57192 1.46995 5.46993 1.57194 5.40602 1.69738C5.33335 1.83999 5.33335 2.02667 5.33335 2.40004V2.93337C5.33335 3.30674 5.33335 3.49343 5.40602 3.63603C5.46993 3.76148 5.57192 3.86346 5.69736 3.92738C5.83997 4.00004 6.02665 4.00004 6.40002 4.00004Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ClipboardPlus;
