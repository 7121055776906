import { Spinner } from '@arcanna/generic';
import { Suspense } from 'react';
import { Layout as LayoutAntd } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import s from './app.module.css';
import { AppProvider } from './App.context';
import { Routes } from '../Routes/Routes';
import { AuthProvider } from '../pages/Main/Login/Auth.context';
import { CsvProvider } from '../pages/Main/CsvEditor/Csv.context';
import { CsvEditorProvider } from '../pages/Main/CsvEditor/CsvEditor.context';
import { useTheme } from '../../themes/theme.hook';
import ErrorBoundary from '../shared/components/ErrorBoundary/ErrorBoundary';
import { ReactQueryProvider } from '../../data-access/react-query-provider';
import getBranding from 'src/_srcMUI/config/branding/getBranding';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SnackbarProvider } from 'notistack';
import { ToastNotification } from '@arcanna/components';

function App() {
  const { theme } = getBranding();
  const { getMetaTitle, getMetaDescription } = useTheme();
  document.title = getMetaTitle();
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  document.querySelector('meta[name="description"]').setAttribute('content', getMetaDescription());
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        Components={{
          success: ToastNotification,
          error: ToastNotification,
          warning: ToastNotification,
          info: ToastNotification
        }}
        classes={{
          containerRoot: 'notistack-container'
        }}
      >
        <AppProvider>
          <Router>
            <AuthProvider>
              <ReactQueryProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <CsvProvider>
                    <CsvEditorProvider>
                      <Suspense fallback={<Spinner isOverlay />}>
                        <LayoutAntd className={s.layout}>
                          <ErrorBoundary>
                            <Routes />
                          </ErrorBoundary>
                        </LayoutAntd>
                      </Suspense>
                    </CsvEditorProvider>
                  </CsvProvider>
                </LocalizationProvider>
              </ReactQueryProvider>
            </AuthProvider>
          </Router>
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
