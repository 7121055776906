import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { FeedbackStatisticsUserRecord } from './FeedbackStatisticsUserRecord';

@JsonObject('FeedbackStatisticsUserResponse')
export class FeedbackStatisticsUserResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('entries', [FeedbackStatisticsUserRecord])
  entries?: FeedbackStatisticsUserRecord[] = [];

  @JsonProperty('have_trained_model', Boolean)
  have_trained_model?: boolean;
}
