import { useEffect, useState } from 'react';
import { useApp } from '../../../App/App.context';
import { usePlatformSummary } from '../../../../data-access';
import { permissions } from '../../static/ComponentsPermissions';

export function GoToKibana() {
  const { hasAccessFor } = useApp();
  const [infoText, setInfoText] = useState<string>('Redirecting to Kibana...');
  const platformSummaryQuery = usePlatformSummary();

  useEffect(() => {
    if (!hasAccessFor([permissions.kibanaLogin])) {
      setInfoText('Not enough permissions to redirect to Kibana.');
      return;
    }
    if (platformSummaryQuery.data) {
      /* Will open in new tab */
      setTimeout(() => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        window.open(platformSummaryQuery.data.platform.kibana.url, '_blank');
        setInfoText('Kibana opened in new tab.');
      }, 1800);
    }
    // eslint-disable-next-line
  }, [platformSummaryQuery.data]);

  return <h2>{infoText}</h2>;
}
