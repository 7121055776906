/* eslint-disable max-len */
function LockUnlocked() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66666 6.66667V5.33333C4.66666 3.49238 6.15905 2 8 2C9.36688 2 10.5416 2.82273 11.056 4M8 9.66667V11M5.86666 14H10.1333C11.2534 14 11.8135 14 12.2413 13.782C12.6176 13.5903 12.9236 13.2843 13.1153 12.908C13.3333 12.4802 13.3333 11.9201 13.3333 10.8V9.86667C13.3333 8.74656 13.3333 8.18651 13.1153 7.75869C12.9236 7.38236 12.6176 7.0764 12.2413 6.88465C11.8135 6.66667 11.2534 6.66667 10.1333 6.66667H5.86666C4.74656 6.66667 4.18651 6.66667 3.75868 6.88465C3.38236 7.0764 3.0764 7.38236 2.88465 7.75869C2.66666 8.18651 2.66666 8.74656 2.66666 9.86667V10.8C2.66666 11.9201 2.66666 12.4802 2.88465 12.908C3.0764 13.2843 3.38236 13.5903 3.75868 13.782C4.18651 14 4.74656 14 5.86666 14Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LockUnlocked;
