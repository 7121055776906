import { InlineNotification } from '@arcanna/components';
import { useJobInfo } from '../../../../data-access';
import { useTranslation } from 'react-i18next';
import { EJobTrainingState } from '../../models/job/EJobTrainingState';

function AutomaticRetrainNotification({ jobId }: { jobId: number }) {
  const jobInfoQuery = useJobInfo(+jobId);

  const { t } = useTranslation(['job']);

  if (jobInfoQuery.data?.info?.status?.retrain_state === EJobTrainingState.AUTOMATIC_RETRAIN) {
    return (
      <InlineNotification
        type="loading"
        title={t('job:notifications.jobUpgradingToLatestVersion')}
        subtitle={t('job:notifications.stayTuned')}
      />
    );
  }
  return null;
}

export default AutomaticRetrainNotification;
