import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobBucketState')
export class JobBucketState {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('count', Number)
  count: number;
}
