import * as React from 'react';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './JobOverview.module.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import { JobBucketState } from '../../../../shared/models/job/JobBucketState';
import { JobOverviewSplitStatsTotal } from '../../../../shared/models/job/JobOverviewSplitStatsTotal';
import { formatNumber } from '../../../../../utils/numeral';
import { computeRandomId } from '../../../../shared/utilities/rand';
import { useUrlFilters } from '../../../../shared/hooks/useUrlFilters';
import { FeedbackBucketState, FeedbackBucketStateSpan } from '../../../../../constants';

export default function JobOverviewTotalAlerts(props: {
  jobId: number;
  bucketStates: JobBucketState[];
  splitStatsTotal: JobOverviewSplitStatsTotal;
  isRcaJob: boolean;
}) {
  const { t } = useTranslation(['common', 'job']);

  const { bucketStates, splitStatsTotal, jobId, isRcaJob } = props;
  const { filtersToParams, encode } = useUrlFilters();

  const getSpanByState = (stateName: string) => {
    return FeedbackBucketStateSpan[stateName];
  };

  const bucketsNewUrl = React.useCallback(() => {
    const advancedFilters = [{ id: computeRandomId(), field: 'state', operator: 'is', value: FeedbackBucketState.New }];
    return `/jobs/feedback-flow/${jobId}?filters=${encode(filtersToParams({ job_id: jobId, advancedFilters }))}`;
    // eslint-disable-next-line
  }, [jobId]);

  const bucketsByStateNameUrl = React.useCallback(
    (stateName: string) => {
      const advancedFilters = [{ id: computeRandomId(), field: 'state', operator: 'is', value: stateName }];
      const excludeProcessed = false;
      const includeProcessed = false;
      return `/jobs/feedback-flow/${jobId}?filters=${encode(
        filtersToParams({
          job_id: jobId,
          advancedFilters,
          excludeProcessed,
          includeProcessed
        })
      )}`;
    },
    // eslint-disable-next-line
    [jobId]
  );

  const clustersNewUrl = React.useCallback(() => {
    const advancedFilters = [
      { id: computeRandomId(), field: 'state', operator: 'is', value: FeedbackBucketState.New, value_type: 'text' }
    ];
    return `/jobs/clusters/${jobId}?filters=${encode(filtersToParams({ job_id: jobId, advancedFilters }))}`;
    // eslint-disable-next-line
  }, [jobId]);

  const clustersByStateNameUrl = React.useCallback(
    (stateName: string) => {
      const advancedFilters = [{ id: computeRandomId(), field: 'state', operator: 'is', value: stateName, value_type: 'text' }];
      const excludeProcessed = false;
      const includeProcessed = false;
      return `/jobs/clusters/${jobId}?filters=${encode(
        filtersToParams({
          job_id: jobId,
          advancedFilters,
          excludeProcessed,
          includeProcessed
        })
      )}`;
    },
    // eslint-disable-next-line
    [jobId]
  );

  const getHasUrlByState = (stateCount: number) => {
    return stateCount > 0;
  };

  const getUrlByState = (stateName: string, isRca: boolean) => {
    if (stateName === FeedbackBucketState.New) {
      if (isRca) {
        return clustersNewUrl();
      }
      return bucketsNewUrl();
    } else {
      if (isRca) {
        return clustersByStateNameUrl(stateName);
      }
      return bucketsByStateNameUrl(stateName);
    }
  };

  const tooltipBuckets = () => {
    return (
      <>
        {[1, 2, 3, 4].map((value: number) => (
          <div key={`job-bucket-tooltip-line-${value}`}>
            <Row>
              <Typography.Text>
                {t(`job:jobOverview.bucketsTooltipLine${value}Title`)}
                <Typography.Text type="secondary" className="m-l-8">
                  {t(`job:jobOverview.bucketsTooltipLine${value}Desc`)}
                </Typography.Text>
              </Typography.Text>
            </Row>
            <Row>{value !== 4 && <div className={styles.separator} />}</Row>
          </div>
        ))}
      </>
    );
  };

  const tooltipClusters = () => {
    return (
      <>
        {[1, 2, 3].map((value: number) => (
          <div key={`job-bucket-tooltip-line-${value}`}>
            <Row>
              <Typography.Text>
                {t(`job:jobOverview.clustersTooltipLine${value}Title`)}
                <Typography.Text type="secondary" className="m-l-8">
                  {t(`job:jobOverview.clustersTooltipLine${value}Desc`)}
                </Typography.Text>
              </Typography.Text>
            </Row>
            <Row>{value !== 3 && <div className={styles.separator} />}</Row>
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <Row justify="space-between" className="w-100">
        <Col span={10} className={styles.card1}>
          <Typography.Title className="body-2 color-nb-100">{t('job:jobOverview:overallMetrics')}</Typography.Title>
          <Row justify="space-between" className="w-100 p-t-12">
            <Col span={12}>
              <Row>
                <Typography.Text type="secondary" className="color-nb-300">
                  {t('common:alerts')}
                </Typography.Text>
              </Row>
              <Row>
                <span className={styles.number} data-test-id="number-events">
                  {formatNumber(splitStatsTotal?.alerts_count)}
                </span>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                {isRcaJob ? (
                  <Typography.Text type="secondary" className="color-nb-300">
                    {t('common:clusters')}
                  </Typography.Text>
                ) : (
                  <Typography.Text type="secondary" className="color-nb-300">
                    {t('common:buckets')}
                  </Typography.Text>
                )}
              </Row>
              <Row>
                <span className={styles.number} data-test-id="number-groups">
                  {formatNumber(splitStatsTotal?.count)}
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={14} className={styles.card2}>
          {!isRcaJob ? (
            <Typography.Title className="body-2 color-nb-100">
              {t('job:jobOverview:bucketsBreakdown')}
              <Tooltip placement="top" title={tooltipBuckets()} className={styles.bucketsTooltip}>
                <i className="m-l-8">
                  <InfoCircleOutlined />
                </i>
              </Tooltip>
            </Typography.Title>
          ) : (
            <Typography.Title className="body-2 color-nb-100">
              {t('job:jobOverview:clustersBreakdown')}
              <Tooltip placement="top" title={tooltipClusters()} className={styles.bucketsTooltip}>
                <i className="m-l-8">
                  <InfoCircleOutlined />
                </i>
              </Tooltip>
            </Typography.Title>
          )}
          <Row justify="space-between" className="w-100 p-t-12">
            <Col span={24}>
              {bucketStates && (
                <Row justify="space-between" className="w-100" key="job-bucket-states">
                  {bucketStates.map((bucketState: JobBucketState) => (
                    <Col span={getSpanByState(bucketState.name)} key={`job-bucket-state-${bucketState.name}`}>
                      <Row>
                        <Typography.Text type="secondary" className="color-nb-300">
                          {t(`job:jobOverview.bucketState.${bucketState.name}`)}
                        </Typography.Text>
                      </Row>
                      <Row>
                        {getHasUrlByState(bucketState.count) ? (
                          <>
                            <Button
                              className={styles.bucketStateItem}
                              onClick={() => window.open(getUrlByState(bucketState.name, isRcaJob), '_blank')}
                            >
                              <span>{formatNumber(bucketState.count)}</span>
                            </Button>
                          </>
                        ) : (
                          <span className={styles.number}>{formatNumber(bucketState.count)}</span>
                        )}
                      </Row>
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
