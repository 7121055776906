import { FeedbackStatisticsFilterRecord } from '../feedback/FeedbackStatisticsFilterRecord';
import { FeedbackSession } from '../feedback/FeedbackStatisticsLabelsUsedRequest';

export enum RetrainSession {
  CURRENT = 'current',
  PREVIOUS = 'previous',
  OVERALL = 'overall'
}

export type JobRetrainFilters = {
  jobId: number;
  retrainSession: RetrainSession | FeedbackSession;
  startDate: Date;
  endDate: Date;
  filters: FeedbackStatisticsFilterRecord[];
};
