export const enumFromValue = <T extends Record<string, string>>(val: string | undefined | null, _enum: T) => {
  if (val === undefined) {
    throw new Error("Can't get the enum from undefined");
  }

  if (val === null) {
    throw new Error("Can't get the enum from null");
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const enumName = (Object.keys(_enum) as Array<keyof T>).find((k) => _enum[k] === val);
  if (!enumName) throw Error(); // here fail fast
  return _enum[enumName];
};
