import { TFunction } from 'i18next';
import Joi from 'joi';
import _ from 'lodash';
import { RHFSmartGeneratedField } from '@arcanna/forms';
import { IntegrationJobParameterRecord } from 'src/components/shared/models/integration/job/IntegrationJobParameterRecord';
import { IntegrationReadRecord } from 'src/components/shared/models/integration/IntegrationReadRecord';

export const getFormSchema = ({ parameters, t }: { parameters: IntegrationJobParameterRecord[]; t: TFunction }) => {
  let formFields = {
    title: Joi.string()
      .required()
      .messages({
        'any.required': t('validation.fieldRequired', {
          ns: 'forms'
        }),
        'string.empty': t('validation.fieldRequired', {
          ns: 'forms'
        })
      })
  };

  parameters.forEach((parameter) => {
    const parameterSchema = RHFSmartGeneratedField.getSmartGeneratedFieldSchema(parameter, t);

    if (parameterSchema) {
      formFields = {
        ...formFields,
        // @ts-expect-error TS(2464): A computed property name must be of type 'string',...
        [parameter.field]: parameterSchema
      };
    }
  });

  return Joi.object(formFields);
};

export const getFormDefaultValues = ({
  parameters,
  initialIntegration
}: {
  parameters: IntegrationJobParameterRecord[];
  initialIntegration: IntegrationReadRecord | undefined;
}) => {
  let formFields = {
    title: ''
  };

  parameters.forEach((parameter) => {
    formFields = {
      ...formFields,
      // @ts-expect-error TS(2464): A computed property name must be of type 'string',...
      [parameter.field]: RHFSmartGeneratedField.getSmartGeneratedFieldDefaultValue(parameter)
    };
  });

  const existingValues = initialIntegration
    ? {
        title: initialIntegration.title,
        // @ts-expect-error TS(2464): A computed property name must be of type 'string',...
        ...initialIntegration.parameters?.reduce((acc, parameter) => ({ ...acc, [parameter.field]: parameter.value }), {})
      }
    : {};

  return _.merge(formFields, existingValues);
};
