import { Checkbox, TCheckboxState } from '@arcanna/generic';
import { TUnifiedDecisionPointRecord } from '@arcanna/models/Jobs';
import { getIsDPDisabled } from '../../DPUnifiedTable.utils';
import { useEffect, useState } from 'react';
import { Stack, Tooltip } from '@mui/material';
import { JobSummaryRecord } from 'src/components/shared/models/job/JobSummaryRecord';
import { useTranslation } from 'react-i18next';
import { TruncatedText } from '@arcanna/components';

type TDPCellProps = {
  handleCheckboxChange: (state: TCheckboxState, fieldName: string, jobId: number | undefined) => void;
  decisionPointRecord: TUnifiedDecisionPointRecord;
  fieldName: string;
  jobSummaryRecord: JobSummaryRecord;
};

function DPCell({ handleCheckboxChange, decisionPointRecord, fieldName, jobSummaryRecord }: TDPCellProps) {
  const { t } = useTranslation();
  const [checkboxState, setCheckboxState] = useState<TCheckboxState>(
    decisionPointRecord?.isDecisionPoint ? 'checked' : 'default'
  );
  const DPState = decisionPointRecord?.isDecisionPoint ? 'checked' : 'default';
  const { isDisabled, reason, checkboxLabel } = getIsDPDisabled(decisionPointRecord, jobSummaryRecord, t);
  const DPLabel = checkboxLabel ?? String(decisionPointRecord.sampleValue ?? t('common:na'));

  useEffect(() => {
    // update current checkbox state if the user selected the whole row for instance
    if (DPState !== checkboxState) {
      setCheckboxState(DPState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DPState]);

  useEffect(() => {
    // update current checkbox state from context
    if (DPState !== checkboxState) {
      handleCheckboxChange(checkboxState, fieldName, jobSummaryRecord.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxState, handleCheckboxChange]);

  return (
    <Tooltip arrow placement="top" title={isDisabled ? reason : DPLabel} enterDelay={1000} enterNextDelay={1000}>
      <Stack>
        <Checkbox
          label={<TruncatedText isTooltipHidden variant="subtitle2" fontWeight={400} text={DPLabel} />}
          labelPlacement="end"
          disabled={isDisabled}
          state={checkboxState}
          onChange={(state) => {
            setCheckboxState(state);
          }}
          sx={{
            display: 'block',
            marginRight: 0
          }}
          checkboxSx={{
            float: 'left'
          }}
        />
      </Stack>
    </Tooltip>
  );
}

export default DPCell;
