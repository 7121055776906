import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyIcon } from '../../../../themes/icons';
import styles from './styles.module.css';
import { Tooltip } from '@mui/material';

type CopyToClipboardButtonProps = {
  value: string;
};
export function ArcannaCopyToClipboardButton({ value }: CopyToClipboardButtonProps) {
  const { t } = useTranslation(['common']);
  const [copyMessage, setCopyMessage] = useState<string | undefined>(undefined);

  const handleCopyToClipboard = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(value);
    setCopyMessage(t('eventExplorer:valueToClipboard'));
  };

  useEffect(
    function clearTooltipText() {
      if (copyMessage !== undefined) {
        const timeout = setTimeout(() => setCopyMessage(undefined), 500);
        return () => clearTimeout(timeout);
      }
    },
    [copyMessage]
  );

  return (
    <Tooltip title={copyMessage === undefined ? t('copyToClipboardBtn') : copyMessage} placement="top" arrow>
      <button type="button" className={styles.btn} onClick={(e) => handleCopyToClipboard(e)}>
        <CopyIcon />
      </button>
    </Tooltip>
  );
}
