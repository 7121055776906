/* eslint-disable max-len */
function Lock() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3332 6.66667V5.33333C11.3332 3.49238 9.84079 2 7.99984 2C6.15889 2 4.6665 3.49238 4.6665 5.33333V6.66667M7.99984 9.66667V11M5.8665 14H10.1332C11.2533 14 11.8133 14 12.2412 13.782C12.6175 13.5903 12.9234 13.2843 13.1152 12.908C13.3332 12.4802 13.3332 11.9201 13.3332 10.8V9.86667C13.3332 8.74656 13.3332 8.18651 13.1152 7.75869C12.9234 7.38236 12.6175 7.0764 12.2412 6.88465C11.8133 6.66667 11.2533 6.66667 10.1332 6.66667H5.8665C4.7464 6.66667 4.18635 6.66667 3.75852 6.88465C3.3822 7.0764 3.07624 7.38236 2.88449 7.75869C2.6665 8.18651 2.6665 8.74656 2.6665 9.86667V10.8C2.6665 11.9201 2.6665 12.4802 2.88449 12.908C3.07624 13.2843 3.3822 13.5903 3.75852 13.782C4.18635 14 4.7464 14 5.8665 14Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Lock;
