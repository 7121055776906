import { withTransaction } from '@elastic/apm-rum-react';
import { VisualizationsProvider } from '../../../../shared/components/QueryVisualisation/Visualizations.context';
import { MobileWifi } from './MobileWifi';

// eslint-disable-next-line
function MobileWifiWrapper(props: any) {
  return (
    <VisualizationsProvider>
      <MobileWifi {...props} />
    </VisualizationsProvider>
  );
}
export default withTransaction('MobileWifiWrapper', 'component')(MobileWifiWrapper);
