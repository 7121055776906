import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiSelectStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiSelect: {
    styleOverrides: {
      root: {
        height: '36px',

        '& .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select': {
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'transparent'
        },

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${palette.info[600]}`,
          color: palette.secondary[100]
        },

        '& .MuiOutlinedInput-notchedOutline': {
          top: 0,
          borderColor: palette.secondary[700],
          height: '36px'
        },

        '.MuiSelect-placeholder': {
          opacity: 0
        },

        '&.Mui-focused .MuiSelect-placeholder': {
          opacity: 1
        },

        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${palette.info[600]}`
        },

        '& svg': {
          color: palette.secondary[300]
        }
      },
      select: {
        padding: '0 36px 0 16px',
        fontSize: '14px',
        lineHeight: '16px',
        color: palette.secondary[100],
        borderColor: palette.secondary[700],
        backgroundColor: palette.secondary[900]
      }
    }
  }
});
