/* A hook with utility functions used by visuals */
import { useCallback } from 'react';

export function useUtils() {
  const riskColorClassNames: { [id: string]: string } = {
    critical: 'critical-color',
    high: 'high-color',
    medium: 'medium-color',
    low: 'low-color',
    info: 'info-color'
  };

  function lastWord(s: string) {
    const words = s.trim().split(' ');
    return words[words.length - 1];
  }

  // eslint-disable-next-line
  function colorClassNameForKey(data: any): string {
    let className = '';
    if (typeof data === 'string') {
      if (riskColorClassNames[lastWord(data).toLowerCase()] != null) {
        className = riskColorClassNames[lastWord(data).toLowerCase()];
      }
    } else if (typeof data === 'object') {
      for (const key in data) {
        if (typeof key === 'string' && riskColorClassNames[key.toLowerCase()] != null) {
          className = riskColorClassNames[key.toLowerCase()];
          break;
        }
        if (typeof data[key] === 'string' && riskColorClassNames[data[key].toLowerCase()] != null) {
          className = riskColorClassNames[data[key].toLowerCase()];
          break;
        }
      }
    }
    return className;
  }

  // eslint-disable-next-line
  function overrideOwnStyleAttributes(style: any, props: any) {
    for (const styleProp in props.style) {
      style[styleProp] = props.style[styleProp];
    }
  }

  function nColorArray(n: number, randomSeed: number) {
    const availableColorPalette = [
      '#8F8F8F',
      '#FF7900',
      '#FF8AD4',
      '#0A6E31',
      '#FFD200',
      '#FFB400',
      '#FFF6B6',
      '#4BB4E6',
      '#B5E8F7',
      '#50BE87',
      '#0A6E31',
      '#FFB4E6',
      '#FFE8F7',
      '#A885D8',
      '#D9C2F0',
      '#595959',
      '#FF7900',
      '#D6D6D6'
    ];

    const result = [];
    for (let i = 0; i < n; i += 1) {
      result.push(availableColorPalette[(i + randomSeed) % availableColorPalette.length]);
    }

    return result;
  }

  // eslint-disable-next-line
  const cloneArray = useCallback((original_array: any) => JSON.parse(JSON.stringify(original_array)), []);

  function hexToRGBA(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
  }

  return {
    colorClassNameForKey,
    overrideOwnStyleAttributes,
    nColorArray,
    cloneArray,
    hexToRGBA
  };
}
