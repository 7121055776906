import { Col, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './JobOverviewMetrics.module.css';
import { useJobInfo, useTimeMetrics } from '../../../../../../data-access';
import { formatNumber } from '../../../../../../utils/numeral';
import TimeMetricsInfo from './TimeMetricsInfo';
import { getHumanReadableTime } from '../../../../../../utils/humanReadableTime';
import MedianDecisionTimeMetric from './MedianDecisionTimeMetric';
import ValueUnitList from './ValueUnitComp';
import TimeMetric from './TimeMetric';

type TimeMetricsMultipleTrainsProps = {
  jobId: number;
};

export default function TimeMetricsMultipleTrains({ jobId }: TimeMetricsMultipleTrainsProps) {
  const { t } = useTranslation(['job']);
  const jobInfoQuery = useJobInfo(+jobId);
  const jobTimeMetrics = jobInfoQuery.data?.info?.metrics;
  const timeMetricsQuery = useTimeMetrics(+jobId);

  const timesDivisionList = getHumanReadableTime(
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    timeMetricsQuery?.data?.timeUnitsSavedByArcanna * jobTimeMetrics.time_value,
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    jobTimeMetrics?.time_units
  )
    .slice(-3)
    .reverse();

  return (
    <Row justify="space-between">
      <Col className={'p-r-20'}>
        <TimeMetricsInfo jobId={jobId} />
      </Col>
      <Col className="p-l-20 p-r-20 p-t-32">
        <TimeMetric
          text={t('job:jobTimeMetrics:timeSavedByArcanna')}
          tooltipText={t('job:jobTimeMetrics:timeSavedByArcannaInfo')}
        />
        <Row>
          <ValueUnitList valueUnitArray={timesDivisionList} />
        </Row>
      </Col>
      <Col className="p-l-20 p-r-20 p-t-32">
        <TimeMetric text={t('job:jobTimeMetrics:timeGainedBack')} tooltipText={t('job:jobTimeMetrics:timeGainedBackInfo')} />
        <Row>
          <Space size={2} align="baseline">
            <span className={style.metricsNumber}>{formatNumber(timeMetricsQuery?.data?.timeGainedBack)}%</span>
            <Typography.Text type="secondary" className="color-nb-300 caption">
              {t('job:jobTimeMetrics:lessTimeInvestigating')}
            </Typography.Text>
          </Space>
        </Row>
      </Col>
      <MedianDecisionTimeMetric jobId={jobId} />
    </Row>
  );
}
