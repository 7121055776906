import { EIcon, Icon } from '@arcanna/generic';
import { TUnifiedDecisionPointsResponse } from '@arcanna/models/Jobs';
import { ButtonGroup, Button, InputAdornment, TextField, useTheme } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { useDPUnifiedContext } from '../DPUnifiedContext/useDPUnifiedContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getButtonsConfig } from './DPUnifiedFilters.utils';

type TDPUnifiedFiltersProps = {
  tableInstance: Table<TUnifiedDecisionPointsResponse>;
};

function DPUnifiedFilters({ tableInstance }: TDPUnifiedFiltersProps) {
  const theme = useTheme();
  const { t } = useTranslation(['decisionPointsUnified', 'common']);
  const { filtersSlice } = useDPUnifiedContext();

  const buttonsConfig = useMemo(() => getButtonsConfig(t), [t]);

  return (
    <>
      <ButtonGroup variant="outlined" color="secondary" size="small">
        {buttonsConfig.map((buttonConfig) => (
          <Button
            key={buttonConfig.key}
            variant={filtersSlice.activeSelectedFilter === buttonConfig.key ? 'outlined' : 'containedHover'}
            onClick={() => {
              filtersSlice.setActiveSelectedFilter(buttonConfig.key);
              tableInstance.resetPageIndex();
            }}
          >
            {buttonConfig.label}
          </Button>
        ))}
      </ButtonGroup>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon name={EIcon.ActionSearch} htmlColor={theme.palette.secondary[300]} />
            </InputAdornment>
          ),
          sx: { height: '36px' }
        }}
        sx={{ width: '275px' }}
        value={tableInstance.getState().globalFilter}
        placeholder={t('common:search')}
        onChange={(event) => {
          tableInstance.setGlobalFilter(event.target.value);
          tableInstance.resetPageIndex();
        }}
      />
    </>
  );
}

export default DPUnifiedFilters;
