import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../../common/CommonResponse';
import { IntegrationSubcategoryRecord } from './IntegrationSubcategoryRecord';

@JsonObject('IntegrationSubcategoryListResponse')
export class IntegrationSubcategoryListResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [IntegrationSubcategoryRecord])
  entries?: IntegrationSubcategoryRecord[] = [];
}
