import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { JobFeedbackColumnsSummaryDetails } from './JobFeedbackColumnsSummaryDetails';

@JsonObject('JobFeedbackColumnsSummaryResponse')
export class JobFeedbackColumnsSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('columns', [JobFeedbackColumnsSummaryDetails])
  columns?: JobFeedbackColumnsSummaryDetails[] = [];
}
