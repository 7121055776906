import { Stack, Typography } from '@mui/material';
import { IntegrationSubcategoryRecord } from 'src/components/shared/models/integration/subcategory/IntegrationSubcategoryRecord';
import IntegrationForm from '../IntegrationForm';
import { useCreateIntegration } from 'src/data-access/integrations/useCreateIntegration';
import { useTranslation } from 'react-i18next';
import IntegrationCreateHealthCheck, { IntegrationHeading } from '@arcanna/components';
import { useCallback, useState } from 'react';
import useCreatePayload from '@arcanna/pages/AddJob/components/IntegrationUpdateModal/IntegrationCreateModal/hooks/useCreatePayload.hook';

function IntegrationCreateSection({
  integrationSubcategory,
  onClose,
  onCreateSuccess
}: {
  integrationSubcategory: IntegrationSubcategoryRecord;
  onClose: () => void;
  onCreateSuccess?: () => void;
}) {
  const { t } = useTranslation(['job-create']);
  const createIntegrationMutation = useCreateIntegration();
  const [showModalSteps, setShowModalSteps] = useState(false);
  const [integrationParams, setIntegrationParams] = useState<unknown>();
  const { getCreatePayload } = useCreatePayload({ subcategoryId: integrationSubcategory.id });

  const handleSubmit = async (values: unknown) => {
    const payload = getCreatePayload(values);
    await createIntegrationMutation.mutateAsync(payload, {
      onSuccess: () => onCreateSuccess?.()
    });
  };

  const startValidation = useCallback(async (values: unknown) => {
    setShowModalSteps(true);
    setIntegrationParams(values);
  }, []);

  return (
    integrationSubcategory && (
      <>
        <Stack direction="column" gap={2}>
          <IntegrationHeading integrationSubcategory={integrationSubcategory} />
          <Typography variant="body2">{t('createIntegrationForm.parameters')}</Typography>
          <IntegrationForm
            parameters={integrationSubcategory.parameters}
            onCancel={onClose}
            onSubmit={startValidation}
            integrationSubcategory={integrationSubcategory}
            getRequestPayload={getCreatePayload as <IntegrationCreateRequest>(values: unknown) => IntegrationCreateRequest}
          />
        </Stack>
        {showModalSteps && (
          <IntegrationCreateHealthCheck
            onSuccessCheck={() => {
              onClose();
            }}
            onClose={() => {
              setShowModalSteps(false);
            }}
            onCredentialsCheck={() => handleSubmit(integrationParams)}
          />
        )}
      </>
    )
  );
}

export default IntegrationCreateSection;
