import { JsonObject, JsonProperty } from 'json2typescript';
import { VisualizationRecord } from './VisualizationRecord';

@JsonObject('MultiLayerRecord')
export class MultiLayerRecord extends VisualizationRecord {
  // eslint-disable-next-line
  @JsonProperty('layers', Array<any>())
  // eslint-disable-next-line
  layers: Array<any> | undefined;
}
