import Icon, { BulbOutlined, KeyOutlined } from '@ant-design/icons';
import { ReactComponent as homeSVG } from './icons/Home.svg';
import { ReactComponent as dashboardSVG } from './icons/Dashboards.svg';
import { ReactComponent as useCaseSVG } from './icons/App.svg';
import { ReactComponent as reportSVG } from './icons/PdfFile.svg';
import { ReactComponent as manageEquipmentSVG } from './icons/ManageEquipment.svg';
import { ReactComponent as cyberDefenceSVG } from './icons/CyberDefence.svg';
import { ReactComponent as managementSVG } from './icons/Management.svg';
import { ReactComponent as clientManagementSVG } from './icons/UserManagement.svg';
import { ReactComponent as AISVG } from './icons/AI.svg';
import { ReactComponent as clientDataManagementSVG } from './icons/ClientDataManagement.svg';
import { ReactComponent as logoutSVG } from './icons/Logout.svg';
import { ReactComponent as avatarSVG } from './icons/Avatar.svg';
import { ReactComponent as dashboardJobSVG } from './icons/BarChart.svg';
import { ReactComponent as feedbackSVG } from './icons/Alert.svg';
import { ReactComponent as csvEditorSVG } from './icons/ExcelFile.svg';
import { ReactComponent as cyberShieldSVG } from './icons/CyberShield.svg';
import { ReactComponent as wifiSVG } from './icons/Wifi.svg';
import { ReactComponent as KibanaSVG } from './icons/Kibana.svg';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

export const HomeIcon = (props: IconComponentProps) => <Icon component={homeSVG} {...props} />;
export const DashboardsIcon = (props: IconComponentProps) => <Icon component={dashboardSVG} {...props} />;
export const UseCaseIcon = (props: IconComponentProps) => <Icon component={useCaseSVG} {...props} />;
export const ReportIcon = (props: IconComponentProps) => <Icon component={reportSVG} {...props} />;
export const ExportCsvIcon = (props: IconComponentProps) => <Icon component={csvEditorSVG} {...props} />;
export const ManagementIcon = (props: IconComponentProps) => <Icon component={managementSVG} {...props} />;
export const ManageEquipmentIcon = (props: IconComponentProps) => <Icon component={manageEquipmentSVG} {...props} />;
export const UserManagementIcon = (props: IconComponentProps) => <Icon component={clientManagementSVG} {...props} />;
export const CyberDefenceIcon = (props: IconComponentProps) => <Icon component={cyberDefenceSVG} {...props} />;
export const AIJobsManagementIcon = (props: IconComponentProps) => <Icon component={AISVG} {...props} />;
export const ClientDataManagementIcon = (props: IconComponentProps) => <Icon component={clientDataManagementSVG} {...props} />;
export const LogoutIcon = (props: IconComponentProps) => <Icon component={logoutSVG} {...props} />;
export const AvatarIcon = (props: IconComponentProps) => <Icon component={avatarSVG} {...props} />;
export const DashboardJobIcon = (props: IconComponentProps) => <Icon component={dashboardJobSVG} {...props} />;
export const FeedbackIcon = (props: IconComponentProps) => <Icon component={feedbackSVG} {...props} />;
export const RetrainIcon = (props: IconComponentProps) => <BulbOutlined {...props} />;
export const CsvEditorIcon = (props: IconComponentProps) => <Icon component={csvEditorSVG} {...props} />;
export const CyberShieldIcon = (props: IconComponentProps) => <Icon component={cyberShieldSVG} {...props} />;
export const WifiIcon = (props: IconComponentProps) => <Icon component={wifiSVG} {...props} />;
export const KibanaIcon = (props: IconComponentProps) => <Icon component={KibanaSVG} {...props} />;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserKeyIcon = (props: any) => <KeyOutlined {...props} />;

export const ReportLogo = (props: IconComponentProps) => <Icon component={reportSVG} {...props} className="landing-logo-icon" />;
export const ExportCsvLogo = (props: IconComponentProps) => (
  <Icon component={csvEditorSVG} {...props} className="landing-logo-icon" />
);
export const ManagementLogo = (props: IconComponentProps) => (
  <Icon component={managementSVG} {...props} className="landing-logo-icon" />
);
export const ClientDataManagementLogo = (props: IconComponentProps) => (
  <Icon component={clientDataManagementSVG} {...props} className="landing-logo-icon" />
);
export const UserManagementLogo = (props: IconComponentProps) => (
  <Icon component={clientManagementSVG} {...props} className="landing-logo-icon" />
);
export const CyberDefenceLogo = (props: IconComponentProps) => (
  <Icon component={cyberDefenceSVG} {...props} className="landing-logo-icon" />
);
export const CyberShieldLogo = (props: IconComponentProps) => (
  <Icon component={cyberShieldSVG} {...props} className="landing-logo-icon" />
);
export const AIJobsManagementLogo = (props: IconComponentProps) => (
  <Icon component={AISVG} {...props} className="landing-logo-icon" />
);
export const KibanaLogo = (props: IconComponentProps) => <Icon component={KibanaSVG} {...props} className="landing-logo-icon" />;
export const UserKeyLogo = (props: IconComponentProps) => <Icon component={UserKeyIcon} {...props} className="user-key-icon" />;
