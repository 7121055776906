import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { EventExplorerStructureRequest } from '../../components/shared/models/event-explorer/EventExplorerStructureRequest';
import { EventExplorerStructureResponse } from '../../components/shared/models/event-explorer/EventExplorerStructureResponse';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

export const useEventExplorerStructure = (jobId: number) => {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<EventExplorerStructureResponse, Error>([config.api.eventExplorerStructure, jobId], () =>
    axios
      .post<{
        resource: EventExplorerStructureResponse;
      }>(config.api.eventExplorerStructure, new EventExplorerStructureRequest(jobId))
      .then((responseData: AxiosResponse<{ resource: EventExplorerStructureResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, EventExplorerStructureResponse)
      )
  );
};
