import * as React from 'react';
import { RouteType } from '../../components/shared/models/route/RouteType';
import { config } from 'src/config';
import { IntegrationsIcon, IntegrationsLogo, UserKeyIcon, UserKeyLogo } from './icons';
import { JobFeedbackColumns } from '../../components/pages/Main/Jobs/FeedbackColumns/JobFeedbackColumns';
import { permissions } from '../../components/shared/static/ComponentsPermissions';
import { LicenseFeatures } from '../../components/shared/models/license/LicenseFeatures';
import { LicenseCheckActions } from '../../components/shared/models/license/LicenseCheckActions';
import { JobProvider } from '../../components/pages/Main/Jobs/Job.context';
import { SettingsIcon } from '../../components/shared/icons/SettingsIcon';
import { JobOverviewProvider } from '../../components/pages/Main/Jobs/OverviewRevamp/JobOverview.context';
import JobOverviewWrapper from '../../components/pages/Main/Jobs/Overview/JobOverviewWrapper'; // temporary for RCA
import { FlowsIcon } from '../icons/FlowsIcon';
import RedirectLinkGenerator from '@arcanna/pages/RedirectLinkGenerator';
import UnifiedJobsNavigation from 'src/components/shared/components/UnifiedJobsNavigation';
import JobFlowsContextProvider from '@arcanna/pages/JobFlows/JobFlowsContext/JobFlowsContextProvider';

const {
  HomeIcon,
  DashboardJobIcon,
  FeedbackIcon,
  UserManagementIcon,
  UserManagementLogo,
  AIJobsManagementIcon,
  AIJobsManagementLogo
  // eslint-disable-next-line
} = require('./icons');

// MUI
const Homepage = React.lazy(() => import('@arcanna/pages/Dashboard'));
const AddJob = React.lazy(() => import('@arcanna/pages/AddJob'));
const Monitoring = React.lazy(() => import('@arcanna/pages/Monitoring/MonitoringDashboard'));
const MonitoringDrilldown = React.lazy(() => import('@arcanna/pages/Monitoring/MonitoringDrilldown'));
const DecisionPointsUnified = React.lazy(() => import('@arcanna/pages/DecisionPointsUnified'));
const PageNotFound = React.lazy(() => import('@arcanna/pages/ErrorPages/404'));
const CodeBlock = React.lazy(() => import('@arcanna/pages/Flows/components/CodeBlock'));
const Jobs = React.lazy(() => import('@arcanna/pages/JobsList'));
const Feedback = React.lazy(() => import('@arcanna/pages/Feedback/Feedback'));
const FeedbackUnified = React.lazy(() => import('@arcanna/pages/Feedback/FeedbackUnified'));
const PlatformSettings = React.lazy(() => import('@arcanna/pages/PlatformSettings'));
const Integrations = React.lazy(() => import('@arcanna/pages/Integrations'));
const JobFlows = React.lazy(() => import('@arcanna/pages/JobFlows'));
const IntegrationEdit = React.lazy(() => import('@arcanna/pages/IntegrationEdit'));
const IntegrationCreate = React.lazy(() => import('@arcanna/pages/IntegrationCreate'));
const ChangePassword = React.lazy(() => import('@arcanna/pages/ChangePassword'));
const UserManagement = React.lazy(() => import('@arcanna/pages/UserManagement'));
const ApiKeys = React.lazy(() => import('@arcanna/pages/ApiKeys'));
const Explorer = React.lazy(() => import('@arcanna/pages/Explorer'));

// older ones
const JobRetrain = React.lazy(() => import('../../components/pages/Main/Jobs/Retrain'));
const JobFeatureSelection = React.lazy(() => import('../../components/pages/Main/Jobs/FeatureSelection'));
const JobEventExplorerColumnsWrapper = React.lazy(
  () => import('../../components/pages/Main/Jobs/EventExplorerColumns/JobEventExplorerColumnsWrapper')
);
const EditJob = React.lazy(() => import('../../components/pages/Main/Jobs/Edit'));
const JobClusters = React.lazy(() => import('../../components/pages/JobClusters'));
const JobAddFlow = React.lazy(() => import('../../components/pages/JobFlows/JobAddFlow'));

export const staticRoutes: Array<RouteType> = [
  {
    name: 'Home',
    title: 'AI-Assisted Cybersecurity Platform',
    path: config.routes.homepage,
    component: Homepage,
    isInMenu: true,
    icon: HomeIcon,
    exact: true,
    isHome: true,
    isPrivate: true
  },
  {
    name: 'AI jobs',
    title: 'AI jobs',
    path: config.routes.jobsAll,
    component: Jobs,
    isInMenu: true,
    isInLandingPage: true,
    requiredPermissions: [permissions.jobRead, permissions.categoryRead],
    exact: false,
    isPrivate: true,
    logo: AIJobsManagementLogo,
    icon: AIJobsManagementIcon,
    provider: JobProvider,
    children: [
      {
        name: 'Edit job',
        title: 'Edit job',
        path: config.routes.editJob,
        component: EditJob,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate, permissions.integrationRead],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Create job',
        title: 'Create job',
        path: config.routes.jobCreate,
        component: AddJob,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate, permissions.integrationRead],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: false
      },
      {
        name: 'Job overview',
        title: 'Job overview',
        path: config.routes.jobOverView,
        component: JobOverviewProvider,
        requiredPermissions: [permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: DashboardJobIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Job overview RCA',
        title: 'Job overview RCA',
        path: config.routes.jobOverViewRCA,
        component: JobOverviewWrapper,
        requiredPermissions: [permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: DashboardJobIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Feedback',
        title: 'Feedback',
        path: config.routes.feedbackFlow,
        component: Feedback,
        requiredPermissions: [permissions.jobRead, permissions.feedbackRead],
        isInMenu: false,
        isPrivate: true,
        icon: FeedbackIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Feedback unified',
        title: 'Feedback unified',
        path: config.routes.feedbackUnified,
        component: FeedbackUnified,
        requiredPermissions: [permissions.jobRead, permissions.feedbackRead],
        isInMenu: false,
        isPrivate: true,
        sider: UnifiedJobsNavigation
      },
      {
        name: 'Decision Points unified',
        title: 'Decision Points unified',
        path: config.routes.decisionPointsUnified,
        component: DecisionPointsUnified,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        sider: UnifiedJobsNavigation
      },
      {
        name: 'Event explorer',
        title: 'Event explorer',
        path: config.routes.eventExplorer,
        component: Explorer,
        requiredPermissions: [permissions.jobRead, permissions.eventExplorerRead],
        isInMenu: false,
        isPrivate: true,
        icon: FeedbackIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Job event explorer columns selection',
        title: 'Job event explorer columns selection',
        path: config.routes.eventExplorerColumnsSelection,
        component: JobEventExplorerColumnsWrapper,
        requiredPermissions: [permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Machine learning feature selection',
        title: 'Machine learning feature selection',
        path: config.routes.featureSelectionJob,
        component: JobFeatureSelection,
        requiredPermissions: [permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Job retrain',
        title: 'Job retrain',
        path: config.routes.retrainJob,
        component: JobRetrain,
        requiredPermissions: [permissions.jobRead, permissions.feedbackRead, permissions.retrainRead],
        isInMenu: false,
        isPrivate: true,
        icon: FeedbackIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Job Feedback columns selection',
        title: 'Job Feedback columns selection',
        path: config.routes.columnsSelection,
        component: JobFeedbackColumns,
        requiredPermissions: [permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Clusters',
        title: 'Clusters',
        path: config.routes.jobClusters,
        component: JobClusters,
        requiredPermissions: [permissions.jobRead, permissions.feedbackRead, permissions.retrainRead],
        isInMenu: false,
        isPrivate: true,
        exact: true,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Not found',
        title: 'Not found',
        path: '*',
        component: PageNotFound,
        isInMenu: false,
        isPrivate: false
      }
    ]
  },
  {
    name: 'Flows',
    title: 'Flows',
    path: config.routes.flowsWrapper,
    requiredPermissions: [permissions.jobRead, permissions.jobUpdate],
    isInMenu: false,
    isPrivate: true,
    icon: FlowsIcon,
    provider: JobFlowsContextProvider,
    hasSideNavigation: true,
    exact: false,
    renderOnlyChildren: true,
    children: [
      {
        name: 'Flows',
        title: 'Flows',
        path: config.routes.flows,
        component: JobFlows,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Add flow',
        title: 'Add flow',
        path: config.routes.flowsComponents.contextEnrichment.add({ jobId: ':id' }),
        component: JobAddFlow,
        requiredPermissions: [permissions.jobWrite, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Edit flow',
        title: 'Edit flow',
        path: config.routes.flowsComponents.contextEnrichment.edit({
          jobId: ':id',
          contextName: ':contextName',
          contextId: ':contextId'
        }),
        component: JobAddFlow,
        requiredPermissions: [permissions.jobUpdate, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Add Code block',
        title: 'Add Code block',
        path: config.routes.flowsComponents.codeBlock.add({ jobId: ':id' }),
        component: CodeBlock,
        requiredPermissions: [permissions.jobWrite, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Edit Code block',
        title: 'Edit Code block',
        path: config.routes.flowsComponents.codeBlock.edit({
          jobId: ':id',
          codeBlockId: ':codeBlockId'
        }),
        component: CodeBlock,
        requiredPermissions: [permissions.jobWrite, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      }
    ]
  },
  {
    name: 'Integrations',
    title: 'Integrations',
    path: config.routes.integrations.base,
    component: Integrations,
    isInMenu: true,
    isInLandingPage: false,
    requiredPermissions: [permissions.integrationRead],
    exact: true,
    isPrivate: true,
    logo: IntegrationsLogo,
    icon: IntegrationsIcon
  },
  {
    name: 'Add integration',
    title: 'Add integration',
    path: config.routes.integrations.add(':id'),
    component: IntegrationCreate,
    requiredPermissions: [permissions.integrationRead, permissions.integrationWrite],
    isInMenu: false,
    isPrivate: true,
    exact: true,
    icon: IntegrationsLogo
  },
  {
    name: 'Edit integration',
    title: 'Edit integration',
    path: config.routes.integrations.edit(':id'),
    component: IntegrationEdit,
    requiredPermissions: [permissions.integrationRead, permissions.integrationUpdate],
    isInMenu: false,
    isPrivate: true,
    exact: true,
    icon: IntegrationsLogo
  },
  {
    name: 'User management',
    title: 'User management',
    path: config.routes.userManagement,
    component: UserManagement,
    requiredPermissions: [permissions.userRead],
    requiredLicenseFeature: LicenseFeatures.TEAM_MODE,
    noLicenseAction: LicenseCheckActions.DISABLE,
    isInMenu: true,
    isInLandingPage: true,
    exact: true,
    isPrivate: true,
    logo: UserManagementLogo,
    icon: UserManagementIcon
  },
  {
    name: 'Platform Settings',
    title: 'Platform Settings',
    path: config.routes.platformManagement,
    component: PlatformSettings,
    requiredPermissions: [permissions.userRead, permissions.licenseUpdate],
    isInMenu: true,
    isInLandingPage: true,
    exact: true,
    isPrivate: true,
    logo: SettingsIcon,
    icon: SettingsIcon
  },
  {
    name: 'Monitoring',
    title: 'Monitoring',
    path: config.routes.monitoring,
    requiredPermissions: [permissions.userRead],
    component: Monitoring,
    isInMenu: true,
    icon: HomeIcon,
    exact: true,
    isPrivate: true
  },
  {
    name: 'Arcanna Redirect',
    title: 'Arcanna Redirect',
    path: config.routes.redirect,
    requiredPermissions: [permissions.userRead],
    component: RedirectLinkGenerator,
    isInMenu: false,
    exact: true,
    isPrivate: true
  },
  {
    name: 'Monitoring drilldown',
    title: 'Monitoring drilldown',
    path: config.routes.monitoringDrilldown,
    component: MonitoringDrilldown,
    requiredPermissions: [permissions.userRead],
    isInMenu: false,
    isPrivate: true
  },
  {
    name: 'Api keys',
    title: 'Api keys',
    path: config.routes.apiKeys,
    component: ApiKeys,
    requiredPermissions: [permissions.userKeyRead],
    isInMenu: false,
    isInLandingPage: true,
    exact: true,
    isPrivate: true,
    logo: UserKeyLogo,
    icon: UserKeyIcon
  },
  {
    name: 'ChangePassword',
    title: 'ChangePassword',
    path: config.routes.auth.changePassword,
    isInMenu: false,
    isPrivate: true,
    layout: ChangePassword,
    exact: true
  },
  {
    name: 'Not found',
    title: 'Not found',
    path: '*',
    component: PageNotFound,
    isInMenu: false,
    isPrivate: false
  }
];
