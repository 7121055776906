import { closeSnackbar, CustomContentProps, SnackbarContent, SnackbarKey } from 'notistack';
import { forwardRef, ReactElement, useMemo } from 'react';
import { Button, Box, Stack, Typography, useTheme } from '@mui/material';
import { Icon, EIcon } from '@arcanna/generic';

export type TActionButtonProps = {
  onClick: (snackId: SnackbarKey) => void;
  title: string;
};

interface ToastNotificationProps extends CustomContentProps {
  title: string;
  subtitle?: string;
  description?: string;
  actionButton?: TActionButtonProps;
}

const notifIconMapping: Record<string, ReactElement> = {
  success: <Icon name={EIcon.Success} />,
  warning: <Icon name={EIcon.Warning} />,
  info: <Icon name={EIcon.Info} />,
  error: <Icon name={EIcon.Error} />
};

const ToastNotification = forwardRef<HTMLDivElement, ToastNotificationProps>((props, ref) => {
  const { palette } = useTheme();
  const borderColorByNotifVariant: Record<string, string> = useMemo(
    () => ({
      error: palette.error.main,
      success: palette.success.main,
      info: palette.info.main,
      warning: palette.warning.main
    }),
    [palette]
  );

  return (
    <SnackbarContent ref={ref}>
      <Stack
        padding="16px 16px 16px 12px"
        borderLeft={`2px solid ${borderColorByNotifVariant[props.variant]}`}
        bgcolor={palette.secondary[800]}
        borderRadius="0px 4px 4px 0px"
        width={312}
        direction={'row'}
      >
        <Box display={'flex'} alignItems={'center'} height={'22px'} marginRight={'8px'}>
          {notifIconMapping[props.variant]}
        </Box>
        <Stack width={'100%'}>
          <Typography sx={textStyles} fontWeight={500} variant={'subtitle1'}>
            {props.title}
          </Typography>
          <Stack gap={'24px'}>
            <Typography sx={textStyles} color={palette.secondary[300]} variant={'subtitle1'}>
              {props.subtitle}
            </Typography>
            {props.description && (
              <Typography sx={textStyles} color={palette.secondary[400]} variant={'subtitle1'}>
                {props.description}
              </Typography>
            )}
            {props.actionButton && (
              <Button
                onClick={() => {
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  props.actionButton.onClick(props.id);
                }}
                disableRipple
                sx={{ width: 'fit-content' }}
                color={'secondary'}
                variant={'text'}
              >
                {props.actionButton.title}
              </Button>
            )}
          </Stack>
        </Stack>
        <Box
          justifySelf={'flex-end'}
          color={palette.secondary[400]}
          width={20}
          height={20}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Icon
            onClick={() => {
              closeSnackbar(props.id);
            }}
            color={'inherit'}
            cursor={'pointer'}
            fontSize={'small'}
            name={EIcon.Close}
          ></Icon>
        </Box>
      </Stack>
    </SnackbarContent>
  );
});

export default ToastNotification;

const textStyles = { width: '230px', wordWrap: 'break-word' };
