import FeatureDataDistribution from './FeatureDataDistribution';
import RocCurve from './RocCurve';

type JobAiModelAvancedProps = {
  jobId: number;
  modelId: string;
};
export default function JobAiModelAvanced({ jobId, modelId }: JobAiModelAvancedProps) {
  return (
    <>
      <RocCurve jobId={jobId} modelId={modelId} />
      <FeatureDataDistribution jobId={jobId} modelId={modelId} />
    </>
  );
}
