import { useMemo } from 'react';
import { config } from '../config';
import { RouteType } from '../components/shared/models/route/RouteType';

// eslint-disable-next-line
const { useTheme: useThemeFromTheme } = require('./' + config.branding.folder + '/theme.hook');

export type UseThemeType = {
  getStaticRoutes: () => Array<RouteType>;
  getMetaTitle: () => string;
  getMetaDescription: () => string;
  getHomepageType: () => HomepageType;
};

type ThemeType = {
  isOrange: boolean;
  isSiscale: boolean;
};

export type HomepageType = 'jobs' | 'cards';

export const useTheme = (): UseThemeType & ThemeType => {
  const isOrange = useMemo(() => config.branding.folder === 'orange', []);
  const isSiscale = useMemo(() => config.branding.folder === 'siscale', []);
  const { getStaticRoutes, getMetaTitle, getMetaDescription, getHomepageType } = useThemeFromTheme();

  return {
    getStaticRoutes,
    getMetaTitle,
    getMetaDescription,
    getHomepageType,
    isOrange,
    isSiscale
  };
};
