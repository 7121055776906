import { Box, MenuItem, Select, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import { EIcon, Icon, Spinner } from '@arcanna/generic';

type TTimeSelectorOption = {
  value: string;
  label: string;
};

type TTimeSelectorProps = {
  options: TTimeSelectorOption[];
  currentValue: string;
  isLoading?: boolean;
  width?: number;
  handleChange: (event: SelectChangeEvent<never>) => void;
};

function TimeSelector({ options, currentValue, isLoading, width, handleChange }: TTimeSelectorProps) {
  const { palette } = useTheme();

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={0} width={width}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={palette.secondary[800]}
        border={`1px solid ${palette.secondary[600]}`}
        width="44px"
        height="36px"
        borderRadius="4px 0px 0px 4px"
        fontSize="16px"
      >
        <Icon name={EIcon.Calendar} fontSize="inherit" htmlColor={palette.secondary[200]} />
      </Box>
      <Select
        // @ts-ignore
        value={currentValue}
        onChange={handleChange}
        fullWidth
        sx={{
          opacity: isLoading ? 0.5 : 1,
          pointerEvents: isLoading ? 'none' : 'auto',
          borderRadius: '0px 4px 4px 0px'
        }}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            <Stack direction="row" gap={1} justifyContent="flex-start" alignItems="center">
              {isLoading ? (
                <Spinner />
              ) : (
                <Typography
                  fontSize="14px"
                  lineHeight="16px"
                  fontWeight={600}
                  color={currentValue === option.value ? palette.secondary[300] : palette.secondary[400]}
                >
                  {option.label}
                </Typography>
              )}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

export default TimeSelector;
