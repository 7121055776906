import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('Bucket')
export class Bucket {
  @JsonProperty('key', String)
  key?: string;

  @JsonProperty('doc_count', Number)
  doc_count?: number;
}

@JsonObject('RecommendedValue')
export class RecommendedValue {
  @JsonProperty('doc_count_error_upper_bound', Number)
  doc_count_error_upper_bound?: number;

  @JsonProperty('sum_other_doc_count', Number)
  sum_other_doc_count?: number;

  @JsonProperty('buckets', [Bucket])
  buckets?: Bucket[];
}

@JsonObject('EventExplorerFiltersValuesResponse')
export class EventExplorerFiltersValuesResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('recommended_values', Any)
  recommended_values?: Record<string, RecommendedValue>;
}
