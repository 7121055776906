import { EIcon, Icon, Label } from '@arcanna/generic';
import { CustomLabelPill } from '@arcanna/components';
import { Button, Stack } from '@mui/material';
import Joi from 'joi';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomLabelPillMenu } from 'src/_srcMUI/shared/forms/RHFPillField/components';
import { customLabelColors } from './components/CustomLabelPillMenu/CustomLabelPillMenu.constants';
import { TRHFPill } from './RHFPillField.types';
import { FormHelperText } from '@mui/material';
import { getPillId } from './RHFPillField.utils';

type TRHFPillFieldProps = {
  name: string;
  disabled?: boolean;
  label?: string;
  tooltipText?: string;
};

function RHFPillField({ name, label, tooltipText, disabled }: TRHFPillFieldProps) {
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitting }
  } = useFormContext();

  const allPills = watch(name) as TRHFPill[];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [selectedColor, setSelectedColor] = useState<string>('');
  const [newLabel, setNewLabel] = useState<string>('');
  const [customError, setCustomError] = useState<string>('');
  const [selectedPillIndex, setSelectedPillIndex] = useState<number>(-1);
  const addPillButtonRef = useRef(null);
  const { t } = useTranslation('common');

  const labelSchema = Joi.string()
    .required()
    .min(3)
    .messages({
      'string.empty': t('decisionLabelRequired'),
      'any.required': t('decisionLabelRequired'),
      'string.min': t('decisionLabelMinLength')
    });

  const handleClose = () => {
    setSelectedColor('');
    setNewLabel('');
    setCustomError('');
    setAnchorEl(null);
    setSelectedPillIndex(-1);
  };

  const validateAndSet = (value: string) => {
    const { error } = labelSchema.validate(value);
    setCustomError(error ? error.details[0].message : '');
    setNewLabel(value);
  };

  const isLabelUnique = (value: string) => {
    if (selectedPillIndex < 0) {
      return true;
    }

    const currentWorkingPill = allPills[selectedPillIndex];

    return allPills.every((pill) => pill.name !== value.trim() || pill.name === currentWorkingPill?.name);
  };

  const handleSave = () => {
    if (customError) {
      return;
    }

    if (!isLabelUnique(newLabel)) {
      setCustomError(t('decisionLabelMustBeUnique'));

      return;
    }

    const pillId = getPillId(allPills);

    if (selectedPillIndex === -1) {
      setValue(
        name,
        [
          {
            id: pillId,
            name: newLabel.trim(),
            hexColor: selectedColor
          }
        ],
        { shouldValidate: true, shouldDirty: true }
      );
      handleClose();

      return;
    }
    if (selectedPillIndex === allPills?.length) {
      setValue(
        name,
        [
          ...allPills,
          {
            id: pillId,
            name: newLabel.trim(),
            hexColor: selectedColor,
            isDeletable: true
          }
        ],
        { shouldValidate: true, shouldDirty: true }
      );
      handleClose();

      return;
    }
    const newPills = [...(allPills ?? [])];

    newPills.splice(selectedPillIndex, 1, {
      id: newPills[selectedPillIndex].id || pillId,
      name: newLabel.trim(),
      hexColor: selectedColor,
      isDeletable: true
    });
    setValue(name, newPills, { shouldValidate: true, shouldDirty: true });
    handleClose();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack direction="column" gap={0.5}>
          {label && <Label required text={label} tooltipText={tooltipText} />}
          {field?.value?.length ? (
            <Stack gap="4px" direction="row" flexWrap="wrap" marginBottom={0.5}>
              {field.value.map((pill: TRHFPill, index: number) => (
                <CustomLabelPill
                  key={index}
                  label={pill.name}
                  color={pill.hexColor}
                  isMenuOpen={isMenuOpen && selectedPillIndex === index}
                  onEditClick={(value: HTMLElement | null) => {
                    setSelectedColor(pill.hexColor);
                    setNewLabel(pill.name);
                    setAnchorEl(value);
                    setSelectedPillIndex(index);
                  }}
                  onDelete={() => {
                    setValue(
                      name,
                      field.value.filter((_: TRHFPill, i: number) => i !== index),
                      { shouldValidate: true, shouldDirty: true }
                    );
                  }}
                  isDisabled={isSubmitting || selectedPillIndex === index || disabled || !pill.isDeletable}
                  isDeletable={pill.isDeletable}
                />
              ))}
            </Stack>
          ) : (
            <></>
          )}
          {!disabled && (
            <>
              <Button
                ref={addPillButtonRef}
                variant="contained"
                color="secondary"
                size="small"
                sx={{ alignSelf: 'flex-start' }}
                onClick={() => {
                  setSelectedColor(customLabelColors[0]);
                  setNewLabel('');
                  setAnchorEl(addPillButtonRef.current);
                  setSelectedPillIndex(allPills?.length ?? -1);
                }}
                endIcon={<Icon name={EIcon.Plus} fontSize="small" />}
                disabled={isSubmitting}
                data-test-id="add-label"
              >
                {t('add')}
              </Button>
              <CustomLabelPillMenu
                anchorEl={anchorEl}
                isMenuOpen={isMenuOpen}
                handleClose={handleClose}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                newLabel={newLabel}
                customError={customError}
                handleSave={handleSave}
                validateAndSet={validateAndSet}
              />
            </>
          )}
          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </Stack>
      )}
    />
  );
}

export default RHFPillField;
