import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { GaugeChart } from '../Visualisation/GaugeChart';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';
import { useVisualizationsContext } from './Visualizations.context';

export function QueryGaugeChart(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // eslint-disable-next-line
  const [buckets, setBuckets] = useState(Array<any>());

  const jsonConvert = useMemo(() => getJsonConvert(), []);
  // const [gaugeData, setGaugeData] = useState<GaugeChartProps>(undefined);
  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    // eslint-disable-next-line
    let responseBuckets: Array<any> = [];
    const aggregations = visualizationRecord.aggs;
    for (const key in aggregations) {
      if (aggregations[key].buckets === undefined) {
        responseBuckets = responseBuckets.concat({
          key: props.name,
          score: aggregations[key].value,
          style: visualizationRecord.style
        });
      } else {
        // eslint-disable-next-line
        aggregations[key].buckets.forEach((bucket: any) => {
          bucket.style = visualizationRecord.style;
        });
        responseBuckets = responseBuckets.concat(aggregations[key].buckets);
      }
    }
    setBuckets(responseBuckets);
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div>
          <div className="usecase-card-title">{props.name}</div>
          <div>
            {buckets.map((bucket) => (
              <GaugeChart key={bucket.key + Math.random()} value={bucket.score} />
            ))}
          </div>
        </div>
      </Spin>
    </>
  );
}
