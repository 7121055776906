import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('PipelineResponse')
export class PipelineResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('total_events', Number)
  totalEvents: number;

  @JsonProperty('enrichment_actions', Number)
  enrichmentActions: number;

  @JsonProperty('post_decision_actions', Number)
  postDecisionActions: number;
}
