import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Aggregation')
export class Aggregation {
  @JsonProperty('count', Number)
  count: number;

  @JsonProperty('category_name', String)
  category_name: string;
}
