import * as React from 'react';
import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { ActionListResponse } from '../../components/shared/models/homepage/ActionListResponse';
import { ActionListRequest } from '../../components/shared/models/homepage/ActionListRequest';
import { config } from '../../config';

export function useActionList(category: string | undefined) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<ActionListResponse, Error>([config.api.actionList, category], () =>
    axios
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
      .post<{ resource: ActionListResponse }>(config.api.actionList, new ActionListRequest(category))
      .then((responseData: AxiosResponse<{ resource: ActionListResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, ActionListResponse)
      )
      .then((data: ActionListResponse) => ({
        ...data,
        actions: [...data.actions].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      }))
  );
}
