import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { IntegrationReadRecord } from './IntegrationReadRecord';

@JsonObject('IntegrationReadResponse')
export class IntegrationReadResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [IntegrationReadRecord])
  entries?: IntegrationReadRecord[] = [];
}
