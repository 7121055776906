import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { BucketsRequiringAttention } from './BucketsRequiringAttention';

@JsonObject('BucketsRequiringAttentionResponse')
export class BucketsRequiringAttentionResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('buckets', BucketsRequiringAttention)
  buckets: BucketsRequiringAttention;

  @JsonProperty('total', Number)
  total: number;
}
