import { Button, IconButton, Menu, Slider, Stack, ToggleButton, ToggleButtonGroup, Tooltip, useTheme } from '@mui/material';
import { Icon, EIcon, Spinner } from '@arcanna/generic';
import { MouseEvent, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { TABLE_SIZES } from './TableSizeSelector.constants';
import { getTableSizeSelectorOptions } from './TableSizeSelector.utils';
import { useTranslation } from 'react-i18next';

type TTableSizeSelectorProps = {
  tableSize: string;
  customRowSizeValue: number;
  handleTableSizeChange: (tableSize: string) => void;
  hasMinimizedView?: boolean;
  handleCustomRowSizeChange?: (customRowSizeValue: number) => void;
  isFakeLoading?: boolean;
};

function TableSizeSelector({
  tableSize,
  customRowSizeValue,
  handleTableSizeChange,
  hasMinimizedView,
  handleCustomRowSizeChange,
  isFakeLoading
}: TTableSizeSelectorProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tempCustomRowSizeValue, setTempCustomRowSizeValue] = useState<number>(customRowSizeValue || 2);
  const isMenuOpen = Boolean(anchorEl);

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    setTempCustomRowSizeValue(newValue as number);
  };

  const handleApplyCustomRowSize = () => {
    handleCustomRowSizeChange?.(tempCustomRowSizeValue);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tableSizeSelectorOptions = useMemo(() => getTableSizeSelectorOptions(t, hasMinimizedView), [hasMinimizedView, t]);

  const isTempCustomRowSizeValueDifferent = useMemo(
    () => tempCustomRowSizeValue !== customRowSizeValue,
    [customRowSizeValue, tempCustomRowSizeValue]
  );

  return (
    <>
      <Tooltip arrow placement="top" title={t('common:table.adjustTableSize')}>
        <IconButton
          sx={{
            height: '16px',
            width: '16px',
            color: theme.palette.secondary[200]
          }}
          onClick={handleClick}
        >
          <Icon fontSize="small" name={EIcon.Layout} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Stack
          direction="column"
          maxWidth="100%"
          padding="14px 12px"
          border={`1px solid ${theme.palette.secondary[600]}`}
          bgcolor={theme.palette.secondary[900]}
          borderRadius="4px"
          gap="16px"
        >
          {isFakeLoading && <Spinner isOverlay />}
          <Typography variant="subtitle1" sx={{ paddingBottom: 1, borderBottom: `1px solid ${theme.palette.secondary[600]}` }}>
            {t('common:table.rowSize')}
          </Typography>
          <ToggleButtonGroup value={tableSize} color="primary" exclusive>
            {tableSizeSelectorOptions.map((tableSizeOption) => (
              <ToggleButton
                key={tableSizeOption.key}
                value={tableSizeOption.value}
                data-test-id={tableSizeOption.dataTestId}
                onClick={() => handleTableSizeChange(tableSizeOption.value)}
              >
                {tableSizeOption.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {tableSize === TABLE_SIZES.custom && (
            <Stack gap={1}>
              <Typography variant="subtitle2" color={theme.palette.secondary[200]}>
                {t('common:table.linesPerRowPlaceholder', { placeholder: customRowSizeValue })}
              </Typography>
              <Slider
                value={tempCustomRowSizeValue}
                min={2}
                max={20}
                step={1}
                shiftStep={20}
                size="small"
                valueLabelDisplay="auto"
                onChange={handleSliderChange}
              />
              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={!isTempCustomRowSizeValueDifferent}
                onClick={handleApplyCustomRowSize}
                sx={{ width: '80px', mt: '4px' }}
              >
                {t('common:apply')}
              </Button>
            </Stack>
          )}
        </Stack>
      </Menu>
    </>
  );
}

export default TableSizeSelector;
