import { Integration } from 'src/components/pages/Main/Jobs/components/JobDraft/JobDraftReducer';
import { IntegrationTypes } from 'src/components/shared/models/integration/IntegrationTypes';
import IntegrationPriority from '../components/IntegrationFlowDrawer/IntegrationPriority';

export function findProcessorIndex(integrations: Integration[]) {
  let processorIndex = integrations.findIndex((integration) => integration.integrationType == IntegrationTypes.PROCESSOR);
  if (processorIndex == -1) {
    processorIndex = integrations.length - 1;
  }
  return processorIndex;
}

export function getNewIntegrationIndex(integrationType: string, currentIntegrations: Integration[]) {
  if (IntegrationPriority.BEFORE_PROCESSOR_INTEGRATION_TYPES.includes(integrationType || '')) {
    const newIndex = findProcessorIndex(currentIntegrations);
    return newIndex;
  }
  if (IntegrationPriority.AFTER_PROCESSOR_INTEGRATION_TYPES.includes(integrationType || '')) {
    return currentIntegrations.length - 1;
  }
  return currentIntegrations.length - 1;
}
