import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { LicenseInfoRecord } from './LicenseInfoRecord';

@JsonObject('LicenseInfoResponse')
export class LicenseInfoResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('license', LicenseInfoRecord)
  license?: LicenseInfoRecord = undefined;
}
