import { Stack, Typography, useTheme } from '@mui/material';
import IntegrationRoleTag from 'src/components/shared/components/IntegrationRoleTag';
import { IntegrationSubcategoryRecord } from 'src/components/shared/models/integration/subcategory/IntegrationSubcategoryRecord';
import StyledIntegrationImage from './StyledIntegrationImage';

function IntegrationHeading({ integrationSubcategory }: { integrationSubcategory: IntegrationSubcategoryRecord }) {
  const theme = useTheme();

  return (
    <>
      <Stack direction="row" gap={2}>
        <StyledIntegrationImage>
          <img src={integrationSubcategory.base_64_logo} alt={integrationSubcategory.caption} width={42} height={42} />
        </StyledIntegrationImage>

        <Stack direction="column" gap={1} justifyContent="space-between">
          <Typography variant="body2">{integrationSubcategory.caption}</Typography>
          <Stack direction="row" gap={1}>
            {integrationSubcategory.roles?.map((role) => <IntegrationRoleTag key={role} role={role} />)}
          </Stack>
        </Stack>
      </Stack>
      {integrationSubcategory.info && (
        <Typography variant="subtitle1" color={theme.palette.secondary[300]}>
          {integrationSubcategory.info}
        </Typography>
      )}
    </>
  );
}

export default IntegrationHeading;
