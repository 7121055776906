/* eslint-disable max-len */
function EnrichmentModal() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#14B8A6" fillOpacity="0.1" />
      <path
        d="M15.5002 17.5831L17.1668 19.2498L20.9168 15.4998M24.6668 17.9998C24.6668 22.0902 20.2052 25.0651 18.5818 26.0122C18.3973 26.1198 18.3051 26.1737 18.1749 26.2016C18.0739 26.2232 17.9265 26.2232 17.8254 26.2016C17.6952 26.1737 17.603 26.1198 17.4185 26.0122C15.7951 25.0651 11.3335 22.0902 11.3335 17.9998V14.0145C11.3335 13.3482 11.3335 13.0151 11.4425 12.7287C11.5387 12.4758 11.6951 12.25 11.8982 12.0711C12.1281 11.8685 12.44 11.7515 13.0638 11.5176L17.532 9.84204C17.7052 9.77707 17.7919 9.74459 17.881 9.73171C17.96 9.72029 18.0403 9.72029 18.1193 9.73171C18.2085 9.74459 18.2951 9.77707 18.4683 9.84204L22.9365 11.5176C23.5603 11.7515 23.8722 11.8685 24.1021 12.0711C24.3052 12.25 24.4616 12.4758 24.5579 12.7287C24.6668 13.0151 24.6668 13.3482 24.6668 14.0145V17.9998Z"
        stroke="#14B8A6"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EnrichmentModal;
