import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';
import { BarChartProps } from './Barchart';
import { FilterProperty } from './FilterProps';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import { useUtils } from '../../hooks/useUtils';
import styles from './Visualisation.module.css';

export function BarchartStacked(props: BarChartProps) {
  const visualizationContext = useVisualizationsContext();
  const { overrideOwnStyleAttributes } = useUtils();
  const [hoveredLegendValue, setHoveredLegendValue] = useState<string>('');
  const tooltipWidth = 550;
  const utils = useUtils();

  const ownStyleAttributes = {
    height: '440px',
    legendHeight: '44px',
    legendContent: 'list'
  };

  // eslint-disable-next-line
  const axisElementClicked = (el: any) => {
    const value = el.name != null ? el.name : el.value;
    const filterProps: FilterProperty = {
      key: props.filterFields[0],
      source: props.filterFields[0],
      values: [],
      selected: value
    };

    visualizationContext.appendClickableFilters(filterProps);
    visualizationContext.updateApplyTrigger();
  };

  const legendElementClicked = (value: string) => () => {
    const filterProps: FilterProperty = {
      key: props.filterFields[1],
      source: props.filterFields[1],
      values: [],
      selected: value
    };

    visualizationContext.appendClickableFilters(filterProps);
    visualizationContext.updateApplyTrigger();
  };

  const legendElementMouseEnter = (value: string) => () => {
    if (value in props.tooltips) {
      setHoveredLegendValue(value);
    }
  };

  const legendElementMouseLeave = () => {
    setHoveredLegendValue('');
  };

  const legendFormatter = (value: string) => (
    <span
      style={{
        width: 'auto',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        marginBottom: '-8px',
        paddingRight: '12px',
        textAlign: 'center'
      }}
    >
      {value}
    </span>
  );

  // eslint-disable-next-line
  const renderLegend = (data: any) => {
    const { payload } = data;
    return (
      <ul style={{ paddingLeft: '7px', textAlign: 'center' }}>
        {/* eslint-disable-next-line */}
        {payload.map((entry: any, index: number) => (
          <li
            key={`item-${index}`}
            style={{ listStyleType: 'none' }}
            onClick={legendElementClicked(entry.value)}
            onKeyPress={legendElementClicked(entry.value)}
            onMouseEnter={legendElementMouseEnter(entry.value)}
            onMouseLeave={legendElementMouseLeave}
            role="tab"
            tabIndex={index}
          >
            <div>
              <div
                style={{
                  width: '280px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                  marginBottom: '-8px',
                  paddingRight: '12px',
                  textAlign: 'left'
                }}
              >
                <span style={{ display: 'inline-block' }}>
                  <svg width="12" height="12">
                    <rect width="12" height="12" fill={entry.color} />
                  </svg>
                </span>
                <span>
                  &nbsp;
                  {entry.value}
                </span>
              </div>
              {hoveredLegendValue === entry.value && (
                <div
                  className={`${styles.barchartStackedLegendTooltip}
                      ${utils.colorClassNameForKey(props.tooltips[entry.value])}`}
                >
                  {props.tooltips[entry.value]}
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  // Show tooltip onscreen.
  // This does a DOM change, because recharts offers small posibilities regarding this.
  const handleTooltipPosition = () => {
    const elements = document.getElementsByClassName('recharts-tooltip-wrapper') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];
      const rect = element.getBoundingClientRect();
      if (rect.x + tooltipWidth > window.outerWidth) {
        if (element.style.getPropertyValue('width') === `${tooltipWidth}px`) {
          element.style.setProperty('left', `-${tooltipWidth}px`);
          element.style.setProperty('position', 'absolute');
        }
      }
    }
  };

  if (props.data != null) {
    overrideOwnStyleAttributes(ownStyleAttributes, props);

    const useLegendFormatter =
      ownStyleAttributes.legendContent === 'spread' ? { formatter: legendFormatter } : { content: renderLegend };

    return (
      <div style={{ height: ownStyleAttributes.height }}>
        <ResponsiveContainer width="99.8%">
          <BarChart
            width={400}
            height={300}
            data={props.data}
            layout="vertical"
            stackOffset="sign"
            margin={{
              top: 5,
              right: 10,
              left: 50,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" style={{ maxHeight: '100px' }} />
            <XAxis type="number" />
            <YAxis type="category" dataKey="name" onClick={axisElementClicked} />
            <Tooltip wrapperStyle={{ width: `${tooltipWidth}px` }} />
            <Legend
              wrapperStyle={{
                width: '100%',
                overflowY: 'auto',
                height: ownStyleAttributes.legendHeight,
                left: 0,
                paddingBottom: '10px'
              }}
              {...useLegendFormatter}
            />
            <ReferenceLine x={0} stroke="#000" />
            {props.colors.map((entry, index) => (
              <Bar
                key={`bar-${index}`}
                dataKey={props.keys[index]}
                fill={`${entry}`}
                stackId="stack"
                onClick={axisElementClicked}
                onMouseEnter={handleTooltipPosition}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return <></>;
}
