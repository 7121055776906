import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { JobModelHistoryResponse } from '../../components/shared/models/retrain-history/JobModelHistoryResponse';

export function useJobRetrainHistory(jobId: number, modelId: string) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobModelHistoryResponse, Error>(
    [config.api.jobModel.replace(':jobId', jobId.toString()).replace(':modelId', modelId)],
    () =>
      axios
        .post<{
          resource: JobModelHistoryResponse;
        }>(config.api.jobModel.replace(':jobId', jobId.toString()).replace(':modelId', modelId), new CommonRequest())
        .then((responseData: AxiosResponse<{ resource: JobModelHistoryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobModelHistoryResponse)
        ),
    {
      enabled: Boolean(jobId) && Boolean(modelId)
    }
  );
}
