import { TComponentsOverrides, TPaletteOverrides } from '../../branding.types';

export const getMuiTableStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiTable: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-root': {
          color: palette.secondary[100],
          borderRight: `1px solid ${palette.secondary[700]}`,
          borderBottom: `1px solid ${palette.secondary[700]}`
        },

        '& .MuiTableHead-root > tr:first-child > .MuiTableCell-root': {
          borderTop: `1px solid ${palette.secondary[700]}`
        },

        '& tr > .MuiTableCell-root:first-child': {
          borderLeft: `1px solid ${palette.secondary[700]}`
        },

        '& .MuiTableCell-sizeSmall': {
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '20px',
          minHeight: '28px',
          padding: '3px 16px'
        },

        '& .MuiTableBody-zebra > .MuiTableRow-root:nth-child(odd)': {
          backgroundColor: palette.secondary[1000]
        },
        '& .MuiTableBody-zebra > .MuiTableRow-root:nth-child(even)': {
          backgroundColor: palette.secondary[900]
        },
        '& .MuiTableHead-zebra > .MuiTableRow-head:nth-child(odd) .MuiTableCell-head': {
          backgroundColor: palette.secondary[1000]
        },
        '& .MuiTableHead-zebra > .MuiTableRow-head:nth-child(even) .MuiTableCell-head': {
          backgroundColor: palette.secondary[900]
        }
      }
    }
  }
});
