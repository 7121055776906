import { IntegrationCreateParameter } from '../../../../../../../components/shared/models/integration/IntegrationCreateParameter';
import {
  getParameterKey,
  useParameterAllowedValues,
  useParameterTypes
} from '../../../../../../../components/pages/IntegrationCreate/hooks/useParameterInfo';
import { IntegrationUpdateRequest } from '../../../../../../../components/shared/models/integration/IntegrationUpdateRequest';
import { IntegrationReadRecord } from '../../../../../../../components/shared/models/integration/IntegrationReadRecord';
import { useCallback } from 'react';

export default function useEditPayload({ integration }: { integration?: IntegrationReadRecord }) {
  // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
  const parameterTypes = useParameterTypes({ subcategoryId: integration?.subcategory_id });
  // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
  const parameterAllowedValues = useParameterAllowedValues({ subcategoryId: integration?.subcategory_id });

  const getIntegrationInfoParameter = useCallback(
    (fieldName: string) => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const integrationParameters = integration.parameters;
      return integrationParameters.find((param: IntegrationCreateParameter) => param.field == fieldName);
    },
    [integration]
  );

  const getEditPayload = useCallback(
    (values: unknown) => {
      const parameters: IntegrationCreateParameter[] = [];
      try {
        // @ts-expect-error TS(2769): No overload matches this call.
        Object.keys(values)
          .filter((key) => key !== 'title')
          .forEach((fieldName: string) => {
            const parameterInfo = getIntegrationInfoParameter(fieldName);
            // eslint-disable-next-line
            const allValues = values as any;
            const currentValue = allValues[fieldName].toString();

            const previousValue = parameterInfo?.value;
            // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
            const parameterKey: string = getParameterKey({
              allowedValues: parameterAllowedValues,
              fieldName: fieldName,
              value: currentValue
            });
            parameters.push({
              field: fieldName,
              value: currentValue,
              type: parameterTypes[fieldName],
              key: parameterKey,
              is_vaulted: parameterInfo !== undefined ? parameterInfo.is_vaulted && currentValue == previousValue : false,
              mappings: []
            });
          });
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error:', error);
      }
      return new IntegrationUpdateRequest(
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        integration.subcategory_id,
        // eslint-disable-next-line
        (values as any).title,
        parameters
      );
    },
    [getIntegrationInfoParameter, parameterAllowedValues, parameterTypes, integration]
  );

  return { getEditPayload };
}
