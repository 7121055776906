import { TruncatedText } from '@arcanna/components';
import { StateChip } from '@arcanna/generic';
import { Palette } from '@mui/material/styles';
import { createColumnHelper } from '@tanstack/react-table';
import { TFunction } from 'react-i18next';
import { formatDateTime } from 'src/components/shared/utilities/date';
import { TEventHistoryRow } from './EventHistory.types';
import _ from 'lodash';

type TGetColumnsParams = {
  palette: Palette;
  t: TFunction;
};

function getColumns({ palette }: TGetColumnsParams) {
  const columnHelper = createColumnHelper<TEventHistoryRow>();

  return [
    columnHelper.accessor('component', {
      header: 'Component',
      cell: ({ row }) => <TruncatedText variant="subtitle2" text={row.original.component} />,
      minSize: 70,
      maxSize: 80
    }),
    columnHelper.accessor('integration', {
      header: 'Integration',
      cell: ({ row }) => <TruncatedText variant="subtitle2" text={row.original.integration} />,
      minSize: 100,
      maxSize: 130
    }),
    columnHelper.accessor('timestamp', {
      header: 'Timestamp',
      cell: ({ row }) => <TruncatedText variant="subtitle2" text={formatDateTime(row.original.timestamp)} />,
      minSize: 100,
      maxSize: 120
    }),
    columnHelper.accessor('level', {
      header: 'Level',
      cell: ({ row }) => {
        const mapLevelToColor = {
          info: palette.vividBlue[500],
          warn: palette.warning[600],
          error: palette.error[600],
          debug: palette.grey[300]
        };

        return <StateChip color={mapLevelToColor[row.original.level]} text={_.capitalize(row.original.level)} />;
      },
      size: 50
    }),
    columnHelper.accessor('message', {
      header: 'Message',
      cell: ({ row }) => <TruncatedText variant="subtitle2" text={row.original.message} />,
      minSize: 200
    })
  ];
}

export { getColumns };
