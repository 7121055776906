import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useJobIdFromUrl = () => {
  const { id } = useParams<{ id: string }>();
  const jobId = +id;

  return useMemo(() => ({ jobId }), [jobId]);
};

export { useJobIdFromUrl };
