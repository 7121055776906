import { JsonObject, JsonProperty } from 'json2typescript';

import { JobDetailRecord } from './JobDetailRecord';
import { JobStatusRecord } from './JobStatusRecord';

@JsonObject('JobSummaryRecord')
export class JobSummaryRecord {
  @JsonProperty('id', Number)
  id: number | undefined;

  @JsonProperty('title', String)
  title: string | undefined;

  @JsonProperty('description', String)
  description: string | undefined;

  @JsonProperty('status', JobStatusRecord)
  status?: JobStatusRecord = undefined;

  @JsonProperty('job_details', JobDetailRecord)
  jobDetails?: JobDetailRecord = undefined;
}
