import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { FilterProperty } from './FilterProps';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';

export interface TimeSeriesChartProps {
  // eslint-disable-next-line
  data: Array<any>;
  keys: Array<string>;
  colors: Array<string>;
  // eslint-disable-next-line
  style: any;
  filterFields: Array<string>;
}

export function TimeseriesChart(props: TimeSeriesChartProps) {
  const visualizationContext = useVisualizationsContext();

  // eslint-disable-next-line
  const elementClicked = (el: any) => {
    const value = el.name != null ? el.name : el.value;
    const filterProps: FilterProperty = {
      key: props.filterFields[0],
      source: props.filterFields[0],
      values: [],
      selected: value
    };

    visualizationContext.appendClickableFilters(filterProps);
    visualizationContext.updateApplyTrigger();
  };

  if (props.data != null) {
    const style = {
      height: '315px'
    };

    // TODO: Find a generic way of passing style attributes
    if (props.style.height) {
      style.height = props.style.height;
    }

    return (
      <div style={style}>
        <ResponsiveContainer width="100%">
          <LineChart
            width={500}
            height={300}
            data={props.data}
            stackOffset="sign"
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              domain={['auto', 'auto']}
              name="Time"
              tickFormatter={(unixTime) => moment(unixTime).format('HH:mm')}
              type="number"
            />
            <YAxis />
            <Tooltip labelFormatter={(unixTime) => moment(unixTime).format('YYYY-MM-DD HH:mm ')} />
            <Legend onClick={elementClicked} />
            {props.colors.map((entry, index) => (
              <Line
                key={`${props.keys[index]}`}
                type="linear"
                dataKey={`${props.keys[index]}`}
                stroke={`${props.colors[index]}`}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return <></>;
}
