import { JsonObject, JsonProperty } from 'json2typescript';
import { EventExplorerBatchColumnRecord } from './EventExplorerBatchColumnRecord';

@JsonObject('EventExplorerBatchRecordChild')
export class EventExplorerBatchRecordChild {
  @JsonProperty('row_id', String)
  row_id: string | undefined;

  @JsonProperty('result_index', String)
  result_index: string | undefined;

  @JsonProperty('label', String)
  label: string | undefined;

  @JsonProperty('nn_label', String)
  nn_label: string | undefined;

  @JsonProperty('columns', [EventExplorerBatchColumnRecord])
  columns: EventExplorerBatchColumnRecord[] = [];
}
