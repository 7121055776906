export function OverviewIcon({ color }: { color?: string }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line
        d="M17.5 17.5H3.83333C3.36662 17.5 3.13327 17.5 2.95501 17.4092C2.79821 17.3293 2.67072 17.2018 2.59083 17.045C2.5 16.8667 2.5 16.6334 2.5 16.1667V2.5M16.6667 12.5L13.4009 9.01445C13.2772 8.88236 13.2153 8.81631 13.1406 8.78217C13.0747 8.75204 13.0021 8.73961 12.93 8.74611C12.8482 8.75348 12.7679 8.79519 12.6072 8.87861L9.89277 10.2881C9.73212 10.3715 9.65179 10.4132 9.57003 10.4206C9.49787 10.4271 9.42526 10.4146 9.35936 10.3845C9.28471 10.3504 9.22282 10.2843 9.09906 10.1522L5.83333 6.66667"
        stroke={color ? color : 'var(--secondary-color-nb-300)'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
