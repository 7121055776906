import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobInfoDetailRecord')
export class JobInfoDetailRecord {
  @JsonProperty('category', String)
  category?: string = undefined;

  @JsonProperty('category_id', Number)
  category_id?: number = undefined;

  @JsonProperty('subcategory', String)
  subcategory?: string = undefined;

  @JsonProperty('subcategory_id', Number)
  subcategory_id?: number = undefined;
}
