import { JsonObject, JsonProperty } from 'json2typescript';
import { BucketsBreakdown } from './BucketsBreakdown';

@JsonObject('CurrentFeedbackBreakdownResponse')
export class CurrentFeedbackBreakdownResponse {
  @JsonProperty('buckets', [BucketsBreakdown])
  buckets: [BucketsBreakdown];

  @JsonProperty('total', Number)
  total: number;
}
