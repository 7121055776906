import { SVGProps } from 'react';

type MessageChatIconProps = SVGProps<SVGSVGElement> & {
  fill?: string;
};
export function MessageChatIcon({ fill = 'none', ...props }: MessageChatIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill} {...props}>
      <g clipPath="url(#clip0_14539_16025)">
        <path
          //eslint-disable-next-line max-len
          d="M8.33329 12.4998L5.77058 15.0946C5.41311 15.4565 5.23438 15.6375 5.08075 15.6502C4.94747 15.6613 4.81697 15.6077 4.72992 15.5062C4.62959 15.3892 4.62959 15.1348 4.62959 14.6261V13.3262C4.62959 12.8698 4.25585 12.5396 3.8043 12.4734V12.4734C2.71141 12.3134 1.85311 11.4551 1.69302 10.3622C1.66663 10.182 1.66663 9.96693 1.66663 9.53687V5.6665C1.66663 4.26637 1.66663 3.56631 1.93911 3.03153C2.17879 2.56112 2.56124 2.17867 3.03165 1.93899C3.56643 1.6665 4.26649 1.6665 5.66663 1.6665H11.8333C13.2334 1.6665 13.9335 1.6665 14.4683 1.93899C14.9387 2.17867 15.3211 2.56112 15.5608 3.03153C15.8333 3.56631 15.8333 4.26637 15.8333 5.6665V9.1665M15.8333 18.3332L14.0196 17.0722C13.7647 16.895 13.6372 16.8064 13.4985 16.7435C13.3753 16.6878 13.2459 16.6472 13.1129 16.6227C12.9632 16.5951 12.8079 16.5951 12.4974 16.5951H11C10.0665 16.5951 9.59983 16.5951 9.24331 16.4134C8.92971 16.2536 8.67474 15.9987 8.51495 15.6851C8.33329 15.3285 8.33329 14.8618 8.33329 13.9284V11.8332C8.33329 10.8998 8.33329 10.433 8.51495 10.0765C8.67474 9.76292 8.92971 9.50795 9.24331 9.34816C9.59983 9.1665 10.0665 9.1665 11 9.1665H15.6666C16.6 9.1665 17.0668 9.1665 17.4233 9.34816C17.7369 9.50795 17.9918 9.76292 18.1516 10.0765C18.3333 10.433 18.3333 10.8997 18.3333 11.8332V14.0951C18.3333 14.8716 18.3333 15.2599 18.2064 15.5662C18.0373 15.9746 17.7128 16.2991 17.3044 16.4682C16.9981 16.5951 16.6099 16.5951 15.8333 16.5951V18.3332Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14539_16025">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
