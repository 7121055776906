import { JsonObject, JsonProperty } from 'json2typescript';
import { LOCAL_STORAGE_TOKEN_NAME } from '../../../pages/Main/Login/AuthConstants';

@JsonObject('CustomFieldNextSequenceNumberRequest')
export class CustomFieldNextSequenceNumberRequest {
  @JsonProperty('user_session_id', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  user_session_id: string = undefined;

  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('input_field', String)
  input_field: string;

  constructor(job_id: number, input_field: string) {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    this.user_session_id = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
    this.job_id = job_id;
    this.input_field = input_field;
  }
}
