import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { LOCAL_STORAGE_REFRESH_TOKEN_NAME } from '../../../pages/Main/Login/AuthConstants';

@JsonObject('RefreshTokenRequest')
export class RefreshTokenRequest extends CommonRequest {
  @JsonProperty('refresh_token', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  refresh_token: string = undefined;

  constructor() {
    super();
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    this.refresh_token = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_NAME);
  }
}
