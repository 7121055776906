import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import useRollbackInProgress from 'src/components/pages/Main/Jobs/OverviewRevamp/JobSummary/useRollbackInProgress';
import { useHasFeaturesSelected, useJobInfo, useRetrainJob } from '../../../../data-access';
import Button from '../Button/Button';
import styles from './styles.module.css';
import { config } from '../../../../config';
import { JobCollectingDataSpinner } from '../JobState/Tags/JobCollectingDataSpinner/JobCollectingDataSpinner';
import FlashIcon from '../../../../themes/icons/FlashIcon';
import useIsRetrainAvailable from '../../../../data-access/job/useIsRetrainAvailable';
import { JobIsRetrainAvailableResponse } from '../../models/job/JobIsRetrainAvailableResponse';
import { useJobNavigate } from '../../hooks/useJobNavigate';
import { Popconfirm } from 'antd';
import { permissions } from '../../static/ComponentsPermissions';
import PermissionsCheck from '../Permissions/Permissions';
import { EJobTrainingState } from '../../models/job/EJobTrainingState';

type RetrainButtonType = {
  jobId: number;
};

export function RetrainButton({ jobId }: RetrainButtonType) {
  const { t } = useTranslation(['retrain']);
  const jobRetrainMutation = useRetrainJob();
  const jobInfoQuery = useJobInfo(jobId);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [isTrainError, setIsTrainError] = React.useState<boolean>(false);
  const [buttonPressed, setButtonPressed] = React.useState<number>(0);
  const [bucketUpdateTriggered, setBucketsUpdateTriggered] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const hasFeaturesSelectedQuery = useHasFeaturesSelected(jobId);
  const { isRollbackInProgress } = useRollbackInProgress({ jobId });

  const { navigateToJobFeatureSelection } = useJobNavigate();
  const [showConfirm, setShowConfirm] = React.useState(false);

  const isRetrainAvailableQuery = useIsRetrainAvailable(jobId);

  const retrainEnabled =
    (isRetrainAvailableQuery.data ? isRetrainAvailableQuery.data.enabled : false) &&
    jobRetrainMutation.status !== 'loading' &&
    jobInfoQuery?.data?.info?.status?.retrain_state !== EJobTrainingState.AUTOMATIC_RETRAIN &&
    !isRollbackInProgress;

  const handleOk = () => {
    setShowConfirm(false);
    navigateToJobFeatureSelection(`${jobId}`, t('job:navigation.train'));
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  React.useEffect(() => {
    if (jobInfoQuery.data !== null) {
      setInProgress(jobInfoQuery.data?.info?.status?.retrain_state === EJobTrainingState.RETRAIN_IN_PROGRESS);
      setIsTrainError(jobInfoQuery.data?.info?.status?.retrain_state === EJobTrainingState.ERROR);
    }
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (jobInfoQuery.data?.info.status.buckets_model_update_in_progress && !bucketUpdateTriggered) {
      setBucketsUpdateTriggered(true);
      setButtonPressed((prevButtonPressed) => prevButtonPressed + 1);
    }
  }, [bucketUpdateTriggered, jobInfoQuery.data, buttonPressed, jobId, queryClient]);

  const handleRetrainJob = React.useCallback(() => {
    setInProgress(true);
    setButtonPressed((prevButtonPressed) => prevButtonPressed + 1);
    // @ts-expect-error TS(2345): Argument of type '(oldData: JobIsRetrainAvailableR...
    queryClient.setQueryData([config.api.isJobRetrainAvailable, { jobId: jobId }], (oldData: JobIsRetrainAvailableResponse) => ({
      request: oldData.request,
      enabled: false
    }));
    jobRetrainMutation.mutate(jobId);
    // eslint-disable-next-line
  }, [jobId, jobRetrainMutation]);

  const retrainButtonClassNames = React.useMemo(() => {
    if (inProgress) {
      return styles.retrainButtonProgress;
    }
    if (buttonPressed > 0 || bucketUpdateTriggered || !retrainEnabled) {
      return styles.retrainButtonDisabled;
    }
    return styles.retrainButton;
    // eslint-disable-next-line
  }, [inProgress, buttonPressed, retrainEnabled]);

  const retrainButtonIcon = React.useMemo(() => {
    if (inProgress) {
      return <JobCollectingDataSpinner />;
    }
    return <FlashIcon fill="var(--utilitarian-color-orange-600)" style={{ color: 'var(--utilitarian-color-orange-600)' }} />;
  }, [inProgress]);

  return (
    <span>
      <PermissionsCheck permissions={[permissions.jobAction]} action={permissions.action.disabledAction}>
        {!hasFeaturesSelectedQuery.data ? (
          <Popconfirm
            title={t('job:retrain.featuresNotSelectedConfirm')}
            open={showConfirm}
            onConfirm={handleOk}
            onCancel={handleCancel}
          >
            <Button
              className={styles.retrainButton}
              type="outlined"
              text={t('common:retrainDisabled')}
              size="small"
              iconPosition="right"
              dataTestId="retrain-button"
              onClick={() => setShowConfirm(true)}
            ></Button>
          </Popconfirm>
        ) : (
          <Button
            className={retrainButtonClassNames}
            title={t('retrain:trainNow')}
            text={t('retrain:trainNow')}
            type="outlined"
            size="small"
            icon={retrainButtonIcon}
            iconPosition="right"
            onClick={handleRetrainJob}
            disabled={!isTrainError && (buttonPressed > 0 || bucketUpdateTriggered || !retrainEnabled)}
            data-test-id="retrain-button"
          />
        )}
      </PermissionsCheck>
    </span>
  );
}
