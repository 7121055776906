type EllipsisPosition = 'start' | 'end' | 'middle';

export default function addEllipsisOnLongText(text: string, limit = 40, ellipsisPosition: EllipsisPosition = 'end') {
  if (text.length < limit) {
    return text;
  }

  if (ellipsisPosition === 'start') {
    return '...' + text.slice(text.length - limit, text.length);
  }

  if (ellipsisPosition === 'end') {
    return text.slice(0, limit) + '...';
  }

  if (ellipsisPosition === 'middle') {
    return text.slice(0, 15) + '...' + text.slice(text.length - limit + 15, text.length);
  }
}
