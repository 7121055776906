/* eslint-disable max-len */
function TransitionArrowRight() {
  return (
    <svg width="75" height="10" viewBox="0 0 75 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M74.5 5L67.5 0.958548V9.04145L74.5 5ZM0.5 5.7H68.2V4.3H0.5V5.7Z" fill="currentColor" />
    </svg>
  );
}

export default TransitionArrowRight;
