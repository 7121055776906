import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { BucketAlertsEventsHistogram } from './BucketAlertsEventsHistogram';

@JsonObject('BucketAlertsTimeseriesResponse')
export class BucketAlertsTimeseriesResponse {
  @JsonProperty('interval_events_count', Number)
  intervalEventsCount: number;

  @JsonProperty('total_events_count', Number)
  totalEventsCount: number;

  @JsonProperty('events_histogram', [BucketAlertsEventsHistogram])
  eventsHistogram: [BucketAlertsEventsHistogram];

  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;
}
