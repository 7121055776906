import { JsonObject, JsonProperty } from 'json2typescript';
import ResponseCommon from './ResponseCommon';
import RequestCommon from './RequestCommon';

// RECORDS
@JsonObject('PaginationRequestRecord')
class PaginationRequestRecord {
  @JsonProperty('offset', Number)
  offset: number;

  @JsonProperty('size', Number)
  size: number;
}

type TConstructPaginationRequestRecord = {
  offset: PaginationRequestRecord['offset'];
  size: PaginationRequestRecord['size'];
};

function constructPaginationRequestRecord({ offset, size }: TConstructPaginationRequestRecord) {
  const instance = new PaginationRequestRecord();
  instance.offset = offset;
  instance.size = size;

  return instance;
}

@JsonObject('PaginationResponseRecord')
class PaginationResponseRecord {
  @JsonProperty('total_entries', Number)
  totalEntries: number;
}

type TConstructPaginationResponseRecord = {
  totalEntries: PaginationResponseRecord['totalEntries'];
};

function constructPaginationResponseRecord({ totalEntries }: TConstructPaginationResponseRecord) {
  const instance = new PaginationResponseRecord();
  instance.totalEntries = totalEntries;

  return instance;
}

// --------------------------------------------------------------------------------

@JsonObject('PaginationCommonRequest')
class PaginationCommonRequest extends RequestCommon {
  @JsonProperty('pagination', PaginationRequestRecord)
  pagination: PaginationRequestRecord;
}

type TConstructPaginationCommonRequest = {
  pagination: TConstructPaginationRequestRecord;
};

function constructPaginationCommonRequest({ pagination }: TConstructPaginationCommonRequest) {
  const instance = new PaginationCommonRequest();
  instance.pagination = constructPaginationRequestRecord(pagination);

  return instance;
}

@JsonObject('PaginationCommonResponse')
class PaginationCommonResponse extends ResponseCommon {
  @JsonProperty('pagination')
  pagination: PaginationResponseRecord;
}

type TConstructPaginationCommonResponse = {
  pagination: TConstructPaginationResponseRecord;
};

function constructPaginationCommonResponse({ pagination }: TConstructPaginationCommonResponse) {
  const instance = new PaginationCommonResponse();
  instance.pagination = constructPaginationResponseRecord(pagination);

  return instance;
}

export { PaginationCommonRequest, PaginationCommonResponse, constructPaginationCommonResponse, constructPaginationCommonRequest };
