import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../../config';
import style from './JobNavigation.module.css';

type JobOverviewNavItemProps = {
  jobId: string;
};
export default function JobOverviewNavItem({ jobId }: JobOverviewNavItemProps) {
  const { t } = useTranslation(['job']);

  return (
    <NavLink
      to={config.routes.jobOverView.replace(':id', jobId)}
      className={cx([style.navLink, 'callout-default'])}
      activeClassName={cx([style.activeNavLink, 'callout-default'])}
      data-test-id={'menu-expanded-overview'}
    >
      {t('job:navigation.overview')}
    </NavLink>
  );
}
