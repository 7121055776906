import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { JobIsRetrainAvailableRequest } from '../../components/shared/models/job/JobIsRetrainAvailableRequest';
import { JobIsRetrainAvailableResponse } from '../../components/shared/models/job/JobIsRetrainAvailableResponse';

export default function useIsRetrainAvailable(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.isJobRetrainAvailable;

  return useQuery<JobIsRetrainAvailableResponse, Error>(
    [url, { jobId }],
    () =>
      axios
        .post<{ resource: JobIsRetrainAvailableResponse }>(url, new JobIsRetrainAvailableRequest(jobId))
        .then((responseData: AxiosResponse<{ resource: JobIsRetrainAvailableResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobIsRetrainAvailableResponse)
        ),
    {
      enabled: Boolean(jobId)
    }
  );
}
