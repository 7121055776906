import { Menu } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { TFilterItem, TFilterItemWithValue } from '../../../../AdvancedFilters.types';
import AdvancedFiltersMenu from '../../../AdvancedFiltersMenu';
import FilterMenu from '../FilterMenu';
import { getIsFilterWithValue, transformValueByValueType } from '../../../../AdvancedFilters.utils';
import { Icon, EIcon, TOption } from '@arcanna/generic';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import StyledFilterChip from './StyledFilterChip.styles';
import { useTranslation } from 'react-i18next';
import addEllipsisOnLongText from 'src/components/shared/utilities/addEllipsisOnLongText';
import { EMPTY_STRING_VALUE } from '../../../../AdvancedFilters.constants';

type TFilterChipProps = {
  filter: TFilterItem;
  fieldOptions: (FeedbackFiltersFieldsRecord & TOption)[];
  operatorOptionsMap: Record<string, TOption[]>;
  onDelete: (filterId: string) => void;
  onUpdate: (filter: TFilterItem) => void;
  getValueOptions: (field: string) => Promise<TOption[]>;
};

function FilterChip({ filter, fieldOptions, operatorOptionsMap, onDelete, onUpdate, getValueOptions }: TFilterChipProps) {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuType, setMenuType] = useState<'quickMenu' | 'editForm'>('quickMenu');
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setMenuType('quickMenu');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFilterValueLabel = () => {
    if (filter.valueLabel) {
      return filter.valueLabel;
    }

    if (getIsFilterWithValue(filter)) {
      if ((filter as TFilterItemWithValue).value === '') {
        return EMPTY_STRING_VALUE;
      }

      const filterValue = transformValueByValueType(filter) || '';
      const filterValueLabel =
        typeof filterValue === 'string'
          ? filterValue
          : `(${filterValue?.map((item) => (item === '' ? EMPTY_STRING_VALUE : item)).join(', ')})`;

      return filterValueLabel;
    }

    return '';
  };

  return (
    <>
      <StyledFilterChip
        label={`${filter.label || filter.field} ${filter.operator}
         ${addEllipsisOnLongText(getFilterValueLabel(), 50)}`}
        onClick={handleClick}
        onDelete={() => onDelete(filter.id)}
        deleteIcon={<Icon name={EIcon.ChipClose} />}
        status={filter.status}
      />
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {menuType === 'editForm' ? (
          <AdvancedFiltersMenu
            filter={filter}
            onSave={(newFilter) => {
              onUpdate(newFilter);
              handleClose();
            }}
            onCancel={() => handleClose()}
            fieldOptions={fieldOptions}
            operatorOptionsMap={operatorOptionsMap}
            getValueOptions={getValueOptions}
          />
        ) : (
          <FilterMenu
            onEdit={() => setMenuType('editForm')}
            onDelete={() => {
              onDelete(filter.id);
              handleClose();
            }}
            onStatusChange={() => {
              onUpdate({ ...filter, status: filter.status === 'active' ? 'disabled' : 'active' });
              handleClose();
            }}
            statusLabel={filter.status === 'active' ? t('advancedFilters.disableFilter') : t('advancedFilters.enableFilter')}
            editLabel={t('advancedFilters.editFilter')}
            deleteLabel={t('advancedFilters.deleteFilter')}
            statusIcon={filter.status === 'active' ? EIcon.EyeSlashed : EIcon.Eye}
          />
        )}
      </Menu>
    </>
  );
}

export default FilterChip;
