import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Operator')
export class Operator {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('is_interval', Boolean)
  is_interval: boolean;

  @JsonProperty('types', [String])
  types: string[];
}
