import { init as initApm } from '@elastic/apm-rum';
import { config } from './config';

const apm = initApm({
  active: config.apmActive === 'true',
  serviceName: config.apmServiceName,
  serviceVersion: '1',
  serverUrl: config.apmServerUrl
});

export default apm;
