import { useEffect, useState } from 'react';
import { Button } from 'antd';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { DownloadOutlined } from '@ant-design/icons';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import { ExportRecord } from '../../models/usecase/ExportRecord';
import { config } from '../../../../config';

export function ExportPdf(props: Array<ExportRecord>) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line
  const clientLogo = require('../../../../themes/' + config.branding.folder + '/logo.png');

  const generatePdf = () => {
    const applyFilterBtn = document.getElementById('apply_filter_btn');
    const downloadBtn = document.getElementById('pdf_download');
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    applyFilterBtn.hidden = true;
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    downloadBtn.hidden = true;

    const input = document.getElementById('usecase-canvas');
    // @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig...
    html2canvas(input, {
      allowTaint: true,
      useCORS: true,
      removeContainer: true,
      backgroundColor: '#000000'
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      // Here are the number for an A4 page size.
      const headerOffset = 20;
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      // eslint-disable-next-line
      const doc = new jsPDF('p', 'mm');

      doc.setFillColor('#000000');
      doc.rect(0, 0, imgWidth, pageHeight, 'F');
      doc.addImage(clientLogo, 10, 4, 10, 10);
      let position = headerOffset;
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight - headerOffset);
      heightLeft -= pageHeight;
      // add page by page untill full height is reached.
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.rect(0, 0, canvas.width, canvas.height, 'F');
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight - headerOffset);
        heightLeft -= pageHeight;
      }
      doc.save('file.pdf');
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      applyFilterBtn.hidden = false;
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      downloadBtn.hidden = false;
    });
  };

  useEffect(() => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (visualisationContext.state.loadedVisuals === visualisationContext.state.page.visualizations.length) {
      setLoading(false);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    } else if (visualisationContext.state.loadedVisuals <= visualisationContext.state.page.visualizations.length) {
      setLoading(true);
    }
    // eslint-disable-next-line
  }, [visualisationContext.state.loadedVisuals, props]);

  return (
    <span className="primary-item">
      <Button id="pdf_download" type="primary" icon={<DownloadOutlined />} onClick={generatePdf} loading={loading}>
        Download
      </Button>
    </span>
  );
}

ExportPdf.defaultProps = {
  kibana_space: undefined,
  attributes: undefined
};
