import { Button, StackProps, Typography } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';
import StyledErrorPage from '../StyledErrorPage';

type TInternal500ErrorProps = StackProps & {
  goHome: () => void;
};

function Internal500Error({ goHome }: TInternal500ErrorProps) {
  // useTranslation is not working reliable when coming from  ErrorBoundary
  return (
    <StyledErrorPage alignItems="center" textAlign="center" gap="16px" padding="24px" mt="124px">
      <Typography variant="body1" fontWeight="600">
        Oops!
      </Typography>
      <Typography fontSize="272px">
        <Icon fontSize="inherit" name={EIcon.InternalError} />
      </Typography>
      <Typography className="pageNotFound">Something went wrong</Typography>
      <Typography variant="body2" className="detailMessage">
        There were some technical issues while processing your request.
      </Typography>
      <Button className="homeButton" variant="contained" color="primary" onClick={goHome}>
        Take me home
      </Button>
    </StyledErrorPage>
  );
}

export default Internal500Error;
