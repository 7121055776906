import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from 'src/components/shared/models/common/CommonRequest';

@JsonObject('UnifiedDecisionPointsListFeatureRecord')
export class UnifiedDecisionPointsListFeatureRecord {
  @JsonProperty('id', String)
  id: string;

  @JsonProperty('name', String)
  name: string;

  @JsonProperty('type', String)
  type: string;

  @JsonProperty('caption', String)
  caption: string;

  @JsonProperty('active', Boolean)
  active: boolean;

  constructor(id: string, name: string, type: string, caption: string, active: boolean) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.caption = caption;
    this.active = active;
  }
}

@JsonObject('UnifiedDecisionPointsListUpdateRecord')
export class UnifiedDecisionPointsListUpdateRecord {
  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('features', [UnifiedDecisionPointsListFeatureRecord])
  features: UnifiedDecisionPointsListFeatureRecord[];

  constructor(jobId: number, features: UnifiedDecisionPointsListFeatureRecord[]) {
    this.job_id = jobId;
    this.features = features;
  }
}

@JsonObject('UnifiedDecisionPointsListUpdate')
export class UnifiedDecisionPointsListUpdate extends CommonRequest {
  @JsonProperty('updates', [UnifiedDecisionPointsListUpdateRecord])
  updates: UnifiedDecisionPointsListUpdateRecord[];

  constructor(updates: UnifiedDecisionPointsListUpdateRecord[]) {
    super();
    this.updates = updates;
  }
}
