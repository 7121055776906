import { Stack, StackProps, styled } from '@mui/material';

const StyledSystemNotificationTextArea = styled(Stack, {
  shouldForwardProp(propName) {
    return propName != 'backgroundColor';
  }
})<
  StackProps & {
    backgroundColor: string;
  }
>(({ backgroundColor }) => ({
  flexDirection: 'row',
  width: '100%',
  height: 72,
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: backgroundColor,
  padding: '24px 22px'
}));

export default StyledSystemNotificationTextArea;
