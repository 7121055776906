import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('BucketUserLabelEntry')
export class BucketUserLabelEntry {
  @JsonProperty('username', String)
  username?: string;

  @JsonProperty('label_timestamp', String)
  labelTimestamp?: string;

  @JsonProperty('label', String)
  label?: string;

  @JsonProperty('is_overwritten', Boolean)
  isOverwritten?: boolean;

  constructor(username: string, labelTimestamp: string, label: string, isOverwritten: boolean) {
    this.username = username;
    this.labelTimestamp = labelTimestamp;
    this.label = label;
    this.isOverwritten = isOverwritten;
  }
}

/**
 * @param {string}  text - the bucket summary generated by the AI.
 * @param {string=} onDemandRequest - when bucket summary is on demand (not automatic). You can generate the summary
 * by clicking on a button in the bucket expand under summary section. The summary will not be available immediately so
 * we set a flag to show that summary will be available soon.
 */
@JsonObject('BucketSummarization')
export class BucketSummarization {
  @JsonProperty('text', String)
  text?: string;

  @JsonProperty('on_demand_request', Boolean)
  onDemandRequest?: boolean;

  constructor(text: string, onDemandRequest: boolean) {
    this.text = text;
    this.onDemandRequest = onDemandRequest;
  }
}

@JsonObject('BucketUserLabels')
export class BucketUserLabels {
  @JsonProperty('entries', [BucketUserLabelEntry])
  entries?: BucketUserLabelEntry[];
}

@JsonObject('BucketConsensusOverwritten')
export class BucketConsensusOverwritten {
  @JsonProperty('consensus_overwritten', Boolean)
  consensusOverwritten?: boolean;

  @JsonProperty('consensus_overwritten_by', String)
  consensusOverwrittenBy?: string;

  @JsonProperty('consensus_overwritten_date', String)
  consensusOverwrittenDate?: string;
}

@JsonObject('BucketDecisionPoint')
export class BucketDecisionPoint {
  @JsonProperty('feature_name', String)
  featureName?: string;

  @JsonProperty('value', String)
  value?: string;
}

@JsonObject('BucketDetails')
export class BucketDetails {
  @JsonProperty('bucket_id', String)
  bucketId?: string;

  @JsonProperty('timestamp', String)
  timestamp?: string;

  @JsonProperty('clean_record', String)
  cleanRecord?: string;

  @JsonProperty('result', String)
  result?: string;

  @JsonProperty('key_hash', String)
  keyHash?: number;

  @JsonProperty('features_hash', String)
  featuresHash?: string;

  @JsonProperty('full_hash', String)
  fullHash?: string;

  @JsonProperty('model', Object)
  model?: object;

  @JsonProperty('user_labels', BucketUserLabels)
  userLabels?: BucketUserLabels;

  @JsonProperty('consensus', String)
  consensus?: string;

  @JsonProperty('consensus_overwritten', BucketConsensusOverwritten)
  consensusOverwritten?: BucketConsensusOverwritten;

  @JsonProperty('state', String)
  state?: string;

  @JsonProperty('summarization', BucketSummarization)
  summarization?: BucketSummarization;

  @JsonProperty('training_status', String)
  training_status?: string;

  @JsonProperty('training_label', String)
  training_label?: string;

  @JsonProperty('decision_points', [BucketDecisionPoint])
  decisionPoints?: BucketDecisionPoint[];
}

@JsonObject('FeedbackBucketDetailsResponse')
export class FeedbackBucketDetailsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('bucket', BucketDetails)
  bucket: BucketDetails;
}
