import { useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useFetch } from '../shared/hooks/useFetch';
import { getBackendEndpoint } from '../shared/utilities/api';
import { useApp } from './App.context';
import { getJsonConvert } from '../shared/utilities/json-convert';
import { CommonRequest } from '../shared/models/common/CommonRequest';
import { useAuth } from '../pages/Main/Login/Auth.context';
import { config } from '../../config';
import { UserPermissionsResponse } from '../shared/models/auth/UserPermissionsResponse';

export function useAppHook() {
  const {
    state: appState,
    setHasUserPermissions,
    setUserPermissions,
    setUserPermissionsLoaded,
    setReloadUserPermissions,
    hasAccessFor
  } = useApp();
  const { state: authState } = useAuth();
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const history = useHistory();
  const location = useLocation();

  const { post: postUserPermissions } = useFetch({
    path: getBackendEndpoint('/user/permissions'),
    load: false
  });

  useEffect(() => {
    if (!location.pathname.startsWith('/login')) {
      setReloadUserPermissions(true);
    }
  }, [location.pathname, setReloadUserPermissions]);

  useEffect(() => {
    if (appState.reloadUserPermissions === false) {
      return;
    }
    setReloadUserPermissions(false);
    if (appState.userPermissionsLoaded) {
      return;
    }
    if (authState.userSessionId === null) {
      setUserPermissions([]);
    } else {
      postUserPermissions(new CommonRequest())
        // eslint-disable-next-line
        .then((response: any) => {
          const respJson = jsonConvert.deserializeObject(response.data.resource, UserPermissionsResponse);
          if (respJson.permissions) {
            setUserPermissions(respJson.permissions);
            setUserPermissionsLoaded(true);
          } else {
            setUserPermissions([]);
            setUserPermissionsLoaded(true);
          }
        })
        .catch(() => {
          setUserPermissions([]);
          setUserPermissionsLoaded(true);
          history.push(config.routes.unavailable);
        });
    }
  }, [
    postUserPermissions,
    jsonConvert,
    authState.userSessionId,
    appState.reloadUserPermissions,
    appState.userPermissionsLoaded,
    hasAccessFor,
    setUserPermissionsLoaded,
    setUserPermissions,
    setHasUserPermissions,
    setReloadUserPermissions,
    history
  ]);

  useEffect(() => {
    if (appState.userPermissionsLoaded) {
      if (hasAccessFor(['HOME_READ'])) {
        setHasUserPermissions(true);
      } else {
        setHasUserPermissions(false);
      }
    }
  }, [hasAccessFor, setHasUserPermissions, appState.userPermissionsLoaded, appState.userPermissions]);

  useEffect(() => {
    if (appState.userPermissionsLoaded && location.pathname.startsWith('/login')) {
      if (appState.hasUserPermissions) {
        setTimeout(() => window.location.assign(config.routes.homepage), 100);
      } else {
        setTimeout(() => window.location.reload(), 1000);
      }
    }
  }, [history, appState.userPermissionsLoaded, appState.hasUserPermissions, location.pathname]);

  useEffect(() => {
    if (authState.forcePasswordChange) {
      history.push(config.routes.changePassword);
    }
  }, [authState.forcePasswordChange, history]);

  // Get the existing favicon by it's id
  const getFaviconEl = () => document.getElementById('favicon');
  const [faviconLoaded, setFaviconLoaded] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line
    const clientFavicon = '/themes/' + config.branding.folder + '/favicon.ico';

    // eslint-disable-next-line
    const favicon: any = getFaviconEl(); // Accessing favicon element
    // Change the default Siscale Favicon with the client favicon
    favicon.href = clientFavicon;
    setFaviconLoaded(true);
  }, [faviconLoaded]);
}
