import { Divider } from 'antd';
import { FeatureDistribution } from '../../../../../../../../shared/models/retrain-history/JobMetricsResponse';
import AvailableValues from '../AvailableValues';
import UsedValuesInTraining from '../UsedValuesInTraining';
import OccurencesList from '../OccurencesList';
import { Button } from '../../../../../../../../shared/components';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

type FeatureDistributionCardProps = {
  featureDistribution: FeatureDistribution;
  displayAllOccurences?: boolean;
  onViewAll?: (featureDistribution: FeatureDistribution) => void;
  rootStyle?: CSSProperties;
};
export function FeatureDistributionCard({
  featureDistribution,
  displayAllOccurences = false,
  onViewAll,
  rootStyle
}: FeatureDistributionCardProps) {
  const { t } = useTranslation(['overview']);

  const featureDistributionOccurences = featureDistribution.occurences ?? [];

  return (
    <div key={featureDistribution.featureName} className={styles.root} style={rootStyle}>
      <div>
        <span className="callout-medium color-nb-100">{featureDistribution.featureName}</span>

        <Divider className="m-t-12 m-b-12 color-nb-400" />

        <AvailableValues featureDistribution={featureDistribution} />

        <UsedValuesInTraining featureDistribution={featureDistribution} />

        {featureDistributionOccurences.length > 0 ? (
          <>
            <Divider className="m-t-20 m-b-20 color-nb-400" />

            <span className="callout color-nb-300 m-t-16">{t('overview:topValuesUsedInTraining')}</span>

            <OccurencesList displayAllOccurences={displayAllOccurences} occurences={featureDistributionOccurences} />
          </>
        ) : null}
      </div>
      {!displayAllOccurences && featureDistributionOccurences.length > 0 ? (
        <div className={styles.action}>
          <Divider className="color-nb-400" />
          {featureDistributionOccurences.length > 5 ? (
            <Button
              size="small"
              type="secondaryNew"
              text={t('overview:viewAll')}
              onClick={() => onViewAll && onViewAll(featureDistribution)}
            />
          ) : (
            // set height to keep divider above aligned
            <div style={{ height: 35 }} />
          )}
        </div>
      ) : null}
    </div>
  );
}
