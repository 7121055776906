import {
  DeleteButton,
  InlineNotification,
  IntegrationCreateHealthCheck,
  IntegrationForm,
  IntegrationHeading
} from '@arcanna/components';
import { Spinner } from '@arcanna/generic';
import { Stack, Typography } from '@mui/material';
import { useDeleteIntegration, useIntegrationInfo, useIntegrationSubcategoryListId, useUpdateIntegration } from 'src/data-access';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import useEditPayload from '@arcanna/pages/AddJob/components/IntegrationUpdateModal/IntegrationEditModal/hooks/useEditPayload.hook';

type TIntegrationModalEditProps = {
  onClose: () => void;
  integrationId: number;
  onUpdateSuccess?: () => void;
  confirmBtnText?: string;
  closeBtnText?: string;
  isDeleteEnabled?: boolean;
};

function IntegrationEditSection({
  onClose,
  integrationId,
  onUpdateSuccess,
  confirmBtnText,
  closeBtnText,
  isDeleteEnabled
}: TIntegrationModalEditProps) {
  const { t } = useTranslation(['job-create', 'common', 'integration']);
  const integrationInfoQuery = useIntegrationInfo({ integrationId });
  const [showModalSteps, setShowModalSteps] = useState(false);
  const [integrationParams, setIntegrationParams] = useState<unknown>();
  const updateIntegrationMutation = useUpdateIntegration({ integrationId });

  const deleteIntegrationMutation = useDeleteIntegration();
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const integration = integrationInfoQuery.data?.entries[0];
  // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
  const subcategoryIntegrationQuery = useIntegrationSubcategoryListId({ id: integration?.subcategory_id });
  const subcategoryIntegration = subcategoryIntegrationQuery?.data;
  const { getEditPayload } = useEditPayload({ integration });

  const handleSubmit = async (values: unknown) => {
    const payload = getEditPayload(values);
    // strange behaviour here, need to catch and then throw the error again, otherwise it is not thrown
    await updateIntegrationMutation.mutateAsync(payload, {
      onSuccess: () => onUpdateSuccess?.()
    });
  };

  const startValidation = useCallback(async (values: unknown) => {
    setShowModalSteps(true);
    setIntegrationParams(values);
  }, []);

  const handleDelete = useCallback(async () => {
    const response = await deleteIntegrationMutation.mutateAsync(Number(integrationId));
    if (response.status === 200) {
      onClose();
    }
  }, [deleteIntegrationMutation, integrationId, onClose]);

  const renderDeleteButton = useCallback(
    () =>
      isDeleteEnabled && (
        <DeleteButton
          handleDelete={handleDelete}
          modalTitle={t('integration:deleteIntegration')}
          modalBody={t('integration:confirmDelete')}
        />
      ),
    [handleDelete, isDeleteEnabled, t]
  );

  return (
    <>
      {(subcategoryIntegrationQuery.isFetching || deleteIntegrationMutation.isLoading) && <Spinner isOverlay />}
      {subcategoryIntegration && (
        <Stack direction="column" gap={2}>
          <IntegrationHeading integrationSubcategory={subcategoryIntegration} />
          {subcategoryIntegration.parameters.length ? (
            <>
              <Typography variant="body2">{t('job-create:createIntegrationForm.parameters')}</Typography>

              <IntegrationForm
                parameters={subcategoryIntegration.parameters}
                onCancel={onClose}
                initialIntegration={integration}
                onSubmit={startValidation}
                closeBtnText={closeBtnText}
                confirmBtnText={confirmBtnText}
                actionButton={renderDeleteButton()}
                integrationSubcategory={subcategoryIntegration}
                getRequestPayload={getEditPayload as <IntegrationEditRequest>(values: unknown) => IntegrationEditRequest}
              />
              {showModalSteps && (
                <IntegrationCreateHealthCheck
                  onSuccessCheck={() => {
                    onClose();
                  }}
                  onClose={() => {
                    setShowModalSteps(false);
                  }}
                  onCredentialsCheck={() => handleSubmit(integrationParams)}
                />
              )}
            </>
          ) : (
            <>
              <InlineNotification type="info" title={t('createIntegrationForm.noOptionsToEditIntegration')} />
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Button color="secondary" variant="tertiary" onClick={onClose} sx={{ alignSelf: 'flex-start' }}>
                  {closeBtnText ?? t('common:cancel')}
                </Button>
                {renderDeleteButton()}
              </Stack>
            </>
          )}
        </Stack>
      )}
    </>
  );
}

export default IntegrationEditSection;
