import { Stack, StackProps, styled } from '@mui/material';

const StyledCollapsible = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'size' && propName !== 'hasBorder' && propName !== 'isExpanded' && propName !== 'hasSubtitle';
  }
})<
  StackProps & {
    size: 'extra-small' | 'small' | 'large';
    hasBorder?: boolean;
    isExpanded: boolean;
    hasSubtitle?: boolean;
  }
>(({ size, hasBorder, isExpanded, theme, hasSubtitle }) => ({
  ...(hasBorder
    ? {
        border: `1px solid ${theme.palette.secondary[700]}`,

        '& .collapsible-heading': {
          borderBottom: `1px solid ${theme.palette.secondary[700]}`,
          marginBottom: isExpanded ? '0' : '-1px'
        }
      }
    : {}),

  '& > .collapsible-heading > .collapsible-trigger': {
    minWidth: 'unset',
    padding: 0,
    ...(size === 'extra-small' && {
      height: '16px',
      width: '16px'
    }),
    ...(size === 'small' && { height: '20px', width: '20px' }),
    ...(size === 'large' && { height: '24px', width: '24px' }),
    ...(hasBorder
      ? {
          marginLeft: '8px'
        }
      : {}),

    '& > svg': {
      ...(size === 'small'
        ? {
            height: '14px',
            width: '14px'
          }
        : {
            height: '16px',
            width: '16px'
          })
    }
  },

  ...(hasSubtitle && {
    '& > .collapsible-heading': {
      marginBottom: '0'
    }
  })
}));

export default StyledCollapsible;
