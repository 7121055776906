import { JsonObject, JsonProperty } from 'json2typescript';
import { FeedbackStatisticsBreakdownItem } from './FeedbackStatisticsBreakdownItem';

@JsonObject('FeedbackStatisticsBreakdownResponse')
export class FeedbackStatisticsBreakdownResponse {
  @JsonProperty('confirmed', Number)
  confirmed?: number = undefined;

  @JsonProperty('changed', Number)
  changed?: number = undefined;

  @JsonProperty('total', Number)
  total?: number = undefined;

  @JsonProperty('have_trained_model', Boolean)
  have_trained_model?: boolean;

  @JsonProperty('labels_breakdown', [FeedbackStatisticsBreakdownItem])
  labels_breakdown?: [FeedbackStatisticsBreakdownItem];
}
