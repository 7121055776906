import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { config } from '../../../../../../config';

export function useOverviewReload() {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  return () => {
    queryClient.resetQueries([config.api.jobRetrainMetrics]);
    queryClient.resetQueries([config.api.jobModel]);
    queryClient.resetQueries([config.api.jobAllRetrainHistory]);
    queryClient.resetQueries([config.api.feedbackStatisticsUsers]);
    queryClient.resetQueries([config.api.feedbackStatisticsEventsBreakdown]);
    queryClient.resetQueries([config.api.feedbackStatisticsLabelsUsed]);
    queryClient.resetQueries([config.api.feedbackStatisticsKbIncrease, { jobId: +id }]);
  };
}
