import { useMemo } from 'react';
import { useIntegrationSubcategoryListId } from '../../../../data-access';
import { IntegrationJobParameterRecord } from '../../../shared/models/integration/job/IntegrationJobParameterRecord';
import { IntegrationAllowedValueRecord } from '../../../shared/models/integration/subcategory/IntegrationAllowedValueRecord';
import { IntegrationCreateMappingRecord } from '../../../shared/models/integration/subcategory/IntegrationCreateMappingRecord';

type useParameterHookData = {
  subcategoryId: number;
};

export function useParameterTypes({ subcategoryId }: useParameterHookData) {
  const subcategoryIntegrationQuery = useIntegrationSubcategoryListId({ id: subcategoryId });

  return useMemo(() => {
    if (subcategoryIntegrationQuery.data) {
      const subcategoryParameters = subcategoryIntegrationQuery.data.parameters;
      const typesDictionary = Object.assign(
        {},
        // @ts-expect-error TS(2464): A computed property name must be of type 'string',...
        ...subcategoryParameters.map((parameter) => ({ [parameter.field]: parameter.type }))
      );

      return typesDictionary;
    }

    return {};
  }, [subcategoryIntegrationQuery.data]);
}

export function useParameterAllowedValues({ subcategoryId }: useParameterHookData) {
  const subcategoryIntegrationQuery = useIntegrationSubcategoryListId({ id: subcategoryId });

  return useMemo(() => {
    if (subcategoryIntegrationQuery.data) {
      const subcategoryParameters = subcategoryIntegrationQuery.data.parameters;
      const allowedValuesMapping: Record<string, Record<string, string>> = {};
      subcategoryParameters.forEach((parameter: IntegrationJobParameterRecord) => {
        const allowedValuesForParam: Record<string, string> = {};
        if (parameter.allowed_values != null) {
          parameter.allowed_values.forEach((entry: IntegrationAllowedValueRecord) => {
            // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
            allowedValuesForParam[entry.value] = entry.key;
          });
        }
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        allowedValuesMapping[parameter.field] = allowedValuesForParam;
      });

      return allowedValuesMapping;
    }
    return {};
  }, [subcategoryIntegrationQuery.data]);
}

type parameterKeyData = {
  allowedValues: Record<string, Record<string, string>>;
  fieldName: string;
  value: string;
};

export function getParameterKey({ allowedValues, fieldName, value }: parameterKeyData) {
  if (allowedValues[fieldName] != null) {
    return allowedValues[fieldName][value];
  }
  return null;
}

export function isValueEmpty(value?: string) {
  return value === null || value === '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getMappings(fieldName: string, fieldType: string, allValues: any, onError: () => void) {
  let fromValues: string[] = [];
  let toValues: string[] = [];
  if (fieldType == 'mapping') {
    fromValues = Object.values(allValues[fieldName]['from']);
    toValues = Object.values(allValues[fieldName]['to']);
  }
  const mappings: IntegrationCreateMappingRecord[] = [];
  for (const i in fromValues) {
    const fromValue = fromValues[i];
    const toValue = toValues[i];
    if (isValueEmpty(fromValue) && isValueEmpty(toValue)) {
      // Skip empty mappings
      continue;
    }
    // Don't allow one empty and one filled
    if ((isValueEmpty(fromValue) && !isValueEmpty(toValue)) || (!isValueEmpty(fromValue) && isValueEmpty(toValue))) {
      onError();
      break;
    }
    mappings.push({
      from: fromValues[i],
      to: toValues[i]
    });
  }
  return mappings;
}
