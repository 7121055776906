import axios from 'axios';
import { config } from '../../config';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { showErrorNotification } from '../../components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';

export default function useDownloadKnowledgeBase(jobId: string) {
  const { t } = useTranslation('overview');
  const downloadKnowledgebase = (modelId: string) => {
    axios
      .post(config.api.jobExportModelKB.replace(':jobId', jobId).replace(':modelId', modelId), new CommonRequest(), {
        responseType: 'blob'
      })
      .then(({ data: blob }) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link); // we need to append the element to the dom -> otherwise it will not work in firefox
        link.download = `${modelId}-knowledgebase.json`;
        link.click();
        link.remove();
      })
      .catch(() => {
        showErrorNotification(t('knowledgeBaseDownload'), t('failedKbDownload'));
      });
  };
  return { downloadKnowledgebase };
}
