import _ from 'lodash';
import { TABLE_SIZES } from 'src/_srcMUI/shared/generic/Table/components/TableSizeSelector/TableSizeSelector.constants';
import { AdvancedFilters } from '../../../../components/Filters';

export const getDisplayedValue = (columnValue: string | undefined) => {
  if (_.isNil(columnValue)) {
    return 'n/a';
  }

  if (!columnValue) {
    return <AdvancedFilters.EmptyStringTooltip />;
  }

  return columnValue;
};

export const mapTableSizeToRowHeight = (tableSize: string, customRowSizeValue?: number) => {
  switch (tableSize) {
    case TABLE_SIZES.small:
      return '1';
    case TABLE_SIZES.auto:
      return 'none';
    case TABLE_SIZES.custom:
      return customRowSizeValue?.toString() ?? '2';
    default:
      return '2';
  }
};
