import { SVGProps } from 'react';

export type DotDotDotVerticalIconProps = SVGProps<SVGSVGElement>;

function DotDotDotVertical({ ...props }: DotDotDotVerticalIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M7.99999 8.66699C8.36818 8.66699 8.66666 8.36851 8.66666 8.00033C8.66666 7.63214 8.36818 7.33366 7.99999 7.33366C7.63181 7.33366 7.33333 7.63214 7.33333 8.00033C7.33333 8.36851 7.63181 8.66699 7.99999 8.66699Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M7.99999 4.00033C8.36818 4.00033 8.66666 3.70185 8.66666 3.33366C8.66666 2.96547 8.36818 2.66699 7.99999 2.66699C7.63181 2.66699 7.33333 2.96547 7.33333 3.33366C7.33333 3.70185 7.63181 4.00033 7.99999 4.00033Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        // eslint-disable-next-line max-len
        d="M7.99999 13.3337C8.36818 13.3337 8.66666 13.0352 8.66666 12.667C8.66666 12.2988 8.36818 12.0003 7.99999 12.0003C7.63181 12.0003 7.33333 12.2988 7.33333 12.667C7.33333 13.0352 7.63181 13.3337 7.99999 13.3337Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DotDotDotVertical;
