/* eslint-disable max-len */
function Edit16() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M0.799805 15.2998H13.7998" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.48919 11.3977L4.14453 11.9997L4.70197 8.61048L12.2052 1.12959C12.3088 1.02509 12.4321 0.942149 12.568 0.885547C12.7038 0.828946 12.8496 0.799805 12.9967 0.799805C13.1439 0.799805 13.2896 0.828946 13.4255 0.885547C13.5614 0.942149 13.6847 1.02509 13.7883 1.12959L14.9701 2.31137C15.0746 2.41501 15.1575 2.53832 15.2141 2.67418C15.2707 2.81004 15.2999 2.95576 15.2999 3.10294C15.2999 3.25012 15.2707 3.39584 15.2141 3.5317C15.1575 3.66756 15.0746 3.79086 14.9701 3.89451L7.48919 11.3977Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Edit16;
