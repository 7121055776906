import { Stack, StackProps, styled } from '@mui/material';

const StyledDropdownButton = styled(Stack)<StackProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '2px',
  width: '220px',

  '.arrow-wrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '44px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },

  '.main-button': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    flexGrow: 1
  }
}));

export default StyledDropdownButton;
