import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../../common/CommonResponse';
import { IntegrationSummaryRecord } from '../IntegrationSummaryRecord';

@JsonObject('IntegrationsResponse')
export class IntegrationsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [IntegrationSummaryRecord])
  entries?: IntegrationSummaryRecord[] = [];
}
