import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { JobBucketState } from './JobBucketState';

@JsonObject('JobBucketStatesResponse')
export class JobBucketStatesResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('states', [JobBucketState])
  states: JobBucketState[];
}
