import { JsonObject, JsonProperty } from 'json2typescript';
import { FieldInsightStatistic } from './FieldInsightStatistic';

@JsonObject('FieldInsightsGeneralStatistics')
export class FieldInsightsGeneralStatistics {
  @JsonProperty('distinct', FieldInsightStatistic)
  distinct: FieldInsightStatistic;

  @JsonProperty('missing', FieldInsightStatistic)
  missing: FieldInsightStatistic;

  @JsonProperty('total', FieldInsightStatistic)
  total: FieldInsightStatistic;
}
