import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('AccuracyPerModelRequest')
export class AccuracyPerModelRequest extends CommonRequest {
  @JsonProperty('job_ids', [Number])
  job_ids: number[];

  @JsonProperty('start_date', String)
  start_date: string;

  @JsonProperty('end_date', String)
  end_date: string;

  constructor(job_ids: number[], start_date: string, end_date: string) {
    super();
    this.job_ids = job_ids;
    this.start_date = start_date;
    this.end_date = end_date;
  }
}
