import { Stack, StackProps, styled } from '@mui/material';

const StyledMenuDropdown = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'isActive' && propName !== 'anchorXPosition';
  }
})<StackProps & { isActive: boolean; anchorXPosition: 'left' | 'right' }>(({ theme, isActive, anchorXPosition }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& .menu-dropdown-trigger': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    height: '36px',
    color: theme.palette.secondary[300],
    fontWeight: 400,
    gap: '8px',

    '&:hover': {
      color: theme.palette.secondary[0]
    }
  },

  '& .MuiList-root': {
    display: 'none'
  },

  ...(isActive
    ? {
        color: theme.palette.secondary[100],
        '& .MuiList-root': {
          display: 'block',
          position: 'absolute',
          top: '100%',
          zIndex: 100,

          ...(anchorXPosition === 'left' ? { left: 0 } : { right: 0 })
        }
      }
    : {})
}));

export default StyledMenuDropdown;
