import { FormProvider, RHFSelect, RHFSubmitButton } from '@arcanna/forms';
import { Button, Divider, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TFilterItem, TFilterOperator } from '../../AdvancedFilters.types';
import { FeedbackFiltersFieldsRecord } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRecord';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  getFilterValue,
  getIsFilterWithMultipleValue,
  getIsOperatorWithMultipleValue,
  getIsOperatorWithValue
} from '../../AdvancedFilters.utils';
import { joiResolver } from '@hookform/resolvers/joi';
import { getAdvancedFilterFormSchema } from './AdvancedFiltersMenu.utils';
import MultiValueArray from './components/MultiValueArray';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING_VALUE } from '../../AdvancedFilters.constants';
import { TOption } from '@arcanna/generic';

type TAdvancedFiltersMenuProps = {
  filter?: TFilterItem;
  onSave: (filter: TFilterItem) => void;
  onCancel: () => void;
  fieldOptions: (FeedbackFiltersFieldsRecord & TOption & { valueType?: string })[];
  operatorOptionsMap: Record<string, TOption[]>;
  getValueOptions: (field: string, filterId?: string) => Promise<TOption[]>;
};

export type TAdvancedFilterForm = {
  field: string;
  operator: TFilterOperator;
  value?: string;
  multipleValues?: string[];
};

function AdvancedFiltersMenu({
  onCancel,
  onSave,
  fieldOptions,
  operatorOptionsMap,
  filter,
  getValueOptions
}: TAdvancedFiltersMenuProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const [isValueOptionsLoading, setIsValueOptionsLoading] = useState<boolean>(false);
  const [valueOptions, setValueOptions] = useState<TOption[]>([]);
  const [isComponentLoaded, setIsComponentLoaded] = useState<boolean>(!filter);

  const isMultipleFilter = filter && getIsFilterWithMultipleValue(filter);

  const schema = getAdvancedFilterFormSchema(t);

  const methods = useForm<TAdvancedFilterForm>({
    resolver: joiResolver(schema),
    defaultValues: {
      field: filter?.field ?? '',
      operator: filter?.operator,
      value: !isMultipleFilter ? (getFilterValue(filter) as string) : '',
      multipleValues: isMultipleFilter ? (getFilterValue(filter) as string[]) : ['']
    }
  });

  const fieldValue = methods.watch('field');
  const operatorValue = methods.watch('operator');

  const isMultipleValueField = useMemo(() => {
    return getIsOperatorWithMultipleValue(operatorValue);
  }, [operatorValue]);

  const isValueFieldVisible = useMemo(() => {
    return getIsOperatorWithMultipleValue(operatorValue) || getIsOperatorWithValue(operatorValue);
  }, [operatorValue]);

  useEffect(() => {
    // used for fetching the value options based on the field selection

    setIsValueOptionsLoading(true);
    getValueOptions(fieldValue, filter?.id)
      ?.then((options) => {
        setValueOptions(
          options.map((option) => {
            return {
              ...option,
              label: option.value === '' ? [EMPTY_STRING_VALUE, option.label].join(' ') : option.label
            };
          })
        );
      })
      .finally(() => {
        setIsComponentLoaded(true);
        setIsValueOptionsLoading(false);
      });
  }, [methods, fieldValue, getValueOptions, filter]);

  const handleSave = (data: TAdvancedFilterForm) => {
    const activeField = fieldOptions?.find((field) => field.value === data.field);

    onSave({
      id: filter?.id ?? uuidv4(),
      label: activeField?.label,
      field: data.field,
      status: 'active',
      operator: data.operator,
      value: getIsOperatorWithMultipleValue(data.operator) ? data.multipleValues : data.value ?? '',
      valueType: activeField?.valueType
    } as TFilterItem);
  };

  return (
    <Stack
      direction="column"
      width="670px"
      maxWidth="100%"
      style={{ background: theme.palette.secondary[1000], borderRadius: '4px' }}
    >
      <Stack direction="row" paddingX={3} paddingY={2}>
        <Typography variant="h4" color={theme.palette.secondary[300]}>
          {t('advancedFilters.filterProperties')}
        </Typography>
      </Stack>
      <Divider />
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)}>
        <Grid container columnSpacing={3} rowSpacing={3} padding={3} minHeight="216px">
          <Grid item xs={8}>
            <RHFSelect
              name="field"
              label={t('advancedFilters.fieldLabel')}
              required
              placeholder={t('advancedFilters.fieldPlaceholder')}
              dataTestId="select-field"
              options={fieldOptions}
              isLoading={isValueOptionsLoading}
              // resets the operator field if needed and sets loading state for value option dropdown
              onChange={() => methods.resetField('operator', { defaultValue: '' as TFilterOperator })}
            />
          </Grid>
          <Grid item xs={4}>
            <RHFSelect
              name="operator"
              label={t('advancedFilters.operatorLabel')}
              required
              placeholder={t('advancedFilters.operatorPlaceholder')}
              dataTestId="select-operator"
              disabled={!fieldValue}
              isLoading={isValueOptionsLoading}
              options={operatorOptionsMap[fieldValue] ?? []}
              shouldUnregister
            />
          </Grid>
          {isValueFieldVisible && (
            <Grid item xs={12}>
              {isMultipleValueField ? (
                <MultiValueArray dataTestId="select-value" valueOptions={valueOptions} methods={methods} />
              ) : (
                <RHFSelect
                  name="value"
                  label={t('advancedFilters.valueLabel')}
                  required
                  placeholder={t('advancedFilters.valuePlaceholder')}
                  options={valueOptions}
                  isTextInputIncluded
                  isLoading={isValueOptionsLoading}
                  shouldUnregister={isComponentLoaded}
                  dataTestId="select-value"
                />
              )}
            </Grid>
          )}
        </Grid>
        <Divider />
        <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={3} paddingX={3} paddingY={2}>
          <Button size="small" color="secondary" variant="outlined" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <RHFSubmitButton size="small" text="Save" data-test-id="save-filter-button" />
        </Stack>
      </FormProvider>
    </Stack>
  );
}

export default AdvancedFiltersMenu;
