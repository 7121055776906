import { EIcon, Icon } from '@arcanna/generic';
import { IconButton, IconButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TRefreshButtonProps = {
  onRefresh: () => void;
  dataTestId?: string;
} & IconButtonProps;

export default function RefreshButton({ onRefresh, dataTestId, ...otherProps }: TRefreshButtonProps) {
  const { t } = useTranslation(['common']);

  return (
    <IconButton
      data-test-id={dataTestId ? dataTestId : 'refresh'}
      onClick={onRefresh}
      color={'secondary'}
      variant={'contained'}
      size={'small'}
      title={t('refresh')}
      {...otherProps}
    >
      <Icon name={EIcon.Refresh} fontSize={'small'} />
    </IconButton>
  );
}
