import { createRoot } from 'react-dom/client';
import L from 'leaflet';
import { MapControl, MapControlProps, withLeaflet } from 'react-leaflet';
import { ReactNode } from 'react';

class Legend extends MapControl<MapControlProps & { children: ReactNode }> {
  panelDiv: HTMLElement;

  createLeafletElement() {
    const MapInfo = L.Control.extend({
      onAdd: () => {
        this.panelDiv = L.DomUtil.create('div', '');
        const root = createRoot(this.panelDiv);
        root.render(<>{this.props.children}</>);
        return this.panelDiv;
      }
    });
    return new MapInfo({ position: 'topright' });
  }
}

export default withLeaflet(Legend);
