import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { IntegrationSummaryRecord } from './IntegrationSummaryRecord';
import { IntegrationSummaryMetadata } from './IntegrationSummaryMetadata';

@JsonObject('IntegrationSummaryResponse')
export class IntegrationSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [IntegrationSummaryRecord])
  entries?: IntegrationSummaryRecord[] = [];

  @JsonProperty('metadata', IntegrationSummaryMetadata)
  metadata?: IntegrationSummaryMetadata = undefined;
}
