import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PlatformKibanaRecord')
export class PlatformKibanaRecord {
  @JsonProperty('url', String)
  url: string | undefined;

  @JsonProperty('version', String)
  version: string | undefined;
}
