import { CardProps } from '@mui/material';
import StyledClickableCard from './StyledClickableCard.styles';

export type TClickableCardProps = CardProps & {
  onClick: Pick<CardProps, 'onClick'>;
};

function ClickableCard({ children, ...otherProps }: TClickableCardProps) {
  return <StyledClickableCard {...otherProps}>{children}</StyledClickableCard>;
}

export default ClickableCard;
