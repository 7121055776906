/* eslint-disable max-len */
function ContextEnrichmentModal({ disabled = false }: { disabled?: boolean }) {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill={disabled ? 'currentColor' : '#3B82F6'} fillOpacity="0.1" />
      <path
        d="M25.5 12.1665C25.5 13.5472 22.1421 14.6665 18 14.6665C13.8579 14.6665 10.5 13.5472 10.5 12.1665M25.5 12.1665C25.5 10.7858 22.1421 9.6665 18 9.6665C13.8579 9.6665 10.5 10.7858 10.5 12.1665M25.5 12.1665V23.8332C25.5 25.2165 22.1667 26.3332 18 26.3332C13.8333 26.3332 10.5 25.2165 10.5 23.8332V12.1665M25.5 17.9998C25.5 19.3832 22.1667 20.4998 18 20.4998C13.8333 20.4998 10.5 19.3832 10.5 17.9998"
        stroke={disabled ? 'currentColor' : '#3B82F6'}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ContextEnrichmentModal;
