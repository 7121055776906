export enum ELinkGeneratorPages {
  feedbackWithBucketId = 'feedbackWithBucketId',
  explorerWithBucketId = 'explorerWithBucketId',
  explorerWithEventId = 'explorerWithEventId',
  explorerWithClusterId = 'explorerWithClusterId',
  clusterWithClusterId = 'clusterWithClusterId'
}

export type TLinkGeneratorQueryParams = {
  pageId?: ELinkGeneratorPages;
  jobId?: string;
  eventId?: string;
  bucketId?: string;
  clusterId?: string;
};
