import { EIcon, Icon, Table } from '@arcanna/generic';
import { InputAdornment, Stack, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TUseAdvancedFilters } from '@arcanna/components';
import { TEventStructuredRow } from './EventStructuredTab.types';
import { getColumns } from './EventStructuredTab.utils';

type TEventStructuredTabProps = {
  rows: TEventStructuredRow[];
  advancedFilters: TUseAdvancedFilters;
  isLoading: boolean;
};

function EventStructuredTab({ rows, advancedFilters, isLoading }: TEventStructuredTabProps) {
  const { t } = useTranslation(['common']);
  const { palette } = useTheme();

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 20,
    pageIndex: 0
  });

  const columns = useMemo(
    () =>
      getColumns({
        palette,
        activeFilters: advancedFilters.activeFilters,
        addAdvancedFilter: advancedFilters.addAdvancedFilter
      }),
    [palette, advancedFilters.activeFilters, advancedFilters.addAdvancedFilter]
  );

  const tableInstance = useReactTable<TEventStructuredRow>({
    columns,
    data: rows,
    enableSorting: true,
    enableHiding: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableColumnPinning: false,
    enableColumnResizing: false,
    enableGlobalFilter: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: true,
    state: {
      pagination
    },
    onPaginationChange: setPagination
  });

  return (
    <Stack gap={1}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon name={EIcon.ActionSearch} htmlColor={palette.secondary[300]} />
            </InputAdornment>
          ),
          sx: { height: '36px' }
        }}
        placeholder={t('common:search') + '...'}
        sx={{
          width: '420px',
          '.MuiInputBase-root': {
            backgroundColor: palette.secondary[900]
          }
        }}
        value={tableInstance.getState().globalFilter}
        onChange={(event) => {
          tableInstance.setGlobalFilter(event.target.value);
        }}
      />
      <Table.component<TEventStructuredRow>
        tableInstance={tableInstance}
        isLoading={isLoading}
        noResultsMessageTitle={t('codeBlock:editTestInputDrawer.tableTab.emptyState.title')}
        noResultsMessageSubtitle={t('codeBlock:editTestInputDrawer.tableTab.emptyState.subtitle')}
        isTableResizable={false}
        defaultTableSize={Table.TABLE_SIZES.small}
        dataTestId="expanded-event-table"
      />
      <Table.TablePagination<TEventStructuredRow> tableInstance={tableInstance} dataTestId="expanded-event-table-pagination" />
    </Stack>
  );
}

export default EventStructuredTab;
