import React from 'react';
import styles from './WifiInsights.module.css';

export interface CircleMetricWrapperProps {
  children: React.ReactElement;
  className?: string;
  color?: string;
}

export function CircleMetricWrapper(props: CircleMetricWrapperProps) {
  // prettier-ignore
  return (
    <>
      {/* eslint-disable-next-line */}
      <div className={`${styles['circle-metric-wrapper']} ${props.color!} ${props.className}`}>{props.children}</div> {/* NOSONAR */}
    </>
  );
}
