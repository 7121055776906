import RHFSmartGeneratedFieldComponent from './RHFSmartGeneratedField';
import { getSmartGeneratedFieldSchema, getSmartGeneratedFieldDefaultValue } from './RHFSmartGeneratedField.utils';

const RHFSmartGeneratedField = {
  component: RHFSmartGeneratedFieldComponent,
  getSmartGeneratedFieldSchema,
  getSmartGeneratedFieldDefaultValue
};

export { RHFSmartGeneratedField };
