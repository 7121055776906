import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { EventExplorerBucketInfoRecord } from './EventExplorerBucketInfoRecord';

@JsonObject('EventExplorerBucketInfoResponse')
export class EventExplorerBucketInfoResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('bucket_info', EventExplorerBucketInfoRecord)
  bucket_info?: EventExplorerBucketInfoRecord;
}
