import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';
import { DeleteModelRequest } from '../../components/shared/models/job/DeleteModelRequest';

type Response = AxiosResponse<{ resource: { request: CommonResponse } }>;

export default function useDeleteModel(jobId: string) {
  const { t } = useTranslation(['job', 'common']);
  const queryClient = useQueryClient();

  return useMutation<Response, Response, DeleteModelRequest>(
    (payload: DeleteModelRequest) =>
      axios.delete(config.api.jobModel.replace(':jobId', jobId).replace(':modelId', payload.model_id), {
        data: new CommonRequest()
      }),
    {
      onSuccess: () => {
        showSuccessNotification(t('common:actionCompleted'), t('job:modelHistory.deletedModelSuccess'));
      },
      onError: () => {
        showErrorNotification(t('common:error'), t('job:modelHistory.deletedModelError'));
      },
      onSettled: () => {
        queryClient.invalidateQueries([config.api.jobAllRetrainHistory.replace(':jobId', jobId.toString())]);
      }
    }
  );
}
