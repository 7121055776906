import { createColumnHelper } from '@tanstack/react-table';
import { JobInsightsTableEntry } from '../../hooks/useJobInsightsTableData';
import { CustomLabel } from '@arcanna/components';
import { TFunction } from 'react-i18next';
import { CustomLabel as CustomLabelType } from 'src/components/shared/models';

export const getColumns = (t: TFunction, jobInfoCustomLabels?: CustomLabelType[]) => {
  const columnHelper = createColumnHelper<JobInsightsTableEntry>();
  return [
    columnHelper.accessor('label', {
      id: 'label',
      header: String(t('overview:feedbackLabel')),
      cell: ({ row }) => {
        const labelId = row.original.label;
        const customLabel = jobInfoCustomLabels?.find((cl: CustomLabelType) => cl.id === labelId);
        return customLabel ? <CustomLabel hexColor={customLabel.hexColor} name={customLabel.name} /> : labelId;
      },
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:feedbackLabelInfo')
      }
    }),
    columnHelper.accessor('tp', {
      id: 'tp',
      header: String(t('overview:truePositives')),
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:truePositivesInfo')
      }
    }),
    columnHelper.accessor('tn', {
      id: 'tn',
      header: String(t('overview:trueNegatives')),
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:trueNegativesInfo')
      }
    }),
    columnHelper.accessor('fp', {
      id: 'fp',
      header: String(t('overview:falsePositives')),
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:falsePositivesInfo')
      }
    }),
    columnHelper.accessor('fn', {
      id: 'fn',
      header: String(t('overview:falseNegatives')),
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:falseNegativesInfo')
      }
    }),
    columnHelper.accessor('recall', {
      id: 'recall',
      header: String(t('overview:recall')),
      cell: ({ row }) => `${row.original.recall.toFixed(2)}%`,
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:recallInfo')
      }
    }),
    columnHelper.accessor('precision', {
      id: 'precision',
      header: String(t('overview:precision')),
      cell: ({ row }) => `${row.original.precision.toFixed(2)}%`,
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:precisionInfo')
      }
    }),
    columnHelper.accessor('fScore', {
      id: 'fScore',
      header: String(t('overview:fScore')),
      cell: ({ row }) => `${row.original.fScore.toFixed(2)}%`,
      enableHiding: false,
      meta: {
        isTooltipDisabled: true,
        tooltipInfo: t('overview:fScoreInfo')
      }
    })
  ];
};
