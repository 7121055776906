import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { BatchTableSort } from '../../../pages/Main/Jobs/Feedback/Flow/FeedbackFlow.context';

@JsonObject('FeedbackBucketAlertsRequest')
export class FeedbackBucketAlertsRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('bucket_full_hash', String)
  bucket_full_hash?: string;

  @JsonProperty('size', Number)
  size?: number;

  @JsonProperty('from', Number)
  from?: number;

  @JsonProperty('sort_by_column', String)
  sort_by_column?: string;

  @JsonProperty('sort_order', Number)
  sort_order?: string;

  @JsonProperty('start_date', String)
  start_date?: string;

  @JsonProperty('end_date', String)
  end_date?: string;

  constructor(
    job_id: number,
    bucket_full_hash: string,
    size: number,
    from: number,
    start_date: string,
    end_date: string,
    tableSort: BatchTableSort
  ) {
    super();
    this.job_id = job_id;
    this.bucket_full_hash = bucket_full_hash;
    this.size = size;
    this.from = from;
    this.start_date = start_date;
    this.end_date = end_date;
    this.sort_by_column = tableSort.column;
    this.sort_order = tableSort.order;
  }
}
