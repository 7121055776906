import { Button, Collapse, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import { Icon, EIcon } from '@arcanna/generic';
import StyledCollapsible from './StyledCollapsible.styles';

type TCollapsibleProps = {
  children: ReactNode;
  heading: ReactNode;
  subtitle?: ReactNode;
  size: 'extra-small' | 'small' | 'large';
  isForcedExpanded?: boolean;
  isClickOnHeaderDisabled?: boolean;
  buttonPosition?: 'right' | 'left';
  hasBorder?: boolean;
  dataTestId?: string;
};

function Collapsible({
  children,
  heading,
  subtitle,
  size = 'small',
  isForcedExpanded,
  isClickOnHeaderDisabled,
  buttonPosition = 'right',
  hasBorder,
  dataTestId
}: TCollapsibleProps) {
  const [isExpandedState, setIsExpanded] = useState<boolean>(false);

  const isExpanded = isForcedExpanded || isExpandedState;
  const renderTriggerButton = () => (
    <Button
      color="secondary"
      variant={isExpanded ? 'containedHover' : 'outlined'}
      size="small"
      className="collapsible-trigger"
      data-test-id={dataTestId}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <Icon name={isExpanded ? EIcon.ChevronDown : EIcon.ChevronUp} fontSize="small" />
    </Button>
  );

  return (
    <Stack>
      <StyledCollapsible size={size} hasBorder={hasBorder} isExpanded={isExpanded} hasSubtitle={!!subtitle}>
        <Stack
          direction="row"
          gap={hasBorder || size === 'extra-small' ? 1 : 2}
          alignItems="center"
          marginBottom={isExpanded ? 0.5 : 0}
          onClick={() => !isClickOnHeaderDisabled && setIsExpanded(!isExpanded)}
          sx={{ cursor: !isClickOnHeaderDisabled ? 'pointer' : 'default' }}
          className="collapsible-heading"
        >
          {buttonPosition === 'left' && renderTriggerButton()}
          {heading}
          {buttonPosition === 'right' && renderTriggerButton()}
        </Stack>
        {subtitle}
        <Collapse in={isExpanded}>{children}</Collapse>
      </StyledCollapsible>
    </Stack>
  );
}

export default Collapsible;
