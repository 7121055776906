import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobAlertHistoryLogRecord')
export class JobAlertHistoryLogRecord {
  autoId: number | undefined;

  @JsonProperty('component', String)
  component: string | undefined;

  @JsonProperty('component_subcategory', String)
  component_subcategory: string | undefined;

  @JsonProperty('timestamp', String)
  timestamp: string | undefined;

  @JsonProperty('level', String)
  level: string | undefined;

  @JsonProperty('content', String)
  content: string | undefined;

  constructor() {
    this.autoId = Math.random();
  }
}
