import { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useJobInfo, useJobMetrics } from '../../../../../../../../data-access';
import { CustomLabel } from '../../../../../../../shared/models';
import styles from './styles.module.css';

type ConfusionMatrixProps = {
  jobId: number;
  modelId: string;
};
export default function ConfusionMatrix({ jobId, modelId }: ConfusionMatrixProps) {
  const { t } = useTranslation(['overview']);
  const [countMap, setCountMap] = useState<Record<string, number>>({});
  const jobInfoQuery = useJobInfo(jobId);
  const jobMetricsQuery = useJobMetrics(jobId, modelId);

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const customLabels = jobInfoQuery.data?.info.advancedSettings.customLabels ?? [];

  // Create a map for easy access to count values
  useEffect(
    function createCountMapForEaseOfAccess() {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (jobMetricsQuery.data?.feedbackSessionMetrics.confusionMatrix.entries) {
        const map: Record<string, number> = {};
        jobMetricsQuery.data.feedbackSessionMetrics.confusionMatrix.entries.forEach(({ consensus, result, count }) => {
          const key = `${consensus}-${result}`;
          // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
          map[key] = count;
        });

        setCountMap(map);
      }
    },
    [jobMetricsQuery.data]
  );

  return (
    <>
      <Typography className="color-nb-0 callouts m-t-24 m-b-12">
        <Space>
          <span>{t('overview:confusionMatrix')}</span>
          <Tooltip title={t('overview:confusionMatrixTooltip')}>
            <InfoCircleOutlined className="color-nb-400" />
          </Tooltip>
        </Space>
      </Typography>

      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <Space direction="vertical" align="center" className="callout-medium">
              <span>{t('overview:predictedLabel')}</span>
              <span>{t('overview:actualLabel')}</span>
            </Space>
          </div>
          {customLabels.map((result) => (
            <div className={styles.cell} key={result.id}>
              <span className="callout-medium overflow">
                <Tooltip title={result.name}>{result.name}</Tooltip>
              </span>
            </div>
          ))}
        </div>

        {customLabels.map((consensus: CustomLabel) => (
          <div key={consensus.id} className={styles.row}>
            <div className={styles.cell}>
              <span className="callout-medium overflow">
                <Tooltip title={consensus.name}>{consensus.name}</Tooltip>
              </span>
            </div>
            {customLabels.map((result: CustomLabel) => (
              <div
                className={cx([styles.cell, { [styles.cellHighlight]: consensus.id === result.id }, 'callout-medium overflow'])}
                key={result.id}
              >
                {countMap[`${consensus.id}-${result.id}`]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
