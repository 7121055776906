import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import PermissionsCheck from '../../../../shared/components/Permissions/Permissions';
import { permissions } from '../../../../shared/static/ComponentsPermissions';
import { config } from '../../../../../config';
import style from './JobNavigation.module.css';

type JobSettingsNavItemProps = {
  jobId: string;
};
export default function JobSettingsNavItem({ jobId }: JobSettingsNavItemProps) {
  const { t } = useTranslation(['job']);

  return (
    <PermissionsCheck permissions={[permissions.jobUpdate]} action={permissions.action.hiddenAction}>
      <NavLink
        to={config.routes.editJob.replace(':id', jobId)}
        className={cx([style.navLink, style.navLinkSettings, 'callout-default'])}
        activeClassName={cx([style.activeNavLink, 'callout-default'])}
        data-test-id={'menu-expanded-settings'}
      >
        {t('job:navigation.settings')}
      </NavLink>
    </PermissionsCheck>
  );
}
