import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { TimeseriesChart, TimeSeriesChartProps } from '../Visualisation/TimeseriesChart';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { useVisualizationsContext } from './Visualizations.context';
import { getJsonConvert } from '../../utilities/json-convert';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { useUtils } from '../../hooks/useUtils';

export function QueryTimeseries(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const [lineChartData, setLineChartData] = useState<TimeSeriesChartProps>(undefined);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const utils = useUtils();

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    let oneBucket = false;
    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    let buckets: Array<any> = [];
    for (const key in aggregations) {
      const { length } = Object.keys(aggregations[key].buckets);
      if (length > 1) {
        buckets = buckets.concat(aggregations[key].buckets);
      } else {
        oneBucket = true;
      }
    }

    // eslint-disable-next-line
    const entries: Array<any> = [];
    const keys = new Set<string>();

    buckets.forEach((bucket) => {
      // eslint-disable-next-line
      const entry: any = {};
      entry.time = bucket.key;

      for (const dictKey in bucket) {
        if (bucket[dictKey].buckets != null) {
          // eslint-disable-next-line
          const subBuckets: Array<any> = bucket[dictKey].buckets;
          subBuckets.forEach((subBucket) => {
            entry[subBucket.key] = subBucket.doc_count;
            keys.add(subBucket.key);
          });
        }
      }

      entries.push(entry);
    });

    if (entries.length === 0 && oneBucket) {
      for (const key in aggregations) {
        if (aggregations[key].buckets[0] != null && aggregations[key].buckets[0].values != null) {
          keys.add(aggregations[key].buckets[0].key);
          // eslint-disable-next-line
          aggregations[key].buckets[0].values.buckets.forEach((bucket: any) => {
            // eslint-disable-next-line
            const entry: any = {};
            entry.time = bucket.key;
            entry[aggregations[key].buckets[0].key] = bucket.doc_count;
            entries.push(entry);
          });
        }
      }
    }

    let colors: Array<string> = [];

    colors = utils.nColorArray(keys.size, visualizationRecord.style['color-seed']);

    // const colors = randomColor({ count: keys.size, seed: visualizationRecord.style['color-seed'] });
    setLineChartData({
      data: entries,
      keys: Array.from(keys),
      colors,
      style: visualizationRecord.style,
      filterFields: VisualizationRecord.getFilterFieldsFromQuery(visualizationRecord.query, [])
    });
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <div style={{ marginTop: '20px' }}>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          <TimeseriesChart key={props.name + Math.random()} {...lineChartData} />
        </div>
      </Spin>
    </>
  );
}
