import { ReactNode, useState } from 'react';
import cs from 'classnames';
import { ArcannaTableColumnHoverFilters } from '../ArcannaTableColumnHoverFilters';
import { FiltersFieldsRecord } from '../../models/filters/FiltersFieldsRecord';
import styles from './styles.module.css';
import { TFilterItem } from '@arcanna/components';

type ArcannaTableCellProps = {
  value: string | undefined;
  children?: ReactNode;
  columnName?: string;
  className?: string;
  // eslint-disable-next-line
  appliedAdvancedFilters?: any[];
  displayFilters?: boolean;
  filterFields?: FiltersFieldsRecord[];
  onAddFilter?: (filter: TFilterItem) => void;
};
export function ArcannaTableCell({
  value,
  columnName,
  className,
  appliedAdvancedFilters = [],
  displayFilters = false,
  children,
  filterFields = [],
  onAddFilter
}: ArcannaTableCellProps) {
  const [showHoverFilters, setHoverFillters] = useState(false);

  const handleMouseEnter = () => {
    setHoverFillters(true);
  };

  const handleMouseLeave = () => {
    setHoverFillters(false);
  };

  return (
    <div
      className={cs(['flex', styles.cellContainer, className])}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>{children}</div>
      {showHoverFilters || displayFilters ? (
        <div className={cs({ [styles.btnEventExplorerEventsFilterContainer]: children }, 'p-t-2 p-l-8 p-b-16')}>
          <ArcannaTableColumnHoverFilters
            value={value}
            appliedAdvancedFilters={appliedAdvancedFilters}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
            columnName={columnName}
            // @ts-expect-error TS(2322): Type '((operator: EFilterOperatorMultiple) => void...
            onAddFilter={onAddFilter}
            filterFields={filterFields}
          />
        </div>
      ) : null}
    </div>
  );
}
