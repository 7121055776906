import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { JobAlertHistoryResponse } from '../../components/shared/models/job/JobAlertHistoryResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

type UseEventHistory = {
  jobId: number;
  eventId: string;
};
export function useEventHistory({ jobId, eventId }: UseEventHistory) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.jobHistory.replace(':id', `${jobId}`).replace(':alertId', eventId);

  return useQuery(
    [url],
    () =>
      axios
        .post<{ resource: JobAlertHistoryResponse }>(url, new CommonRequest())
        .then((responseData: AxiosResponse<{ resource: JobAlertHistoryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobAlertHistoryResponse)
        ),
    {
      enabled: Boolean(jobId && eventId)
    }
  );
}
