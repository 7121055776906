import { EIcon, Icon } from '@arcanna/generic';
import { LabelCircle } from '@arcanna/components';
import { Button, Divider, InputAdornment, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import ColorCircle from './components/ColorCircle';
import { customLabelColors } from './CustomLabelPillMenu.constants';
import StyledCustomLabelPillMenu from './StyledCustomLabelPillMenu.styles';

type TCustomLabelPillMenuProps = {
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleClose: () => void;
  selectedColor: string;
  setSelectedColor: (color: string) => void;
  newLabel: string;
  customError: string;
  handleSave: () => void;
  validateAndSet: (value: string) => void;
};

function CustomLabelPillMenu({
  anchorEl,
  isMenuOpen,
  handleClose,
  selectedColor,
  setSelectedColor,
  newLabel,
  customError,
  handleSave,
  validateAndSet
}: TCustomLabelPillMenuProps) {
  const { t } = useTranslation('common');

  return (
    <StyledCustomLabelPillMenu
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <Stack direction="column" width="397px" margin="8px" gap="8px">
        <Stack justifyContent="space-between" alignItems="center" direction="row" flexWrap="wrap" gap="24px" rowGap="8px">
          {customLabelColors.map((color, index) => (
            <ColorCircle
              key={color}
              color={color}
              setSelectedColor={setSelectedColor}
              selectedColor={selectedColor}
              index={index}
            />
          ))}
        </Stack>
        <Divider />
        <TextField
          size="medium"
          value={newLabel}
          placeholder={t('enterLabel')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LabelCircle hexColor={selectedColor} />
              </InputAdornment>
            ),
            inputProps: {
              'data-test-id': 'feedback-label-name'
            }
          }}
          onChange={(e) => validateAndSet(e.target.value)}
          error={!!customError}
          helperText={customError}
        />
        <Divider />
        <Stack direction="row" justifyContent="flex-end" alignItems="baseline" gap="24px" marginTop="4px">
          <Button size="small" color="secondary" variant="contained" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleSave}
            endIcon={<Icon name={EIcon.Save02} fontSize="small" />}
            disabled={!!customError || !newLabel}
            data-test-id="submit-new-feedback-label-btn"
          >
            {t('save')}
          </Button>
        </Stack>
      </Stack>
    </StyledCustomLabelPillMenu>
  );
}

export default CustomLabelPillMenu;
