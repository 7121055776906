/* eslint-disable max-len */
function ThreeDots() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M7.99993 4.0001C8.9467 4.0001 9.71422 3.23259 9.71422 2.28582C9.71422 1.33904 8.9467 0.571533 7.99993 0.571533C7.05316 0.571533 6.28564 1.33904 6.28564 2.28582C6.28564 3.23259 7.05316 4.0001 7.99993 4.0001Z"
        fill="currentColor"
      />
      <path
        d="M7.99993 9.71446C8.9467 9.71446 9.71422 8.94695 9.71422 8.00017C9.71422 7.0534 8.9467 6.28589 7.99993 6.28589C7.05316 6.28589 6.28564 7.0534 6.28564 8.00017C6.28564 8.94695 7.05316 9.71446 7.99993 9.71446Z"
        fill="currentColor"
      />
      <path
        d="M7.99993 15.4286C8.9467 15.4286 9.71422 14.6611 9.71422 13.7143C9.71422 12.7675 8.9467 12 7.99993 12C7.05316 12 6.28564 12.7675 6.28564 13.7143C6.28564 14.6611 7.05316 15.4286 7.99993 15.4286Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ThreeDots;
