import { Button, FormHelperText, FormLabel, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Icon, EIcon, Select, TOption } from '@arcanna/generic';
import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { TAdvancedFilterForm } from '../../AdvancedFiltersMenu';
import { useTranslation } from 'react-i18next';

type TMultiValueArrayProps = {
  valueOptions: TOption[];
  methods: UseFormReturn<TAdvancedFilterForm>;
  dataTestId?: string;
};

function MultiValueArray({ valueOptions, methods, dataTestId }: TMultiValueArrayProps) {
  const { t } = useTranslation('common');
  const theme = useTheme();

  const multipleValueField = methods.watch('multipleValues');

  const availableOptionsForMultiValue = useMemo(
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    () => valueOptions.filter((value) => !multipleValueField.includes(value.value)),
    [multipleValueField, valueOptions]
  );

  const renderMultiFieldRow = ({
    value,
    onChange,
    onDelete,
    isDeleteDisabled,
    isError = false,
    key
  }: {
    key: string;
    value: string;
    onChange: (newValue: string) => void;
    onDelete: () => void;
    isDeleteDisabled: boolean;
    isError?: boolean;
  }) => (
    <Stack key={key} direction="row" gap={3} alignItems="center">
      <Select
        options={value ? [...availableOptionsForMultiValue, { label: value, value: value }] : availableOptionsForMultiValue}
        isTextInputIncluded
        onChange={onChange}
        required
        placeholder={t('advancedFilters.valuePlaceholder')}
        fullWidth
        textFieldProps={{ error: isError }}
        value={value}
        dataTestId={dataTestId}
      />
      <Stack direction="row" gap={2} alignItems="center">
        <Tooltip title={t('advancedFilters.deleteValue')} placement="bottom" arrow>
          <Button
            disabled={isDeleteDisabled}
            color="secondary"
            variant="outlined"
            size="small"
            className="MuiButton-root--icon"
            onClick={onDelete}
          >
            <Icon name={EIcon.Delete} fontSize="small" />
          </Button>
        </Tooltip>
      </Stack>
    </Stack>
  );

  const isAtLeastOneEmptyField = Boolean(multipleValueField?.filter((value) => !value)?.length);

  return (
    <Stack direction="column" gap={1}>
      <FormLabel required style={{ lineHeight: '20px', height: '20px' }}>
        <Typography color={theme.palette.secondary[200]} component="span" fontSize="12px" lineHeight="20px">
          {t('advancedFilters.multipleValuesLabel')}
        </Typography>
      </FormLabel>
      {multipleValueField?.map((value, index) =>
        renderMultiFieldRow({
          key: `multi-value-${index}`,
          value,
          onChange: (newValue) => {
            const newValues = [...multipleValueField];
            newValues.splice(index, 1, newValue);

            methods.clearErrors('multipleValues');
            methods.setValue('multipleValues', newValues);
          },
          onDelete: () => {
            const newValues = [...multipleValueField];
            newValues.splice(index, 1);

            methods.clearErrors('multipleValues');
            methods.setValue('multipleValues', newValues);
          },
          isDeleteDisabled: multipleValueField?.length <= 1,
          isError: Boolean(methods.formState.errors.multipleValues)
        })
      )}
      {Boolean(multipleValueField?.length) && (
        <Stack direction="row">
          <Button
            color="secondary"
            variant="tertiary"
            size="small"
            disabled={isAtLeastOneEmptyField}
            onClick={() => {
              methods.clearErrors('multipleValues');
              // @ts-expect-error
              methods.setValue('multipleValues', [...multipleValueField, '']);
            }}
            endIcon={<Icon name={EIcon.Plus} />}
          >
            Add value
          </Button>
        </Stack>
      )}
      {methods.formState.errors.multipleValues && (
        <FormHelperText error>{t('advancedFilters.multipleValueRequired')}</FormHelperText>
      )}
    </Stack>
  );
}

export default MultiValueArray;
