import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EventExplorerBatchColumnRecord')
export class EventExplorerBatchColumnRecord {
  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('caption', String)
  caption: string | undefined;

  @JsonProperty('value', String)
  value?: string | undefined;

  @JsonProperty('type', String)
  type?: string | undefined;

  constructor(name: string, caption: string, value: string, type = 'string') {
    this.name = name;
    this.caption = caption;
    this.value = value;
    this.type = type;
  }
}
