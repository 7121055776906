import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNewlyLabeledStatistics } from '../../hooks/useNewlyLabeledStatistics';
import styles from './styles.module.css';
import { FeedbackSession } from '../../../../../../shared/models/feedback/FeedbackStatisticsLabelsUsedRequest';
import cx from 'classnames';

type NewlyLabeledStatisticsProps = {
  jobId: number;
  feedbackSession: FeedbackSession;
};
export function NewlyLabeledStatistics({ jobId, feedbackSession }: NewlyLabeledStatisticsProps) {
  const { t } = useTranslation(['overview']);
  const [newlyLabeledStatistics] = useNewlyLabeledStatistics({ jobId, feedbackSession });

  return (
    <div className={styles.newlyLabeled}>
      <div className="flex flex-sp m-t-12">
        <Space direction="vertical" size={2}>
          <span className="callout-medium color-nb-300">{t('overview:confirmed')}</span>
          <span className="title-3 color-nb-200">{newlyLabeledStatistics.confirmed}</span>
        </Space>

        <Space direction="vertical" size={2} align="end">
          <span className="callout-medium color-nb-300">{t('overview:changed')}</span>
          <span className="title-3 color-nb-200">{newlyLabeledStatistics.changed}</span>
        </Space>
      </div>
      <div className="flex w-100 m-t-8">
        <div className="w-50 relative">
          <div className={styles.confirmedBar}>
            <span className={cx('color-nb-0 body-2 m-l-12 font-weight-700', styles.percentageText)}>
              {newlyLabeledStatistics.confirmedPercent.toFixed(2)}%
            </span>
          </div>
          <div
            className={styles.confirmedBarPercentage}
            style={{ width: `${newlyLabeledStatistics.confirmedPercent.toFixed(2)}%` }}
          />
        </div>
        <div className="w-50" style={{ position: 'relative' }}>
          <div className={styles.changedBar}>
            <span className={cx('color-nb-0 body-2 m-r-12 font-weight-700', styles.percentageText)}>
              {newlyLabeledStatistics.changedPercent.toFixed(2)}%
            </span>
          </div>
          <div
            className={styles.changedBarPercentage}
            style={{ width: `${newlyLabeledStatistics.changedPercent.toFixed(2)}%` }}
          />
        </div>
      </div>
    </div>
  );
}

export default NewlyLabeledStatistics;
