import * as React from 'react';
import { useQuery } from 'react-query';
import { config } from '../../config';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { EventExplorerFiltersFieldsResponse } from '../../components/shared/models/event-explorer/EventExplorerFiltersFieldsResponse';
import { EventExplorerFiltersFieldsRequest } from '../../components/shared/models/event-explorer/EventExplorerFiltersFieldsRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { EventExplorerFiltersFieldsRecord } from '../../components/shared/models/event-explorer/EventExplorerFiltersFieldsRecord';

function fetchExplorerFiltersFields(jobId: number) {
  return axios.post<{ resource: EventExplorerFiltersFieldsResponse }>(
    config.api.eventExplorerFiltersFields,
    new EventExplorerFiltersFieldsRequest(jobId)
  );
}

type UseEventExplorerFiltersFields = {
  jobId: number;
};
export function useEventExplorerFiltersFields({ jobId }: UseEventExplorerFiltersFields) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<EventExplorerFiltersFieldsResponse, AxiosError>([config.api.eventExplorerFiltersFields, { jobId }], () =>
    fetchExplorerFiltersFields(jobId).then((responseData: AxiosResponse<{ resource: EventExplorerFiltersFieldsResponse }>) =>
      jsonConvert.deserializeObject(responseData.data.resource, EventExplorerFiltersFieldsResponse)
    )
  );
}

type UseOperators = {
  jobId: number;
  source: string;
};
export function useSelectedFilterFields({ jobId, source }: UseOperators) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  return useQuery<EventExplorerFiltersFieldsResponse, AxiosError, EventExplorerFiltersFieldsRecord>(
    [config.api.eventExplorerFiltersFields, { jobId, source }],
    () =>
      fetchExplorerFiltersFields(jobId).then((responseData: AxiosResponse<{ resource: EventExplorerFiltersFieldsResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, EventExplorerFiltersFieldsResponse)
      ),
    {
      enabled: source !== undefined,
      // @ts-expect-error TS(2322): Type '(data: EventExplorerFiltersFieldsResponse) =...
      select: (data: EventExplorerFiltersFieldsResponse) => data.fields_list.find((item) => item.source === source)
    }
  );
}
