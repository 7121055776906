import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobRollbackToModel')
export class JobRollbackToModel extends CommonRequest {
  @JsonProperty('model_id', String)
  model_id: string;

  constructor(modelId: string) {
    super();
    this.model_id = modelId;
  }
}
