import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EnvVariableRecord')
export class EnvVariableRecord {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('value', String)
  value: string;

  @JsonProperty('is_secret', Boolean)
  isSecret: boolean;

  @JsonProperty('should_encrypt', Boolean, true)
  shouldEncrypt = false;
}

type TConstructorParams = {
  name: EnvVariableRecord['name'];
  value: EnvVariableRecord['value'];
  isSecret: EnvVariableRecord['isSecret'];
  shouldEncrypt: EnvVariableRecord['shouldEncrypt'];
};

export function constructEnvVariableRecord({ name, value, isSecret, shouldEncrypt }: TConstructorParams) {
  const instance = new EnvVariableRecord();
  instance.name = name;
  instance.value = value;
  instance.isSecret = isSecret;
  instance.shouldEncrypt = shouldEncrypt;
  return instance;
}
