import { JsonObject, JsonProperty } from 'json2typescript';
import { VisualizationRecord } from './VisualizationRecord';
import { ExportRecord } from './ExportRecord';
import { TimeRangeRecord } from './TimeRangeRecord';

@JsonObject('UsecaseRecord')
export class UsecaseRecord {
  @JsonProperty('id', Number)
  id: number | undefined;

  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('logo_base64', String)
  logo_base64: string | undefined;

  @JsonProperty('display_name', String)
  display_name: string | undefined;

  @JsonProperty('exports', Array<ExportRecord>(), true)
  exports: Array<ExportRecord> | undefined;

  @JsonProperty('visualizations', Array<VisualizationRecord>())
  visualizations: Array<VisualizationRecord> | undefined;

  @JsonProperty('default_timerange', Array<TimeRangeRecord>())
  default_timerange: Array<TimeRangeRecord> | undefined;
}
