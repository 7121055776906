import axios, { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { IntegrationUpdateRequest } from '../../components/shared/models/integration/IntegrationUpdateRequest';
import { useTranslation } from 'react-i18next';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { useMemo } from 'react';

type Response = AxiosResponse<{ resource: { request: CommonResponse } }>;
type UseUpdateIntegration = {
  integrationId: number;
};

export function useUpdateIntegration({ integrationId }: UseUpdateIntegration) {
  const { t } = useTranslation(['common']);
  const queryClient = useQueryClient();
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const url = config.api.integrationUpdate.replace(':integrationId', `${integrationId}`);

  return useMutation<Response, Response, IntegrationUpdateRequest>(
    (payload: IntegrationUpdateRequest) => axios.post<{ resource: { request: CommonResponse } }>(url, payload),
    {
      onSuccess: (responseData: Response) => {
        if (!responseData.data) {
          return;
        }

        if (!responseData.data.resource) {
          showErrorNotification(t('common:actionCompleted'), t('common:updateError'));
          return;
        }

        const integrationUpdateResponse = jsonConvert.deserializeObject(responseData.data.resource.request, CommonResponse);

        if (integrationUpdateResponse.status === 'OK') {
          showSuccessNotification(t('common:actionCompleted'), t('common:updateSuccessfully'));
        } else {
          showErrorNotification(t('common:actionCompleted'), t('common:updateError') + integrationUpdateResponse.reason);
        }
      },
      onError: (error) => {
        if (!error.data?.resource) {
          showErrorNotification(t('common:actionCompleted'), t('common:updateError'));
          return;
        }

        const integrationUpdateResponse = jsonConvert.deserializeObject(error.data.resource.request, CommonResponse);

        if (integrationUpdateResponse.status === 'NOK') {
          showErrorNotification(t('common:actionCompleted'), t('common:updateError') + integrationUpdateResponse.reason);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([
          config.api.integrationList.replace(':integrationId', `${integrationId}`),
          { integrationId }
        ]);
        queryClient.invalidateQueries([config.api.integrations]);
      }
    }
  );
}
