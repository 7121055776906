import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('JobRawAlertResponse')
export class JobRawAlertResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('alert', Object)
  alert?: object = undefined;
}
