import { Tooltip } from 'antd';
import { FeatureOccurence } from '../../../../../../../../../shared/models/retrain-history/JobMetricsResponse';
import { PercentageBar } from '../../../../../../../../../shared/components';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';

type OccurenceProps = {
  occurence: FeatureOccurence;
  totalUsedCount: number;
};
export function Occurence({ occurence, totalUsedCount }: OccurenceProps) {
  const { t } = useTranslation(['overview']);

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const percentage = ((occurence.usedCount / totalUsedCount) * 100).toFixed(2);

  return (
    <div className="flex flex-col m-t-8">
      <Tooltip title={occurence.value}>
        <div className="flex flex-sp callout color-nb-200 m-b-4">
          <span className={styles.occurenceText}>{occurence.value}</span>
          <span>
            {occurence.usedCount} {t('overview:buckets')} ({percentage}%)
          </span>
        </div>
      </Tooltip>
      <PercentageBar percentage={percentage} />
    </div>
  );
}
