import { useCallback, useEffect, useState } from 'react';
import { IntegrationJobParameterRecord } from '../../../../../../../components/shared/models/integration/job/IntegrationJobParameterRecord';
import useIntegrationSubcategoriesParameterValue from '../../../../../../../data-access/integrations/IntegrationSubcategories/useIntegrationSubcategoriesParameterValue';

export default function useFutureParametersValues({
  allParameters
}: {
  subcategoryId: number;
  allParameters: IntegrationJobParameterRecord[];
}) {
  // const { t } = useTranslation('common');
  const [isLoadingFutureValues, setIsLoadingFutureValues] = useState(false);
  const [futureParameters, setFutureParameters] = useState<IntegrationJobParameterRecord[]>([]);
  // @ts-ignore
  const getParamsForFutureValue = useIntegrationSubcategoriesParameterValue();

  useEffect(
    function setFutureParametersOnParametersChange() {
      setFutureParameters(allParameters.filter((parameter) => parameter.is_future).map((parameter) => ({ ...parameter })));
    },
    [allParameters]
  );

  const getFutureValues = useCallback(
    (formValues: unknown, getPayload: <Payload>(values: unknown) => Payload, onFinish?: () => void) => {
      setIsLoadingFutureValues(true);
      const payload = getPayload(formValues);
      const promisesToResolve: Promise<unknown>[] = [];
      allParameters.forEach((parameter) => {
        if (parameter.callback_endpoint && parameter.field) {
          promisesToResolve.push(
            getParamsForFutureValue.mutateAsync({
              url: parameter.callback_endpoint,
              params: payload,
              futureParameterName: parameter.field
            })
          );
        }
      });
      const futureParametersWithValue = [...futureParameters];

      Promise.all(promisesToResolve)
        .then((results) => {
          results.forEach((result) => {
            const futureParameterName = Object.keys(result as Record<string, unknown>)[0];
            const futureValue = (result as Record<string, unknown>)[futureParameterName];
            const futureParameterIndex = futureParameters.findIndex(
              (futureParameter) => futureParameter.field === futureParameterName
            );

            // logic for use of future value in dropdown
            // @ts-ignore
            if (Array.isArray(futureValue)) {
              futureParametersWithValue[futureParameterIndex].allowed_values = (futureValue as Array<string>).map(
                (value: string) => ({
                  key: value,
                  caption: value,
                  value: value
                })
              );
            }
          });
          setFutureParameters(JSON.parse(JSON.stringify(futureParametersWithValue)));
          onFinish?.();
        })
        .finally(() => {
          setIsLoadingFutureValues(false);
        });
    },
    [futureParameters, getParamsForFutureValue, allParameters]
  );

  return {
    futureParameters,
    getFutureValues,
    isLoadingFutureValues
  };
}
