/* eslint-disable max-len */
function EyeSlashed() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
        d="M12.2192 5.42139C12.594 5.7568 12.9097 6.08234 13.1465 6.33883C13.3101 6.5201 13.4006 6.75561 13.4006 6.99979C13.4006 7.24397 13.3101 7.47948 13.1465 7.66075C12.1106 8.77549 9.7529 10.9458 7.00056 10.9458H6.60596"
        stroke="currentColor"
        strokeWidth="1.20846"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.91241 10.088C2.76968 9.44796 1.73715 8.6286 0.854259 7.66116C0.690657 7.47989 0.600098 7.24439 0.600098 7.00021C0.600098 6.75602 0.690657 6.52052 0.854259 6.33925C1.89009 5.2245 4.24782 3.0542 7.00016 3.0542C8.08509 3.07687 9.1469 3.37201 10.0879 3.91246"
        stroke="currentColor"
        strokeWidth="1.20846"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4262 1.57422L1.57471 12.4257"
        stroke="currentColor"
        strokeWidth="1.20846"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.60938 8.39083C5.23886 8.02252 5.02955 7.52229 5.02734 6.99986C5.02734 6.47659 5.23521 5.97474 5.60522 5.60473C5.97523 5.23472 6.47707 5.02686 7.00035 5.02686C7.52278 5.02906 8.023 5.23837 8.39131 5.60889"
        stroke="currentColor"
        strokeWidth="1.20846"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.71683 7.98633C8.54121 8.28652 8.28937 8.53496 7.98682 8.70647"
        stroke="currentColor"
        strokeWidth="1.20846"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EyeSlashed;
