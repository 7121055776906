import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobFeedbackColumnsUpdateDetails')
export class JobFeedbackColumnsUpdateDetails {
  @JsonProperty('id', String)
  id?: string = undefined;

  @JsonProperty('name', String)
  name?: string = undefined;

  @JsonProperty('type', String)
  type?: string = undefined;

  @JsonProperty('caption', String)
  caption?: string = undefined;

  @JsonProperty('active', Boolean)
  active?: boolean = undefined;
}
