import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CommonPaginationRequest')
export class CommonPaginationRequest {
  @JsonProperty('offset', Number)
  offset?: number | undefined;

  @JsonProperty('size', Number)
  size?: number | undefined;

  constructor(offset: number, size: number) {
    this.offset = offset;
    this.size = size;
  }
}
