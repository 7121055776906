import React from 'react';
import { config } from '../../config';
import { useQuery } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { ErrorResponse } from '../../components/shared/models/error/ErrorResponse';
import axios, { AxiosResponse } from 'axios';
import {
  FeedbackSession,
  FeedbackStatisticsLabelsUsedRequest
} from '../../components/shared/models/feedback/FeedbackStatisticsLabelsUsedRequest';
import { FeedbackStatisticsLabelsUsedResponse } from '../../components/shared/models/feedback/FeedbackStatisticsLabelsUsedResponse';

export function useRetrainLabelsUsed(jobId: number, feedbackSession: FeedbackSession = FeedbackSession.OVERALL) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<FeedbackStatisticsLabelsUsedResponse, { data: ErrorResponse }>(
    [config.api.feedbackStatisticsLabelsUsed, { jobId, feedbackSession }],
    () =>
      axios
        .post<{
          resource: FeedbackStatisticsLabelsUsedResponse;
        }>(config.api.feedbackStatisticsLabelsUsed, new FeedbackStatisticsLabelsUsedRequest(jobId, feedbackSession))
        .then((responseData: AxiosResponse<{ resource: FeedbackStatisticsLabelsUsedResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, FeedbackStatisticsLabelsUsedResponse)
        )
  );
}
