import { CellContext, Column, flexRender } from '@tanstack/react-table';
import StyledTableBodyCell from './StyledTableBodyCell.styles';

function TableBodyCell<T>({
  column,
  cellContext,
  tableSize,
  actionsColumnSize,
  customRowSizeValue
}: {
  column: Column<T, unknown>;
  cellContext: CellContext<T, unknown>;
  tableSize: string;
  actionsColumnSize: number;
  customRowSizeValue: number;
}) {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');

  return (
    <StyledTableBodyCell
      tableSize={tableSize}
      actionsColumnSize={actionsColumnSize}
      isPinned={column.getIsPinned()}
      columnId={column.id}
      isLastLeftPinnedColumn={isLastLeftPinnedColumn}
      leftStartPosition={column.getStart('left')}
      className={cellContext.column.columnDef.meta?.isColumnDisabled ? 'MuiTableCell-root--disabled' : ''}
    >
      {flexRender(column.columnDef.cell, { ...cellContext, tableSize, customRowSizeValue })}
    </StyledTableBodyCell>
  );
}

export default TableBodyCell;
