import { useState } from 'react';
import { Spin, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { RetrainSession } from '../../../../../shared/models/retrain-statistics/JobRetrain';
import NewlyLabeledStatistics from './NewlyLabeledStatistics';
import LabelsBreakdown from './LabelsBreakdown';
import FeedbackStatisticsSplitByUser from './FeedbackStatisticsSplitByUser';
import {
  useFeedbackStatisticsSplitByUser,
  useRetrainEventsBreakdownStatistics,
  useRetrainLabelsUsed
} from '../../../../../../data-access';
import { enumFromValue } from '../../../../../shared/utilities/enumFromValue';
import { FeedbackSession } from '../../../../../shared/models/feedback/FeedbackStatisticsLabelsUsedRequest';
import { RefreshButton } from '@arcanna/components';

type JobFeedbackSectionProps = {
  jobId: number;
};
export function JobFeedbackSection({ jobId }: JobFeedbackSectionProps) {
  const { t } = useTranslation(['overview']);
  const [activeTab, setActiveTab] = useState<RetrainSession>(RetrainSession.CURRENT);

  const feedbackStatisticsByUserQuery = useFeedbackStatisticsSplitByUser({
    jobId,
    retrainSession: activeTab,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    startDate: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    endDate: null,
    filters: []
  });

  const retrainEventsBreakdownStatisticsQuery = useRetrainEventsBreakdownStatistics({
    jobId,
    retrainSession: activeTab,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    startDate: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    endDate: null,
    filters: []
  });

  const labelsUsedQuery = useRetrainLabelsUsed(jobId);

  const refreshAllData = () => {
    feedbackStatisticsByUserQuery.refetch();
    retrainEventsBreakdownStatisticsQuery.refetch();
    labelsUsedQuery.refetch();
  };

  const showSpinner =
    retrainEventsBreakdownStatisticsQuery.isLoading ||
    retrainEventsBreakdownStatisticsQuery.isRefetching ||
    feedbackStatisticsByUserQuery.isLoading ||
    feedbackStatisticsByUserQuery.isRefetching ||
    labelsUsedQuery.isLoading ||
    labelsUsedQuery.isRefetching;

  return (
    <div className="flex flex-col">
      <div className="w-100 flex flex-sp flex-align-center m-b-16">
        <Typography.Title level={3} className="color-nb-0 m-b-0">
          {t('overview:feedback')}
        </Typography.Title>
        <RefreshButton onRefresh={refreshAllData} />
      </div>
      <Spin spinning={showSpinner}>
        <div className={styles.root}>
          <Tabs
            activeKey={activeTab}
            onChange={(tab: string) => setActiveTab(enumFromValue(tab, RetrainSession))}
            items={[
              {
                label: t('overview:currentFeedbackSession'),
                key: RetrainSession.CURRENT,
                children: (
                  <div className={styles.tabWrapper}>
                    <div className={styles.metrics}>
                      <NewlyLabeledStatistics jobId={jobId} feedbackSession={FeedbackSession.CURRENT} />
                      <LabelsBreakdown jobId={jobId} feedbackSession={FeedbackSession.CURRENT} />
                    </div>

                    <FeedbackStatisticsSplitByUser jobId={jobId} feedbackSession={FeedbackSession.CURRENT} />
                  </div>
                )
              },
              {
                label: t('overview:previousFeedbackSession'),
                key: RetrainSession.PREVIOUS,
                children: (
                  <div className={styles.tabWrapper}>
                    <div className={styles.metrics}>
                      <NewlyLabeledStatistics jobId={jobId} feedbackSession={FeedbackSession.PREVIOUS} />
                      <LabelsBreakdown jobId={jobId} feedbackSession={FeedbackSession.PREVIOUS} />
                    </div>

                    <FeedbackStatisticsSplitByUser jobId={jobId} feedbackSession={FeedbackSession.PREVIOUS} />
                  </div>
                )
              },
              {
                label: t('overview:allFeedbackSessions'),
                key: RetrainSession.OVERALL,
                children: (
                  <div className={styles.tabWrapper}>
                    <div className={styles.metrics}>
                      <NewlyLabeledStatistics jobId={jobId} feedbackSession={FeedbackSession.OVERALL} />
                      <LabelsBreakdown jobId={jobId} feedbackSession={FeedbackSession.OVERALL} />
                    </div>

                    <FeedbackStatisticsSplitByUser jobId={jobId} feedbackSession={FeedbackSession.OVERALL} />
                  </div>
                )
              }
            ]}
          />
        </div>
      </Spin>
    </div>
  );
}

export default JobFeedbackSection;
