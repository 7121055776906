import * as React from 'react';
import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { JobRawAlertResponse } from '../../components/shared/models/job/JobRawAlertResponse';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

type UseEventJson = {
  jobId: number;
  eventId: string;
};
export function useEventJson({ jobId, eventId }: UseEventJson) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.jobRawAlert.replace(':id', `${jobId}`).replace(':alertId', eventId);
  return useQuery([url], () =>
    axios
      .post<{ resource: JobRawAlertResponse }>(url, new CommonRequest())
      .then((responseData: AxiosResponse<{ resource: JobRawAlertResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, JobRawAlertResponse)
      )
  );
}
