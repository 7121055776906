/* eslint-disable max-len */
function MinusCircle() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_4849_52642)">
        <path
          d="M4.66675 6.99935H9.33342M12.8334 6.99935C12.8334 10.221 10.2217 12.8327 7.00008 12.8327C3.77842 12.8327 1.16675 10.221 1.16675 6.99935C1.16675 3.77769 3.77842 1.16602 7.00008 1.16602C10.2217 1.16602 12.8334 3.77769 12.8334 6.99935Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4849_52642">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default MinusCircle;
