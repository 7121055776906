import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { MigrationStatus } from './MigrationStatus';

@JsonObject('MigrationStatusResponse')
export class MigrationStatusResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('migration_status', MigrationStatus)
  migrationStatus?: MigrationStatus = undefined;
}
