import { JsonObject, JsonProperty } from 'json2typescript';
import { FeedbackBatchBucketColumn } from './FeedbackBatchBucketColumn';
import { BucketConsensusOverwritten } from './FeedbackBucketDetailsResponse';

@JsonObject('FeedbackBatchBucketRow')
export class FeedbackBatchBucketRow {
  @JsonProperty('columns', [FeedbackBatchBucketColumn])
  columns?: FeedbackBatchBucketColumn[] = [];

  @JsonProperty('consensus_overwritten', BucketConsensusOverwritten)
  consensusOverwritten?: BucketConsensusOverwritten;

  row_id?: string | undefined;

  enabled?: string | undefined;
}
