import { CustomIconProps } from './CustomIconProps';

export function FileIcon({ fill = 'none', ...props }: CustomIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill} {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M11.6666 1.89111V5.33323C11.6666 5.79994 11.6666 6.0333 11.7574 6.21156C11.8373 6.36836 11.9648 6.49584 12.1216 6.57574C12.2999 6.66656 12.5332 6.66656 12.9999 6.66656H16.442M13.3333 10.8332H6.66658M13.3333 14.1665H6.66658M8.33325 7.49984H6.66658M11.6666 1.6665H7.33325C5.93312 1.6665 5.23305 1.6665 4.69828 1.93899C4.22787 2.17867 3.84542 2.56112 3.60574 3.03153C3.33325 3.56631 3.33325 4.26637 3.33325 5.6665V14.3332C3.33325 15.7333 3.33325 16.4334 3.60574 16.9681C3.84542 17.4386 4.22787 17.821 4.69828 18.0607C5.23305 18.3332 5.93312 18.3332 7.33325 18.3332H12.6666C14.0667 18.3332 14.7668 18.3332 15.3016 18.0607C15.772 17.821 16.1544 17.4386 16.3941 16.9681C16.6666 16.4334 16.6666 15.7333 16.6666 14.3332V6.6665L11.6666 1.6665Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
