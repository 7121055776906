function CPU() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Computer Chip 2" clipPath="url(#clip0_12990_74366)">
        <g id="Group">
          <g id="Group_2">
            <path
              id="Vector"
              /* eslint-disable-next-line max-len */
              d="M8.70039 2.3999H3.30039C2.80333 2.3999 2.40039 2.80285 2.40039 3.2999V8.6999C2.40039 9.19696 2.80333 9.5999 3.30039 9.5999H8.70039C9.19745 9.5999 9.60039 9.19696 9.60039 8.6999V3.2999C9.60039 2.80285 9.19745 2.3999 8.70039 2.3999Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M4.19922 1.2001L4.19922 0.600098"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M7.79883 1.2001L7.79883 0.600098"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path id="Vector_4" d="M2.39961 7.80029H0.599609" stroke="#2E9FFF" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_5" d="M2.39961 4.2002H0.599609" stroke="#2E9FFF" strokeLinecap="round" strokeLinejoin="round" />
            <path
              id="Vector_6"
              d="M7.79883 10.8003L7.79883 11.4003"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_7"
              d="M4.19922 10.8003L4.19922 11.4003"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_8"
              d="M9.59961 4.2002L11.3996 4.2002"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_9"
              d="M9.59961 7.80029L11.3996 7.80029"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path id="Vector_10" d="M7.19883 7.2002H4.79883" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_12990_74366">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CPU;
