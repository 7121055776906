import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('DecisionsResponse')
export class DecisionsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('confirmed', Number)
  confirmed: number;

  @JsonProperty('confirmed_percent', Number)
  confirmedPercent: number;

  @JsonProperty('changed', Number)
  changed: number;

  @JsonProperty('changed_percent', Number)
  changedPercent: number;

  @JsonProperty('feedback_count', Number)
  feedbackCount: number;

  @JsonProperty('users_consensus_percent', Number)
  usersConsensusPercent: number;

  @JsonProperty('users_count', Number)
  usersCount: number;

  @JsonProperty('models_count', Number)
  modelsCount: number;
}
