import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import _ from 'lodash';

const encode = (str: string) => window.btoa(str);

const decode = (str: string) => {
  try {
    return window.atob(str);
  } catch (err) {
    return str;
  }
};

const filtersToParams = (filtersObject: unknown) =>
  qs.stringify(filtersObject, { encodeValuesOnly: true, strictNullHandling: true });

function useQueryParams<T extends Record<string, string>>() {
  const { search } = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => {
    const urlParams = new URLSearchParams(search.substring(search.indexOf('?')));

    return Object.fromEntries(urlParams) as T;
  }, [search]);

  const setSearchParams = useCallback(
    (params: T) => {
      const parsedParams = qs.stringify(params);
      history.push({ search: parsedParams });
    },
    [history]
  );

  const setQueryParams = useCallback(
    (params: T) => {
      const newParams = Object.keys(params).reduce((acc, key) => {
        if (_.isEmpty(params[key])) {
          return acc;
        }

        return {
          ...acc,
          [key]: params[key]
        };
      }, {}) as T;
      setSearchParams(newParams);

      return params;
    },
    [setSearchParams]
  );

  return {
    queryParams,
    setQueryParams
  };
}

const QueryParams = {
  encode,
  decode,
  filtersToParams,
  useQueryParams
};

export { QueryParams };
