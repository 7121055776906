import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobTrainingStatusBreakdownEntry')
export class JobTrainingStatusBreakdownEntry {
  @JsonProperty('buckets', Number)
  buckets?: number;

  @JsonProperty('events', Number)
  events?: number;

  @JsonProperty('name', String)
  name?: string;
}
