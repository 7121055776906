import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as AuthConstants from './AuthConstants';
import { AuthResponse } from '../../../shared/models/auth/AuthResponse';
import { CustomerRecord } from '../../../shared/models/customer/CustomerRecord';
import { useFetch } from '../../../shared/hooks/useFetch';
import { getBackendEndpoint } from '../../../shared/utilities/api';
import { AuthStatusRequest } from '../../../shared/models/auth/AuthStatusRequest';
import { AuthStatusResponse } from '../../../shared/models/auth/AuthStatusResponse';
import { getJsonConvert } from '../../../shared/utilities/json-convert';
import { DeAuthRequest } from '../../../shared/models/auth/DeAuthRequest';

// eslint-disable-next-line
export const AuthContext = createContext<AuthContextType>(null as any);

export type AuthStateType = {
  isLoggedIn: boolean;
  userSessionId: string;
  customerName: string;
  customer: CustomerRecord;
  forcePasswordChange: boolean;
  // makes the LeavePrompt visible when trying to log out while changes are being made in the TransferTable or
  // in the JobEventExplorerColumns
  isLoggingOut: boolean;
  // true by default, set to false when the LeavePrompt appears
  readyToLogOut: boolean;
};

export type AuthContextType = {
  state: AuthStateType;
  setAuthResponse: (authResponse: AuthResponse) => void;
  // eslint-disable-next-line
  setOidcAuthResponse: (oidcAuthResonse: any) => void;
  logout: () => void;
  getToken: () => string;
  getCustomerName: () => string;
  getUsername: () => string;
  setForcePasswordChange: (value: boolean) => void;
  setIsLoggingOut: (value: boolean) => void;
  setReadyToLogOut: (value: boolean) => void;
};

// eslint-disable-next-line
export function AuthProvider(props: any) {
  const token = localStorage.getItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME);
  const userSessionId = localStorage.getItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME);
  const customerName = localStorage.getItem(AuthConstants.LOCAL_STORAGE_CUSTOMER_NAME);

  const [state, setState] = useState<AuthStateType>({
    isLoggedIn: !!token,
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    userSessionId,
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    customerName,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'CustomerRec...
    customer: null,
    forcePasswordChange: false,
    isLoggingOut: false,
    readyToLogOut: true
  });

  const setAuthResponse = useCallback((authResponse: AuthResponse) => {
    // @ts-expect-error TS(2345): Argument of type '(current: AuthStateType) => { is...
    setState((current) => ({
      ...current,
      isLoggedIn: true,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      userSessionId: authResponse.user.userSessionId,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      customerName: authResponse.customer.name,
      customer: authResponse.customer,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      forcePasswordChange: authResponse.user.force_password_change
    }));
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    localStorage.setItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME, authResponse.user.userSessionId);
    localStorage.setItem(
      AuthConstants.LOCAL_STORAGE_CUSTOMER_NAME,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      authResponse.customer.name ? authResponse.customer.name : authResponse.user.name
    );
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    localStorage.setItem(AuthConstants.LOCAL_STORAGE_USERNAME, authResponse.user.name);
  }, []);

  // eslint-disable-next-line
  const setOidcAuthResponse = useCallback((oidcAuthResponse: any) => {
    const bearerToken = AuthConstants.BEARER_PREFIX + oidcAuthResponse.access_token;
    setState((current) => ({
      ...current,
      isLoggedIn: true,
      userSessionId: bearerToken,
      customerName: oidcAuthResponse.username
    }));
    localStorage.setItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME, bearerToken);
    localStorage.setItem(AuthConstants.LOCAL_STORAGE_CUSTOMER_NAME, oidcAuthResponse.username);
    localStorage.setItem(AuthConstants.LOCAL_STORAGE_REFRESH_TOKEN_NAME, oidcAuthResponse.refresh_token);
  }, []);

  const clearState = useCallback(() => {
    localStorage.removeItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME);
    localStorage.removeItem(AuthConstants.LOCAL_STORAGE_CUSTOMER_NAME);
    localStorage.removeItem(AuthConstants.LOCAL_STORAGE_REFRESH_TOKEN_NAME);
    // @ts-expect-error TS(2345): Argument of type '(current: AuthStateType) => { is...
    setState((current) => ({
      ...current,
      isLoggedIn: false,
      userSessionId: null,
      forcePasswordChange: false,
      isLoggingOut: false,
      readyToLogOut: true
    }));
  }, []);

  // De-authenticate user
  const { post: postDeAuth } = useFetch({
    path: getBackendEndpoint('/user/deauth'),
    load: false
  });

  const logout = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable...
    postDeAuth(new DeAuthRequest(getToken()))
      .then(() => {
        clearState();
      })
      .catch(() => {
        clearState();
      });
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const setForcePasswordChange = useCallback((value: any) => {
    setState((current) => ({
      ...current,
      forcePasswordChange: value
    }));
  }, []);

  // eslint-disable-next-line
  const setIsLoggingOut = useCallback((value: any) => {
    setState((current) => ({
      ...current,
      isLoggingOut: value
    }));
  }, []);

  // eslint-disable-next-line
  const setReadyToLogOut = useCallback((value: any) => {
    setState((current) => ({
      ...current,
      readyToLogOut: value
    }));
  }, []);

  const getToken = useCallback(() => localStorage.getItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME), []);

  const getCustomerName = useCallback(() => localStorage.getItem(AuthConstants.LOCAL_STORAGE_CUSTOMER_NAME), []);

  const getUsername = useCallback(() => localStorage.getItem(AuthConstants.LOCAL_STORAGE_USERNAME), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/user/auth_status'),
    load: false
  });

  const jsonConvert = useMemo(() => getJsonConvert(), []);

  useEffect(() => {
    if (!!token === false) {
      return;
    }

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (token.indexOf('Bearer:') === 0) {
      return;
    }

    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable...
    // eslint-disable-next-line
    post(new AuthStatusRequest(token)).then((response: any) => {
      const respJson = jsonConvert.deserializeObject(response.data.resource, AuthStatusResponse);
      if (respJson.is_authenticated === false) {
        logout();
      }
      setForcePasswordChange(respJson.force_password_change);
    });
  }, [post, jsonConvert, logout, token, setForcePasswordChange]);

  useEffect(
    function checkUsernameExistsInLocalStorage() {
      // username is a new field introduced in local storage, will need user to logout and login again if it doesn't exist
      if (!state.isLoggedIn) {
        return;
      }

      if (getUsername()) {
        return;
      }

      logout();
    },
    [state.isLoggedIn, getUsername, logout]
  );

  const authContext: AuthContextType = {
    state,
    setAuthResponse,
    setOidcAuthResponse,
    logout,
    // @ts-expect-error TS(2322): Type '() => string | null' is not assignable to ty...
    getToken,
    // @ts-expect-error TS(2322): Type '() => string | null' is not assignable to ty...
    getCustomerName,
    // @ts-expect-error TS(2322): Type '() => string | null' is not assignable to ty...
    getUsername,
    setForcePasswordChange,
    setIsLoggingOut,
    setReadyToLogOut
  };

  const { children } = props;
  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
