import { useTranslation } from 'react-i18next';
import { config } from 'src/config';
import { useApp } from 'src/components/App/App.context';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { getMenuItems } from './useMainNavItems.constants';

export const useMainNavItems = ({
  jobCount,
  integrationCount
}: {
  jobCount: number | undefined;
  integrationCount: number | undefined;
}) => {
  const { t } = useTranslation('common');
  const { hasAccessFor } = useApp();
  const location = useLocation();

  const mainNavItems = useMemo(
    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
    () => getMenuItems({ jobCount, t, integrationCount }).filter((menuItem) => hasAccessFor(menuItem.permissions as string[])),
    [hasAccessFor, jobCount, integrationCount, t]
  );

  const activeMenuItemId = useMemo(
    () =>
      mainNavItems.find((menuItem) => {
        if (menuItem.subitems) {
          return Boolean(menuItem.subitems.find((subItem) => matchPath(location.pathname, { path: subItem.path })));
        }

        return menuItem.path !== config.routes.homepage && matchPath(location.pathname, { path: menuItem.path });
      })?.id ?? 'homepage',
    [mainNavItems, location.pathname]
  );

  return useMemo(
    () => ({
      activeMenuItemId,
      mainNavItems
    }),
    [activeMenuItemId, mainNavItems]
  );
};
