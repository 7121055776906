import { useCallback, useMemo } from 'react';
import { EFilterOperatorWithValue, EFilterOperatorWithoutValue, TFilterItem } from '../AdvancedFilters.types';
import { getAreFilterItemsEqual, getIsOperatorWithValue } from '../AdvancedFilters.utils';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

type TUseQuickFiltersProps = {
  value: string | undefined;
  filterSource: string | undefined;
  columnName: string;
};

function useQuickFilters({ value, filterSource, columnName }: TUseQuickFiltersProps) {
  const getIsFilterPresent = useCallback((newFilter: TFilterItem, activeFilters: TFilterItem[]) => {
    return Boolean(
      activeFilters.find((appliedFilter) => {
        return getAreFilterItemsEqual(appliedFilter, newFilter);
      })
    );
  }, []);

  const getFilterToApply = useCallback(
    (operatorWithValue: EFilterOperatorWithValue, operatorWithoutValue: EFilterOperatorWithoutValue) => {
      const operator = !_.isNil(value) ? operatorWithValue : operatorWithoutValue;

      const filterToApply: TFilterItem | undefined = filterSource
        ? ({
            id: uuidv4(),
            label: columnName,
            field: filterSource,
            operator: operator,
            ...(getIsOperatorWithValue(operator)
              ? {
                  value: value ?? ''
                }
              : {}),
            status: 'active'
          } as TFilterItem)
        : undefined;

      return {
        filterToApply
      };
    },
    [value, columnName, filterSource]
  );

  return useMemo(
    () => ({
      getIsFilterPresent,
      getFilterToApply
    }),
    [getIsFilterPresent, getFilterToApply]
  );
}

export default useQuickFilters;
