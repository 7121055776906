import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import { AxiosResponse } from 'axios';
import { JsonConvert, OperationMode, PropertyConvertingMode, PropertyMatchingRule, ValueCheckingMode } from 'json2typescript';

class Serializer {
  private static instance: Serializer;
  private jsonConvert: JsonConvert;

  private constructor() {
    this.jsonConvert = new JsonConvert(
      OperationMode.ENABLE,
      ValueCheckingMode.DISALLOW_NULL,
      false,
      PropertyMatchingRule.CASE_STRICT
    );
    this.jsonConvert.mapUndefinedToNull = false;
    this.jsonConvert.propertyConvertingMode = PropertyConvertingMode.PASS_NULLABLE;
    this.jsonConvert.useDiscriminator = false;
  }

  public static getInstance(): Serializer {
    if (!Serializer.instance) {
      Serializer.instance = new Serializer();
    }
    return Serializer.instance;
  }

  public serializeObject<T extends object, U extends object>(data: T, classReference?: { new (): U }): object {
    return this.jsonConvert.serializeObject<T, U>(data, classReference);
  }

  public serializeArray<T extends object, U extends object>(dataArray: T[], classReference?: { new (): U }): object[] {
    return this.jsonConvert.serializeArray<T, U>(dataArray, classReference);
  }

  public deserializeObject<T extends object, U extends object>(data: T, classReference: { new (): U }): U {
    return this.jsonConvert.deserializeObject(data, classReference);
  }

  public deserializeArray<T extends object, U extends object>(dataArray: T[], classReference: { new (): U }): U[] {
    return this.jsonConvert.deserializeArray(dataArray, classReference);
  }

  // Common response deserialization
  public deserializeCommonResponse<T extends ResponseCommon, U extends object>(
    commonResponse: AxiosResponse<ResourceWrapper<T>>,
    classReference: {
      new (): U;
    }
  ): U {
    return this.jsonConvert.deserializeObject(commonResponse.data.resource, classReference);
  }
}

export default Serializer;
