import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { JobAlertsTimeseriesIntervalRecord } from './JobAlertsTimeseriesIntervalRecord';
import { JobAlertsTimeseriesRecord } from './JobAlertsTimeseriesRecord';

@JsonObject('JobAlertsTimeseriesResponse')
export class JobAlertsTimeseriesResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('labels', [String])
  labels: string[];

  @JsonProperty('aggs', [JobAlertsTimeseriesRecord])
  timeseries: JobAlertsTimeseriesRecord[];

  @JsonProperty('retrain_timestamps', [String])
  retrain_timestamps: string[];

  @JsonProperty('interval', JobAlertsTimeseriesIntervalRecord)
  interval: JobAlertsTimeseriesIntervalRecord;
}
