import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './JobOverviewMetrics.module.css';
import { usePipeline } from '../../../../../../data-access';

type PipelineActionsProps = {
  jobId: number;
};

export default function PipelineActions({ jobId }: PipelineActionsProps) {
  const { t } = useTranslation(['job']);
  const pipelineQuery = usePipeline(jobId);

  return (
    <>
      <Row>
        <Typography.Text className="body-2 color-nb-100">{t('job:jobTimeMetrics:pipelineActionsTitle')}</Typography.Text>
      </Row>
      <Row>
        <Col className="p-r-24 p-t-8">
          <Row>
            <Typography.Text type="secondary" className="color-nb-300">
              {t('job:jobTimeMetrics:enrichments')}
            </Typography.Text>
          </Row>
          <Row>
            <span className={style.metricsNumber}>{pipelineQuery.data?.enrichmentActions?.toLocaleString() || 0}</span>
          </Row>
        </Col>
        <Col className="p-r-8 p-t-8">
          <Row>
            <Typography.Text type="secondary" className="color-nb-300">
              {t('job:jobTimeMetrics:postDecisions')}
            </Typography.Text>
          </Row>
          <Row>
            <span className={style.metricsNumber}>{pipelineQuery.data?.postDecisionActions?.toLocaleString() || 0}</span>
          </Row>
        </Col>
      </Row>
    </>
  );
}
