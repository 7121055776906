import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { Operator } from './Operator';

@JsonObject('OperatorsResponse')
export class OperatorsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('operators', [Operator])
  operators: Operator[];
}
