import { useMemo } from 'react';
import { RHFRadioGroup } from '@arcanna/forms';
import { getFormParameterName } from '../RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';

function RadioGroupParameter({ parameter, formSectionName }: TRHFSmartGeneratedFieldProps) {
  const radioGroupOptions = useMemo(
    () =>
      parameter.allowed_values?.map((allowedValue) => ({
        label: allowedValue.caption,
        value: allowedValue.value
      })) ?? [],
    [parameter.allowed_values]
  );

  return (
    <RHFRadioGroup
      name={getFormParameterName(parameter, formSectionName)}
      label={parameter.caption}
      tooltipText={parameter.description}
      required={parameter.required}
      // @ts-expect-error TS(2322): Type '{ label: string | undefined; value: string |...
      options={radioGroupOptions}
      row
    />
  );
}

export default RadioGroupParameter;
