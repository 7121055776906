import { JsonObject, JsonProperty } from 'json2typescript';
import { LOCAL_STORAGE_TOKEN_NAME } from '../../../pages/Main/Login/AuthConstants';

@JsonObject('CustomFieldSummaryRequest')
export class CustomFieldSummaryRequest {
  @JsonProperty('user_session_id', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  user_session_id: string = undefined;

  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('name', String)
  name: string;

  constructor(job_id: number, name: string) {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    this.user_session_id = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
    this.job_id = job_id;
    this.name = name;
  }
}
