import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ActionListRecord')
export class ActionListRecord {
  @JsonProperty('id', Number)
  id: number | undefined;

  @JsonProperty('caption', String)
  caption: string | undefined;

  @JsonProperty('resource_id', String)
  resource_id?: string | undefined;

  @JsonProperty('object_type', String)
  object_type?: string | undefined;
}
