import { SVGProps } from 'react';

type TWarningIconProps = SVGProps<SVGSVGElement> & {
  fill?: string;
};

export default function Warning({ fill = 'none', ...props }: TWarningIconProps) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M11.7838 3.6454L11.7838 3.64541L11.7853 3.6429C11.8314 3.56196 11.9101 3.52026 12.0001 3.52026C12.0876 3.52026 12.1669 3.56014 12.2165 3.64565C12.2166 3.64575 12.2166 3.64584 12.2167 3.64594L21.9658 20.5194C21.9659 20.5196 21.966 20.5197 21.9661 20.5199C22.0628 20.6888 21.9411 20.8953 21.7501 20.8953H2.25011C2.05909 20.8953 1.93748 20.6888 2.0341 20.5199C2.0342 20.5198 2.03429 20.5196 2.03438 20.5194L11.7838 3.6454ZM11.9899 18.7077L12.0001 18.7079L12.0103 18.7077C12.4355 18.699 12.8404 18.524 13.138 18.2202C13.4357 17.9164 13.6024 17.5081 13.6024 17.0828C13.6024 16.6575 13.4357 16.2491 13.138 15.9453C12.8404 15.6416 12.4355 15.4665 12.0103 15.4579L12.0001 15.4577L11.9899 15.4579C11.5647 15.4665 11.1598 15.6416 10.8622 15.9453C10.5645 16.2491 10.3978 16.6575 10.3978 17.0828C10.3978 17.5081 10.5645 17.9164 10.8622 18.2202C11.1598 18.524 11.5647 18.699 11.9899 18.7077ZM11.4376 9.27026C11.0583 9.27026 10.7501 9.5785 10.7501 9.95776V14.2703C10.7501 14.6495 11.0583 14.9578 11.4376 14.9578H12.5626C12.9419 14.9578 13.2501 14.6495 13.2501 14.2703V9.95776C13.2501 9.5785 12.9419 9.27026 12.5626 9.27026H11.4376Z"
        fill="#D97706"
        stroke="#D97706"
      />
    </svg>
  );
}
