/* eslint-disable max-len */
function InternalError() {
  return (
    <svg width="312" height="341" viewBox="0 0 312 341" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.22"
        d="M156 340.69C241.693 340.69 311.16 329.676 311.16 316.09C311.16 302.504 241.693 291.49 156 291.49C70.3075 291.49 0.839996 302.504 0.839996 316.09C0.839996 329.676 70.3075 340.69 156 340.69Z"
        fill="#2A3247"
      />
      <path
        d="M79.19 159.69L93.97 272.22C94.0701 273.046 94.469 273.808 95.0917 274.36C95.7143 274.913 96.5175 275.219 97.35 275.22H212.68C213.513 275.221 214.318 274.916 214.941 274.363C215.564 273.81 215.962 273.047 216.06 272.22L230.84 159.69H79.19ZM210.05 269.82H99.98L86.22 165.03H223.81L210.05 269.82Z"
        fill="#0F162B"
        stroke="#20273B"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.52 1H172.52C176.27 1 179.867 2.48975 182.518 5.14151C185.17 7.79327 186.66 11.3898 186.66 15.14V46.19H123.38V15.14C123.38 11.3898 124.87 7.79327 127.522 5.14151C130.173 2.48975 133.77 1 137.52 1Z"
        fill="#0F162B"
        stroke="#20273B"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.28 278.18H211.76V290.69C211.76 290.846 211.698 290.997 211.587 291.107C211.477 291.218 211.326 291.28 211.17 291.28H98.86C98.7035 291.28 98.5535 291.218 98.4428 291.107C98.3322 290.997 98.27 290.846 98.27 290.69V278.18H98.28Z"
        fill="#1B2236"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.19 163.69L93.97 276.22C94.068 277.047 94.4663 277.81 95.0894 278.363C95.7125 278.916 96.5169 279.221 97.35 279.22H212.68C213.514 279.223 214.319 278.919 214.943 278.365C215.566 277.812 215.964 277.048 216.06 276.22L230.84 163.69H79.19ZM210.05 273.83H99.98L86.22 169.05H223.81L210.05 273.83Z"
        fill="#1B2236"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M304.15 12.05H5.87999V235H304.15V12.05Z"
        fill="#1B2236"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.25 13.06C156.47 13.06 158.27 11.2602 158.27 9.04002C158.27 6.81984 156.47 5.02002 154.25 5.02002C152.03 5.02002 150.23 6.81984 150.23 9.04002C150.23 11.2602 152.03 13.06 154.25 13.06Z"
        fill="#465064"
        stroke="#20273B"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M293.4 20.0801H16.63V226.96H293.4V20.0801Z"
        fill="#091025"
        stroke="#091025"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1198 28.9297C24.1198 28.3223 24.3611 27.7398 24.7905 27.3104C25.22 26.8809 25.8024 26.6396 26.4098 26.6396C27.0171 26.6396 27.5996 26.8809 28.0291 27.3104C28.4585 27.7398 28.6998 28.3223 28.6998 28.9297V33.9297C28.6998 34.537 28.4585 35.1195 28.0291 35.5489C27.5996 35.9784 27.0171 36.2197 26.4098 36.2197C25.8024 36.2197 25.22 35.9784 24.7905 35.5489C24.3611 35.1195 24.1198 34.537 24.1198 33.9297V28.9297ZM25.6298 34.0097C25.6298 34.6997 25.9298 35.0097 26.4098 35.0097C26.8898 35.0097 27.1898 34.7497 27.1898 34.0097V28.8297C27.1898 28.1497 26.8898 27.8896 26.4098 27.8896C25.9298 27.8896 25.6298 28.1497 25.6298 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M29.5901 28.9295C29.5694 28.6168 29.6131 28.303 29.7186 28.0078C29.8241 27.7126 29.989 27.4423 30.2032 27.2134C30.4174 26.9845 30.6764 26.8021 30.9639 26.6774C31.2515 26.5526 31.5616 26.4883 31.8751 26.4883C32.1886 26.4883 32.4987 26.5526 32.7863 26.6774C33.0739 26.8021 33.3328 26.9845 33.547 27.2134C33.7612 27.4423 33.9262 27.7126 34.0316 28.0078C34.1371 28.303 34.1808 28.6168 34.1601 28.9295V33.9295C34.1808 34.2423 34.1371 34.556 34.0316 34.8512C33.9262 35.1464 33.7612 35.4168 33.547 35.6457C33.3328 35.8745 33.0739 36.057 32.7863 36.1817C32.4987 36.3064 32.1886 36.3708 31.8751 36.3708C31.5616 36.3708 31.2515 36.3064 30.9639 36.1817C30.6764 36.057 30.4174 35.8745 30.2032 35.6457C29.989 35.4168 29.8241 35.1464 29.7186 34.8512C29.6131 34.556 29.5694 34.2423 29.5901 33.9295V28.9295ZM31.0901 34.0095C31.0901 34.6995 31.4001 35.0095 31.8801 35.0095C32.3601 35.0095 32.6601 34.7495 32.6601 34.0095V28.8295C32.6601 28.1495 32.3501 27.8895 31.8801 27.8895C31.4101 27.8895 31.0901 28.1495 31.0901 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 28.9297C34.9699 28.3223 35.2111 27.7398 35.6406 27.3104C36.0701 26.8809 36.6525 26.6396 37.2599 26.6396C37.8672 26.6396 38.4497 26.8809 38.8792 27.3104C39.3086 27.7398 39.5499 28.3223 39.5499 28.9297V33.9297C39.5499 34.537 39.3086 35.1195 38.8792 35.5489C38.4497 35.9784 37.8672 36.2197 37.2599 36.2197C36.6525 36.2197 36.0701 35.9784 35.6406 35.5489C35.2111 35.1195 34.9699 34.537 34.9699 33.9297V28.9297ZM36.4799 34.0097C36.4799 34.6997 36.7799 35.0097 37.2599 35.0097C37.7399 35.0097 38.0399 34.7497 38.0399 34.0097V28.8297C38.0399 28.1497 37.7399 27.8896 37.2599 27.8896C36.7799 27.8896 36.4799 28.1497 36.4799 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 27.8299C41.8277 27.88 42.2102 27.7835 42.519 27.5603C42.8277 27.3372 43.0392 27.0042 43.1101 26.6299H44.1101V36.2199H42.5901V28.8999H41.4201L41.4501 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M49.0001 27.8299C49.3778 27.88 49.7603 27.7835 50.069 27.5603C50.3778 27.3372 50.5893 27.0042 50.6601 26.6299H51.6601V36.2199H50.1501V28.8999H48.9801L49.0001 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M54.4298 27.8299C54.8071 27.8775 55.1884 27.7801 55.4966 27.5573C55.8047 27.3346 56.0168 27.0031 56.0898 26.6299H57.0898V36.2199H55.5898V28.8999H54.4298V27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M59.86 27.8299C60.2365 27.8797 60.6177 27.783 60.9248 27.5596C61.2319 27.3363 61.4414 27.0034 61.51 26.6299H62.51V36.2199H61V28.8999H59.84L59.86 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M65.2802 27.8299C65.6578 27.88 66.0403 27.7835 66.3491 27.5603C66.6578 27.3372 66.8693 27.0042 66.9402 26.6299H67.9402V36.2199H66.4402V28.8999H65.2702L65.2802 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M70.7099 27.8299C71.0871 27.8775 71.4684 27.7801 71.7766 27.5573C72.0847 27.3346 72.2968 27.0031 72.3699 26.6299H73.3699V36.2199H71.8599V28.8999H70.6999L70.7099 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M77.1999 28.9297C77.1999 28.3223 77.4411 27.7398 77.8706 27.3104C78.3 26.8809 78.8825 26.6396 79.4899 26.6396C80.0972 26.6396 80.6797 26.8809 81.1091 27.3104C81.5386 27.7398 81.7799 28.3223 81.7799 28.9297V33.9297C81.7799 34.537 81.5386 35.1195 81.1091 35.5489C80.6797 35.9784 80.0972 36.2197 79.4899 36.2197C78.8825 36.2197 78.3 35.9784 77.8706 35.5489C77.4411 35.1195 77.1999 34.537 77.1999 33.9297V28.9297ZM78.7099 34.0097C78.7099 34.6997 79.0099 35.0097 79.4899 35.0097C79.9699 35.0097 80.2699 34.7497 80.2699 34.0097V28.8297C80.2699 28.1497 79.9699 27.8896 79.4899 27.8896C79.0099 27.8896 78.7099 28.1497 78.7099 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M82.5901 28.9295C82.5694 28.6168 82.6131 28.303 82.7186 28.0078C82.8241 27.7126 82.989 27.4423 83.2032 27.2134C83.4174 26.9845 83.6764 26.8021 83.9639 26.6774C84.2515 26.5526 84.5616 26.4883 84.8751 26.4883C85.1886 26.4883 85.4987 26.5526 85.7863 26.6774C86.0739 26.8021 86.3328 26.9845 86.547 27.2134C86.7612 27.4423 86.9262 27.7126 87.0316 28.0078C87.1371 28.303 87.1808 28.6168 87.1601 28.9295V33.9295C87.1808 34.2423 87.1371 34.556 87.0316 34.8512C86.9262 35.1464 86.7612 35.4168 86.547 35.6457C86.3328 35.8745 86.0739 36.057 85.7863 36.1817C85.4987 36.3064 85.1886 36.3708 84.8751 36.3708C84.5616 36.3708 84.2515 36.3064 83.9639 36.1817C83.6764 36.057 83.4174 35.8745 83.2032 35.6457C82.989 35.4168 82.8241 35.1464 82.7186 34.8512C82.6131 34.556 82.5694 34.2423 82.5901 33.9295V28.9295ZM84.0901 34.0095C84.0901 34.6995 84.4001 35.0095 84.8801 35.0095C85.3601 35.0095 85.6601 34.7495 85.6601 34.0095V28.8295C85.6601 28.1495 85.3601 27.8895 84.8801 27.8895C84.4001 27.8895 84.0901 28.1495 84.0901 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M88.05 28.9297C88.05 28.3223 88.2912 27.7398 88.7207 27.3104C89.1501 26.8809 89.7326 26.6396 90.34 26.6396C90.9473 26.6396 91.5298 26.8809 91.9592 27.3104C92.3887 27.7398 92.6299 28.3223 92.6299 28.9297V33.9297C92.6299 34.537 92.3887 35.1195 91.9592 35.5489C91.5298 35.9784 90.9473 36.2197 90.34 36.2197C89.7326 36.2197 89.1501 35.9784 88.7207 35.5489C88.2912 35.1195 88.05 34.537 88.05 33.9297V28.9297ZM89.56 34.0097C89.56 34.6997 89.86 35.0097 90.34 35.0097C90.82 35.0097 91.1199 34.7497 91.1199 34.0097V28.8297C91.1199 28.1497 90.82 27.8896 90.34 27.8896C89.86 27.8896 89.56 28.1497 89.56 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M93.4801 28.9297C93.4801 28.3223 93.7214 27.7398 94.1509 27.3104C94.5803 26.8809 95.1628 26.6396 95.7701 26.6396C96.3775 26.6396 96.9599 26.8809 97.3894 27.3104C97.8189 27.7398 98.0601 28.3223 98.0601 28.9297V33.9297C98.0601 34.537 97.8189 35.1195 97.3894 35.5489C96.9599 35.9784 96.3775 36.2197 95.7701 36.2197C95.1628 36.2197 94.5803 35.9784 94.1509 35.5489C93.7214 35.1195 93.4801 34.537 93.4801 33.9297V28.9297ZM94.9901 34.0097C94.9901 34.6997 95.2901 35.0097 95.7701 35.0097C96.2501 35.0097 96.5501 34.7497 96.5501 34.0097V28.8297C96.5501 28.1497 96.2501 27.8896 95.7701 27.8896C95.2901 27.8896 94.9901 28.1497 94.9901 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M98.9099 28.9295C98.8892 28.6168 98.933 28.303 99.0384 28.0078C99.1439 27.7126 99.3088 27.4423 99.523 27.2134C99.7372 26.9845 99.9962 26.8021 100.284 26.6774C100.571 26.5526 100.881 26.4883 101.195 26.4883C101.508 26.4883 101.819 26.5526 102.106 26.6774C102.394 26.8021 102.653 26.9845 102.867 27.2134C103.081 27.4423 103.246 27.7126 103.351 28.0078C103.457 28.303 103.501 28.6168 103.48 28.9295V33.9295C103.501 34.2423 103.457 34.556 103.351 34.8512C103.246 35.1464 103.081 35.4168 102.867 35.6457C102.653 35.8745 102.394 36.057 102.106 36.1817C101.819 36.3064 101.508 36.3708 101.195 36.3708C100.881 36.3708 100.571 36.3064 100.284 36.1817C99.9962 36.057 99.7372 35.8745 99.523 35.6457C99.3088 35.4168 99.1439 35.1464 99.0384 34.8512C98.933 34.556 98.8892 34.2423 98.9099 33.9295V28.9295ZM100.41 34.0095C100.41 34.6995 100.71 35.0095 101.19 35.0095C101.67 35.0095 101.97 34.7495 101.97 34.0095V28.8295C101.97 28.1495 101.67 27.8895 101.19 27.8895C100.71 27.8895 100.41 28.1495 100.41 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M109.59 27.8299C109.968 27.88 110.35 27.7835 110.659 27.5603C110.968 27.3372 111.179 27.0042 111.25 26.6299H112.25V36.2199H110.74V28.8999H109.59V27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M115.06 27.8299C115.437 27.8775 115.819 27.7801 116.127 27.5573C116.435 27.3346 116.647 27.0031 116.72 26.6299H117.72V36.2199H116.21V28.8999H115.05L115.06 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M120.49 27.8299C120.866 27.8797 121.248 27.783 121.555 27.5596C121.862 27.3363 122.071 27.0034 122.14 26.6299H123.14V36.2199H121.63V28.8999H120.49V27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M125.91 27.8299C126.288 27.88 126.67 27.7835 126.979 27.5603C127.288 27.3372 127.499 27.0042 127.57 26.6299H128.57V36.2199H127.07V28.8999H125.9L125.91 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M131.34 27.8299C131.718 27.8775 132.099 27.7801 132.407 27.5573C132.715 27.3346 132.927 27.0031 133 26.6299H134V36.2199H132.49V28.8999H131.33L131.34 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M136.76 27.8299C137.138 27.88 137.52 27.7835 137.829 27.5603C138.138 27.3372 138.349 27.0042 138.42 26.6299H139.42V36.2199H137.92V28.8999H136.75L136.76 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M141.13 28.9297C141.13 28.3223 141.371 27.7398 141.801 27.3104C142.23 26.8809 142.813 26.6396 143.42 26.6396C144.027 26.6396 144.61 26.8809 145.039 27.3104C145.469 27.7398 145.71 28.3223 145.71 28.9297V33.9297C145.71 34.537 145.469 35.1195 145.039 35.5489C144.61 35.9784 144.027 36.2197 143.42 36.2197C142.813 36.2197 142.23 35.9784 141.801 35.5489C141.371 35.1195 141.13 34.537 141.13 33.9297V28.9297ZM142.64 34.0097C142.64 34.6997 142.94 35.0097 143.42 35.0097C143.9 35.0097 144.2 34.7497 144.2 34.0097V28.8297C144.2 28.1497 143.9 27.8896 143.42 27.8896C142.94 27.8896 142.64 28.1497 142.64 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M146.59 28.9297C146.59 28.3223 146.831 27.7398 147.261 27.3104C147.69 26.8809 148.273 26.6396 148.88 26.6396C149.487 26.6396 150.07 26.8809 150.499 27.3104C150.929 27.7398 151.17 28.3223 151.17 28.9297V33.9297C151.17 34.537 150.929 35.1195 150.499 35.5489C150.07 35.9784 149.487 36.2197 148.88 36.2197C148.273 36.2197 147.69 35.9784 147.261 35.5489C146.831 35.1195 146.59 34.537 146.59 33.9297V28.9297ZM148.1 34.0097C148.1 34.6997 148.4 35.0097 148.88 35.0097C149.36 35.0097 149.66 34.7497 149.66 34.0097V28.8297C149.66 28.1497 149.36 27.8896 148.88 27.8896C148.4 27.8896 148.1 28.1497 148.1 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M151.99 28.9295C151.969 28.6168 152.013 28.303 152.119 28.0078C152.224 27.7126 152.389 27.4423 152.603 27.2134C152.817 26.9845 153.076 26.8021 153.364 26.6774C153.651 26.5526 153.962 26.4883 154.275 26.4883C154.588 26.4883 154.899 26.5526 155.186 26.6774C155.474 26.8021 155.733 26.9845 155.947 27.2134C156.161 27.4423 156.326 27.7126 156.432 28.0078C156.537 28.303 156.581 28.6168 156.56 28.9295V33.9295C156.581 34.2423 156.537 34.556 156.432 34.8512C156.326 35.1464 156.161 35.4168 155.947 35.6457C155.733 35.8745 155.474 36.057 155.186 36.1817C154.899 36.3064 154.588 36.3708 154.275 36.3708C153.962 36.3708 153.651 36.3064 153.364 36.1817C153.076 36.057 152.817 35.8745 152.603 35.6457C152.389 35.4168 152.224 35.1464 152.119 34.8512C152.013 34.556 151.969 34.2423 151.99 33.9295V28.9295ZM153.49 34.0095C153.49 34.6995 153.79 35.0095 154.27 35.0095C154.75 35.0095 155.05 34.7495 155.05 34.0095V28.8295C155.05 28.1495 154.75 27.8895 154.27 27.8895C153.79 27.8895 153.49 28.1495 153.49 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M157.41 28.9297C157.41 28.3223 157.651 27.7398 158.081 27.3104C158.51 26.8809 159.092 26.6396 159.7 26.6396C160.307 26.6396 160.89 26.8809 161.319 27.3104C161.749 27.7398 161.99 28.3223 161.99 28.9297V33.9297C161.99 34.537 161.749 35.1195 161.319 35.5489C160.89 35.9784 160.307 36.2197 159.7 36.2197C159.092 36.2197 158.51 35.9784 158.081 35.5489C157.651 35.1195 157.41 34.537 157.41 33.9297V28.9297ZM158.92 34.0097C158.92 34.6997 159.22 35.0097 159.7 35.0097C160.18 35.0097 160.48 34.7497 160.48 34.0097V28.8297C160.48 28.1497 160.18 27.8896 159.7 27.8896C159.22 27.8896 158.92 28.1497 158.92 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M162.84 28.9295C162.819 28.6168 162.863 28.303 162.969 28.0078C163.074 27.7126 163.239 27.4423 163.453 27.2134C163.667 26.9845 163.926 26.8021 164.214 26.6774C164.502 26.5526 164.812 26.4883 165.125 26.4883C165.439 26.4883 165.749 26.5526 166.036 26.6774C166.324 26.8021 166.583 26.9845 166.797 27.2134C167.011 27.4423 167.176 27.7126 167.282 28.0078C167.387 28.303 167.431 28.6168 167.41 28.9295V33.9295C167.431 34.2423 167.387 34.556 167.282 34.8512C167.176 35.1464 167.011 35.4168 166.797 35.6457C166.583 35.8745 166.324 36.057 166.036 36.1817C165.749 36.3064 165.439 36.3708 165.125 36.3708C164.812 36.3708 164.502 36.3064 164.214 36.1817C163.926 36.057 163.667 35.8745 163.453 35.6457C163.239 35.4168 163.074 35.1464 162.969 34.8512C162.863 34.556 162.819 34.2423 162.84 33.9295V28.9295ZM164.34 34.0095C164.34 34.6995 164.65 35.0095 165.13 35.0095C165.61 35.0095 165.91 34.7495 165.91 34.0095V28.8295C165.91 28.1495 165.6 27.8895 165.13 27.8895C164.66 27.8895 164.34 28.1495 164.34 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M168.26 28.9297C168.26 28.3223 168.501 27.7398 168.931 27.3104C169.36 26.8809 169.943 26.6396 170.55 26.6396C171.157 26.6396 171.74 26.8809 172.169 27.3104C172.599 27.7398 172.84 28.3223 172.84 28.9297V33.9297C172.84 34.537 172.599 35.1195 172.169 35.5489C171.74 35.9784 171.157 36.2197 170.55 36.2197C169.943 36.2197 169.36 35.9784 168.931 35.5489C168.501 35.1195 168.26 34.537 168.26 33.9297V28.9297ZM169.77 34.0097C169.77 34.6997 170.07 35.0097 170.55 35.0097C171.03 35.0097 171.33 34.7497 171.33 34.0097V28.8297C171.33 28.1497 171.03 27.8896 170.55 27.8896C170.07 27.8896 169.77 28.1497 169.77 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M173.69 28.9295C173.67 28.6168 173.713 28.303 173.819 28.0078C173.924 27.7126 174.089 27.4423 174.303 27.2134C174.518 26.9845 174.776 26.8021 175.064 26.6774C175.352 26.5526 175.662 26.4883 175.975 26.4883C176.289 26.4883 176.599 26.5526 176.886 26.6774C177.174 26.8021 177.433 26.9845 177.647 27.2134C177.861 27.4423 178.026 27.7126 178.132 28.0078C178.237 28.303 178.281 28.6168 178.26 28.9295V33.9295C178.281 34.2423 178.237 34.556 178.132 34.8512C178.026 35.1464 177.861 35.4168 177.647 35.6457C177.433 35.8745 177.174 36.057 176.886 36.1817C176.599 36.3064 176.289 36.3708 175.975 36.3708C175.662 36.3708 175.352 36.3064 175.064 36.1817C174.776 36.057 174.518 35.8745 174.303 35.6457C174.089 35.4168 173.924 35.1464 173.819 34.8512C173.713 34.556 173.67 34.2423 173.69 33.9295V28.9295ZM175.2 34.0095C175.2 34.6995 175.5 35.0095 175.98 35.0095C176.46 35.0095 176.76 34.7495 176.76 34.0095V28.8295C176.76 28.1495 176.46 27.8895 175.98 27.8895C175.5 27.8895 175.2 28.1495 175.2 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M182.29 27.8299C182.668 27.88 183.05 27.7835 183.359 27.5603C183.668 27.3372 183.879 27.0042 183.95 26.6299H184.95V36.2199H183.44V28.8999H182.29V27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M187.72 27.8299C188.097 27.8775 188.479 27.7801 188.787 27.5573C189.095 27.3346 189.307 27.0031 189.38 26.6299H190.38V36.2199H188.87V28.8999H187.71L187.72 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M193.15 27.8299C193.526 27.8797 193.908 27.783 194.215 27.5596C194.522 27.3363 194.731 27.0034 194.8 26.6299H195.8V36.2199H194.29V28.8999H193.13L193.15 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M198.59 27.8299C198.968 27.88 199.35 27.7835 199.659 27.5603C199.968 27.3372 200.179 27.0042 200.25 26.6299H201.25V36.2199H199.75V28.8999H198.59V27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M204 27.8299C204.377 27.8775 204.758 27.7801 205.067 27.5573C205.375 27.3346 205.587 27.0031 205.66 26.6299H206.66V36.2199H205.15V28.8999H203.99L204 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M212.59 28.9295C212.569 28.6168 212.613 28.303 212.719 28.0078C212.824 27.7126 212.989 27.4423 213.203 27.2134C213.417 26.9845 213.676 26.8021 213.964 26.6774C214.252 26.5526 214.562 26.4883 214.875 26.4883C215.189 26.4883 215.499 26.5526 215.786 26.6774C216.074 26.8021 216.333 26.9845 216.547 27.2134C216.761 27.4423 216.926 27.7126 217.032 28.0078C217.137 28.303 217.181 28.6168 217.16 28.9295V33.9295C217.181 34.2423 217.137 34.556 217.032 34.8512C216.926 35.1464 216.761 35.4168 216.547 35.6457C216.333 35.8745 216.074 36.057 215.786 36.1817C215.499 36.3064 215.189 36.3708 214.875 36.3708C214.562 36.3708 214.252 36.3064 213.964 36.1817C213.676 36.057 213.417 35.8745 213.203 35.6457C212.989 35.4168 212.824 35.1464 212.719 34.8512C212.613 34.556 212.569 34.2423 212.59 33.9295V28.9295ZM214.09 34.0095C214.09 34.6995 214.39 35.0095 214.87 35.0095C215.35 35.0095 215.65 34.7495 215.65 34.0095V28.8295C215.65 28.1495 215.35 27.8895 214.87 27.8895C214.39 27.8895 214.09 28.1495 214.09 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M218.04 28.9297C218.04 28.3223 218.281 27.7398 218.711 27.3104C219.14 26.8809 219.723 26.6396 220.33 26.6396C220.938 26.6396 221.52 26.8809 221.949 27.3104C222.379 27.7398 222.62 28.3223 222.62 28.9297V33.9297C222.62 34.537 222.379 35.1195 221.949 35.5489C221.52 35.9784 220.938 36.2197 220.33 36.2197C219.723 36.2197 219.14 35.9784 218.711 35.5489C218.281 35.1195 218.04 34.537 218.04 33.9297V28.9297ZM219.55 34.0097C219.55 34.6997 219.85 35.0097 220.33 35.0097C220.81 35.0097 221.11 34.7497 221.11 34.0097V28.8297C221.11 28.1497 220.81 27.8896 220.33 27.8896C219.85 27.8896 219.55 28.1497 219.55 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M223.47 28.9295C223.449 28.6168 223.493 28.303 223.598 28.0078C223.704 27.7126 223.869 27.4423 224.083 27.2134C224.297 26.9845 224.556 26.8021 224.844 26.6774C225.131 26.5526 225.442 26.4883 225.755 26.4883C226.068 26.4883 226.379 26.5526 226.666 26.6774C226.954 26.8021 227.213 26.9845 227.427 27.2134C227.641 27.4423 227.806 27.7126 227.911 28.0078C228.017 28.303 228.061 28.6168 228.04 28.9295V33.9295C228.061 34.2423 228.017 34.556 227.911 34.8512C227.806 35.1464 227.641 35.4168 227.427 35.6457C227.213 35.8745 226.954 36.057 226.666 36.1817C226.379 36.3064 226.068 36.3708 225.755 36.3708C225.442 36.3708 225.131 36.3064 224.844 36.1817C224.556 36.057 224.297 35.8745 224.083 35.6457C223.869 35.4168 223.704 35.1464 223.598 34.8512C223.493 34.556 223.449 34.2423 223.47 33.9295V28.9295ZM224.97 34.0095C224.97 34.6995 225.28 35.0095 225.76 35.0095C226.24 35.0095 226.54 34.7495 226.54 34.0095V28.8295C226.54 28.1495 226.23 27.8895 225.76 27.8895C225.29 27.8895 224.97 28.1495 224.97 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M228.89 28.9297C228.89 28.3223 229.131 27.7398 229.561 27.3104C229.99 26.8809 230.572 26.6396 231.18 26.6396C231.787 26.6396 232.37 26.8809 232.799 27.3104C233.229 27.7398 233.47 28.3223 233.47 28.9297V33.9297C233.47 34.537 233.229 35.1195 232.799 35.5489C232.37 35.9784 231.787 36.2197 231.18 36.2197C230.572 36.2197 229.99 35.9784 229.561 35.5489C229.131 35.1195 228.89 34.537 228.89 33.9297V28.9297ZM230.4 34.0097C230.4 34.6997 230.7 35.0097 231.18 35.0097C231.66 35.0097 231.96 34.7497 231.96 34.0097V28.8297C231.96 28.1497 231.66 27.8896 231.18 27.8896C230.7 27.8896 230.4 28.1497 230.4 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M234.32 28.9297C234.32 28.6289 234.379 28.3311 234.494 28.0533C234.609 27.7755 234.778 27.523 234.991 27.3104C235.203 27.0977 235.456 26.929 235.734 26.814C236.011 26.6989 236.309 26.6396 236.61 26.6396C236.911 26.6396 237.208 26.6989 237.486 26.814C237.764 26.929 238.017 27.0977 238.229 27.3104C238.442 27.523 238.611 27.7755 238.726 28.0533C238.841 28.3311 238.9 28.6289 238.9 28.9297V33.9297C238.9 34.2304 238.841 34.5282 238.726 34.806C238.611 35.0838 238.442 35.3363 238.229 35.5489C238.017 35.7616 237.764 35.9303 237.486 36.0453C237.208 36.1604 236.911 36.2197 236.61 36.2197C236.309 36.2197 236.011 36.1604 235.734 36.0453C235.456 35.9303 235.203 35.7616 234.991 35.5489C234.778 35.3363 234.609 35.0838 234.494 34.806C234.379 34.5282 234.32 34.2304 234.32 33.9297V28.9297ZM235.83 34.0097C235.83 34.6997 236.13 35.0097 236.61 35.0097C237.09 35.0097 237.39 34.7497 237.39 34.0097V28.8297C237.39 28.1497 237.09 27.8896 236.61 27.8896C236.13 27.8896 235.83 28.1497 235.83 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M239.75 28.9295C239.729 28.6168 239.773 28.303 239.878 28.0078C239.984 27.7126 240.149 27.4423 240.363 27.2134C240.577 26.9845 240.836 26.8021 241.124 26.6774C241.411 26.5526 241.721 26.4883 242.035 26.4883C242.348 26.4883 242.658 26.5526 242.946 26.6774C243.234 26.8021 243.492 26.9845 243.707 27.2134C243.921 27.4423 244.086 27.7126 244.191 28.0078C244.297 28.303 244.34 28.6168 244.32 28.9295V33.9295C244.34 34.2423 244.297 34.556 244.191 34.8512C244.086 35.1464 243.921 35.4168 243.707 35.6457C243.492 35.8745 243.234 36.057 242.946 36.1817C242.658 36.3064 242.348 36.3708 242.035 36.3708C241.721 36.3708 241.411 36.3064 241.124 36.1817C240.836 36.057 240.577 35.8745 240.363 35.6457C240.149 35.4168 239.984 35.1464 239.878 34.8512C239.773 34.556 239.729 34.2423 239.75 33.9295V28.9295ZM241.25 34.0095C241.25 34.6995 241.55 35.0095 242.03 35.0095C242.51 35.0095 242.81 34.7495 242.81 34.0095V28.8295C242.81 28.1495 242.51 27.8895 242.03 27.8895C241.55 27.8895 241.25 28.1495 241.25 28.8295V34.0095Z"
        fill="#1B2236"
      />
      <path
        d="M249.42 28.9297C249.42 28.3223 249.661 27.7398 250.091 27.3104C250.52 26.8809 251.103 26.6396 251.71 26.6396C252.317 26.6396 252.9 26.8809 253.329 27.3104C253.759 27.7398 254 28.3223 254 28.9297V33.9297C254 34.537 253.759 35.1195 253.329 35.5489C252.9 35.9784 252.317 36.2197 251.71 36.2197C251.103 36.2197 250.52 35.9784 250.091 35.5489C249.661 35.1195 249.42 34.537 249.42 33.9297V28.9297ZM250.93 34.0097C250.93 34.6997 251.23 35.0097 251.71 35.0097C252.19 35.0097 252.49 34.7497 252.49 34.0097V28.8297C252.49 28.1497 252.19 27.8896 251.71 27.8896C251.23 27.8896 250.93 28.1497 250.93 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M255.9 27.8299C256.277 27.8775 256.658 27.7801 256.967 27.5573C257.275 27.3346 257.487 27.0031 257.56 26.6299H258.56V36.2199H257.05V28.8999H255.89L255.9 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M260.27 28.9297C260.27 28.3223 260.511 27.7398 260.941 27.3104C261.37 26.8809 261.953 26.6396 262.56 26.6396C263.168 26.6396 263.75 26.8809 264.179 27.3104C264.609 27.7398 264.85 28.3223 264.85 28.9297V33.9297C264.85 34.537 264.609 35.1195 264.179 35.5489C263.75 35.9784 263.168 36.2197 262.56 36.2197C261.953 36.2197 261.37 35.9784 260.941 35.5489C260.511 35.1195 260.27 34.537 260.27 33.9297V28.9297ZM261.78 34.0097C261.78 34.6997 262.08 35.0097 262.56 35.0097C263.04 35.0097 263.34 34.7497 263.34 34.0097V28.8297C263.34 28.1497 263.04 27.8896 262.56 27.8896C262.08 27.8896 261.78 28.1497 261.78 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M266.75 27.8299C267.128 27.88 267.51 27.7835 267.819 27.5603C268.128 27.3372 268.339 27.0042 268.41 26.6299H269.41V36.2199H267.91V28.8999H266.74L266.75 27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M271.12 28.9297C271.12 28.3223 271.361 27.7398 271.79 27.3104C272.22 26.8809 272.802 26.6396 273.41 26.6396C274.017 26.6396 274.6 26.8809 275.029 27.3104C275.459 27.7398 275.7 28.3223 275.7 28.9297V33.9297C275.7 34.537 275.459 35.1195 275.029 35.5489C274.6 35.9784 274.017 36.2197 273.41 36.2197C272.802 36.2197 272.22 35.9784 271.79 35.5489C271.361 35.1195 271.12 34.537 271.12 33.9297V28.9297ZM272.63 34.0097C272.63 34.6997 272.93 35.0097 273.41 35.0097C273.89 35.0097 274.19 34.7497 274.19 34.0097V28.8297C274.19 28.1497 273.89 27.8896 273.41 27.8896C272.93 27.8896 272.63 28.1497 272.63 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M277.59 27.8299C277.968 27.88 278.35 27.7835 278.659 27.5603C278.968 27.3372 279.179 27.0042 279.25 26.6299H280.25V36.2199H278.75V28.8999H277.59V27.8299Z"
        fill="#1B2236"
      />
      <path
        d="M281.97 28.9297C281.97 28.3223 282.211 27.7398 282.641 27.3104C283.07 26.8809 283.653 26.6396 284.26 26.6396C284.867 26.6396 285.45 26.8809 285.879 27.3104C286.309 27.7398 286.55 28.3223 286.55 28.9297V33.9297C286.55 34.537 286.309 35.1195 285.879 35.5489C285.45 35.9784 284.867 36.2197 284.26 36.2197C283.653 36.2197 283.07 35.9784 282.641 35.5489C282.211 35.1195 281.97 34.537 281.97 33.9297V28.9297ZM283.48 34.0097C283.48 34.6997 283.78 35.0097 284.26 35.0097C284.74 35.0097 285.04 34.7497 285.04 34.0097V28.8297C285.04 28.1497 284.74 27.8896 284.26 27.8896C283.78 27.8896 283.48 28.1497 283.48 28.8297V34.0097Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 45.3701C24.1198 44.7627 24.3611 44.1803 24.7905 43.7508C25.22 43.3214 25.8024 43.0801 26.4098 43.0801C27.0171 43.0801 27.5996 43.3214 28.0291 43.7508C28.4585 44.1803 28.6998 44.7627 28.6998 45.3701V50.3701C28.6998 50.9774 28.4585 51.5599 28.0291 51.9893C27.5996 52.4188 27.0171 52.6601 26.4098 52.6601C25.8024 52.6601 25.22 52.4188 24.7905 51.9893C24.3611 51.5599 24.1198 50.9774 24.1198 50.3701V45.3701ZM25.6298 50.4501C25.6298 51.1401 25.9298 51.4501 26.4098 51.4501C26.8898 51.4501 27.1898 51.1901 27.1898 50.4501V45.2801C27.1898 44.5901 26.8898 44.2801 26.4098 44.2801C25.9298 44.2801 25.6298 44.5401 25.6298 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M30.59 44.2803C30.9687 44.3276 31.3512 44.2289 31.6596 44.004C31.968 43.7792 32.1792 43.4453 32.25 43.0703H33.25V52.6903H31.75V45.3703H30.59V44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 45.3701C34.9699 44.7627 35.2111 44.1803 35.6406 43.7508C36.0701 43.3214 36.6525 43.0801 37.2599 43.0801C37.8672 43.0801 38.4497 43.3214 38.8792 43.7508C39.3086 44.1803 39.5499 44.7627 39.5499 45.3701V50.3701C39.5499 50.9774 39.3086 51.5599 38.8792 51.9893C38.4497 52.4188 37.8672 52.6601 37.2599 52.6601C36.6525 52.6601 36.0701 52.4188 35.6406 51.9893C35.2111 51.5599 34.9699 50.9774 34.9699 50.3701V45.3701ZM36.4799 50.4501C36.4799 51.1401 36.7799 51.4501 37.2599 51.4501C37.7399 51.4501 38.0399 51.1901 38.0399 50.4501V45.2801C38.0399 44.5901 37.7399 44.2801 37.2599 44.2801C36.7799 44.2801 36.4799 44.5401 36.4799 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 44.2803C41.8288 44.3276 42.2113 44.2289 42.5197 44.004C42.8281 43.7792 43.0392 43.4453 43.1101 43.0703H44.1101V52.6903H42.5901V45.3703H41.4201L41.4501 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M45.8299 45.3699C45.8092 45.0572 45.8529 44.7435 45.9583 44.4483C46.0638 44.1531 46.2287 43.8827 46.443 43.6538C46.6572 43.425 46.9161 43.2425 47.2037 43.1178C47.4913 42.9931 47.8014 42.9287 48.1148 42.9287C48.4283 42.9287 48.7385 42.9931 49.026 43.1178C49.3136 43.2425 49.5725 43.425 49.7868 43.6538C50.001 43.8827 50.1659 44.1531 50.2714 44.4483C50.3768 44.7435 50.4206 45.0572 50.3999 45.3699V50.3699C50.4206 50.6827 50.3768 50.9964 50.2714 51.2916C50.1659 51.5868 50.001 51.8572 49.7868 52.0861C49.5725 52.3149 49.3136 52.4974 49.026 52.6221C48.7385 52.7468 48.4283 52.8112 48.1148 52.8112C47.8014 52.8112 47.4913 52.7468 47.2037 52.6221C46.9161 52.4974 46.6572 52.3149 46.443 52.0861C46.2287 51.8572 46.0638 51.5868 45.9583 51.2916C45.8529 50.9964 45.8092 50.6827 45.8299 50.3699V45.3699ZM47.3299 50.45C47.3299 51.14 47.6299 51.45 48.1099 51.45C48.5899 51.45 48.8899 51.19 48.8899 50.45V45.28C48.8899 44.59 48.5899 44.28 48.1099 44.28C47.6299 44.28 47.3299 44.54 47.3299 45.28V50.45Z"
        fill="#1B2236"
      />
      <path
        d="M52.3102 44.2803C52.6877 44.3274 53.0689 44.2283 53.3757 44.0033C53.6825 43.7783 53.8916 43.4445 53.9602 43.0703H54.9602V52.6903H53.4502V45.3703H52.2902L52.3102 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M57.7299 44.2803C58.1086 44.3276 58.4911 44.2289 58.7995 44.004C59.1079 43.7792 59.319 43.4453 59.3899 43.0703H60.3899V52.6903H58.8899V45.3703H57.7199L57.7299 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M63.1601 44.2803C63.5384 44.3252 63.9197 44.2255 64.2275 44.0011C64.5354 43.7767 64.747 43.4442 64.8201 43.0703H65.8201V52.6903H64.3101V45.3703H63.1501L63.1601 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M68.59 44.2803C68.9687 44.3276 69.3512 44.2289 69.6596 44.004C69.968 43.7792 70.1792 43.4453 70.25 43.0703H71.25V52.6903H69.74V45.3703H68.59V44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M74.0101 44.2803C74.3885 44.3252 74.7698 44.2255 75.0776 44.0011C75.3855 43.7767 75.5971 43.4442 75.6702 43.0703H76.6702V52.6903H75.1602V45.3703H74.0002L74.0101 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M79.4398 44.2803C79.8174 44.3274 80.1986 44.2283 80.5054 44.0033C80.8122 43.7783 81.0213 43.4445 81.0898 43.0703H82.0898V52.6903H80.5898V45.3703H79.4298L79.4398 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M84.8598 44.2803C85.2385 44.3276 85.621 44.2289 85.9294 44.004C86.2378 43.7792 86.4489 43.4453 86.5198 43.0703H87.5198V52.6903H86.0198V45.3703H84.8498L84.8598 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M89.2301 45.3701C89.2301 44.7627 89.4714 44.1803 89.9009 43.7508C90.3303 43.3214 90.9128 43.0801 91.5201 43.0801C92.1275 43.0801 92.7099 43.3214 93.1394 43.7508C93.5689 44.1803 93.8101 44.7627 93.8101 45.3701V50.3701C93.8101 50.9774 93.5689 51.5599 93.1394 51.9893C92.7099 52.4188 92.1275 52.6601 91.5201 52.6601C90.9128 52.6601 90.3303 52.4188 89.9009 51.9893C89.4714 51.5599 89.2301 50.9774 89.2301 50.3701V45.3701ZM90.7401 50.4501C90.7401 51.1401 91.0401 51.4501 91.5201 51.4501C92.0001 51.4501 92.3001 51.1901 92.3001 50.4501V45.2801C92.3001 44.5901 92.0001 44.2801 91.5201 44.2801C91.0401 44.2801 90.7401 44.5401 90.7401 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M95.7099 44.2803C96.0886 44.3276 96.4711 44.2289 96.7795 44.004C97.0879 43.7792 97.299 43.4453 97.3699 43.0703H98.3699V52.6903H96.8699V45.3703H95.6999L95.7099 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M103.26 44.2803C103.639 44.3276 104.021 44.2289 104.33 44.004C104.638 43.7792 104.849 43.4453 104.92 43.0703H105.92V52.6903H104.42V45.3703H103.25L103.26 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M107.59 45.3701C107.59 44.7627 107.831 44.1803 108.261 43.7508C108.69 43.3214 109.273 43.0801 109.88 43.0801C110.487 43.0801 111.07 43.3214 111.499 43.7508C111.929 44.1803 112.17 44.7627 112.17 45.3701V50.3701C112.17 50.9774 111.929 51.5599 111.499 51.9893C111.07 52.4188 110.487 52.6601 109.88 52.6601C109.273 52.6601 108.69 52.4188 108.261 51.9893C107.831 51.5599 107.59 50.9774 107.59 50.3701V45.3701ZM109.1 50.4501C109.1 51.1401 109.4 51.4501 109.88 51.4501C110.36 51.4501 110.66 51.1901 110.66 50.4501V45.2801C110.66 44.5901 110.36 44.2801 109.88 44.2801C109.4 44.2801 109.1 44.5401 109.1 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M114.11 44.2803C114.489 44.3276 114.871 44.2289 115.18 44.004C115.488 43.7792 115.699 43.4453 115.77 43.0703H116.77V52.6903H115.26V45.3703H114.09L114.11 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M121.66 44.2803C122.039 44.3276 122.421 44.2289 122.729 44.004C123.038 43.7792 123.249 43.4453 123.32 43.0703H124.32V52.6903H122.81V45.3703H121.64L121.66 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M128.16 45.3699C128.139 45.0572 128.183 44.7435 128.288 44.4483C128.394 44.1531 128.559 43.8827 128.773 43.6538C128.987 43.425 129.246 43.2425 129.534 43.1178C129.821 42.9931 130.131 42.9287 130.445 42.9287C130.758 42.9287 131.069 42.9931 131.356 43.1178C131.644 43.2425 131.903 43.425 132.117 43.6538C132.331 43.8827 132.496 44.1531 132.601 44.4483C132.707 44.7435 132.751 45.0572 132.73 45.3699V50.3699C132.751 50.6827 132.707 50.9964 132.601 51.2916C132.496 51.5868 132.331 51.8572 132.117 52.0861C131.903 52.3149 131.644 52.4974 131.356 52.6221C131.069 52.7468 130.758 52.8112 130.445 52.8112C130.131 52.8112 129.821 52.7468 129.534 52.6221C129.246 52.4974 128.987 52.3149 128.773 52.0861C128.559 51.8572 128.394 51.5868 128.288 51.2916C128.183 50.9964 128.139 50.6827 128.16 50.3699V45.3699ZM129.67 50.45C129.67 51.14 129.97 51.45 130.45 51.45C130.93 51.45 131.23 51.19 131.23 50.45V45.28C131.23 44.59 130.93 44.28 130.45 44.28C129.97 44.28 129.67 44.54 129.67 45.28V50.45Z"
        fill="#1B2236"
      />
      <path
        d="M134.64 44.2803C135.018 44.3252 135.4 44.2255 135.707 44.0011C136.015 43.7767 136.227 43.4442 136.3 43.0703H137.3V52.6903H135.79V45.3703H134.63L134.64 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M140.07 44.2803C140.447 44.3274 140.829 44.2283 141.135 44.0033C141.442 43.7783 141.651 43.4445 141.72 43.0703H142.72V52.6903H141.21V45.3703H140.05L140.07 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M145.49 44.2803C145.869 44.3276 146.251 44.2289 146.56 44.004C146.868 43.7792 147.079 43.4453 147.15 43.0703H148.15V52.6903H146.65V45.3703H145.49V44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M150.92 44.2803C151.298 44.3274 151.679 44.2283 151.986 44.0033C152.292 43.7783 152.502 43.4445 152.57 43.0703H153.57V52.6903H152.06V45.3703H150.9L150.92 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M156.34 44.2803C156.719 44.3276 157.101 44.2289 157.41 44.004C157.718 43.7792 157.929 43.4453 158 43.0703H159V52.6903H157.5V45.3703H156.33L156.34 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M160.71 45.3701C160.71 45.0693 160.769 44.7716 160.884 44.4937C161 44.2159 161.168 43.9635 161.381 43.7508C161.593 43.5382 161.846 43.3695 162.124 43.2544C162.402 43.1393 162.699 43.0801 163 43.0801C163.301 43.0801 163.599 43.1393 163.876 43.2544C164.154 43.3695 164.407 43.5382 164.619 43.7508C164.832 43.9635 165.001 44.2159 165.116 44.4937C165.231 44.7716 165.29 45.0693 165.29 45.3701V50.3701C165.29 50.6708 165.231 50.9686 165.116 51.2464C165.001 51.5243 164.832 51.7767 164.619 51.9893C164.407 52.202 164.154 52.3707 163.876 52.4858C163.599 52.6008 163.301 52.6601 163 52.6601C162.699 52.6601 162.402 52.6008 162.124 52.4858C161.846 52.3707 161.593 52.202 161.381 51.9893C161.168 51.7767 161 51.5243 160.884 51.2464C160.769 50.9686 160.71 50.6708 160.71 50.3701V45.3701ZM162.22 50.4501C162.22 51.1401 162.52 51.4501 163 51.4501C163.48 51.4501 163.78 51.1901 163.78 50.4501V45.2801C163.78 44.5901 163.48 44.2801 163 44.2801C162.52 44.2801 162.22 44.5401 162.22 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M167.19 44.2803C167.569 44.3276 167.951 44.2289 168.26 44.004C168.568 43.7792 168.779 43.4453 168.85 43.0703H169.85V52.6903H168.34V45.3703H167.19V44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M171.59 45.3699C171.569 45.0572 171.613 44.7435 171.719 44.4483C171.824 44.1531 171.989 43.8827 172.203 43.6538C172.417 43.425 172.676 43.2425 172.964 43.1178C173.252 42.9931 173.562 42.9287 173.875 42.9287C174.189 42.9287 174.499 42.9931 174.786 43.1178C175.074 43.2425 175.333 43.425 175.547 43.6538C175.761 43.8827 175.926 44.1531 176.032 44.4483C176.137 44.7435 176.181 45.0572 176.16 45.3699V50.3699C176.181 50.6827 176.137 50.9964 176.032 51.2916C175.926 51.5868 175.761 51.8572 175.547 52.0861C175.333 52.3149 175.074 52.4974 174.786 52.6221C174.499 52.7468 174.189 52.8112 173.875 52.8112C173.562 52.8112 173.252 52.7468 172.964 52.6221C172.676 52.4974 172.417 52.3149 172.203 52.0861C171.989 51.8572 171.824 51.5868 171.719 51.2916C171.613 50.9964 171.569 50.6827 171.59 50.3699V45.3699ZM173.09 50.45C173.09 51.14 173.39 51.45 173.87 51.45C174.35 51.45 174.65 51.19 174.65 50.45V45.28C174.65 44.59 174.35 44.28 173.87 44.28C173.39 44.28 173.09 44.54 173.09 45.28V50.45Z"
        fill="#1B2236"
      />
      <path
        d="M178.05 44.2803C178.427 44.3274 178.809 44.2283 179.115 44.0033C179.422 43.7783 179.631 43.4445 179.7 43.0703H180.7V52.6903H179.19V45.3703H178.03L178.05 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M182.42 45.3699C182.399 45.0572 182.443 44.7435 182.549 44.4483C182.654 44.1531 182.819 43.8827 183.033 43.6538C183.248 43.425 183.506 43.2425 183.794 43.1178C184.082 42.9931 184.392 42.9287 184.705 42.9287C185.019 42.9287 185.329 42.9931 185.616 43.1178C185.904 43.2425 186.163 43.425 186.377 43.6538C186.591 43.8827 186.756 44.1531 186.862 44.4483C186.967 44.7435 187.011 45.0572 186.99 45.3699V50.3699C187.011 50.6827 186.967 50.9964 186.862 51.2916C186.756 51.5868 186.591 51.8572 186.377 52.0861C186.163 52.3149 185.904 52.4974 185.616 52.6221C185.329 52.7468 185.019 52.8112 184.705 52.8112C184.392 52.8112 184.082 52.7468 183.794 52.6221C183.506 52.4974 183.248 52.3149 183.033 52.0861C182.819 51.8572 182.654 51.5868 182.549 51.2916C182.443 50.9964 182.399 50.6827 182.42 50.3699V45.3699ZM183.92 50.45C183.92 51.14 184.23 51.45 184.71 51.45C185.19 51.45 185.49 51.19 185.49 50.45V45.28C185.49 44.59 185.18 44.28 184.71 44.28C184.24 44.28 183.92 44.54 183.92 45.28V50.45Z"
        fill="#1B2236"
      />
      <path
        d="M188.9 44.2803C189.278 44.3252 189.659 44.2255 189.967 44.0011C190.275 43.7767 190.487 43.4442 190.56 43.0703H191.56V52.6903H190.05V45.3703H188.89L188.9 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M199.64 45.3701C199.64 44.7627 199.881 44.1803 200.311 43.7508C200.74 43.3214 201.322 43.0801 201.93 43.0801C202.537 43.0801 203.12 43.3214 203.549 43.7508C203.979 44.1803 204.22 44.7627 204.22 45.3701V50.3701C204.22 50.9774 203.979 51.5599 203.549 51.9893C203.12 52.4188 202.537 52.6601 201.93 52.6601C201.322 52.6601 200.74 52.4188 200.311 51.9893C199.881 51.5599 199.64 50.9774 199.64 50.3701V45.3701ZM201.15 50.4501C201.15 51.1401 201.45 51.4501 201.93 51.4501C202.41 51.4501 202.71 51.1901 202.71 50.4501V45.2801C202.71 44.5901 202.41 44.2801 201.93 44.2801C201.45 44.2801 201.15 44.5401 201.15 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M206.12 44.2803C206.499 44.3276 206.881 44.2289 207.19 44.004C207.498 43.7792 207.709 43.4453 207.78 43.0703H208.78V52.6903H207.28V45.3703H206.11L206.12 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M210.49 45.3701C210.49 44.7627 210.731 44.1803 211.161 43.7508C211.59 43.3214 212.173 43.0801 212.78 43.0801C213.387 43.0801 213.97 43.3214 214.399 43.7508C214.829 44.1803 215.07 44.7627 215.07 45.3701V50.3701C215.07 50.9774 214.829 51.5599 214.399 51.9893C213.97 52.4188 213.387 52.6601 212.78 52.6601C212.173 52.6601 211.59 52.4188 211.161 51.9893C210.731 51.5599 210.49 50.9774 210.49 50.3701V45.3701ZM212 50.4501C212 51.1401 212.3 51.4501 212.78 51.4501C213.26 51.4501 213.56 51.1901 213.56 50.4501V45.2801C213.56 44.5901 213.26 44.2801 212.78 44.2801C212.3 44.2801 212 44.5401 212 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M216.97 44.2803C217.349 44.3276 217.731 44.2289 218.04 44.004C218.348 43.7792 218.559 43.4453 218.63 43.0703H219.63V52.6903H218.13V45.3703H216.96L216.97 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M222.4 44.2803C222.778 44.3252 223.16 44.2255 223.467 44.0011C223.775 43.7767 223.987 43.4442 224.06 43.0703H225.06V52.6903H223.59V45.3703H222.43L222.4 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M227.82 44.2803C228.199 44.3276 228.581 44.2289 228.89 44.004C229.198 43.7792 229.409 43.4453 229.48 43.0703H230.48V52.6903H228.99V45.3703H227.82V44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M232.2 45.3699C232.179 45.0572 232.223 44.7435 232.328 44.4483C232.434 44.1531 232.599 43.8827 232.813 43.6538C233.027 43.425 233.286 43.2425 233.574 43.1178C233.861 42.9931 234.172 42.9287 234.485 42.9287C234.798 42.9287 235.109 42.9931 235.396 43.1178C235.684 43.2425 235.943 43.425 236.157 43.6538C236.371 43.8827 236.536 44.1531 236.642 44.4483C236.747 44.7435 236.791 45.0572 236.77 45.3699V50.3699C236.791 50.6827 236.747 50.9964 236.642 51.2916C236.536 51.5868 236.371 51.8572 236.157 52.0861C235.943 52.3149 235.684 52.4974 235.396 52.6221C235.109 52.7468 234.798 52.8112 234.485 52.8112C234.172 52.8112 233.861 52.7468 233.574 52.6221C233.286 52.4974 233.027 52.3149 232.813 52.0861C232.599 51.8572 232.434 51.5868 232.328 51.2916C232.223 50.9964 232.179 50.6827 232.2 50.3699V45.3699ZM233.7 50.45C233.7 51.14 234 51.45 234.48 51.45C234.96 51.45 235.26 51.19 235.26 50.45V45.28C235.26 44.59 234.96 44.28 234.48 44.28C234 44.28 233.7 44.54 233.7 45.28V50.45Z"
        fill="#1B2236"
      />
      <path
        d="M238.68 44.2803C239.057 44.3274 239.439 44.2283 239.745 44.0033C240.052 43.7783 240.261 43.4445 240.33 43.0703H241.33V52.6903H239.82V45.3703H238.66L238.68 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M244.1 44.2803C244.479 44.3276 244.861 44.2289 245.17 44.004C245.478 43.7792 245.689 43.4453 245.76 43.0703H246.76V52.6903H245.25V45.3703H244.08L244.1 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M249.53 44.2803C249.908 44.3252 250.29 44.2255 250.597 44.0011C250.905 43.7767 251.117 43.4442 251.19 43.0703H252.19V52.6903H250.69V45.3703H249.53V44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M254.95 44.2803C255.329 44.3276 255.711 44.2289 256.019 44.004C256.328 43.7792 256.539 43.4453 256.61 43.0703H257.61V52.6903H256.1V45.3703H254.93L254.95 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M260.38 44.2803C260.758 44.3252 261.14 44.2255 261.447 44.0011C261.755 43.7767 261.967 43.4442 262.04 43.0703H263.04V52.6903H261.59V45.3703H260.43L260.38 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M265.81 44.2803C266.188 44.3274 266.569 44.2283 266.876 44.0033C267.182 43.7783 267.392 43.4445 267.46 43.0703H268.46V52.6903H266.95V45.3703H265.79L265.81 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M272.3 45.3701C272.3 44.7627 272.541 44.1803 272.971 43.7508C273.4 43.3214 273.983 43.0801 274.59 43.0801C275.197 43.0801 275.78 43.3214 276.209 43.7508C276.639 44.1803 276.88 44.7627 276.88 45.3701V50.3701C276.88 50.9774 276.639 51.5599 276.209 51.9893C275.78 52.4188 275.197 52.6601 274.59 52.6601C273.983 52.6601 273.4 52.4188 272.971 51.9893C272.541 51.5599 272.3 50.9774 272.3 50.3701V45.3701ZM273.81 50.4501C273.81 51.1401 274.11 51.4501 274.59 51.4501C275.07 51.4501 275.37 51.1901 275.37 50.4501V45.2801C275.37 44.5901 275.07 44.2801 274.59 44.2801C274.11 44.2801 273.81 44.5401 273.81 45.2801V50.4501Z"
        fill="#1B2236"
      />
      <path
        d="M278.78 44.2803C279.159 44.3276 279.541 44.2289 279.85 44.004C280.158 43.7792 280.369 43.4453 280.44 43.0703H281.44V52.6903H279.94V45.3703H278.77L278.78 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M284.21 44.2803C284.588 44.3274 284.969 44.2283 285.276 44.0033C285.582 43.7783 285.792 43.4445 285.86 43.0703H286.86V52.6903H285.35V45.3703H284.19L284.21 44.2803Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 62.2597C24.1198 61.6524 24.3611 61.0699 24.7905 60.6405C25.22 60.211 25.8024 59.9697 26.4098 59.9697C27.0171 59.9697 27.5996 60.211 28.0291 60.6405C28.4585 61.0699 28.6998 61.6524 28.6998 62.2597V67.2597C28.6998 67.8671 28.4585 68.4495 28.0291 68.879C27.5996 69.3085 27.0171 69.5497 26.4098 69.5497C25.8024 69.5497 25.22 69.3085 24.7905 68.879C24.3611 68.4495 24.1198 67.8671 24.1198 67.2597V62.2597ZM25.6298 67.3397C25.6298 68.0297 25.9298 68.2897 26.4098 68.2897C26.8898 68.2897 27.1898 68.0297 27.1898 67.3397V62.1697C27.1898 61.4797 26.8898 61.2197 26.4098 61.2197C25.9298 61.2197 25.6298 61.4797 25.6298 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M29.5901 62.2596C29.5694 61.9468 29.6131 61.6331 29.7186 61.3379C29.8241 61.0427 29.989 60.7723 30.2032 60.5435C30.4174 60.3146 30.6764 60.1322 30.9639 60.0074C31.2515 59.8827 31.5616 59.8184 31.8751 59.8184C32.1886 59.8184 32.4987 59.8827 32.7863 60.0074C33.0739 60.1322 33.3328 60.3146 33.547 60.5435C33.7612 60.7723 33.9262 61.0427 34.0316 61.3379C34.1371 61.6331 34.1808 61.9468 34.1601 62.2596V67.2596C34.1808 67.5724 34.1371 67.8861 34.0316 68.1813C33.9262 68.4765 33.7612 68.7469 33.547 68.9757C33.3328 69.2046 33.0739 69.387 32.7863 69.5118C32.4987 69.6365 32.1886 69.7009 31.8751 69.7009C31.5616 69.7009 31.2515 69.6365 30.9639 69.5118C30.6764 69.387 30.4174 69.2046 30.2032 68.9757C29.989 68.7469 29.8241 68.4765 29.7186 68.1813C29.6131 67.8861 29.5694 67.5724 29.5901 67.2596V62.2596ZM31.0901 67.3396C31.0901 68.0296 31.4001 68.2896 31.8801 68.2896C32.3601 68.2896 32.6601 68.0296 32.6601 67.3396V62.1696C32.6601 61.4796 32.3501 61.2196 31.8801 61.2196C31.4101 61.2196 31.0901 61.4796 31.0901 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 62.2597C34.9699 61.6524 35.2111 61.0699 35.6406 60.6405C36.0701 60.211 36.6525 59.9697 37.2599 59.9697C37.8672 59.9697 38.4497 60.211 38.8792 60.6405C39.3086 61.0699 39.5499 61.6524 39.5499 62.2597V67.2597C39.5499 67.8671 39.3086 68.4495 38.8792 68.879C38.4497 69.3085 37.8672 69.5497 37.2599 69.5497C36.6525 69.5497 36.0701 69.3085 35.6406 68.879C35.2111 68.4495 34.9699 67.8671 34.9699 67.2597V62.2597ZM36.4799 67.3397C36.4799 68.0297 36.7799 68.2897 37.2599 68.2897C37.7399 68.2897 38.0399 68.0297 38.0399 67.3397V62.1697C38.0399 61.4797 37.7399 61.2197 37.2599 61.2197C36.7799 61.2197 36.4799 61.4797 36.4799 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 61.17C41.8288 61.2173 42.2113 61.1185 42.5197 60.8937C42.8281 60.6689 43.0392 60.335 43.1101 59.96H44.1101V69.55H42.5901V62.2299H41.4201L41.4501 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M49.0001 61.17C49.3788 61.2173 49.7613 61.1185 50.0698 60.8937C50.3782 60.6689 50.5893 60.335 50.6601 59.96H51.6601V69.55H50.1501V62.2299H48.9801L49.0001 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M54.4298 61.17C54.8081 61.2149 55.1894 61.1151 55.4973 60.8907C55.8052 60.6663 56.0168 60.3339 56.0898 59.96H57.0898V69.55H55.5898V62.2299H54.4298V61.17Z"
        fill="#1B2236"
      />
      <path
        d="M59.86 61.17C60.2375 61.217 60.6187 61.1179 60.9255 60.893C61.2323 60.668 61.4414 60.3342 61.51 59.96H62.51V69.55H61V62.2299H59.84L59.86 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M65.2802 61.17C65.6589 61.2173 66.0414 61.1185 66.3498 60.8937C66.6582 60.6689 66.8693 60.335 66.9402 59.96H67.9402V69.55H66.4402V62.2299H65.2702L65.2802 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M70.7099 61.17C71.0882 61.2149 71.4695 61.1151 71.7773 60.8907C72.0852 60.6663 72.2968 60.3339 72.3699 59.96H73.3699V69.55H71.8599V62.2299H70.6999L70.7099 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M77.1999 62.2597C77.1999 61.6524 77.4411 61.0699 77.8706 60.6405C78.3 60.211 78.8825 59.9697 79.4899 59.9697C80.0972 59.9697 80.6797 60.211 81.1091 60.6405C81.5386 61.0699 81.7799 61.6524 81.7799 62.2597V67.2597C81.7799 67.8671 81.5386 68.4495 81.1091 68.879C80.6797 69.3085 80.0972 69.5497 79.4899 69.5497C78.8825 69.5497 78.3 69.3085 77.8706 68.879C77.4411 68.4495 77.1999 67.8671 77.1999 67.2597V62.2597ZM78.7099 67.3397C78.7099 68.0297 79.0099 68.2897 79.4899 68.2897C79.9699 68.2897 80.2699 68.0297 80.2699 67.3397V62.1697C80.2699 61.4797 79.9699 61.2197 79.4899 61.2197C79.0099 61.2197 78.7099 61.4797 78.7099 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M82.5901 62.2596C82.5694 61.9468 82.6131 61.6331 82.7186 61.3379C82.8241 61.0427 82.989 60.7723 83.2032 60.5435C83.4174 60.3146 83.6764 60.1322 83.9639 60.0074C84.2515 59.8827 84.5616 59.8184 84.8751 59.8184C85.1886 59.8184 85.4987 59.8827 85.7863 60.0074C86.0739 60.1322 86.3328 60.3146 86.547 60.5435C86.7612 60.7723 86.9262 61.0427 87.0316 61.3379C87.1371 61.6331 87.1808 61.9468 87.1601 62.2596V67.2596C87.1808 67.5724 87.1371 67.8861 87.0316 68.1813C86.9262 68.4765 86.7612 68.7469 86.547 68.9757C86.3328 69.2046 86.0739 69.387 85.7863 69.5118C85.4987 69.6365 85.1886 69.7009 84.8751 69.7009C84.5616 69.7009 84.2515 69.6365 83.9639 69.5118C83.6764 69.387 83.4174 69.2046 83.2032 68.9757C82.989 68.7469 82.8241 68.4765 82.7186 68.1813C82.6131 67.8861 82.5694 67.5724 82.5901 67.2596V62.2596ZM84.0901 67.3396C84.0901 68.0296 84.4001 68.2896 84.8801 68.2896C85.3601 68.2896 85.6601 68.0296 85.6601 67.3396V62.1696C85.6601 61.4796 85.3601 61.2196 84.8801 61.2196C84.4001 61.2196 84.0901 61.4796 84.0901 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M88.05 62.2597C88.05 61.6524 88.2912 61.0699 88.7207 60.6405C89.1501 60.211 89.7326 59.9697 90.34 59.9697C90.9473 59.9697 91.5298 60.211 91.9592 60.6405C92.3887 61.0699 92.6299 61.6524 92.6299 62.2597V67.2597C92.6299 67.8671 92.3887 68.4495 91.9592 68.879C91.5298 69.3085 90.9473 69.5497 90.34 69.5497C89.7326 69.5497 89.1501 69.3085 88.7207 68.879C88.2912 68.4495 88.05 67.8671 88.05 67.2597V62.2597ZM89.56 67.3397C89.56 68.0297 89.86 68.2897 90.34 68.2897C90.82 68.2897 91.1199 68.0297 91.1199 67.3397V62.1697C91.1199 61.4797 90.82 61.2197 90.34 61.2197C89.86 61.2197 89.56 61.4797 89.56 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M93.4801 62.2597C93.4801 61.6524 93.7214 61.0699 94.1509 60.6405C94.5803 60.211 95.1628 59.9697 95.7701 59.9697C96.3775 59.9697 96.9599 60.211 97.3894 60.6405C97.8189 61.0699 98.0601 61.6524 98.0601 62.2597V67.2597C98.0601 67.8671 97.8189 68.4495 97.3894 68.879C96.9599 69.3085 96.3775 69.5497 95.7701 69.5497C95.1628 69.5497 94.5803 69.3085 94.1509 68.879C93.7214 68.4495 93.4801 67.8671 93.4801 67.2597V62.2597ZM94.9901 67.3397C94.9901 68.0297 95.2901 68.2897 95.7701 68.2897C96.2501 68.2897 96.5501 68.0297 96.5501 67.3397V62.1697C96.5501 61.4797 96.2501 61.2197 95.7701 61.2197C95.2901 61.2197 94.9901 61.4797 94.9901 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M98.9099 62.2596C98.8892 61.9468 98.933 61.6331 99.0384 61.3379C99.1439 61.0427 99.3088 60.7723 99.523 60.5435C99.7372 60.3146 99.9962 60.1322 100.284 60.0074C100.571 59.8827 100.881 59.8184 101.195 59.8184C101.508 59.8184 101.819 59.8827 102.106 60.0074C102.394 60.1322 102.653 60.3146 102.867 60.5435C103.081 60.7723 103.246 61.0427 103.351 61.3379C103.457 61.6331 103.501 61.9468 103.48 62.2596V67.2596C103.501 67.5724 103.457 67.8861 103.351 68.1813C103.246 68.4765 103.081 68.7469 102.867 68.9757C102.653 69.2046 102.394 69.387 102.106 69.5118C101.819 69.6365 101.508 69.7009 101.195 69.7009C100.881 69.7009 100.571 69.6365 100.284 69.5118C99.9962 69.387 99.7372 69.2046 99.523 68.9757C99.3088 68.7469 99.1439 68.4765 99.0384 68.1813C98.933 67.8861 98.8892 67.5724 98.9099 67.2596V62.2596ZM100.41 67.3396C100.41 68.0296 100.71 68.2896 101.19 68.2896C101.67 68.2896 101.97 68.0296 101.97 67.3396V62.1696C101.97 61.4796 101.67 61.2196 101.19 61.2196C100.71 61.2196 100.41 61.4796 100.41 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M109.59 61.17C109.969 61.2173 110.351 61.1185 110.66 60.8937C110.968 60.6689 111.179 60.335 111.25 59.96H112.25V69.55H110.74V62.2299H109.59V61.17Z"
        fill="#1B2236"
      />
      <path
        d="M115.06 61.17C115.438 61.2149 115.82 61.1151 116.127 60.8907C116.435 60.6663 116.647 60.3339 116.72 59.96H117.72V69.55H116.21V62.2299H115.05L115.06 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M120.49 61.17C120.867 61.217 121.249 61.1179 121.555 60.893C121.862 60.668 122.071 60.3342 122.14 59.96H123.14V69.55H121.63V62.2299H120.49V61.17Z"
        fill="#1B2236"
      />
      <path
        d="M125.91 61.17C126.289 61.2173 126.671 61.1185 126.98 60.8937C127.288 60.6689 127.499 60.335 127.57 59.96H128.57V69.55H127.07V62.2299H125.9L125.91 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M131.34 61.17C131.719 61.2149 132.1 61.1151 132.408 60.8907C132.716 60.6663 132.927 60.3339 133 59.96H134V69.55H132.49V62.2299H131.33L131.34 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M136.76 61.17C137.139 61.2173 137.521 61.1185 137.83 60.8937C138.138 60.6689 138.349 60.335 138.42 59.96H139.42V69.55H137.92V62.2299H136.75L136.76 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M141.13 62.2597C141.13 61.6524 141.371 61.0699 141.801 60.6405C142.23 60.211 142.813 59.9697 143.42 59.9697C144.027 59.9697 144.61 60.211 145.039 60.6405C145.469 61.0699 145.71 61.6524 145.71 62.2597V67.2597C145.71 67.8671 145.469 68.4495 145.039 68.879C144.61 69.3085 144.027 69.5497 143.42 69.5497C142.813 69.5497 142.23 69.3085 141.801 68.879C141.371 68.4495 141.13 67.8671 141.13 67.2597V62.2597ZM142.64 67.3397C142.64 68.0297 142.94 68.2897 143.42 68.2897C143.9 68.2897 144.2 68.0297 144.2 67.3397V62.1697C144.2 61.4797 143.9 61.2197 143.42 61.2197C142.94 61.2197 142.64 61.4797 142.64 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M146.59 62.2597C146.59 61.6524 146.831 61.0699 147.261 60.6405C147.69 60.211 148.273 59.9697 148.88 59.9697C149.487 59.9697 150.07 60.211 150.499 60.6405C150.929 61.0699 151.17 61.6524 151.17 62.2597V67.2597C151.17 67.8671 150.929 68.4495 150.499 68.879C150.07 69.3085 149.487 69.5497 148.88 69.5497C148.273 69.5497 147.69 69.3085 147.261 68.879C146.831 68.4495 146.59 67.8671 146.59 67.2597V62.2597ZM148.1 67.3397C148.1 68.0297 148.4 68.2897 148.88 68.2897C149.36 68.2897 149.66 68.0297 149.66 67.3397V62.1697C149.66 61.4797 149.36 61.2197 148.88 61.2197C148.4 61.2197 148.1 61.4797 148.1 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M151.99 62.2596C151.969 61.9468 152.013 61.6331 152.119 61.3379C152.224 61.0427 152.389 60.7723 152.603 60.5435C152.817 60.3146 153.076 60.1322 153.364 60.0074C153.651 59.8827 153.962 59.8184 154.275 59.8184C154.588 59.8184 154.899 59.8827 155.186 60.0074C155.474 60.1322 155.733 60.3146 155.947 60.5435C156.161 60.7723 156.326 61.0427 156.432 61.3379C156.537 61.6331 156.581 61.9468 156.56 62.2596V67.2596C156.581 67.5724 156.537 67.8861 156.432 68.1813C156.326 68.4765 156.161 68.7469 155.947 68.9757C155.733 69.2046 155.474 69.387 155.186 69.5118C154.899 69.6365 154.588 69.7009 154.275 69.7009C153.962 69.7009 153.651 69.6365 153.364 69.5118C153.076 69.387 152.817 69.2046 152.603 68.9757C152.389 68.7469 152.224 68.4765 152.119 68.1813C152.013 67.8861 151.969 67.5724 151.99 67.2596V62.2596ZM153.49 67.3396C153.49 68.0296 153.79 68.2896 154.27 68.2896C154.75 68.2896 155.05 68.0296 155.05 67.3396V62.1696C155.05 61.4796 154.75 61.2196 154.27 61.2196C153.79 61.2196 153.49 61.4796 153.49 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M157.41 62.2597C157.41 61.6524 157.651 61.0699 158.081 60.6405C158.51 60.211 159.092 59.9697 159.7 59.9697C160.307 59.9697 160.89 60.211 161.319 60.6405C161.749 61.0699 161.99 61.6524 161.99 62.2597V67.2597C161.99 67.8671 161.749 68.4495 161.319 68.879C160.89 69.3085 160.307 69.5497 159.7 69.5497C159.092 69.5497 158.51 69.3085 158.081 68.879C157.651 68.4495 157.41 67.8671 157.41 67.2597V62.2597ZM158.92 67.3397C158.92 68.0297 159.22 68.2897 159.7 68.2897C160.18 68.2897 160.48 68.0297 160.48 67.3397V62.1697C160.48 61.4797 160.18 61.2197 159.7 61.2197C159.22 61.2197 158.92 61.4797 158.92 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M162.84 62.2596C162.819 61.9468 162.863 61.6331 162.969 61.3379C163.074 61.0427 163.239 60.7723 163.453 60.5435C163.667 60.3146 163.926 60.1322 164.214 60.0074C164.502 59.8827 164.812 59.8184 165.125 59.8184C165.439 59.8184 165.749 59.8827 166.036 60.0074C166.324 60.1322 166.583 60.3146 166.797 60.5435C167.011 60.7723 167.176 61.0427 167.282 61.3379C167.387 61.6331 167.431 61.9468 167.41 62.2596V67.2596C167.431 67.5724 167.387 67.8861 167.282 68.1813C167.176 68.4765 167.011 68.7469 166.797 68.9757C166.583 69.2046 166.324 69.387 166.036 69.5118C165.749 69.6365 165.439 69.7009 165.125 69.7009C164.812 69.7009 164.502 69.6365 164.214 69.5118C163.926 69.387 163.667 69.2046 163.453 68.9757C163.239 68.7469 163.074 68.4765 162.969 68.1813C162.863 67.8861 162.819 67.5724 162.84 67.2596V62.2596ZM164.34 67.3396C164.34 68.0296 164.65 68.2896 165.13 68.2896C165.61 68.2896 165.91 68.0296 165.91 67.3396V62.1696C165.91 61.4796 165.6 61.2196 165.13 61.2196C164.66 61.2196 164.34 61.4796 164.34 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M168.26 62.2597C168.26 61.6524 168.501 61.0699 168.931 60.6405C169.36 60.211 169.943 59.9697 170.55 59.9697C171.157 59.9697 171.74 60.211 172.169 60.6405C172.599 61.0699 172.84 61.6524 172.84 62.2597V67.2597C172.84 67.8671 172.599 68.4495 172.169 68.879C171.74 69.3085 171.157 69.5497 170.55 69.5497C169.943 69.5497 169.36 69.3085 168.931 68.879C168.501 68.4495 168.26 67.8671 168.26 67.2597V62.2597ZM169.77 67.3397C169.77 68.0297 170.07 68.2897 170.55 68.2897C171.03 68.2897 171.33 68.0297 171.33 67.3397V62.1697C171.33 61.4797 171.03 61.2197 170.55 61.2197C170.07 61.2197 169.77 61.4797 169.77 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M173.69 62.2596C173.67 61.9468 173.713 61.6331 173.819 61.3379C173.924 61.0427 174.089 60.7723 174.303 60.5435C174.518 60.3146 174.776 60.1322 175.064 60.0074C175.352 59.8827 175.662 59.8184 175.975 59.8184C176.289 59.8184 176.599 59.8827 176.886 60.0074C177.174 60.1322 177.433 60.3146 177.647 60.5435C177.861 60.7723 178.026 61.0427 178.132 61.3379C178.237 61.6331 178.281 61.9468 178.26 62.2596V67.2596C178.281 67.5724 178.237 67.8861 178.132 68.1813C178.026 68.4765 177.861 68.7469 177.647 68.9757C177.433 69.2046 177.174 69.387 176.886 69.5118C176.599 69.6365 176.289 69.7009 175.975 69.7009C175.662 69.7009 175.352 69.6365 175.064 69.5118C174.776 69.387 174.518 69.2046 174.303 68.9757C174.089 68.7469 173.924 68.4765 173.819 68.1813C173.713 67.8861 173.67 67.5724 173.69 67.2596V62.2596ZM175.2 67.3396C175.2 68.0296 175.5 68.2896 175.98 68.2896C176.46 68.2896 176.76 68.0296 176.76 67.3396V62.1696C176.76 61.4796 176.46 61.2196 175.98 61.2196C175.5 61.2196 175.2 61.4796 175.2 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M182.29 61.17C182.669 61.2173 183.051 61.1185 183.36 60.8937C183.668 60.6689 183.879 60.335 183.95 59.96H184.95V69.55H183.44V62.2299H182.29V61.17Z"
        fill="#1B2236"
      />
      <path
        d="M187.72 61.17C188.098 61.2149 188.48 61.1151 188.788 60.8907C189.095 60.6663 189.307 60.3339 189.38 59.96H190.38V69.55H188.87V62.2299H187.71L187.72 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M193.15 61.17C193.528 61.217 193.909 61.1179 194.216 60.893C194.522 60.668 194.731 60.3342 194.8 59.96H195.8V69.55H194.29V62.2299H193.13L193.15 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M198.59 61.17C198.969 61.2173 199.351 61.1185 199.66 60.8937C199.968 60.6689 200.179 60.335 200.25 59.96H201.25V69.55H199.75V62.2299H198.59V61.17Z"
        fill="#1B2236"
      />
      <path
        d="M204 61.17C204.378 61.2149 204.759 61.1151 205.067 60.8907C205.375 60.6663 205.587 60.3339 205.66 59.96H206.66V69.55H205.15V62.2299H203.99L204 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M212.59 62.2596C212.569 61.9468 212.613 61.6331 212.719 61.3379C212.824 61.0427 212.989 60.7723 213.203 60.5435C213.417 60.3146 213.676 60.1322 213.964 60.0074C214.252 59.8827 214.562 59.8184 214.875 59.8184C215.189 59.8184 215.499 59.8827 215.786 60.0074C216.074 60.1322 216.333 60.3146 216.547 60.5435C216.761 60.7723 216.926 61.0427 217.032 61.3379C217.137 61.6331 217.181 61.9468 217.16 62.2596V67.2596C217.181 67.5724 217.137 67.8861 217.032 68.1813C216.926 68.4765 216.761 68.7469 216.547 68.9757C216.333 69.2046 216.074 69.387 215.786 69.5118C215.499 69.6365 215.189 69.7009 214.875 69.7009C214.562 69.7009 214.252 69.6365 213.964 69.5118C213.676 69.387 213.417 69.2046 213.203 68.9757C212.989 68.7469 212.824 68.4765 212.719 68.1813C212.613 67.8861 212.569 67.5724 212.59 67.2596V62.2596ZM214.09 67.3396C214.09 68.0296 214.39 68.2896 214.87 68.2896C215.35 68.2896 215.65 68.0296 215.65 67.3396V62.1696C215.65 61.4796 215.35 61.2196 214.87 61.2196C214.39 61.2196 214.09 61.4796 214.09 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M218.04 62.2597C218.04 61.6524 218.281 61.0699 218.711 60.6405C219.14 60.211 219.723 59.9697 220.33 59.9697C220.938 59.9697 221.52 60.211 221.949 60.6405C222.379 61.0699 222.62 61.6524 222.62 62.2597V67.2597C222.62 67.8671 222.379 68.4495 221.949 68.879C221.52 69.3085 220.938 69.5497 220.33 69.5497C219.723 69.5497 219.14 69.3085 218.711 68.879C218.281 68.4495 218.04 67.8671 218.04 67.2597V62.2597ZM219.55 67.3397C219.55 68.0297 219.85 68.2897 220.33 68.2897C220.81 68.2897 221.11 68.0297 221.11 67.3397V62.1697C221.11 61.4797 220.81 61.2197 220.33 61.2197C219.85 61.2197 219.55 61.4797 219.55 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M223.47 62.2596C223.449 61.9468 223.493 61.6331 223.598 61.3379C223.704 61.0427 223.869 60.7723 224.083 60.5435C224.297 60.3146 224.556 60.1322 224.844 60.0074C225.131 59.8827 225.442 59.8184 225.755 59.8184C226.068 59.8184 226.379 59.8827 226.666 60.0074C226.954 60.1322 227.213 60.3146 227.427 60.5435C227.641 60.7723 227.806 61.0427 227.911 61.3379C228.017 61.6331 228.061 61.9468 228.04 62.2596V67.2596C228.061 67.5724 228.017 67.8861 227.911 68.1813C227.806 68.4765 227.641 68.7469 227.427 68.9757C227.213 69.2046 226.954 69.387 226.666 69.5118C226.379 69.6365 226.068 69.7009 225.755 69.7009C225.442 69.7009 225.131 69.6365 224.844 69.5118C224.556 69.387 224.297 69.2046 224.083 68.9757C223.869 68.7469 223.704 68.4765 223.598 68.1813C223.493 67.8861 223.449 67.5724 223.47 67.2596V62.2596ZM224.97 67.3396C224.97 68.0296 225.28 68.2896 225.76 68.2896C226.24 68.2896 226.54 68.0296 226.54 67.3396V62.1696C226.54 61.4796 226.23 61.2196 225.76 61.2196C225.29 61.2196 224.97 61.4796 224.97 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M228.89 62.2597C228.89 61.6524 229.131 61.0699 229.561 60.6405C229.99 60.211 230.572 59.9697 231.18 59.9697C231.787 59.9697 232.37 60.211 232.799 60.6405C233.229 61.0699 233.47 61.6524 233.47 62.2597V67.2597C233.47 67.8671 233.229 68.4495 232.799 68.879C232.37 69.3085 231.787 69.5497 231.18 69.5497C230.572 69.5497 229.99 69.3085 229.561 68.879C229.131 68.4495 228.89 67.8671 228.89 67.2597V62.2597ZM230.4 67.3397C230.4 68.0297 230.7 68.2897 231.18 68.2897C231.66 68.2897 231.96 68.0297 231.96 67.3397V62.1697C231.96 61.4797 231.66 61.2197 231.18 61.2197C230.7 61.2197 230.4 61.4797 230.4 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M234.32 62.2597C234.32 61.959 234.379 61.6612 234.494 61.3834C234.609 61.1055 234.778 60.8531 234.991 60.6405C235.203 60.4278 235.456 60.2591 235.734 60.144C236.011 60.029 236.309 59.9697 236.61 59.9697C236.911 59.9697 237.208 60.029 237.486 60.144C237.764 60.2591 238.017 60.4278 238.229 60.6405C238.442 60.8531 238.611 61.1055 238.726 61.3834C238.841 61.6612 238.9 61.959 238.9 62.2597V67.2597C238.9 67.5605 238.841 67.8582 238.726 68.1361C238.611 68.4139 238.442 68.6664 238.229 68.879C238.017 69.0916 237.764 69.2603 237.486 69.3754C237.208 69.4905 236.911 69.5497 236.61 69.5497C236.309 69.5497 236.011 69.4905 235.734 69.3754C235.456 69.2603 235.203 69.0916 234.991 68.879C234.778 68.6664 234.609 68.4139 234.494 68.1361C234.379 67.8582 234.32 67.5605 234.32 67.2597V62.2597ZM235.83 67.3397C235.83 68.0297 236.13 68.2897 236.61 68.2897C237.09 68.2897 237.39 68.0297 237.39 67.3397V62.1697C237.39 61.4797 237.09 61.2197 236.61 61.2197C236.13 61.2197 235.83 61.4797 235.83 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M239.75 62.2596C239.729 61.9468 239.773 61.6331 239.878 61.3379C239.984 61.0427 240.149 60.7723 240.363 60.5435C240.577 60.3146 240.836 60.1322 241.124 60.0074C241.411 59.8827 241.721 59.8184 242.035 59.8184C242.348 59.8184 242.658 59.8827 242.946 60.0074C243.234 60.1322 243.492 60.3146 243.707 60.5435C243.921 60.7723 244.086 61.0427 244.191 61.3379C244.297 61.6331 244.34 61.9468 244.32 62.2596V67.2596C244.34 67.5724 244.297 67.8861 244.191 68.1813C244.086 68.4765 243.921 68.7469 243.707 68.9757C243.492 69.2046 243.234 69.387 242.946 69.5118C242.658 69.6365 242.348 69.7009 242.035 69.7009C241.721 69.7009 241.411 69.6365 241.124 69.5118C240.836 69.387 240.577 69.2046 240.363 68.9757C240.149 68.7469 239.984 68.4765 239.878 68.1813C239.773 67.8861 239.729 67.5724 239.75 67.2596V62.2596ZM241.25 67.3396C241.25 68.0296 241.55 68.2896 242.03 68.2896C242.51 68.2896 242.81 68.0296 242.81 67.3396V62.1696C242.81 61.4796 242.51 61.2196 242.03 61.2196C241.55 61.2196 241.25 61.4796 241.25 62.1696V67.3396Z"
        fill="#1B2236"
      />
      <path
        d="M249.42 62.2597C249.42 61.6524 249.661 61.0699 250.091 60.6405C250.52 60.211 251.103 59.9697 251.71 59.9697C252.317 59.9697 252.9 60.211 253.329 60.6405C253.759 61.0699 254 61.6524 254 62.2597V67.2597C254 67.8671 253.759 68.4495 253.329 68.879C252.9 69.3085 252.317 69.5497 251.71 69.5497C251.103 69.5497 250.52 69.3085 250.091 68.879C249.661 68.4495 249.42 67.8671 249.42 67.2597V62.2597ZM250.93 67.3397C250.93 68.0297 251.23 68.2897 251.71 68.2897C252.19 68.2897 252.49 68.0297 252.49 67.3397V62.1697C252.49 61.4797 252.19 61.2197 251.71 61.2197C251.23 61.2197 250.93 61.4797 250.93 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M255.9 61.17C256.278 61.2149 256.659 61.1151 256.967 60.8907C257.275 60.6663 257.487 60.3339 257.56 59.96H258.56V69.55H257.05V62.2299H255.89L255.9 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M260.27 62.2597C260.27 61.6524 260.511 61.0699 260.941 60.6405C261.37 60.211 261.953 59.9697 262.56 59.9697C263.168 59.9697 263.75 60.211 264.179 60.6405C264.609 61.0699 264.85 61.6524 264.85 62.2597V67.2597C264.85 67.8671 264.609 68.4495 264.179 68.879C263.75 69.3085 263.168 69.5497 262.56 69.5497C261.953 69.5497 261.37 69.3085 260.941 68.879C260.511 68.4495 260.27 67.8671 260.27 67.2597V62.2597ZM261.78 67.3397C261.78 68.0297 262.08 68.2897 262.56 68.2897C263.04 68.2897 263.34 68.0297 263.34 67.3397V62.1697C263.34 61.4797 263.04 61.2197 262.56 61.2197C262.08 61.2197 261.78 61.4797 261.78 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M266.75 61.17C267.129 61.2173 267.511 61.1185 267.82 60.8937C268.128 60.6689 268.339 60.335 268.41 59.96H269.41V69.55H267.91V62.2299H266.74L266.75 61.17Z"
        fill="#1B2236"
      />
      <path
        d="M271.12 62.2597C271.12 61.6524 271.361 61.0699 271.79 60.6405C272.22 60.211 272.802 59.9697 273.41 59.9697C274.017 59.9697 274.6 60.211 275.029 60.6405C275.459 61.0699 275.7 61.6524 275.7 62.2597V67.2597C275.7 67.8671 275.459 68.4495 275.029 68.879C274.6 69.3085 274.017 69.5497 273.41 69.5497C272.802 69.5497 272.22 69.3085 271.79 68.879C271.361 68.4495 271.12 67.8671 271.12 67.2597V62.2597ZM272.63 67.3397C272.63 68.0297 272.93 68.2897 273.41 68.2897C273.89 68.2897 274.19 68.0297 274.19 67.3397V62.1697C274.19 61.4797 273.89 61.2197 273.41 61.2197C272.93 61.2197 272.63 61.4797 272.63 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M277.59 61.17C277.969 61.2173 278.351 61.1185 278.66 60.8937C278.968 60.6689 279.179 60.335 279.25 59.96H280.25V69.55H278.75V62.2299H277.59V61.17Z"
        fill="#1B2236"
      />
      <path
        d="M281.97 62.2597C281.97 61.6524 282.211 61.0699 282.641 60.6405C283.07 60.211 283.653 59.9697 284.26 59.9697C284.867 59.9697 285.45 60.211 285.879 60.6405C286.309 61.0699 286.55 61.6524 286.55 62.2597V67.2597C286.55 67.8671 286.309 68.4495 285.879 68.879C285.45 69.3085 284.867 69.5497 284.26 69.5497C283.653 69.5497 283.07 69.3085 282.641 68.879C282.211 68.4495 281.97 67.8671 281.97 67.2597V62.2597ZM283.48 67.3397C283.48 68.0297 283.78 68.2897 284.26 68.2897C284.74 68.2897 285.04 68.0297 285.04 67.3397V62.1697C285.04 61.4797 284.74 61.2197 284.26 61.2197C283.78 61.2197 283.48 61.4797 283.48 62.1697V67.3397Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 78.6904C24.1198 78.083 24.3611 77.5006 24.7905 77.0711C25.22 76.6416 25.8024 76.4004 26.4098 76.4004C27.0171 76.4004 27.5996 76.6416 28.0291 77.0711C28.4585 77.5006 28.6998 78.083 28.6998 78.6904V83.6904C28.6998 84.2977 28.4585 84.8802 28.0291 85.3097C27.5996 85.7391 27.0171 85.9804 26.4098 85.9804C25.8024 85.9804 25.22 85.7391 24.7905 85.3097C24.3611 84.8802 24.1198 84.2977 24.1198 83.6904V78.6904ZM25.5898 83.7904C25.5898 84.4704 25.8898 84.7304 26.3698 84.7304C26.8498 84.7304 27.1498 84.4704 27.1498 83.7904V78.6104C27.1498 77.9204 26.8498 77.6604 26.3698 77.6604C25.8898 77.6604 25.5898 77.9204 25.5898 78.6104V83.7904Z"
        fill="#1B2236"
      />
      <path
        d="M30.59 77.6104C30.9691 77.6602 31.3528 77.5624 31.6618 77.3372C31.9708 77.1119 32.1814 76.7766 32.25 76.4004H33.25V85.9904H31.75V78.6904H30.59V77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 78.6904C34.9699 78.083 35.2111 77.5006 35.6406 77.0711C36.0701 76.6416 36.6525 76.4004 37.2599 76.4004C37.8672 76.4004 38.4497 76.6416 38.8792 77.0711C39.3086 77.5006 39.5499 78.083 39.5499 78.6904V83.6904C39.5499 84.2977 39.3086 84.8802 38.8792 85.3097C38.4497 85.7391 37.8672 85.9804 37.2599 85.9804C36.6525 85.9804 36.0701 85.7391 35.6406 85.3097C35.2111 84.8802 34.9699 84.2977 34.9699 83.6904V78.6904ZM36.4799 83.7804C36.4799 84.4604 36.7799 84.7204 37.2599 84.7204C37.7399 84.7204 38.0399 84.4604 38.0399 83.7804V78.6004C38.0399 77.9104 37.7399 77.6504 37.2599 77.6504C36.7799 77.6504 36.4799 77.9104 36.4799 78.6004V83.7804Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 77.6104C41.8292 77.6602 42.2129 77.5624 42.5219 77.3372C42.8309 77.1119 43.0415 76.7766 43.1101 76.4004H44.1101V85.9904H42.5901V78.6904H41.4201L41.4501 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M45.8299 78.6903C45.8092 78.3775 45.8529 78.0638 45.9583 77.7686C46.0638 77.4734 46.2287 77.203 46.443 76.9741C46.6572 76.7453 46.9161 76.5628 47.2037 76.4381C47.4913 76.3134 47.8014 76.249 48.1148 76.249C48.4283 76.249 48.7385 76.3134 49.026 76.4381C49.3136 76.5628 49.5725 76.7453 49.7868 76.9741C50.001 77.203 50.1659 77.4734 50.2714 77.7686C50.3768 78.0638 50.4206 78.3775 50.3999 78.6903V83.6903C50.4206 84.003 50.3768 84.3167 50.2714 84.6119C50.1659 84.9071 50.001 85.1775 49.7868 85.4064C49.5725 85.6352 49.3136 85.8177 49.026 85.9424C48.7385 86.0672 48.4283 86.1315 48.1148 86.1315C47.8014 86.1315 47.4913 86.0672 47.2037 85.9424C46.9161 85.8177 46.6572 85.6352 46.443 85.4064C46.2287 85.1775 46.0638 84.9071 45.9583 84.6119C45.8529 84.3167 45.8092 84.003 45.8299 83.6903V78.6903ZM47.3299 83.7803C47.3299 84.4603 47.6299 84.7203 48.1099 84.7203C48.5899 84.7203 48.8899 84.4603 48.8899 83.7803V78.6003C48.8899 77.9103 48.5899 77.6503 48.1099 77.6503C47.6299 77.6503 47.3299 77.9103 47.3299 78.6003V83.7803Z"
        fill="#1B2236"
      />
      <path
        d="M52.3102 77.6104C52.6881 77.66 53.0706 77.5618 53.378 77.3364C53.6853 77.111 53.8939 76.7757 53.9602 76.4004H54.9602V85.9904H53.4502V78.6904H52.2902L52.3102 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M57.7299 77.6104C58.109 77.6602 58.4927 77.5624 58.8017 77.3372C59.1107 77.1119 59.3213 76.7766 59.3899 76.4004H60.3899V85.9904H58.8899V78.6904H57.7199L57.7299 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M63.1601 77.6104C63.5388 77.6577 63.9213 77.5589 64.2297 77.3341C64.5381 77.1093 64.7492 76.7754 64.8201 76.4004H65.8201V85.9904H64.3101V78.6904H63.1501L63.1601 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M68.59 77.6104C68.9691 77.6602 69.3528 77.5624 69.6618 77.3372C69.9708 77.1119 70.1814 76.7766 70.25 76.4004H71.25V85.9904H69.74V78.6904H68.59V77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M74.0101 77.6104C74.3889 77.6577 74.7714 77.5589 75.0798 77.3341C75.3882 77.1093 75.5993 76.7754 75.6702 76.4004H76.6702V85.9904H75.1602V78.6904H74.0002L74.0101 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M79.4398 77.6104C79.8178 77.66 80.2002 77.5618 80.5076 77.3364C80.815 77.111 81.0235 76.7757 81.0898 76.4004H82.0898V85.9904H80.5898V78.6904H79.4298L79.4398 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M84.8598 77.6104C85.2389 77.6602 85.6226 77.5624 85.9316 77.3372C86.2406 77.1119 86.4512 76.7766 86.5198 76.4004H87.5198V85.9904H86.0198V78.6904H84.8498L84.8598 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M89.2301 78.6904C89.2301 78.083 89.4714 77.5006 89.9009 77.0711C90.3303 76.6416 90.9128 76.4004 91.5201 76.4004C92.1275 76.4004 92.7099 76.6416 93.1394 77.0711C93.5689 77.5006 93.8101 78.083 93.8101 78.6904V83.6904C93.8101 84.2977 93.5689 84.8802 93.1394 85.3097C92.7099 85.7391 92.1275 85.9804 91.5201 85.9804C90.9128 85.9804 90.3303 85.7391 89.9009 85.3097C89.4714 84.8802 89.2301 84.2977 89.2301 83.6904V78.6904ZM90.7401 83.7804C90.7401 84.4604 91.0401 84.7204 91.5201 84.7204C92.0001 84.7204 92.3001 84.4604 92.3001 83.7804V78.6004C92.3001 77.9104 92.0001 77.6504 91.5201 77.6504C91.0401 77.6504 90.7401 77.9104 90.7401 78.6004V83.7804Z"
        fill="#1B2236"
      />
      <path
        d="M95.7099 77.6104C96.089 77.6602 96.4727 77.5624 96.7817 77.3372C97.0907 77.1119 97.3013 76.7766 97.3699 76.4004H98.3699V85.9904H96.8699V78.6904H95.6999L95.7099 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M103.26 77.6104C103.639 77.6602 104.023 77.5624 104.332 77.3372C104.641 77.1119 104.852 76.7766 104.92 76.4004H105.92V85.9904H104.42V78.6904H103.25L103.26 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M107.59 78.6904C107.59 78.083 107.831 77.5006 108.261 77.0711C108.69 76.6416 109.273 76.4004 109.88 76.4004C110.487 76.4004 111.07 76.6416 111.499 77.0711C111.929 77.5006 112.17 78.083 112.17 78.6904V83.6904C112.17 84.2977 111.929 84.8802 111.499 85.3097C111.07 85.7391 110.487 85.9804 109.88 85.9804C109.273 85.9804 108.69 85.7391 108.261 85.3097C107.831 84.8802 107.59 84.2977 107.59 83.6904V78.6904ZM109.1 83.7804C109.1 84.4604 109.4 84.7204 109.88 84.7204C110.36 84.7204 110.66 84.4604 110.66 83.7804V78.6004C110.66 77.9104 110.36 77.6504 109.88 77.6504C109.4 77.6504 109.1 77.9104 109.1 78.6004V83.7804Z"
        fill="#1B2236"
      />
      <path
        d="M114.11 77.6104C114.489 77.6602 114.873 77.5624 115.182 77.3372C115.491 77.1119 115.701 76.7766 115.77 76.4004H116.77V85.9904H115.26V78.6904H114.09L114.11 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M121.66 77.6104C122.039 77.6602 122.423 77.5624 122.732 77.3372C123.041 77.1119 123.251 76.7766 123.32 76.4004H124.32V85.9904H122.81V78.6904H121.64L121.66 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M128.16 78.6903C128.139 78.3775 128.183 78.0638 128.288 77.7686C128.394 77.4734 128.559 77.203 128.773 76.9741C128.987 76.7453 129.246 76.5628 129.534 76.4381C129.821 76.3134 130.131 76.249 130.445 76.249C130.758 76.249 131.069 76.3134 131.356 76.4381C131.644 76.5628 131.903 76.7453 132.117 76.9741C132.331 77.203 132.496 77.4734 132.601 77.7686C132.707 78.0638 132.751 78.3775 132.73 78.6903V83.6903C132.751 84.003 132.707 84.3167 132.601 84.6119C132.496 84.9071 132.331 85.1775 132.117 85.4064C131.903 85.6352 131.644 85.8177 131.356 85.9424C131.069 86.0672 130.758 86.1315 130.445 86.1315C130.131 86.1315 129.821 86.0672 129.534 85.9424C129.246 85.8177 128.987 85.6352 128.773 85.4064C128.559 85.1775 128.394 84.9071 128.288 84.6119C128.183 84.3167 128.139 84.003 128.16 83.6903V78.6903ZM129.67 83.7803C129.67 84.4603 129.97 84.7203 130.45 84.7203C130.93 84.7203 131.23 84.4603 131.23 83.7803V78.6003C131.23 77.9103 130.93 77.6503 130.45 77.6503C129.97 77.6503 129.67 77.9103 129.67 78.6003V83.7803Z"
        fill="#1B2236"
      />
      <path
        d="M134.64 77.6104C135.019 77.6577 135.401 77.5589 135.71 77.3341C136.018 77.1093 136.229 76.7754 136.3 76.4004H137.3V85.9904H135.79V78.6904H134.63L134.64 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M140.07 77.6104C140.448 77.66 140.83 77.5618 141.138 77.3364C141.445 77.111 141.654 76.7757 141.72 76.4004H142.72V85.9904H141.21V78.6904H140.05L140.07 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M145.49 77.6104C145.869 77.6602 146.253 77.5624 146.562 77.3372C146.871 77.1119 147.081 76.7766 147.15 76.4004H148.15V85.9904H146.65V78.6904H145.49V77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M150.92 77.6104C151.298 77.66 151.68 77.5618 151.988 77.3364C152.295 77.111 152.504 76.7757 152.57 76.4004H153.57V85.9904H152.06V78.6904H150.9L150.92 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M156.34 77.6104C156.719 77.6602 157.103 77.5624 157.412 77.3372C157.721 77.1119 157.932 76.7766 158 76.4004H159V85.9904H157.5V78.6904H156.33L156.34 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M160.71 78.6904C160.71 78.3897 160.769 78.0919 160.884 77.814C161 77.5362 161.168 77.2838 161.381 77.0711C161.593 76.8585 161.846 76.6898 162.124 76.5747C162.402 76.4596 162.699 76.4004 163 76.4004C163.301 76.4004 163.599 76.4596 163.876 76.5747C164.154 76.6898 164.407 76.8585 164.619 77.0711C164.832 77.2838 165.001 77.5362 165.116 77.814C165.231 78.0919 165.29 78.3897 165.29 78.6904V83.6904C165.29 83.9911 165.231 84.2889 165.116 84.5667C165.001 84.8446 164.832 85.097 164.619 85.3097C164.407 85.5223 164.154 85.691 163.876 85.8061C163.599 85.9211 163.301 85.9804 163 85.9804C162.699 85.9804 162.402 85.9211 162.124 85.8061C161.846 85.691 161.593 85.5223 161.381 85.3097C161.168 85.097 161 84.8446 160.884 84.5667C160.769 84.2889 160.71 83.9911 160.71 83.6904V78.6904ZM162.22 83.7804C162.22 84.4604 162.52 84.7204 163 84.7204C163.48 84.7204 163.78 84.4604 163.78 83.7804V78.6004C163.78 77.9104 163.48 77.6504 163 77.6504C162.52 77.6504 162.22 77.9104 162.22 78.6004V83.7804Z"
        fill="#1B2236"
      />
      <path
        d="M167.19 77.6104C167.569 77.6602 167.953 77.5624 168.262 77.3372C168.571 77.1119 168.782 76.7766 168.85 76.4004H169.85V85.9904H168.34V78.6904H167.19V77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M171.59 78.6903C171.569 78.3775 171.613 78.0638 171.719 77.7686C171.824 77.4734 171.989 77.203 172.203 76.9741C172.417 76.7453 172.676 76.5628 172.964 76.4381C173.252 76.3134 173.562 76.249 173.875 76.249C174.189 76.249 174.499 76.3134 174.786 76.4381C175.074 76.5628 175.333 76.7453 175.547 76.9741C175.761 77.203 175.926 77.4734 176.032 77.7686C176.137 78.0638 176.181 78.3775 176.16 78.6903V83.6903C176.181 84.003 176.137 84.3167 176.032 84.6119C175.926 84.9071 175.761 85.1775 175.547 85.4064C175.333 85.6352 175.074 85.8177 174.786 85.9424C174.499 86.0672 174.189 86.1315 173.875 86.1315C173.562 86.1315 173.252 86.0672 172.964 85.9424C172.676 85.8177 172.417 85.6352 172.203 85.4064C171.989 85.1775 171.824 84.9071 171.719 84.6119C171.613 84.3167 171.569 84.003 171.59 83.6903V78.6903ZM173.09 83.7803C173.09 84.4603 173.39 84.7203 173.87 84.7203C174.35 84.7203 174.65 84.4603 174.65 83.7803V78.6003C174.65 77.9103 174.35 77.6503 173.87 77.6503C173.39 77.6503 173.09 77.9103 173.09 78.6003V83.7803Z"
        fill="#1B2236"
      />
      <path
        d="M178.05 77.6104C178.428 77.66 178.81 77.5618 179.118 77.3364C179.425 77.111 179.634 76.7757 179.7 76.4004H180.7V85.9904H179.19V78.6904H178.03L178.05 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M182.42 78.6903C182.399 78.3775 182.443 78.0638 182.549 77.7686C182.654 77.4734 182.819 77.203 183.033 76.9741C183.248 76.7453 183.506 76.5628 183.794 76.4381C184.082 76.3134 184.392 76.249 184.705 76.249C185.019 76.249 185.329 76.3134 185.616 76.4381C185.904 76.5628 186.163 76.7453 186.377 76.9741C186.591 77.203 186.756 77.4734 186.862 77.7686C186.967 78.0638 187.011 78.3775 186.99 78.6903V83.6903C187.011 84.003 186.967 84.3167 186.862 84.6119C186.756 84.9071 186.591 85.1775 186.377 85.4064C186.163 85.6352 185.904 85.8177 185.616 85.9424C185.329 86.0672 185.019 86.1315 184.705 86.1315C184.392 86.1315 184.082 86.0672 183.794 85.9424C183.506 85.8177 183.248 85.6352 183.033 85.4064C182.819 85.1775 182.654 84.9071 182.549 84.6119C182.443 84.3167 182.399 84.003 182.42 83.6903V78.6903ZM183.92 83.7803C183.92 84.4603 184.23 84.7203 184.71 84.7203C185.19 84.7203 185.49 84.4603 185.49 83.7803V78.6003C185.49 77.9103 185.18 77.6503 184.71 77.6503C184.24 77.6503 183.92 77.9103 183.92 78.6003V83.7803Z"
        fill="#1B2236"
      />
      <path
        d="M188.9 77.6104C189.279 77.6577 189.661 77.5589 189.969 77.3341C190.278 77.1093 190.489 76.7754 190.56 76.4004H191.56V85.9904H190.05V78.6904H188.89L188.9 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M199.64 78.6904C199.64 78.083 199.881 77.5006 200.311 77.0711C200.74 76.6416 201.322 76.4004 201.93 76.4004C202.537 76.4004 203.12 76.6416 203.549 77.0711C203.979 77.5006 204.22 78.083 204.22 78.6904V83.6904C204.22 84.2977 203.979 84.8802 203.549 85.3097C203.12 85.7391 202.537 85.9804 201.93 85.9804C201.322 85.9804 200.74 85.7391 200.311 85.3097C199.881 84.8802 199.64 84.2977 199.64 83.6904V78.6904ZM201.15 83.7804C201.15 84.4604 201.45 84.7204 201.93 84.7204C202.41 84.7204 202.71 84.4604 202.71 83.7804V78.6004C202.71 77.9104 202.41 77.6504 201.93 77.6504C201.45 77.6504 201.15 77.9104 201.15 78.6004V83.7804Z"
        fill="#1B2236"
      />
      <path
        d="M206.12 77.6104C206.499 77.6602 206.883 77.5624 207.192 77.3372C207.501 77.1119 207.711 76.7766 207.78 76.4004H208.78V85.9904H207.28V78.6904H206.11L206.12 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M210.49 78.6904C210.49 78.083 210.731 77.5006 211.161 77.0711C211.59 76.6416 212.173 76.4004 212.78 76.4004C213.387 76.4004 213.97 76.6416 214.399 77.0711C214.829 77.5006 215.07 78.083 215.07 78.6904V83.6904C215.07 84.2977 214.829 84.8802 214.399 85.3097C213.97 85.7391 213.387 85.9804 212.78 85.9804C212.173 85.9804 211.59 85.7391 211.161 85.3097C210.731 84.8802 210.49 84.2977 210.49 83.6904V78.6904ZM212 83.7804C212 84.4604 212.3 84.7204 212.78 84.7204C213.26 84.7204 213.56 84.4604 213.56 83.7804V78.6004C213.56 77.9104 213.26 77.6504 212.78 77.6504C212.3 77.6504 212 77.9104 212 78.6004V83.7804Z"
        fill="#1B2236"
      />
      <path
        d="M216.97 77.6104C217.349 77.6602 217.733 77.5624 218.042 77.3372C218.351 77.1119 218.562 76.7766 218.63 76.4004H219.63V85.9904H218.13V78.6904H216.96L216.97 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M222.4 77.6104C222.779 77.6577 223.161 77.5589 223.47 77.3341C223.778 77.1093 223.989 76.7754 224.06 76.4004H225.06V85.9904H223.59V78.6904H222.43L222.4 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M227.82 77.6104C228.199 77.6602 228.583 77.5624 228.892 77.3372C229.201 77.1119 229.411 76.7766 229.48 76.4004H230.48V85.9904H228.99V78.6904H227.82V77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M232.2 78.6903C232.179 78.3775 232.223 78.0638 232.328 77.7686C232.434 77.4734 232.599 77.203 232.813 76.9741C233.027 76.7453 233.286 76.5628 233.574 76.4381C233.861 76.3134 234.172 76.249 234.485 76.249C234.798 76.249 235.109 76.3134 235.396 76.4381C235.684 76.5628 235.943 76.7453 236.157 76.9741C236.371 77.203 236.536 77.4734 236.642 77.7686C236.747 78.0638 236.791 78.3775 236.77 78.6903V83.6903C236.791 84.003 236.747 84.3167 236.642 84.6119C236.536 84.9071 236.371 85.1775 236.157 85.4064C235.943 85.6352 235.684 85.8177 235.396 85.9424C235.109 86.0672 234.798 86.1315 234.485 86.1315C234.172 86.1315 233.861 86.0672 233.574 85.9424C233.286 85.8177 233.027 85.6352 232.813 85.4064C232.599 85.1775 232.434 84.9071 232.328 84.6119C232.223 84.3167 232.179 84.003 232.2 83.6903V78.6903ZM233.7 83.7803C233.7 84.4603 234 84.7203 234.48 84.7203C234.96 84.7203 235.26 84.4603 235.26 83.7803V78.6003C235.26 77.9103 234.96 77.6503 234.48 77.6503C234 77.6503 233.7 77.9103 233.7 78.6003V83.7803Z"
        fill="#1B2236"
      />
      <path
        d="M238.68 77.6104C239.058 77.66 239.44 77.5618 239.748 77.3364C240.055 77.111 240.264 76.7757 240.33 76.4004H241.33V85.9904H239.82V78.6904H238.66L238.68 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M244.1 77.6104C244.479 77.6602 244.863 77.5624 245.172 77.3372C245.481 77.1119 245.692 76.7766 245.76 76.4004H246.76V85.9904H245.25V78.6904H244.08L244.1 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M249.53 77.6104C249.909 77.6577 250.291 77.5589 250.6 77.3341C250.908 77.1093 251.119 76.7754 251.19 76.4004H252.19V85.9904H250.69V78.6904H249.53V77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M254.95 77.6104C255.329 77.6602 255.713 77.5624 256.022 77.3372C256.331 77.1119 256.541 76.7766 256.61 76.4004H257.61V85.9904H256.1V78.6904H254.93L254.95 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M260.38 77.6104C260.759 77.6577 261.141 77.5589 261.45 77.3341C261.758 77.1093 261.969 76.7754 262.04 76.4004H263.04V85.9904H261.59V78.6904H260.43L260.38 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M265.81 77.6104C266.188 77.66 266.571 77.5618 266.878 77.3364C267.185 77.111 267.394 76.7757 267.46 76.4004H268.46V85.9904H266.95V78.6904H265.79L265.81 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M272.3 78.6904C272.3 78.083 272.541 77.5006 272.971 77.0711C273.4 76.6416 273.983 76.4004 274.59 76.4004C275.197 76.4004 275.78 76.6416 276.209 77.0711C276.639 77.5006 276.88 78.083 276.88 78.6904V83.6904C276.88 84.2977 276.639 84.8802 276.209 85.3097C275.78 85.7391 275.197 85.9804 274.59 85.9804C273.983 85.9804 273.4 85.7391 272.971 85.3097C272.541 84.8802 272.3 84.2977 272.3 83.6904V78.6904ZM273.81 83.7804C273.81 84.4604 274.11 84.7204 274.59 84.7204C275.07 84.7204 275.37 84.4604 275.37 83.7804V78.6004C275.37 77.9104 275.07 77.6504 274.59 77.6504C274.11 77.6504 273.81 77.9104 273.81 78.6004V83.7804Z"
        fill="#1B2236"
      />
      <path
        d="M278.78 77.6104C279.159 77.6602 279.543 77.5624 279.852 77.3372C280.161 77.1119 280.372 76.7766 280.44 76.4004H281.44V85.9904H279.94V78.6904H278.77L278.78 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M284.21 77.6104C284.588 77.66 284.97 77.5618 285.278 77.3364C285.585 77.111 285.794 76.7757 285.86 76.4004H286.86V85.9904H285.35V78.6904H284.19L284.21 77.6104Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 95.5898C24.1198 94.9825 24.3611 94.4 24.7905 93.9705C25.22 93.5411 25.8024 93.2998 26.4098 93.2998C27.0171 93.2998 27.5996 93.5411 28.0291 93.9705C28.4585 94.4 28.6998 94.9825 28.6998 95.5898V100.59C28.6998 101.197 28.4585 101.78 28.0291 102.209C27.5996 102.639 27.0171 102.88 26.4098 102.88C25.8024 102.88 25.22 102.639 24.7905 102.209C24.3611 101.78 24.1198 101.197 24.1198 100.59V95.5898ZM25.5898 100.69C25.5898 101.37 25.8898 101.63 26.3698 101.63C26.8498 101.63 27.1498 101.37 27.1498 100.69V95.5098C27.1498 94.8198 26.8498 94.5598 26.3698 94.5598C25.8898 94.5598 25.5898 94.8198 25.5898 95.5098V100.69Z"
        fill="#1B2236"
      />
      <path
        d="M29.5901 95.5897C29.5694 95.2769 29.6131 94.9632 29.7186 94.668C29.8241 94.3728 29.989 94.1024 30.2032 93.8736C30.4174 93.6447 30.6764 93.4623 30.9639 93.3375C31.2515 93.2128 31.5616 93.1484 31.8751 93.1484C32.1886 93.1484 32.4987 93.2128 32.7863 93.3375C33.0739 93.4623 33.3328 93.6447 33.547 93.8736C33.7612 94.1024 33.9262 94.3728 34.0316 94.668C34.1371 94.9632 34.1808 95.2769 34.1601 95.5897V100.59C34.1808 100.902 34.1371 101.216 34.0316 101.511C33.9262 101.807 33.7612 102.077 33.547 102.306C33.3328 102.535 33.0739 102.717 32.7863 102.842C32.4987 102.967 32.1886 103.031 31.8751 103.031C31.5616 103.031 31.2515 102.967 30.9639 102.842C30.6764 102.717 30.4174 102.535 30.2032 102.306C29.989 102.077 29.8241 101.807 29.7186 101.511C29.6131 101.216 29.5694 100.902 29.5901 100.59V95.5897ZM31.0901 100.68C31.0901 101.36 31.4001 101.62 31.8801 101.62C32.3601 101.62 32.6601 101.36 32.6601 100.68V95.4997C32.6601 94.8097 32.3501 94.5497 31.8801 94.5497C31.4101 94.5497 31.0901 94.8097 31.0901 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 95.5898C34.9699 94.9825 35.2111 94.4 35.6406 93.9705C36.0701 93.5411 36.6525 93.2998 37.2599 93.2998C37.8672 93.2998 38.4497 93.5411 38.8792 93.9705C39.3086 94.4 39.5499 94.9825 39.5499 95.5898V100.59C39.5499 101.197 39.3086 101.78 38.8792 102.209C38.4497 102.639 37.8672 102.88 37.2599 102.88C36.6525 102.88 36.0701 102.639 35.6406 102.209C35.2111 101.78 34.9699 101.197 34.9699 100.59V95.5898ZM36.4799 100.68C36.4799 101.36 36.7799 101.62 37.2599 101.62C37.7399 101.62 38.0399 101.36 38.0399 100.68V95.4998C38.0399 94.8098 37.7399 94.5498 37.2599 94.5498C36.7799 94.5498 36.4799 94.8098 36.4799 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 94.5C41.8292 94.5499 42.2129 94.452 42.5219 94.2268C42.8309 94.0016 43.0415 93.6662 43.1101 93.29H44.1101V102.88H42.5901V95.57H41.4201L41.4501 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M49.0001 94.5C49.3792 94.5499 49.763 94.452 50.072 94.2268C50.381 94.0016 50.5915 93.6662 50.6601 93.29H51.6601V102.88H50.1501V95.57H48.9801L49.0001 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M54.4298 94.5C54.8085 94.5474 55.1911 94.4486 55.4995 94.2238C55.8079 93.999 56.019 93.6651 56.0898 93.29H57.0898V102.88H55.5898V95.57H54.4298V94.5Z"
        fill="#1B2236"
      />
      <path
        d="M59.86 94.5C60.2379 94.5496 60.6204 94.4515 60.9278 94.2261C61.2352 94.0007 61.4437 93.6654 61.51 93.29H62.51V102.88H61V95.57H59.84L59.86 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M65.2802 94.5C65.6593 94.5499 66.043 94.452 66.352 94.2268C66.661 94.0016 66.8716 93.6662 66.9402 93.29H67.9402V102.88H66.4402V95.57H65.2702L65.2802 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M70.7099 94.5C71.0886 94.5474 71.4711 94.4486 71.7795 94.2238C72.0879 93.999 72.299 93.6651 72.3699 93.29H73.3699V102.88H71.8599V95.57H70.6999L70.7099 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M77.1999 95.5898C77.1999 94.9825 77.4411 94.4 77.8706 93.9705C78.3 93.5411 78.8825 93.2998 79.4899 93.2998C80.0972 93.2998 80.6797 93.5411 81.1091 93.9705C81.5386 94.4 81.7799 94.9825 81.7799 95.5898V100.59C81.7799 101.197 81.5386 101.78 81.1091 102.209C80.6797 102.639 80.0972 102.88 79.4899 102.88C78.8825 102.88 78.3 102.639 77.8706 102.209C77.4411 101.78 77.1999 101.197 77.1999 100.59V95.5898ZM78.7099 100.68C78.7099 101.36 79.0099 101.62 79.4899 101.62C79.9699 101.62 80.2699 101.36 80.2699 100.68V95.4998C80.2699 94.8098 79.9699 94.5498 79.4899 94.5498C79.0099 94.5498 78.7099 94.8098 78.7099 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M82.5901 95.5897C82.5694 95.2769 82.6131 94.9632 82.7186 94.668C82.8241 94.3728 82.989 94.1024 83.2032 93.8736C83.4174 93.6447 83.6764 93.4623 83.9639 93.3375C84.2515 93.2128 84.5616 93.1484 84.8751 93.1484C85.1886 93.1484 85.4987 93.2128 85.7863 93.3375C86.0739 93.4623 86.3328 93.6447 86.547 93.8736C86.7612 94.1024 86.9262 94.3728 87.0316 94.668C87.1371 94.9632 87.1808 95.2769 87.1601 95.5897V100.59C87.1808 100.902 87.1371 101.216 87.0316 101.511C86.9262 101.807 86.7612 102.077 86.547 102.306C86.3328 102.535 86.0739 102.717 85.7863 102.842C85.4987 102.967 85.1886 103.031 84.8751 103.031C84.5616 103.031 84.2515 102.967 83.9639 102.842C83.6764 102.717 83.4174 102.535 83.2032 102.306C82.989 102.077 82.8241 101.807 82.7186 101.511C82.6131 101.216 82.5694 100.902 82.5901 100.59V95.5897ZM84.0901 100.68C84.0901 101.36 84.4001 101.62 84.8801 101.62C85.3601 101.62 85.6601 101.36 85.6601 100.68V95.4997C85.6601 94.8097 85.3601 94.5497 84.8801 94.5497C84.4001 94.5497 84.0901 94.8097 84.0901 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M88.05 95.5898C88.05 94.9825 88.2912 94.4 88.7207 93.9705C89.1501 93.5411 89.7326 93.2998 90.34 93.2998C90.9473 93.2998 91.5298 93.5411 91.9592 93.9705C92.3887 94.4 92.6299 94.9825 92.6299 95.5898V100.59C92.6299 101.197 92.3887 101.78 91.9592 102.209C91.5298 102.639 90.9473 102.88 90.34 102.88C89.7326 102.88 89.1501 102.639 88.7207 102.209C88.2912 101.78 88.05 101.197 88.05 100.59V95.5898ZM89.59 100.69C89.59 101.37 89.8899 101.63 90.3699 101.63C90.8499 101.63 91.1499 101.37 91.1499 100.69V95.5098C91.1499 94.8198 90.8499 94.5598 90.3699 94.5598C89.8899 94.5598 89.59 94.8198 89.59 95.5098V100.69Z"
        fill="#1B2236"
      />
      <path
        d="M93.4801 95.5898C93.4801 94.9825 93.7214 94.4 94.1509 93.9705C94.5803 93.5411 95.1628 93.2998 95.7701 93.2998C96.3775 93.2998 96.9599 93.5411 97.3894 93.9705C97.8189 94.4 98.0601 94.9825 98.0601 95.5898V100.59C98.0601 101.197 97.8189 101.78 97.3894 102.209C96.9599 102.639 96.3775 102.88 95.7701 102.88C95.1628 102.88 94.5803 102.639 94.1509 102.209C93.7214 101.78 93.4801 101.197 93.4801 100.59V95.5898ZM94.9901 100.69C94.9901 101.37 95.2901 101.63 95.7701 101.63C96.2501 101.63 96.5501 101.37 96.5501 100.69V95.5098C96.5501 94.8198 96.2501 94.5598 95.7701 94.5598C95.2901 94.5598 94.9901 94.8198 94.9901 95.5098V100.69Z"
        fill="#1B2236"
      />
      <path
        d="M98.9099 95.5897C98.8892 95.2769 98.933 94.9632 99.0384 94.668C99.1439 94.3728 99.3088 94.1024 99.523 93.8736C99.7372 93.6447 99.9962 93.4623 100.284 93.3375C100.571 93.2128 100.881 93.1484 101.195 93.1484C101.508 93.1484 101.819 93.2128 102.106 93.3375C102.394 93.4623 102.653 93.6447 102.867 93.8736C103.081 94.1024 103.246 94.3728 103.351 94.668C103.457 94.9632 103.501 95.2769 103.48 95.5897V100.59C103.501 100.902 103.457 101.216 103.351 101.511C103.246 101.807 103.081 102.077 102.867 102.306C102.653 102.535 102.394 102.717 102.106 102.842C101.819 102.967 101.508 103.031 101.195 103.031C100.881 103.031 100.571 102.967 100.284 102.842C99.9962 102.717 99.7372 102.535 99.523 102.306C99.3088 102.077 99.1439 101.807 99.0384 101.511C98.933 101.216 98.8892 100.902 98.9099 100.59V95.5897ZM100.41 100.68C100.41 101.36 100.71 101.62 101.19 101.62C101.67 101.62 101.97 101.36 101.97 100.68V95.4997C101.97 94.8097 101.67 94.5497 101.19 94.5497C100.71 94.5497 100.41 94.8097 100.41 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M109.59 94.5C109.969 94.5499 110.353 94.452 110.662 94.2268C110.971 94.0016 111.181 93.6662 111.25 93.29H112.25V102.88H110.74V95.57H109.59V94.5Z"
        fill="#1B2236"
      />
      <path
        d="M115.06 94.5C115.439 94.5474 115.821 94.4486 116.13 94.2238C116.438 93.999 116.649 93.6651 116.72 93.29H117.72V102.88H116.21V95.57H115.05L115.06 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M120.49 94.5C120.868 94.5496 121.25 94.4515 121.558 94.2261C121.865 94.0007 122.074 93.6654 122.14 93.29H123.14V102.88H121.63V95.57H120.49V94.5Z"
        fill="#1B2236"
      />
      <path
        d="M125.91 94.5C126.292 94.5554 126.681 94.4602 126.994 94.2345C127.308 94.0087 127.521 93.6701 127.59 93.29H128.59V102.88H127.09V95.57H125.92L125.91 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M131.34 94.5C131.719 94.5474 132.101 94.4486 132.41 94.2238C132.718 93.999 132.929 93.6651 133 93.29H134V102.88H132.49V95.57H131.33L131.34 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M136.76 94.5C137.139 94.5499 137.523 94.452 137.832 94.2268C138.141 94.0016 138.352 93.6662 138.42 93.29H139.42V102.88H137.92V95.57H136.75L136.76 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M141.13 95.5898C141.13 94.9825 141.371 94.4 141.801 93.9705C142.23 93.5411 142.813 93.2998 143.42 93.2998C144.027 93.2998 144.61 93.5411 145.039 93.9705C145.469 94.4 145.71 94.9825 145.71 95.5898V100.59C145.71 101.197 145.469 101.78 145.039 102.209C144.61 102.639 144.027 102.88 143.42 102.88C142.813 102.88 142.23 102.639 141.801 102.209C141.371 101.78 141.13 101.197 141.13 100.59V95.5898ZM142.64 100.68C142.64 101.36 142.94 101.62 143.42 101.62C143.9 101.62 144.2 101.36 144.2 100.68V95.4998C144.2 94.8098 143.9 94.5498 143.42 94.5498C142.94 94.5498 142.64 94.8098 142.64 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M146.59 95.5898C146.59 94.9825 146.831 94.4 147.261 93.9705C147.69 93.5411 148.273 93.2998 148.88 93.2998C149.487 93.2998 150.07 93.5411 150.499 93.9705C150.929 94.4 151.17 94.9825 151.17 95.5898V100.59C151.17 101.197 150.929 101.78 150.499 102.209C150.07 102.639 149.487 102.88 148.88 102.88C148.273 102.88 147.69 102.639 147.261 102.209C146.831 101.78 146.59 101.197 146.59 100.59V95.5898ZM148.1 100.68C148.1 101.36 148.4 101.62 148.88 101.62C149.36 101.62 149.66 101.36 149.66 100.68V95.4998C149.66 94.8098 149.36 94.5498 148.88 94.5498C148.4 94.5498 148.1 94.8098 148.1 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M151.99 95.5897C151.969 95.2769 152.013 94.9632 152.119 94.668C152.224 94.3728 152.389 94.1024 152.603 93.8736C152.817 93.6447 153.076 93.4623 153.364 93.3375C153.651 93.2128 153.962 93.1484 154.275 93.1484C154.588 93.1484 154.899 93.2128 155.186 93.3375C155.474 93.4623 155.733 93.6447 155.947 93.8736C156.161 94.1024 156.326 94.3728 156.432 94.668C156.537 94.9632 156.581 95.2769 156.56 95.5897V100.59C156.581 100.902 156.537 101.216 156.432 101.511C156.326 101.807 156.161 102.077 155.947 102.306C155.733 102.535 155.474 102.717 155.186 102.842C154.899 102.967 154.588 103.031 154.275 103.031C153.962 103.031 153.651 102.967 153.364 102.842C153.076 102.717 152.817 102.535 152.603 102.306C152.389 102.077 152.224 101.807 152.119 101.511C152.013 101.216 151.969 100.902 151.99 100.59V95.5897ZM153.49 100.68C153.49 101.36 153.79 101.62 154.27 101.62C154.75 101.62 155.05 101.36 155.05 100.68V95.4997C155.05 94.8097 154.75 94.5497 154.27 94.5497C153.79 94.5497 153.49 94.8097 153.49 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M157.41 95.5898C157.41 94.9825 157.651 94.4 158.081 93.9705C158.51 93.5411 159.092 93.2998 159.7 93.2998C160.307 93.2998 160.89 93.5411 161.319 93.9705C161.749 94.4 161.99 94.9825 161.99 95.5898V100.59C161.99 101.197 161.749 101.78 161.319 102.209C160.89 102.639 160.307 102.88 159.7 102.88C159.092 102.88 158.51 102.639 158.081 102.209C157.651 101.78 157.41 101.197 157.41 100.59V95.5898ZM158.92 100.68C158.92 101.36 159.22 101.62 159.7 101.62C160.18 101.62 160.48 101.36 160.48 100.68V95.4998C160.48 94.8098 160.18 94.5498 159.7 94.5498C159.22 94.5498 158.92 94.8098 158.92 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M162.84 95.5897C162.819 95.2769 162.863 94.9632 162.969 94.668C163.074 94.3728 163.239 94.1024 163.453 93.8736C163.667 93.6447 163.926 93.4623 164.214 93.3375C164.502 93.2128 164.812 93.1484 165.125 93.1484C165.439 93.1484 165.749 93.2128 166.036 93.3375C166.324 93.4623 166.583 93.6447 166.797 93.8736C167.011 94.1024 167.176 94.3728 167.282 94.668C167.387 94.9632 167.431 95.2769 167.41 95.5897V100.59C167.431 100.902 167.387 101.216 167.282 101.511C167.176 101.807 167.011 102.077 166.797 102.306C166.583 102.535 166.324 102.717 166.036 102.842C165.749 102.967 165.439 103.031 165.125 103.031C164.812 103.031 164.502 102.967 164.214 102.842C163.926 102.717 163.667 102.535 163.453 102.306C163.239 102.077 163.074 101.807 162.969 101.511C162.863 101.216 162.819 100.902 162.84 100.59V95.5897ZM164.34 100.68C164.34 101.36 164.65 101.62 165.13 101.62C165.61 101.62 165.91 101.36 165.91 100.68V95.4997C165.91 94.8097 165.6 94.5497 165.13 94.5497C164.66 94.5497 164.34 94.8097 164.34 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M168.26 95.5898C168.26 94.9825 168.501 94.4 168.931 93.9705C169.36 93.5411 169.943 93.2998 170.55 93.2998C171.157 93.2998 171.74 93.5411 172.169 93.9705C172.599 94.4 172.84 94.9825 172.84 95.5898V100.59C172.84 101.197 172.599 101.78 172.169 102.209C171.74 102.639 171.157 102.88 170.55 102.88C169.943 102.88 169.36 102.639 168.931 102.209C168.501 101.78 168.26 101.197 168.26 100.59V95.5898ZM169.77 100.68C169.77 101.36 170.07 101.62 170.55 101.62C171.03 101.62 171.33 101.36 171.33 100.68V95.4998C171.33 94.8098 171.03 94.5498 170.55 94.5498C170.07 94.5498 169.77 94.8098 169.77 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M173.69 95.5897C173.67 95.2769 173.713 94.9632 173.819 94.668C173.924 94.3728 174.089 94.1024 174.303 93.8736C174.518 93.6447 174.776 93.4623 175.064 93.3375C175.352 93.2128 175.662 93.1484 175.975 93.1484C176.289 93.1484 176.599 93.2128 176.886 93.3375C177.174 93.4623 177.433 93.6447 177.647 93.8736C177.861 94.1024 178.026 94.3728 178.132 94.668C178.237 94.9632 178.281 95.2769 178.26 95.5897V100.59C178.281 100.902 178.237 101.216 178.132 101.511C178.026 101.807 177.861 102.077 177.647 102.306C177.433 102.535 177.174 102.717 176.886 102.842C176.599 102.967 176.289 103.031 175.975 103.031C175.662 103.031 175.352 102.967 175.064 102.842C174.776 102.717 174.518 102.535 174.303 102.306C174.089 102.077 173.924 101.807 173.819 101.511C173.713 101.216 173.67 100.902 173.69 100.59V95.5897ZM175.2 100.68C175.2 101.36 175.5 101.62 175.98 101.62C176.46 101.62 176.76 101.36 176.76 100.68V95.4997C176.76 94.8097 176.46 94.5497 175.98 94.5497C175.5 94.5497 175.2 94.8097 175.2 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M182.29 94.5C182.669 94.5499 183.053 94.452 183.362 94.2268C183.671 94.0016 183.882 93.6662 183.95 93.29H184.95V102.88H183.44V95.57H182.29V94.5Z"
        fill="#1B2236"
      />
      <path
        d="M187.72 94.5C188.099 94.5474 188.481 94.4486 188.79 94.2238C189.098 93.999 189.309 93.6651 189.38 93.29H190.38V102.88H188.87V95.57H187.71L187.72 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M193.15 94.5C193.528 94.5496 193.91 94.4515 194.218 94.2261C194.525 94.0007 194.734 93.6654 194.8 93.29H195.8V102.88H194.29V95.57H193.13L193.15 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M198.59 94.5C198.969 94.5499 199.353 94.452 199.662 94.2268C199.971 94.0016 200.181 93.6662 200.25 93.29H201.25V102.88H199.75V95.57H198.59V94.5Z"
        fill="#1B2236"
      />
      <path
        d="M204 94.5C204.379 94.5474 204.761 94.4486 205.07 94.2238C205.378 93.999 205.589 93.6651 205.66 93.29H206.66V102.88H205.15V95.57H203.99L204 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M212.59 95.5897C212.569 95.2769 212.613 94.9632 212.719 94.668C212.824 94.3728 212.989 94.1024 213.203 93.8736C213.417 93.6447 213.676 93.4623 213.964 93.3375C214.252 93.2128 214.562 93.1484 214.875 93.1484C215.189 93.1484 215.499 93.2128 215.786 93.3375C216.074 93.4623 216.333 93.6447 216.547 93.8736C216.761 94.1024 216.926 94.3728 217.032 94.668C217.137 94.9632 217.181 95.2769 217.16 95.5897V100.59C217.181 100.902 217.137 101.216 217.032 101.511C216.926 101.807 216.761 102.077 216.547 102.306C216.333 102.535 216.074 102.717 215.786 102.842C215.499 102.967 215.189 103.031 214.875 103.031C214.562 103.031 214.252 102.967 213.964 102.842C213.676 102.717 213.417 102.535 213.203 102.306C212.989 102.077 212.824 101.807 212.719 101.511C212.613 101.216 212.569 100.902 212.59 100.59V95.5897ZM214.09 100.68C214.09 101.36 214.39 101.62 214.87 101.62C215.35 101.62 215.65 101.36 215.65 100.68V95.4997C215.65 94.8097 215.35 94.5497 214.87 94.5497C214.39 94.5497 214.09 94.8097 214.09 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M218.04 95.5898C218.04 94.9825 218.281 94.4 218.711 93.9705C219.14 93.5411 219.723 93.2998 220.33 93.2998C220.938 93.2998 221.52 93.5411 221.949 93.9705C222.379 94.4 222.62 94.9825 222.62 95.5898V100.59C222.62 101.197 222.379 101.78 221.949 102.209C221.52 102.639 220.938 102.88 220.33 102.88C219.723 102.88 219.14 102.639 218.711 102.209C218.281 101.78 218.04 101.197 218.04 100.59V95.5898ZM219.59 100.69C219.59 101.37 219.89 101.63 220.37 101.63C220.85 101.63 221.15 101.37 221.15 100.69V95.5098C221.15 94.8198 220.85 94.5598 220.37 94.5598C219.89 94.5598 219.59 94.8198 219.59 95.5098V100.69Z"
        fill="#1B2236"
      />
      <path
        d="M223.47 95.5897C223.449 95.2769 223.493 94.9632 223.598 94.668C223.704 94.3728 223.869 94.1024 224.083 93.8736C224.297 93.6447 224.556 93.4623 224.844 93.3375C225.131 93.2128 225.442 93.1484 225.755 93.1484C226.068 93.1484 226.379 93.2128 226.666 93.3375C226.954 93.4623 227.213 93.6447 227.427 93.8736C227.641 94.1024 227.806 94.3728 227.911 94.668C228.017 94.9632 228.061 95.2769 228.04 95.5897V100.59C228.061 100.902 228.017 101.216 227.911 101.511C227.806 101.807 227.641 102.077 227.427 102.306C227.213 102.535 226.954 102.717 226.666 102.842C226.379 102.967 226.068 103.031 225.755 103.031C225.442 103.031 225.131 102.967 224.844 102.842C224.556 102.717 224.297 102.535 224.083 102.306C223.869 102.077 223.704 101.807 223.598 101.511C223.493 101.216 223.449 100.902 223.47 100.59V95.5897ZM224.97 100.68C224.97 101.36 225.28 101.62 225.76 101.62C226.24 101.62 226.54 101.36 226.54 100.68V95.4997C226.54 94.8097 226.23 94.5497 225.76 94.5497C225.29 94.5497 224.97 94.8097 224.97 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M228.89 95.5898C228.89 94.9825 229.131 94.4 229.561 93.9705C229.99 93.5411 230.572 93.2998 231.18 93.2998C231.787 93.2998 232.37 93.5411 232.799 93.9705C233.229 94.4 233.47 94.9825 233.47 95.5898V100.59C233.47 101.197 233.229 101.78 232.799 102.209C232.37 102.639 231.787 102.88 231.18 102.88C230.572 102.88 229.99 102.639 229.561 102.209C229.131 101.78 228.89 101.197 228.89 100.59V95.5898ZM230.4 100.68C230.4 101.36 230.7 101.62 231.18 101.62C231.66 101.62 231.96 101.36 231.96 100.68V95.4998C231.96 94.8098 231.66 94.5498 231.18 94.5498C230.7 94.5498 230.4 94.8098 230.4 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M234.32 95.5898C234.32 95.2891 234.379 94.9913 234.494 94.7135C234.609 94.4356 234.778 94.1832 234.991 93.9705C235.203 93.7579 235.456 93.5892 235.734 93.4741C236.011 93.359 236.309 93.2998 236.61 93.2998C236.911 93.2998 237.208 93.359 237.486 93.4741C237.764 93.5892 238.017 93.7579 238.229 93.9705C238.442 94.1832 238.611 94.4356 238.726 94.7135C238.841 94.9913 238.9 95.2891 238.9 95.5898V100.59C238.9 100.891 238.841 101.188 238.726 101.466C238.611 101.744 238.442 101.996 238.229 102.209C238.017 102.422 237.764 102.59 237.486 102.705C237.208 102.821 236.911 102.88 236.61 102.88C236.309 102.88 236.011 102.821 235.734 102.705C235.456 102.59 235.203 102.422 234.991 102.209C234.778 101.996 234.609 101.744 234.494 101.466C234.379 101.188 234.32 100.891 234.32 100.59V95.5898ZM235.83 100.68C235.83 101.36 236.13 101.62 236.61 101.62C237.09 101.62 237.39 101.36 237.39 100.68V95.4998C237.39 94.8098 237.09 94.5498 236.61 94.5498C236.13 94.5498 235.83 94.8098 235.83 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M239.75 95.5897C239.729 95.2769 239.773 94.9632 239.878 94.668C239.984 94.3728 240.149 94.1024 240.363 93.8736C240.577 93.6447 240.836 93.4623 241.124 93.3375C241.411 93.2128 241.721 93.1484 242.035 93.1484C242.348 93.1484 242.658 93.2128 242.946 93.3375C243.234 93.4623 243.492 93.6447 243.707 93.8736C243.921 94.1024 244.086 94.3728 244.191 94.668C244.297 94.9632 244.34 95.2769 244.32 95.5897V100.59C244.34 100.902 244.297 101.216 244.191 101.511C244.086 101.807 243.921 102.077 243.707 102.306C243.492 102.535 243.234 102.717 242.946 102.842C242.658 102.967 242.348 103.031 242.035 103.031C241.721 103.031 241.411 102.967 241.124 102.842C240.836 102.717 240.577 102.535 240.363 102.306C240.149 102.077 239.984 101.807 239.878 101.511C239.773 101.216 239.729 100.902 239.75 100.59V95.5897ZM241.25 100.68C241.25 101.36 241.55 101.62 242.03 101.62C242.51 101.62 242.81 101.36 242.81 100.68V95.4997C242.81 94.8097 242.51 94.5497 242.03 94.5497C241.55 94.5497 241.25 94.8097 241.25 95.4997V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M249.42 95.5898C249.42 94.9825 249.661 94.4 250.091 93.9705C250.52 93.5411 251.103 93.2998 251.71 93.2998C252.317 93.2998 252.9 93.5411 253.329 93.9705C253.759 94.4 254 94.9825 254 95.5898V100.59C254 101.197 253.759 101.78 253.329 102.209C252.9 102.639 252.317 102.88 251.71 102.88C251.103 102.88 250.52 102.639 250.091 102.209C249.661 101.78 249.42 101.197 249.42 100.59V95.5898ZM250.93 100.68C250.93 101.36 251.23 101.62 251.71 101.62C252.19 101.62 252.49 101.36 252.49 100.68V95.4998C252.49 94.8098 252.19 94.5498 251.71 94.5498C251.23 94.5498 250.93 94.8098 250.93 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M255.9 94.5C256.283 94.5556 256.674 94.4608 256.989 94.2352C257.304 94.0096 257.519 93.6709 257.59 93.29H258.59V102.88H257.08V95.57H255.92L255.9 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M260.27 95.5898C260.27 94.9825 260.511 94.4 260.941 93.9705C261.37 93.5411 261.953 93.2998 262.56 93.2998C263.168 93.2998 263.75 93.5411 264.179 93.9705C264.609 94.4 264.85 94.9825 264.85 95.5898V100.59C264.85 101.197 264.609 101.78 264.179 102.209C263.75 102.639 263.168 102.88 262.56 102.88C261.953 102.88 261.37 102.639 260.941 102.209C260.511 101.78 260.27 101.197 260.27 100.59V95.5898ZM261.78 100.68C261.78 101.36 262.08 101.62 262.56 101.62C263.04 101.62 263.34 101.36 263.34 100.68V95.4998C263.34 94.8098 263.04 94.5498 262.56 94.5498C262.08 94.5498 261.78 94.8098 261.78 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M266.75 94.5C267.129 94.5499 267.513 94.452 267.822 94.2268C268.131 94.0016 268.341 93.6662 268.41 93.29H269.41V102.88H267.91V95.57H266.74L266.75 94.5Z"
        fill="#1B2236"
      />
      <path
        d="M271.12 95.5898C271.12 94.9825 271.361 94.4 271.79 93.9705C272.22 93.5411 272.802 93.2998 273.41 93.2998C274.017 93.2998 274.6 93.5411 275.029 93.9705C275.459 94.4 275.7 94.9825 275.7 95.5898V100.59C275.7 101.197 275.459 101.78 275.029 102.209C274.6 102.639 274.017 102.88 273.41 102.88C272.802 102.88 272.22 102.639 271.79 102.209C271.361 101.78 271.12 101.197 271.12 100.59V95.5898ZM272.59 100.69C272.59 101.37 272.89 101.63 273.37 101.63C273.85 101.63 274.15 101.37 274.15 100.69V95.5098C274.15 94.8198 273.85 94.5598 273.37 94.5598C272.89 94.5598 272.59 94.8198 272.59 95.5098V100.69Z"
        fill="#1B2236"
      />
      <path
        d="M277.59 94.5C277.969 94.5499 278.353 94.452 278.662 94.2268C278.971 94.0016 279.181 93.6662 279.25 93.29H280.25V102.88H278.75V95.57H277.59V94.5Z"
        fill="#1B2236"
      />
      <path
        d="M281.97 95.5898C281.97 94.9825 282.211 94.4 282.641 93.9705C283.07 93.5411 283.653 93.2998 284.26 93.2998C284.867 93.2998 285.45 93.5411 285.879 93.9705C286.309 94.4 286.55 94.9825 286.55 95.5898V100.59C286.55 101.197 286.309 101.78 285.879 102.209C285.45 102.639 284.867 102.88 284.26 102.88C283.653 102.88 283.07 102.639 282.641 102.209C282.211 101.78 281.97 101.197 281.97 100.59V95.5898ZM283.48 100.68C283.48 101.36 283.78 101.62 284.26 101.62C284.74 101.62 285.04 101.36 285.04 100.68V95.4998C285.04 94.8098 284.74 94.5498 284.26 94.5498C283.78 94.5498 283.48 94.8098 283.48 95.4998V100.68Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 112.04C24.1198 111.433 24.3611 110.85 24.7905 110.421C25.22 109.991 25.8024 109.75 26.4098 109.75C27.0171 109.75 27.5996 109.991 28.0291 110.421C28.4585 110.85 28.6998 111.433 28.6998 112.04V117.04C28.6998 117.647 28.4585 118.23 28.0291 118.659C27.5996 119.089 27.0171 119.33 26.4098 119.33C25.8024 119.33 25.22 119.089 24.7905 118.659C24.3611 118.23 24.1198 117.647 24.1198 117.04V112.04ZM25.6298 117.12C25.6298 117.8 25.9298 118.06 26.4098 118.06C26.8898 118.06 27.1898 117.8 27.1898 117.12V111.94C27.1898 111.26 26.8898 110.99 26.4098 110.99C25.9298 110.99 25.6298 111.26 25.6298 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M30.59 110.94C30.9691 110.99 31.3528 110.892 31.6618 110.667C31.9708 110.442 32.1814 110.107 32.25 109.73H33.25V119.32H31.75V112.01H30.59V110.94Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 112.04C34.9699 111.433 35.2111 110.85 35.6406 110.421C36.0701 109.991 36.6525 109.75 37.2599 109.75C37.8672 109.75 38.4497 109.991 38.8792 110.421C39.3086 110.85 39.5499 111.433 39.5499 112.04V117.04C39.5499 117.647 39.3086 118.23 38.8792 118.659C38.4497 119.089 37.8672 119.33 37.2599 119.33C36.6525 119.33 36.0701 119.089 35.6406 118.659C35.2111 118.23 34.9699 117.647 34.9699 117.04V112.04ZM36.4799 117.12C36.4799 117.8 36.7799 118.06 37.2599 118.06C37.7399 118.06 38.0399 117.8 38.0399 117.12V111.94C38.0399 111.26 37.7399 110.99 37.2599 110.99C36.7799 110.99 36.4799 111.26 36.4799 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 110.94C41.8292 110.99 42.2129 110.892 42.5219 110.667C42.8309 110.442 43.0415 110.107 43.1101 109.73H44.1101V119.32H42.5901V112.01H41.4201L41.4501 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M45.8299 112.04C45.8092 111.727 45.8529 111.413 45.9583 111.118C46.0638 110.823 46.2287 110.553 46.443 110.324C46.6572 110.095 46.9161 109.912 47.2037 109.788C47.4913 109.663 47.8014 109.599 48.1148 109.599C48.4283 109.599 48.7385 109.663 49.026 109.788C49.3136 109.912 49.5725 110.095 49.7868 110.324C50.001 110.553 50.1659 110.823 50.2714 111.118C50.3768 111.413 50.4206 111.727 50.3999 112.04V117.04C50.4206 117.353 50.3768 117.666 50.2714 117.962C50.1659 118.257 50.001 118.527 49.7868 118.756C49.5725 118.985 49.3136 119.167 49.026 119.292C48.7385 119.417 48.4283 119.481 48.1148 119.481C47.8014 119.481 47.4913 119.417 47.2037 119.292C46.9161 119.167 46.6572 118.985 46.443 118.756C46.2287 118.527 46.0638 118.257 45.9583 117.962C45.8529 117.666 45.8092 117.353 45.8299 117.04V112.04ZM47.3299 117.12C47.3299 117.8 47.6299 118.06 48.1099 118.06C48.5899 118.06 48.8899 117.8 48.8899 117.12V111.94C48.8899 111.26 48.5899 110.99 48.1099 110.99C47.6299 110.99 47.3299 111.26 47.3299 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M52.3102 110.94C52.6881 110.99 53.0706 110.892 53.378 110.667C53.6853 110.441 53.8939 110.106 53.9602 109.73H54.9602V119.32H53.4502V112.01H52.2902L52.3102 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M57.7299 110.94C58.1149 110.989 58.5037 110.886 58.8137 110.653C59.1237 110.419 59.3305 110.074 59.3899 109.69H60.3899V119.28H58.8899V111.97H57.7199L57.7299 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M63.1601 110.94C63.5388 110.988 63.9213 110.889 64.2297 110.664C64.5381 110.439 64.7492 110.105 64.8201 109.73H65.8201V119.32H64.3101V112.01H63.1501L63.1601 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M68.59 110.94C68.9691 110.99 69.3528 110.892 69.6618 110.667C69.9708 110.442 70.1814 110.107 70.25 109.73H71.25V119.32H69.74V112.01H68.59V110.94Z"
        fill="#1B2236"
      />
      <path
        d="M74.0101 110.94C74.3889 110.988 74.7714 110.889 75.0798 110.664C75.3882 110.439 75.5993 110.105 75.6702 109.73H76.6702V119.32H75.1602V112.01H74.0002L74.0101 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M79.4398 110.94C79.8237 110.989 80.2113 110.886 80.5197 110.652C80.8281 110.418 81.0328 110.073 81.0898 109.69H82.0898V119.28H80.5898V111.97H79.4298L79.4398 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M84.8598 110.94C85.2389 110.99 85.6226 110.892 85.9316 110.667C86.2406 110.442 86.4512 110.107 86.5198 109.73H87.5198V119.32H86.0198V112.01H84.8498L84.8598 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M89.2301 112.04C89.2301 111.433 89.4714 110.85 89.9009 110.421C90.3303 109.991 90.9128 109.75 91.5201 109.75C92.1275 109.75 92.7099 109.991 93.1394 110.421C93.5689 110.85 93.8101 111.433 93.8101 112.04V117.04C93.8101 117.647 93.5689 118.23 93.1394 118.659C92.7099 119.089 92.1275 119.33 91.5201 119.33C90.9128 119.33 90.3303 119.089 89.9009 118.659C89.4714 118.23 89.2301 117.647 89.2301 117.04V112.04ZM90.7401 117.12C90.7401 117.8 91.0401 118.06 91.5201 118.06C92.0001 118.06 92.3001 117.8 92.3001 117.12V111.94C92.3001 111.26 92.0001 110.99 91.5201 110.99C91.0401 110.99 90.7401 111.26 90.7401 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M95.7099 110.94C96.089 110.99 96.4727 110.892 96.7817 110.667C97.0907 110.442 97.3013 110.107 97.3699 109.73H98.3699V119.32H96.8699V112.01H95.6999L95.7099 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M103.26 110.94C103.639 110.99 104.023 110.892 104.332 110.667C104.641 110.442 104.852 110.107 104.92 109.73H105.92V119.32H104.42V112.01H103.25L103.26 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M107.59 112.04C107.59 111.433 107.831 110.85 108.261 110.421C108.69 109.991 109.273 109.75 109.88 109.75C110.487 109.75 111.07 109.991 111.499 110.421C111.929 110.85 112.17 111.433 112.17 112.04V117.04C112.17 117.647 111.929 118.23 111.499 118.659C111.07 119.089 110.487 119.33 109.88 119.33C109.273 119.33 108.69 119.089 108.261 118.659C107.831 118.23 107.59 117.647 107.59 117.04V112.04ZM109.1 117.12C109.1 117.8 109.4 118.06 109.88 118.06C110.36 118.06 110.66 117.8 110.66 117.12V111.94C110.66 111.26 110.36 110.99 109.88 110.99C109.4 110.99 109.1 111.26 109.1 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M114.11 110.94C114.489 110.99 114.873 110.892 115.182 110.667C115.491 110.442 115.701 110.107 115.77 109.73H116.77V119.32H115.26V112.01H114.09L114.11 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M121.66 110.94C122.039 110.99 122.423 110.892 122.732 110.667C123.041 110.442 123.251 110.107 123.32 109.73H124.32V119.32H122.81V112.01H121.64L121.66 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M128.16 112.04C128.139 111.727 128.183 111.413 128.288 111.118C128.394 110.823 128.559 110.553 128.773 110.324C128.987 110.095 129.246 109.912 129.534 109.788C129.821 109.663 130.131 109.599 130.445 109.599C130.758 109.599 131.069 109.663 131.356 109.788C131.644 109.912 131.903 110.095 132.117 110.324C132.331 110.553 132.496 110.823 132.601 111.118C132.707 111.413 132.751 111.727 132.73 112.04V117.04C132.751 117.353 132.707 117.666 132.601 117.962C132.496 118.257 132.331 118.527 132.117 118.756C131.903 118.985 131.644 119.167 131.356 119.292C131.069 119.417 130.758 119.481 130.445 119.481C130.131 119.481 129.821 119.417 129.534 119.292C129.246 119.167 128.987 118.985 128.773 118.756C128.559 118.527 128.394 118.257 128.288 117.962C128.183 117.666 128.139 117.353 128.16 117.04V112.04ZM129.67 117.12C129.67 117.8 129.97 118.06 130.45 118.06C130.93 118.06 131.23 117.8 131.23 117.12V111.94C131.23 111.26 130.93 110.99 130.45 110.99C129.97 110.99 129.67 111.26 129.67 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M134.64 110.94C135.019 110.988 135.401 110.889 135.71 110.664C136.018 110.439 136.229 110.105 136.3 109.73H137.3V119.32H135.79V112.01H134.63L134.64 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M140.07 110.94C140.448 110.99 140.83 110.892 141.138 110.667C141.445 110.441 141.654 110.106 141.72 109.73H142.72V119.32H141.21V112.01H140.05L140.07 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M145.49 110.94C145.869 110.99 146.253 110.892 146.562 110.667C146.871 110.442 147.081 110.107 147.15 109.73H148.15V119.32H146.65V112.01H145.49V110.94Z"
        fill="#1B2236"
      />
      <path
        d="M150.92 110.94C151.307 110.995 151.7 110.894 152.013 110.66C152.326 110.425 152.533 110.077 152.59 109.69H153.59V119.28H152.08V111.97H150.92V110.94Z"
        fill="#1B2236"
      />
      <path
        d="M156.34 110.94C156.719 110.99 157.103 110.892 157.412 110.667C157.721 110.442 157.932 110.107 158 109.73H159V119.32H157.5V112.01H156.33L156.34 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M160.71 112.04C160.71 111.739 160.769 111.441 160.884 111.164C161 110.886 161.168 110.633 161.381 110.421C161.593 110.208 161.846 110.039 162.124 109.924C162.402 109.809 162.699 109.75 163 109.75C163.301 109.75 163.599 109.809 163.876 109.924C164.154 110.039 164.407 110.208 164.619 110.421C164.832 110.633 165.001 110.886 165.116 111.164C165.231 111.441 165.29 111.739 165.29 112.04V117.04C165.29 117.341 165.231 117.639 165.116 117.916C165.001 118.194 164.832 118.447 164.619 118.659C164.407 118.872 164.154 119.041 163.876 119.156C163.599 119.271 163.301 119.33 163 119.33C162.699 119.33 162.402 119.271 162.124 119.156C161.846 119.041 161.593 118.872 161.381 118.659C161.168 118.447 161 118.194 160.884 117.916C160.769 117.639 160.71 117.341 160.71 117.04V112.04ZM162.22 117.12C162.22 117.8 162.52 118.06 163 118.06C163.48 118.06 163.78 117.8 163.78 117.12V111.94C163.78 111.26 163.48 110.99 163 110.99C162.52 110.99 162.22 111.26 162.22 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M167.19 110.94C167.569 110.99 167.953 110.892 168.262 110.667C168.571 110.442 168.782 110.107 168.85 109.73H169.85V119.32H168.34V112.01H167.19V110.94Z"
        fill="#1B2236"
      />
      <path
        d="M171.59 112.04C171.569 111.727 171.613 111.413 171.719 111.118C171.824 110.823 171.989 110.553 172.203 110.324C172.417 110.095 172.676 109.912 172.964 109.788C173.252 109.663 173.562 109.599 173.875 109.599C174.189 109.599 174.499 109.663 174.786 109.788C175.074 109.912 175.333 110.095 175.547 110.324C175.761 110.553 175.926 110.823 176.032 111.118C176.137 111.413 176.181 111.727 176.16 112.04V117.04C176.181 117.353 176.137 117.666 176.032 117.962C175.926 118.257 175.761 118.527 175.547 118.756C175.333 118.985 175.074 119.167 174.786 119.292C174.499 119.417 174.189 119.481 173.875 119.481C173.562 119.481 173.252 119.417 172.964 119.292C172.676 119.167 172.417 118.985 172.203 118.756C171.989 118.527 171.824 118.257 171.719 117.962C171.613 117.666 171.569 117.353 171.59 117.04V112.04ZM173.09 117.12C173.09 117.8 173.39 118.06 173.87 118.06C174.35 118.06 174.65 117.8 174.65 117.12V111.94C174.65 111.26 174.35 110.99 173.87 110.99C173.39 110.99 173.09 111.26 173.09 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M178.05 110.94C178.428 110.99 178.81 110.892 179.118 110.667C179.425 110.441 179.634 110.106 179.7 109.73H180.7V119.32H179.19V112.01H178.03L178.05 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M182.42 112.04C182.399 111.727 182.443 111.413 182.549 111.118C182.654 110.823 182.819 110.553 183.033 110.324C183.248 110.095 183.506 109.912 183.794 109.788C184.082 109.663 184.392 109.599 184.705 109.599C185.019 109.599 185.329 109.663 185.616 109.788C185.904 109.912 186.163 110.095 186.377 110.324C186.591 110.553 186.756 110.823 186.862 111.118C186.967 111.413 187.011 111.727 186.99 112.04V117.04C187.011 117.353 186.967 117.666 186.862 117.962C186.756 118.257 186.591 118.527 186.377 118.756C186.163 118.985 185.904 119.167 185.616 119.292C185.329 119.417 185.019 119.481 184.705 119.481C184.392 119.481 184.082 119.417 183.794 119.292C183.506 119.167 183.248 118.985 183.033 118.756C182.819 118.527 182.654 118.257 182.549 117.962C182.443 117.666 182.399 117.353 182.42 117.04V112.04ZM183.92 117.12C183.92 117.8 184.23 118.06 184.71 118.06C185.19 118.06 185.49 117.8 185.49 117.12V111.94C185.49 111.26 185.18 110.99 184.71 110.99C184.24 110.99 183.92 111.26 183.92 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M188.9 110.94C189.289 110.995 189.685 110.895 190.001 110.661C190.317 110.427 190.528 110.079 190.59 109.69H191.59V119.28H190.08V111.97H188.92L188.9 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M199.64 112.04C199.64 111.433 199.881 110.85 200.311 110.421C200.74 109.991 201.322 109.75 201.93 109.75C202.537 109.75 203.12 109.991 203.549 110.421C203.979 110.85 204.22 111.433 204.22 112.04V117.04C204.22 117.647 203.979 118.23 203.549 118.659C203.12 119.089 202.537 119.33 201.93 119.33C201.322 119.33 200.74 119.089 200.311 118.659C199.881 118.23 199.64 117.647 199.64 117.04V112.04ZM201.15 117.12C201.15 117.8 201.45 118.06 201.93 118.06C202.41 118.06 202.71 117.8 202.71 117.12V111.94C202.71 111.26 202.41 110.99 201.93 110.99C201.45 110.99 201.15 111.26 201.15 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M206.12 110.94C206.499 110.99 206.883 110.892 207.192 110.667C207.501 110.442 207.711 110.107 207.78 109.73H208.78V119.32H207.28V112.01H206.11L206.12 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M210.49 112.04C210.49 111.433 210.731 110.85 211.161 110.421C211.59 109.991 212.173 109.75 212.78 109.75C213.387 109.75 213.97 109.991 214.399 110.421C214.829 110.85 215.07 111.433 215.07 112.04V117.04C215.07 117.647 214.829 118.23 214.399 118.659C213.97 119.089 213.387 119.33 212.78 119.33C212.173 119.33 211.59 119.089 211.161 118.659C210.731 118.23 210.49 117.647 210.49 117.04V112.04ZM212 117.12C212 117.8 212.3 118.06 212.78 118.06C213.26 118.06 213.56 117.8 213.56 117.12V111.94C213.56 111.26 213.26 110.99 212.78 110.99C212.3 110.99 212 111.26 212 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M216.97 110.94C217.349 110.978 217.727 110.87 218.029 110.637C218.33 110.405 218.531 110.066 218.59 109.69H219.59V119.28H218.09V111.97H216.92L216.97 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M222.4 110.94C222.779 110.988 223.161 110.889 223.47 110.664C223.778 110.439 223.989 110.105 224.06 109.73H225.06V119.32H223.59V112.01H222.43L222.4 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M227.82 110.94C228.199 110.99 228.583 110.892 228.892 110.667C229.201 110.442 229.411 110.107 229.48 109.73H230.48V119.32H228.99V112.01H227.82V110.94Z"
        fill="#1B2236"
      />
      <path
        d="M232.2 112.04C232.179 111.727 232.223 111.413 232.328 111.118C232.434 110.823 232.599 110.553 232.813 110.324C233.027 110.095 233.286 109.912 233.574 109.788C233.861 109.663 234.172 109.599 234.485 109.599C234.798 109.599 235.109 109.663 235.396 109.788C235.684 109.912 235.943 110.095 236.157 110.324C236.371 110.553 236.536 110.823 236.642 111.118C236.747 111.413 236.791 111.727 236.77 112.04V117.04C236.791 117.353 236.747 117.666 236.642 117.962C236.536 118.257 236.371 118.527 236.157 118.756C235.943 118.985 235.684 119.167 235.396 119.292C235.109 119.417 234.798 119.481 234.485 119.481C234.172 119.481 233.861 119.417 233.574 119.292C233.286 119.167 233.027 118.985 232.813 118.756C232.599 118.527 232.434 118.257 232.328 117.962C232.223 117.666 232.179 117.353 232.2 117.04V112.04ZM233.7 117.12C233.7 117.8 234 118.06 234.48 118.06C234.96 118.06 235.26 117.8 235.26 117.12V111.94C235.26 111.26 234.96 110.99 234.48 110.99C234 110.99 233.7 111.26 233.7 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M238.68 110.94C239.058 110.99 239.44 110.892 239.748 110.667C240.055 110.441 240.264 110.106 240.33 109.73H241.33V119.32H239.82V112.01H238.66L238.68 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M244.1 110.94C244.479 110.99 244.863 110.892 245.172 110.667C245.481 110.442 245.692 110.107 245.76 109.73H246.76V119.32H245.25V112.01H244.08L244.1 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M249.53 110.94C249.914 110.987 250.302 110.882 250.611 110.649C250.921 110.416 251.128 110.073 251.19 109.69H252.19V119.28H250.69V111.97H249.53V110.94Z"
        fill="#1B2236"
      />
      <path
        d="M254.95 110.94C255.332 110.984 255.715 110.878 256.021 110.645C256.327 110.412 256.53 110.07 256.59 109.69H257.59V119.28H256.08V111.97H254.91L254.95 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M260.38 110.94C260.759 110.988 261.141 110.889 261.45 110.664C261.758 110.439 261.969 110.105 262.04 109.73H263.04V119.32H261.59V112.01H260.43L260.38 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M265.81 110.94C266.188 110.99 266.571 110.892 266.878 110.667C267.185 110.441 267.394 110.106 267.46 109.73H268.46V119.32H266.95V112.01H265.79L265.81 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M272.3 112.04C272.3 111.433 272.541 110.85 272.971 110.421C273.4 109.991 273.983 109.75 274.59 109.75C275.197 109.75 275.78 109.991 276.209 110.421C276.639 110.85 276.88 111.433 276.88 112.04V117.04C276.88 117.647 276.639 118.23 276.209 118.659C275.78 119.089 275.197 119.33 274.59 119.33C273.983 119.33 273.4 119.089 272.971 118.659C272.541 118.23 272.3 117.647 272.3 117.04V112.04ZM273.81 117.12C273.81 117.8 274.11 118.06 274.59 118.06C275.07 118.06 275.37 117.8 275.37 117.12V111.94C275.37 111.26 275.07 110.99 274.59 110.99C274.11 110.99 273.81 111.26 273.81 111.94V117.12Z"
        fill="#1B2236"
      />
      <path
        d="M278.78 110.94C279.159 110.99 279.543 110.892 279.852 110.667C280.161 110.442 280.372 110.107 280.44 109.73H281.44V119.32H279.94V112.01H278.77L278.78 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M284.21 110.94C284.588 110.99 284.97 110.892 285.278 110.667C285.585 110.441 285.794 110.106 285.86 109.73H286.86V119.32H285.35V112.01H284.19L284.21 110.94Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 128.93C24.1198 128.322 24.3611 127.74 24.7905 127.31C25.22 126.881 25.8024 126.64 26.4098 126.64C27.0171 126.64 27.5996 126.881 28.0291 127.31C28.4585 127.74 28.6998 128.322 28.6998 128.93V133.93C28.6998 134.537 28.4585 135.119 28.0291 135.549C27.5996 135.978 27.0171 136.22 26.4098 136.22C25.8024 136.22 25.22 135.978 24.7905 135.549C24.3611 135.119 24.1198 134.537 24.1198 133.93V128.93ZM25.6298 134.01C25.6298 134.69 25.9298 134.95 26.4098 134.95C26.8898 134.95 27.1898 134.69 27.1898 134.01V128.83C27.1898 128.15 26.8898 127.88 26.4098 127.88C25.9298 127.88 25.6298 128.15 25.6298 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M29.5901 128.93C29.5694 128.617 29.6131 128.303 29.7186 128.008C29.8241 127.713 29.989 127.442 30.2032 127.213C30.4174 126.985 30.6764 126.802 30.9639 126.677C31.2515 126.553 31.5616 126.488 31.8751 126.488C32.1886 126.488 32.4987 126.553 32.7863 126.677C33.0739 126.802 33.3328 126.985 33.547 127.213C33.7612 127.442 33.9262 127.713 34.0316 128.008C34.1371 128.303 34.1808 128.617 34.1601 128.93V133.93C34.1808 134.242 34.1371 134.556 34.0316 134.851C33.9262 135.146 33.7612 135.417 33.547 135.646C33.3328 135.875 33.0739 136.057 32.7863 136.182C32.4987 136.306 32.1886 136.371 31.8751 136.371C31.5616 136.371 31.2515 136.306 30.9639 136.182C30.6764 136.057 30.4174 135.875 30.2032 135.646C29.989 135.417 29.8241 135.146 29.7186 134.851C29.6131 134.556 29.5694 134.242 29.5901 133.93V128.93ZM31.0901 134.01C31.0901 134.69 31.4001 134.95 31.8801 134.95C32.3601 134.95 32.6601 134.69 32.6601 134.01V128.83C32.6601 128.15 32.3501 127.88 31.8801 127.88C31.4101 127.88 31.0901 128.15 31.0901 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 128.93C34.9699 128.322 35.2111 127.74 35.6406 127.31C36.0701 126.881 36.6525 126.64 37.2599 126.64C37.8672 126.64 38.4497 126.881 38.8792 127.31C39.3086 127.74 39.5499 128.322 39.5499 128.93V133.93C39.5499 134.537 39.3086 135.119 38.8792 135.549C38.4497 135.978 37.8672 136.22 37.2599 136.22C36.6525 136.22 36.0701 135.978 35.6406 135.549C35.2111 135.119 34.9699 134.537 34.9699 133.93V128.93ZM36.4799 134.01C36.4799 134.69 36.7799 134.95 37.2599 134.95C37.7399 134.95 38.0399 134.69 38.0399 134.01V128.83C38.0399 128.15 37.7399 127.88 37.2599 127.88C36.7799 127.88 36.4799 128.15 36.4799 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 127.83C41.8292 127.88 42.2129 127.782 42.5219 127.557C42.8309 127.332 43.0415 126.996 43.1101 126.62H44.1101V136.21H42.5901V128.9H41.4201L41.4501 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M49.0001 127.83C49.3792 127.88 49.763 127.782 50.072 127.557C50.381 127.332 50.5915 126.996 50.6601 126.62H51.6601V136.21H50.1501V128.9H48.9801L49.0001 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M54.4298 127.83C54.8085 127.877 55.1911 127.779 55.4995 127.554C55.8079 127.329 56.019 126.995 56.0898 126.62H57.0898V136.21H55.5898V128.9H54.4298V127.83Z"
        fill="#1B2236"
      />
      <path
        d="M59.86 127.83C60.2379 127.88 60.6204 127.782 60.9278 127.556C61.2352 127.331 61.4437 126.995 61.51 126.62H62.51V136.21H61V128.9H59.84L59.86 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M65.2802 127.83C65.6593 127.88 66.043 127.782 66.352 127.557C66.661 127.332 66.8716 126.996 66.9402 126.62H67.9402V136.21H66.4402V128.9H65.2702L65.2802 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M70.7099 127.83C71.0886 127.877 71.4711 127.779 71.7795 127.554C72.0879 127.329 72.299 126.995 72.3699 126.62H73.3699V136.21H71.8599V128.9H70.6999L70.7099 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M77.1999 128.93C77.1999 128.322 77.4411 127.74 77.8706 127.31C78.3 126.881 78.8825 126.64 79.4899 126.64C80.0972 126.64 80.6797 126.881 81.1091 127.31C81.5386 127.74 81.7799 128.322 81.7799 128.93V133.93C81.7799 134.537 81.5386 135.119 81.1091 135.549C80.6797 135.978 80.0972 136.22 79.4899 136.22C78.8825 136.22 78.3 135.978 77.8706 135.549C77.4411 135.119 77.1999 134.537 77.1999 133.93V128.93ZM78.7099 134.01C78.7099 134.69 79.0099 134.95 79.4899 134.95C79.9699 134.95 80.2699 134.69 80.2699 134.01V128.83C80.2699 128.15 79.9699 127.88 79.4899 127.88C79.0099 127.88 78.7099 128.15 78.7099 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M82.5901 128.93C82.5694 128.617 82.6131 128.303 82.7186 128.008C82.8241 127.713 82.989 127.442 83.2032 127.213C83.4174 126.985 83.6764 126.802 83.9639 126.677C84.2515 126.553 84.5616 126.488 84.8751 126.488C85.1886 126.488 85.4987 126.553 85.7863 126.677C86.0739 126.802 86.3328 126.985 86.547 127.213C86.7612 127.442 86.9262 127.713 87.0316 128.008C87.1371 128.303 87.1808 128.617 87.1601 128.93V133.93C87.1808 134.242 87.1371 134.556 87.0316 134.851C86.9262 135.146 86.7612 135.417 86.547 135.646C86.3328 135.875 86.0739 136.057 85.7863 136.182C85.4987 136.306 85.1886 136.371 84.8751 136.371C84.5616 136.371 84.2515 136.306 83.9639 136.182C83.6764 136.057 83.4174 135.875 83.2032 135.646C82.989 135.417 82.8241 135.146 82.7186 134.851C82.6131 134.556 82.5694 134.242 82.5901 133.93V128.93ZM84.0901 134.01C84.0901 134.69 84.4001 134.95 84.8801 134.95C85.3601 134.95 85.6601 134.69 85.6601 134.01V128.83C85.6601 128.15 85.3601 127.88 84.8801 127.88C84.4001 127.88 84.0901 128.15 84.0901 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M88.05 128.93C88.05 128.322 88.2912 127.74 88.7207 127.31C89.1501 126.881 89.7326 126.64 90.34 126.64C90.9473 126.64 91.5298 126.881 91.9592 127.31C92.3887 127.74 92.6299 128.322 92.6299 128.93V133.93C92.6299 134.537 92.3887 135.119 91.9592 135.549C91.5298 135.978 90.9473 136.22 90.34 136.22C89.7326 136.22 89.1501 135.978 88.7207 135.549C88.2912 135.119 88.05 134.537 88.05 133.93V128.93ZM89.56 134.01C89.56 134.69 89.86 134.95 90.34 134.95C90.82 134.95 91.1199 134.69 91.1199 134.01V128.83C91.1199 128.15 90.82 127.88 90.34 127.88C89.86 127.88 89.56 128.15 89.56 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M93.4801 128.93C93.4801 128.322 93.7214 127.74 94.1509 127.31C94.5803 126.881 95.1628 126.64 95.7701 126.64C96.3775 126.64 96.9599 126.881 97.3894 127.31C97.8189 127.74 98.0601 128.322 98.0601 128.93V133.93C98.0601 134.537 97.8189 135.119 97.3894 135.549C96.9599 135.978 96.3775 136.22 95.7701 136.22C95.1628 136.22 94.5803 135.978 94.1509 135.549C93.7214 135.119 93.4801 134.537 93.4801 133.93V128.93ZM94.9901 134.01C94.9901 134.69 95.2901 134.95 95.7701 134.95C96.2501 134.95 96.5501 134.69 96.5501 134.01V128.83C96.5501 128.15 96.2501 127.88 95.7701 127.88C95.2901 127.88 94.9901 128.15 94.9901 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M98.9099 128.93C98.8892 128.617 98.933 128.303 99.0384 128.008C99.1439 127.713 99.3088 127.442 99.523 127.213C99.7372 126.985 99.9962 126.802 100.284 126.677C100.571 126.553 100.881 126.488 101.195 126.488C101.508 126.488 101.819 126.553 102.106 126.677C102.394 126.802 102.653 126.985 102.867 127.213C103.081 127.442 103.246 127.713 103.351 128.008C103.457 128.303 103.501 128.617 103.48 128.93V133.93C103.501 134.242 103.457 134.556 103.351 134.851C103.246 135.146 103.081 135.417 102.867 135.646C102.653 135.875 102.394 136.057 102.106 136.182C101.819 136.306 101.508 136.371 101.195 136.371C100.881 136.371 100.571 136.306 100.284 136.182C99.9962 136.057 99.7372 135.875 99.523 135.646C99.3088 135.417 99.1439 135.146 99.0384 134.851C98.933 134.556 98.8892 134.242 98.9099 133.93V128.93ZM100.41 134.01C100.41 134.69 100.71 134.95 101.19 134.95C101.67 134.95 101.97 134.69 101.97 134.01V128.83C101.97 128.15 101.67 127.88 101.19 127.88C100.71 127.88 100.41 128.15 100.41 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M109.59 127.83C109.969 127.88 110.353 127.782 110.662 127.557C110.971 127.332 111.181 126.996 111.25 126.62H112.25V136.21H110.74V128.9H109.59V127.83Z"
        fill="#1B2236"
      />
      <path
        d="M115.06 127.83C115.439 127.877 115.821 127.779 116.13 127.554C116.438 127.329 116.649 126.995 116.72 126.62H117.72V136.21H116.21V128.9H115.05L115.06 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M120.49 127.83C120.868 127.88 121.25 127.782 121.558 127.556C121.865 127.331 122.074 126.995 122.14 126.62H123.14V136.21H121.63V128.9H120.49V127.83Z"
        fill="#1B2236"
      />
      <path
        d="M125.91 127.83C126.289 127.88 126.673 127.782 126.982 127.557C127.291 127.332 127.501 126.996 127.57 126.62H128.57V136.21H127.07V128.9H125.9L125.91 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M131.34 127.83C131.719 127.877 132.101 127.779 132.41 127.554C132.718 127.329 132.929 126.995 133 126.62H134V136.21H132.49V128.9H131.33L131.34 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M136.76 127.83C137.139 127.88 137.523 127.782 137.832 127.557C138.141 127.332 138.352 126.996 138.42 126.62H139.42V136.21H137.92V128.9H136.75L136.76 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M141.13 128.93C141.13 128.322 141.371 127.74 141.801 127.31C142.23 126.881 142.813 126.64 143.42 126.64C144.027 126.64 144.61 126.881 145.039 127.31C145.469 127.74 145.71 128.322 145.71 128.93V133.93C145.71 134.537 145.469 135.119 145.039 135.549C144.61 135.978 144.027 136.22 143.42 136.22C142.813 136.22 142.23 135.978 141.801 135.549C141.371 135.119 141.13 134.537 141.13 133.93V128.93ZM142.64 134.01C142.64 134.69 142.94 134.95 143.42 134.95C143.9 134.95 144.2 134.69 144.2 134.01V128.83C144.2 128.15 143.9 127.88 143.42 127.88C142.94 127.88 142.64 128.15 142.64 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M146.59 128.93C146.59 128.322 146.831 127.74 147.261 127.31C147.69 126.881 148.273 126.64 148.88 126.64C149.487 126.64 150.07 126.881 150.499 127.31C150.929 127.74 151.17 128.322 151.17 128.93V133.93C151.17 134.537 150.929 135.119 150.499 135.549C150.07 135.978 149.487 136.22 148.88 136.22C148.273 136.22 147.69 135.978 147.261 135.549C146.831 135.119 146.59 134.537 146.59 133.93V128.93ZM148.1 134.01C148.1 134.69 148.4 134.95 148.88 134.95C149.36 134.95 149.66 134.69 149.66 134.01V128.83C149.66 128.15 149.36 127.88 148.88 127.88C148.4 127.88 148.1 128.15 148.1 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M151.99 128.93C151.969 128.617 152.013 128.303 152.119 128.008C152.224 127.713 152.389 127.442 152.603 127.213C152.817 126.985 153.076 126.802 153.364 126.677C153.651 126.553 153.962 126.488 154.275 126.488C154.588 126.488 154.899 126.553 155.186 126.677C155.474 126.802 155.733 126.985 155.947 127.213C156.161 127.442 156.326 127.713 156.432 128.008C156.537 128.303 156.581 128.617 156.56 128.93V133.93C156.581 134.242 156.537 134.556 156.432 134.851C156.326 135.146 156.161 135.417 155.947 135.646C155.733 135.875 155.474 136.057 155.186 136.182C154.899 136.306 154.588 136.371 154.275 136.371C153.962 136.371 153.651 136.306 153.364 136.182C153.076 136.057 152.817 135.875 152.603 135.646C152.389 135.417 152.224 135.146 152.119 134.851C152.013 134.556 151.969 134.242 151.99 133.93V128.93ZM153.49 134.01C153.49 134.69 153.79 134.95 154.27 134.95C154.75 134.95 155.05 134.69 155.05 134.01V128.83C155.05 128.15 154.75 127.88 154.27 127.88C153.79 127.88 153.49 128.15 153.49 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M157.41 128.93C157.41 128.322 157.651 127.74 158.081 127.31C158.51 126.881 159.092 126.64 159.7 126.64C160.307 126.64 160.89 126.881 161.319 127.31C161.749 127.74 161.99 128.322 161.99 128.93V133.93C161.99 134.537 161.749 135.119 161.319 135.549C160.89 135.978 160.307 136.22 159.7 136.22C159.092 136.22 158.51 135.978 158.081 135.549C157.651 135.119 157.41 134.537 157.41 133.93V128.93ZM158.92 134.01C158.92 134.69 159.22 134.95 159.7 134.95C160.18 134.95 160.48 134.69 160.48 134.01V128.83C160.48 128.15 160.18 127.88 159.7 127.88C159.22 127.88 158.92 128.15 158.92 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M162.84 128.93C162.819 128.617 162.863 128.303 162.969 128.008C163.074 127.713 163.239 127.442 163.453 127.213C163.667 126.985 163.926 126.802 164.214 126.677C164.502 126.553 164.812 126.488 165.125 126.488C165.439 126.488 165.749 126.553 166.036 126.677C166.324 126.802 166.583 126.985 166.797 127.213C167.011 127.442 167.176 127.713 167.282 128.008C167.387 128.303 167.431 128.617 167.41 128.93V133.93C167.431 134.242 167.387 134.556 167.282 134.851C167.176 135.146 167.011 135.417 166.797 135.646C166.583 135.875 166.324 136.057 166.036 136.182C165.749 136.306 165.439 136.371 165.125 136.371C164.812 136.371 164.502 136.306 164.214 136.182C163.926 136.057 163.667 135.875 163.453 135.646C163.239 135.417 163.074 135.146 162.969 134.851C162.863 134.556 162.819 134.242 162.84 133.93V128.93ZM164.34 134.01C164.34 134.69 164.65 134.95 165.13 134.95C165.61 134.95 165.91 134.69 165.91 134.01V128.83C165.91 128.15 165.6 127.88 165.13 127.88C164.66 127.88 164.34 128.15 164.34 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M168.26 128.93C168.26 128.322 168.501 127.74 168.931 127.31C169.36 126.881 169.943 126.64 170.55 126.64C171.157 126.64 171.74 126.881 172.169 127.31C172.599 127.74 172.84 128.322 172.84 128.93V133.93C172.84 134.537 172.599 135.119 172.169 135.549C171.74 135.978 171.157 136.22 170.55 136.22C169.943 136.22 169.36 135.978 168.931 135.549C168.501 135.119 168.26 134.537 168.26 133.93V128.93ZM169.77 134.01C169.77 134.69 170.07 134.95 170.55 134.95C171.03 134.95 171.33 134.69 171.33 134.01V128.83C171.33 128.15 171.03 127.88 170.55 127.88C170.07 127.88 169.77 128.15 169.77 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M173.69 128.93C173.67 128.617 173.713 128.303 173.819 128.008C173.924 127.713 174.089 127.442 174.303 127.213C174.518 126.985 174.776 126.802 175.064 126.677C175.352 126.553 175.662 126.488 175.975 126.488C176.289 126.488 176.599 126.553 176.886 126.677C177.174 126.802 177.433 126.985 177.647 127.213C177.861 127.442 178.026 127.713 178.132 128.008C178.237 128.303 178.281 128.617 178.26 128.93V133.93C178.281 134.242 178.237 134.556 178.132 134.851C178.026 135.146 177.861 135.417 177.647 135.646C177.433 135.875 177.174 136.057 176.886 136.182C176.599 136.306 176.289 136.371 175.975 136.371C175.662 136.371 175.352 136.306 175.064 136.182C174.776 136.057 174.518 135.875 174.303 135.646C174.089 135.417 173.924 135.146 173.819 134.851C173.713 134.556 173.67 134.242 173.69 133.93V128.93ZM175.2 134.01C175.2 134.69 175.5 134.95 175.98 134.95C176.46 134.95 176.76 134.69 176.76 134.01V128.83C176.76 128.15 176.46 127.88 175.98 127.88C175.5 127.88 175.2 128.15 175.2 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M182.29 127.83C182.669 127.88 183.053 127.782 183.362 127.557C183.671 127.332 183.882 126.996 183.95 126.62H184.95V136.21H183.44V128.9H182.29V127.83Z"
        fill="#1B2236"
      />
      <path
        d="M187.72 127.83C188.099 127.877 188.481 127.779 188.79 127.554C189.098 127.329 189.309 126.995 189.38 126.62H190.38V136.21H188.87V128.9H187.71L187.72 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M193.15 127.83C193.528 127.88 193.91 127.782 194.218 127.556C194.525 127.331 194.734 126.995 194.8 126.62H195.8V136.21H194.29V128.9H193.13L193.15 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M198.59 127.83C198.969 127.88 199.353 127.782 199.662 127.557C199.971 127.332 200.181 126.996 200.25 126.62H201.25V136.21H199.75V128.9H198.59V127.83Z"
        fill="#1B2236"
      />
      <path
        d="M204 127.83C204.379 127.877 204.761 127.779 205.07 127.554C205.378 127.329 205.589 126.995 205.66 126.62H206.66V136.21H205.15V128.9H203.99L204 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M212.59 128.93C212.569 128.617 212.613 128.303 212.719 128.008C212.824 127.713 212.989 127.442 213.203 127.213C213.417 126.985 213.676 126.802 213.964 126.677C214.252 126.553 214.562 126.488 214.875 126.488C215.189 126.488 215.499 126.553 215.786 126.677C216.074 126.802 216.333 126.985 216.547 127.213C216.761 127.442 216.926 127.713 217.032 128.008C217.137 128.303 217.181 128.617 217.16 128.93V133.93C217.181 134.242 217.137 134.556 217.032 134.851C216.926 135.146 216.761 135.417 216.547 135.646C216.333 135.875 216.074 136.057 215.786 136.182C215.499 136.306 215.189 136.371 214.875 136.371C214.562 136.371 214.252 136.306 213.964 136.182C213.676 136.057 213.417 135.875 213.203 135.646C212.989 135.417 212.824 135.146 212.719 134.851C212.613 134.556 212.569 134.242 212.59 133.93V128.93ZM214.09 134.01C214.09 134.69 214.39 134.95 214.87 134.95C215.35 134.95 215.65 134.69 215.65 134.01V128.83C215.65 128.15 215.35 127.88 214.87 127.88C214.39 127.88 214.09 128.15 214.09 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M218.04 128.93C218.04 128.322 218.281 127.74 218.711 127.31C219.14 126.881 219.723 126.64 220.33 126.64C220.938 126.64 221.52 126.881 221.949 127.31C222.379 127.74 222.62 128.322 222.62 128.93V133.93C222.62 134.537 222.379 135.119 221.949 135.549C221.52 135.978 220.938 136.22 220.33 136.22C219.723 136.22 219.14 135.978 218.711 135.549C218.281 135.119 218.04 134.537 218.04 133.93V128.93ZM219.55 134.01C219.55 134.69 219.85 134.95 220.33 134.95C220.81 134.95 221.11 134.69 221.11 134.01V128.83C221.11 128.15 220.81 127.88 220.33 127.88C219.85 127.88 219.55 128.15 219.55 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M223.47 128.93C223.449 128.617 223.493 128.303 223.598 128.008C223.704 127.713 223.869 127.442 224.083 127.213C224.297 126.985 224.556 126.802 224.844 126.677C225.131 126.553 225.442 126.488 225.755 126.488C226.068 126.488 226.379 126.553 226.666 126.677C226.954 126.802 227.213 126.985 227.427 127.213C227.641 127.442 227.806 127.713 227.911 128.008C228.017 128.303 228.061 128.617 228.04 128.93V133.93C228.061 134.242 228.017 134.556 227.911 134.851C227.806 135.146 227.641 135.417 227.427 135.646C227.213 135.875 226.954 136.057 226.666 136.182C226.379 136.306 226.068 136.371 225.755 136.371C225.442 136.371 225.131 136.306 224.844 136.182C224.556 136.057 224.297 135.875 224.083 135.646C223.869 135.417 223.704 135.146 223.598 134.851C223.493 134.556 223.449 134.242 223.47 133.93V128.93ZM224.97 134.01C224.97 134.69 225.28 134.95 225.76 134.95C226.24 134.95 226.54 134.69 226.54 134.01V128.83C226.54 128.15 226.23 127.88 225.76 127.88C225.29 127.88 224.97 128.15 224.97 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M228.89 128.93C228.89 128.322 229.131 127.74 229.561 127.31C229.99 126.881 230.572 126.64 231.18 126.64C231.787 126.64 232.37 126.881 232.799 127.31C233.229 127.74 233.47 128.322 233.47 128.93V133.93C233.47 134.537 233.229 135.119 232.799 135.549C232.37 135.978 231.787 136.22 231.18 136.22C230.572 136.22 229.99 135.978 229.561 135.549C229.131 135.119 228.89 134.537 228.89 133.93V128.93ZM230.4 134.01C230.4 134.69 230.7 134.95 231.18 134.95C231.66 134.95 231.96 134.69 231.96 134.01V128.83C231.96 128.15 231.66 127.88 231.18 127.88C230.7 127.88 230.4 128.15 230.4 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M234.32 128.93C234.32 128.629 234.379 128.331 234.494 128.053C234.609 127.775 234.778 127.523 234.991 127.31C235.203 127.098 235.456 126.929 235.734 126.814C236.011 126.699 236.309 126.64 236.61 126.64C236.911 126.64 237.208 126.699 237.486 126.814C237.764 126.929 238.017 127.098 238.229 127.31C238.442 127.523 238.611 127.775 238.726 128.053C238.841 128.331 238.9 128.629 238.9 128.93V133.93C238.9 134.23 238.841 134.528 238.726 134.806C238.611 135.084 238.442 135.336 238.229 135.549C238.017 135.762 237.764 135.93 237.486 136.045C237.208 136.16 236.911 136.22 236.61 136.22C236.309 136.22 236.011 136.16 235.734 136.045C235.456 135.93 235.203 135.762 234.991 135.549C234.778 135.336 234.609 135.084 234.494 134.806C234.379 134.528 234.32 134.23 234.32 133.93V128.93ZM235.83 134.01C235.83 134.69 236.13 134.95 236.61 134.95C237.09 134.95 237.39 134.69 237.39 134.01V128.83C237.39 128.15 237.09 127.88 236.61 127.88C236.13 127.88 235.83 128.15 235.83 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M239.75 128.93C239.729 128.617 239.773 128.303 239.878 128.008C239.984 127.713 240.149 127.442 240.363 127.213C240.577 126.985 240.836 126.802 241.124 126.677C241.411 126.553 241.721 126.488 242.035 126.488C242.348 126.488 242.658 126.553 242.946 126.677C243.234 126.802 243.492 126.985 243.707 127.213C243.921 127.442 244.086 127.713 244.191 128.008C244.297 128.303 244.34 128.617 244.32 128.93V133.93C244.34 134.242 244.297 134.556 244.191 134.851C244.086 135.146 243.921 135.417 243.707 135.646C243.492 135.875 243.234 136.057 242.946 136.182C242.658 136.306 242.348 136.371 242.035 136.371C241.721 136.371 241.411 136.306 241.124 136.182C240.836 136.057 240.577 135.875 240.363 135.646C240.149 135.417 239.984 135.146 239.878 134.851C239.773 134.556 239.729 134.242 239.75 133.93V128.93ZM241.25 134.01C241.25 134.69 241.55 134.95 242.03 134.95C242.51 134.95 242.81 134.69 242.81 134.01V128.83C242.81 128.15 242.51 127.88 242.03 127.88C241.55 127.88 241.25 128.15 241.25 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M249.42 128.93C249.42 128.322 249.661 127.74 250.091 127.31C250.52 126.881 251.103 126.64 251.71 126.64C252.317 126.64 252.9 126.881 253.329 127.31C253.759 127.74 254 128.322 254 128.93V133.93C254 134.537 253.759 135.119 253.329 135.549C252.9 135.978 252.317 136.22 251.71 136.22C251.103 136.22 250.52 135.978 250.091 135.549C249.661 135.119 249.42 134.537 249.42 133.93V128.93ZM250.93 134.01C250.93 134.69 251.23 134.95 251.71 134.95C252.19 134.95 252.49 134.69 252.49 134.01V128.83C252.49 128.15 252.19 127.88 251.71 127.88C251.23 127.88 250.93 128.15 250.93 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M255.9 127.83C256.279 127.877 256.661 127.779 256.969 127.554C257.278 127.329 257.489 126.995 257.56 126.62H258.56V136.21H257.05V128.9H255.89L255.9 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M260.27 128.93C260.27 128.322 260.511 127.74 260.941 127.31C261.37 126.881 261.953 126.64 262.56 126.64C263.168 126.64 263.75 126.881 264.179 127.31C264.609 127.74 264.85 128.322 264.85 128.93V133.93C264.85 134.537 264.609 135.119 264.179 135.549C263.75 135.978 263.168 136.22 262.56 136.22C261.953 136.22 261.37 135.978 260.941 135.549C260.511 135.119 260.27 134.537 260.27 133.93V128.93ZM261.78 134.01C261.78 134.69 262.08 134.95 262.56 134.95C263.04 134.95 263.34 134.69 263.34 134.01V128.83C263.34 128.15 263.04 127.88 262.56 127.88C262.08 127.88 261.78 128.15 261.78 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M266.75 127.83C267.129 127.88 267.513 127.782 267.822 127.557C268.131 127.332 268.341 126.996 268.41 126.62H269.41V136.21H267.91V128.9H266.74L266.75 127.83Z"
        fill="#1B2236"
      />
      <path
        d="M271.12 128.93C271.12 128.322 271.361 127.74 271.79 127.31C272.22 126.881 272.802 126.64 273.41 126.64C274.017 126.64 274.6 126.881 275.029 127.31C275.459 127.74 275.7 128.322 275.7 128.93V133.93C275.7 134.537 275.459 135.119 275.029 135.549C274.6 135.978 274.017 136.22 273.41 136.22C272.802 136.22 272.22 135.978 271.79 135.549C271.361 135.119 271.12 134.537 271.12 133.93V128.93ZM272.63 134.01C272.63 134.69 272.93 134.95 273.41 134.95C273.89 134.95 274.19 134.69 274.19 134.01V128.83C274.19 128.15 273.89 127.88 273.41 127.88C272.93 127.88 272.63 128.15 272.63 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M277.59 127.83C277.969 127.88 278.353 127.782 278.662 127.557C278.971 127.332 279.181 126.996 279.25 126.62H280.25V136.21H278.75V128.9H277.59V127.83Z"
        fill="#1B2236"
      />
      <path
        d="M281.97 128.93C281.97 128.322 282.211 127.74 282.641 127.31C283.07 126.881 283.653 126.64 284.26 126.64C284.867 126.64 285.45 126.881 285.879 127.31C286.309 127.74 286.55 128.322 286.55 128.93V133.93C286.55 134.537 286.309 135.119 285.879 135.549C285.45 135.978 284.867 136.22 284.26 136.22C283.653 136.22 283.07 135.978 282.641 135.549C282.211 135.119 281.97 134.537 281.97 133.93V128.93ZM283.48 134.01C283.48 134.69 283.78 134.95 284.26 134.95C284.74 134.95 285.04 134.69 285.04 134.01V128.83C285.04 128.15 284.74 127.88 284.26 127.88C283.78 127.88 283.48 128.15 283.48 128.83V134.01Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 145.37C24.1198 144.763 24.3611 144.18 24.7905 143.751C25.22 143.321 25.8024 143.08 26.4098 143.08C27.0171 143.08 27.5996 143.321 28.0291 143.751C28.4585 144.18 28.6998 144.763 28.6998 145.37V150.37C28.6998 150.977 28.4585 151.56 28.0291 151.989C27.5996 152.419 27.0171 152.66 26.4098 152.66C25.8024 152.66 25.22 152.419 24.7905 151.989C24.3611 151.56 24.1198 150.977 24.1198 150.37V145.37ZM25.6298 150.45C25.6298 151.14 25.9298 151.4 26.4098 151.4C26.8898 151.4 27.1898 151.14 27.1898 150.45V145.27C27.1898 144.59 26.8898 144.33 26.4098 144.33C25.9298 144.33 25.6298 144.59 25.6298 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M30.59 144.27C30.9676 144.32 31.3501 144.224 31.6589 144.001C31.9676 143.778 32.1792 143.445 32.25 143.07H33.25V152.69H31.75V145.37H30.59V144.27Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 145.37C34.9699 144.763 35.2111 144.18 35.6406 143.751C36.0701 143.321 36.6525 143.08 37.2599 143.08C37.8672 143.08 38.4497 143.321 38.8792 143.751C39.3086 144.18 39.5499 144.763 39.5499 145.37V150.37C39.5499 150.977 39.3086 151.56 38.8792 151.989C38.4497 152.419 37.8672 152.66 37.2599 152.66C36.6525 152.66 36.0701 152.419 35.6406 151.989C35.2111 151.56 34.9699 150.977 34.9699 150.37V145.37ZM36.4799 150.45C36.4799 151.14 36.7799 151.4 37.2599 151.4C37.7399 151.4 38.0399 151.14 38.0399 150.45V145.27C38.0399 144.59 37.7399 144.33 37.2599 144.33C36.7799 144.33 36.4799 144.59 36.4799 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 144.27C41.8277 144.32 42.2102 144.224 42.519 144.001C42.8277 143.778 43.0392 143.445 43.1101 143.07H44.1101V152.69H42.5901V145.37H41.4201L41.4501 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M45.8299 145.37C45.8092 145.057 45.8529 144.743 45.9583 144.448C46.0638 144.153 46.2287 143.883 46.443 143.654C46.6572 143.425 46.9161 143.242 47.2037 143.118C47.4913 142.993 47.8014 142.929 48.1148 142.929C48.4283 142.929 48.7385 142.993 49.026 143.118C49.3136 143.242 49.5725 143.425 49.7868 143.654C50.001 143.883 50.1659 144.153 50.2714 144.448C50.3768 144.743 50.4206 145.057 50.3999 145.37V150.37C50.4206 150.683 50.3768 150.996 50.2714 151.292C50.1659 151.587 50.001 151.857 49.7868 152.086C49.5725 152.315 49.3136 152.497 49.026 152.622C48.7385 152.747 48.4283 152.811 48.1148 152.811C47.8014 152.811 47.4913 152.747 47.2037 152.622C46.9161 152.497 46.6572 152.315 46.443 152.086C46.2287 151.857 46.0638 151.587 45.9583 151.292C45.8529 150.996 45.8092 150.683 45.8299 150.37V145.37ZM47.3299 150.45C47.3299 151.14 47.6299 151.4 48.1099 151.4C48.5899 151.4 48.8899 151.14 48.8899 150.45V145.27C48.8899 144.59 48.5899 144.33 48.1099 144.33C47.6299 144.33 47.3299 144.59 47.3299 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M52.3102 144.27C52.6867 144.32 53.0679 144.223 53.375 144C53.6821 143.777 53.8916 143.444 53.9602 143.07H54.9602V152.69H53.4502V145.37H52.2902L52.3102 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M57.7299 144.27C58.1075 144.32 58.49 144.224 58.7988 144.001C59.1075 143.778 59.319 143.445 59.3899 143.07H60.3899V152.69H58.8899V145.37H57.7199L57.7299 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M63.1601 144.27C63.5373 144.318 63.9186 144.221 64.2268 143.998C64.5349 143.775 64.747 143.443 64.8201 143.07H65.8201V152.69H64.3101V145.37H63.1501L63.1601 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M68.59 144.27C68.9676 144.32 69.3501 144.224 69.6589 144.001C69.9676 143.778 70.1792 143.445 70.25 143.07H71.25V152.69H69.74V145.37H68.59V144.27Z"
        fill="#1B2236"
      />
      <path
        d="M74.0101 144.27C74.3874 144.318 74.7687 144.221 75.0769 143.998C75.385 143.775 75.5971 143.443 75.6702 143.07H76.6702V152.69H75.1602V145.37H74.0002L74.0101 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M79.4398 144.27C79.8163 144.32 80.1975 144.223 80.5047 144C80.8118 143.777 81.0213 143.444 81.0898 143.07H82.0898V152.69H80.5898V145.37H79.4298L79.4398 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M84.8598 144.27C85.2374 144.32 85.6199 144.224 85.9287 144.001C86.2374 143.778 86.4489 143.445 86.5198 143.07H87.5198V152.69H86.0198V145.37H84.8498L84.8598 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M89.2301 145.37C89.2301 144.763 89.4714 144.18 89.9009 143.751C90.3303 143.321 90.9128 143.08 91.5201 143.08C92.1275 143.08 92.7099 143.321 93.1394 143.751C93.5689 144.18 93.8101 144.763 93.8101 145.37V150.37C93.8101 150.977 93.5689 151.56 93.1394 151.989C92.7099 152.419 92.1275 152.66 91.5201 152.66C90.9128 152.66 90.3303 152.419 89.9009 151.989C89.4714 151.56 89.2301 150.977 89.2301 150.37V145.37ZM90.7401 150.45C90.7401 151.14 91.0401 151.4 91.5201 151.4C92.0001 151.4 92.3001 151.14 92.3001 150.45V145.27C92.3001 144.59 92.0001 144.33 91.5201 144.33C91.0401 144.33 90.7401 144.59 90.7401 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M95.7099 144.27C96.0875 144.32 96.47 144.224 96.7788 144.001C97.0875 143.778 97.299 143.445 97.3699 143.07H98.3699V152.69H96.8699V145.37H95.6999L95.7099 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M103.26 144.27C103.638 144.32 104.02 144.224 104.329 144.001C104.638 143.778 104.849 143.445 104.92 143.07H105.92V152.69H104.42V145.37H103.25L103.26 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M107.59 145.37C107.59 144.763 107.831 144.18 108.261 143.751C108.69 143.321 109.273 143.08 109.88 143.08C110.487 143.08 111.07 143.321 111.499 143.751C111.929 144.18 112.17 144.763 112.17 145.37V150.37C112.17 150.977 111.929 151.56 111.499 151.989C111.07 152.419 110.487 152.66 109.88 152.66C109.273 152.66 108.69 152.419 108.261 151.989C107.831 151.56 107.59 150.977 107.59 150.37V145.37ZM109.1 150.45C109.1 151.14 109.4 151.4 109.88 151.4C110.36 151.4 110.66 151.14 110.66 150.45V145.27C110.66 144.59 110.36 144.33 109.88 144.33C109.4 144.33 109.1 144.59 109.1 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M114.11 144.27C114.488 144.32 114.87 144.224 115.179 144.001C115.488 143.778 115.699 143.445 115.77 143.07H116.77V152.69H115.26V145.37H114.09L114.11 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M121.66 144.27C122.037 144.32 122.42 144.224 122.729 144.001C123.037 143.778 123.249 143.445 123.32 143.07H124.32V152.69H122.81V145.37H121.64L121.66 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M128.16 145.37C128.139 145.057 128.183 144.743 128.288 144.448C128.394 144.153 128.559 143.883 128.773 143.654C128.987 143.425 129.246 143.242 129.534 143.118C129.821 142.993 130.131 142.929 130.445 142.929C130.758 142.929 131.069 142.993 131.356 143.118C131.644 143.242 131.903 143.425 132.117 143.654C132.331 143.883 132.496 144.153 132.601 144.448C132.707 144.743 132.751 145.057 132.73 145.37V150.37C132.751 150.683 132.707 150.996 132.601 151.292C132.496 151.587 132.331 151.857 132.117 152.086C131.903 152.315 131.644 152.497 131.356 152.622C131.069 152.747 130.758 152.811 130.445 152.811C130.131 152.811 129.821 152.747 129.534 152.622C129.246 152.497 128.987 152.315 128.773 152.086C128.559 151.857 128.394 151.587 128.288 151.292C128.183 150.996 128.139 150.683 128.16 150.37V145.37ZM129.67 150.45C129.67 151.14 129.97 151.4 130.45 151.4C130.93 151.4 131.23 151.14 131.23 150.45V145.27C131.23 144.59 130.93 144.33 130.45 144.33C129.97 144.33 129.67 144.59 129.67 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M134.64 144.27C135.017 144.318 135.399 144.221 135.707 143.998C136.015 143.775 136.227 143.443 136.3 143.07H137.3V152.69H135.79V145.37H134.63L134.64 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M140.07 144.27C140.446 144.32 140.828 144.223 141.135 144C141.442 143.777 141.651 143.444 141.72 143.07H142.72V152.69H141.21V145.37H140.05L140.07 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M145.49 144.27C145.868 144.32 146.25 144.224 146.559 144.001C146.868 143.778 147.079 143.445 147.15 143.07H148.15V152.69H146.65V145.37H145.49V144.27Z"
        fill="#1B2236"
      />
      <path
        d="M150.92 144.27C151.297 144.32 151.678 144.223 151.985 144C152.292 143.777 152.502 143.444 152.57 143.07H153.57V152.69H152.06V145.37H150.9L150.92 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M156.34 144.27C156.718 144.32 157.1 144.224 157.409 144.001C157.718 143.778 157.929 143.445 158 143.07H159V152.69H157.5V145.37H156.33L156.34 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M160.71 145.37C160.71 145.069 160.769 144.772 160.884 144.494C161 144.216 161.168 143.963 161.381 143.751C161.593 143.538 161.846 143.369 162.124 143.254C162.402 143.139 162.699 143.08 163 143.08C163.301 143.08 163.599 143.139 163.876 143.254C164.154 143.369 164.407 143.538 164.619 143.751C164.832 143.963 165.001 144.216 165.116 144.494C165.231 144.772 165.29 145.069 165.29 145.37V150.37C165.29 150.671 165.231 150.969 165.116 151.246C165.001 151.524 164.832 151.777 164.619 151.989C164.407 152.202 164.154 152.371 163.876 152.486C163.599 152.601 163.301 152.66 163 152.66C162.699 152.66 162.402 152.601 162.124 152.486C161.846 152.371 161.593 152.202 161.381 151.989C161.168 151.777 161 151.524 160.884 151.246C160.769 150.969 160.71 150.671 160.71 150.37V145.37ZM162.22 150.45C162.22 151.14 162.52 151.4 163 151.4C163.48 151.4 163.78 151.14 163.78 150.45V145.27C163.78 144.59 163.48 144.33 163 144.33C162.52 144.33 162.22 144.59 162.22 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M167.19 144.27C167.568 144.32 167.95 144.224 168.259 144.001C168.568 143.778 168.779 143.445 168.85 143.07H169.85V152.69H168.34V145.37H167.19V144.27Z"
        fill="#1B2236"
      />
      <path
        d="M171.59 145.37C171.569 145.057 171.613 144.743 171.719 144.448C171.824 144.153 171.989 143.883 172.203 143.654C172.417 143.425 172.676 143.242 172.964 143.118C173.252 142.993 173.562 142.929 173.875 142.929C174.189 142.929 174.499 142.993 174.786 143.118C175.074 143.242 175.333 143.425 175.547 143.654C175.761 143.883 175.926 144.153 176.032 144.448C176.137 144.743 176.181 145.057 176.16 145.37V150.37C176.181 150.683 176.137 150.996 176.032 151.292C175.926 151.587 175.761 151.857 175.547 152.086C175.333 152.315 175.074 152.497 174.786 152.622C174.499 152.747 174.189 152.811 173.875 152.811C173.562 152.811 173.252 152.747 172.964 152.622C172.676 152.497 172.417 152.315 172.203 152.086C171.989 151.857 171.824 151.587 171.719 151.292C171.613 150.996 171.569 150.683 171.59 150.37V145.37ZM173.09 150.45C173.09 151.14 173.39 151.4 173.87 151.4C174.35 151.4 174.65 151.14 174.65 150.45V145.27C174.65 144.59 174.35 144.33 173.87 144.33C173.39 144.33 173.09 144.59 173.09 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M178.05 144.27C178.426 144.32 178.808 144.223 179.115 144C179.422 143.777 179.631 143.444 179.7 143.07H180.7V152.69H179.19V145.37H178.03L178.05 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M182.42 145.37C182.399 145.057 182.443 144.743 182.549 144.448C182.654 144.153 182.819 143.883 183.033 143.654C183.248 143.425 183.506 143.242 183.794 143.118C184.082 142.993 184.392 142.929 184.705 142.929C185.019 142.929 185.329 142.993 185.616 143.118C185.904 143.242 186.163 143.425 186.377 143.654C186.591 143.883 186.756 144.153 186.862 144.448C186.967 144.743 187.011 145.057 186.99 145.37V150.37C187.011 150.683 186.967 150.996 186.862 151.292C186.756 151.587 186.591 151.857 186.377 152.086C186.163 152.315 185.904 152.497 185.616 152.622C185.329 152.747 185.019 152.811 184.705 152.811C184.392 152.811 184.082 152.747 183.794 152.622C183.506 152.497 183.248 152.315 183.033 152.086C182.819 151.857 182.654 151.587 182.549 151.292C182.443 150.996 182.399 150.683 182.42 150.37V145.37ZM183.92 150.45C183.92 151.14 184.23 151.4 184.71 151.4C185.19 151.4 185.49 151.14 185.49 150.45V145.27C185.49 144.59 185.18 144.33 184.71 144.33C184.24 144.33 183.92 144.59 183.92 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M188.9 144.27C189.277 144.318 189.658 144.221 189.967 143.998C190.275 143.775 190.487 143.443 190.56 143.07H191.56V152.69H190.05V145.37H188.89L188.9 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M199.64 145.37C199.64 144.763 199.881 144.18 200.311 143.751C200.74 143.321 201.322 143.08 201.93 143.08C202.537 143.08 203.12 143.321 203.549 143.751C203.979 144.18 204.22 144.763 204.22 145.37V150.37C204.22 150.977 203.979 151.56 203.549 151.989C203.12 152.419 202.537 152.66 201.93 152.66C201.322 152.66 200.74 152.419 200.311 151.989C199.881 151.56 199.64 150.977 199.64 150.37V145.37ZM201.15 150.45C201.15 151.14 201.45 151.4 201.93 151.4C202.41 151.4 202.71 151.14 202.71 150.45V145.27C202.71 144.59 202.41 144.33 201.93 144.33C201.45 144.33 201.15 144.59 201.15 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M206.12 144.27C206.498 144.32 206.88 144.224 207.189 144.001C207.498 143.778 207.709 143.445 207.78 143.07H208.78V152.69H207.28V145.37H206.11L206.12 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M210.49 145.37C210.49 144.763 210.731 144.18 211.161 143.751C211.59 143.321 212.173 143.08 212.78 143.08C213.387 143.08 213.97 143.321 214.399 143.751C214.829 144.18 215.07 144.763 215.07 145.37V150.37C215.07 150.977 214.829 151.56 214.399 151.989C213.97 152.419 213.387 152.66 212.78 152.66C212.173 152.66 211.59 152.419 211.161 151.989C210.731 151.56 210.49 150.977 210.49 150.37V145.37ZM212 150.45C212 151.14 212.3 151.4 212.78 151.4C213.26 151.4 213.56 151.14 213.56 150.45V145.27C213.56 144.59 213.26 144.33 212.78 144.33C212.3 144.33 212 144.59 212 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M216.97 144.27C217.348 144.32 217.73 144.224 218.039 144.001C218.348 143.778 218.559 143.445 218.63 143.07H219.63V152.69H218.13V145.37H216.96L216.97 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M222.4 144.27C222.777 144.318 223.159 144.221 223.467 143.998C223.775 143.775 223.987 143.443 224.06 143.07H225.06V152.69H223.59V145.37H222.43L222.4 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M227.82 144.27C228.198 144.32 228.58 144.224 228.889 144.001C229.198 143.778 229.409 143.445 229.48 143.07H230.48V152.69H228.99V145.37H227.82V144.27Z"
        fill="#1B2236"
      />
      <path
        d="M232.2 145.37C232.179 145.057 232.223 144.743 232.328 144.448C232.434 144.153 232.599 143.883 232.813 143.654C233.027 143.425 233.286 143.242 233.574 143.118C233.861 142.993 234.172 142.929 234.485 142.929C234.798 142.929 235.109 142.993 235.396 143.118C235.684 143.242 235.943 143.425 236.157 143.654C236.371 143.883 236.536 144.153 236.642 144.448C236.747 144.743 236.791 145.057 236.77 145.37V150.37C236.791 150.683 236.747 150.996 236.642 151.292C236.536 151.587 236.371 151.857 236.157 152.086C235.943 152.315 235.684 152.497 235.396 152.622C235.109 152.747 234.798 152.811 234.485 152.811C234.172 152.811 233.861 152.747 233.574 152.622C233.286 152.497 233.027 152.315 232.813 152.086C232.599 151.857 232.434 151.587 232.328 151.292C232.223 150.996 232.179 150.683 232.2 150.37V145.37ZM233.7 150.45C233.7 151.14 234 151.4 234.48 151.4C234.96 151.4 235.26 151.14 235.26 150.45V145.27C235.26 144.59 234.96 144.33 234.48 144.33C234 144.33 233.7 144.59 233.7 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M238.68 144.27C239.056 144.32 239.438 144.223 239.745 144C240.052 143.777 240.261 143.444 240.33 143.07H241.33V152.69H239.82V145.37H238.66L238.68 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M244.1 144.27C244.478 144.32 244.86 144.224 245.169 144.001C245.478 143.778 245.689 143.445 245.76 143.07H246.76V152.69H245.25V145.37H244.08L244.1 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M249.53 144.27C249.907 144.318 250.288 144.221 250.597 143.998C250.905 143.775 251.117 143.443 251.19 143.07H252.19V152.69H250.69V145.37H249.53V144.27Z"
        fill="#1B2236"
      />
      <path
        d="M254.95 144.27C255.327 144.32 255.71 144.224 256.019 144.001C256.327 143.778 256.539 143.445 256.61 143.07H257.61V152.69H256.1V145.37H254.93L254.95 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M260.38 144.27C260.757 144.318 261.139 144.221 261.447 143.998C261.755 143.775 261.967 143.443 262.04 143.07H263.04V152.69H261.59V145.37H260.43L260.38 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M265.81 144.27C266.187 144.32 266.568 144.223 266.875 144C267.182 143.777 267.392 143.444 267.46 143.07H268.46V152.69H266.95V145.37H265.79L265.81 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M272.3 145.37C272.3 144.763 272.541 144.18 272.971 143.751C273.4 143.321 273.983 143.08 274.59 143.08C275.197 143.08 275.78 143.321 276.209 143.751C276.639 144.18 276.88 144.763 276.88 145.37V150.37C276.88 150.977 276.639 151.56 276.209 151.989C275.78 152.419 275.197 152.66 274.59 152.66C273.983 152.66 273.4 152.419 272.971 151.989C272.541 151.56 272.3 150.977 272.3 150.37V145.37ZM273.81 150.45C273.81 151.14 274.11 151.4 274.59 151.4C275.07 151.4 275.37 151.14 275.37 150.45V145.27C275.37 144.59 275.07 144.33 274.59 144.33C274.11 144.33 273.81 144.59 273.81 145.27V150.45Z"
        fill="#1B2236"
      />
      <path
        d="M278.78 144.27C279.158 144.32 279.54 144.224 279.849 144.001C280.158 143.778 280.369 143.445 280.44 143.07H281.44V152.69H279.94V145.37H278.77L278.78 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M284.21 144.27C284.587 144.32 284.968 144.223 285.275 144C285.582 143.777 285.792 143.444 285.86 143.07H286.86V152.69H285.35V145.37H284.19L284.21 144.27Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 162.26C24.1198 161.652 24.3611 161.07 24.7905 160.64C25.22 160.211 25.8024 159.97 26.4098 159.97C27.0171 159.97 27.5996 160.211 28.0291 160.64C28.4585 161.07 28.6998 161.652 28.6998 162.26V167.26C28.6998 167.867 28.4585 168.45 28.0291 168.879C27.5996 169.308 27.0171 169.55 26.4098 169.55C25.8024 169.55 25.22 169.308 24.7905 168.879C24.3611 168.45 24.1198 167.867 24.1198 167.26V162.26ZM25.6298 167.34C25.6298 168.03 25.9298 168.34 26.4098 168.34C26.8898 168.34 27.1898 168.08 27.1898 167.34V162.16C27.1898 161.48 26.8898 161.22 26.4098 161.22C25.9298 161.22 25.6298 161.48 25.6298 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M29.5901 162.26C29.5694 161.947 29.6131 161.633 29.7186 161.338C29.8241 161.043 29.989 160.772 30.2032 160.543C30.4174 160.315 30.6764 160.132 30.9639 160.007C31.2515 159.883 31.5616 159.818 31.8751 159.818C32.1886 159.818 32.4987 159.883 32.7863 160.007C33.0739 160.132 33.3328 160.315 33.547 160.543C33.7612 160.772 33.9262 161.043 34.0316 161.338C34.1371 161.633 34.1808 161.947 34.1601 162.26V167.26C34.1808 167.572 34.1371 167.886 34.0316 168.181C33.9262 168.476 33.7612 168.747 33.547 168.976C33.3328 169.205 33.0739 169.387 32.7863 169.512C32.4987 169.637 32.1886 169.701 31.8751 169.701C31.5616 169.701 31.2515 169.637 30.9639 169.512C30.6764 169.387 30.4174 169.205 30.2032 168.976C29.989 168.747 29.8241 168.476 29.7186 168.181C29.6131 167.886 29.5694 167.572 29.5901 167.26V162.26ZM31.0901 167.34C31.0901 168.03 31.4001 168.34 31.8801 168.34C32.3601 168.34 32.6601 168.08 32.6601 167.34V162.16C32.6601 161.48 32.3501 161.22 31.8801 161.22C31.4101 161.22 31.0901 161.48 31.0901 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 162.26C34.9699 161.652 35.2111 161.07 35.6406 160.64C36.0701 160.211 36.6525 159.97 37.2599 159.97C37.8672 159.97 38.4497 160.211 38.8792 160.64C39.3086 161.07 39.5499 161.652 39.5499 162.26V167.26C39.5499 167.867 39.3086 168.45 38.8792 168.879C38.4497 169.308 37.8672 169.55 37.2599 169.55C36.6525 169.55 36.0701 169.308 35.6406 168.879C35.2111 168.45 34.9699 167.867 34.9699 167.26V162.26ZM36.4799 167.34C36.4799 168.03 36.7799 168.34 37.2599 168.34C37.7399 168.34 38.0399 168.08 38.0399 167.34V162.16C38.0399 161.48 37.7399 161.22 37.2599 161.22C36.7799 161.22 36.4799 161.48 36.4799 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 161.16C41.8277 161.21 42.2102 161.114 42.519 160.89C42.8277 160.667 43.0392 160.334 43.1101 159.96H44.1101V169.55H42.5901V162.23H41.4201L41.4501 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M49.0001 161.16C49.3778 161.21 49.7603 161.114 50.069 160.89C50.3778 160.667 50.5893 160.334 50.6601 159.96H51.6601V169.55H50.1501V162.23H48.9801L49.0001 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M54.4298 161.16C54.8071 161.208 55.1884 161.11 55.4966 160.887C55.8047 160.665 56.0168 160.333 56.0898 159.96H57.0898V169.55H55.5898V162.23H54.4298V161.16Z"
        fill="#1B2236"
      />
      <path
        d="M59.86 161.16C60.2365 161.21 60.6177 161.113 60.9248 160.89C61.2319 160.666 61.4414 160.333 61.51 159.96H62.51V169.55H61V162.23H59.84L59.86 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M65.2802 161.16C65.6578 161.21 66.0403 161.114 66.3491 160.89C66.6578 160.667 66.8693 160.334 66.9402 159.96H67.9402V169.55H66.4402V162.23H65.2702L65.2802 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M70.7099 161.16C71.0871 161.208 71.4684 161.11 71.7766 160.887C72.0847 160.665 72.2968 160.333 72.3699 159.96H73.3699V169.55H71.8599V162.23H70.6999L70.7099 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M77.1999 162.26C77.1999 161.652 77.4411 161.07 77.8706 160.64C78.3 160.211 78.8825 159.97 79.4899 159.97C80.0972 159.97 80.6797 160.211 81.1091 160.64C81.5386 161.07 81.7799 161.652 81.7799 162.26V167.26C81.7799 167.867 81.5386 168.45 81.1091 168.879C80.6797 169.308 80.0972 169.55 79.4899 169.55C78.8825 169.55 78.3 169.308 77.8706 168.879C77.4411 168.45 77.1999 167.867 77.1999 167.26V162.26ZM78.7099 167.34C78.7099 168.03 79.0099 168.34 79.4899 168.34C79.9699 168.34 80.2699 168.08 80.2699 167.34V162.16C80.2699 161.48 79.9699 161.22 79.4899 161.22C79.0099 161.22 78.7099 161.48 78.7099 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M82.5901 162.26C82.5694 161.947 82.6131 161.633 82.7186 161.338C82.8241 161.043 82.989 160.772 83.2032 160.543C83.4174 160.315 83.6764 160.132 83.9639 160.007C84.2515 159.883 84.5616 159.818 84.8751 159.818C85.1886 159.818 85.4987 159.883 85.7863 160.007C86.0739 160.132 86.3328 160.315 86.547 160.543C86.7612 160.772 86.9262 161.043 87.0316 161.338C87.1371 161.633 87.1808 161.947 87.1601 162.26V167.26C87.1808 167.572 87.1371 167.886 87.0316 168.181C86.9262 168.476 86.7612 168.747 86.547 168.976C86.3328 169.205 86.0739 169.387 85.7863 169.512C85.4987 169.637 85.1886 169.701 84.8751 169.701C84.5616 169.701 84.2515 169.637 83.9639 169.512C83.6764 169.387 83.4174 169.205 83.2032 168.976C82.989 168.747 82.8241 168.476 82.7186 168.181C82.6131 167.886 82.5694 167.572 82.5901 167.26V162.26ZM84.0901 167.34C84.0901 168.03 84.4001 168.34 84.8801 168.34C85.3601 168.34 85.6601 168.08 85.6601 167.34V162.16C85.6601 161.48 85.3601 161.22 84.8801 161.22C84.4001 161.22 84.0901 161.48 84.0901 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M88.05 162.26C88.05 161.652 88.2912 161.07 88.7207 160.64C89.1501 160.211 89.7326 159.97 90.34 159.97C90.9473 159.97 91.5298 160.211 91.9592 160.64C92.3887 161.07 92.6299 161.652 92.6299 162.26V167.26C92.6299 167.867 92.3887 168.45 91.9592 168.879C91.5298 169.308 90.9473 169.55 90.34 169.55C89.7326 169.55 89.1501 169.308 88.7207 168.879C88.2912 168.45 88.05 167.867 88.05 167.26V162.26ZM89.56 167.34C89.56 168.03 89.86 168.34 90.34 168.34C90.82 168.34 91.1199 168.08 91.1199 167.34V162.16C91.1199 161.48 90.82 161.22 90.34 161.22C89.86 161.22 89.56 161.48 89.56 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M93.4801 162.26C93.4801 161.652 93.7214 161.07 94.1509 160.64C94.5803 160.211 95.1628 159.97 95.7701 159.97C96.3775 159.97 96.9599 160.211 97.3894 160.64C97.8189 161.07 98.0601 161.652 98.0601 162.26V167.26C98.0601 167.867 97.8189 168.45 97.3894 168.879C96.9599 169.308 96.3775 169.55 95.7701 169.55C95.1628 169.55 94.5803 169.308 94.1509 168.879C93.7214 168.45 93.4801 167.867 93.4801 167.26V162.26ZM94.9901 167.34C94.9901 168.03 95.2901 168.34 95.7701 168.34C96.2501 168.34 96.5501 168.08 96.5501 167.34V162.16C96.5501 161.48 96.2501 161.22 95.7701 161.22C95.2901 161.22 94.9901 161.48 94.9901 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M98.9099 162.26C98.8892 161.947 98.933 161.633 99.0384 161.338C99.1439 161.043 99.3088 160.772 99.523 160.543C99.7372 160.315 99.9962 160.132 100.284 160.007C100.571 159.883 100.881 159.818 101.195 159.818C101.508 159.818 101.819 159.883 102.106 160.007C102.394 160.132 102.653 160.315 102.867 160.543C103.081 160.772 103.246 161.043 103.351 161.338C103.457 161.633 103.501 161.947 103.48 162.26V167.26C103.501 167.572 103.457 167.886 103.351 168.181C103.246 168.476 103.081 168.747 102.867 168.976C102.653 169.205 102.394 169.387 102.106 169.512C101.819 169.637 101.508 169.701 101.195 169.701C100.881 169.701 100.571 169.637 100.284 169.512C99.9962 169.387 99.7372 169.205 99.523 168.976C99.3088 168.747 99.1439 168.476 99.0384 168.181C98.933 167.886 98.8892 167.572 98.9099 167.26V162.26ZM100.41 167.34C100.41 168.03 100.71 168.34 101.19 168.34C101.67 168.34 101.97 168.08 101.97 167.34V162.16C101.97 161.48 101.67 161.22 101.19 161.22C100.71 161.22 100.41 161.48 100.41 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M109.59 161.16C109.968 161.21 110.35 161.114 110.659 160.89C110.968 160.667 111.179 160.334 111.25 159.96H112.25V169.55H110.74V162.23H109.59V161.16Z"
        fill="#1B2236"
      />
      <path
        d="M115.06 161.16C115.437 161.208 115.819 161.11 116.127 160.887C116.435 160.665 116.647 160.333 116.72 159.96H117.72V169.55H116.21V162.23H115.05L115.06 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M120.49 161.16C120.866 161.21 121.248 161.113 121.555 160.89C121.862 160.666 122.071 160.333 122.14 159.96H123.14V169.55H121.63V162.23H120.49V161.16Z"
        fill="#1B2236"
      />
      <path
        d="M125.91 161.16C126.288 161.21 126.67 161.114 126.979 160.89C127.288 160.667 127.499 160.334 127.57 159.96H128.57V169.55H127.07V162.23H125.9L125.91 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M131.34 161.16C131.718 161.208 132.099 161.11 132.407 160.887C132.715 160.665 132.927 160.333 133 159.96H134V169.55H132.49V162.23H131.33L131.34 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M136.76 161.16C137.138 161.21 137.52 161.114 137.829 160.89C138.138 160.667 138.349 160.334 138.42 159.96H139.42V169.55H137.92V162.23H136.75L136.76 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M141.13 162.26C141.13 161.652 141.371 161.07 141.801 160.64C142.23 160.211 142.813 159.97 143.42 159.97C144.027 159.97 144.61 160.211 145.039 160.64C145.469 161.07 145.71 161.652 145.71 162.26V167.26C145.71 167.867 145.469 168.45 145.039 168.879C144.61 169.308 144.027 169.55 143.42 169.55C142.813 169.55 142.23 169.308 141.801 168.879C141.371 168.45 141.13 167.867 141.13 167.26V162.26ZM142.64 167.34C142.64 168.03 142.94 168.34 143.42 168.34C143.9 168.34 144.2 168.08 144.2 167.34V162.16C144.2 161.48 143.9 161.22 143.42 161.22C142.94 161.22 142.64 161.48 142.64 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M146.59 162.26C146.59 161.652 146.831 161.07 147.261 160.64C147.69 160.211 148.273 159.97 148.88 159.97C149.487 159.97 150.07 160.211 150.499 160.64C150.929 161.07 151.17 161.652 151.17 162.26V167.26C151.17 167.867 150.929 168.45 150.499 168.879C150.07 169.308 149.487 169.55 148.88 169.55C148.273 169.55 147.69 169.308 147.261 168.879C146.831 168.45 146.59 167.867 146.59 167.26V162.26ZM148.1 167.34C148.1 168.03 148.4 168.34 148.88 168.34C149.36 168.34 149.66 168.08 149.66 167.34V162.16C149.66 161.48 149.36 161.22 148.88 161.22C148.4 161.22 148.1 161.48 148.1 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M151.99 162.26C151.969 161.947 152.013 161.633 152.119 161.338C152.224 161.043 152.389 160.772 152.603 160.543C152.817 160.315 153.076 160.132 153.364 160.007C153.651 159.883 153.962 159.818 154.275 159.818C154.588 159.818 154.899 159.883 155.186 160.007C155.474 160.132 155.733 160.315 155.947 160.543C156.161 160.772 156.326 161.043 156.432 161.338C156.537 161.633 156.581 161.947 156.56 162.26V167.26C156.581 167.572 156.537 167.886 156.432 168.181C156.326 168.476 156.161 168.747 155.947 168.976C155.733 169.205 155.474 169.387 155.186 169.512C154.899 169.637 154.588 169.701 154.275 169.701C153.962 169.701 153.651 169.637 153.364 169.512C153.076 169.387 152.817 169.205 152.603 168.976C152.389 168.747 152.224 168.476 152.119 168.181C152.013 167.886 151.969 167.572 151.99 167.26V162.26ZM153.49 167.34C153.49 168.03 153.79 168.34 154.27 168.34C154.75 168.34 155.05 168.08 155.05 167.34V162.16C155.05 161.48 154.75 161.22 154.27 161.22C153.79 161.22 153.49 161.48 153.49 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M157.41 162.26C157.41 161.652 157.651 161.07 158.081 160.64C158.51 160.211 159.092 159.97 159.7 159.97C160.307 159.97 160.89 160.211 161.319 160.64C161.749 161.07 161.99 161.652 161.99 162.26V167.26C161.99 167.867 161.749 168.45 161.319 168.879C160.89 169.308 160.307 169.55 159.7 169.55C159.092 169.55 158.51 169.308 158.081 168.879C157.651 168.45 157.41 167.867 157.41 167.26V162.26ZM158.92 167.34C158.92 168.03 159.22 168.34 159.7 168.34C160.18 168.34 160.48 168.08 160.48 167.34V162.16C160.48 161.48 160.18 161.22 159.7 161.22C159.22 161.22 158.92 161.48 158.92 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M162.84 162.26C162.819 161.947 162.863 161.633 162.969 161.338C163.074 161.043 163.239 160.772 163.453 160.543C163.667 160.315 163.926 160.132 164.214 160.007C164.502 159.883 164.812 159.818 165.125 159.818C165.439 159.818 165.749 159.883 166.036 160.007C166.324 160.132 166.583 160.315 166.797 160.543C167.011 160.772 167.176 161.043 167.282 161.338C167.387 161.633 167.431 161.947 167.41 162.26V167.26C167.431 167.572 167.387 167.886 167.282 168.181C167.176 168.476 167.011 168.747 166.797 168.976C166.583 169.205 166.324 169.387 166.036 169.512C165.749 169.637 165.439 169.701 165.125 169.701C164.812 169.701 164.502 169.637 164.214 169.512C163.926 169.387 163.667 169.205 163.453 168.976C163.239 168.747 163.074 168.476 162.969 168.181C162.863 167.886 162.819 167.572 162.84 167.26V162.26ZM164.34 167.34C164.34 168.03 164.65 168.34 165.13 168.34C165.61 168.34 165.91 168.08 165.91 167.34V162.16C165.91 161.48 165.6 161.22 165.13 161.22C164.66 161.22 164.34 161.48 164.34 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M168.26 162.26C168.26 161.652 168.501 161.07 168.931 160.64C169.36 160.211 169.943 159.97 170.55 159.97C171.157 159.97 171.74 160.211 172.169 160.64C172.599 161.07 172.84 161.652 172.84 162.26V167.26C172.84 167.867 172.599 168.45 172.169 168.879C171.74 169.308 171.157 169.55 170.55 169.55C169.943 169.55 169.36 169.308 168.931 168.879C168.501 168.45 168.26 167.867 168.26 167.26V162.26ZM169.77 167.34C169.77 168.03 170.07 168.34 170.55 168.34C171.03 168.34 171.33 168.08 171.33 167.34V162.16C171.33 161.48 171.03 161.22 170.55 161.22C170.07 161.22 169.77 161.48 169.77 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M173.69 162.26C173.67 161.947 173.713 161.633 173.819 161.338C173.924 161.043 174.089 160.772 174.303 160.543C174.518 160.315 174.776 160.132 175.064 160.007C175.352 159.883 175.662 159.818 175.975 159.818C176.289 159.818 176.599 159.883 176.886 160.007C177.174 160.132 177.433 160.315 177.647 160.543C177.861 160.772 178.026 161.043 178.132 161.338C178.237 161.633 178.281 161.947 178.26 162.26V167.26C178.281 167.572 178.237 167.886 178.132 168.181C178.026 168.476 177.861 168.747 177.647 168.976C177.433 169.205 177.174 169.387 176.886 169.512C176.599 169.637 176.289 169.701 175.975 169.701C175.662 169.701 175.352 169.637 175.064 169.512C174.776 169.387 174.518 169.205 174.303 168.976C174.089 168.747 173.924 168.476 173.819 168.181C173.713 167.886 173.67 167.572 173.69 167.26V162.26ZM175.2 167.34C175.2 168.03 175.5 168.34 175.98 168.34C176.46 168.34 176.76 168.08 176.76 167.34V162.16C176.76 161.48 176.46 161.22 175.98 161.22C175.5 161.22 175.2 161.48 175.2 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M182.29 161.16C182.668 161.21 183.05 161.114 183.359 160.89C183.668 160.667 183.879 160.334 183.95 159.96H184.95V169.55H183.44V162.23H182.29V161.16Z"
        fill="#1B2236"
      />
      <path
        d="M187.72 161.16C188.097 161.208 188.479 161.11 188.787 160.887C189.095 160.665 189.307 160.333 189.38 159.96H190.38V169.55H188.87V162.23H187.71L187.72 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M193.15 161.16C193.526 161.21 193.908 161.113 194.215 160.89C194.522 160.666 194.731 160.333 194.8 159.96H195.8V169.55H194.29V162.23H193.13L193.15 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M198.59 161.16C198.968 161.21 199.35 161.114 199.659 160.89C199.968 160.667 200.179 160.334 200.25 159.96H201.25V169.55H199.75V162.23H198.59V161.16Z"
        fill="#1B2236"
      />
      <path
        d="M204 161.16C204.377 161.208 204.758 161.11 205.067 160.887C205.375 160.665 205.587 160.333 205.66 159.96H206.66V169.55H205.15V162.23H203.99L204 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M212.59 162.26C212.569 161.947 212.613 161.633 212.719 161.338C212.824 161.043 212.989 160.772 213.203 160.543C213.417 160.315 213.676 160.132 213.964 160.007C214.252 159.883 214.562 159.818 214.875 159.818C215.189 159.818 215.499 159.883 215.786 160.007C216.074 160.132 216.333 160.315 216.547 160.543C216.761 160.772 216.926 161.043 217.032 161.338C217.137 161.633 217.181 161.947 217.16 162.26V167.26C217.181 167.572 217.137 167.886 217.032 168.181C216.926 168.476 216.761 168.747 216.547 168.976C216.333 169.205 216.074 169.387 215.786 169.512C215.499 169.637 215.189 169.701 214.875 169.701C214.562 169.701 214.252 169.637 213.964 169.512C213.676 169.387 213.417 169.205 213.203 168.976C212.989 168.747 212.824 168.476 212.719 168.181C212.613 167.886 212.569 167.572 212.59 167.26V162.26ZM214.09 167.34C214.09 168.03 214.39 168.34 214.87 168.34C215.35 168.34 215.65 168.08 215.65 167.34V162.16C215.65 161.48 215.35 161.22 214.87 161.22C214.39 161.22 214.09 161.48 214.09 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M218.04 162.26C218.04 161.652 218.281 161.07 218.711 160.64C219.14 160.211 219.723 159.97 220.33 159.97C220.938 159.97 221.52 160.211 221.949 160.64C222.379 161.07 222.62 161.652 222.62 162.26V167.26C222.62 167.867 222.379 168.45 221.949 168.879C221.52 169.308 220.938 169.55 220.33 169.55C219.723 169.55 219.14 169.308 218.711 168.879C218.281 168.45 218.04 167.867 218.04 167.26V162.26ZM219.55 167.34C219.55 168.03 219.85 168.34 220.33 168.34C220.81 168.34 221.11 168.08 221.11 167.34V162.16C221.11 161.48 220.81 161.22 220.33 161.22C219.85 161.22 219.55 161.48 219.55 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M223.47 162.26C223.449 161.947 223.493 161.633 223.598 161.338C223.704 161.043 223.869 160.772 224.083 160.543C224.297 160.315 224.556 160.132 224.844 160.007C225.131 159.883 225.442 159.818 225.755 159.818C226.068 159.818 226.379 159.883 226.666 160.007C226.954 160.132 227.213 160.315 227.427 160.543C227.641 160.772 227.806 161.043 227.911 161.338C228.017 161.633 228.061 161.947 228.04 162.26V167.26C228.061 167.572 228.017 167.886 227.911 168.181C227.806 168.476 227.641 168.747 227.427 168.976C227.213 169.205 226.954 169.387 226.666 169.512C226.379 169.637 226.068 169.701 225.755 169.701C225.442 169.701 225.131 169.637 224.844 169.512C224.556 169.387 224.297 169.205 224.083 168.976C223.869 168.747 223.704 168.476 223.598 168.181C223.493 167.886 223.449 167.572 223.47 167.26V162.26ZM224.97 167.34C224.97 168.03 225.28 168.34 225.76 168.34C226.24 168.34 226.54 168.08 226.54 167.34V162.16C226.54 161.48 226.23 161.22 225.76 161.22C225.29 161.22 224.97 161.48 224.97 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M228.89 162.26C228.89 161.652 229.131 161.07 229.561 160.64C229.99 160.211 230.572 159.97 231.18 159.97C231.787 159.97 232.37 160.211 232.799 160.64C233.229 161.07 233.47 161.652 233.47 162.26V167.26C233.47 167.867 233.229 168.45 232.799 168.879C232.37 169.308 231.787 169.55 231.18 169.55C230.572 169.55 229.99 169.308 229.561 168.879C229.131 168.45 228.89 167.867 228.89 167.26V162.26ZM230.4 167.34C230.4 168.03 230.7 168.34 231.18 168.34C231.66 168.34 231.96 168.08 231.96 167.34V162.16C231.96 161.48 231.66 161.22 231.18 161.22C230.7 161.22 230.4 161.48 230.4 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M234.32 162.26C234.32 161.959 234.379 161.661 234.494 161.383C234.609 161.106 234.778 160.853 234.991 160.64C235.203 160.428 235.456 160.259 235.734 160.144C236.011 160.029 236.309 159.97 236.61 159.97C236.911 159.97 237.208 160.029 237.486 160.144C237.764 160.259 238.017 160.428 238.229 160.64C238.442 160.853 238.611 161.106 238.726 161.383C238.841 161.661 238.9 161.959 238.9 162.26V167.26C238.9 167.56 238.841 167.858 238.726 168.136C238.611 168.414 238.442 168.666 238.229 168.879C238.017 169.092 237.764 169.26 237.486 169.375C237.208 169.491 236.911 169.55 236.61 169.55C236.309 169.55 236.011 169.491 235.734 169.375C235.456 169.26 235.203 169.092 234.991 168.879C234.778 168.666 234.609 168.414 234.494 168.136C234.379 167.858 234.32 167.56 234.32 167.26V162.26ZM235.83 167.34C235.83 168.03 236.13 168.34 236.61 168.34C237.09 168.34 237.39 168.08 237.39 167.34V162.16C237.39 161.48 237.09 161.22 236.61 161.22C236.13 161.22 235.83 161.48 235.83 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M239.75 162.26C239.729 161.947 239.773 161.633 239.878 161.338C239.984 161.043 240.149 160.772 240.363 160.543C240.577 160.315 240.836 160.132 241.124 160.007C241.411 159.883 241.721 159.818 242.035 159.818C242.348 159.818 242.658 159.883 242.946 160.007C243.234 160.132 243.492 160.315 243.707 160.543C243.921 160.772 244.086 161.043 244.191 161.338C244.297 161.633 244.34 161.947 244.32 162.26V167.26C244.34 167.572 244.297 167.886 244.191 168.181C244.086 168.476 243.921 168.747 243.707 168.976C243.492 169.205 243.234 169.387 242.946 169.512C242.658 169.637 242.348 169.701 242.035 169.701C241.721 169.701 241.411 169.637 241.124 169.512C240.836 169.387 240.577 169.205 240.363 168.976C240.149 168.747 239.984 168.476 239.878 168.181C239.773 167.886 239.729 167.572 239.75 167.26V162.26ZM241.25 167.34C241.25 168.03 241.55 168.34 242.03 168.34C242.51 168.34 242.81 168.08 242.81 167.34V162.16C242.81 161.48 242.51 161.22 242.03 161.22C241.55 161.22 241.25 161.48 241.25 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M249.42 162.26C249.42 161.652 249.661 161.07 250.091 160.64C250.52 160.211 251.103 159.97 251.71 159.97C252.317 159.97 252.9 160.211 253.329 160.64C253.759 161.07 254 161.652 254 162.26V167.26C254 167.867 253.759 168.45 253.329 168.879C252.9 169.308 252.317 169.55 251.71 169.55C251.103 169.55 250.52 169.308 250.091 168.879C249.661 168.45 249.42 167.867 249.42 167.26V162.26ZM250.93 167.34C250.93 168.03 251.23 168.34 251.71 168.34C252.19 168.34 252.49 168.08 252.49 167.34V162.16C252.49 161.48 252.19 161.22 251.71 161.22C251.23 161.22 250.93 161.48 250.93 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M255.9 161.16C256.277 161.208 256.658 161.11 256.967 160.887C257.275 160.665 257.487 160.333 257.56 159.96H258.56V169.55H257.05V162.23H255.89L255.9 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M260.27 162.26C260.27 161.652 260.511 161.07 260.941 160.64C261.37 160.211 261.953 159.97 262.56 159.97C263.168 159.97 263.75 160.211 264.179 160.64C264.609 161.07 264.85 161.652 264.85 162.26V167.26C264.85 167.867 264.609 168.45 264.179 168.879C263.75 169.308 263.168 169.55 262.56 169.55C261.953 169.55 261.37 169.308 260.941 168.879C260.511 168.45 260.27 167.867 260.27 167.26V162.26ZM261.78 167.34C261.78 168.03 262.08 168.34 262.56 168.34C263.04 168.34 263.34 168.08 263.34 167.34V162.16C263.34 161.48 263.04 161.22 262.56 161.22C262.08 161.22 261.78 161.48 261.78 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M266.75 161.16C267.128 161.21 267.51 161.114 267.819 160.89C268.128 160.667 268.339 160.334 268.41 159.96H269.41V169.55H267.91V162.23H266.74L266.75 161.16Z"
        fill="#1B2236"
      />
      <path
        d="M271.12 162.26C271.12 161.652 271.361 161.07 271.79 160.64C272.22 160.211 272.802 159.97 273.41 159.97C274.017 159.97 274.6 160.211 275.029 160.64C275.459 161.07 275.7 161.652 275.7 162.26V167.26C275.7 167.867 275.459 168.45 275.029 168.879C274.6 169.308 274.017 169.55 273.41 169.55C272.802 169.55 272.22 169.308 271.79 168.879C271.361 168.45 271.12 167.867 271.12 167.26V162.26ZM272.63 167.34C272.63 168.03 272.93 168.34 273.41 168.34C273.89 168.34 274.19 168.08 274.19 167.34V162.16C274.19 161.48 273.89 161.22 273.41 161.22C272.93 161.22 272.63 161.48 272.63 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M277.59 161.16C277.968 161.21 278.35 161.114 278.659 160.89C278.968 160.667 279.179 160.334 279.25 159.96H280.25V169.55H278.75V162.23H277.59V161.16Z"
        fill="#1B2236"
      />
      <path
        d="M281.97 162.26C281.97 161.652 282.211 161.07 282.641 160.64C283.07 160.211 283.653 159.97 284.26 159.97C284.867 159.97 285.45 160.211 285.879 160.64C286.309 161.07 286.55 161.652 286.55 162.26V167.26C286.55 167.867 286.309 168.45 285.879 168.879C285.45 169.308 284.867 169.55 284.26 169.55C283.653 169.55 283.07 169.308 282.641 168.879C282.211 168.45 281.97 167.867 281.97 167.26V162.26ZM283.48 167.34C283.48 168.03 283.78 168.34 284.26 168.34C284.74 168.34 285.04 168.08 285.04 167.34V162.16C285.04 161.48 284.74 161.22 284.26 161.22C283.78 161.22 283.48 161.48 283.48 162.16V167.34Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 178.69C24.1198 178.083 24.3611 177.501 24.7905 177.071C25.22 176.642 25.8024 176.4 26.4098 176.4C27.0171 176.4 27.5996 176.642 28.0291 177.071C28.4585 177.501 28.6998 178.083 28.6998 178.69V183.69C28.6998 184.298 28.4585 184.88 28.0291 185.31C27.5996 185.739 27.0171 185.98 26.4098 185.98C25.8024 185.98 25.22 185.739 24.7905 185.31C24.3611 184.88 24.1198 184.298 24.1198 183.69V178.69ZM25.6298 183.77C25.6298 184.46 25.9298 184.77 26.4098 184.77C26.8898 184.77 27.1898 184.51 27.1898 183.77V178.59C27.1898 177.91 26.8898 177.65 26.4098 177.65C25.9298 177.65 25.6298 177.91 25.6298 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M30.59 177.6C30.9676 177.651 31.3501 177.554 31.6589 177.331C31.9676 177.108 32.1792 176.775 32.25 176.4H33.25V185.99H31.75V178.69H30.59V177.6Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 178.69C34.9699 178.083 35.2111 177.501 35.6406 177.071C36.0701 176.642 36.6525 176.4 37.2599 176.4C37.8672 176.4 38.4497 176.642 38.8792 177.071C39.3086 177.501 39.5499 178.083 39.5499 178.69V183.69C39.5499 184.298 39.3086 184.88 38.8792 185.31C38.4497 185.739 37.8672 185.98 37.2599 185.98C36.6525 185.98 36.0701 185.739 35.6406 185.31C35.2111 184.88 34.9699 184.298 34.9699 183.69V178.69ZM36.4799 183.77C36.4799 184.46 36.7799 184.77 37.2599 184.77C37.7399 184.77 38.0399 184.51 38.0399 183.77V178.59C38.0399 177.91 37.7399 177.65 37.2599 177.65C36.7799 177.65 36.4799 177.91 36.4799 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 177.6C41.8277 177.651 42.2102 177.554 42.519 177.331C42.8277 177.108 43.0392 176.775 43.1101 176.4H44.1101V185.99H42.5901V178.69H41.4201L41.4501 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M45.8299 178.69C45.8092 178.377 45.8529 178.064 45.9583 177.769C46.0638 177.473 46.2287 177.203 46.443 176.974C46.6572 176.745 46.9161 176.563 47.2037 176.438C47.4913 176.313 47.8014 176.249 48.1148 176.249C48.4283 176.249 48.7385 176.313 49.026 176.438C49.3136 176.563 49.5725 176.745 49.7868 176.974C50.001 177.203 50.1659 177.473 50.2714 177.769C50.3768 178.064 50.4206 178.377 50.3999 178.69V183.69C50.4206 184.003 50.3768 184.317 50.2714 184.612C50.1659 184.907 50.001 185.178 49.7868 185.406C49.5725 185.635 49.3136 185.818 49.026 185.942C48.7385 186.067 48.4283 186.132 48.1148 186.132C47.8014 186.132 47.4913 186.067 47.2037 185.942C46.9161 185.818 46.6572 185.635 46.443 185.406C46.2287 185.178 46.0638 184.907 45.9583 184.612C45.8529 184.317 45.8092 184.003 45.8299 183.69V178.69ZM47.3299 183.77C47.3299 184.46 47.6299 184.77 48.1099 184.77C48.5899 184.77 48.8899 184.51 48.8899 183.77V178.59C48.8899 177.91 48.5899 177.65 48.1099 177.65C47.6299 177.65 47.3299 177.91 47.3299 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M52.3102 177.6C52.6867 177.65 53.0679 177.553 53.375 177.33C53.6821 177.107 53.8916 176.774 53.9602 176.4H54.9602V185.99H53.4502V178.69H52.2902L52.3102 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M57.7299 177.6C58.1075 177.651 58.49 177.554 58.7988 177.331C59.1075 177.108 59.319 176.775 59.3899 176.4H60.3899V185.99H58.8899V178.69H57.7199L57.7299 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M63.1601 177.6C63.5373 177.648 63.9186 177.551 64.2268 177.328C64.5349 177.105 64.747 176.774 64.8201 176.4H65.8201V185.99H64.3101V178.69H63.1501L63.1601 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M68.59 177.6C68.9676 177.651 69.3501 177.554 69.6589 177.331C69.9676 177.108 70.1792 176.775 70.25 176.4H71.25V185.99H69.74V178.69H68.59V177.6Z"
        fill="#1B2236"
      />
      <path
        d="M74.0101 177.6C74.3874 177.648 74.7687 177.551 75.0769 177.328C75.385 177.105 75.5971 176.774 75.6702 176.4H76.6702V185.99H75.1602V178.69H74.0002L74.0101 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M79.4398 177.6C79.8163 177.65 80.1975 177.553 80.5047 177.33C80.8118 177.107 81.0213 176.774 81.0898 176.4H82.0898V185.99H80.5898V178.69H79.4298L79.4398 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M84.8598 177.6C85.2374 177.651 85.6199 177.554 85.9287 177.331C86.2374 177.108 86.4489 176.775 86.5198 176.4H87.5198V185.99H86.0198V178.69H84.8498L84.8598 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M89.2301 178.69C89.2301 178.083 89.4714 177.501 89.9009 177.071C90.3303 176.642 90.9128 176.4 91.5201 176.4C92.1275 176.4 92.7099 176.642 93.1394 177.071C93.5689 177.501 93.8101 178.083 93.8101 178.69V183.69C93.8101 184.298 93.5689 184.88 93.1394 185.31C92.7099 185.739 92.1275 185.98 91.5201 185.98C90.9128 185.98 90.3303 185.739 89.9009 185.31C89.4714 184.88 89.2301 184.298 89.2301 183.69V178.69ZM90.7401 183.77C90.7401 184.46 91.0401 184.77 91.5201 184.77C92.0001 184.77 92.3001 184.51 92.3001 183.77V178.59C92.3001 177.91 92.0001 177.65 91.5201 177.65C91.0401 177.65 90.7401 177.91 90.7401 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M95.7099 177.6C96.0875 177.651 96.47 177.554 96.7788 177.331C97.0875 177.108 97.299 176.775 97.3699 176.4H98.3699V185.99H96.8699V178.69H95.6999L95.7099 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M103.26 177.6C103.638 177.651 104.02 177.554 104.329 177.331C104.638 177.108 104.849 176.775 104.92 176.4H105.92V185.99H104.42V178.69H103.25L103.26 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M107.59 178.69C107.59 178.083 107.831 177.501 108.261 177.071C108.69 176.642 109.273 176.4 109.88 176.4C110.487 176.4 111.07 176.642 111.499 177.071C111.929 177.501 112.17 178.083 112.17 178.69V183.69C112.17 184.298 111.929 184.88 111.499 185.31C111.07 185.739 110.487 185.98 109.88 185.98C109.273 185.98 108.69 185.739 108.261 185.31C107.831 184.88 107.59 184.298 107.59 183.69V178.69ZM109.1 183.77C109.1 184.46 109.4 184.77 109.88 184.77C110.36 184.77 110.66 184.51 110.66 183.77V178.59C110.66 177.91 110.36 177.65 109.88 177.65C109.4 177.65 109.1 177.91 109.1 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M114.11 177.6C114.488 177.651 114.87 177.554 115.179 177.331C115.488 177.108 115.699 176.775 115.77 176.4H116.77V185.99H115.26V178.69H114.09L114.11 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M121.66 177.6C122.037 177.651 122.42 177.554 122.729 177.331C123.037 177.108 123.249 176.775 123.32 176.4H124.32V185.99H122.81V178.69H121.64L121.66 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M128.16 178.69C128.139 178.377 128.183 178.064 128.288 177.769C128.394 177.473 128.559 177.203 128.773 176.974C128.987 176.745 129.246 176.563 129.534 176.438C129.821 176.313 130.131 176.249 130.445 176.249C130.758 176.249 131.069 176.313 131.356 176.438C131.644 176.563 131.903 176.745 132.117 176.974C132.331 177.203 132.496 177.473 132.601 177.769C132.707 178.064 132.751 178.377 132.73 178.69V183.69C132.751 184.003 132.707 184.317 132.601 184.612C132.496 184.907 132.331 185.178 132.117 185.406C131.903 185.635 131.644 185.818 131.356 185.942C131.069 186.067 130.758 186.132 130.445 186.132C130.131 186.132 129.821 186.067 129.534 185.942C129.246 185.818 128.987 185.635 128.773 185.406C128.559 185.178 128.394 184.907 128.288 184.612C128.183 184.317 128.139 184.003 128.16 183.69V178.69ZM129.67 183.77C129.67 184.46 129.97 184.77 130.45 184.77C130.93 184.77 131.23 184.51 131.23 183.77V178.59C131.23 177.91 130.93 177.65 130.45 177.65C129.97 177.65 129.67 177.91 129.67 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M134.64 177.6C135.017 177.648 135.399 177.551 135.707 177.328C136.015 177.105 136.227 176.774 136.3 176.4H137.3V185.99H135.79V178.69H134.63L134.64 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M140.07 177.6C140.446 177.65 140.828 177.553 141.135 177.33C141.442 177.107 141.651 176.774 141.72 176.4H142.72V185.99H141.21V178.69H140.05L140.07 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M145.49 177.6C145.868 177.651 146.25 177.554 146.559 177.331C146.868 177.108 147.079 176.775 147.15 176.4H148.15V185.99H146.65V178.69H145.49V177.6Z"
        fill="#1B2236"
      />
      <path
        d="M150.92 177.6C151.297 177.65 151.678 177.553 151.985 177.33C152.292 177.107 152.502 176.774 152.57 176.4H153.57V185.99H152.06V178.69H150.9L150.92 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M156.34 177.6C156.718 177.651 157.1 177.554 157.409 177.331C157.718 177.108 157.929 176.775 158 176.4H159V185.99H157.5V178.69H156.33L156.34 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M160.71 178.69C160.71 178.39 160.769 178.092 160.884 177.814C161 177.536 161.168 177.284 161.381 177.071C161.593 176.858 161.846 176.69 162.124 176.575C162.402 176.46 162.699 176.4 163 176.4C163.301 176.4 163.599 176.46 163.876 176.575C164.154 176.69 164.407 176.858 164.619 177.071C164.832 177.284 165.001 177.536 165.116 177.814C165.231 178.092 165.29 178.39 165.29 178.69V183.69C165.29 183.991 165.231 184.289 165.116 184.567C165.001 184.845 164.832 185.097 164.619 185.31C164.407 185.522 164.154 185.691 163.876 185.806C163.599 185.921 163.301 185.98 163 185.98C162.699 185.98 162.402 185.921 162.124 185.806C161.846 185.691 161.593 185.522 161.381 185.31C161.168 185.097 161 184.845 160.884 184.567C160.769 184.289 160.71 183.991 160.71 183.69V178.69ZM162.22 183.77C162.22 184.46 162.52 184.77 163 184.77C163.48 184.77 163.78 184.51 163.78 183.77V178.59C163.78 177.91 163.48 177.65 163 177.65C162.52 177.65 162.22 177.91 162.22 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M167.19 177.6C167.568 177.651 167.95 177.554 168.259 177.331C168.568 177.108 168.779 176.775 168.85 176.4H169.85V185.99H168.34V178.69H167.19V177.6Z"
        fill="#1B2236"
      />
      <path
        d="M171.59 178.69C171.569 178.377 171.613 178.064 171.719 177.769C171.824 177.473 171.989 177.203 172.203 176.974C172.417 176.745 172.676 176.563 172.964 176.438C173.252 176.313 173.562 176.249 173.875 176.249C174.189 176.249 174.499 176.313 174.786 176.438C175.074 176.563 175.333 176.745 175.547 176.974C175.761 177.203 175.926 177.473 176.032 177.769C176.137 178.064 176.181 178.377 176.16 178.69V183.69C176.181 184.003 176.137 184.317 176.032 184.612C175.926 184.907 175.761 185.178 175.547 185.406C175.333 185.635 175.074 185.818 174.786 185.942C174.499 186.067 174.189 186.132 173.875 186.132C173.562 186.132 173.252 186.067 172.964 185.942C172.676 185.818 172.417 185.635 172.203 185.406C171.989 185.178 171.824 184.907 171.719 184.612C171.613 184.317 171.569 184.003 171.59 183.69V178.69ZM173.09 183.77C173.09 184.46 173.39 184.77 173.87 184.77C174.35 184.77 174.65 184.51 174.65 183.77V178.59C174.65 177.91 174.35 177.65 173.87 177.65C173.39 177.65 173.09 177.91 173.09 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M178.05 177.6C178.426 177.65 178.808 177.553 179.115 177.33C179.422 177.107 179.631 176.774 179.7 176.4H180.7V185.99H179.19V178.69H178.03L178.05 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M182.42 178.69C182.399 178.377 182.443 178.064 182.549 177.769C182.654 177.473 182.819 177.203 183.033 176.974C183.248 176.745 183.506 176.563 183.794 176.438C184.082 176.313 184.392 176.249 184.705 176.249C185.019 176.249 185.329 176.313 185.616 176.438C185.904 176.563 186.163 176.745 186.377 176.974C186.591 177.203 186.756 177.473 186.862 177.769C186.967 178.064 187.011 178.377 186.99 178.69V183.69C187.011 184.003 186.967 184.317 186.862 184.612C186.756 184.907 186.591 185.178 186.377 185.406C186.163 185.635 185.904 185.818 185.616 185.942C185.329 186.067 185.019 186.132 184.705 186.132C184.392 186.132 184.082 186.067 183.794 185.942C183.506 185.818 183.248 185.635 183.033 185.406C182.819 185.178 182.654 184.907 182.549 184.612C182.443 184.317 182.399 184.003 182.42 183.69V178.69ZM183.92 183.77C183.92 184.46 184.23 184.77 184.71 184.77C185.19 184.77 185.49 184.51 185.49 183.77V178.59C185.49 177.91 185.18 177.65 184.71 177.65C184.24 177.65 183.92 177.91 183.92 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M188.9 177.6C189.277 177.648 189.658 177.551 189.967 177.328C190.275 177.105 190.487 176.774 190.56 176.4H191.56V185.99H190.05V178.69H188.89L188.9 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M199.64 178.69C199.64 178.083 199.881 177.501 200.311 177.071C200.74 176.642 201.322 176.4 201.93 176.4C202.537 176.4 203.12 176.642 203.549 177.071C203.979 177.501 204.22 178.083 204.22 178.69V183.69C204.22 184.298 203.979 184.88 203.549 185.31C203.12 185.739 202.537 185.98 201.93 185.98C201.322 185.98 200.74 185.739 200.311 185.31C199.881 184.88 199.64 184.298 199.64 183.69V178.69ZM201.15 183.77C201.15 184.46 201.45 184.77 201.93 184.77C202.41 184.77 202.71 184.51 202.71 183.77V178.59C202.71 177.91 202.41 177.65 201.93 177.65C201.45 177.65 201.15 177.91 201.15 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M206.12 177.6C206.498 177.651 206.88 177.554 207.189 177.331C207.498 177.108 207.709 176.775 207.78 176.4H208.78V185.99H207.28V178.69H206.11L206.12 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M210.49 178.69C210.49 178.083 210.731 177.501 211.161 177.071C211.59 176.642 212.173 176.4 212.78 176.4C213.387 176.4 213.97 176.642 214.399 177.071C214.829 177.501 215.07 178.083 215.07 178.69V183.69C215.07 184.298 214.829 184.88 214.399 185.31C213.97 185.739 213.387 185.98 212.78 185.98C212.173 185.98 211.59 185.739 211.161 185.31C210.731 184.88 210.49 184.298 210.49 183.69V178.69ZM212 183.77C212 184.46 212.3 184.77 212.78 184.77C213.26 184.77 213.56 184.51 213.56 183.77V178.59C213.56 177.91 213.26 177.65 212.78 177.65C212.3 177.65 212 177.91 212 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M216.97 177.6C217.348 177.651 217.73 177.554 218.039 177.331C218.348 177.108 218.559 176.775 218.63 176.4H219.63V185.99H218.13V178.69H216.96L216.97 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M222.4 177.6C222.777 177.648 223.159 177.551 223.467 177.328C223.775 177.105 223.987 176.774 224.06 176.4H225.06V185.99H223.59V178.69H222.43L222.4 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M227.82 177.6C228.198 177.651 228.58 177.554 228.889 177.331C229.198 177.108 229.409 176.775 229.48 176.4H230.48V185.99H228.99V178.69H227.82V177.6Z"
        fill="#1B2236"
      />
      <path
        d="M232.2 178.69C232.179 178.377 232.223 178.064 232.328 177.769C232.434 177.473 232.599 177.203 232.813 176.974C233.027 176.745 233.286 176.563 233.574 176.438C233.861 176.313 234.172 176.249 234.485 176.249C234.798 176.249 235.109 176.313 235.396 176.438C235.684 176.563 235.943 176.745 236.157 176.974C236.371 177.203 236.536 177.473 236.642 177.769C236.747 178.064 236.791 178.377 236.77 178.69V183.69C236.791 184.003 236.747 184.317 236.642 184.612C236.536 184.907 236.371 185.178 236.157 185.406C235.943 185.635 235.684 185.818 235.396 185.942C235.109 186.067 234.798 186.132 234.485 186.132C234.172 186.132 233.861 186.067 233.574 185.942C233.286 185.818 233.027 185.635 232.813 185.406C232.599 185.178 232.434 184.907 232.328 184.612C232.223 184.317 232.179 184.003 232.2 183.69V178.69ZM233.7 183.77C233.7 184.46 234 184.77 234.48 184.77C234.96 184.77 235.26 184.51 235.26 183.77V178.59C235.26 177.91 234.96 177.65 234.48 177.65C234 177.65 233.7 177.91 233.7 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M238.68 177.6C239.056 177.65 239.438 177.553 239.745 177.33C240.052 177.107 240.261 176.774 240.33 176.4H241.33V185.99H239.82V178.69H238.66L238.68 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M244.1 177.6C244.478 177.651 244.86 177.554 245.169 177.331C245.478 177.108 245.689 176.775 245.76 176.4H246.76V185.99H245.25V178.69H244.08L244.1 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M249.53 177.6C249.907 177.648 250.288 177.551 250.597 177.328C250.905 177.105 251.117 176.774 251.19 176.4H252.19V185.99H250.69V178.69H249.53V177.6Z"
        fill="#1B2236"
      />
      <path
        d="M254.95 177.6C255.327 177.651 255.71 177.554 256.019 177.331C256.327 177.108 256.539 176.775 256.61 176.4H257.61V185.99H256.1V178.69H254.93L254.95 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M260.38 177.6C260.757 177.648 261.139 177.551 261.447 177.328C261.755 177.105 261.967 176.774 262.04 176.4H263.04V185.99H261.59V178.69H260.43L260.38 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M265.81 177.6C266.187 177.65 266.568 177.553 266.875 177.33C267.182 177.107 267.392 176.774 267.46 176.4H268.46V185.99H266.95V178.69H265.79L265.81 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M272.3 178.69C272.3 178.083 272.541 177.501 272.971 177.071C273.4 176.642 273.983 176.4 274.59 176.4C275.197 176.4 275.78 176.642 276.209 177.071C276.639 177.501 276.88 178.083 276.88 178.69V183.69C276.88 184.298 276.639 184.88 276.209 185.31C275.78 185.739 275.197 185.98 274.59 185.98C273.983 185.98 273.4 185.739 272.971 185.31C272.541 184.88 272.3 184.298 272.3 183.69V178.69ZM273.81 183.77C273.81 184.46 274.11 184.77 274.59 184.77C275.07 184.77 275.37 184.51 275.37 183.77V178.59C275.37 177.91 275.07 177.65 274.59 177.65C274.11 177.65 273.81 177.91 273.81 178.59V183.77Z"
        fill="#1B2236"
      />
      <path
        d="M278.78 177.6C279.158 177.651 279.54 177.554 279.849 177.331C280.158 177.108 280.369 176.775 280.44 176.4H281.44V185.99H279.94V178.69H278.77L278.78 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M284.21 177.6C284.587 177.65 284.968 177.553 285.275 177.33C285.582 177.107 285.792 176.774 285.86 176.4H286.86V185.99H285.35V178.69H284.19L284.21 177.6Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 195.59C24.1198 194.982 24.3611 194.4 24.7905 193.971C25.22 193.541 25.8024 193.3 26.4098 193.3C27.0171 193.3 27.5996 193.541 28.0291 193.971C28.4585 194.4 28.6998 194.982 28.6998 195.59V200.59C28.6998 201.197 28.4585 201.78 28.0291 202.209C27.5996 202.639 27.0171 202.88 26.4098 202.88C25.8024 202.88 25.22 202.639 24.7905 202.209C24.3611 201.78 24.1198 201.197 24.1198 200.59V195.59ZM25.5898 200.69C25.5898 201.38 25.8898 201.64 26.3698 201.64C26.8498 201.64 27.1498 201.38 27.1498 200.69V195.49C27.1498 194.81 26.8498 194.55 26.3698 194.55C25.8898 194.55 25.5898 194.81 25.5898 195.49V200.69Z"
        fill="#1B2236"
      />
      <path
        d="M29.5901 195.59C29.5694 195.277 29.6131 194.963 29.7186 194.668C29.8241 194.373 29.989 194.102 30.2032 193.874C30.4174 193.645 30.6764 193.462 30.9639 193.338C31.2515 193.213 31.5616 193.148 31.8751 193.148C32.1886 193.148 32.4987 193.213 32.7863 193.338C33.0739 193.462 33.3328 193.645 33.547 193.874C33.7612 194.102 33.9262 194.373 34.0316 194.668C34.1371 194.963 34.1808 195.277 34.1601 195.59V200.59C34.1808 200.902 34.1371 201.216 34.0316 201.511C33.9262 201.807 33.7612 202.077 33.547 202.306C33.3328 202.535 33.0739 202.717 32.7863 202.842C32.4987 202.967 32.1886 203.031 31.8751 203.031C31.5616 203.031 31.2515 202.967 30.9639 202.842C30.6764 202.717 30.4174 202.535 30.2032 202.306C29.989 202.077 29.8241 201.807 29.7186 201.511C29.6131 201.216 29.5694 200.902 29.5901 200.59V195.59ZM31.0901 200.67C31.0901 201.36 31.4001 201.62 31.8801 201.62C32.3601 201.62 32.6601 201.36 32.6601 200.67V195.49C32.6601 194.81 32.3501 194.55 31.8801 194.55C31.4101 194.55 31.0901 194.81 31.0901 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 195.59C34.9699 194.982 35.2111 194.4 35.6406 193.971C36.0701 193.541 36.6525 193.3 37.2599 193.3C37.8672 193.3 38.4497 193.541 38.8792 193.971C39.3086 194.4 39.5499 194.982 39.5499 195.59V200.59C39.5499 201.197 39.3086 201.78 38.8792 202.209C38.4497 202.639 37.8672 202.88 37.2599 202.88C36.6525 202.88 36.0701 202.639 35.6406 202.209C35.2111 201.78 34.9699 201.197 34.9699 200.59V195.59ZM36.4799 200.67C36.4799 201.36 36.7799 201.62 37.2599 201.62C37.7399 201.62 38.0399 201.36 38.0399 200.67V195.49C38.0399 194.81 37.7399 194.55 37.2599 194.55C36.7799 194.55 36.4799 194.81 36.4799 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 194.49C41.8277 194.54 42.2102 194.444 42.519 194.221C42.8277 193.997 43.0392 193.664 43.1101 193.29H44.1101V202.88H42.5901V195.56H41.4201L41.4501 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M49.0001 194.49C49.3778 194.54 49.7603 194.444 50.069 194.221C50.3778 193.997 50.5893 193.664 50.6601 193.29H51.6601V202.88H50.1501V195.56H48.9801L49.0001 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M54.4298 194.49C54.8071 194.538 55.1884 194.44 55.4966 194.217C55.8047 193.995 56.0168 193.663 56.0898 193.29H57.0898V202.88H55.5898V195.56H54.4298V194.49Z"
        fill="#1B2236"
      />
      <path
        d="M59.86 194.49C60.2365 194.54 60.6177 194.443 60.9248 194.22C61.2319 193.996 61.4414 193.664 61.51 193.29H62.51V202.88H61V195.56H59.84L59.86 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M65.2802 194.49C65.6578 194.54 66.0403 194.444 66.3491 194.221C66.6578 193.997 66.8693 193.664 66.9402 193.29H67.9402V202.88H66.4402V195.56H65.2702L65.2802 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M70.7099 194.49C71.0871 194.538 71.4684 194.44 71.7766 194.217C72.0847 193.995 72.2968 193.663 72.3699 193.29H73.3699V202.88H71.8599V195.56H70.6999L70.7099 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M77.1999 195.59C77.1999 194.982 77.4411 194.4 77.8706 193.971C78.3 193.541 78.8825 193.3 79.4899 193.3C80.0972 193.3 80.6797 193.541 81.1091 193.971C81.5386 194.4 81.7799 194.982 81.7799 195.59V200.59C81.7799 201.197 81.5386 201.78 81.1091 202.209C80.6797 202.639 80.0972 202.88 79.4899 202.88C78.8825 202.88 78.3 202.639 77.8706 202.209C77.4411 201.78 77.1999 201.197 77.1999 200.59V195.59ZM78.7099 200.67C78.7099 201.36 79.0099 201.62 79.4899 201.62C79.9699 201.62 80.2699 201.36 80.2699 200.67V195.49C80.2699 194.81 79.9699 194.55 79.4899 194.55C79.0099 194.55 78.7099 194.81 78.7099 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M82.5901 195.59C82.5694 195.277 82.6131 194.963 82.7186 194.668C82.8241 194.373 82.989 194.102 83.2032 193.874C83.4174 193.645 83.6764 193.462 83.9639 193.338C84.2515 193.213 84.5616 193.148 84.8751 193.148C85.1886 193.148 85.4987 193.213 85.7863 193.338C86.0739 193.462 86.3328 193.645 86.547 193.874C86.7612 194.102 86.9262 194.373 87.0316 194.668C87.1371 194.963 87.1808 195.277 87.1601 195.59V200.59C87.1808 200.902 87.1371 201.216 87.0316 201.511C86.9262 201.807 86.7612 202.077 86.547 202.306C86.3328 202.535 86.0739 202.717 85.7863 202.842C85.4987 202.967 85.1886 203.031 84.8751 203.031C84.5616 203.031 84.2515 202.967 83.9639 202.842C83.6764 202.717 83.4174 202.535 83.2032 202.306C82.989 202.077 82.8241 201.807 82.7186 201.511C82.6131 201.216 82.5694 200.902 82.5901 200.59V195.59ZM84.0901 200.67C84.0901 201.36 84.4001 201.62 84.8801 201.62C85.3601 201.62 85.6601 201.36 85.6601 200.67V195.49C85.6601 194.81 85.3601 194.55 84.8801 194.55C84.4001 194.55 84.0901 194.81 84.0901 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M88.05 195.59C88.05 194.982 88.2912 194.4 88.7207 193.971C89.1501 193.541 89.7326 193.3 90.34 193.3C90.9473 193.3 91.5298 193.541 91.9592 193.971C92.3887 194.4 92.6299 194.982 92.6299 195.59V200.59C92.6299 201.197 92.3887 201.78 91.9592 202.209C91.5298 202.639 90.9473 202.88 90.34 202.88C89.7326 202.88 89.1501 202.639 88.7207 202.209C88.2912 201.78 88.05 201.197 88.05 200.59V195.59ZM89.59 200.69C89.59 201.38 89.8899 201.64 90.3699 201.64C90.8499 201.64 91.1499 201.38 91.1499 200.69V195.49C91.1499 194.81 90.8499 194.55 90.3699 194.55C89.8899 194.55 89.59 194.81 89.59 195.49V200.69Z"
        fill="#1B2236"
      />
      <path
        d="M93.4801 195.59C93.4801 194.982 93.7214 194.4 94.1509 193.971C94.5803 193.541 95.1628 193.3 95.7701 193.3C96.3775 193.3 96.9599 193.541 97.3894 193.971C97.8189 194.4 98.0601 194.982 98.0601 195.59V200.59C98.0601 201.197 97.8189 201.78 97.3894 202.209C96.9599 202.639 96.3775 202.88 95.7701 202.88C95.1628 202.88 94.5803 202.639 94.1509 202.209C93.7214 201.78 93.4801 201.197 93.4801 200.59V195.59ZM94.9901 200.69C94.9901 201.38 95.2901 201.64 95.7701 201.64C96.2501 201.64 96.5501 201.38 96.5501 200.69V195.49C96.5501 194.81 96.2501 194.55 95.7701 194.55C95.2901 194.55 94.9901 194.81 94.9901 195.49V200.69Z"
        fill="#1B2236"
      />
      <path
        d="M98.9099 195.59C98.8892 195.277 98.933 194.963 99.0384 194.668C99.1439 194.373 99.3088 194.102 99.523 193.874C99.7372 193.645 99.9962 193.462 100.284 193.338C100.571 193.213 100.881 193.148 101.195 193.148C101.508 193.148 101.819 193.213 102.106 193.338C102.394 193.462 102.653 193.645 102.867 193.874C103.081 194.102 103.246 194.373 103.351 194.668C103.457 194.963 103.501 195.277 103.48 195.59V200.59C103.501 200.902 103.457 201.216 103.351 201.511C103.246 201.807 103.081 202.077 102.867 202.306C102.653 202.535 102.394 202.717 102.106 202.842C101.819 202.967 101.508 203.031 101.195 203.031C100.881 203.031 100.571 202.967 100.284 202.842C99.9962 202.717 99.7372 202.535 99.523 202.306C99.3088 202.077 99.1439 201.807 99.0384 201.511C98.933 201.216 98.8892 200.902 98.9099 200.59V195.59ZM100.41 200.67C100.41 201.36 100.71 201.62 101.19 201.62C101.67 201.62 101.97 201.36 101.97 200.67V195.49C101.97 194.81 101.67 194.55 101.19 194.55C100.71 194.55 100.41 194.81 100.41 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M109.59 194.49C109.968 194.54 110.35 194.444 110.659 194.221C110.968 193.997 111.179 193.664 111.25 193.29H112.25V202.88H110.74V195.56H109.59V194.49Z"
        fill="#1B2236"
      />
      <path
        d="M115.06 194.49C115.437 194.538 115.819 194.44 116.127 194.217C116.435 193.995 116.647 193.663 116.72 193.29H117.72V202.88H116.21V195.56H115.05L115.06 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M120.49 194.49C120.866 194.54 121.248 194.443 121.555 194.22C121.862 193.996 122.071 193.664 122.14 193.29H123.14V202.88H121.63V195.56H120.49V194.49Z"
        fill="#1B2236"
      />
      <path
        d="M125.91 194.49C126.288 194.54 126.67 194.444 126.979 194.221C127.288 193.997 127.499 193.664 127.57 193.29H128.57V202.88H127.07V195.56H125.9L125.91 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M131.34 194.49C131.718 194.538 132.099 194.44 132.407 194.217C132.715 193.995 132.927 193.663 133 193.29H134V202.88H132.49V195.56H131.33L131.34 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M136.76 194.49C137.138 194.54 137.52 194.444 137.829 194.221C138.138 193.997 138.349 193.664 138.42 193.29H139.42V202.88H137.92V195.56H136.75L136.76 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M141.13 195.59C141.13 194.982 141.371 194.4 141.801 193.971C142.23 193.541 142.813 193.3 143.42 193.3C144.027 193.3 144.61 193.541 145.039 193.971C145.469 194.4 145.71 194.982 145.71 195.59V200.59C145.71 201.197 145.469 201.78 145.039 202.209C144.61 202.639 144.027 202.88 143.42 202.88C142.813 202.88 142.23 202.639 141.801 202.209C141.371 201.78 141.13 201.197 141.13 200.59V195.59ZM142.64 200.67C142.64 201.36 142.94 201.62 143.42 201.62C143.9 201.62 144.2 201.36 144.2 200.67V195.49C144.2 194.81 143.9 194.55 143.42 194.55C142.94 194.55 142.64 194.81 142.64 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M146.59 195.59C146.59 194.982 146.831 194.4 147.261 193.971C147.69 193.541 148.273 193.3 148.88 193.3C149.487 193.3 150.07 193.541 150.499 193.971C150.929 194.4 151.17 194.982 151.17 195.59V200.59C151.17 201.197 150.929 201.78 150.499 202.209C150.07 202.639 149.487 202.88 148.88 202.88C148.273 202.88 147.69 202.639 147.261 202.209C146.831 201.78 146.59 201.197 146.59 200.59V195.59ZM148.1 200.67C148.1 201.36 148.4 201.62 148.88 201.62C149.36 201.62 149.66 201.36 149.66 200.67V195.49C149.66 194.81 149.36 194.55 148.88 194.55C148.4 194.55 148.1 194.81 148.1 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M151.99 195.59C151.969 195.277 152.013 194.963 152.119 194.668C152.224 194.373 152.389 194.102 152.603 193.874C152.817 193.645 153.076 193.462 153.364 193.338C153.651 193.213 153.962 193.148 154.275 193.148C154.588 193.148 154.899 193.213 155.186 193.338C155.474 193.462 155.733 193.645 155.947 193.874C156.161 194.102 156.326 194.373 156.432 194.668C156.537 194.963 156.581 195.277 156.56 195.59V200.59C156.581 200.902 156.537 201.216 156.432 201.511C156.326 201.807 156.161 202.077 155.947 202.306C155.733 202.535 155.474 202.717 155.186 202.842C154.899 202.967 154.588 203.031 154.275 203.031C153.962 203.031 153.651 202.967 153.364 202.842C153.076 202.717 152.817 202.535 152.603 202.306C152.389 202.077 152.224 201.807 152.119 201.511C152.013 201.216 151.969 200.902 151.99 200.59V195.59ZM153.49 200.67C153.49 201.36 153.79 201.62 154.27 201.62C154.75 201.62 155.05 201.36 155.05 200.67V195.49C155.05 194.81 154.75 194.55 154.27 194.55C153.79 194.55 153.49 194.81 153.49 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M157.41 195.59C157.41 194.982 157.651 194.4 158.081 193.971C158.51 193.541 159.092 193.3 159.7 193.3C160.307 193.3 160.89 193.541 161.319 193.971C161.749 194.4 161.99 194.982 161.99 195.59V200.59C161.99 201.197 161.749 201.78 161.319 202.209C160.89 202.639 160.307 202.88 159.7 202.88C159.092 202.88 158.51 202.639 158.081 202.209C157.651 201.78 157.41 201.197 157.41 200.59V195.59ZM158.92 200.67C158.92 201.36 159.22 201.62 159.7 201.62C160.18 201.62 160.48 201.36 160.48 200.67V195.49C160.48 194.81 160.18 194.55 159.7 194.55C159.22 194.55 158.92 194.81 158.92 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M162.84 195.59C162.819 195.277 162.863 194.963 162.969 194.668C163.074 194.373 163.239 194.102 163.453 193.874C163.667 193.645 163.926 193.462 164.214 193.338C164.502 193.213 164.812 193.148 165.125 193.148C165.439 193.148 165.749 193.213 166.036 193.338C166.324 193.462 166.583 193.645 166.797 193.874C167.011 194.102 167.176 194.373 167.282 194.668C167.387 194.963 167.431 195.277 167.41 195.59V200.59C167.431 200.902 167.387 201.216 167.282 201.511C167.176 201.807 167.011 202.077 166.797 202.306C166.583 202.535 166.324 202.717 166.036 202.842C165.749 202.967 165.439 203.031 165.125 203.031C164.812 203.031 164.502 202.967 164.214 202.842C163.926 202.717 163.667 202.535 163.453 202.306C163.239 202.077 163.074 201.807 162.969 201.511C162.863 201.216 162.819 200.902 162.84 200.59V195.59ZM164.34 200.67C164.34 201.36 164.65 201.62 165.13 201.62C165.61 201.62 165.91 201.36 165.91 200.67V195.49C165.91 194.81 165.6 194.55 165.13 194.55C164.66 194.55 164.34 194.81 164.34 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M168.26 195.59C168.26 194.982 168.501 194.4 168.931 193.971C169.36 193.541 169.943 193.3 170.55 193.3C171.157 193.3 171.74 193.541 172.169 193.971C172.599 194.4 172.84 194.982 172.84 195.59V200.59C172.84 201.197 172.599 201.78 172.169 202.209C171.74 202.639 171.157 202.88 170.55 202.88C169.943 202.88 169.36 202.639 168.931 202.209C168.501 201.78 168.26 201.197 168.26 200.59V195.59ZM169.77 200.67C169.77 201.36 170.07 201.62 170.55 201.62C171.03 201.62 171.33 201.36 171.33 200.67V195.49C171.33 194.81 171.03 194.55 170.55 194.55C170.07 194.55 169.77 194.81 169.77 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M173.69 195.59C173.67 195.277 173.713 194.963 173.819 194.668C173.924 194.373 174.089 194.102 174.303 193.874C174.518 193.645 174.776 193.462 175.064 193.338C175.352 193.213 175.662 193.148 175.975 193.148C176.289 193.148 176.599 193.213 176.886 193.338C177.174 193.462 177.433 193.645 177.647 193.874C177.861 194.102 178.026 194.373 178.132 194.668C178.237 194.963 178.281 195.277 178.26 195.59V200.59C178.281 200.902 178.237 201.216 178.132 201.511C178.026 201.807 177.861 202.077 177.647 202.306C177.433 202.535 177.174 202.717 176.886 202.842C176.599 202.967 176.289 203.031 175.975 203.031C175.662 203.031 175.352 202.967 175.064 202.842C174.776 202.717 174.518 202.535 174.303 202.306C174.089 202.077 173.924 201.807 173.819 201.511C173.713 201.216 173.67 200.902 173.69 200.59V195.59ZM175.2 200.67C175.2 201.36 175.5 201.62 175.98 201.62C176.46 201.62 176.76 201.36 176.76 200.67V195.49C176.76 194.81 176.46 194.55 175.98 194.55C175.5 194.55 175.2 194.81 175.2 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M182.29 194.49C182.668 194.54 183.05 194.444 183.359 194.221C183.668 193.997 183.879 193.664 183.95 193.29H184.95V202.88H183.44V195.56H182.29V194.49Z"
        fill="#1B2236"
      />
      <path
        d="M187.72 194.49C188.097 194.538 188.479 194.44 188.787 194.217C189.095 193.995 189.307 193.663 189.38 193.29H190.38V202.88H188.87V195.56H187.71L187.72 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M193.15 194.49C193.526 194.54 193.908 194.443 194.215 194.22C194.522 193.996 194.731 193.664 194.8 193.29H195.8V202.88H194.29V195.56H193.13L193.15 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M198.59 194.49C198.968 194.54 199.35 194.444 199.659 194.221C199.968 193.997 200.179 193.664 200.25 193.29H201.25V202.88H199.75V195.56H198.59V194.49Z"
        fill="#1B2236"
      />
      <path
        d="M204 194.49C204.377 194.538 204.758 194.44 205.067 194.217C205.375 193.995 205.587 193.663 205.66 193.29H206.66V202.88H205.15V195.56H203.99L204 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M212.59 195.59C212.569 195.277 212.613 194.963 212.719 194.668C212.824 194.373 212.989 194.102 213.203 193.874C213.417 193.645 213.676 193.462 213.964 193.338C214.252 193.213 214.562 193.148 214.875 193.148C215.189 193.148 215.499 193.213 215.786 193.338C216.074 193.462 216.333 193.645 216.547 193.874C216.761 194.102 216.926 194.373 217.032 194.668C217.137 194.963 217.181 195.277 217.16 195.59V200.59C217.181 200.902 217.137 201.216 217.032 201.511C216.926 201.807 216.761 202.077 216.547 202.306C216.333 202.535 216.074 202.717 215.786 202.842C215.499 202.967 215.189 203.031 214.875 203.031C214.562 203.031 214.252 202.967 213.964 202.842C213.676 202.717 213.417 202.535 213.203 202.306C212.989 202.077 212.824 201.807 212.719 201.511C212.613 201.216 212.569 200.902 212.59 200.59V195.59ZM214.09 200.67C214.09 201.36 214.39 201.62 214.87 201.62C215.35 201.62 215.65 201.36 215.65 200.67V195.49C215.65 194.81 215.35 194.55 214.87 194.55C214.39 194.55 214.09 194.81 214.09 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M218.04 195.59C218.04 194.982 218.281 194.4 218.711 193.971C219.14 193.541 219.723 193.3 220.33 193.3C220.938 193.3 221.52 193.541 221.949 193.971C222.379 194.4 222.62 194.982 222.62 195.59V200.59C222.62 201.197 222.379 201.78 221.949 202.209C221.52 202.639 220.938 202.88 220.33 202.88C219.723 202.88 219.14 202.639 218.711 202.209C218.281 201.78 218.04 201.197 218.04 200.59V195.59ZM219.59 200.69C219.59 201.38 219.89 201.64 220.37 201.64C220.85 201.64 221.15 201.38 221.15 200.69V195.49C221.15 194.81 220.85 194.55 220.37 194.55C219.89 194.55 219.59 194.81 219.59 195.49V200.69Z"
        fill="#1B2236"
      />
      <path
        d="M223.47 195.59C223.449 195.277 223.493 194.963 223.598 194.668C223.704 194.373 223.869 194.102 224.083 193.874C224.297 193.645 224.556 193.462 224.844 193.338C225.131 193.213 225.442 193.148 225.755 193.148C226.068 193.148 226.379 193.213 226.666 193.338C226.954 193.462 227.213 193.645 227.427 193.874C227.641 194.102 227.806 194.373 227.911 194.668C228.017 194.963 228.061 195.277 228.04 195.59V200.59C228.061 200.902 228.017 201.216 227.911 201.511C227.806 201.807 227.641 202.077 227.427 202.306C227.213 202.535 226.954 202.717 226.666 202.842C226.379 202.967 226.068 203.031 225.755 203.031C225.442 203.031 225.131 202.967 224.844 202.842C224.556 202.717 224.297 202.535 224.083 202.306C223.869 202.077 223.704 201.807 223.598 201.511C223.493 201.216 223.449 200.902 223.47 200.59V195.59ZM224.97 200.67C224.97 201.36 225.28 201.62 225.76 201.62C226.24 201.62 226.54 201.36 226.54 200.67V195.49C226.54 194.81 226.23 194.55 225.76 194.55C225.29 194.55 224.97 194.81 224.97 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M228.89 195.59C228.89 194.982 229.131 194.4 229.561 193.971C229.99 193.541 230.572 193.3 231.18 193.3C231.787 193.3 232.37 193.541 232.799 193.971C233.229 194.4 233.47 194.982 233.47 195.59V200.59C233.47 201.197 233.229 201.78 232.799 202.209C232.37 202.639 231.787 202.88 231.18 202.88C230.572 202.88 229.99 202.639 229.561 202.209C229.131 201.78 228.89 201.197 228.89 200.59V195.59ZM230.4 200.67C230.4 201.36 230.7 201.62 231.18 201.62C231.66 201.62 231.96 201.36 231.96 200.67V195.49C231.96 194.81 231.66 194.55 231.18 194.55C230.7 194.55 230.4 194.81 230.4 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M234.32 195.59C234.32 195.289 234.379 194.991 234.494 194.713C234.609 194.436 234.778 194.183 234.991 193.971C235.203 193.758 235.456 193.589 235.734 193.474C236.011 193.359 236.309 193.3 236.61 193.3C236.911 193.3 237.208 193.359 237.486 193.474C237.764 193.589 238.017 193.758 238.229 193.971C238.442 194.183 238.611 194.436 238.726 194.713C238.841 194.991 238.9 195.289 238.9 195.59V200.59C238.9 200.891 238.841 201.188 238.726 201.466C238.611 201.744 238.442 201.996 238.229 202.209C238.017 202.422 237.764 202.59 237.486 202.706C237.208 202.821 236.911 202.88 236.61 202.88C236.309 202.88 236.011 202.821 235.734 202.706C235.456 202.59 235.203 202.422 234.991 202.209C234.778 201.996 234.609 201.744 234.494 201.466C234.379 201.188 234.32 200.891 234.32 200.59V195.59ZM235.83 200.67C235.83 201.36 236.13 201.62 236.61 201.62C237.09 201.62 237.39 201.36 237.39 200.67V195.49C237.39 194.81 237.09 194.55 236.61 194.55C236.13 194.55 235.83 194.81 235.83 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M239.75 195.59C239.729 195.277 239.773 194.963 239.878 194.668C239.984 194.373 240.149 194.102 240.363 193.874C240.577 193.645 240.836 193.462 241.124 193.338C241.411 193.213 241.721 193.148 242.035 193.148C242.348 193.148 242.658 193.213 242.946 193.338C243.234 193.462 243.492 193.645 243.707 193.874C243.921 194.102 244.086 194.373 244.191 194.668C244.297 194.963 244.34 195.277 244.32 195.59V200.59C244.34 200.902 244.297 201.216 244.191 201.511C244.086 201.807 243.921 202.077 243.707 202.306C243.492 202.535 243.234 202.717 242.946 202.842C242.658 202.967 242.348 203.031 242.035 203.031C241.721 203.031 241.411 202.967 241.124 202.842C240.836 202.717 240.577 202.535 240.363 202.306C240.149 202.077 239.984 201.807 239.878 201.511C239.773 201.216 239.729 200.902 239.75 200.59V195.59ZM241.25 200.67C241.25 201.36 241.55 201.62 242.03 201.62C242.51 201.62 242.81 201.36 242.81 200.67V195.49C242.81 194.81 242.51 194.55 242.03 194.55C241.55 194.55 241.25 194.81 241.25 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M249.42 195.59C249.42 194.982 249.661 194.4 250.091 193.971C250.52 193.541 251.103 193.3 251.71 193.3C252.317 193.3 252.9 193.541 253.329 193.971C253.759 194.4 254 194.982 254 195.59V200.59C254 201.197 253.759 201.78 253.329 202.209C252.9 202.639 252.317 202.88 251.71 202.88C251.103 202.88 250.52 202.639 250.091 202.209C249.661 201.78 249.42 201.197 249.42 200.59V195.59ZM250.93 200.67C250.93 201.36 251.23 201.62 251.71 201.62C252.19 201.62 252.49 201.36 252.49 200.67V195.49C252.49 194.81 252.19 194.55 251.71 194.55C251.23 194.55 250.93 194.81 250.93 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M255.9 194.49C256.277 194.538 256.658 194.44 256.967 194.217C257.275 193.995 257.487 193.663 257.56 193.29H258.56V202.88H257.05V195.56H255.89L255.9 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M260.27 195.59C260.27 194.982 260.511 194.4 260.941 193.971C261.37 193.541 261.953 193.3 262.56 193.3C263.168 193.3 263.75 193.541 264.179 193.971C264.609 194.4 264.85 194.982 264.85 195.59V200.59C264.85 201.197 264.609 201.78 264.179 202.209C263.75 202.639 263.168 202.88 262.56 202.88C261.953 202.88 261.37 202.639 260.941 202.209C260.511 201.78 260.27 201.197 260.27 200.59V195.59ZM261.78 200.67C261.78 201.36 262.08 201.62 262.56 201.62C263.04 201.62 263.34 201.36 263.34 200.67V195.49C263.34 194.81 263.04 194.55 262.56 194.55C262.08 194.55 261.78 194.81 261.78 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M266.75 194.49C267.128 194.54 267.51 194.444 267.819 194.221C268.128 193.997 268.339 193.664 268.41 193.29H269.41V202.88H267.91V195.56H266.74L266.75 194.49Z"
        fill="#1B2236"
      />
      <path
        d="M271.12 195.59C271.12 194.982 271.361 194.4 271.79 193.971C272.22 193.541 272.802 193.3 273.41 193.3C274.017 193.3 274.6 193.541 275.029 193.971C275.459 194.4 275.7 194.982 275.7 195.59V200.59C275.7 201.197 275.459 201.78 275.029 202.209C274.6 202.639 274.017 202.88 273.41 202.88C272.802 202.88 272.22 202.639 271.79 202.209C271.361 201.78 271.12 201.197 271.12 200.59V195.59ZM272.59 200.69C272.59 201.38 272.89 201.64 273.37 201.64C273.85 201.64 274.15 201.38 274.15 200.69V195.49C274.15 194.81 273.85 194.55 273.37 194.55C272.89 194.55 272.59 194.81 272.59 195.49V200.69Z"
        fill="#1B2236"
      />
      <path
        d="M277.59 194.49C277.968 194.54 278.35 194.444 278.659 194.221C278.968 193.997 279.179 193.664 279.25 193.29H280.25V202.88H278.75V195.56H277.59V194.49Z"
        fill="#1B2236"
      />
      <path
        d="M281.97 195.59C281.97 194.982 282.211 194.4 282.641 193.971C283.07 193.541 283.653 193.3 284.26 193.3C284.867 193.3 285.45 193.541 285.879 193.971C286.309 194.4 286.55 194.982 286.55 195.59V200.59C286.55 201.197 286.309 201.78 285.879 202.209C285.45 202.639 284.867 202.88 284.26 202.88C283.653 202.88 283.07 202.639 282.641 202.209C282.211 201.78 281.97 201.197 281.97 200.59V195.59ZM283.48 200.67C283.48 201.36 283.78 201.62 284.26 201.62C284.74 201.62 285.04 201.36 285.04 200.67V195.49C285.04 194.81 284.74 194.55 284.26 194.55C283.78 194.55 283.48 194.81 283.48 195.49V200.67Z"
        fill="#1B2236"
      />
      <path
        d="M24.1198 212.03C24.1198 211.423 24.3611 210.84 24.7905 210.411C25.22 209.982 25.8024 209.74 26.4098 209.74C27.0171 209.74 27.5996 209.982 28.0291 210.411C28.4585 210.84 28.6998 211.423 28.6998 212.03V217.03C28.6998 217.638 28.4585 218.22 28.0291 218.65C27.5996 219.079 27.0171 219.32 26.4098 219.32C25.8024 219.32 25.22 219.079 24.7905 218.65C24.3611 218.22 24.1198 217.638 24.1198 217.03V212.03ZM25.6298 217.12C25.6298 217.8 25.9298 218.06 26.4098 218.06C26.8898 218.06 27.1898 217.8 27.1898 217.12V211.94C27.1898 211.25 26.8898 210.99 26.4098 210.99C25.9298 210.99 25.6298 211.25 25.6298 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M30.59 210.94C30.9691 210.99 31.3528 210.892 31.6618 210.667C31.9708 210.442 32.1814 210.107 32.25 209.73H33.25V219.32H31.75V212.01H30.59V210.94Z"
        fill="#1B2236"
      />
      <path
        d="M34.9699 212.03C34.9699 211.423 35.2111 210.84 35.6406 210.411C36.0701 209.982 36.6525 209.74 37.2599 209.74C37.8672 209.74 38.4497 209.982 38.8792 210.411C39.3086 210.84 39.5499 211.423 39.5499 212.03V217.03C39.5499 217.638 39.3086 218.22 38.8792 218.65C38.4497 219.079 37.8672 219.32 37.2599 219.32C36.6525 219.32 36.0701 219.079 35.6406 218.65C35.2111 218.22 34.9699 217.638 34.9699 217.03V212.03ZM36.4799 217.12C36.4799 217.8 36.7799 218.06 37.2599 218.06C37.7399 218.06 38.0399 217.8 38.0399 217.12V211.94C38.0399 211.25 37.7399 210.99 37.2599 210.99C36.7799 210.99 36.4799 211.25 36.4799 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M41.4501 210.94C41.8292 210.99 42.2129 210.892 42.5219 210.667C42.8309 210.442 43.0415 210.107 43.1101 209.73H44.1101V219.32H42.5901V212.01H41.4201L41.4501 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M45.8299 212.03C45.8092 211.717 45.8529 211.404 45.9583 211.108C46.0638 210.813 46.2287 210.543 46.443 210.314C46.6572 210.085 46.9161 209.903 47.2037 209.778C47.4913 209.653 47.8014 209.589 48.1148 209.589C48.4283 209.589 48.7385 209.653 49.026 209.778C49.3136 209.903 49.5725 210.085 49.7868 210.314C50.001 210.543 50.1659 210.813 50.2714 211.108C50.3768 211.404 50.4206 211.717 50.3999 212.03V217.03C50.4206 217.343 50.3768 217.657 50.2714 217.952C50.1659 218.247 50.001 218.517 49.7868 218.746C49.5725 218.975 49.3136 219.158 49.026 219.282C48.7385 219.407 48.4283 219.471 48.1148 219.471C47.8014 219.471 47.4913 219.407 47.2037 219.282C46.9161 219.158 46.6572 218.975 46.443 218.746C46.2287 218.517 46.0638 218.247 45.9583 217.952C45.8529 217.657 45.8092 217.343 45.8299 217.03V212.03ZM47.3299 217.12C47.3299 217.8 47.6299 218.06 48.1099 218.06C48.5899 218.06 48.8899 217.8 48.8899 217.12V211.94C48.8899 211.25 48.5899 210.99 48.1099 210.99C47.6299 210.99 47.3299 211.25 47.3299 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M52.3102 210.94C52.6881 210.99 53.0706 210.892 53.378 210.667C53.6853 210.441 53.8939 210.106 53.9602 209.73H54.9602V219.32H53.4502V212.01H52.2902L52.3102 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M57.7299 210.94C58.1149 210.989 58.5037 210.886 58.8137 210.653C59.1237 210.419 59.3305 210.074 59.3899 209.69H60.3899V219.28H58.8899V211.97H57.7199L57.7299 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M63.1601 210.94C63.5388 210.988 63.9213 210.889 64.2297 210.664C64.5381 210.439 64.7492 210.105 64.8201 209.73H65.8201V219.32H64.3101V212.01H63.1501L63.1601 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M68.59 210.94C68.9691 210.99 69.3528 210.892 69.6618 210.667C69.9708 210.442 70.1814 210.107 70.25 209.73H71.25V219.32H69.74V212.01H68.59V210.94Z"
        fill="#1B2236"
      />
      <path
        d="M74.0101 210.94C74.3889 210.988 74.7714 210.889 75.0798 210.664C75.3882 210.439 75.5993 210.105 75.6702 209.73H76.6702V219.32H75.1602V212.01H74.0002L74.0101 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M79.4398 210.94C79.8237 210.989 80.2113 210.886 80.5197 210.652C80.8281 210.418 81.0328 210.073 81.0898 209.69H82.0898V219.28H80.5898V211.97H79.4298L79.4398 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M84.8598 210.94C85.2389 210.99 85.6226 210.892 85.9316 210.667C86.2406 210.442 86.4512 210.107 86.5198 209.73H87.5198V219.32H86.0198V212.01H84.8498L84.8598 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M89.2301 212.03C89.2301 211.423 89.4714 210.84 89.9009 210.411C90.3303 209.982 90.9128 209.74 91.5201 209.74C92.1275 209.74 92.7099 209.982 93.1394 210.411C93.5689 210.84 93.8101 211.423 93.8101 212.03V217.03C93.8101 217.638 93.5689 218.22 93.1394 218.65C92.7099 219.079 92.1275 219.32 91.5201 219.32C90.9128 219.32 90.3303 219.079 89.9009 218.65C89.4714 218.22 89.2301 217.638 89.2301 217.03V212.03ZM90.7401 217.12C90.7401 217.8 91.0401 218.06 91.5201 218.06C92.0001 218.06 92.3001 217.8 92.3001 217.12V211.94C92.3001 211.25 92.0001 210.99 91.5201 210.99C91.0401 210.99 90.7401 211.25 90.7401 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M95.7099 210.94C96.089 210.99 96.4727 210.892 96.7817 210.667C97.0907 210.442 97.3013 210.107 97.3699 209.73H98.3699V219.32H96.8699V212.01H95.6999L95.7099 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M103.26 210.94C103.639 210.99 104.023 210.892 104.332 210.667C104.641 210.442 104.852 210.107 104.92 209.73H105.92V219.32H104.42V212.01H103.25L103.26 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M107.59 212.03C107.59 211.423 107.831 210.84 108.261 210.411C108.69 209.982 109.273 209.74 109.88 209.74C110.487 209.74 111.07 209.982 111.499 210.411C111.929 210.84 112.17 211.423 112.17 212.03V217.03C112.17 217.638 111.929 218.22 111.499 218.65C111.07 219.079 110.487 219.32 109.88 219.32C109.273 219.32 108.69 219.079 108.261 218.65C107.831 218.22 107.59 217.638 107.59 217.03V212.03ZM109.1 217.12C109.1 217.8 109.4 218.06 109.88 218.06C110.36 218.06 110.66 217.8 110.66 217.12V211.94C110.66 211.25 110.36 210.99 109.88 210.99C109.4 210.99 109.1 211.25 109.1 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M114.11 210.94C114.489 210.99 114.873 210.892 115.182 210.667C115.491 210.442 115.701 210.107 115.77 209.73H116.77V219.32H115.26V212.01H114.09L114.11 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M121.66 210.94C122.039 210.99 122.423 210.892 122.732 210.667C123.041 210.442 123.251 210.107 123.32 209.73H124.32V219.32H122.81V212.01H121.64L121.66 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M128.16 212.03C128.139 211.717 128.183 211.404 128.288 211.108C128.394 210.813 128.559 210.543 128.773 210.314C128.987 210.085 129.246 209.903 129.534 209.778C129.821 209.653 130.131 209.589 130.445 209.589C130.758 209.589 131.069 209.653 131.356 209.778C131.644 209.903 131.903 210.085 132.117 210.314C132.331 210.543 132.496 210.813 132.601 211.108C132.707 211.404 132.751 211.717 132.73 212.03V217.03C132.751 217.343 132.707 217.657 132.601 217.952C132.496 218.247 132.331 218.517 132.117 218.746C131.903 218.975 131.644 219.158 131.356 219.282C131.069 219.407 130.758 219.471 130.445 219.471C130.131 219.471 129.821 219.407 129.534 219.282C129.246 219.158 128.987 218.975 128.773 218.746C128.559 218.517 128.394 218.247 128.288 217.952C128.183 217.657 128.139 217.343 128.16 217.03V212.03ZM129.67 217.12C129.67 217.8 129.97 218.06 130.45 218.06C130.93 218.06 131.23 217.8 131.23 217.12V211.94C131.23 211.25 130.93 210.99 130.45 210.99C129.97 210.99 129.67 211.25 129.67 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M134.64 210.94C135.019 210.988 135.401 210.889 135.71 210.664C136.018 210.439 136.229 210.105 136.3 209.73H137.3V219.32H135.79V212.01H134.63L134.64 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M140.07 210.94C140.448 210.99 140.83 210.892 141.138 210.667C141.445 210.441 141.654 210.106 141.72 209.73H142.72V219.32H141.21V212.01H140.05L140.07 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M145.49 210.94C145.869 210.99 146.253 210.892 146.562 210.667C146.871 210.442 147.081 210.107 147.15 209.73H148.15V219.32H146.65V212.01H145.49V210.94Z"
        fill="#1B2236"
      />
      <path
        d="M150.92 210.94C151.307 210.995 151.7 210.894 152.013 210.66C152.326 210.425 152.533 210.077 152.59 209.69H153.59V219.28H152.08V211.97H150.92V210.94Z"
        fill="#1B2236"
      />
      <path
        d="M156.34 210.94C156.719 210.99 157.103 210.892 157.412 210.667C157.721 210.442 157.932 210.107 158 209.73H159V219.32H157.5V212.01H156.33L156.34 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M160.71 212.03C160.71 211.73 160.769 211.432 160.884 211.154C161 210.876 161.168 210.624 161.381 210.411C161.593 210.198 161.846 210.03 162.124 209.915C162.402 209.799 162.699 209.74 163 209.74C163.301 209.74 163.599 209.799 163.876 209.915C164.154 210.03 164.407 210.198 164.619 210.411C164.832 210.624 165.001 210.876 165.116 211.154C165.231 211.432 165.29 211.73 165.29 212.03V217.03C165.29 217.331 165.231 217.629 165.116 217.907C165.001 218.184 164.832 218.437 164.619 218.65C164.407 218.862 164.154 219.031 163.876 219.146C163.599 219.261 163.301 219.32 163 219.32C162.699 219.32 162.402 219.261 162.124 219.146C161.846 219.031 161.593 218.862 161.381 218.65C161.168 218.437 161 218.184 160.884 217.907C160.769 217.629 160.71 217.331 160.71 217.03V212.03ZM162.22 217.12C162.22 217.8 162.52 218.06 163 218.06C163.48 218.06 163.78 217.8 163.78 217.12V211.94C163.78 211.25 163.48 210.99 163 210.99C162.52 210.99 162.22 211.25 162.22 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M167.19 210.94C167.569 210.99 167.953 210.892 168.262 210.667C168.571 210.442 168.782 210.107 168.85 209.73H169.85V219.32H168.34V212.01H167.19V210.94Z"
        fill="#1B2236"
      />
      <path
        d="M171.59 212.03C171.569 211.717 171.613 211.404 171.719 211.108C171.824 210.813 171.989 210.543 172.203 210.314C172.417 210.085 172.676 209.903 172.964 209.778C173.252 209.653 173.562 209.589 173.875 209.589C174.189 209.589 174.499 209.653 174.786 209.778C175.074 209.903 175.333 210.085 175.547 210.314C175.761 210.543 175.926 210.813 176.032 211.108C176.137 211.404 176.181 211.717 176.16 212.03V217.03C176.181 217.343 176.137 217.657 176.032 217.952C175.926 218.247 175.761 218.517 175.547 218.746C175.333 218.975 175.074 219.158 174.786 219.282C174.499 219.407 174.189 219.471 173.875 219.471C173.562 219.471 173.252 219.407 172.964 219.282C172.676 219.158 172.417 218.975 172.203 218.746C171.989 218.517 171.824 218.247 171.719 217.952C171.613 217.657 171.569 217.343 171.59 217.03V212.03ZM173.09 217.12C173.09 217.8 173.39 218.06 173.87 218.06C174.35 218.06 174.65 217.8 174.65 217.12V211.94C174.65 211.25 174.35 210.99 173.87 210.99C173.39 210.99 173.09 211.25 173.09 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M178.05 210.94C178.428 210.99 178.81 210.892 179.118 210.667C179.425 210.441 179.634 210.106 179.7 209.73H180.7V219.32H179.19V212.01H178.03L178.05 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M182.42 212.03C182.399 211.717 182.443 211.404 182.549 211.108C182.654 210.813 182.819 210.543 183.033 210.314C183.248 210.085 183.506 209.903 183.794 209.778C184.082 209.653 184.392 209.589 184.705 209.589C185.019 209.589 185.329 209.653 185.616 209.778C185.904 209.903 186.163 210.085 186.377 210.314C186.591 210.543 186.756 210.813 186.862 211.108C186.967 211.404 187.011 211.717 186.99 212.03V217.03C187.011 217.343 186.967 217.657 186.862 217.952C186.756 218.247 186.591 218.517 186.377 218.746C186.163 218.975 185.904 219.158 185.616 219.282C185.329 219.407 185.019 219.471 184.705 219.471C184.392 219.471 184.082 219.407 183.794 219.282C183.506 219.158 183.248 218.975 183.033 218.746C182.819 218.517 182.654 218.247 182.549 217.952C182.443 217.657 182.399 217.343 182.42 217.03V212.03ZM183.92 217.12C183.92 217.8 184.23 218.06 184.71 218.06C185.19 218.06 185.49 217.8 185.49 217.12V211.94C185.49 211.25 185.18 210.99 184.71 210.99C184.24 210.99 183.92 211.25 183.92 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M188.9 210.94C189.289 210.995 189.685 210.895 190.001 210.661C190.317 210.427 190.528 210.079 190.59 209.69H191.59V219.28H190.08V211.97H188.92L188.9 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M199.64 212.03C199.64 211.423 199.881 210.84 200.311 210.411C200.74 209.982 201.322 209.74 201.93 209.74C202.537 209.74 203.12 209.982 203.549 210.411C203.979 210.84 204.22 211.423 204.22 212.03V217.03C204.22 217.638 203.979 218.22 203.549 218.65C203.12 219.079 202.537 219.32 201.93 219.32C201.322 219.32 200.74 219.079 200.311 218.65C199.881 218.22 199.64 217.638 199.64 217.03V212.03ZM201.15 217.12C201.15 217.8 201.45 218.06 201.93 218.06C202.41 218.06 202.71 217.8 202.71 217.12V211.94C202.71 211.25 202.41 210.99 201.93 210.99C201.45 210.99 201.15 211.25 201.15 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M206.12 210.94C206.499 210.99 206.883 210.892 207.192 210.667C207.501 210.442 207.711 210.107 207.78 209.73H208.78V219.32H207.28V212.01H206.11L206.12 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M210.49 212.03C210.49 211.423 210.731 210.84 211.161 210.411C211.59 209.982 212.173 209.74 212.78 209.74C213.387 209.74 213.97 209.982 214.399 210.411C214.829 210.84 215.07 211.423 215.07 212.03V217.03C215.07 217.638 214.829 218.22 214.399 218.65C213.97 219.079 213.387 219.32 212.78 219.32C212.173 219.32 211.59 219.079 211.161 218.65C210.731 218.22 210.49 217.638 210.49 217.03V212.03ZM212 217.12C212 217.8 212.3 218.06 212.78 218.06C213.26 218.06 213.56 217.8 213.56 217.12V211.94C213.56 211.25 213.26 210.99 212.78 210.99C212.3 210.99 212 211.25 212 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M216.97 210.94C217.349 210.978 217.727 210.87 218.029 210.637C218.33 210.405 218.531 210.066 218.59 209.69H219.59V219.28H218.09V211.97H216.92L216.97 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M222.4 210.94C222.779 210.988 223.161 210.889 223.47 210.664C223.778 210.439 223.989 210.105 224.06 209.73H225.06V219.32H223.59V212.01H222.43L222.4 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M227.82 210.94C228.199 210.99 228.583 210.892 228.892 210.667C229.201 210.442 229.411 210.107 229.48 209.73H230.48V219.32H228.99V212.01H227.82V210.94Z"
        fill="#1B2236"
      />
      <path
        d="M232.2 212.03C232.179 211.717 232.223 211.404 232.328 211.108C232.434 210.813 232.599 210.543 232.813 210.314C233.027 210.085 233.286 209.903 233.574 209.778C233.861 209.653 234.172 209.589 234.485 209.589C234.798 209.589 235.109 209.653 235.396 209.778C235.684 209.903 235.943 210.085 236.157 210.314C236.371 210.543 236.536 210.813 236.642 211.108C236.747 211.404 236.791 211.717 236.77 212.03V217.03C236.791 217.343 236.747 217.657 236.642 217.952C236.536 218.247 236.371 218.517 236.157 218.746C235.943 218.975 235.684 219.158 235.396 219.282C235.109 219.407 234.798 219.471 234.485 219.471C234.172 219.471 233.861 219.407 233.574 219.282C233.286 219.158 233.027 218.975 232.813 218.746C232.599 218.517 232.434 218.247 232.328 217.952C232.223 217.657 232.179 217.343 232.2 217.03V212.03ZM233.7 217.12C233.7 217.8 234 218.06 234.48 218.06C234.96 218.06 235.26 217.8 235.26 217.12V211.94C235.26 211.25 234.96 210.99 234.48 210.99C234 210.99 233.7 211.25 233.7 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M238.68 210.94C239.058 210.99 239.44 210.892 239.748 210.667C240.055 210.441 240.264 210.106 240.33 209.73H241.33V219.32H239.82V212.01H238.66L238.68 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M244.1 210.94C244.479 210.99 244.863 210.892 245.172 210.667C245.481 210.442 245.692 210.107 245.76 209.73H246.76V219.32H245.25V212.01H244.08L244.1 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M249.53 210.94C249.914 210.987 250.302 210.882 250.611 210.649C250.921 210.416 251.128 210.073 251.19 209.69H252.19V219.28H250.69V211.97H249.53V210.94Z"
        fill="#1B2236"
      />
      <path
        d="M254.95 210.94C255.332 210.984 255.715 210.878 256.021 210.645C256.327 210.412 256.53 210.07 256.59 209.69H257.59V219.28H256.08V211.97H254.91L254.95 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M260.38 210.94C260.759 210.988 261.141 210.889 261.45 210.664C261.758 210.439 261.969 210.105 262.04 209.73H263.04V219.32H261.59V212.01H260.43L260.38 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M265.81 210.94C266.188 210.99 266.571 210.892 266.878 210.667C267.185 210.441 267.394 210.106 267.46 209.73H268.46V219.32H266.95V212.01H265.79L265.81 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M272.3 212.03C272.3 211.423 272.541 210.84 272.971 210.411C273.4 209.982 273.983 209.74 274.59 209.74C275.197 209.74 275.78 209.982 276.209 210.411C276.639 210.84 276.88 211.423 276.88 212.03V217.03C276.88 217.638 276.639 218.22 276.209 218.65C275.78 219.079 275.197 219.32 274.59 219.32C273.983 219.32 273.4 219.079 272.971 218.65C272.541 218.22 272.3 217.638 272.3 217.03V212.03ZM273.81 217.12C273.81 217.8 274.11 218.06 274.59 218.06C275.07 218.06 275.37 217.8 275.37 217.12V211.94C275.37 211.25 275.07 210.99 274.59 210.99C274.11 210.99 273.81 211.25 273.81 211.94V217.12Z"
        fill="#1B2236"
      />
      <path
        d="M278.78 210.94C279.159 210.99 279.543 210.892 279.852 210.667C280.161 210.442 280.372 210.107 280.44 209.73H281.44V219.32H279.94V212.01H278.77L278.78 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M284.21 210.94C284.588 210.99 284.97 210.892 285.278 210.667C285.585 210.441 285.794 210.106 285.86 209.73H286.86V219.32H285.35V212.01H284.19L284.21 210.94Z"
        fill="#1B2236"
      />
      <path
        d="M149.82 84.6901H160.99C161.491 84.6901 161.972 84.8893 162.326 85.2437C162.681 85.5981 162.88 86.0789 162.88 86.5801V105.17H147.88V86.5501C147.884 86.3002 147.937 86.0535 148.037 85.8244C148.137 85.5952 148.281 85.3882 148.462 85.2152C148.642 85.0422 148.855 84.9067 149.088 84.8166C149.321 84.7265 149.57 84.6835 149.82 84.6901Z"
        fill="#2A3247"
        stroke="#20273B"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.471 85.3201C139.246 85.3186 139.027 85.2403 138.853 85.0979C138.677 84.9553 138.556 84.7571 138.509 84.5362C138.509 84.5361 138.509 84.5361 138.509 84.536L134.932 67.6512C134.897 67.4052 134.956 67.1547 135.097 66.9499C135.241 66.7416 135.459 66.5962 135.706 66.5435C135.954 66.4908 136.212 66.5347 136.428 66.6664C136.64 66.7958 136.796 67.0003 136.864 67.2391L140.44 84.1315C140.44 84.1318 140.441 84.1322 140.441 84.1325C140.493 84.3886 140.443 84.6551 140.3 84.874C140.157 85.0919 139.935 85.245 139.68 85.3C139.611 85.3132 139.541 85.3199 139.471 85.3201Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M139.601 84.1274L139.601 84.1274L139.603 84.1286L149.345 89.9741C149.397 90.0086 149.434 90.0611 149.449 90.1214C149.464 90.1833 149.456 90.2489 149.424 90.3046L149.423 90.3068C149.403 90.3434 149.373 90.374 149.337 90.3956C149.303 90.4166 149.263 90.4284 149.222 90.4299C149.18 90.4296 149.139 90.4187 149.102 90.3982L139.349 84.5423C139.349 84.5422 139.349 84.5421 139.349 84.5419C139.294 84.5085 139.255 84.4549 139.239 84.3926C139.224 84.3301 139.234 84.2641 139.268 84.2091C139.301 84.1541 139.355 84.1146 139.417 84.0993C139.48 84.0839 139.546 84.0941 139.601 84.1274Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M121.72 108.75L121.733 108.754L121.745 108.757C121.826 108.776 121.903 108.807 121.974 108.849L121.985 108.856L121.997 108.862C122.162 108.949 122.309 109.068 122.428 109.212C122.547 109.356 122.637 109.522 122.692 109.701C122.747 109.879 122.766 110.067 122.748 110.253C122.731 110.439 122.676 110.62 122.589 110.785C122.589 110.785 122.589 110.785 122.589 110.785L112.069 130.544C112.069 130.544 112.069 130.545 112.069 130.545C111.981 130.709 111.861 130.855 111.717 130.973C111.573 131.092 111.407 131.181 111.228 131.235C111.05 131.289 110.862 131.308 110.676 131.29C110.491 131.271 110.31 131.217 110.146 131.129C109.981 131.041 109.835 130.921 109.717 130.777C109.598 130.633 109.509 130.467 109.455 130.288C109.401 130.11 109.382 129.922 109.4 129.736C109.419 129.55 109.473 129.37 109.561 129.205L109.561 129.205L120.071 109.445C120.225 109.158 120.472 108.932 120.772 108.805C121.073 108.679 121.407 108.659 121.72 108.75Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M135.458 105.883C135.521 105.867 135.587 105.863 135.652 105.871C135.738 105.909 135.813 105.971 135.866 106.049C135.922 106.132 135.952 106.23 135.952 106.33C135.952 106.43 135.922 106.528 135.866 106.611C135.814 106.687 135.742 106.748 135.659 106.786L121.452 110.557L121.385 110.575L121.325 110.61C121.259 110.649 121.184 110.673 121.107 110.679C121.03 110.684 120.953 110.672 120.881 110.643C120.81 110.614 120.746 110.569 120.695 110.511C120.644 110.453 120.607 110.384 120.587 110.31C120.567 110.235 120.564 110.157 120.58 110.081C120.595 110.006 120.627 109.935 120.675 109.874C120.722 109.813 120.783 109.763 120.852 109.73C120.921 109.696 120.998 109.679 121.075 109.68L121.143 109.681L121.208 109.663L135.457 105.883C135.458 105.883 135.458 105.883 135.458 105.883Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M118.549 129.688L118.554 129.689C118.648 129.695 118.741 129.708 118.833 129.726C119.191 129.829 119.496 130.068 119.681 130.393C119.868 130.722 119.918 131.113 119.818 131.478L119.818 131.478L113.918 153.068L113.917 153.07C113.869 153.25 113.785 153.419 113.671 153.567C113.557 153.715 113.416 153.839 113.254 153.932C113.092 154.025 112.913 154.085 112.728 154.109C112.543 154.133 112.355 154.121 112.175 154.072C111.995 154.024 111.826 153.94 111.678 153.826C111.53 153.712 111.406 153.57 111.313 153.409C111.22 153.247 111.16 153.068 111.136 152.883C111.112 152.698 111.124 152.511 111.173 152.331C111.173 152.331 111.173 152.33 111.173 152.33L117.072 130.732L117.072 130.732L117.073 130.729C117.158 130.41 117.353 130.131 117.623 129.941C117.892 129.751 118.22 129.661 118.549 129.688Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M131.56 123.819L131.56 123.819L131.571 123.82C131.649 123.823 131.724 123.846 131.79 123.888C131.854 123.929 131.907 123.986 131.942 124.053C131.995 124.165 132.004 124.293 131.967 124.411C131.931 124.529 131.853 124.629 131.747 124.693L118.698 131.517L118.634 131.55L118.583 131.6C118.527 131.654 118.459 131.695 118.385 131.718C118.311 131.741 118.232 131.747 118.155 131.734C118.079 131.722 118.006 131.692 117.943 131.646C117.88 131.601 117.828 131.541 117.792 131.473C117.756 131.404 117.737 131.327 117.735 131.25C117.734 131.172 117.751 131.095 117.784 131.025C117.818 130.955 117.867 130.894 117.928 130.846C117.99 130.798 118.061 130.765 118.138 130.75L118.208 130.736L118.272 130.703L131.315 123.867C131.391 123.83 131.475 123.814 131.56 123.819Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M123.068 165.098L123.067 165.1C122.969 165.464 122.73 165.774 122.404 165.962C122.077 166.15 121.689 166.2 121.325 166.102C120.961 166.004 120.651 165.765 120.463 165.439C120.275 165.112 120.225 164.725 120.323 164.361C120.323 164.361 120.323 164.36 120.323 164.36L126.222 142.763C126.222 142.763 126.222 142.763 126.222 142.763C126.31 142.444 126.506 142.166 126.776 141.976C127.042 141.789 127.364 141.697 127.689 141.718L127.996 141.76C128.349 141.865 128.648 142.103 128.831 142.423C129.018 142.752 129.068 143.143 128.968 143.508L128.968 143.508L123.068 165.098Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M140.71 135.849L140.71 135.849L140.718 135.85C140.795 135.853 140.869 135.876 140.933 135.918C140.996 135.958 141.047 136.015 141.082 136.081C141.135 136.194 141.144 136.322 141.107 136.441C141.071 136.559 140.993 136.659 140.887 136.723L127.838 143.547L127.774 143.58L127.723 143.63C127.667 143.684 127.599 143.725 127.525 143.748C127.451 143.771 127.372 143.777 127.296 143.764C127.219 143.752 127.146 143.722 127.083 143.676C127.02 143.631 126.968 143.572 126.932 143.503C126.896 143.434 126.877 143.357 126.875 143.28C126.874 143.202 126.891 143.125 126.924 143.055C126.958 142.985 127.007 142.924 127.068 142.876C127.13 142.828 127.201 142.796 127.278 142.78L127.348 142.766L127.412 142.733L140.465 135.897C140.541 135.86 140.625 135.844 140.71 135.849Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M188.292 108.755L188.302 108.753L188.312 108.75C188.624 108.657 188.959 108.676 189.259 108.803C189.559 108.929 189.806 109.156 189.958 109.444L189.958 109.444L189.959 109.445L200.479 129.205L200.486 129.219L200.495 129.233C200.598 129.4 200.666 129.585 200.694 129.779C200.722 129.973 200.711 130.17 200.66 130.359C200.609 130.548 200.52 130.724 200.398 130.877C200.276 131.03 200.124 131.157 199.951 131.249C199.778 131.341 199.589 131.396 199.394 131.412C199.199 131.427 199.003 131.403 198.817 131.34C198.632 131.276 198.462 131.176 198.317 131.044C198.172 130.913 198.056 130.753 197.976 130.574L197.969 130.56L197.961 130.545L187.443 110.788C187.442 110.787 187.442 110.787 187.442 110.786C187.267 110.452 187.231 110.063 187.342 109.702C187.453 109.341 187.702 109.039 188.035 108.861L188.043 108.857L188.05 108.853C188.126 108.809 188.207 108.776 188.292 108.755Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M174.164 106.049C174.218 105.97 174.293 105.908 174.381 105.871C174.447 105.861 174.515 105.865 174.58 105.883L174.58 105.883L174.582 105.883L188.811 109.66C188.928 109.699 189.027 109.779 189.089 109.886L189.522 109.636L189.089 109.886C189.149 109.99 189.169 110.112 189.147 110.23C189.107 110.341 189.029 110.436 188.926 110.496C188.82 110.557 188.696 110.578 188.576 110.554L174.371 106.786C174.288 106.748 174.216 106.687 174.164 106.611C174.108 106.528 174.078 106.43 174.078 106.33C174.078 106.23 174.108 106.132 174.164 106.049Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M196.073 153.07L196.072 153.068L190.172 131.478L190.172 131.477C190.072 131.113 190.12 130.724 190.306 130.394C190.487 130.075 190.784 129.837 191.134 129.73L191.45 129.688C191.775 129.667 192.098 129.758 192.364 129.946C192.634 130.136 192.83 130.414 192.918 130.733C192.918 130.733 192.918 130.733 192.918 130.733L198.817 152.33C198.817 152.33 198.817 152.331 198.817 152.331C198.866 152.511 198.878 152.698 198.854 152.883C198.83 153.068 198.77 153.247 198.677 153.409C198.584 153.57 198.46 153.712 198.312 153.826C198.164 153.94 197.995 154.024 197.815 154.072C197.635 154.121 197.447 154.133 197.262 154.109C197.077 154.085 196.898 154.025 196.736 153.932C196.574 153.839 196.433 153.715 196.319 153.567C196.205 153.419 196.121 153.25 196.073 153.07Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M178.419 123.82L178.419 123.82L178.43 123.819C178.515 123.814 178.599 123.83 178.675 123.867L191.717 130.697C191.823 130.761 191.901 130.861 191.937 130.978C191.972 131.093 191.965 131.215 191.918 131.325C191.854 131.424 191.758 131.498 191.645 131.533C191.528 131.569 191.402 131.561 191.291 131.511L178.25 124.691C178.143 124.629 178.063 124.529 178.026 124.41C177.989 124.292 177.997 124.164 178.048 124.052C178.084 123.985 178.136 123.929 178.2 123.888C178.266 123.846 178.341 123.823 178.419 123.82Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M183.817 142.759L183.817 142.759L183.818 142.762L189.717 164.36C189.717 164.36 189.717 164.361 189.717 164.361C189.766 164.541 189.778 164.728 189.754 164.913C189.73 165.098 189.67 165.277 189.577 165.439C189.389 165.765 189.079 166.004 188.715 166.102C188.535 166.151 188.347 166.163 188.162 166.139C187.976 166.115 187.798 166.055 187.636 165.962L187.387 166.395L187.636 165.962C187.31 165.774 187.071 165.464 186.973 165.1L186.972 165.098L181.072 143.558C181.072 143.558 181.072 143.558 181.072 143.558C180.972 143.192 181.022 142.802 181.209 142.473C181.397 142.144 181.707 141.902 182.073 141.802L182.09 141.797L182.106 141.792C182.193 141.761 182.281 141.736 182.37 141.716C182.689 141.697 183.006 141.787 183.267 141.971C183.537 142.161 183.732 142.44 183.817 142.759Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M169.319 135.85L169.319 135.85L169.33 135.849C169.415 135.844 169.499 135.86 169.575 135.897L182.618 142.733L182.682 142.766L182.752 142.78C182.829 142.796 182.9 142.828 182.962 142.876C183.023 142.924 183.072 142.985 183.106 143.055C183.139 143.125 183.156 143.202 183.155 143.28C183.153 143.357 183.134 143.434 183.098 143.503C183.062 143.571 183.01 143.631 182.947 143.676C182.884 143.722 182.811 143.752 182.735 143.764C182.658 143.777 182.579 143.771 182.505 143.748C182.431 143.725 182.363 143.684 182.307 143.63L182.256 143.58L182.192 143.547L169.143 136.723C169.037 136.659 168.959 136.559 168.923 136.441C168.886 136.323 168.895 136.195 168.948 136.083C168.983 136.016 169.036 135.959 169.1 135.918C169.166 135.876 169.241 135.853 169.319 135.85Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M172.315 85.3201L172.315 85.3201H172.31H172.058C171.948 85.2914 171.845 85.2439 171.751 85.1797C171.645 85.1066 171.554 85.0132 171.484 84.905C171.414 84.7967 171.366 84.6757 171.343 84.5488C171.319 84.4219 171.322 84.2916 171.349 84.1655L171.349 84.1634L174.916 67.269C174.984 67.0303 175.14 66.8258 175.352 66.6964C175.568 66.5648 175.826 66.5208 176.074 66.5735C176.321 66.6262 176.539 66.7716 176.683 66.9799C176.824 67.1848 176.883 67.4353 176.848 67.6814L173.283 84.5771C173.228 84.7899 173.104 84.9785 172.93 85.113C172.754 85.2493 172.537 85.3222 172.315 85.3201Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M162.593 90.43L162.59 90.43C162.548 90.43 162.505 90.419 162.468 90.3979C162.433 90.3781 162.403 90.35 162.381 90.3161C162.353 90.2631 162.345 90.2013 162.36 90.143C162.376 90.0826 162.415 90.0307 162.468 89.9983C162.468 89.9982 162.468 89.9981 162.469 89.998L172.211 84.1524C172.265 84.123 172.328 84.1151 172.387 84.1305C172.447 84.146 172.498 84.1839 172.531 84.2361C172.545 84.2616 172.555 84.2895 172.559 84.3184C172.563 84.3484 172.561 84.3789 172.553 84.4081C172.546 84.4374 172.533 84.4648 172.514 84.489C172.496 84.5131 172.473 84.5334 172.447 84.5487L172.447 84.5487L172.442 84.5515L162.705 90.4039C162.67 90.4213 162.632 90.4302 162.593 90.43Z"
        fill="#00C5A3"
        stroke="#2A3247"
      />
      <path
        d="M155.4 145.46C168.865 145.46 179.78 134.545 179.78 121.08C179.78 107.615 168.865 96.7 155.4 96.7C141.935 96.7 131.02 107.615 131.02 121.08C131.02 134.545 141.935 145.46 155.4 145.46Z"
        fill="#00C5A3"
        stroke="#20273B"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.39 127.42V114.74L155.4 108.4L144.42 114.74V127.42L155.4 133.76L166.39 127.42Z"
        fill="#0F162B"
        stroke="#20273B"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M155.4 108.4V96.6899" stroke="#20273B" strokeWidth="1.08761" strokeMiterlimit="10" />
      <path d="M155.4 133.76V145.46" stroke="#20273B" strokeWidth="1.08761" strokeMiterlimit="10" />
      <path d="M131.02 121.08H179.79" stroke="#20273B" strokeWidth="1.08761" strokeMiterlimit="10" />
      <path
        d="M289.04 326.81H24.64L36.63 293.59H277.05L289.04 326.81Z"
        fill="#465064"
        stroke="#20273B"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M273.27 297.5H40.41L41 295.79H272.68L273.27 297.5Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.17 301.98H38.29L39.28 299.12H47.09L46.17 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.82 301.98H59.95L60.76 299.12H68.57L67.82 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.52 301.98H69.64L70.37 299.12H78.18L77.52 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.21 301.98H79.34L79.99 299.12H87.8L87.21 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.9 301.98H89.03L89.6 299.12H97.41L96.9 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.69 301.98H107.81L108.22 299.12H116.03L115.69 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.38 301.98H117.51L117.84 299.12H125.65L125.38 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.08 301.98H127.2L127.45 299.12H135.26L135.08 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.77 301.98H136.89L137.06 299.12H144.87L144.77 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M247.02 306.64H239.04L238.34 303.7H246.26L247.02 306.64Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M256.85 306.64H248.86L248.08 303.7H256L256.85 306.64Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M266.67 306.64H258.69L257.83 303.7H265.74L266.67 306.64Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M276.5 306.64H268.52L267.57 303.7H275.48L276.5 306.64Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M248.13 310.89H240.05L239.33 307.87H247.34L248.13 310.89Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.07 310.89H249.99L249.19 307.87H257.2L258.07 310.89Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.02 310.89H259.94L259.05 307.87H267.07L268.02 310.89Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M249.26 315.24H241.08L240.34 312.15H248.46L249.26 315.24Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M259.33 315.24H251.15L250.33 312.15H258.44L259.33 315.24Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M269.4 315.24H261.22L260.31 312.15H268.42L269.4 315.24Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M268.91 307.87L269.45 309.54L269.89 310.89L271.29 315.24H279.47L277.97 310.89L277.5 309.54L276.93 307.87H268.91Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M250.42 319.69H242.14L241.39 316.53H249.6L250.42 319.69Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M260.62 319.69H252.33L251.49 316.53H259.7L260.62 319.69Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M270.82 319.69H262.53L261.6 316.53H269.81L270.82 319.69Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M256.76 321.02H252.69H242.45L243.22 324.27H253.55H257.66H261.94L261 321.02H256.76Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M272.27 324.27H263.88L262.92 321.02H271.24L272.27 324.27Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M271.71 316.53L273.15 321.02L273.44 321.9L274.2 324.27H282.59L281.78 321.9L281.47 321.02L279.92 316.53H271.71Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.55 301.98H155.68L155.69 299.12H163.5L163.55 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.25 301.98H165.37L165.3 299.12H173.11L173.25 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.94 301.98H175.06L174.91 299.12H182.72L182.94 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.63 301.98H184.76L184.52 299.12H192.33L192.63 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.64 306.32H155.66L155.67 303.39H163.58L163.64 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.46 306.32H165.48L165.4 303.39H173.31L173.46 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.05 303.39H182.61H175.14L175.29 306.32H182.84H183.27H193.09L192.78 303.39H183.05Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.01 306.32H116.03L116.38 303.39H124.29L124.01 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.83 306.32H125.85L126.11 303.39H134.02L133.83 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.65 306.32H135.67L135.85 303.39H143.76L143.65 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.46 306.32H145.49L145.58 303.39H153.49L153.46 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.38 306.32H76.41L77.09 303.39H85L84.38 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.2 306.32H86.23L86.83 303.39H94.73L94.2 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.02 306.32H96.04L96.56 303.39H104.47L104.02 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.84 306.32H105.86L106.29 303.39H114.2L113.84 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.76 306.32H36.78L37.8 303.39H45.71L44.76 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.58 306.32H46.6L47.53 303.39H55.44L54.58 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.39 306.32H56.42L57.27 303.39H65.18L64.39 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.21 306.32H66.23L67 303.39H74.91L74.21 306.32Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.72 310.61H155.65L155.66 307.6H163.66L163.72 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.66 310.61H165.59L165.51 307.6H173.52L173.66 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.37 307.6H182.93H175.36L175.52 310.61H183.16H183.6H193.54L193.22 307.6H183.37Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.6 310.61H115.53L115.89 307.6H123.89L123.6 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.54 310.61H125.47L125.74 307.6H133.74L133.54 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.48 310.61H135.41L135.59 307.6H143.6L143.48 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.42 310.61H145.35L145.45 307.6H153.45L153.42 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.49 310.61H75.41L76.11 307.6H84.12L83.49 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.43 310.61H85.35L85.97 307.6H93.97L93.43 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.37 310.61H95.29L95.82 307.6H103.83L103.37 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.3 310.61H105.23L105.67 307.6H113.68L113.3 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.37 310.61H35.3L36.34 307.6H44.35L43.37 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.31 310.61H45.24L46.19 307.6H54.2L53.31 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.25 310.61H55.17L56.05 307.6H64.05L63.25 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.19 310.61H65.11L65.9 307.6H73.91L73.19 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.81 314.99H155.63L155.64 311.91H163.75L163.81 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.87 314.99H165.7L165.62 311.91H173.72L173.87 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.7 311.91H183.26H175.59L175.76 314.99H183.49H183.93H194L193.67 311.91H183.7Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.19 314.99H115.01L115.38 311.91H123.48L123.19 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.25 314.99H125.08L125.35 311.91H133.46L133.25 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.32 314.99H135.14L135.33 311.91H143.43L143.32 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.38 314.99H145.2L145.3 311.91H153.41L153.38 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.57 314.99H74.39L75.11 311.91H83.22L82.57 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.63 314.99H84.46L85.09 311.91H93.19L92.63 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.7 314.99H94.52L95.06 311.91H103.17L102.7 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.76 314.99H104.58L105.04 311.91H113.14L112.76 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.95 314.99H33.77L34.84 311.91H42.95L41.95 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.01 314.99H43.84L44.82 311.91H52.93L52.01 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.08 314.99H53.9L54.8 311.91H62.9L62.08 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.14 314.99H63.96L64.77 311.91H72.88L72.14 314.99Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.9 319.49H155.62L155.63 316.33H163.83L163.9 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.04 316.33H183.59H175.83H165.73L165.81 319.49H176H179.29H183.83H184.28H194.47L194.14 316.33H184.04Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.76 319.49H114.48L114.85 316.33H123.06L122.76 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.95 319.49H124.67L124.96 316.33H133.16L132.95 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.14 319.49H134.86L135.06 316.33H143.26L143.14 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.33 319.49H145.05L145.16 316.33H153.36L153.33 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.63 319.49H73.35L74.08 316.33H82.29L81.63 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.82 319.49H83.54L84.18 316.33H92.39L91.82 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.01 319.49H93.73L94.28 316.33H102.49L102.01 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.2 319.49H103.92L104.39 316.33H112.59L112.2 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.52 316.33H37.79H33.31L32.21 319.49H36.73H40.49H50.68L51.62 316.33H41.52Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.87 319.49H52.59L53.51 316.33H61.72L60.87 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.06 319.49H62.78L63.61 316.33H71.82L71.06 319.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.99 324.11H155.6L155.61 320.86H163.92L163.99 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.31 324.11H165.92L165.84 320.86H174.15L174.31 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.37 324.11H175.98L175.81 320.86H184.12L184.37 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M194.42 324.11H186.04L185.78 320.86H194.09L194.42 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.32 324.11H113.94L114.32 320.86H122.63L122.32 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.65 324.11H124.26L124.55 320.86H132.86L132.65 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.97 324.11H134.58L134.78 320.86H143.09L142.97 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.66 324.11H72.27L73.03 320.86H81.34L80.66 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.98 324.11H82.59L83.26 320.86H91.57L90.98 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.3 324.11H92.92L93.49 320.86H101.8L101.3 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.63 324.11H103.24L103.72 320.86H112.03L111.63 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39 324.11H30.61L31.74 320.86H40.05L39 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.32 324.11H40.93L41.97 320.86H50.28L49.32 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.64 324.11H51.25L52.19 320.86H60.51L59.64 324.11Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.68 320.86H145.01H62.43L61.58 324.11H144.9H146.59H153.29L153.32 320.86H146.68Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M211.42 301.98H203.54L203.15 299.12H210.96L211.42 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.11 301.98H213.23L212.76 299.12H220.57L221.11 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M230.8 301.98H222.93L222.37 299.12H230.18L230.8 301.98Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.14 306.49H204.16L203.76 303.56H211.67L212.14 306.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.96 306.49H213.98L213.49 303.56H221.41L221.96 306.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M231.78 306.49H223.8L223.23 303.56H231.15L231.78 306.49Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.79 310.61H204.72L204.31 307.6H212.31L212.79 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.73 310.61H214.66L214.16 307.6H222.17L222.73 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.67 310.61H224.6L224.01 307.6H232.02L232.67 310.61Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.47 314.82H205.29L204.87 311.73H212.97L213.47 314.82Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.53 314.82H215.35L214.84 311.73H222.95L223.53 314.82Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M233.58 314.82H225.41L224.82 311.73H232.92L233.58 314.82Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M224.51 320.04H216.22L215.69 316.87H223.91L224.51 320.04Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.26 324.04H216.88L216.34 320.8H224.65L225.26 324.04Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M235.32 324.04H226.93L226.31 320.8H234.62L235.32 324.04Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M214.73 324.04H206.34L205.9 320.8H214.21L214.73 324.04Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M277.43 288H13V291.25H277.43V288Z"
        fill="#647083"
        stroke="#2A3247"
        strokeWidth="1.08761"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InternalError;
