import { createContext, useCallback, useContext, useState } from 'react';

import { AppContextType, AppStateType } from './app-context.type';
import { RouteType } from '../shared/models/route/RouteType';

// eslint-disable-next-line
export const AppContext = createContext<AppContextType>(null as any);

const AppStateInitialState = {
  routes: new Array<RouteType>(),
  dynamicRoutesLoaded: false,
  userPermissions: new Array<string>(),
  userPermissionsLoaded: false,
  hasUserPermissions: false,
  reloadUserPermissions: false,
  titleText: '',
  titlePathname: ''
};

// eslint-disable-next-line
export function AppProvider(props: any) {
  const [state, setState] = useState<AppStateType>(AppStateInitialState);

  const setDynamicRoutesLoaded = useCallback((dynamicRoutesLoaded: boolean) => {
    setState((current: AppStateType) => ({
      ...current,
      dynamicRoutesLoaded
    }));
  }, []);

  const setRoutes = useCallback((routes: Array<RouteType>) => {
    setState((current: AppStateType) => ({
      ...current,
      routes
    }));
  }, []);

  const setUserPermissions = useCallback((userPermissions: string[]) => {
    setState((current: AppStateType) => ({
      ...current,
      userPermissions
    }));
  }, []);

  const setReloadUserPermissions = useCallback((reloadUserPermissions: boolean) => {
    setState((current: AppStateType) => ({
      ...current,
      reloadUserPermissions
    }));
  }, []);

  const setHasUserPermissions = useCallback((hasUserPermissions: boolean) => {
    setState((current: AppStateType) => ({
      ...current,
      hasUserPermissions
    }));
  }, []);

  const setUserPermissionsLoaded = useCallback((userPermissionsLoaded: boolean) => {
    setState((current: AppStateType) => ({
      ...current,
      userPermissionsLoaded
    }));
  }, []);

  const setAppHeaderTitle = useCallback((titleText: string, titlePathname: string) => {
    setState((current: AppStateType) => ({
      ...current,
      titleText,
      titlePathname
    }));
  }, []);

  const hasAccessFor = useCallback(
    (requiredPermissions: string[]) => requiredPermissions.every((p) => state.userPermissions.includes(p)),
    [state.userPermissions]
  );

  const value: AppContextType = {
    state,
    setRoutes,
    setDynamicRoutesLoaded,
    hasAccessFor,
    setAppHeaderTitle,
    setUserPermissions,
    setUserPermissionsLoaded,
    setReloadUserPermissions,
    setHasUserPermissions
  };

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
}

export function useApp(): AppContextType {
  return useContext(AppContext);
}
