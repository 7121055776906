import { createContext, useContext } from 'react';

export type JobNotificationsStateType = {
  bucketsModelUpdateStarted: boolean;
  bucketsModelUpdateFinished: boolean;
};

export type JobNotificationsContextType = {
  state: JobNotificationsStateType;
  setBucketsModelUpdateStarted: (bucketsModelUpdateStarted: boolean) => void;
  setBucketsModelUpdateFinished: (bucketsModelUpdateFinished: boolean) => void;
};

export const jobNotificationsContext = createContext<JobNotificationsContextType | undefined>(undefined);

export function useJobNotificationsContext() {
  const context = useContext(jobNotificationsContext);
  if (context === undefined) {
    throw new Error('useJobNotificationsContext must be used within JobNotificationsProvider');
  }
  return context;
}
