import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { Aggregation } from './Aggregation';

@JsonObject('ActionAggregationsResponse')
export class ActionAggregationsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('aggregations', [Aggregation])
  aggregations: Aggregation[];
}
