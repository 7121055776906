import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { JobOverviewSplitStatsTotal } from './JobOverviewSplitStatsTotal';
import { JobOverviewSplitStatsSplit } from './JobOverviewSplitStatsSplit';

@JsonObject('JobOverviewSplitStatsResponse')
export class JobOverviewSplitStatsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('total', JobOverviewSplitStatsTotal)
  total: JobOverviewSplitStatsTotal;

  @JsonProperty('split', [JobOverviewSplitStatsSplit])
  split: JobOverviewSplitStatsSplit[];
}
