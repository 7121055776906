import { Controller, useFormContext } from 'react-hook-form';
import { Label, Checkbox, TCheckboxState } from '@arcanna/generic';
import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type TRHFCheckboxProps = {
  name: string;
  label?: string;
  dataTestId?: string;
  tooltipText?: string;
  required?: boolean;
  checkboxLabel: ReactNode;
  disabled?: boolean;
  onChange?: (state: TCheckboxState) => void;
};

function RHFCheckbox({ checkboxLabel, label, name, tooltipText, required, disabled, onChange, dataTestId }: TRHFCheckboxProps) {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Stack direction="column" gap={0.5}>
          {label && <Label text={label} required={required} tooltipText={tooltipText} />}
          <Checkbox
            data-test-id={dataTestId}
            label={checkboxLabel}
            onChange={(state) => {
              field.onChange(state === 'checked');

              if (onChange) {
                onChange(state);
              }
            }}
            state={field.value ? 'checked' : 'default'}
            disabled={disabled || isSubmitting}
          />
        </Stack>
      )}
    />
  );
}

export default RHFCheckbox;
