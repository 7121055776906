import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobRetrainRequest')
export class JobRetrainRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number | undefined;

  constructor(job_id: number) {
    super();
    this.job_id = job_id;
  }
}
