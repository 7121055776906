import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { CommonPaginationRequest } from '../common/CommonPaginationRequest';
import { CommonSortedRequest } from '../common/CommonSortedRequest';

@JsonObject('JobSummaryRequest')
export class JobSummaryRequest extends CommonRequest {
  @JsonProperty('pagination', CommonPaginationRequest)
  pagination?: CommonPaginationRequest = undefined;

  @JsonProperty('sorting', CommonSortedRequest)
  sorting?: CommonSortedRequest = undefined;

  @JsonProperty('search_term', String)
  search_term?: string = undefined;

  constructor(pagination: CommonPaginationRequest, sorting: CommonSortedRequest, search_term?: string) {
    super();
    this.pagination = pagination;
    this.sorting = sorting;
    this.search_term = search_term;
  }
}
