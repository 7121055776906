import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { OperatorsRequest } from '../../../components/shared/models/custom-field/OperatorsRequest';
import { OperatorsResponse } from '../../../components/shared/models/custom-field/OperatorsResponse';
import { config } from '../../../config';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';

export function useCustomFieldOperators(selectedInputType: string | undefined) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
  const requestPayload = new OperatorsRequest(selectedInputType);

  return useQuery(
    [config.api.customFieldOperators, requestPayload],
    () =>
      axios
        .post<{ resource: OperatorsResponse }>(config.api.customFieldOperators, requestPayload)
        .then((responseData: AxiosResponse<{ resource: OperatorsResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, OperatorsResponse)
        ),
    {
      enabled: selectedInputType !== undefined,
      staleTime: Infinity
    }
  );
}
