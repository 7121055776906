import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ELinkGeneratorPages } from '../RedirectLinkGenerator.types';
import { constructExplorerBucketIdRedirectURL, constructFeedbackBucketIdRedirectURL } from '../RedirectLinkGenerator.utils';
import { Spinner } from '@arcanna/generic';

type TRedirectDIFromBucketIdProps = {
  pageId: ELinkGeneratorPages;
  bucketId: string;
  jobId: string;
};

function RedirectDIFromBucketId({ jobId, bucketId, pageId }: TRedirectDIFromBucketIdProps) {
  const history = useHistory();

  useEffect(() => {
    if (pageId === ELinkGeneratorPages.feedbackWithBucketId) {
      return history.replace(constructFeedbackBucketIdRedirectURL({ jobId: jobId, bucketId }));
    }

    if (pageId === ELinkGeneratorPages.explorerWithBucketId) {
      return history.replace(constructExplorerBucketIdRedirectURL({ jobId: jobId, bucketId }));
    }
  }, [jobId, history, bucketId, pageId]);

  return <Spinner isOverlay />;
}

export default RedirectDIFromBucketId;
