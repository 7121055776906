import { JsonObject, JsonProperty } from 'json2typescript';
import { LOCAL_STORAGE_TOKEN_NAME } from '../../../pages/Main/Login/AuthConstants';

@JsonObject('JobTimeMetricsRequest')
export class JobTimeMetricsRequest {
  @JsonProperty('user_session_id', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  user_session_id: string = undefined;

  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('time_units', String)
  time_units: string;

  @JsonProperty('time_value', Number)
  time_value: number;

  constructor(job_id: number, time_units: string, time_value: number) {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    this.user_session_id = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
    this.job_id = job_id;
    this.time_units = time_units;
    this.time_value = time_value;
  }
}
