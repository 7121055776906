import { RHFTextField } from '@arcanna/forms';
import { getDataTestIdFormat } from 'src/utils/getDataTestIdFormat';
import { getFormParameterName } from '../RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';

function StringParameter({ parameter, formSectionName }: TRHFSmartGeneratedFieldProps) {
  return (
    <RHFTextField
      name={getFormParameterName(parameter, formSectionName)}
      label={parameter.caption}
      tooltipText={parameter.description}
      hidden={parameter.visible === false}
      required={parameter.required}
      placeholder={parameter.placeholder || 'Value'}
      dataTestId={getDataTestIdFormat(`parameter-field-${parameter.field}`)}
    />
  );
}

export default StringParameter;
