import { Card, CardProps, styled } from '@mui/material';

const StyledArcannaDecisionTimeseries = styled(Card)<CardProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary[900],
  border: `1px solid ${theme.palette.secondary[800]}`,
  maxHeight: '400px',
  overflow: 'visible',

  '.MuiTypography-subtitle2': {
    color: theme.palette.secondary[400]
  },

  '.MuiTypography-subtitle1': {
    color: theme.palette.secondary[400]
  },

  '.eventNumber': {
    color: theme.palette.secondary[100],
    marginLeft: '10px'
  }
}));

export default StyledArcannaDecisionTimeseries;
