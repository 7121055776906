import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery, useQueryClient } from 'react-query';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { JobSummaryRequest } from '../../components/shared/models/job/JobSummaryRequest';
import { CommonPaginationRequest } from '../../components/shared/models/common/CommonPaginationRequest';
import { CommonSortedRequest } from '../../components/shared/models/common/CommonSortedRequest';
import { JobSummaryResponse } from '../../components/shared/models/job/JobSummaryResponse';

type UseJobSummary = {
  categoryId?: number;
  tablePage?: number;
  tablePageSize?: number;
  sortByColumn?: string;
  sortDirection?: string;
  searchTerm?: string;
  enabled?: boolean;
};
export function useJobsSummary({
  categoryId = undefined,
  tablePage = 1,
  tablePageSize = 1000,
  sortByColumn = undefined,
  sortDirection = 'asc',
  searchTerm = '',
  enabled = true
}: UseJobSummary) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const requestPath = categoryId
    ? config.api.jobsSummaryByCategory.replace(':categoryId', categoryId.toString())
    : config.api.jobsSummary;
  return useQuery(
    [requestPath, tablePage, tablePageSize, sortByColumn, sortDirection, searchTerm],
    () =>
      axios
        .post<{
          resource: JobSummaryResponse;
        }>(
          requestPath,
          new JobSummaryRequest(
            new CommonPaginationRequest((tablePage - 1) * tablePageSize, tablePageSize),
            // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
            new CommonSortedRequest(sortByColumn, sortDirection),
            searchTerm
          )
        )
        .then((responseData: AxiosResponse<{ resource: JobSummaryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobSummaryResponse)
        ),
    {
      enabled: enabled
    }
  );
}

export function useInvalidateJobsSummary() {
  const queryClient = useQueryClient();

  const invalidateJobsSummary = () => {
    queryClient.invalidateQueries([config.api.jobsSummary]);
  };
  return { invalidateJobsSummary };
}
