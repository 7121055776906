import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Drawer, Space, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { useJobMetrics } from '../../../../../../../../data-access';
import { FeatureDistribution, FeatureOccurence } from '../../../../../../../shared/models/retrain-history/JobMetricsResponse';
import { useMemo, useState } from 'react';
import { FeatureDistributionCard } from './FeatureDistributionCard';

type FeatureDataDistributionProps = {
  jobId: number;
  modelId: string;
};
export default function FeatureDataDistribution({ jobId, modelId }: FeatureDataDistributionProps) {
  const { t } = useTranslation(['overview']);
  const [expandedFeature, setExpandedFeature] = useState<FeatureDistribution | undefined>(undefined);
  const jobMetricsQuery = useJobMetrics(jobId, modelId);

  const areAllOccurencesEmpty = useMemo(() => {
    let allOccurences: FeatureOccurence[] = [];
    jobMetricsQuery.data?.featureDistribution?.forEach((featureDistribution) => {
      allOccurences = allOccurences.concat(featureDistribution.occurences || []);
    });
    return allOccurences.length == 0;
  }, [jobMetricsQuery.data]);

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const sortedFeatures = jobMetricsQuery.data?.featureDistribution
    .slice(0)
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    .sort((a, b) => b.occurences?.length - a.occurences?.length);

  return areAllOccurencesEmpty ? (
    <></>
  ) : (
    <>
      <Typography className="color-nb-0 callout m-t-24 m-b-16">
        <Space>
          <span>{t('overview:dataDistribution')}</span>
          <Tooltip title={t('overview:dataDistributionTooltip')}>
            <InfoCircleOutlined className="color-nb-400" />
          </Tooltip>
        </Space>
      </Typography>

      {jobMetricsQuery.data ? (
        <div className={styles.labelsRow}>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {sortedFeatures.map((featureDistribution: FeatureDistribution) => (
            <FeatureDistributionCard
              key={featureDistribution.featureName}
              featureDistribution={featureDistribution}
              onViewAll={setExpandedFeature}
              rootStyle={{ width: '400px' }}
            />
          ))}
        </div>
      ) : null}

      <Drawer
        open={expandedFeature !== undefined}
        title={
          <div className="flex flex-between">
            <Space align="center" className="color-nb-100">
              <span className="headline">{expandedFeature?.featureName}</span>
            </Space>
          </div>
        }
        headerStyle={{
          backgroundColor: 'var(--secondary-color-nb-1000)',
          padding: '16px 40px 16px 32px',
          borderBottom: '1px solid var(--secondary-color-nb-800)'
        }}
        bodyStyle={{
          padding: '24px 40px 40px 40px',
          backgroundColor: 'var(--secondary-color-nb-1000)'
        }}
        width="50%"
        destroyOnClose
        closeIcon={<CloseOutlined className={styles.closeIcon} size={16} />}
        onClose={() => setExpandedFeature(undefined)}
      >
        {expandedFeature !== undefined ? (
          <FeatureDistributionCard featureDistribution={expandedFeature} displayAllOccurences />
        ) : null}
      </Drawer>
    </>
  );
}
