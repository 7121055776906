import { Spinner } from '@arcanna/generic';
import { QueryParams } from '@arcanna/utils';
import { TLinkGeneratorQueryParams } from './RedirectLinkGenerator.types';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { config } from 'src/config';
import RedirectDIFromJobIdAndEventId from './components/RedirectDIFromJobIdAndEventId';
import RedirectDIFromBucketId from './components/RedirectDIFromBucketId';
import { getIsDIJobRelated } from './RedirectLinkGenerator.utils';
import RedirectRCAFromJobIdAndClusterId from './components/RedirectRCAFromJobIdAndClusterId';
import RedirectRCAFromJobIdAndEventId from './components/RedirectRCAFromJobIdAndEventId';

function RedirectLinkGenerator() {
  const history = useHistory();
  const { queryParams } = QueryParams.useQueryParams<TLinkGeneratorQueryParams>();

  useEffect(() => {
    // if no redirect happens in 5s -> redirect user to 404
    const timeoutId = setTimeout(() => history.push(config.routes.page404), 5000);

    return () => clearTimeout(timeoutId);
  }, [history]);

  if (queryParams.pageId) {
    if (queryParams.jobId && getIsDIJobRelated(queryParams.pageId)) {
      if (queryParams.eventId) {
        return (
          <RedirectDIFromJobIdAndEventId pageId={queryParams.pageId} jobId={queryParams.jobId} eventId={queryParams.eventId} />
        );
      }

      if (queryParams.bucketId) {
        return <RedirectDIFromBucketId pageId={queryParams.pageId} jobId={queryParams.jobId} bucketId={queryParams.bucketId} />;
      }
    }
    if (queryParams.jobId && !getIsDIJobRelated(queryParams.pageId)) {
      if (queryParams.eventId) {
        return (
          <RedirectRCAFromJobIdAndEventId pageId={queryParams.pageId} jobId={queryParams.jobId} eventId={queryParams.eventId} />
        );
      }
      if (queryParams.clusterId) {
        return (
          <RedirectRCAFromJobIdAndClusterId
            pageId={queryParams.pageId}
            jobId={queryParams.jobId}
            clusterId={queryParams.clusterId}
          />
        );
      }
    }
  }

  return <Spinner isOverlay />;
}

export default RedirectLinkGenerator;
