import { useMemo } from 'react';
import { RHFSelect } from '@arcanna/forms';
import { getDataTestIdFormat } from 'src/utils/getDataTestIdFormat';
import { getFormParameterName } from '../RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';
import { TOption } from '@arcanna/generic';

function UserApiKeyParameter({ parameter, formSectionName }: TRHFSmartGeneratedFieldProps) {
  const options: TOption[] = useMemo(
    () =>
      (parameter.allowed_values
        ?.map((option) => {
          return {
            label: option.value,
            value: option.value
          };
        })
        .filter((option) => Boolean(option.value) && Boolean(option.label)) ?? []) as TOption[],
    [parameter]
  );

  return (
    <RHFSelect
      label={parameter.caption}
      name={getFormParameterName(parameter, formSectionName)}
      tooltipText={parameter.description}
      dataTestId={getDataTestIdFormat(`parameter-field-${parameter.field}`)}
      options={options}
      required={parameter.required}
    />
  );
}

export default UserApiKeyParameter;
