import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('FeedbackUsersResponse')
export class FeedbackUsersResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('feedback_users', [String])
  feedback_users?: string[] = [];
}
