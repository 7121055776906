import numeral from 'numeral';

export const numeralFormat = (number: number | string, pattern: string) => {
  const roundingFunction = number >= 0 ? Math.floor : Math.ceil;
  return numeral(number).format(pattern, roundingFunction);
};

export const generateDigitsPattern = (numberOfDigits: number) => {
  return Array(numberOfDigits).fill(0).join('');
};

export const formatNumber = (number = 0, numberOfDigits = 0) => {
  return numeralFormat(number, `0,0.${generateDigitsPattern(numberOfDigits)}`);
};

export const isNumeric = (str: string) => {
  if (typeof str != 'string') return false;
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
};

export function divMod(dividend: number, divisor: number) {
  if (divisor === 0) {
    return { quotient: 0, remainder: 0 };
  }

  const quotient = Math.floor(dividend / divisor);
  const remainder = dividend % divisor;

  return { quotient, remainder };
}
