import { useMemo, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { useRouteMatch } from 'react-router-dom';

import { QueryMetric } from '../../../shared/components/QueryVisualisation/QueryMetric';
import { QueryPiechart } from '../../../shared/components/QueryVisualisation/QueryPiechart';
import { QueryTimeseries } from '../../../shared/components/QueryVisualisation/QueryTimeseries';
import { QueryGeoMap } from '../../../shared/components/QueryVisualisation/QueryGeoMap';
import { QueryFilters } from '../../../shared/components/QueryVisualisation/QueryFilters';
import { VisualizationRecord } from '../../../shared/models/usecase/VisualizationRecord';

import { useCaseHook } from './Usecase.hook';
import styles from './Usecase.module.css';
import { QueryBarChart } from '../../../shared/components/QueryVisualisation/QueryBarchart';
import { QueryMetricCircle } from '../../../shared/components/QueryVisualisation/QueryMetricCircle';
import { useVisualizationsContext } from '../../../shared/components/QueryVisualisation/Visualizations.context';
import { MultiLayerRecord } from '../../../shared/models/usecase/MultiLayerRecord';
import { ExportPdf } from '../../../shared/components/Export/ExportPdf';
import { QueryPiechartNested } from '../../../shared/components/QueryVisualisation/QueryPiechartNested';
import { QueryBarChartStacked } from '../../../shared/components/QueryVisualisation/QueryBarchartStacked';
import { QueryPiechartMultiple } from '../../../shared/components/QueryVisualisation/QueryPiechartMultiple';
import { QueryTable } from '../../../shared/components/QueryVisualisation/QueryTable';
import { QueryTextBox } from '../../../shared/components/QueryVisualisation/QueryTextBox';
import { FilterProperty } from '../../../shared/components/Visualisation/FilterProps';
import { FiltersClickable } from '../../../shared/components/Visualisation/FiltersClickable';
import { QueryTimeseriesArea } from '../../../shared/components/QueryVisualisation/QueryTimeseriesArea';
import { QueryGaugeChart } from '../../../shared/components/QueryVisualisation/QueryGaugeChart';
import { TimeFilterFragment } from '../../../shared/components/Visualisation/TimeFilterFragment';

export function Usecase() {
  const visualizationContext = useVisualizationsContext();
  const match = useRouteMatch('/usecases/usecase/:useCaseId');
  // @ts-expect-error TS(2339): Property 'params' does not exist on type 'match<{}...
  const { params } = match;
  // eslint-disable-next-line
  const usecaseId = (params as any).useCaseId;
  const { getMetadata } = useCaseHook();

  useMemo(() => {
    getMetadata(usecaseId);
  }, [usecaseId, getMetadata]);

  // On use case change
  useEffect(() => {
    visualizationContext.setZeroToLoadedVisuals();
    visualizationContext.setFiltersProps({ filters: Array<FilterProperty>() });
    visualizationContext.setClickableFilters({
      filters: Array<FilterProperty>()
    });
    // eslint-disable-next-line
  }, [usecaseId]);

  // Render
  if (visualizationContext.state.page != null) {
    return (
      <>
        <div id="usecase-canvas">
          <Row style={{ marginBottom: '10px' }}>
            <Col span="24" className="flex flex-end">
              {/* @ts-expect-error TS(2322): Type '{ length?: number | undefined; toString?: ((... */}
              <ExportPdf {...visualizationContext.state.page.exports} />
            </Col>
          </Row>
          <Row>
            <TimeFilterFragment />
          </Row>
          <Row>
            <Col span="24">&nbsp;</Col>
          </Row>
          <Row>
            <FiltersClickable />
          </Row>
          <Row>
            {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
            {visualizationContext.state.page.visualizations.map((entry: VisualizationRecord) => (
              <Col key={entry.name} span={entry.style['col-width']}>
                <Card className={styles.visualCard}>
                  {(() => {
                    switch (entry.type) {
                      case 'filters':
                        return <QueryFilters {...entry} />;
                      case 'metrics':
                        return <QueryMetric {...entry} />;
                      case 'metriccircle':
                        return <QueryMetricCircle {...entry} />;
                      case 'piechart':
                        return <QueryPiechart {...entry} />;
                      case 'piechartmultiple':
                        return <QueryPiechartMultiple {...entry} />;
                      case 'piechartnested':
                        return <QueryPiechartNested {...entry} />;
                      case 'barchart':
                        return <QueryBarChart {...entry} />;
                      case 'barchartstacked':
                        return <QueryBarChartStacked {...entry} />;
                      case 'timeseries':
                        return <QueryTimeseries {...entry} />;
                      case 'timeseriesarea':
                        return <QueryTimeseriesArea {...entry} />;
                      case 'table':
                        return <QueryTable {...entry} />;
                      case 'geomap':
                        return <QueryGeoMap {...(entry as MultiLayerRecord)} />;
                      case 'textbox':
                        return <QueryTextBox {...entry} />;
                      case 'gauge':
                        return <QueryGaugeChart {...entry} />;
                      default:
                        return <></>;
                    }
                  })()}
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </>
    );
  }

  return <></>;
}
