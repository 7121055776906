import { JobSummaryRecord } from 'src/components/shared/models/job/JobSummaryRecord';
import JobState from './components/JobState';
import JobInvalidState from './components/JobInvalidState';
import JobRetrainState from './components/JobRetrainState';
import { EJobTrainingState } from 'src/components/shared/models/job/EJobTrainingState';

type TJobStateTagProps = {
  job: JobSummaryRecord;
};

function JobStateTag({ job }: TJobStateTagProps) {
  return job.status && job.id ? (
    <>
      <JobState state={job.status.state} message={job.status.reason} jobId={job.id} />
      <JobInvalidState job={job} />
      <JobRetrainState retrainState={job.status.retrain_state as EJobTrainingState} message={job.status.retrain_msg} />
    </>
  ) : null;
}

export default JobStateTag;
