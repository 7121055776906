import { JsonObject, JsonProperty } from 'json2typescript';
import { LLMSettings } from './LLMSettings';
import { SchedulersSettings } from './SchedulersSettings';

@JsonObject('CustomLabel')
export class CustomLabel {
  @JsonProperty('id', String)
  id: string;

  @JsonProperty('name', String)
  name: string;

  @JsonProperty('hex_color', String)
  hexColor: string;

  @JsonProperty('removable', Boolean, true)
  removable?: boolean = false;

  constructor(name: string, hexColor: string, id: string, removable = false) {
    this.name = name;
    this.hexColor = hexColor;
    this.id = id;
    this.removable = removable;
  }
}

@JsonObject('Monitors')
export class Monitors {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('active', Boolean)
  active: boolean;

  @JsonProperty('interval_check', Number)
  intervalCheck: number;
}

@JsonObject('JobMonitoringSettings')
export class JobMonitoringSettings {
  @JsonProperty('throttling', Number)
  throttling: number;

  @JsonProperty('monitors', [Monitors])
  monitors: Monitors[];
}

@JsonObject('AdvancedJobSettings')
export class AdvancedJobSettings {
  @JsonProperty('custom_labels', [CustomLabel])
  customLabels: CustomLabel[];

  @JsonProperty('monitoring_settings', JobMonitoringSettings)
  monitoringSettings?: JobMonitoringSettings | null;

  @JsonProperty('llm_settings', LLMSettings)
  llmSettings?: LLMSettings | null;

  @JsonProperty('schedulers_settings', SchedulersSettings, true)
  schedulersSettings?: SchedulersSettings | null;

  constructor(customLabels: CustomLabel[], monitoringSettings?: JobMonitoringSettings) {
    this.customLabels = customLabels;
    this.monitoringSettings = monitoringSettings;
  }
}
