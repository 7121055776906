import { MenuItem, MenuList, Stack, Typography } from '@mui/material';
import { Icon, EIcon } from '@arcanna/generic';

type TFilterMenuProps = {
  onEdit: () => void;
  onStatusChange: () => void;
  onDelete: () => void;
  statusLabel: string;
  editLabel: string;
  deleteLabel: string;
  statusIcon: EIcon;
};

function FilterMenu({ onEdit, onStatusChange, onDelete, statusLabel, editLabel, deleteLabel, statusIcon }: TFilterMenuProps) {
  const renderMenuItem = (onClick: () => void, label: string, iconName: EIcon) => (
    <MenuItem onClick={onClick} className="MuiMenuItem-root--small">
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
        <Typography fontSize="14px" lineHeight="24px">
          {label}
        </Typography>
        <Icon name={iconName} fontSize="small" />
      </Stack>
    </MenuItem>
  );

  return (
    <Stack direction="column" width="300px" maxWidth="100%">
      <MenuList>
        {renderMenuItem(onEdit, editLabel, EIcon.Edit16)}
        {renderMenuItem(onStatusChange, statusLabel, statusIcon)}
        {renderMenuItem(onDelete, deleteLabel, EIcon.Delete)}
      </MenuList>
    </Stack>
  );
}

export default FilterMenu;
