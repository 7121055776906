/* eslint-disable max-len */
function Delete() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M1.3335 2.7998H2.40016H10.9335"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.00039 2.79984V1.73317C4.00039 1.45027 4.11277 1.17896 4.31281 0.978923C4.51285 0.778884 4.78416 0.666504 5.06706 0.666504H7.20039C7.48329 0.666504 7.7546 0.778884 7.95464 0.978923C8.15468 1.17896 8.26706 1.45027 8.26706 1.73317V2.79984M9.86706 2.79984V10.2665C9.86706 10.5494 9.75468 10.8207 9.55464 11.0208C9.3546 11.2208 9.08329 11.3332 8.80039 11.3332H3.46706C3.18416 11.3332 2.91285 11.2208 2.71281 11.0208C2.51277 10.8207 2.40039 10.5494 2.40039 10.2665V2.79984H9.86706Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.06689 5.46631V8.66631" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.2002 5.46631V8.66631" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default Delete;
