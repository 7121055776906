import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { CommonRequest } from '../common/CommonRequest';
import { JobFeedbackColumnsUpdateDetails } from './JobFeedbackColumnsUpdateDetails';

@JsonObject('JobFeedbackColumnsUpdateRequest')
export class JobFeedbackColumnsUpdateRequest extends CommonRequest {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('job_id', Number)
  job_id?: number = undefined;

  @JsonProperty('columns_type', String)
  columns_type?: string = undefined;

  @JsonProperty('columns', [JobFeedbackColumnsUpdateDetails])
  columns?: JobFeedbackColumnsUpdateDetails[] = [];

  constructor(job_id: number, columns_type: string, columns: JobFeedbackColumnsUpdateDetails[]) {
    super();
    this.job_id = job_id;
    this.columns_type = columns_type;
    this.columns = columns;
  }
}
