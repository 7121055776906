import { EIcon, Icon } from '@arcanna/generic';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef, useState } from 'react';

type TPasswordTextFieldProps = TextFieldProps;

const PasswordTextField = forwardRef<HTMLDivElement, TPasswordTextFieldProps>((props, ref) => {
  const { palette } = useTheme();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <TextField
      ref={ref}
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginRight: '4px' }}>
            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
              <Icon htmlColor={palette.secondary[300]} name={showPassword ? EIcon.EyeSlashed : EIcon.Eye} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
});

export default PasswordTextField;
