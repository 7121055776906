import { TSelectedFilterType } from './../DPUnifiedContext/DPUnifiedContext.type';
import { TFunction } from 'react-i18next';

export const getButtonsConfig = (
  t: TFunction
): {
  key: TSelectedFilterType;
  label: string;
}[] => [
  {
    key: 'all',
    label: t('common:all')
  },
  {
    key: 'selected',
    label: t('common:selected')
  },
  {
    key: 'notSelected',
    label: t('common:notSelected')
  }
];
