import { createContext, useContext, useState } from 'react';

// eslint-disable-next-line
export const MetricsContext = createContext<MetricsContextType>(null as any);

export type MetricsStateType = {
  // eslint-disable-next-line
  buckets: Array<any>;
};

export type MetricsContextType = {
  state: MetricsStateType;
  // eslint-disable-next-line
  setBuckets: (buckets: Array<any>) => void;
};

// eslint-disable-next-line
export function MetricsProvider(props: any) {
  const [state, setState] = useState<MetricsStateType>({
    // eslint-disable-next-line
    buckets: new Array<any>()
  });

  // eslint-disable-next-line
  const setBuckets = (buckets: Array<any>) => {
    setState((current) => ({
      ...current,
      buckets
    }));
  };

  const value: MetricsContextType = {
    state,
    setBuckets
  };

  return <MetricsContext.Provider value={value}>{props.children}</MetricsContext.Provider>;
}

export function useMetricsContext(): MetricsContextType {
  return useContext(MetricsContext);
}
