/* eslint-disable max-len */
function Hand() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.59992 7.62967V9.48152M4.59992 7.62967V3.18523C4.59992 2.57158 5.10733 2.07411 5.73325 2.07411C6.35917 2.07411 6.86659 2.57158 6.86659 3.18523M4.59992 7.62967C4.59992 7.01602 4.09251 6.51856 3.46659 6.51856C2.84066 6.51856 2.33325 7.01602 2.33325 7.62967V9.11115C2.33325 12.1794 4.87031 14.6667 7.99992 14.6667C11.1295 14.6667 13.6666 12.1794 13.6666 9.11115V5.40745C13.6666 4.7938 13.1592 4.29634 12.5333 4.29634C11.9073 4.29634 11.3999 4.7938 11.3999 5.40745M6.86659 3.18523V7.2593M6.86659 3.18523V2.44449C6.86659 1.83084 7.374 1.33337 7.99992 1.33337C8.62584 1.33337 9.13325 1.83084 9.13325 2.44449V3.18523M9.13325 3.18523V7.2593M9.13325 3.18523C9.13325 2.57158 9.64066 2.07411 10.2666 2.07411C10.8925 2.07411 11.3999 2.57158 11.3999 3.18523V5.40745M11.3999 5.40745V7.2593"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Hand;
