import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('AuthStatusResponse')
export class AuthStatusResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('is_authenticated', Boolean)
  is_authenticated?: boolean = undefined;

  @JsonProperty('force_password_change', Boolean)
  force_password_change?: boolean = undefined;
}
