/* eslint-disable max-len */
function RadioCheckedIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#1B2236" stroke="#2A3247" />
      <g filter="url(#filter0_d_8612_70712)">
        <rect x="5" y="5" width="10" height="10" rx="5" fill="#10B981" />
      </g>
      <defs>
        <filter
          id="filter0_d_8612_70712"
          x="3"
          y="5"
          width="14"
          height="14"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0235294 0 0 0 0 0.105882 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8612_70712" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8612_70712" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default RadioCheckedIcon;
