import axios, { AxiosResponse } from 'axios';
import { JsonConvert } from 'json2typescript';
import moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CustomLabel } from '../../components/shared/models/job/AdvancedJobSettings';
import { JobAlertsTimeseriesIntervalRecord } from '../../components/shared/models/job/JobAlertsTimeseriesIntervalRecord';
import { JobAlertsTimeseriesRecord } from '../../components/shared/models/job/JobAlertsTimeseriesRecord';
import { JobAlertsTimeseriesRecordLabelSplit } from '../../components/shared/models/job/JobAlertsTimeseriesRecordLabelSplit';
import { JobAlertsTimeseriesRequest } from '../../components/shared/models/job/JobAlertsTimeseriesRequest';
import { JobAlertsTimeseriesResponse } from '../../components/shared/models/job/JobAlertsTimeseriesResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';

function fetchJobAlertsTimeseries(payload: JobAlertsTimeseriesRequest, jsonConvert: JsonConvert) {
  return axios
    .post<{ resource: JobAlertsTimeseriesResponse }>(config.api.jobAlertsTimeseries, payload)
    .then((responseData: AxiosResponse<{ resource: JobAlertsTimeseriesResponse }>) =>
      jsonConvert.deserializeObject(responseData.data.resource, JobAlertsTimeseriesResponse)
    );
}

export type JobAlertsTimeseriesGraphDataPoint = {
  date: string;
  dateEntryStart?: string;
  dateEntryEnd?: string;
  train: boolean;
  totalCount?: number;
} & object;

export type JobOverviewAlertsTimeseries = {
  graphDataSource: JobAlertsTimeseriesGraphDataPoint[];
  retrainTimestamps: string[];
  labels: string[];
  interval: JobAlertsTimeseriesIntervalRecord;
};

function subsetOf(subset: string[], array: string[]) {
  for (const element of subset) {
    if (array.indexOf(element) == -1) {
      return false;
    }
  }
  return true;
}

function generateCountZeroForLabels(
  labels: string[],
  dataPoint: JobAlertsTimeseriesGraphDataPoint,
  orderedLabels: CustomLabel[]
) {
  let result: JobAlertsTimeseriesGraphDataPoint = { ...dataPoint, totalCount: 0 };
  const orderedLabelsId = orderedLabels.map((l) => l.id);
  if (subsetOf(orderedLabelsId, labels)) {
    orderedLabelsId.forEach((labelId: string) => {
      result = {
        ...result,
        [labelId]: 0
      };
    });
  }
  return result;
}

function generateCountForLabels(
  labels: string[],
  split: JobAlertsTimeseriesRecordLabelSplit[],
  dataPoint: JobAlertsTimeseriesGraphDataPoint,
  orderedLabels: CustomLabel[]
) {
  let result: JobAlertsTimeseriesGraphDataPoint = { ...dataPoint };
  let totalCount = 0;
  const orderedLabelsId = orderedLabels.map((l) => l.id);
  if (subsetOf(orderedLabelsId, labels)) {
    orderedLabelsId.forEach((labelId: string) => {
      const labelInfo = split.find((l) => l.name === labelId);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      totalCount += labelInfo ? labelInfo.count : 0;
      result = {
        ...result,
        [labelId]: labelInfo ? labelInfo.count : 0
      };
    });
  }
  result = { ...result, totalCount: totalCount };
  return result;
}

export function useJobAlertsTimeseries(payload: JobAlertsTimeseriesRequest, orderedLabels: CustomLabel[]) {
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useQuery<JobAlertsTimeseriesResponse, Error, JobOverviewAlertsTimeseries>(
    [config.api.jobAlertsTimeseries, payload],
    () => fetchJobAlertsTimeseries(payload, jsonConvert),
    {
      select: (data: JobAlertsTimeseriesResponse) => {
        let graphDataSource: JobAlertsTimeseriesGraphDataPoint[] = [];
        data.timeseries.forEach((timeseries: JobAlertsTimeseriesRecord) => {
          // Add one with value zero for actual date
          const dataPoint1 = generateCountZeroForLabels(
            data.labels,
            {
              date: timeseries.date,
              train: false
            },
            orderedLabels
          );
          graphDataSource.push(dataPoint1);

          // Add one with the actual value with the date in the middle
          const dataPoint2 = generateCountForLabels(
            data.labels,
            timeseries.labels.split,
            {
              date: timeseries.date_middle,
              dateEntryStart: timeseries.date,
              dateEntryEnd: timeseries.date_end,
              train: false
            },
            orderedLabels
          );
          graphDataSource.push(dataPoint2);
        });
        // Add value zero for last date_end
        if (data.timeseries.length > 0) {
          const lastEntry = data.timeseries[data.timeseries.length - 1];
          const dataPoint3 = generateCountZeroForLabels(
            data.labels,
            {
              date: lastEntry.date_end,
              train: false
            },
            orderedLabels
          );
          graphDataSource.push(dataPoint3);
        }
        graphDataSource = graphDataSource
          .concat(
            data.retrain_timestamps.map((timestamp) => {
              return generateCountZeroForLabels(
                data.labels,
                {
                  date: timestamp,
                  train: false
                },
                orderedLabels
              );
            })
          )
          .sort((a, b) => moment(a.date).diff(moment(b.date)));
        return {
          graphDataSource: graphDataSource,
          retrainTimestamps: data.retrain_timestamps,
          labels: data.labels,
          interval: data.interval
        };
      }
    }
  );
}
