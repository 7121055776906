/* eslint-disable max-len */
function Folder() {
  return (
    <svg width="84" height="63" viewBox="0 0 84 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M77.5 3V15H6.5V12.3684C6.5 10.1593 8.29086 8.36842 10.5 8.36842H40.603L44.2661 2.8013C45.006 1.67692 46.2617 1 47.6077 1H75.5C76.6046 1 77.5 1.89543 77.5 3Z"
        fill="url(#paint0_linear_14300_62427)"
        stroke="#00C5A3"
      />
      <g filter="url(#filter0_b_14300_62427)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.2076 15H4.50096C2.03244 15 0.153128 17.214 0.554087 19.6497L7.13867 59.6497C7.45677 61.5821 9.12717 63 11.0856 63H42.2076H45.1183H72.9134C74.8718 63 76.5422 61.5821 76.8603 59.6497L83.4449 19.6497C83.8458 17.214 81.9665 15 79.498 15H45.1183H42.2076Z"
          fill="url(#paint1_linear_14300_62427)"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_14300_62427"
          x="-7.5"
          y="7"
          width="98.999"
          height="64"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14300_62427" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_14300_62427" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_14300_62427"
          x1="80.0364"
          y1="0.840288"
          x2="77.2196"
          y2="15.1674"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22293E" />
          <stop offset="1" stopColor="#22293E" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14300_62427"
          x1="100.183"
          y1="68.5"
          x2="12.9131"
          y2="-13.7479"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C5A3" stopOpacity="0" />
          <stop offset="1" stopColor="#00C5A3" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Folder;
