import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { IntegrationCreateParameter } from './IntegrationCreateParameter';

@JsonObject('IntegrationCreateRequest')
export class IntegrationCreateRequest extends CommonRequest {
  @JsonProperty('id', Number)
  id?: number = undefined;

  @JsonProperty('subcategory_id', Number)
  subcategory_id?: number = undefined;

  @JsonProperty('title', String)
  title?: string = undefined;

  @JsonProperty('parameters', Array<IntegrationCreateParameter>())
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Integr...
  parameters: Array<IntegrationCreateParameter> = undefined;

  constructor(subcategory_id: number, title: string, parameters: Array<IntegrationCreateParameter>) {
    super();
    this.subcategory_id = subcategory_id;
    this.title = title;
    this.parameters = parameters;
  }
}
