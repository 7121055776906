import { Spinner } from '@arcanna/generic';
import { Stack, StackProps, Typography } from '@mui/material';
import { memo, ReactNode, useMemo } from 'react';

type TJobPageLayoutProps = {
  breadcrumbContent?: ReactNode;
  title?: string;
  children: ReactNode;
  isLoading?: boolean;
  actions?: ReactNode;
  notificationsContent?: ReactNode;
} & StackProps;

function PageLayout({
  isLoading,
  actions,
  title,
  breadcrumbContent,
  children,
  notificationsContent,
  ...stackProps
}: TJobPageLayoutProps) {
  const showAnyLayoutComponent = useMemo(
    () => breadcrumbContent || title || actions || notificationsContent,
    [actions, breadcrumbContent, notificationsContent, title]
  );

  return (
    <Stack gap={2}>
      {showAnyLayoutComponent && (
        // Add .notificationsWrapper className to notifications you put in the notificationsContent
        // if they are wrapped in a Stack (not solo notifications)
        <Stack id="pageHeaderWrapper" gap={2} sx={{ '.notificationsWrapper:empty': { marginBottom: '-16px' } }}>
          {(breadcrumbContent || actions) && (
            <Stack id="breadCrumbsAndActionsWrapper" flexDirection="row" justifyContent="space-between" alignItems="center">
              {breadcrumbContent}
              {actions}
            </Stack>
          )}
          {title && (
            <Typography id="pageTitle" variant="h3">
              {title}
            </Typography>
          )}
          {notificationsContent}
        </Stack>
      )}
      {(isLoading || children) && (
        <Stack id="pageContent" position="relative" minHeight="50vh" {...stackProps}>
          {isLoading ? <Spinner isOverlay /> : children}
        </Stack>
      )}
    </Stack>
  );
}

export default memo(PageLayout);
