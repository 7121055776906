import { Stack, Typography, useTheme, StackProps, Box } from '@mui/material';
import { Icon, EIcon } from '@arcanna/generic';
import StyledEmptyState from './StyledEmptyState.styles';

type TEmptyStateProps = StackProps & {
  title: string;
  subtitle: string;
  suggestions?: string[];
  iconType?: EIcon.Folder | EIcon.FolderPlus;
  isCard?: boolean;
};

function EmptyState({ title, subtitle, suggestions, iconType = EIcon.Folder, ...other }: TEmptyStateProps) {
  const { palette } = useTheme();

  return (
    <StyledEmptyState mt={5} mb={3} alignItems="center" {...other}>
      <Icon name={iconType} fontSize="inherit" />
      <Stack direction="column">
        <Typography variant="body1">{title}</Typography>
        <Typography variant="body2" color={palette.secondary[300]}>
          {subtitle}
        </Typography>
        {suggestions?.map((suggestion, index) => (
          <Stack direction="row" ml={1} key={index} alignItems="center">
            <Box className="suggestionDot" component="span" />
            <Typography key={index} variant="body2" color={palette.secondary[300]} ml={1}>
              {suggestion}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </StyledEmptyState>
  );
}

export default EmptyState;
