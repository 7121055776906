import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../../common/CommonRequest';

@JsonObject('IntegrationsRequest')
export class IntegrationsRequest extends CommonRequest {
  @JsonProperty('event_count', Number)
  event_count?: number = undefined;

  @JsonProperty('include_internal', Boolean)
  include_internal?: boolean = undefined;

  constructor(eventCount: number, include_internal?: boolean) {
    super();
    this.event_count = eventCount;
    this.include_internal = include_internal;
  }
}
