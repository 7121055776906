import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { Metric } from '../Visualisation/Metric';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import styles from './QueryVisualisation.module.css';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useVisualizationsContext } from './Visualizations.context';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';
import { humanReadableValue } from './QueryMetric';

export function QueryMetricDerivative(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // eslint-disable-next-line
  const [buckets, setBuckets] = useState(Array<any>());
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    let entry: any = {};
    for (const key in aggregations) {
      if (aggregations[key].buckets != null) {
        const lastBucketIndex = aggregations[key].buckets.length - 1;
        const bucket = aggregations[key].buckets[lastBucketIndex];
        for (const bucketKey in bucket) {
          if (bucketKey.toString().indexOf('derivative') >= 0) {
            entry = {
              key: props.name,
              doc_count: humanReadableValue(bucket[bucketKey].value, props)
            };
          }
        }
      }
    }
    setBuckets([entry]);
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  // Render differently base on orientation
  if (props.style.orientation === 'horizontal') {
    return (
      <>
        <Spin tip="Loading..." spinning={loading}>
          <div>
            <div className={styles.horizontalMetricContainer}>
              {buckets.map((bucket) => (
                <div key={bucket.key}>
                  <Metric
                    key={`${bucket.key}metric`}
                    value={bucket.doc_count}
                    label={bucket.key}
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    tooltip={visualizationRecord.tooltip}
                    style={bucket.style}
                  />
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </>
    );
  }

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div>
          <div>
            {buckets.map((bucket) => (
              <Metric
                key={bucket.key}
                value={bucket.doc_count}
                label={bucket.key}
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                tooltip={visualizationRecord.tooltip}
                style={bucket.style}
              />
            ))}
          </div>
        </div>
      </Spin>
    </>
  );
}
