import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BucketsRequiringAttention')
export class BucketsRequiringAttention {
  @JsonProperty('new', Number)
  new: number;

  @JsonProperty('undecided', Number)
  undecided: number;
}
