import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackStatisticsLabelsConsensusStatsRecord')
export class FeedbackStatisticsLabelsConsensusStatsRecord {
  @JsonProperty('id', String)
  id?: string;

  @JsonProperty('name', String)
  name?: string;

  @JsonProperty('hex_color', String)
  hex_color?: string;

  @JsonProperty('count', Number)
  count?: number;

  @JsonProperty('percent', Number)
  percent?: number;
}
