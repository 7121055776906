import { useState, useMemo, useEffect } from 'react';

import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { PlatformInfoRecord } from '../../models/platform/PlatformInfoRecord';
import { getJsonConvert } from '../../utilities/json-convert';
import { PlatformSummaryResponse } from '../../models/platform/PlatformSummaryResponse';

export function PlatformData() {
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PlatformInf...
  const defaultPlatformData: PlatformInfoRecord = null;
  const [platformData, setPlatformData] = useState(defaultPlatformData);
  const [platformDataLoaded, setPlatformDataLoaded] = useState(false);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/platform/summary'),
    initialResponseData: null,
    load: false
  });

  useEffect(() => {
    if (!platformDataLoaded) {
      setPlatformDataLoaded(true);
      post({})
        .then((response) => {
          const platformSummaryResponse = jsonConvert.deserializeObject(response.data.resource, PlatformSummaryResponse);
          // @ts-expect-error TS(2345): Argument of type 'PlatformInfoRecord | undefined' ...
          setPlatformData(platformSummaryResponse.platform);
        })
        .catch(() => {
          setPlatformData({});
        });
    }
  }, [jsonConvert, post, platformDataLoaded]);

  return { platformData };
}
