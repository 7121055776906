import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { FieldInsightsRequest } from '../../components/shared/models/field-insights/FieldInsightsRequest';
import { FieldInsightsResponse } from '../../components/shared/models/field-insights/FieldInsightsResponse';

type UseFieldInsights = {
  jobId: number;
  feature: string;
};

export function useFieldInsights({ jobId, feature }: UseFieldInsights) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const payload = new FieldInsightsRequest(jobId, feature);

  return useQuery<FieldInsightsResponse, Error>([config.api.fieldInsights, payload], () =>
    axios
      .post(config.api.fieldInsights, payload)
      .then((responseData: AxiosResponse<{ resource: FieldInsightsResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, FieldInsightsResponse)
      )
  );
}
