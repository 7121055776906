import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackStatisticsFilterRecord')
export class FeedbackStatisticsFilterRecord {
  @JsonProperty('field', String)
  field?: string;

  @JsonProperty('operator', String)
  operator?: string;

  @JsonProperty('caption', [String])
  value?: string[] | [];
}
