import { Table } from '@arcanna/generic';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TEventHistoryRow } from './EventHistory.types';
import { getColumns } from './EventHistory.utils';

type TEventHistoryProps = {
  rows: TEventHistoryRow[];
  isLoading: boolean;
};

function EventHistory({ rows, isLoading }: TEventHistoryProps) {
  const { t } = useTranslation(['common']);
  const { palette } = useTheme();

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 10,
    pageIndex: 0
  });

  const columns = useMemo(
    () =>
      getColumns({
        palette,
        t
      }),
    [palette, t]
  );

  const tableInstance = useReactTable<TEventHistoryRow>({
    columns,
    data: rows,
    enableSorting: true,
    enableHiding: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    enableColumnPinning: false,
    enableColumnResizing: false,
    enableGlobalFilter: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false,
    state: {
      pagination
    },
    onPaginationChange: setPagination
  });

  return (
    <Stack gap={1}>
      <Typography variant="subtitle1" color={palette.secondary[300]}>
        History
      </Typography>
      <Table.component<TEventHistoryRow>
        tableInstance={tableInstance}
        isLoading={isLoading}
        // TODO: FIX THESE MESSAGES
        noResultsMessageTitle={t('codeBlock:editTestInputDrawer.tableTab.emptyState.title')}
        noResultsMessageSubtitle={t('codeBlock:editTestInputDrawer.tableTab.emptyState.subtitle')}
        isTableResizable={false}
        defaultTableSize={Table.TABLE_SIZES.small}
      />
      <Table.TablePagination<TEventHistoryRow> tableInstance={tableInstance} />
    </Stack>
  );
}

export default EventHistory;
