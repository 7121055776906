import { withTransaction } from '@elastic/apm-rum-react';
import JobOverview from './JobOverview';
import { JobOverviewProvider } from './JobOverview.context';

function JobOverviewWrapper() {
  return (
    <JobOverviewProvider>
      <JobOverview />
    </JobOverviewProvider>
  );
}

export default withTransaction('EventExplorerWrapper', 'component')(JobOverviewWrapper);
