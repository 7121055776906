import { JsonObject, JsonProperty } from 'json2typescript';

import { JobAlertsTimeseriesRecordLabel } from './JobAlertsTimeseriesRecordLabel';

@JsonObject('JobAlertsTimeseriesRecord')
export class JobAlertsTimeseriesRecord {
  @JsonProperty('date', String)
  date: string;

  @JsonProperty('date_middle', String)
  date_middle: string;

  @JsonProperty('date_end', String)
  date_end: string;

  @JsonProperty('labels', JobAlertsTimeseriesRecordLabel)
  labels: JobAlertsTimeseriesRecordLabel;
}
