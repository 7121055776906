import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { JobAllRetrainHistoryResponse } from '../../components/shared/models/retrain-history/JobAllRetrainHistoryResponse';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';

export function useJobAllRetrainHistory(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobAllRetrainHistoryResponse, Error>(
    [config.api.jobAllRetrainHistory.replace(':jobId', jobId.toString())],
    () =>
      axios
        .post<{
          resource: JobAllRetrainHistoryResponse;
        }>(config.api.jobAllRetrainHistory.replace(':jobId', jobId.toString()), new CommonRequest())
        .then((responseData: AxiosResponse<{ resource: JobAllRetrainHistoryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobAllRetrainHistoryResponse)
        ),
    {
      enabled: Boolean(jobId)
    }
  );
}
