import React, { ReactElement } from 'react';
import { withTransaction } from '@elastic/apm-rum-react';
import { config } from '../../../config';

const OrangeLogin = React.lazy(() => import('./orange/OrangeLogin'));
const SiscaleLogin = React.lazy(() => import('./siscale/SiscaleLogin'));

function Login(): ReactElement {
  if (config.branding.folder === 'orange') {
    return <OrangeLogin />;
  }

  return <SiscaleLogin />;
}

export default withTransaction('Login', 'component')(Login);
