import { enqueueSnackbar } from 'notistack';
import { TActionButtonProps } from '@arcanna/components';

export function showSuccessNotification(
  message: string,
  subtitle: string,
  description?: string,
  actionButton?: TActionButtonProps
) {
  enqueueSnackbar('', {
    variant: 'success',
    title: message,
    subtitle: subtitle,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    description: description,
    // @ts-expect-error TS(2322): Type 'TActionButtonProps | undefined' is not assig...
    actionButton: actionButton
  });
}

export function showInfoNotification(message: string, subtitle: string, description?: string, actionButton?: TActionButtonProps) {
  enqueueSnackbar('', {
    variant: 'info',
    title: message,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    description: description,
    subtitle: subtitle,
    // @ts-expect-error TS(2322): Type 'TActionButtonProps | undefined' is not assig...
    actionButton: actionButton
  });
}

export function showErrorNotification(
  message: string,
  subtitle: string,
  description?: string,
  actionButton?: TActionButtonProps
) {
  enqueueSnackbar('', {
    variant: 'error',
    title: message,
    subtitle: subtitle,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    description: description,
    // @ts-expect-error TS(2322): Type 'TActionButtonProps | undefined' is not assig...
    actionButton: actionButton
  });
}

export function showWarningNotification(
  message: string,
  subtitle: string,
  description?: string,
  actionButton?: TActionButtonProps
) {
  enqueueSnackbar('', {
    variant: 'warning',
    title: message,
    subtitle: subtitle,
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    description: description,
    // @ts-expect-error TS(2322): Type 'TActionButtonProps | undefined' is not assig...
    actionButton: actionButton
  });
}
