/* eslint-disable max-len */

function RadioDisabledIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#1B2236" stroke="#20273B" />
    </svg>
  );
}

export default RadioDisabledIcon;
