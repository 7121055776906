/* eslint-disable max-len */
function PostDecisionModal() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#A78BFA" fillOpacity="0.1" />
      <path
        d="M22.1667 24.6665H22C20.5999 24.6665 19.8998 24.6665 19.365 24.394C18.8946 24.1543 18.5122 23.7719 18.2725 23.3015C18 22.7667 18 22.0666 18 20.6665V15.3332C18 13.933 18 13.233 18.2725 12.6982C18.5122 12.2278 18.8946 11.8453 19.365 11.6057C19.8998 11.3332 20.5999 11.3332 22 11.3332H22.1667M22.1667 24.6665C22.1667 25.587 22.9129 26.3332 23.8333 26.3332C24.7538 26.3332 25.5 25.587 25.5 24.6665C25.5 23.746 24.7538 22.9998 23.8333 22.9998C22.9129 22.9998 22.1667 23.746 22.1667 24.6665ZM22.1667 11.3332C22.1667 12.2536 22.9129 12.9998 23.8333 12.9998C24.7538 12.9998 25.5 12.2536 25.5 11.3332C25.5 10.4127 24.7538 9.6665 23.8333 9.6665C22.9129 9.6665 22.1667 10.4127 22.1667 11.3332ZM13.8333 17.9998L22.1667 17.9998M13.8333 17.9998C13.8333 18.9203 13.0871 19.6665 12.1667 19.6665C11.2462 19.6665 10.5 18.9203 10.5 17.9998C10.5 17.0794 11.2462 16.3332 12.1667 16.3332C13.0871 16.3332 13.8333 17.0794 13.8333 17.9998ZM22.1667 17.9998C22.1667 18.9203 22.9129 19.6665 23.8333 19.6665C24.7538 19.6665 25.5 18.9203 25.5 17.9998C25.5 17.0794 24.7538 16.3332 23.8333 16.3332C22.9129 16.3332 22.1667 17.0794 22.1667 17.9998Z"
        stroke="#A78BFA"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PostDecisionModal;
