import axios, { AxiosResponse } from 'axios';
import { JsonConvert } from 'json2typescript';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import { config } from 'src/config';
import { FeedbackFiltersFieldsResponse } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsResponse';
import { FeedbackFiltersFieldsRequest } from 'src/components/shared/models/feedback/FeedbackFiltersFieldsRequest';

const URL = config.api.jobFeedbackFilterFields;

const fetchJobFeedbackFilterFields = async (jobId: number, jsonConvert: JsonConvert) =>
  axios
    .post<{ resource: FeedbackFiltersFieldsResponse }>(URL, new FeedbackFiltersFieldsRequest(jobId))
    .then((responseData: AxiosResponse<{ resource: FeedbackFiltersFieldsResponse }>) =>
      jsonConvert.deserializeObject(responseData.data.resource, FeedbackFiltersFieldsResponse)
    );

const useJobFeedbackFilterFields = ({ jobId }: { jobId: number }) => {
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useQuery<FeedbackFiltersFieldsResponse, Error>(
    [URL + `${jobId}`],
    () => fetchJobFeedbackFilterFields(jobId, jsonConvert),
    {
      staleTime: Infinity
    }
  );
};

export default useJobFeedbackFilterFields;
