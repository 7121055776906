import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UnifiedDecisionPointRecord')
export class UnifiedDecisionPointRecord {
  @JsonProperty('is_decision_point', Boolean, true)
  isDecisionPoint: boolean;

  @JsonProperty('sample_value', String, true)
  sampleValue: string | boolean | number | null = null;

  @JsonProperty('field_type', String, true)
  fieldType: string;
}

@JsonObject('UnifiedDecisionPointsListResponseItem')
export class UnifiedDecisionPointsListResponseItem {
  @JsonProperty('field_name', String)
  fieldName: string;
}

@JsonObject('UnifiedDecisionPointsListResponse')
export class UnifiedDecisionPointsListResponse {
  @JsonProperty('features', [UnifiedDecisionPointsListResponseItem])
  features: UnifiedDecisionPointsListResponseItem[];
}

export type TUnifiedDecisionPointRecordUnparsed = {
  is_decision_point?: boolean;
  sample_value?: string | boolean | number | null;
  field_type?: string;
  is_custom_field?: boolean;
};

export type TUnifiedDecisionPointsResponseUnparsed = {
  field_name: string;
} & Record<number, TUnifiedDecisionPointRecordUnparsed>;

export type TUnifiedDecisionPointRecord = {
  isDecisionPoint?: boolean;
  isCustomField?: boolean;
  sampleValue?: string | boolean | number | null;
  fieldType?: string;
};

export type TUnifiedDecisionPointsResponse = {
  fieldName: string;
} & Record<number, TUnifiedDecisionPointRecord>;
