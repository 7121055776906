import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiAutocompleteStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        color: palette.secondary[100],

        '& .MuiSvgIcon-root': {
          color: palette.secondary[300]
        },

        '& .MuiAutocomplete-inputRoot': {
          height: '36px'
        },

        '& .MuiInputLabel-root': {
          marginBottom: 0
        },

        '& .MuiFormControl-root': {
          gap: '4px'
        },

        '&.MuiAutocomplete-without-label': {
          '& .MuiOutlinedInput-notchedOutline': {
            top: 0
          }
        }
      },
      listbox: {
        marginTop: '4px',
        borderRadius: '4px',
        border: `1px solid ${palette.info[600]}`,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '0',

        '& .MuiAutocomplete-option': {
          padding: '8px 12px',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px',
          color: palette.secondary[300],
          overflow: 'visible',

          '&:hover': {
            color: palette.secondary[100]
          },

          '&:not(:last-child)': {
            borderBottom: `1px solid ${palette.secondary[700]}`
          }
        },
        '& .MuiAutocomplete-groupLabel': {
          color: palette.secondary[100],
          fontWeight: 500,
          fontSize: '14px',
          borderBottom: `1px solid ${palette.secondary[800]}`,
          height: '48px',
          top: '0px'
        }
      },
      noOptions: {
        borderRadius: '4px',
        border: `1px solid ${palette.info[600]}`,
        padding: '8px 12px',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '24px',
        color: palette.secondary[600],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }
});
