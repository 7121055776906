import { JsonObject, JsonProperty } from 'json2typescript';
import { IntegrationJobParametersRecord } from '../job/IntegrationJobParametersRecord';
import { IntegrationJobParameterRecord } from '../job/IntegrationJobParameterRecord';

@JsonObject('IntegrationSubcategoryRecord')
export class IntegrationSubcategoryRecord {
  @JsonProperty('id', Number)
  id: number;

  @JsonProperty('caption', String)
  caption?: string;

  @JsonProperty('categories_compatible', Number)
  categories_compatible?: number;

  @JsonProperty('required_parameters', [IntegrationJobParameterRecord])
  parameters: IntegrationJobParameterRecord[] = [];

  @JsonProperty('job_parameters', IntegrationJobParametersRecord)
  job_parameters: IntegrationJobParametersRecord | undefined;

  @JsonProperty('disabled', Boolean)
  disabled?: boolean;

  @JsonProperty('disabled_reason', String)
  disabled_reason?: string;

  @JsonProperty('roles', [String])
  roles?: string[];

  @JsonProperty('base_64_logo', String)
  base_64_logo?: string;

  @JsonProperty('info', String)
  info?: string;

  @JsonProperty('roles_summary', String)
  rolesSummary?: string;

  @JsonProperty('roles_details', Object)
  rolesDetails?: Record<string, string>;

  @JsonProperty('validate', Boolean)
  validate?: boolean;

  @JsonProperty('creatable', Boolean)
  creatable?: boolean;
}
