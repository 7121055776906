import { JsonObject, JsonProperty } from 'json2typescript';

import { EventExplorerStructureRecord } from './EventExplorerStructureRecord';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('EventExplorerStructureResponse')
export class EventExplorerStructureResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse | undefined;

  @JsonProperty('structure', EventExplorerStructureRecord)
  structure?: EventExplorerStructureRecord | undefined;
}
