import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('CodeBlockDeleteRequest')
export class CodeBlockDeleteRequest extends RequestCommon {
  @JsonProperty('custom_code_id', String)
  codeBlockId: string;

  @JsonProperty('job_id', Number)
  jobId: number;
}

type TConstructorParams = {
  codeBlockId: CodeBlockDeleteRequest['codeBlockId'];
  jobId: CodeBlockDeleteRequest['jobId'];
};

export function constructCodeBlockDeleteRequest({ codeBlockId, jobId }: TConstructorParams) {
  const instance = new CodeBlockDeleteRequest();
  instance.codeBlockId = codeBlockId;
  instance.jobId = jobId;
  return instance;
}
