import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
@JsonObject('EventExplorerStaticRuleFilterRecord')
export class EventExplorerStaticRuleFilterRecord {
  @JsonProperty('field', String)
  field: string | undefined;

  @JsonProperty('operator', String)
  operator: string | undefined;

  @JsonProperty('value', String)
  value: string | string[] | undefined;

  @JsonProperty('value_type', String)
  value_type?: string;

  constructor(field: string, operator: string, value: string | string[], value_type?: string) {
    this.field = field;
    this.operator = operator;
    this.value = value;
    this.value_type = value_type;
  }
}

@JsonObject('EventExplorerFiltersValuesRequest')
export class EventExplorerFiltersValuesRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number | undefined;

  @JsonProperty('field_name', String)
  field_name?: string | undefined;

  @JsonProperty('start_date', String)
  start_date?: string | undefined;

  @JsonProperty('end_date', String)
  end_date?: string | undefined;

  @JsonProperty('filters', [EventExplorerStaticRuleFilterRecord])
  filters?: EventExplorerStaticRuleFilterRecord[] = [];

  constructor(
    job_id: number,
    field_name: string,
    start_date: string,
    end_date: string,
    filters: EventExplorerStaticRuleFilterRecord[]
  ) {
    super();
    this.job_id = job_id;
    this.field_name = field_name;
    this.start_date = start_date;
    this.end_date = end_date;
    this.filters = filters;
  }
}
