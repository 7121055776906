import { JsonObject, JsonProperty } from 'json2typescript';
import { Component } from './Component';

@JsonObject('ActionCategory')
export class ActionCategory {
  @JsonProperty('name', String)
  name: string;

  @JsonProperty('component', String)
  component: Component;

  @JsonProperty('aggregation_description', String)
  aggregation_description: string;
}
