/* eslint-disable max-len */
function TicketingModal() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#F59E0B" fillOpacity="0.1" />
      <path
        d="M16.3332 14.6668V13.8335M16.3332 18.4168V17.5835M16.3332 22.1668V21.3335M12.3332 11.3335H23.6665C24.5999 11.3335 25.0666 11.3335 25.4232 11.5152C25.7368 11.6749 25.9917 11.9299 26.1515 12.2435C26.3332 12.6 26.3332 13.0667 26.3332 14.0002V15.0835C24.7223 15.0835 23.4165 16.3893 23.4165 18.0002C23.4165 19.611 24.7223 20.9168 26.3332 20.9168V22.0002C26.3332 22.9336 26.3332 23.4003 26.1515 23.7568C25.9917 24.0704 25.7368 24.3254 25.4232 24.4852C25.0666 24.6668 24.5999 24.6668 23.6665 24.6668H12.3332C11.3998 24.6668 10.933 24.6668 10.5765 24.4852C10.2629 24.3254 10.0079 24.0704 9.84816 23.7568C9.6665 23.4003 9.6665 22.9336 9.6665 22.0002V20.9168C11.2773 20.9168 12.5832 19.611 12.5832 18.0002C12.5832 16.3893 11.2773 15.0835 9.6665 15.0835V14.0002C9.6665 13.0667 9.6665 12.6 9.84816 12.2435C10.0079 11.9299 10.2629 11.6749 10.5765 11.5152C10.933 11.3335 11.3997 11.3335 12.3332 11.3335Z"
        stroke="#F59E0B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default TicketingModal;
