import { JobSummaryRecord } from 'src/components/shared/models/job/JobSummaryRecord';
import { JobInvalidTag } from '../JobState/components/JobInvalidTag';

type JobInvalidStateProps = {
  job: JobSummaryRecord;
};

function JobInvalidState({ job }: JobInvalidStateProps) {
  return job.status && job.status.invalid ? (
    <JobInvalidTag message={job.status.invalid_reason} invalidTimestamp={job.status.invalid_timestamp} />
  ) : (
    <></>
  );
}

export default JobInvalidState;
