import * as React from 'react';
import { NavLink, matchPath } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../../config';
import style from './JobNavigation.module.css';

type JobExplorerNavItemProps = {
  jobId: string;
};
export default function JobExplorerNavItem({ jobId }: JobExplorerNavItemProps) {
  const { t } = useTranslation(['job']);

  // eslint-disable-next-line
  const isExplorerItemActive = React.useCallback((_: any, location: any) => {
    if (
      matchPath(location.pathname, { path: config.routes.eventExplorer }) ||
      matchPath(location.pathname, {
        path: config.routes.eventExplorerColumnsSelection
      })
    ) {
      return true;
    }
    return false;
  }, []);

  return (
    <NavLink
      to={config.routes.eventExplorer.replace(':id', jobId)}
      className={cx([style.navLink, 'callout-default'])}
      activeClassName={cx([style.activeNavLink, 'callout-default'])}
      isActive={isExplorerItemActive}
      data-test-id={'menu-expanded-explorer'}
    >
      {t('job:navigation.explorer')}
    </NavLink>
  );
}
