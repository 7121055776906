import { PopoverProps, Stack, useTheme } from '@mui/material';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Popover } from '@mui/material';
import { ReactNode } from 'react';
import { Icon, EIcon } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';

type TConfirmPopoverProps = {
  text: ReactNode;
  anchor: Element | null;
  onConfirm: () => void;
  onClose: () => void;
} & Omit<PopoverProps, 'open' | 'slotProps'>;

function ConfirmPopover({ text, anchor, onConfirm, onClose, ...otherProps }: TConfirmPopoverProps) {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const isOpen = Boolean(anchor);
  const id = isOpen ? 'confirm-popover' : undefined;

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      slotProps={{ paper: { sx: { background: theme.palette.secondary[800], marginTop: 1 } } }}
      {...otherProps}
    >
      <Stack direction="column" gap={1} sx={{ maxWidth: '360px', padding: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Icon name={EIcon.Warning} />
          <Typography variant="body2" fontWeight={500}>
            {t('warning')}
          </Typography>
        </Stack>
        <Typography component="div" variant="subtitle1">
          {text}
        </Typography>
        <Stack direction="row" width="100%" alignItems="center" gap={2} justifyContent="flex-end">
          <Button size="small" variant="tertiary" color="secondary" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {t('confirm')}
          </Button>
        </Stack>
      </Stack>
    </Popover>
  );
}

export default ConfirmPopover;
