/* eslint-disable max-len */
function File04() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M9.33341 1.51289V4.26659C9.33341 4.63995 9.33341 4.82664 9.40608 4.96925C9.46999 5.09469 9.57198 5.19667 9.69742 5.26059C9.84003 5.33325 10.0267 5.33325 10.4001 5.33325H13.1538M13.3334 6.65874V11.4666C13.3334 12.5867 13.3334 13.1467 13.1154 13.5746C12.9237 13.9509 12.6177 14.2569 12.2414 14.4486C11.8136 14.6666 11.2535 14.6666 10.1334 14.6666H5.86675C4.74664 14.6666 4.18659 14.6666 3.75877 14.4486C3.38244 14.2569 3.07648 13.9509 2.88473 13.5746C2.66675 13.1467 2.66675 12.5867 2.66675 11.4666V4.53325C2.66675 3.41315 2.66675 2.85309 2.88473 2.42527C3.07648 2.04895 3.38244 1.74299 3.75877 1.55124C4.18659 1.33325 4.74664 1.33325 5.86675 1.33325H8.00793C8.49711 1.33325 8.7417 1.33325 8.97188 1.38851C9.17595 1.43751 9.37104 1.51831 9.54998 1.62797C9.75182 1.75166 9.92477 1.92461 10.2707 2.27051L12.3962 4.39599C12.7421 4.7419 12.915 4.91485 13.0387 5.11668C13.1484 5.29563 13.2292 5.49072 13.2782 5.69479C13.3334 5.92496 13.3334 6.16955 13.3334 6.65874Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default File04;
