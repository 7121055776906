import styles from './JobCollectingDataSpinner.module.css';
import { CSSProperties } from 'react';

type JobCollectingDataSpinnerProps = {
  style?: CSSProperties;
  centerStyle?: CSSProperties;
};
export function JobCollectingDataSpinner({ style, centerStyle }: JobCollectingDataSpinnerProps) {
  return (
    <div style={style} className={styles.spinnerBackground}>
      <div style={centerStyle} className={styles.spinnerCenter}></div>
    </div>
  );
}
