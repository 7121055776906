import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobFeatureSelectionSummaryRequest')
export class JobFeatureSelectionSummaryRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number = undefined;

  @JsonProperty('skip_inactive', Boolean)
  skip_inactive?: boolean = false;

  constructor(job_id: number, skip_inactive?: boolean) {
    super();
    this.job_id = job_id;
    if (skip_inactive != null) {
      this.skip_inactive = skip_inactive;
    }
  }
}
