import { StateChip } from '@arcanna/generic';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function JobEnabledTag() {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return <StateChip color={theme.palette.success[400]} text={t('job:jobState.enabled')} />;
}
