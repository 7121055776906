import { TTablePreferences } from './useSaveTablePreferences';

const TABLE_PREFERENCES_LS_KEY = 'tablePreferences';

export const getTablePreferencesFromLS = (tableKey: string): TTablePreferences | undefined => {
  const localStorageInfo = localStorage.getItem(TABLE_PREFERENCES_LS_KEY);

  if (!localStorageInfo) {
    return undefined;
  }

  return JSON.parse(localStorageInfo)[tableKey] ?? null;
};

export const setTablePreferencesToLS = ({
  tableKey,
  partialTableData
}: {
  tableKey: string;
  partialTableData: Partial<TTablePreferences>;
}) => {
  const previousValue = localStorage.getItem(TABLE_PREFERENCES_LS_KEY);
  const parsedPreviousValue = previousValue ? JSON.parse(previousValue) ?? {} : {};

  localStorage.setItem(
    TABLE_PREFERENCES_LS_KEY,
    JSON.stringify({
      ...parsedPreviousValue,
      [tableKey]: {
        ...parsedPreviousValue[tableKey],
        ...partialTableData
      }
    })
  );
};
