export enum LicenseFeatures {
  TEAM_MODE = 'team_mode',
  USER_DEFINED_FIELDS = 'user_defined_fields',
  THIRD_PARTY_ENRICHMENT = 'third_party_enrichment',
  EXPOSER_INVESTIGATE = 'exposer_investigate',
  SUPPORTED_INTEGRATIONS = 'supported_integrations',
  MAX_JOBS = 'max_jobs',
  RCA_SUPPORT = 'rca_support'
}

export type LicenseFeature =
  | LicenseFeatures.TEAM_MODE
  | LicenseFeatures.USER_DEFINED_FIELDS
  | LicenseFeatures.THIRD_PARTY_ENRICHMENT
  | LicenseFeatures.EXPOSER_INVESTIGATE
  | LicenseFeatures.MAX_JOBS
  | LicenseFeatures.SUPPORTED_INTEGRATIONS
  | LicenseFeatures.RCA_SUPPORT;
