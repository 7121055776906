import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { useVisualizationsContext } from './Visualizations.context';
import { getJsonConvert } from '../../utilities/json-convert';
import { PierchartNestedProps, PiechartNested } from '../Visualisation/PiechartNested';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useUtils } from '../../hooks/useUtils';

export function QueryPiechartNested(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const [pieData, setPieData] = useState<PierchartNestedProps>(undefined);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const utils = useUtils();

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    let slices: Array<any> = [];
    for (const key in aggregations) {
      slices = slices.concat(aggregations[key].buckets);
    }

    // eslint-disable-next-line
    const innerData: Array<any> = [];
    // eslint-disable-next-line
    const outerData: Array<any> = [];
    slices.forEach((slice) => {
      let childElement = null;
      for (const key in slice) {
        if (key !== 'key' && key !== 'doc_count') {
          childElement = slice[key];
          break;
        }
      }

      const element = { name: slice.key, value: slice.doc_count - childElement.sum_other_doc_count };
      innerData.push(element);

      // eslint-disable-next-line
      childElement.buckets.forEach((childSlice: any) => {
        const outerSlice = { name: childSlice.key, value: childSlice.doc_count };
        outerData.push(outerSlice);
      });
    });

    const allColors = utils.nColorArray(innerData.length + outerData.length, visualizationRecord.style['color-seed']);
    const innerColors = allColors.slice(0, innerData.length);
    const outerColors = allColors.slice(innerData.length);

    const filterFields = VisualizationRecord.getFilterFieldsFromQuery(visualizationRecord.query, []);

    setPieData({
      innerLayer: {
        data: innerData,
        colors: innerColors,
        filterFields: filterFields.slice(0, 1)
      },
      outerLayer: {
        data: outerData,
        colors: outerColors,
        filterFields: filterFields.slice(1)
      }
    });

    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <p>&nbsp;</p>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        <PiechartNested key={props.name + Math.random()} {...pieData} />
      </Spin>
    </>
  );
}
