import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from 'src/components/shared/models/common/CommonRequest';

@JsonObject('IdeaBoxRequest')
export class IdeaBoxRequest extends CommonRequest {
  @JsonProperty('topic', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  topic: string = undefined;

  @JsonProperty('message', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  message: string = undefined;

  @JsonProperty('route', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  route: string = undefined;

  constructor(topic: string, message: string, route: string) {
    super();
    this.route = route;
    this.topic = topic;
    this.message = message;
  }
}
