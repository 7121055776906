import { useTranslation } from 'react-i18next';
import { StateChip } from '@arcanna/generic';
import { useTheme } from '@mui/material';

export function JobDisabledTag() {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return <StateChip color={theme.palette.grey[400]} text={t('job:jobState.disabled')} />;
}
