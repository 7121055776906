import { JsonObject, JsonProperty } from 'json2typescript';
import { JobCreateIntegrationRecord } from './JobCreateIntegrationRecord';

@JsonObject('JobInfoProcessorRecord')
export class JobInfoProcessorRecord {
  @JsonProperty('integrations', [JobCreateIntegrationRecord])
  integrations?: JobCreateIntegrationRecord[] = [];

  @JsonProperty('retrain_models', [String])
  retrainModels?: string[];

  @JsonProperty('last_model_path', String)
  lastModelPath?: string;

  @JsonProperty('is_rollback', Boolean)
  isRollback?: boolean;

  constructor(integrations: JobCreateIntegrationRecord[]) {
    this.integrations = integrations;
  }
}
