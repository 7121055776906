import { useMemo } from 'react';
import { Row, Col, Card } from 'antd';

import { useRouteMatch } from 'react-router-dom';
import { useVisualizationsContext } from '../../../../shared/components/QueryVisualisation/Visualizations.context';
import { useCaseHook } from '../../Usecase/Usecase.hook';

import styles from './WifiInsights.module.css';
import { VisualizationRecord } from '../../../../shared/models/usecase/VisualizationRecord';
import { QueryMetric } from '../../../../shared/components/QueryVisualisation/QueryMetric';
import { CircleBackground } from './CircleBackground';
import { CircleMetricWrapper } from './CircleMetricWrapper';
import { QueryMetricDerivative } from '../../../../shared/components/QueryVisualisation/QueryMetricsDerivative';
import { QueryMetricsSum } from '../../../../shared/components/QueryVisualisation/QueryMetricsSum';
import { MultiLayerRecord } from '../../../../shared/models/usecase/MultiLayerRecord';
import { QueryMetricsPercentDerivative } from '../../../../shared/components/QueryVisualisation/QueryMetricsPercentDerivative';
import { CirclePercentageBackground } from './CirclePercentageBackground';
import { MetricsProvider } from '../../../../shared/components/QueryVisualisation/QueryMetric.context';
import { ExportPdf } from '../../../../shared/components/Export/ExportPdf';
import { TimeFilterFragment } from '../../../../shared/components/Visualisation/TimeFilterFragment';

export function WifiInsights() {
  const visualizationContext = useVisualizationsContext();
  const match = useRouteMatch('/usecases/usecase/:useCaseId');
  // @ts-expect-error TS(2339): Property 'params' does not exist on type 'match<{}...
  const { params } = match;
  // eslint-disable-next-line
  const usecaseId = (params as any).useCaseId;
  const { getMetadata } = useCaseHook();

  useMemo(() => {
    getMetadata(usecaseId);
  }, [usecaseId, getMetadata]);

  // TODO: Move to hook
  function getVisualisationRecordById(id: string): VisualizationRecord {
    // @ts-expect-error TS(2322): Type 'VisualizationRecord | undefined' is not assi...
    return visualizationContext.state.page.visualizations.find((record: VisualizationRecord) => record.id === id);
  }

  // Render
  if (visualizationContext.state.page != null) {
    return (
      <>
        <div id="usecase-canvas" className={styles['wifi-insight']}>
          <Row style={{ marginBottom: '10px' }}>
            <Col span="24" className="flex flex-end">
              {/* @ts-expect-error TS(2322): Type '{ length?: number | undefined; toString?: ((... */}
              <ExportPdf {...visualizationContext.state.page.exports} />
            </Col>
          </Row>
          <Row>
            <TimeFilterFragment />
          </Row>

          <Row style={{ marginTop: '10px' }}>
            {/* Hotspots */}
            <Col span="12">
              <Card>
                <Row>
                  <Col span="9">
                    <h2 className={styles['orange-title']}>Hotspots</h2>
                    <Row style={{ paddingTop: '35px' }}>
                      <Col span="12">
                        <img src="/wifi_insights/hotspots_asset.png" alt="Hotspots" className={styles['card-logo']} />
                      </Col>
                      <Col span="12">
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_hotspots_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                  <Col span="15">
                    <Row>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricDerivative {...getVisualisationRecordById('wifi_hotspots_variation')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_hotspots_business_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="green-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_hotspots_orange_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>

                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricsSum
                            {...(getVisualisationRecordById('wifi_hotspots_captive_portal_cardinality') as MultiLayerRecord)}
                          />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetricsSum
                            {...(getVisualisationRecordById(
                              'wifi_hotspots_captive_portal_business_cardinality'
                            ) as MultiLayerRecord)}
                          />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="green-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetricsSum
                            {...(getVisualisationRecordById(
                              'wifi_hotspots_captive_portal_orange_cardinality'
                            ) as MultiLayerRecord)}
                          />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Traffic */}
            <Col span="12">
              <Card>
                <Row>
                  <Col span="9">
                    <h2 className={styles['orange-title']}>Traffic</h2>
                    <Row style={{ paddingTop: '35px' }}>
                      <Col span="12">
                        <img src="/wifi_insights/traffic_asset.png" alt="Traffic" className={styles['card-logo']} />
                      </Col>
                      <Col span="12">
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_traffic')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                  <Col span="15">
                    <Row>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricsPercentDerivative {...getVisualisationRecordById('wifi_traffic_percent_variation')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_traffic_business')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="green-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_traffic_mobile_offload')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetric {...getVisualisationRecordById('wifi_traffic_captive_portal')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_traffic_business_percentage')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="green-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_traffic_orange')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Access Points */}
            <Col span="12">
              <Card>
                <Row>
                  <Col span="9">
                    <h2 className={styles['orange-title']}>Access Points</h2>
                    <Row style={{ paddingTop: '35px' }}>
                      <Col span="12">
                        <img src="/wifi_insights/ap_asset.png" alt="APs" className={styles['card-logo']} />
                      </Col>
                      <Col span="12">
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_ap_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                  <Col span="15">
                    <Row>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricDerivative {...getVisualisationRecordById('wifi_ap_variation')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_ap_average_per_business_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="green-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_ap_average_per_orange_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricsSum
                            {...(getVisualisationRecordById('wifi_ap_captive_portal_cardinality') as MultiLayerRecord)}
                          />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="blue-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_ap_reachable_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <MetricsProvider>
                          <CirclePercentageBackground />
                          <CircleMetricWrapper className={`${styles['inside-donut']}`}>
                            <QueryMetric {...getVisualisationRecordById('wifi_ap_reachable_percentage')} />
                          </CircleMetricWrapper>
                        </MetricsProvider>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Users */}
            <Col span="12">
              <Card>
                <Row>
                  <Col span="9">
                    <h2 className={styles['orange-title']}>Users</h2>
                    <Row style={{ paddingTop: '35px' }}>
                      <Col span="12">
                        <img src="/wifi_insights/users_asset.png" alt="Users" className={styles['card-logo']} />
                      </Col>
                      <Col span="12">
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_clients_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                  <Col span="15">
                    <Row>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricsPercentDerivative {...getVisualisationRecordById('wifi_clients_percent_variation')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="yellow-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_clients_peak_hour_alltime_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="green-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_clients_offload_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricsSum
                            {...(getVisualisationRecordById('wifi_clients_captive_portal_cardinality') as MultiLayerRecord)}
                          />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="yellow-background" />
                        <CircleMetricWrapper className="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_clients_peak_hour_cardinality')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <MetricsProvider>
                          <CirclePercentageBackground />
                          <CircleMetricWrapper className={`${styles['inside-donut']}`}>
                            <QueryMetric {...getVisualisationRecordById('wifi_clients_successful_connected_percentage')} />
                          </CircleMetricWrapper>
                        </MetricsProvider>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  return <></>;
}
