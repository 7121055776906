import { Box, BoxProps, styled } from '@mui/material';

const StyledColorCircle = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'selectedColor';
  }
})<BoxProps & { color: string; index: number; selectedColor: string }>(({ theme, color, index, selectedColor }) => ({
  width: '20px',
  height: '20px',
  backgroundColor: color,
  borderRadius: '99px',
  margin: '4px',
  marginLeft: index % 8 === 0 ? 0 : '4px',
  outline: selectedColor === color ? `2px solid ${theme.palette.info.main}` : 'none',
  outlineOffset: '2px',
  cursor: 'pointer'
}));
export default StyledColorCircle;
