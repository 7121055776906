import * as React from 'react';
import cx from 'classnames';
import styles from './Box.module.css';

type BoxProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};
export function Box({ children, className = '', style = {} }: BoxProps) {
  return (
    <div className={cx([styles.root, className])} style={style}>
      {children}
    </div>
  );
}
