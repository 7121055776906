import { ResourceWrapper, ResponseCommon } from '@arcanna/models/utils';
import axios from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { config } from 'src/config';
import { jobKeys } from './keys';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';
import { Serializer } from '@arcanna/utils';
import { JobBulkStartRequest } from '@arcanna/models/Jobs/JobBulkStartRequest';

function useJobBulkStart() {
  const { t } = useTranslation(['requests']);
  const queryClient = useQueryClient();

  // SETUP
  const URL = config.api.job.bulkStart;
  const axiosFunction = useCallback((body: object) => axios.post<ResourceWrapper<ResponseCommon>>(URL, body), [URL]);

  // QUERY
  return useMutation(
    jobKeys.bulkStart(),
    (payload: JobBulkStartRequest) => {
      const payloadSerialized = Serializer.getInstance().serializeObject(payload);

      return axiosFunction(payloadSerialized);
    },
    {
      onSuccess: async (_, payload) => {
        await queryClient.invalidateQueries([config.api.jobsSummary]);
        await queryClient.invalidateQueries(jobKeys.all);
        await Promise.all(
          payload.jobIds.map((jobId) => queryClient.invalidateQueries([config.api.jobInfo.replace(':id', String(jobId))]))
        );

        showSuccessNotification(t('requests:job.bulkStart.success.title'), t('requests:job.bulkStart.success.subtitle'));
      },
      onError: () => {
        showErrorNotification(t('requests:job.bulkStart.error.title'), t('requests:job.bulkStart.error.subtitle'));
      }
    }
  );
}

export default useJobBulkStart;
