import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiToggleButtonStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiToggleButtonGroup: {
    styleOverrides: {
      root: {
        '&.MuiToggleButtonGroup-root > *': {
          marginLeft: 0
        }
      }
    }
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderColor: palette.secondary[600],
        backgroundColor: palette.secondary[900],
        height: '36px',
        textTransform: 'none',
        color: palette.secondary[300],
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '16px',
        border: `1px solid ${palette.secondary[600]}`,
        margin: 0,

        '&.Mui-selected': {
          backgroundColor: palette.secondary[800],
          color: palette.secondary[200],
          border: `1px solid ${palette.secondary[600]}`
        }
      }
    }
  }
});
