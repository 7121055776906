const HTML_CODES = {
  BULLET_POINT: '&#8226;',
  SPACE: '&nbsp;',
  PERCENTAGE: '&#37;',
  DOT: '&#46;'
};

const renderHTMLCode = (code: (typeof HTML_CODES)[keyof typeof HTML_CODES]) => ({ __html: code });

export { HTML_CODES, renderHTMLCode };
