import { JsonObject, JsonProperty } from 'json2typescript';
import { ResponseCommon } from '@arcanna/models/utils';

@JsonObject('CodeBlockRunResponse')
export class CodeBlockRunResponse extends ResponseCommon {
  @JsonProperty('stdout', String)
  stdout: string | null;

  @JsonProperty('stderr', String)
  stderr: string | null;

  @JsonProperty('output_record', String)
  outputRecord: string | null;
}

type TConstructorParams = {
  stdout: CodeBlockRunResponse['stdout'];
  stderr: CodeBlockRunResponse['stderr'];
  outputRecord: CodeBlockRunResponse['outputRecord'];
};

export function constructCodeBlockRunResponse({ stdout, stderr, outputRecord }: TConstructorParams): CodeBlockRunResponse {
  const instance = new CodeBlockRunResponse();
  instance.stdout = stdout;
  instance.stderr = stderr;
  instance.outputRecord = outputRecord;
  return instance;
}
