import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackFiltersFieldsRecord')
export class FeedbackFiltersFieldsRecord {
  @JsonProperty('source', String)
  source?: string;

  @JsonProperty('name', String)
  name?: string;

  @JsonProperty('operators', [String])
  operators?: string[] = [];
}
