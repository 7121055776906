import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('JobBulkStartRequest')
export class JobBulkStartRequest extends RequestCommon {
  @JsonProperty('job_ids', [Number])
  jobIds: number[];

  @JsonProperty('include_all_jobs', Boolean)
  includeAllJobs: boolean;
}

type TConstructorParams = {
  jobIds: JobBulkStartRequest['jobIds'];
  includeAllJobs?: JobBulkStartRequest['includeAllJobs'];
};

export function constructJobBulkStartRequest({ jobIds, includeAllJobs }: TConstructorParams) {
  const instance = new JobBulkStartRequest();
  instance.jobIds = jobIds;
  instance.includeAllJobs = includeAllJobs ?? false;
  return instance;
}
