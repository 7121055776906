import { useCallback, useEffect, useState } from 'react';
import { AppstoreOutlined } from '@ant-design/icons';

import { useFetch } from '../shared/hooks/useFetch';
import { getBackendEndpoint } from '../shared/utilities/api';
import { RouteType } from '../shared/models/route/RouteType';
import { useApp } from '../App/App.context';
import { useAppHook } from '../App/App.hook';
import { useAuth } from '../pages/Main/Login/Auth.context';
import { CommonRequest } from '../shared/models/common/CommonRequest';
import { UsecaseRecord } from '../shared/models/usecase/UsecaseRecord';
import WifiInsightsWrapper from '../pages/Main/Wifi/Insights/WifiInsightsWrapper';
import MobileWifiWrapper from '../pages/Main/Wifi/Insights/MobileWifiWrapper';
import UsecaseWrapper from '../pages/Main/Usecase/UsecaseWrapper';
import { useTheme } from '../../themes/theme.hook';

export function useRoutesHook() {
  useAppHook();

  const { getStaticRoutes } = useTheme();
  const { state: authState } = useAuth();
  const appContext = useApp();
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
  // eslint-disable-next-line
  const [dynamicPages, setDynamicPages] = useState<Array<any>>(null);

  // Retrieve infra ai_pages entry
  const { post: postUseCases } = useFetch({
    path: getBackendEndpoint('/usecase/list'),
    initialResponseData: null,
    load: false
  });

  useEffect(() => {
    if (authState.userSessionId === null) {
      setDynamicPages([]);
    } else {
      postUseCases(new CommonRequest())
        // eslint-disable-next-line
        .then((response: any) => {
          setDynamicPages(response.data.usecases);
        })
        .catch(() => {
          setDynamicPages([]);
        });
    }
  }, [postUseCases, authState.userSessionId]);

  // Goes recursive in finding a route with a given name and sets its children
  function setChildrenForRoute(routes: Array<RouteType>, name: string, children: Array<RouteType>) {
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].name === name) {
        routes[i].children = children;
      } else if (routes[i].children) {
        // @ts-expect-error TS(2345): Argument of type 'RouteType[] | undefined' is not ...
        setChildrenForRoute(routes[i].children, name, children);
      }
    }
  }

  const filterPublicRoutes = useCallback((items: Array<RouteType>) => {
    return items.filter((item: RouteType) => item.requiredPermissions == undefined);
  }, []);

  const filterAccessibleRoutes = useCallback(
    (items: Array<RouteType>) => {
      const result: Array<RouteType> = [];
      items.forEach((item: RouteType) => {
        if (item.children != null) {
          item.children = filterAccessibleRoutes(item.children);
        }
        if (item.requiredPermissions == undefined || appContext.hasAccessFor(item.requiredPermissions)) {
          result.push(item);
        }
      });
      return result;
    },
    // eslint-disable-next-line
    [appContext.hasAccessFor, appContext.state.userPermissions]
  );

  // Return use case React component class for given name
  function getUseCaseComponentForName(name: string) {
    if (name === 'wifi_insights') {
      return WifiInsightsWrapper;
    }
    if (name === 'mobile_wifi') {
      return MobileWifiWrapper;
    }
    return UsecaseWrapper;
  }

  useEffect(() => {
    const staticRoutes = getStaticRoutes();
    if (dynamicPages != null && appContext.state.userPermissions.length > 0) {
      const dashboardsRoute = staticRoutes.find((staticRoute: RouteType) => staticRoute.name === 'Dashboards');
      if (dashboardsRoute && dashboardsRoute.children && dashboardsRoute.children.length > 0) {
        const children: Array<RouteType> = dashboardsRoute.children.slice(0, 2);
        if (children && children.length > 0) {
          dynamicPages.forEach((page: UsecaseRecord) => {
            // Use base64 logos for uses cases
            let logo = '';
            if (page.logo_base64 !== null) {
              logo = `data:image/svg+xml;charset=utf-8;base64,${page.logo_base64}`;
            }

            children.push({
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
              name: page.display_name,
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
              title: page.display_name,
              path: `/usecases/usecase/${page.name}`,
              // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
              component: getUseCaseComponentForName(page.name),
              icon: AppstoreOutlined,
              isInMenu: true,
              isInLandingPage: true,
              logoPath: logo,
              isPrivate: true
            });
          });

          setChildrenForRoute(staticRoutes, 'Dashboards', children);
        }
      }
      const accessibleRoutes = filterAccessibleRoutes(staticRoutes);
      appContext.setRoutes(accessibleRoutes);
      appContext.setDynamicRoutesLoaded(true);
    } else {
      appContext.setRoutes(filterPublicRoutes(staticRoutes));
      appContext.setDynamicRoutesLoaded(true);
    }
    // eslint-disable-next-line
  }, [dynamicPages, filterAccessibleRoutes, appContext.state.userPermissions]);
}
