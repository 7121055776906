import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useFetch } from '../../../shared/hooks/useFetch';
import { getJsonConvert } from '../../../shared/utilities/json-convert';
import { UsecaseRecord } from '../../../shared/models/usecase/UsecaseRecord';
import { useVisualizationsContext } from '../../../shared/components/QueryVisualisation/Visualizations.context';
import { CommonRequest } from '../../../shared/models/common/CommonRequest';

export function useCaseHook() {
  const visualizationContext = useVisualizationsContext();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    initialResponseData: null,
    load: false
  });

  // eslint-disable-next-line
  function setDefaultTimeRange(pageResponse: any) {
    if (pageResponse.default_timerange != null) {
      // eslint-disable-next-line
      const myamount = pageResponse.default_timerange.find((f: any) => f.amount);
      // eslint-disable-next-line
      const myunits = pageResponse.default_timerange.find((f: any) => f.units);
      if (myamount != null && myunits != null) {
        visualizationContext.setIntervalStart(moment().subtract(myamount.amount, myunits.units));
        visualizationContext.setIntervalEnd(moment());
      }
    }
  }

  // Retrieve usecase metadata and available filters
  const getMetadata = useCallback(
    (usecaseId: string) => {
      post(`/usecase/metadata/${usecaseId}`, new CommonRequest())
        // eslint-disable-next-line
        .then((response: any) => {
          const pageResponse = jsonConvert.deserializeObject(response.data, UsecaseRecord);
          setDefaultTimeRange(pageResponse);
          visualizationContext.setPage(pageResponse);
        })
        // eslint-disable-next-line
        .catch(() => {});
    },
    // eslint-disable-next-line
    [post, jsonConvert]
  );

  return {
    getMetadata
  };
}
