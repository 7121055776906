import { RHFSwitch } from '@arcanna/forms';
import { getFormParameterName } from '../RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';
import { getDataTestIdFormat } from '../../../../../utils/getDataTestIdFormat';

function BooleanParameter({ parameter, formSectionName }: TRHFSmartGeneratedFieldProps) {
  return (
    <RHFSwitch
      name={getFormParameterName(parameter, formSectionName)}
      label={parameter.caption}
      tooltipText={parameter.description}
      required={parameter.required}
      dataTestId={getDataTestIdFormat(`parameter-field-${parameter.field}`)}
    />
  );
}

export default BooleanParameter;
