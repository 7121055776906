import { Card, CardProps, styled } from '@mui/material';

const StyledClickableCard = styled(Card)<CardProps>(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.standard
  }),

  '&:hover': {
    transform: 'scale(1.05)'
  }
}));

export default StyledClickableCard;
