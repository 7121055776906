import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { JobAlertsTimeseriesGraphDataPoint, useJobAlertsTimeseries } from '../../../data-access';
import { CustomLabel, JobAlertsTimeseriesRequest } from '../models';
import { PeriodOptions } from '../models/filters/period';

export const tickOptions: Record<PeriodOptions, string> = {
  [PeriodOptions.LAST_24H]: 'HH:mm',
  [PeriodOptions.LAST_7DAYS]: 'DD MMM HH:mm',
  [PeriodOptions.LAST_30DAYS]: 'DD MMM HH:mm',
  [PeriodOptions.ALL_TIME]: 'DD MMM YYYY'
};

type UseJobOverviewTimeseries = {
  jobId: number;
  startDate: Date;
  endDate: Date;
  selectedLabels: CustomLabel[];
  period?: PeriodOptions;
  bucketId?: string;
};
export function useJobOverviewTimeseries({
  jobId,
  startDate,
  endDate,
  selectedLabels,
  period = PeriodOptions.LAST_24H,
  bucketId
}: UseJobOverviewTimeseries) {
  const [tickFormatter, setTickFormatter] = useState<string>(period);

  const jobAlertsTimeseriesQuery = useJobAlertsTimeseries(
    new JobAlertsTimeseriesRequest(jobId, startDate, endDate, bucketId),
    selectedLabels
  );

  // Change the format for 'All time' in case the buckets are too close to each other (less than a day)
  useEffect(() => {
    if (jobAlertsTimeseriesQuery.data == null) {
      return;
    }

    let resetTickFormatter = true;

    if (period == PeriodOptions.ALL_TIME && jobAlertsTimeseriesQuery.data.graphDataSource.length > 1) {
      const dataPoints = jobAlertsTimeseriesQuery.data.graphDataSource;
      const timestamp1 = moment(dataPoints[0].date);
      const timestamp2 = moment(dataPoints[1].date);
      const interval = timestamp2.diff(timestamp1, 'days');
      if (interval < 1) {
        setTickFormatter('DD MMM YYYY HH:mm');
        resetTickFormatter = false;
      }
    }

    if (resetTickFormatter) {
      setTickFormatter(tickOptions[period]);
    }
  }, [jobAlertsTimeseriesQuery.data, period, setTickFormatter]);

  const computeTicks = useCallback((dataPoints: JobAlertsTimeseriesGraphDataPoint[]) => {
    const ticks: string[] = [];
    // Eliminate ticks for each interval median value. This way we create the impression
    // that the bar is inside a interval without actually showing the ticks for the middle of the interval.
    for (let i = 0; i < dataPoints.length; i++) {
      if (i % 2 == 0) {
        ticks.push(dataPoints[i].date);
      }
    }
    return ticks;
  }, []);

  // Format the label to contain the interval start_end - end_date for aggregation
  const formatLabel = useCallback(
    // eslint-disable-next-line
    (data: any) => {
      if (data == null || data.length == 0) {
        return '';
      }

      let intervalStart: string;
      let intervalEnd: string;
      if (data[0]['payload'].train === false) {
        intervalStart = data[0]['payload']['dateEntryStart'];
        intervalEnd = data[0]['payload']['dateEntryEnd'];
        return moment(intervalStart).format(tickFormatter) + ' - ' + moment(intervalEnd).format(tickFormatter);
      } else {
        const trainDate = data[0]['payload']['date'];
        const stillUtc = moment.utc(trainDate).toDate();
        return moment(stillUtc).local().format(tickFormatter);
      }
    },
    [tickFormatter]
  );

  return {
    computeTicks,
    formatLabel,
    tickFormatter,
    setTickFormatter
  };
}
