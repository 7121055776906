import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Menu, type MenuProps, Modal, Space, Tooltip, Typography } from 'antd';
import { Button } from 'src/components/shared/components';
import styles from './ModelsHistoryTable.module.css';
import { DotDotDotVerticalIcon } from 'src/themes/icons/DotDotDotVerticalIcon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DownloadIcon from 'src/themes/icons/DownloadIcon';
import RocketIcon from 'src/themes/icons/RocketIcon';
import TrashIcon from 'src/components/shared/icons/TrashIcon';
import useDownloadKnowledgeBase from 'src/data-access/model-history/useDownloadKnowledgeBase';
import useRollbackToModel from 'src/data-access/model-history/useRollbackToModel';
import { DeleteModelRequest } from 'src/components/shared/models/job/DeleteModelRequest';
import useDeleteModel from 'src/data-access/model-history/useDeleteModel';
import { JobRollbackToModel } from 'src/components/shared/models/job/JobRollbackToModel';
import { useJobInfo } from 'src/data-access';
import { useTranslation } from 'react-i18next';
import { showInfoNotification } from 'src/components/shared/utilities/notification';
import { ForbiddenIcon, WarningIcon } from 'src/themes/icons';

type RetrainHistoryOptions = {
  modelId: string;
  jobId: number;
  isModelRevertible: boolean;
};
export default function RetrainHistoryOptions({ modelId, jobId, isModelRevertible }: RetrainHistoryOptions) {
  const jobInfoQuery = useJobInfo(jobId);
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const currentModelInUse = jobInfoQuery.data?.info.processor.lastModelPath?.split('.')[0];
  const [showOptions, setShowOptions] = useState(false);
  const { t } = useTranslation(['overview', 'rollback']);
  const menuRef = useRef<HTMLDivElement>(null); // Ref for the dropdown
  const { downloadKnowledgebase } = useDownloadKnowledgeBase(jobId.toString());
  const rollbackToModelMutation = useRollbackToModel(jobId.toString());
  const [isRollbackModalOpen, setIsRollbackModalOpen] = useState(false);

  const deleteModelMutation = useDeleteModel(jobId.toString());

  const deleteModel = useCallback(
    (modelIdToDelete: string) => {
      deleteModelMutation.mutate(new DeleteModelRequest(modelIdToDelete));
    },
    [deleteModelMutation]
  );

  const isCurrentModelInUse = currentModelInUse === modelId;

  const handleMenuClick: MenuProps['onClick'] = useCallback(
    // eslint-disable-next-line
    (e: any) => {
      switch (e.key) {
        case 'downloadKnowledgebase': {
          downloadKnowledgebase(modelId);
          setShowOptions(false);
          break;
        }
        case 'rollback': {
          setIsRollbackModalOpen(true);
          break;
        }
        case 'production': {
          if (!(isCurrentModelInUse || !isModelRevertible)) {
            showInfoNotification(t('alreadyInUse'), t('usedInProduction'));
            setShowOptions(false);
            break;
          }
          break;
        }
        case 'deleteModel':
          if (isCurrentModelInUse) {
            e.domEvent.stopPropagation();
            break;
          }
          if (!isCurrentModelInUse && confirm(t('deleteModelConfirm'))) {
            deleteModel(modelId);
            setShowOptions(false);
            break;
          }
          break;
        default:
          break;
      }
    },
    [isCurrentModelInUse, modelId, downloadKnowledgebase, deleteModel, t, isModelRevertible]
  );

  const [displayForbiddenIconForMenuKey, setDislpayForbiddenIconForMenuKey] = useState<string | undefined>(undefined);

  const handleCancelRollbackModal = () => {
    setIsRollbackModalOpen(false);
    setShowOptions(false);
  };

  const handleSaveRollbackModal = () => {
    rollbackToModelMutation.mutate(new JobRollbackToModel(modelId));
    setShowOptions(false);
    setIsRollbackModalOpen(false);
  };

  const forbiddenIcon = useMemo(
    () => (
      <ForbiddenIcon
        width={16}
        height={16}
        style={{ color: 'var(--utilitarian-color-red-600)', display: 'flex', alignSelf: 'center' }}
      />
    ),
    []
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const menuItems: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: 'downloadKnowledgebase',
        label: t('downloadKnowledgebase'),
        icon: <DownloadIcon data-test-id="history-download-knowledge-base-icon" />
      },
      {
        key: isCurrentModelInUse ? 'production' : 'rollback',
        label:
          isCurrentModelInUse || !isModelRevertible ? (
            <Tooltip title={t('currentModelInUseTooltip')} placement="bottom">
              <Space size={4} align="center">
                <span>{t('currentModelInUse')}</span>
                {displayForbiddenIconForMenuKey === 'production' ? forbiddenIcon : null}
              </Space>
            </Tooltip>
          ) : (
            t('rollback')
          ),
        icon: <RocketIcon data-test-id={`history-${isCurrentModelInUse ? 'production' : 'rollback'}-icon`} />,
        onMouseEnter: () => setDislpayForbiddenIconForMenuKey('production'),
        onMouseLeave: () => setDislpayForbiddenIconForMenuKey(undefined),
        className: isCurrentModelInUse || !isModelRevertible ? 'disabled' : ''
      },
      {
        key: 'deleteModel',
        label: isCurrentModelInUse ? (
          <Tooltip title={t('currentModelCannotBeDeletedTooltip')} placement="bottom">
            <Space size={4} align="center">
              <span>{t('deleteModel')}</span>
              {displayForbiddenIconForMenuKey === 'deleteModel' ? forbiddenIcon : null}
            </Space>
          </Tooltip>
        ) : (
          t('deleteModel')
        ),
        icon: <TrashIcon className="m-r-8" data-test-id="history-trash-icon" />,
        onMouseEnter: () => setDislpayForbiddenIconForMenuKey('deleteModel'),
        onMouseLeave: () => setDislpayForbiddenIconForMenuKey(undefined),
        className: isCurrentModelInUse ? 'disabled' : ''
      }
    ];
  }, [t, displayForbiddenIconForMenuKey, isCurrentModelInUse, forbiddenIcon, isModelRevertible]);

  const menu = useMemo(
    () => (
      <div ref={menuRef}>
        <Menu onClick={handleMenuClick} items={menuItems} className={styles.retrainHistoryMenu} />
      </div>
    ),
    [menuItems, handleMenuClick]
  );

  return (
    <>
      <Dropdown
        trigger={['click']}
        placement={'bottomRight'}
        overlay={menu}
        overlayClassName={styles.retrainHistoryMenuDropdown}
        open={showOptions}
        onOpenChange={() => setShowOptions(true)}
      >
        <DotDotDotVerticalIcon className={styles.pointerCursor} data-test-id="actions-dropdown" />
      </Dropdown>
      <Modal
        open={isRollbackModalOpen}
        title={
          <Space size={8} className={styles.warning}>
            <WarningIcon className={styles.warningIcon} />
            <Typography.Text className={styles.warningText}>{t('rollback:title')}</Typography.Text>
          </Space>
        }
        onOk={handleSaveRollbackModal}
        onCancel={handleCancelRollbackModal}
        centered
        width={420}
        className={styles.modal}
        footer={[
          <Button size="small" type="secondaryNew" className="m-r-24" key="cancel" onClick={handleCancelRollbackModal}>
            {t('common:cancel')}
          </Button>,
          <Button
            size="small"
            key="submit"
            className={styles.confirmButton}
            type="primaryNew"
            onClick={handleSaveRollbackModal}
            // @ts-expect-error TS(2322): Type 'Element | null' is not assignable to type 'R...
            icon={rollbackToModelMutation.isLoading ? <LoadingOutlined /> : null}
            disabled={rollbackToModelMutation.isLoading}
          >
            {t('common:yesContinue')}
          </Button>
        ]}
      >
        <Typography.Text>{t('rollback:switchingChanges')}</Typography.Text>
        <br />
        <Typography.Text>{t('rollback:duringUpdate')}</Typography.Text>
        <br />
        <ul>
          <li>
            <Typography.Text>{t('rollback:disabledJob')}</Typography.Text>
          </li>
          <li>
            <Typography.Text>{t('rollback:updatedFeedback')}</Typography.Text>
          </li>
          <li>
            <Typography.Text>{t('rollback:noActionsJob')}</Typography.Text>
          </li>
        </ul>
        <br />
        <Typography.Text>{t('rollback:continueSentence')}</Typography.Text>
      </Modal>
    </>
  );
}
