import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { JobBucketStatesRequest } from '../../components/shared/models/job/JobBucketStatesRequest';
import { JobBucketStatesResponse } from '../../components/shared/models/job/JobBucketStatesResponse';

type UseJobOverviewBucketStates = {
  jobId: number;
  dateFrom: Date;
};
export function useJobBucketStates({ jobId, dateFrom }: UseJobOverviewBucketStates) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const requestPayload = new JobBucketStatesRequest(jobId, dateFrom);

  return useQuery(
    [config.api.feedbackStatisticsBucketsStatesBreakdown, requestPayload],
    () =>
      axios
        .post<{ resource: JobBucketStatesResponse }>(config.api.feedbackStatisticsBucketsStatesBreakdown, requestPayload)
        .then((responseData: AxiosResponse<{ resource: JobBucketStatesResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobBucketStatesResponse)
        ),
    {
      enabled: Boolean(jobId)
    }
  );
}
