import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import fileSize from 'filesize';
import { Metric } from '../Visualisation/Metric';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import styles from './QueryVisualisation.module.css';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useVisualizationsContext } from './Visualizations.context';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';

import { useMetricsContext } from './QueryMetric.context';
import { MappingRecord } from '../../models/usecase/MappingRecord';

// eslint-disable-next-line
export function accessMappingElement(obj: any, mappingSource: string) {
  const mappingTokens: Array<string> = mappingSource.split('.');
  let objRef = obj;
  for (let i = 0; i < mappingTokens.length; i += 1) {
    const token = mappingTokens[i];
    if (objRef !== undefined && objRef !== null && token in objRef) {
      objRef = objRef[token];
    } else {
      return 0;
    }
  }

  if (objRef === 0 || objRef == null) {
    objRef = 0;
  }
  return objRef;
}

export function humanReadableValue(value: number, props: VisualizationRecord) {
  if (typeof value !== 'number') {
    return value;
  }
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (props.id.indexOf('percent') >= 0) {
    return `${value.toFixed(1)}%`;
  }
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (props.id.indexOf('traffic') >= 0) {
    return fileSize(value, { round: 1 });
  }
  return value.toLocaleString('en-US', { maximumFractionDigits: 1 });
}

export function QueryMetric(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const metricsContext = useMetricsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // eslint-disable-next-line
  const [buckets, setBuckets] = useState(Array<any>());
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  /*
    Sets the metric buckets in the MetricsProvider, only if it exists.
    This way not all metrics need to be surrounded by a MetricProvider component.
  */
  // eslint-disable-next-line
  function setContextMetricBuckets(b: Array<any>) {
    if (metricsContext != null) {
      metricsContext.setBuckets(b);
    }
  }

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    // eslint-disable-next-line
    let responseBuckets: Array<any> = [];
    const aggregations = visualizationRecord.aggs;
    /*
      If a metric mapping is specified than only some specific fields from the returned
      aggregations are used. Also the label for the metric because the one specified
      by the mapping.
    */
    if (visualizationRecord.mappings != null && visualizationRecord.mappings.length > 0) {
      visualizationRecord.mappings.forEach((mapping: MappingRecord) => {
        const mappingSource = mapping.source;
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        const value = accessMappingElement(aggregations, mappingSource);
        responseBuckets = responseBuckets.concat({
          key: mapping.name,
          doc_count: humanReadableValue(value, props)
        });
      });
    } else {
      /*
        Otherwise just use all the buckets from the aggregation to create a metric bucket collection.
       */
      for (const key in aggregations) {
        if (aggregations[key].buckets === undefined) {
          responseBuckets = responseBuckets.concat({
            key: props.name,
            doc_count: humanReadableValue(aggregations[key].value, props)
          });
        } else {
          // eslint-disable-next-line
          aggregations[key].buckets.forEach((bucket: any) => {
            bucket.doc_count = humanReadableValue(bucket.doc_count, props);
          });
          responseBuckets = responseBuckets.concat(aggregations[key].buckets);
        }
      }
    }

    setBuckets(responseBuckets);
    setContextMetricBuckets(responseBuckets);
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  // Render differently base on orientation
  if (props.style.orientation === 'horizontal') {
    return (
      <>
        <Spin tip="Loading..." spinning={loading}>
          <div>
            {buckets.length > 0 && buckets[0].key !== props.name && <div>{props.name}</div>}
            <div className={styles.horizontalMetricContainer}>
              {buckets.map((bucket) => (
                <div key={bucket.key}>
                  <Metric
                    key={bucket.key + Math.random()}
                    value={bucket.doc_count}
                    label={bucket.key}
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    tooltip={visualizationRecord.tooltip}
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    style={visualizationRecord.style}
                  />
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </>
    );
  }

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div>
          {buckets.length > 0 && buckets[0].key !== props.name && <div className="usecase-card-title">{props.name}</div>}
          <div>
            {buckets.map((bucket) => (
              <Metric
                key={bucket.key + Math.random()}
                value={bucket.doc_count}
                label={bucket.key}
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                tooltip={visualizationRecord.tooltip}
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                style={visualizationRecord.style}
              />
            ))}
          </div>
        </div>
      </Spin>
    </>
  );
}
