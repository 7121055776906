import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('JobBulkStopRequest')
export class JobBulkStopRequest extends RequestCommon {
  @JsonProperty('job_ids', [Number])
  jobIds: number[];

  @JsonProperty('include_all_jobs', Boolean)
  includeAllJobs: boolean;
}

type TConstructorParams = {
  jobIds: JobBulkStopRequest['jobIds'];
  includeAllJobs?: JobBulkStopRequest['includeAllJobs'];
};

export function constructJobBulkStopRequest({ jobIds, includeAllJobs }: TConstructorParams) {
  const instance = new JobBulkStopRequest();
  instance.jobIds = jobIds;
  instance.includeAllJobs = includeAllJobs ?? false;
  return instance;
}
