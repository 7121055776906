import React from 'react';
import { createRoot } from 'react-dom/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  fas,
  faPlus,
  faMinus,
  faCommentDots,
  faArrowLeft,
  faArrowRight,
  faChartArea,
  faCircle,
  faTimesCircle,
  faCheckCircle,
  faEdit,
  faSave,
  faUndo,
  faTrash,
  faTrashAlt,
  faLightbulb,
  faPause,
  faPencilAlt,
  faRedo,
  faExclamationCircle,
  faCaretDown,
  faCaretRight,
  faCopy,
  faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';

import { config } from './config';

import App from './components/App/App';
import './i18n';
import apm from './rum';
import './data-access/api';
import reportWebVitals from './reportWebVitals';

library.add(
  fas,
  faPlus,
  faMinus,
  faCommentDots,
  faArrowLeft,
  faArrowRight,
  faChartArea,
  faCircle,
  faTimesCircle,
  faEdit,
  faSave,
  faCheckCircle,
  faUndo,
  faTrash,
  faTrashAlt,
  faLightbulb,
  faPause,
  faPencilAlt,
  faRedo,
  faExclamationCircle,
  faCaretDown,
  faCaretRight,
  faCopy,
  faAngleDoubleRight
);

const OrangeTheme = React.lazy(() => import('./themes/orange/Theme'));
const SiscaleTheme = React.lazy(() => import('./themes/siscale/Theme'));

interface ThemeProps {
  children: React.ReactElement;
}

const ThemeSelector = (props: ThemeProps) => (
  <>
    <React.Suspense fallback={<></>}>
      {(() => {
        if (config.branding.folder === 'orange') {
          return <OrangeTheme />;
        }
        return <SiscaleTheme />;
      })()}
    </React.Suspense>
    {props.children}
  </>
);

apm.init();

const container = document.getElementById('root');
// @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig...
const root = createRoot(container);
root.render(
  <ThemeSelector>
    <App />
  </ThemeSelector>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log);
