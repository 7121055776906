import { Result } from 'antd';
import Button from '../../shared/components/Button/Button';

export default function Unavailable() {
  return (
    <Result
      status="error"
      title="Something went wrong"
      subTitle="Please contact support to report this issue."
      extra={[<Button size="small" key="buy" onClick={() => window.location.assign('/')} text="Retry" />]}
    />
  );
}
