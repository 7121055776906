import { JobStateEnum } from 'src/components/shared/models';
import { JobEnabledTag } from './components/JobEnabledTag';
import { JobCollectingDataTag } from './components/JobCollectingDataTag';
import JobReadyToSelectFeaturesTag from './components/JobReadyToSelectFeaturesTag';
import { JobDisabledTag } from './components/JobDisabledTag';
import { JobInvalidTag } from './components/JobInvalidTag';
import JobRollbackInProgressTag from './components/JobRollbackInProgressTag';
import JobCloningTag from './components/JobCloningTag';
import { ReactNode } from 'react';

type JobStateProps = {
  state: JobStateEnum | string | undefined;
  message?: string;
  jobId: number;
};

function JobState({ state, message, jobId }: JobStateProps) {
  const jobStateTagMap: Record<JobStateEnum, ReactNode> = {
    [JobStateEnum.STARTED]: null,
    [JobStateEnum.ENABLED]: <JobEnabledTag />,
    [JobStateEnum.COLLECTING_DATA]: <JobCollectingDataTag />,
    [JobStateEnum.READY_TO_SELECT_FEATURES]: <JobReadyToSelectFeaturesTag />,
    [JobStateEnum.DISABLED]: <JobDisabledTag />,
    [JobStateEnum.ERROR]: <JobInvalidTag message={message} />,
    [JobStateEnum.ROLLING_BACK]: <JobRollbackInProgressTag />,
    [JobStateEnum.CLONING]: <JobCloningTag jobId={jobId} />
  };

  const component = jobStateTagMap[state as JobStateEnum];

  return state && component ? <>{component}</> : null;
}

export default JobState;
