function Code02() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.3335 11.3333L14.6668 8L11.3335 4.66667M4.66683 4.66667L1.3335 8L4.66683 11.3333M9.3335 2L6.66683 14"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Code02;
