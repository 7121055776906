import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { EventFeedbackRequest } from '../../components/shared/models/events/EventFeedbackRequest';
import { EventFeedbackResponse } from '../../components/shared/models/events/EventFeedbackResponse';
import { showErrorNotification } from '../../components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';
import { ErrorResponse } from '../../components/shared/models/error/ErrorResponse';

type UseEventFeedback = {
  jobId: number;
};
export function useEventFeedback({ jobId }: UseEventFeedback) {
  const queryClient = useQueryClient();
  const { t } = useTranslation(['common']);

  return useMutation(
    (payload: EventFeedbackRequest) => {
      return axios.post<{ resource: EventFeedbackResponse }>(config.api.eventsFeedback, payload);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([config.api.eventExplorerBatch, { jobId }]);
      },
      onError: (error: { data: ErrorResponse }) => {
        showErrorNotification(t('error'), error.data.resource.request.reason);
      }
    }
  );
}
