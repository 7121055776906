import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { FeedbackFiltersFieldsRecord } from './FeedbackFiltersFieldsRecord';

@JsonObject('FeedbackFiltersFieldsResponse')
export class FeedbackFiltersFieldsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('fields_list', [FeedbackFiltersFieldsRecord])
  fields_list?: FeedbackFiltersFieldsRecord[] = [];
}
