/* eslint-disable max-len */
function CheckboxUncheckedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#091025" stroke="#2A3247" />
    </svg>
  );
}

export default CheckboxUncheckedIcon;
