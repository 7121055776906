import { EStepStatus, TStep } from './ModalSteps.types';
import { StepIconProps } from '@mui/material';
import { EIcon, Icon, Spinner } from '@arcanna/generic';

export const ModalStepIcon = (props: StepIconProps) => {
  const { completed, error } = props;
  if (error) {
    return <Icon name={EIcon.Error} sx={{ fontSize: '24px' }} />;
  }
  if (completed) {
    return <Icon name={EIcon.Success} sx={{ fontSize: '24px' }} />;
  }
  return <Spinner />;
};

export const getStatusMessage = (step: TStep) => {
  switch (step.status) {
    case EStepStatus.Validating:
      return step.validationDescription;
    case EStepStatus.Success:
      return step.successDescription;
    default:
      return step.errorDescription;
  }
};
