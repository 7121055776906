import { TFunction } from 'react-i18next';
import { showSuccessNotification } from 'src/components/shared/utilities/notification';

const copyToClipboard = (textToCopy: string | undefined, t: TFunction) => {
  navigator.clipboard
    .writeText(textToCopy || '')
    .then(() => showSuccessNotification(t('common:actionCompleted'), t('common:copyValueSuccess')));
};

export { copyToClipboard };
