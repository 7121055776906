import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { Row, Table as TanstackTable } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import TableHead from './components/TableHead';
import { computeTableBodyCellCSSVariableName, computeTableHeaderCellCSSVariableName } from './Table.utils';
import { MemoizedTableBody } from './components/TableBody/TableBody';
import { EmptyState, Spinner } from '@arcanna/generic';
import { TABLE_SIZES } from './components/TableSizeSelector/TableSizeSelector.constants';
import StyledTable from './StyledTable.styles';
import { SxProps } from '@mui/material';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

type TTableProps<T> = {
  tableInstance: TanstackTable<T>;
  columnOrder?: string[];
  setColumnOrder?: Dispatch<SetStateAction<string[]>>;
  onRowClick?: (row: Row<T>, index: number) => void;
  isLoading?: boolean;
  noResultsMessageTitle: string;
  noResultsMessageSubtitle: string;
  emphasizedRows?: number[];
  isTableResizable?: boolean;
  hasMinimizedView?: boolean;
  onTableSizeChange?: (tableSize: string) => void;
  customRowSizeValue?: number;
  onCustomRowSizeChange?: (customRowSizeValue: number) => void;
  isStrippedTable?: boolean;
  defaultTableSize?: TABLE_SIZES;
  getIsRowEmphasized?: (row: Row<T>) => boolean;
  hasColumnReordering?: boolean;
  sx?: SxProps;
  dataTestId?: string;
};

function Table<T>({
  tableInstance,
  onRowClick,
  isLoading,
  noResultsMessageTitle,
  noResultsMessageSubtitle,
  emphasizedRows,
  isTableResizable = true,
  onTableSizeChange,
  customRowSizeValue = 2,
  onCustomRowSizeChange,
  isStrippedTable = false,
  defaultTableSize = TABLE_SIZES.small,
  hasMinimizedView = false,
  getIsRowEmphasized,
  hasColumnReordering = false,
  sx,
  dataTestId
}: TTableProps<T>) {
  const [tableSize, setTableSize] = useState<string>(defaultTableSize);
  const [tempCustomRowSizeValue, setTempCustomRowSizeValue] = useState<number>(customRowSizeValue);

  // This is used to fake a loading state when changing sizes, needed becuase without it the table freezes when changing sizes
  const [fakeIsLoading, setFakeIsLoading] = useState<boolean>(false);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active && over && active.id !== over.id) {
        tableInstance.setColumnOrder?.((prevColumnOrder: string[]) => {
          const oldIndex = prevColumnOrder.indexOf(active.id as string);
          const newIndex = prevColumnOrder.indexOf(over.id as string);

          return arrayMove(prevColumnOrder, oldIndex, newIndex);
        });
      }
    },
    [tableInstance]
  );

  const columnSizeVars = useMemo(() => {
    const headers = tableInstance.getFlatHeaders();

    const colSizes: { [key: string]: string } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i];
      colSizes[computeTableHeaderCellCSSVariableName(header.id)] = `${header.getSize()}px`;
      colSizes[computeTableBodyCellCSSVariableName(header.column.id)] = `${header.column.getSize()}px`;
    }
    return colSizes;
    // eslint-disable-next-line
  }, [tableInstance.getFlatHeaders(), tableInstance.getState().columnSizingInfo, tableInstance.getState().columnSizing]);

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

  const availableActions = useMemo(
    () =>
      [tableInstance.options.enableMultiRowSelection, tableInstance.options.enableHiding, isTableResizable].filter(
        (item) => item === true
      ),
    [tableInstance.options.enableMultiRowSelection, tableInstance.options.enableHiding, isTableResizable]
  );

  const actionsColumnSize = useMemo(() => availableActions.length * 32, [availableActions]);

  const isActionsColumnVisible = useMemo(() => availableActions.length > 0, [availableActions]);

  const handleTableSizeChange = useCallback(
    async (newTableSize: string) => {
      setTableSize(newTableSize);
      onTableSizeChange?.(newTableSize);
      setFakeIsLoading(true);
      await sleep(500);
      setFakeIsLoading(false);
    },
    [onTableSizeChange]
  );

  const handleCustomRowSizeChange = useCallback(
    async (newCustomRowSize: number) => {
      setFakeIsLoading(true);
      setTempCustomRowSizeValue(newCustomRowSize);
      onCustomRowSizeChange?.(newCustomRowSize);
      await sleep(500);
      setFakeIsLoading(false);
    },
    [onCustomRowSizeChange]
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <StyledTable isLoading={Boolean(isLoading) || fakeIsLoading} sx={sx} data-test-id={dataTestId}>
        <table
          style={{
            ...columnSizeVars,
            width: isLoading ? '100%' : tableInstance.getTotalSize() + actionsColumnSize,
            minWidth: '100%',
            overflow: isLoading ? 'hidden' : 'auto'
          }}
        >
          <TableHead<T>
            isActionsColumnVisible={isActionsColumnVisible}
            tableInstance={tableInstance}
            columnOrder={tableInstance.getState().columnOrder}
            customRowSizeValue={tempCustomRowSizeValue}
            tableSize={tableSize}
            handleTableSizeChange={handleTableSizeChange}
            handleCustomRowSizeChange={handleCustomRowSizeChange}
            isFakeLoading={fakeIsLoading}
            isTableResizable={isTableResizable}
            hasMinimizedView={hasMinimizedView}
            actionsColumnSize={actionsColumnSize}
            hasColumnReordering={hasColumnReordering}
            isMemoized={Boolean(tableInstance.getState().columnSizingInfo.isResizingColumn)}
          />
          <MemoizedTableBody<T>
            isActionsColumnVisible={isActionsColumnVisible}
            tableInstance={tableInstance}
            onRowClick={onRowClick}
            isLoading={isLoading || fakeIsLoading}
            emphasizedRows={emphasizedRows}
            tableSize={tableSize}
            actionsColumnSize={actionsColumnSize}
            isStrippedTable={isStrippedTable}
            getIsRowEmphasized={getIsRowEmphasized}
            isMemoized={Boolean(tableInstance.getState().columnSizingInfo.isResizingColumn)}
            customRowSizeValue={tempCustomRowSizeValue}
          />
        </table>
        {(isLoading || fakeIsLoading) && <Spinner isOverlay sx={{ paddingTop: '40px' }} />}
        {!isLoading && !tableInstance.getRowModel().rows.length && (
          <EmptyState
            sx={{
              marginTop: '24px',
              marginLeft: '24px'
            }}
            title={noResultsMessageTitle}
            subtitle={noResultsMessageSubtitle}
          />
        )}
      </StyledTable>
    </DndContext>
  );
}

export default Table;
