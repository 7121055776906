import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiSwitchStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiSwitch: {
    styleOverrides: {
      root: {
        height: '24px',
        width: '44px',
        padding: 0,
        border: `1px solid ${palette.secondary[600]}`,
        background: palette.secondary[800],
        borderRadius: '100px',
        position: 'relative'
      },
      sizeSmall: {
        height: '20px',
        width: '36px',

        '.MuiSwitch-switchBase': {
          padding: '0px'
        },
        '.MuiSwitch-thumb': {
          width: '14px',
          height: '14px'
        }
      },
      thumb: {
        height: '18px',
        width: '18px'
      },
      input: {
        height: '18px !important',
        width: '44px !important',
        left: 0
      },
      switchBase: {
        padding: 0,
        top: '2px',
        left: '2px',
        position: 'absolute',
        color: palette.secondary[400],

        '&.Mui-checked': {
          color: palette.success[500],

          '.PrivateSwitchBase-input': {
            left: '-22px'
          },

          '&.Mui-disabled': {
            color: palette.secondary[600],
            cursor: 'not-allowed',
            pointerEvents: 'initial'
          }
        },

        '&.Mui-disabled': {
          color: palette.secondary[600],
          cursor: 'not-allowed',
          pointerEvents: 'initial'
        }
      },
      track: {
        display: 'none'
      }
    }
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          cursor: 'not-allowed'
        }
      }
    }
  }
});
