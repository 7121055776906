import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import PermissionsCheck from '../../../../shared/components/Permissions/Permissions';
import { permissions } from '../../../../shared/static/ComponentsPermissions';
import { config } from '../../../../../config';
import style from './JobNavigation.module.css';

type JobTrainNavItemProps = {
  jobId: string;
};
export default function JobTrainNavItem({ jobId }: JobTrainNavItemProps) {
  const { t } = useTranslation(['job']);

  return (
    <PermissionsCheck permissions={[permissions.retrainRead]} action={permissions.action.hiddenAction}>
      <NavLink
        to={config.routes.retrainJob.replace(':id', jobId)}
        className={cx([style.navLink, 'callout-default'])}
        activeClassName={cx([style.activeNavLink, 'callout-default'])}
        data-test-id={'menu-expanded-train'}
      >
        {t('job:navigation.train')}
      </NavLink>
    </PermissionsCheck>
  );
}
