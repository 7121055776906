/* eslint-disable max-len */
function Eye() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
      <path
        d="M13.146 4.33898C13.3096 4.52025 13.4001 4.75575 13.4001 4.99993C13.4001 5.24411 13.3096 5.47961 13.146 5.66088C12.1101 6.77561 9.75242 8.9459 7.00011 8.9459C4.24779 8.9459 1.89007 6.77561 0.854257 5.66088C0.690657 5.47961 0.600098 5.24411 0.600098 4.99993C0.600098 4.75575 0.690657 4.52025 0.854257 4.33898C1.89007 3.22424 4.24779 1.05396 7.00011 1.05396C9.75242 1.05396 12.1101 3.22424 13.146 4.33898Z"
        stroke="currentColor"
        strokeWidth="1.20845"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00033 6.97295C8.08998 6.97295 8.97332 6.08961 8.97332 4.99996C8.97332 3.91031 8.08998 3.02698 7.00033 3.02698C5.91068 3.02698 5.02734 3.91031 5.02734 4.99996C5.02734 6.08961 5.91068 6.97295 7.00033 6.97295Z"
        stroke="currentColor"
        strokeWidth="1.20845"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Eye;
