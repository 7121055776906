import { RHFTextField } from '@arcanna/forms';
import { getDataTestIdFormat } from 'src/utils/getDataTestIdFormat';
import { getFormParameterName } from '../RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';
import { useState } from 'react';
import { IconButton, InputAdornment, useTheme } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';

function PasswordParameter({ parameter, formSectionName }: TRHFSmartGeneratedFieldProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <RHFTextField
      name={getFormParameterName(parameter, formSectionName)}
      label={parameter.caption}
      tooltipText={parameter.description}
      hidden={parameter.visible === false}
      required={parameter.required}
      type={showPassword ? 'text' : 'password'}
      placeholder={parameter.placeholder}
      dataTestId={getDataTestIdFormat(`parameter-field-${parameter.field}`)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" sx={{ marginRight: '4px' }}>
            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
              <Icon htmlColor={theme.palette.secondary[300]} name={showPassword ? EIcon.EyeSlashed : EIcon.Eye} />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}

export default PasswordParameter;
