import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('JobBulkDeleteRequest')
export class JobBulkDeleteRequest extends RequestCommon {
  @JsonProperty('job_ids', [Number])
  jobIds: number[];

  @JsonProperty('include_all_jobs', Boolean)
  includeAllJobs: boolean;
}

type TConstructorParams = {
  jobIds: JobBulkDeleteRequest['jobIds'];
  includeAllJobs?: JobBulkDeleteRequest['includeAllJobs'];
};

export function constructJobBulkDeleteRequest({ jobIds, includeAllJobs }: TConstructorParams) {
  const instance = new JobBulkDeleteRequest();
  instance.jobIds = jobIds;
  instance.includeAllJobs = includeAllJobs ?? false;
  return instance;
}
