/* eslint-disable max-len */
function ChipClose() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M13 7L7 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7 7L13 13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default ChipClose;
