import * as React from 'react';
import { useUserLoginStatusCheck } from '../../../data-access/useUserLoginStatusCheck';
import { useLocation } from 'react-router-dom';
import { config } from '../../../config';

export function useCheckLoginSession() {
  const { query: loginStatusQuery, setIsQueryEnabled } = useUserLoginStatusCheck();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname == config.routes.login) {
      setIsQueryEnabled(false);
    }
    // eslint-disable-next-line
  }, [loginStatusQuery.error]);
}
