import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { PlatformInfoRecord } from './PlatformInfoRecord';

@JsonObject('PlatformSummaryResponse')
export class PlatformSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse | undefined;

  @JsonProperty('platform', PlatformInfoRecord)
  platform?: PlatformInfoRecord | undefined;
}
