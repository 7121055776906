/* eslint-disable max-len */
function Home() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.9 17.5H13.1003C12.2167 17.5 11.5004 16.7837 11.5003 15.9001L11.5001 13.1002C11.5001 12.2164 12.2164 11.5 13.1002 11.5L15.9 11.5001C16.7837 11.5001 17.5 12.2165 17.5 13.1001V15.9C17.5 16.7837 16.7837 17.5 15.9 17.5Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1 17.5H6.89968C7.78329 17.5 8.49961 16.7837 8.49968 15.9001L8.49988 13.1002C8.49995 12.2164 7.78356 11.5 6.89984 11.5L4.09996 11.5001C3.21632 11.5001 2.5 12.2165 2.5 13.1001V15.9C2.5 16.7837 3.21635 17.5 4.1 17.5Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9 2.5H13.1004C12.2168 2.5 11.5004 3.21626 11.5004 4.09987L11.5001 6.89983C11.5001 7.78355 12.2164 8.49998 13.1002 8.49996L15.9001 8.49989C16.7837 8.49987 17.5 7.78353 17.5 6.89989V4.1C17.5 3.21634 16.7837 2.5 15.9 2.5Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1 2.5H6.89968C7.78329 2.5 8.49961 3.21627 8.49968 4.09988L8.49988 6.89984C8.49995 7.78356 7.78356 8.49998 6.89984 8.49996L4.09996 8.49989C3.21632 8.49987 2.5 7.78353 2.5 6.89989V4.1C2.5 3.21634 3.21635 2.5 4.1 2.5Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Home;
