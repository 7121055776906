import { Tag } from 'antd';
import styles from './AlertTag.module.css';
import { CustomLabel } from '../../models/job/AdvancedJobSettings';
import { useUtils } from '../../hooks/useUtils';
import CloseIcon from '../CloseIcon';
import React from 'react';

type AlertTypeProps = {
  label: CustomLabel;
  onRemoveHandler?: (labelName: string) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export default function AlertTag({ label, onRemoveHandler }: AlertTypeProps) {
  const { hexToRGBA } = useUtils();

  return (
    <Tag
      className={styles.customTag}
      key={label.id}
      style={{
        backgroundColor: hexToRGBA(label.hexColor, 0.2),
        borderColor: hexToRGBA(label.hexColor, 0.5)
      }}
      closable
      closeIcon={<CloseIcon />}
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin...
      onClose={onRemoveHandler(label.id)}
    >
      <span>{label.name}</span>
    </Tag>
  );
}
