import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AutoRetrainBlockersRecord')
export class AutoRetrainBlockersRecord {
  @JsonProperty('consensus_changes', Boolean, true)
  consensus_changes? = false;

  @JsonProperty('consensus_flipping', Boolean, true)
  consensus_flipping? = false;

  @JsonProperty('consensus_low_score', Boolean, true)
  consensus_low_score? = false;

  @JsonProperty('outliers', Boolean, true)
  outliers? = false;

  @JsonProperty('low_confidence_score', Boolean, true)
  low_confidence_score? = false;

  @JsonProperty('undecided_consensus', Boolean, true)
  undecided_consensus? = false;
}

@JsonObject('AutoRetrainRecord')
export class AutoRetrainRecord {
  @JsonProperty('enabled', Boolean, true)
  enabled?: boolean | null = false;

  @JsonProperty('cron', String, true)
  cron?: string | null = '0 0 * * *';

  @JsonProperty('blockers', AutoRetrainBlockersRecord, true)
  blockers?: AutoRetrainBlockersRecord | null;
}

@JsonObject('SchedulersSettings')
export class SchedulersSettings {
  @JsonProperty('auto_retrain', AutoRetrainRecord, true)
  auto_retrain?: AutoRetrainRecord | null;
}
