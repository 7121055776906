import * as React from 'react';
import { useQuery } from 'react-query';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { fetchJobInfo } from './useJobInfo';

export function useIsJobFeatureSelectionEnabled(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, boolean>(
    [config.api.jobInfo.replace(':id', `${jobId}`)],
    () => fetchJobInfo(jobId, jsonConvert),
    {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      select: (data: JobInfoResponse) => data.info.status.processedDocumentsCount !== 0,
      enabled: Boolean(jobId)
    }
  );
}
