/* eslint-disable max-len */
function Edit01() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.91734 12.0771C1.94797 11.8014 1.96329 11.6636 2.00499 11.5348C2.04199 11.4205 2.09427 11.3117 2.16041 11.2114C2.23495 11.0984 2.33301 11.0003 2.52913 10.8042L11.3333 1.99999C12.0697 1.26361 13.2636 1.26361 14 1.99999C14.7364 2.73637 14.7364 3.93028 14 4.66666L5.1958 13.4708C4.99968 13.667 4.90162 13.765 4.78857 13.8396C4.68828 13.9057 4.57951 13.958 4.46521 13.995C4.33638 14.0367 4.19855 14.052 3.92289 14.0826L1.66665 14.3333L1.91734 12.0771Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Edit01;
