import { useEffect, useState } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { CustomLabel } from '@arcanna/components';
import { FeedbackStatisticsLabelsConsensusStatsRecord } from '../../../../../../shared/models/feedback/FeedbackStatisticsLabelsConsensusStatsRecord';
import { useRetrainLabelsUsed } from '../../../../../../../data-access';
import styles from './styles.module.css';
import { useWindowDimensions } from '../../../../../../shared/hooks/useWindowDimensions';
import { FeedbackSession } from '../../../../../../shared/models/feedback/FeedbackStatisticsLabelsUsedRequest';
import cx from 'classnames';

function chunkArray(
  array: FeedbackStatisticsLabelsConsensusStatsRecord[],
  chunkSize: number
): FeedbackStatisticsLabelsConsensusStatsRecord[][] {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, [] as FeedbackStatisticsLabelsConsensusStatsRecord[][]);
}

type LabelsBreakdownProps = {
  jobId: number;
  feedbackSession: FeedbackSession;
};

export function LabelsBreakdown({ jobId, feedbackSession }: LabelsBreakdownProps) {
  const [displayLabels, setDisplayLabels] = useState<FeedbackStatisticsLabelsConsensusStatsRecord[][]>([]);
  const labelsUsedQuery = useRetrainLabelsUsed(jobId, feedbackSession);

  useEffect(
    function groupLabelsInChunksForColumnsDisplay() {
      if (labelsUsedQuery.data) {
        // @ts-expect-error TS(2345): Argument of type '[FeedbackStatisticsLabelsConsens...
        setDisplayLabels(chunkArray(labelsUsedQuery.data.consensus_stats, 5).slice(0, 100));
      }
    },
    [labelsUsedQuery.data]
  );

  const statsPercentage =
    labelsUsedQuery.data?.consensus_stats?.reduce((result: number, stat: FeedbackStatisticsLabelsConsensusStatsRecord) => {
      return result + (stat.percent ?? 0);
    }, 0) ?? 0;

  const pieData =
    statsPercentage > 0 ? labelsUsedQuery.data?.consensus_stats : [{ id: 'empty', hex_color: 'transparent', percent: 100 }];

  const { width } = useWindowDimensions();
  const maxLabelNameWidth = width > 1600 ? 200 : 100;

  return (
    <div className={styles.labelsBreakdown}>
      <div>
        <PieChart width={140} height={140}>
          {statsPercentage > 0 ? (
            <Pie
              data={pieData}
              innerRadius={55}
              outerRadius={65}
              fill="#fff"
              paddingAngle={statsPercentage > 0 ? 10 : 0}
              dataKey="percent"
              startAngle={90}
              endAngle={450}
              stroke={statsPercentage === 0 ? 'var(--secondary-color-nb-500)' : 'none'}
              strokeWidth={statsPercentage === 0 ? 2 : 0}
              isAnimationActive={false}
            >
              {pieData?.map((label: FeedbackStatisticsLabelsConsensusStatsRecord) => (
                <Cell key={label.id} fill={label.hex_color} />
              ))}
            </Pie>
          ) : (
            <g>
              <circle cx={70} cy={67} r={55} fill="none" stroke="var(--secondary-color-nb-500)" strokeWidth={2} />
              <circle cx={70} cy={67} r={65} fill="none" stroke="var(--secondary-color-nb-500)" strokeWidth={2} />
            </g>
          )}
        </PieChart>
      </div>

      <div className={cx(['w-100 flex', styles.labelsBreakdownList])} style={{ gap: displayLabels.length > 2 ? 16 : 30 }}>
        {displayLabels.map((labels: FeedbackStatisticsLabelsConsensusStatsRecord[], index: number) => (
          <div key={index} className="flex flex-col" style={{ gap: 4 }}>
            {labels.map((label: FeedbackStatisticsLabelsConsensusStatsRecord) => (
              <div key={label.id} className={styles.labelsBreakdownItem}>
                <CustomLabel
                  textStyle={
                    displayLabels.length > 1
                      ? {
                          display: 'block',
                          flex: 1,
                          maxWidth: displayLabels.length === 2 ? 136 : maxLabelNameWidth,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          color: 'var(--secondary-color-nb-100)',
                          fontSize: displayLabels.length === 2 ? 14 : 12
                        }
                      : {
                          color: 'var(--secondary-color-nb-100)'
                        }
                  }
                  // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
                  hexColor={label.hex_color}
                  name={label.name}
                />
                <span
                  className={cx(['color-nb-100', styles.labelsBreakdownItemPercent])}
                  style={{ fontSize: displayLabels.length == 2 ? 14 : 12 }}
                >
                  {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                  {label.count} ({label.percent.toFixed(2)}%)
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LabelsBreakdown;
