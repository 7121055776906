import { staticRoutes } from './StaticRoutes';
import { HomepageType, UseThemeType } from '../theme.hook';
import { RouteType } from '../../components/shared/models/route/RouteType';

export const useTheme = (): UseThemeType => {
  const getStaticRoutes = (): Array<RouteType> => staticRoutes;
  const getMetaTitle = (): string => 'Arcanna.ai' + ' | Using artificial intelligence to unlock greater human potential';
  const getMetaDescription = (): string => 'Arcanna.ai' + ' | Using artificial intelligence to unlock greater human potential';
  const getHomepageType = (): HomepageType => 'jobs';
  return {
    getStaticRoutes,
    getMetaTitle,
    getMetaDescription,
    getHomepageType
  };
};
