import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { JobFeatureSelectionSummaryDetails } from './JobFeatureSelectionSummaryDetails';

@JsonObject('JobFeatureSelectionSummaryResponse')
export class JobFeatureSelectionSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('features', [JobFeatureSelectionSummaryDetails])
  features?: JobFeatureSelectionSummaryDetails[] = [];
}
