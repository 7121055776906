export const permissions = {
  activityRead: 'ACTIVITY_READ',
  categoryRead: 'CATEGORY_READ',
  subcategoryRead: 'SUBCATEGORY_READ',
  chartsRead: 'CHARTS_READ',
  homeRead: 'HOME_READ',
  parameterRead: 'PARAMETER_READ',
  queryRead: 'QUERY_READ',
  uiAccessRead: 'UI_ACCESS_READ',
  useCaseRead: 'USE_CASE_READ',
  csvRead: 'CSV_READ',
  csvUpdate: 'CSV_UPDATE',
  csvDelete: 'CSV_DELETE',
  eventExplorerRead: 'EVENT_EXPLORER_READ',
  exportRead: 'EXPORT_READ',
  exportWrite: 'EXPORT_WRITE',
  exportUpdate: 'EXPORT_UPDATE',
  exportDelete: 'EXPORT_DELETE',
  featureSelectionRead: 'FEATURE_SELECTION_READ',
  featureSelectionUpdate: 'FEATURE_SELECTION_UPDATE',
  feedbackRead: 'FEEDBACK_READ',
  feedbackUpdate: 'FEEDBACK_UPDATE',
  feedbackOverwriteUpdate: 'FEEDBACK_OVERWRITE_UPDATE',
  feedbackStatistics: 'FEEDBACK_STATISTICS',
  integrationRead: 'INTEGRATION_READ',
  integrationWrite: 'INTEGRATION_WRITE',
  integrationUpdate: 'INTEGRATION_UPDATE',
  integrationDelete: 'INTEGRATION_DELETE',
  jobRead: 'JOB_READ',
  jobWrite: 'JOB_WRITE',
  jobUpdate: 'JOB_UPDATE',
  jobDelete: 'JOB_DELETE',
  jobAction: 'JOB_ACTION',
  licenseRead: 'LICENSE_READ',
  licenseWrite: 'LICENSE_WRITE',
  licenseUpdate: 'LICENSE_UPDATE',
  reportRead: 'REPORT_READ',
  reportWrite: 'REPORT_WRITE',
  retrainRead: 'RETRAIN_READ',
  retrainUpdate: 'RETRAIN_UPDATE',
  userRead: 'USER_READ',
  userWrite: 'USER_WRITE',
  userUpdate: 'USER_UPDATE',
  userDelete: 'USER_DELETE',
  userChangePassword: 'USER_CHANGE_PASSWORD',
  userKeyRead: 'USER_KEY_READ',
  userKeyWrite: 'USER_KEY_WRITE',
  userKeyDelete: 'USER_KEY_DELETE',
  kibanaLogin: 'KIBANA_LOGIN',
  action: {
    hiddenAction: 'hidden',
    disabledAction: 'disabled'
  }
};
