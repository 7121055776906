import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { BucketsBreakdown } from '../retrain-statistics/BucketsBreakdown';
import { JobTrainingStatusBreakdownEntry } from '../job/JobTrainingStatusBreakdownEntry';

@JsonObject('DecisionBreakdown')
export class DecisionBreakdown {
  @JsonProperty('events', Number)
  events?: number;

  @JsonProperty('buckets', Number)
  buckets?: number;

  @JsonProperty('label', String)
  label?: string;

  constructor(events: number, buckets: number, label: string) {
    this.events = events;
    this.buckets = buckets;
    this.label = label;
  }
}

@JsonObject('ModelHistory')
export class ModelHistory {
  @JsonProperty('model_id', String)
  modelId?: string;

  @JsonProperty('model_path', String)
  modelPath?: string;

  @JsonProperty('version_id', String)
  versionId?: string;

  @JsonProperty('train_date', String)
  trainDate?: string;

  @JsonProperty('trained_by', String)
  trainedBy?: string;

  @JsonProperty('kb_accuracy_percent', Number)
  kbAccuracyPercent?: number;

  @JsonProperty('total_buckets', Number)
  totalBuckets: number;

  @JsonProperty('buckets_breakdown', [BucketsBreakdown])
  bucketsBreakdown?: BucketsBreakdown[];

  @JsonProperty('is_revertible', Boolean)
  isRevertible?: boolean;

  @JsonProperty('feedback_session_accuracy', Number)
  feedbackSessionAccuracy?: number;

  @JsonProperty('feedback_session_f_score_mean', Number)
  feedbackSessionFScoreMean?: number;

  @JsonProperty('f_score_weighted_average', Number)
  feedbackSessionFScoreWeightedAverage?: number;

  @JsonProperty('events_in_kb', Number)
  eventsInKb?: number;

  @JsonProperty('feedback_labels', Number)
  feedbackLabels?: number;

  @JsonProperty('decision_breakdown', [DecisionBreakdown])
  decisionBreakdown?: DecisionBreakdown[];

  @JsonProperty('training_status_breakdown', [JobTrainingStatusBreakdownEntry])
  trainingStatusBreakdown?: JobTrainingStatusBreakdownEntry[] = undefined;
}

@JsonObject('JobRetrainHistoryResponse')
export class JobAllRetrainHistoryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('models', [ModelHistory])
  models?: ModelHistory[];
}
