import { useEffect, useState } from 'react';
import { Tooltip, Typography } from 'antd';
import moment from 'moment';
import styles from './ModelsHistoryTable.module.css';
import cx from 'classnames';
import { formatNumber } from '../../../../../../utils/numeral';
import { useJobInfo } from '../../../../../../data-access';
import { ColumnsType } from 'antd/es/table';
import { useJobAllRetrainHistory } from '../../../../../../data-access';
import { useTranslation } from 'react-i18next';
import { ModelHistory } from '../../../../../shared/models/retrain-history/JobAllRetrainHistoryResponse';
import useRollbackInProgress from './useRollbackInProgress';
import RetrainHistoryOptions from './RetrainHistoryOptions';

export default function useModelsHistoryTable({ jobId }: { jobId: number }) {
  const modelsHistoryQuery = useJobAllRetrainHistory(jobId);
  const jobInfoQuery = useJobInfo(jobId);
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const currentModelInUse = jobInfoQuery.data?.info.processor.lastModelPath?.split('.')[0];
  const { isRollbackInProgress } = useRollbackInProgress({ jobId });

  const isModelRevertible = (modelId: string) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return modelsHistoryQuery.data.models.find((model) => model.modelId === modelId)?.isRevertible;
  };

  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation(['overview']);
  const columns: ColumnsType<ModelHistory> = [
    {
      key: 'model_id',
      title: t('versionHash'),
      dataIndex: 'modelId',
      width: 380,
      sorter: (a, b) => {
        const modelIdA = a.modelId;
        const modelIdB = b.modelId;
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return modelIdB.localeCompare(modelIdA);
      },
      render: (modelId: string) => {
        return (
          <div className={styles.modelIdContainer}>
            <Tooltip title={!isModelRevertible(modelId) ? t('modelNotAvailable') : ''}>
              {currentModelInUse === modelId ? (
                <Tooltip title={t('modelUsedInProduction')}>
                  <div className={styles.currentModelInUseMarker} data-test-id="history-model-used-in-production" />
                </Tooltip>
              ) : null}
              <Typography.Text className={cx(styles.ellipsisText, styles.tableColumnsValueText)} data-test-id="history-model-id">
                {modelId}
              </Typography.Text>
            </Tooltip>
          </div>
        );
      }
    },
    {
      key: 'train_date',
      title: t('trainedOn'),
      dataIndex: 'trainDate',
      defaultSortOrder: 'descend',
      sorter: (a: ModelHistory, b: ModelHistory) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        const dateA = new Date(a.trainDate);
        // @ts-expect-error TS(2769): No overload matches this call.
        const dateB = new Date(b.trainDate);
        return dateA.getTime() - dateB.getTime();
      },
      render: (date: string, model: ModelHistory) => {
        return (
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
          <Tooltip title={!isModelRevertible(model.modelId) ? t('modelNotAvailable') : ''}>
            <Typography.Text className={styles.tableColumnsValueText} data-test-id="history-train-date">
              {moment(date).format('MMM DD, YYYY HH:mm')}
            </Typography.Text>
          </Tooltip>
        );
      }
    },
    {
      key: 'accuracy',
      title: t('accuracy'),
      dataIndex: 'feedbackSessionAccuracy',
      width: 112,
      sorter: (a: ModelHistory, b: ModelHistory) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return b.feedbackSessionAccuracy - a.feedbackSessionAccuracy;
      },
      render: (value: number, model: ModelHistory) => (
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        <Tooltip title={!isModelRevertible(model.modelId) ? t('modelNotAvailable') : ''}>
          {value === undefined || value === null ? (
            'n/a'
          ) : (
            <Typography.Text className={styles.tableColumnsValueText} data-test-id="history-accuracy">
              {formatNumber(value, 2)}%
            </Typography.Text>
          )}
        </Tooltip>
      )
    },
    {
      key: 'f_score',
      title: t('fScore'),
      dataIndex: 'feedbackSessionFScoreWeightedAverage',
      width: 112,
      sorter: (a: ModelHistory, b: ModelHistory) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return b.feedbackSessionFScoreMean - a.feedbackSessionFScoreMean;
      },
      render: (value: number, model: ModelHistory) => (
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        <Tooltip title={!isModelRevertible(model.modelId) ? t('modelNotAvailable') : ''}>
          {value === undefined || value === null ? (
            'n/a'
          ) : (
            <Typography.Text className={styles.tableColumnsValueText} data-test-id="history-f-score">
              {formatNumber(value, 2)}%
            </Typography.Text>
          )}
        </Tooltip>
      )
    },
    {
      key: 'options',
      width: 50,
      dataIndex: 'modelId',
      render: (modelId: string, model: ModelHistory) => (
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        <Tooltip title={!isModelRevertible(model.modelId) ? t('modelNotAvailable') : ''}>
          {!isModelRevertible(modelId) ? null : (
            // @ts-expect-error TS(2322): Type 'boolean | undefined' is not assignable to ty...
            <RetrainHistoryOptions modelId={modelId} jobId={jobId} isModelRevertible={isModelRevertible(modelId)} />
          )}
        </Tooltip>
      )
    }
  ];

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'ModelHistory[] | undefined' is n...
    setTableData(modelsHistoryQuery.data?.models);
  }, [modelsHistoryQuery.data]);

  return {
    columns,
    tableData,
    isRollbackInProgress
  };
}
