/* eslint-disable max-len */
function Python() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <g clipPath="url(#clip0_22098_5491)">
        <path
          d="M7.96329 0.00463867C3.8954 0.00463867 4.14939 1.76872 4.14939 1.76872L4.15391 3.59636H8.03583V4.14506H2.61208C2.61208 4.14506 0.0090332 3.84985 0.0090332 7.95432C0.0090332 12.0589 2.28103 11.9133 2.28103 11.9133H3.63695V10.0087C3.63695 10.0087 3.56386 7.73666 5.87269 7.73666H9.72285C9.72285 7.73666 11.886 7.7716 11.886 5.64605V2.13157C11.886 2.13157 12.2145 0.00463867 7.96329 0.00463867ZM5.82281 1.23369C5.91454 1.23361 6.00539 1.25161 6.09015 1.28668C6.17492 1.32175 6.25194 1.37318 6.3168 1.43805C6.38166 1.50291 6.4331 1.57993 6.46817 1.6647C6.50323 1.74946 6.52124 1.84031 6.52116 1.93204C6.52124 2.02377 6.50323 2.11462 6.46817 2.19939C6.4331 2.28415 6.38166 2.36117 6.3168 2.42604C6.25194 2.4909 6.17492 2.54234 6.09015 2.5774C6.00539 2.61247 5.91454 2.63048 5.82281 2.6304C5.73107 2.63048 5.64022 2.61247 5.55546 2.5774C5.47069 2.54234 5.39368 2.4909 5.32881 2.42604C5.26395 2.36117 5.21251 2.28415 5.17744 2.19939C5.14238 2.11462 5.12437 2.02377 5.12445 1.93204C5.12437 1.84031 5.14238 1.74946 5.17744 1.6647C5.21251 1.57993 5.26395 1.50291 5.32881 1.43805C5.39368 1.37318 5.47069 1.32175 5.55546 1.28668C5.64022 1.25161 5.73107 1.23361 5.82281 1.23369Z"
          fill="url(#paint0_linear_22098_5491)"
        />
        <path
          d="M8.07879 15.9452C12.1467 15.9452 11.8927 14.1812 11.8927 14.1812L11.8882 12.3536H8.0062V11.8049H13.4299C13.4299 11.8049 16.033 12.1001 16.033 7.99549C16.033 3.89096 13.761 4.03653 13.761 4.03653H12.4051V5.94115C12.4051 5.94115 12.4782 8.21315 10.1693 8.21315H6.31917C6.31917 8.21315 4.15603 8.17821 4.15603 10.3038V13.8184C4.15603 13.8184 3.82756 15.9452 8.07873 15.9452H8.07879ZM10.2193 14.7163C10.1275 14.7164 10.0367 14.6984 9.95193 14.6633C9.86717 14.6283 9.79015 14.5768 9.72528 14.5119C9.66042 14.4471 9.60898 14.3701 9.57392 14.2853C9.53885 14.2005 9.52084 14.1097 9.52093 14.018C9.52083 13.9262 9.53884 13.8354 9.5739 13.7506C9.60896 13.6658 9.6604 13.5888 9.72526 13.5239C9.79013 13.459 9.86715 13.4076 9.95192 13.3725C10.0367 13.3375 10.1275 13.3195 10.2193 13.3195C10.311 13.3195 10.4019 13.3375 10.4866 13.3725C10.5714 13.4076 10.6484 13.459 10.7133 13.5239C10.7781 13.5888 10.8296 13.6658 10.8646 13.7505C10.8997 13.8353 10.9177 13.9262 10.9176 14.0179C10.9177 14.1096 10.8997 14.2005 10.8646 14.2852C10.8296 14.37 10.7781 14.447 10.7133 14.5119C10.6484 14.5768 10.5714 14.6282 10.4866 14.6633C10.4019 14.6983 10.311 14.7164 10.2193 14.7163Z"
          fill="url(#paint1_linear_22098_5491)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_22098_5491"
          x1="153.999"
          y1="143.381"
          x2="948.098"
          y2="929.558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#387EB8" />
          <stop offset="1" stopColor="#366994" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_22098_5491"
          x1="231.445"
          y1="249.118"
          x2="1084.22"
          y2="1055.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE052" />
          <stop offset="1" stopColor="#FFC331" />
        </linearGradient>
        <clipPath id="clip0_22098_5491">
          <rect width="16.0643" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Python;
