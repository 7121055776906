import { Box, BoxProps, styled } from '@mui/material';

const StyledIntegrationImage = styled(Box)<BoxProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary[600]}`,
  height: '60px',
  width: '60px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export default StyledIntegrationImage;
