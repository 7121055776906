export default function CaretDown() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        /* eslint-disable-next-line max-len */
        d="M5.61074 7.69463L3.12903 4.85794C2.83615 4.52418 3.07418 4 3.51867 4H8.4821C8.58158 3.99991 8.67897 4.02851 8.76262 4.08235C8.84627 4.13619 8.91262 4.213 8.95373 4.30359C8.99485 4.39417 9.00898 4.49469 8.99444 4.5931C8.9799 4.6915 8.9373 4.78364 8.87174 4.85846L6.39003 7.69411C6.34146 7.74969 6.28156 7.79424 6.21436 7.82476C6.14715 7.85528 6.0742 7.87107 6.00039 7.87107C5.92658 7.87107 5.85362 7.85528 5.78641 7.82476C5.71921 7.79424 5.65931 7.74969 5.61074 7.69411V7.69463Z"
        fill="currentColor"
      />
    </svg>
  );
}
