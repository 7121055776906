import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { Piechart, PiechartProps } from '../Visualisation/Piechart';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { useVisualizationsContext } from './Visualizations.context';
import { getJsonConvert } from '../../utilities/json-convert';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { useUtils } from '../../hooks/useUtils';

export function QueryPiechart(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const [pieData, setPieData] = useState<PiechartProps>(undefined);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const utils = useUtils();

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    let slices: Array<any> = [];
    for (const key in aggregations) {
      slices = slices.concat(aggregations[key].buckets);
    }

    // eslint-disable-next-line
    const data: Array<any> = [];
    slices.forEach((slice) => {
      let element = null;
      if (slice.slice_count) {
        element = { name: slice.key, value: slice.slice_count.value };
      } else {
        element = { name: slice.key, value: slice.doc_count };
      }
      data.push(element);
    });

    const colors = utils.nColorArray(data.length, visualizationRecord.style['color-seed']);

    setPieData({
      data,
      colors,
      filterFields: VisualizationRecord.getFilterFieldsFromQuery(visualizationRecord.query, [])
    });
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <p>&nbsp;</p>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        <Piechart key={props.name + Math.random()} {...pieData} />
      </Spin>
    </>
  );
}
