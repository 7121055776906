import { Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../shared/components/Button/Button';
import { useJobInfo } from '../../../../../../data-access';
import { useJobOverviewContext } from '../JobOverview.context';
import style from './JobOverviewMetrics.module.css';
import { EditIconClosed } from '../../../../../../themes/icons';
import TimeMetric from './TimeMetric';

type TimeMetricsInfoProps = {
  jobId: number;
};

export default function TimeMetricsInfo({ jobId }: TimeMetricsInfoProps) {
  const { t } = useTranslation(['job']);
  const jobInfoQuery = useJobInfo(+jobId);
  const jobTimeMetrics = jobInfoQuery.data?.info?.metrics;
  const { setEditTimeMetrics } = useJobOverviewContext();

  return (
    <>
      <Row className="p-l-0">
        <Typography.Text className="body-2 color-nb-100">{t('job:jobTimeMetrics:title')}</Typography.Text>
      </Row>
      <TimeMetric
        text={t('job:jobTimeMetrics:averageTimeMessage')}
        tooltipText={t('job:jobTimeMetrics:averageTimeMessageInfo')}
        customClassName="p-t-8"
      />
      <Row>
        <Space size={2} align="baseline">
          <span className={style.metricsNumber}>{jobTimeMetrics?.time_value}</span>
          <Typography.Text type="secondary" className="color-nb-300 caption">
            {`${jobTimeMetrics?.time_units} / event`}
          </Typography.Text>
          <Button
            style={{ border: 'none', padding: 0, marginLeft: 6 }}
            type="tertiaryNew"
            size="small"
            onClick={() => setEditTimeMetrics(true)}
            icon={<EditIconClosed />}
            title="Edit time metrics"
          />
        </Space>
      </Row>
    </>
  );
}
