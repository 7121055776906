import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  TooltipProps
} from 'recharts';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import { FilterProperty } from './FilterProps';
import { LegendTooltip, useLegendTooltipHook } from '../Tooltips/LegendTooltip';
import { useUtils } from '../../hooks/useUtils';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

export interface BarChartProps {
  // eslint-disable-next-line
  data: Array<any>;
  keys: Array<string>;
  colors: Array<string>;
  // eslint-disable-next-line
  style: any;
  filterFields: Array<string>;
  tooltips: { [id: string]: string };
}

export function Barchart(props: BarChartProps) {
  const visualizationContext = useVisualizationsContext();
  const { handleMouseEnter, handleMouseLeave } = useLegendTooltipHook();
  const { overrideOwnStyleAttributes } = useUtils();

  const ownStyleAttributes = {
    height: '300px'
  };

  // This is the tooltip for hovering the actual bar elements
  const tooltipContent = (tooltipProps: TooltipProps<ValueType, NameType>) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (tooltipProps.payload.length > 0) {
      return (
        <div className="recharts-default-tooltip">
          <div style={{ padding: '10px' }}>
            <p>
              {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
              {tooltipProps.label} : {tooltipProps.payload[0].value}
            </p>
            <p>{props.tooltips[tooltipProps.label]}</p>
          </div>
        </div>
      );
    }
    return <></>;
  };

  // eslint-disable-next-line
  const elementClicked = (el: any) => {
    const value = el.name != null ? el.name : el.value;
    const filterProps: FilterProperty = {
      key: props.filterFields[0],
      source: props.filterFields[0],
      values: [],
      selected: value
    };

    visualizationContext.appendClickableFilters(filterProps);
    visualizationContext.updateApplyTrigger();
  };

  /*
   * Formats the names displayed by the barchart on the X or Y axis.
   * This is useful for vertical barcharts.
   * It will avoid name overlapping in case they are too long. */
  // eslint-disable-next-line
  const tickFormatter = (value: any) => {
    let trimmedValue = value;
    if (value.length > 22) {
      const words = value.split(' ');
      if (words.length > 2) {
        trimmedValue = `${words[0]} ${words[1]}..`;
      } else {
        trimmedValue = `${value.substr(0, 20)}..`;
      }
    }
    return trimmedValue;
  };

  if (props.data != null) {
    overrideOwnStyleAttributes(ownStyleAttributes, props);

    if (props.style.orientation === 'vertical') {
      return (
        <div style={{ height: ownStyleAttributes.height }}>
          {/* This is the tooltip for hovering the legend elements */}
          <LegendTooltip {...{ values: props.tooltips }} />
          <ResponsiveContainer width="99.8%" height="99.8%">
            <BarChart
              width={280}
              height={300}
              data={props.data}
              layout={props.style.orientation}
              stackOffset="sign"
              margin={{
                top: 5,
                right: 30,
                left: 75,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis
                type="category"
                dataKey="name"
                tickFormatter={tickFormatter}
                interval={0}
                onClick={elementClicked}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
              {Object.keys(props.tooltips).length > 0 && <Tooltip content={tooltipContent} />}
              {Object.keys(props.tooltips).length === 0 && <Tooltip />}
              <ReferenceLine y={0} stroke="#000" />
              {props.colors.map((entry, index) => (
                <Bar
                  key={`bar-${index}`}
                  dataKey={`${props.keys[index]}`}
                  fill={`${entry}`}
                  stackId="stack"
                  onClick={elementClicked}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      );
    }

    return (
      <div style={{ height: ownStyleAttributes.height }}>
        <ResponsiveContainer width="99.8%" height="99.8%">
          <BarChart
            width={300}
            height={300}
            data={props.data}
            stackOffset="sign"
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tickFormatter={tickFormatter} />
            <YAxis />
            {Object.keys(props.tooltips).length > 0 && <Tooltip content={tooltipContent} />}
            {Object.keys(props.tooltips).length === 0 && <Tooltip />}
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            {props.colors.map((entry, index) => (
              <Bar key={`bar-${index}`} dataKey={`${props.keys[index]}`} fill={`${entry}`} stackId="stack" />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return <></>;
}
