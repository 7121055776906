import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';
import { JobMetricsResponse } from '../../components/shared/models/retrain-history/JobMetricsResponse';
import { JobMetricsRequest } from '../../components/shared/models/retrain-history/JobMetricsRequest';

export function useJobMetrics(jobId: number, modelId: string | undefined) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobMetricsResponse, Error>(
    [config.api.jobRetrainMetrics.replace(':jobId', jobId.toString()), modelId],
    () =>
      axios
        .post<{
          resource: JobMetricsResponse;
          // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
        }>(config.api.jobRetrainMetrics.replace(':jobId', jobId.toString()), new JobMetricsRequest(modelId))
        .then((responseData: AxiosResponse<{ resource: JobMetricsResponse }>) => {
          return jsonConvert.deserializeObject(responseData.data.resource, JobMetricsResponse);
        }),
    {
      enabled: Boolean(jobId) && Boolean(modelId)
    }
  );
}
