import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { ActionCategory } from './ActionCategory';

@JsonObject('ActionCategoriesResponse')
export class ActionCategoriesResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('categories', [ActionCategory])
  categories: ActionCategory[];
}
