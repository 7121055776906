import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMutation } from 'react-query';
import { IdeaBoxRequest } from 'src/components/shared/models/idea-box/IdeaBoxRequest';
import { config } from 'src/config';
import { showErrorNotification, showSuccessNotification } from 'src/components/shared/utilities/notification';

function useIdeaBoxMutation() {
  const { t } = useTranslation(['make-a-wish']);

  return useMutation((payload: IdeaBoxRequest) => axios.post(config.api.makeAWish, payload), {
    onSuccess: () => {
      showSuccessNotification(t('make-a-wish:successMessage'), t('make-a-wish:successDescription'));
    },
    onError: () => {
      showErrorNotification(t('make-a-wish:errorMessage'), t('make-a-wish:genericErrorDescription'));
    }
  });
}

export default useIdeaBoxMutation;
