import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { CustomFieldSummaryRequest, CustomFieldSummaryResponse } from '../../../components/shared/models/custom-field';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { config } from '../../../config';

type UseCustomFieldSummary = {
  jobId: number;
  name: string;
};
export function useCustomFieldSummary({ jobId, name }: UseCustomFieldSummary) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const requestPayload = new CustomFieldSummaryRequest(jobId, name);

  return useQuery(
    [config.api.summaryCustomField, requestPayload],
    () =>
      axios
        .post<{ resource: CustomFieldSummaryResponse }>(config.api.summaryCustomField, requestPayload)
        .then((responseData: AxiosResponse<{ resource: CustomFieldSummaryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, CustomFieldSummaryResponse)
        ),
    {
      enabled: Boolean(jobId && name)
    }
  );
}
