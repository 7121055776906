import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { EventExplorerFiltersFieldsRecord } from './EventExplorerFiltersFieldsRecord';

@JsonObject('EventExplorerFiltersFieldsResponse')
export class EventExplorerFiltersFieldsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse | undefined;

  @JsonProperty('fields_list', [EventExplorerFiltersFieldsRecord])
  fields_list?: EventExplorerFiltersFieldsRecord[] = [];
}
