import { useJobInfo } from '../../../../../../data-access';
import { useJobOverviewContext } from '../JobOverview.context';
import TimeMetricsEmptyStateNoMetrics from './TimeMetricsEmptyStateNoMetrics';
import TimeMetricsEdit from './TimeMetricsEdit';
import TimeMetricsFeedbackNeeded from './TimeMetricsFeedbackNeeded';
import TimeMetricsMultipleTrains from './TimeMetricsMultipleTrains';

type JobOverviewTimeMetricsProps = {
  jobId: number;
};

export default function JobTimeMetrics({ jobId }: JobOverviewTimeMetricsProps) {
  const jobInfoQuery = useJobInfo(+jobId);
  const { state: jobOverviewState } = useJobOverviewContext();

  const trainModelsAvailable = jobInfoQuery.data?.info?.trained_models_count;
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const hasAtLeastOneTrain = trainModelsAvailable >= 1;
  const jobTimeMetrics = jobInfoQuery.data?.info?.metrics;
  const noTimeMetrics = jobTimeMetrics?.time_value === null;

  if (noTimeMetrics && jobOverviewState.editTimeMetrics === false) {
    return <TimeMetricsEmptyStateNoMetrics jobId={jobId} />;
  } else if (jobOverviewState.editTimeMetrics === true) {
    return <TimeMetricsEdit jobId={jobId} jobTimeMetrics={jobTimeMetrics} />;
  } else if (noTimeMetrics === false && hasAtLeastOneTrain === false) {
    return <TimeMetricsFeedbackNeeded jobId={jobId} />;
  } else {
    return <TimeMetricsMultipleTrains jobId={jobId} />;
  }
}
