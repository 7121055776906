import { useTranslation } from 'react-i18next';
import { EIcon, Icon, StateChip } from '@arcanna/generic';
import { Stack, Tooltip, useTheme } from '@mui/material';

type JobRetrainStateErrorTagProps = {
  message?: string;
};

export function JobRetrainStateErrorTag({ message }: JobRetrainStateErrorTagProps) {
  const { t } = useTranslation('job');
  const theme = useTheme();

  return (
    <>
      <StateChip color={theme.palette.error[600]} text={t('job:jobRetrainState.retrainError')} />
      <Tooltip title={message} placement="top" arrow>
        <Stack>
          <Icon htmlColor={theme.palette.error[700]} name={EIcon.AlertTriangle} />
        </Stack>
      </Tooltip>
    </>
  );
}
