import { SVGProps } from 'react';

export type RocketIconProps = SVGProps<SVGSVGElement>;

export default function RocketIcon({ ...props }: RocketIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_16493_1605)">
        <path
          // eslint-disable-next-line max-len
          d="M7.99996 9.99984L5.99996 7.99984M7.99996 9.99984C8.93119 9.64567 9.82455 9.19899 10.6666 8.66651M7.99996 9.99984V13.3332C7.99996 13.3332 10.02 12.9665 10.6666 11.9998C11.3866 10.9198 10.6666 8.66651 10.6666 8.66651M5.99996 7.99984C6.35472 7.07946 6.80143 6.19722 7.33329 5.36651C8.11008 4.1245 9.19171 3.10187 10.4753 2.3959C11.7589 1.68993 13.2017 1.32409 14.6666 1.33317C14.6666 3.14651 14.1466 6.33317 10.6666 8.66651M5.99996 7.99984H2.66663C2.66663 7.99984 3.03329 5.97984 3.99996 5.33317C5.07996 4.61317 7.33329 5.33317 7.33329 5.33317M2.99996 10.9998C1.99996 11.8398 1.66663 14.3332 1.66663 14.3332C1.66663 14.3332 4.15996 13.9998 4.99996 12.9998C5.47329 12.4398 5.46663 11.5798 4.93996 11.0598C4.68083 10.8125 4.33949 10.6696 3.98144 10.6585C3.6234 10.6475 3.27388 10.769 2.99996 10.9998Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16493_1605">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
