import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TimeIntervalRecord')
export class TimeIntervalRecord {
  @JsonProperty('intervalStart', Date, true)
  intervalStart?: Date | undefined;

  @JsonProperty('intervalEnd', Date, true)
  intervalEnd?: Date | undefined;
}
