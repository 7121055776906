import StyledModal from './StyledModal.styles';
import { Box, Button, ModalProps, Stack, Typography, useTheme } from '@mui/material';
import { Icon, EIcon } from '@arcanna/generic';
import * as React from 'react';
import { useCallback } from 'react';

type TModalProps = ModalProps & {
  open: boolean;
  onClose: () => void;
  titleNode?: React.ReactElement;
  title?: string;
  width?: number;
  footer?: React.ReactElement;
};

export default function Modal({ children, title, titleNode, open, onClose, width, footer, ...otherProps }: TModalProps) {
  const theme = useTheme();

  const modalHeaderTitle = useCallback(() => {
    if (titleNode) return titleNode;
    if (title) return <Typography variant={'h4'}>{title}</Typography>;
    return null;
  }, [title, titleNode]);

  return (
    <StyledModal open={open} onClose={onClose} {...otherProps}>
      <Stack className="container" width={width ? width : undefined}>
        <Box className="modalHeader">
          <Stack direction="row" alignItems="center">
            <Button color="secondary" variant="tertiary" size="small" className="closeButton" onClick={onClose}>
              <Icon name={EIcon.Close} htmlColor={theme.palette.secondary[400]} fontSize="small" />
            </Button>
            {modalHeaderTitle()}
          </Stack>
        </Box>
        <Box className="modalBody" sx={{ position: 'relative' }}>
          {children}
        </Box>
        {footer ? <Box className="modalFooter">{footer}</Box> : null}
      </Stack>
    </StyledModal>
  );
}
