/* eslint-disable max-len */
function ActionSearch() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70005 10.5008C4.70005 7.29753 7.2968 4.70078 10.5 4.70078C13.7033 4.70078 16.3 7.29753 16.3 10.5008C16.3 13.704 13.7033 16.3008 10.5 16.3008C7.2968 16.3008 4.70005 13.704 4.70005 10.5008ZM10.5 3.30078C6.5236 3.30078 3.30005 6.52433 3.30005 10.5008C3.30005 14.4772 6.5236 17.7008 10.5 17.7008C12.2362 17.7008 13.8288 17.0863 15.0723 16.063L18.5051 19.4958C18.7784 19.7691 19.2217 19.7691 19.495 19.4958C19.7684 19.2224 19.7684 18.7792 19.495 18.5058L16.0622 15.073C17.0856 13.8295 17.7 12.2369 17.7 10.5008C17.7 6.52433 14.4765 3.30078 10.5 3.30078Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ActionSearch;
