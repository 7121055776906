import { ReactNode, useCallback, useMemo, useState } from 'react';
import { JobFlowsContext, initialJobFlowsContext } from './JobFlowsContext';
import { TJobFlowsContext, TJobFlowsState } from './JobFlowsContext.type';
import { Integration } from 'src/components/pages/Main/Jobs/components/JobDraft/JobDraftReducer';
import { CodeBlockGetResponse } from '@arcanna/models/CodeBlock';
import _ from 'lodash';
import { matchPath, Prompt } from 'react-router-dom';
import { config } from 'src/config';
import { useTranslation } from 'react-i18next';
import { getNewIntegrationIndex } from '../FlowList/hooks/newIntegrationIndex';

type TJobFlowsProviderProps = {
  children: ReactNode;
};

/*
  This context it's used on App level at this point because we need to keep some state
  variables when switching between routes:
  - job flows -> context enrichments -> back to job flows
  - job flows -> code blocks -> back to job flows
  We need to to this because when switching between this pages we are not saving the
  changed integrations server-side. We are only saving them after going back to job flows
  and pressing 'Save'.
 */
function JobFlowsContextProvider({ children }: TJobFlowsProviderProps) {
  const [state, setState] = useState<TJobFlowsState>(initialJobFlowsContext.state);
  const { t } = useTranslation('common');

  const setJobId = useCallback((jobId: number | undefined) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      jobId
    }));
  }, []);

  const setJobCategory = useCallback((jobCategory: number) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      jobCategory
    }));
  }, []);

  const setEditIndex = useCallback((editIndex: number | undefined) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      editIndex
    }));
  }, []);

  const setIntegrations = useCallback((integrations: Integration[]) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      integrations
    }));
  }, []);

  const setInitialIntegrations = useCallback((initialIntegrations: Integration[]) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      initialIntegrations
    }));
  }, []);

  const addIntegration = useCallback((integration: Integration) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      integrations: [...current.integrations, integration]
    }));
  }, []);

  const setEnabledIntegrations = useCallback((enabledIntegrations: boolean[]) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      enabledIntegrations
    }));
  }, []);

  const setInitialEnabledIntegrations = useCallback((initialEnabledIntegrations: boolean[]) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      initialEnabledIntegrations
    }));
  }, []);

  const setEnabledIntegrationNextToLast = useCallback(
    (enabledIntegration: boolean) => {
      setEnabledIntegrations([
        ...state.enabledIntegrations.slice(0, -1),
        enabledIntegration,
        state.enabledIntegrations.at(-1) || true
      ]);
    },
    [state.enabledIntegrations, setEnabledIntegrations]
  );

  const setEnabledIntegrationByIndex = useCallback(
    (index: number, enabledIntegration: boolean) => {
      const secondSliceStartIndex = state.editIndex ? state.editIndex + 1 : index;
      const enabledIntegrations = [
        ...state.enabledIntegrations.slice(0, index),
        enabledIntegration,
        ...state.enabledIntegrations.slice(secondSliceStartIndex)
      ];
      setEnabledIntegrations(enabledIntegrations);
    },
    [state.editIndex, state.enabledIntegrations, setEnabledIntegrations]
  );

  const addDraggableIntegration = useCallback((integration: Integration) => {
    setState((current: TJobFlowsState) => {
      const currentIntegrationsCount = current.integrations.length;
      const firstItems = [...current.integrations].slice(0, currentIntegrationsCount - 1);
      const lastItem = current.integrations[currentIntegrationsCount - 1];

      return {
        ...current,
        integrations: [...firstItems, integration, lastItem],
        enabledIntegrations: [...current.enabledIntegrations, true]
      };
    });
  }, []);

  const setDraggableIntegration = useCallback(
    (index: number | undefined, integration: Integration) => {
      if (index !== undefined) {
        const firstSliceEndIndex = state.editIndex ? state.editIndex : index;
        const secondSliceStartIndex = state.editIndex ? state.editIndex + 1 : index;
        setState((current: TJobFlowsState) => ({
          ...current,
          integrations: [
            ...current.integrations.slice(0, firstSliceEndIndex),
            integration,
            ...current.integrations.slice(secondSliceStartIndex)
          ]
        }));
      } else {
        addDraggableIntegration(integration);
      }
    },
    [state.editIndex, addDraggableIntegration]
  );

  const setIntegrationOnEditEnd = useCallback(
    (integration: Integration, enabled: boolean) => {
      const defaultIndex = getNewIntegrationIndex(integration.integrationType || '', state.integrations);
      setDraggableIntegration(defaultIndex, integration);
      if (defaultIndex) {
        setEnabledIntegrationByIndex(defaultIndex, enabled);
      }
    },
    [state.integrations, setDraggableIntegration, setEnabledIntegrationByIndex]
  );

  const setCache = useCallback((cache: boolean) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      cache
    }));
  }, []);

  const setCodeBlockToEdit = useCallback((codeBlockToEdit: CodeBlockGetResponse | undefined) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      codeBlockToEdit
    }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setContextEnrichmentToEdit = useCallback((contextEnrichmentToEdit: any) => {
    setState((current: TJobFlowsState) => ({
      ...current,
      contextEnrichmentToEdit
    }));
  }, []);

  const isFormDirty = useMemo(
    () =>
      !_.isEqual(state.integrations, state.initialIntegrations) ||
      !_.isEqual(state.enabledIntegrations, state.initialEnabledIntegrations),
    [state.integrations, state.initialIntegrations, state.enabledIntegrations, state.initialEnabledIntegrations]
  );

  const getPromptMessage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (location: any) => {
      const isCodeBlockAddMatched = matchPath(location.pathname, {
        path: config.routes.flowsComponents.codeBlock.add({ jobId: ':id' }),
        exact: true,
        strict: false
      });
      const isCodeBlockEditMatched = matchPath(location.pathname, {
        path: config.routes.flowsComponents.codeBlock.edit({ jobId: ':id', codeBlockId: ':codeBlockId' }),
        exact: true,
        strict: false
      });
      const isCEAddMatched = matchPath(location.pathname, {
        path: config.routes.flowsComponents.contextEnrichment.add({ jobId: ':id' }),
        exact: true,
        strict: false
      });
      const isCEEditMatched = matchPath(location.pathname, {
        path: config.routes.flowsComponents.contextEnrichment.edit({
          jobId: ':id',
          contextId: ':contextId',
          contextName: ':contextName'
        }),
        exact: true,
        strict: false
      });
      const isFlowsMatched = matchPath(location.pathname, {
        path: config.routes.flows,
        exact: true,
        strict: false
      });

      const shouldAllowUserNavigation =
        Boolean(isCodeBlockAddMatched) ||
        Boolean(isCodeBlockEditMatched) ||
        Boolean(isCEAddMatched) ||
        Boolean(isCEEditMatched) ||
        Boolean(isFlowsMatched);

      return shouldAllowUserNavigation ? true : t('common:unsavedChanges');
    },
    [t]
  );

  const value: TJobFlowsContext = {
    state,
    setJobId,
    setEditIndex,
    setIntegrations,
    addIntegration,
    setIntegrationOnEditEnd,
    addDraggableIntegration,
    setDraggableIntegration,
    setCache,
    setCodeBlockToEdit,
    setEnabledIntegrations,
    setEnabledIntegrationByIndex,
    setEnabledIntegrationNextToLast,
    setContextEnrichmentToEdit,
    setJobCategory,
    setInitialIntegrations,
    setInitialEnabledIntegrations,
    isFormDirty
  };

  return (
    <JobFlowsContext.Provider value={value}>
      {children}
      <Prompt when={isFormDirty} message={getPromptMessage} />
    </JobFlowsContext.Provider>
  );
}

export default JobFlowsContextProvider;
