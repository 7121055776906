/* eslint-disable max-len */
function ApiOutlined() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_22138_9312)">
      <path
        d="M5.36377 8.89941L11.0206 14.5563L9.60641 15.9705C8.04431 17.5326 5.51165 17.5326 3.94956 15.9705V15.9705C2.38746 14.4084 2.38746 11.8757 3.94956 10.3136L5.36377 8.89941Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        d="M8.89941 5.36377L14.5563 11.0206L15.9705 9.60641C17.5326 8.04431 17.5326 5.51165 15.9705 3.94956V3.94956C14.4084 2.38746 11.8757 2.38746 10.3136 3.94956L8.89941 5.36377Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinejoin="round"/>
      <line
        x1="2.53564" y1="17.8089" x2="3.66702" y2="16.6775"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"/>
      <path d="M17.8087 2.53564L16.6774 3.66702M11.268 11.5513L10.9852 11.8341M8.43958 8.72283L8.15674 9.00567"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
      </g>
      <defs>
      <clipPath id="clip0_22138_9312">
        <rect width="20" height="20" fill="currentColor"/>
      </clipPath>
      </defs>
    </svg>
  );
}

export default ApiOutlined;