import * as React from 'react';
import { useQuery } from 'react-query';
import { config } from '../config';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { CommonRequest } from '../components/shared/models/common/CommonRequest';
import { useLocation } from 'react-router-dom';
import HttpStatusCode from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import { showErrorNotification } from '../components/shared/utilities/notification';
import { useAuth } from '../components/pages/Main/Login/Auth.context';

const USER_SESSION_CHECK_INTERVAL = 60 * 1000;

export function useUserLoginStatusCheck() {
  const [isQueryEnabled, setIsQueryEnabled] = React.useState(true);
  const location = useLocation();
  const { t } = useTranslation(['common']);
  const { logout } = useAuth();

  const redirectLogin = () => {
    const LOGOUT_WARNING_TIMEOUT = 10;
    const INTERMEDIATE_LOGOUT_WARNING_TIMEOUT = 5;

    setTimeout(() => {
      showErrorNotification(
        t('common:loggingOutWarningDescription'),
        t('common:loggingOutWarningMessage').replace('<seconds>', LOGOUT_WARNING_TIMEOUT.toString())
      );
    }, 0);
    setTimeout(() => {
      showErrorNotification(
        t('common:loggingOutWarningDescription'),
        t('common:loggingOutWarningMessage').replace('<seconds>', INTERMEDIATE_LOGOUT_WARNING_TIMEOUT.toString())
      );
    }, INTERMEDIATE_LOGOUT_WARNING_TIMEOUT * 1000);
    setTimeout(() => {
      logout();
    }, LOGOUT_WARNING_TIMEOUT * 1000);
  };

  const query = useQuery<AxiosResponse, AxiosError>(
    [config.api.authCheckKeepAlive],
    () => axios.post<AxiosResponse>(config.api.authCheckKeepAlive, new CommonRequest()).then((response) => response),
    {
      refetchInterval: USER_SESSION_CHECK_INTERVAL,
      refetchIntervalInBackground: true,
      enabled: isQueryEnabled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess: (data: any) => {
        if (location.pathname != config.routes.login) {
          if (data.status == HttpStatusCode.UNAUTHORIZED) {
            redirectLogin();
          }
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (location.pathname != config.routes.login) {
          if (error.status == HttpStatusCode.UNAUTHORIZED) {
            redirectLogin();
          }
        }
      }
    }
  );
  return {
    query,
    setIsQueryEnabled
  };
}
