import { Box, styled, BoxProps } from '@mui/material';

const StyledCustomLabel = styled(Box, {
  shouldForwardProp(propName) {
    return propName !== 'isClickable' && propName !== 'hasPadding';
  }
})<
  BoxProps & {
    isClickable: boolean;
    hasPadding: boolean;
  }
>(({ theme, isClickable, hasPadding }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '6px',
  borderRadius: '4px',

  ...(isClickable && {
    cursor: 'pointer'
  }),

  ...(hasPadding && {
    padding: '6px 16px'
  }),

  '.strike': {
    textDecoration: 'line-through',
    color: theme.palette.secondary[400]
  },

  '.ellipsis': {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  '.icon': {
    color: theme.palette.secondary[300]
  }
}));

export default StyledCustomLabel;
