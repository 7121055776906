import axios, { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { IntegrationSubcategoryParameterValueResponse } from '../../../components/shared/models/integration/IntegrationSubcategoryParameterValueResponse';
import { showErrorNotification, showSuccessNotification } from '../../../components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';
import { CommonResponse } from '../../../components/shared/models/common/CommonResponse';

type Response = { resource: IntegrationSubcategoryParameterValueResponse };

export default function useIntegrationSubcategoriesParameterValue<RequestPayload>() {
  const { t } = useTranslation(['integration', 'common']);

  return useMutation<
    // The return of the call is <future field name>: <value>. Where value can be anything from a list of
    // strings for dropdown options, or a simple string for a text field, or a number for a number field etc.
    // When using the value retrieved, make sure you check its type and use it accordingly
    { [x: string]: string | string[] | number | number[] | boolean | boolean[] },
    AxiosResponse,
    { url: string; params: RequestPayload; futureParameterName: string }
  >(
    async (payload: { url: string; params: RequestPayload; futureParameterName: string }) => {
      const responseData: AxiosResponse<Response> = await axios.post<Response, AxiosResponse<Response>>(
        payload.url,
        payload.params
      );
      return { [payload.futureParameterName]: responseData.data.resource.value };
    },
    {
      // eslint-disable-next-line
      onSuccess: () => {
        showSuccessNotification(t('common:success'), t('integration:futureValuesRetrievalSuccess'));
      },
      onError: (err: AxiosResponse<{ resource: { request: CommonResponse } }>) => {
        showErrorNotification(
          t('common:error'),
          err.data.resource.request.reason ?? '',
          t('integration:futureValuesRetrievalError')
        );
      }
    }
  );
}
