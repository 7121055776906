import StyledColorCircle from './StyledColorCircle.styles';

type TColorCircleProps = {
  color: string;
  setSelectedColor: (color: string) => void;
  selectedColor: string;
  index: number;
};

function ColorCircle({ color, setSelectedColor, selectedColor, index }: TColorCircleProps) {
  return <StyledColorCircle color={color} onClick={() => setSelectedColor(color)} selectedColor={selectedColor} index={index} />;
}

export default ColorCircle;
