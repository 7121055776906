import { useEffect, useState } from 'react';
import { useJobMetrics } from '../../../../../../data-access';
import { BinaryConfusionMatrixEntry } from '../../../../../shared/models/retrain-history/JobMetricsResponse';

export type JobInsightsTableEntry = {
  label: string;
  tp: number;
  fp: number;
  tn: number;
  fn: number;
  fScore: number;
  recall: number;
  precision: number;
};

type UseJobInsightsTableData = {
  jobId: number;
  modelId: string;
};
export function useJobInsightsTableData({ jobId, modelId }: UseJobInsightsTableData) {
  const [tableData, setTableData] = useState<JobInsightsTableEntry[]>([]);

  const jobMetricsQuery = useJobMetrics(jobId, modelId);

  useEffect(
    function mapApiResponseToTableStructure() {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (jobMetricsQuery.data && jobMetricsQuery.data.feedbackSessionMetrics.binaryConfusionMatrix.entries) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        const result = jobMetricsQuery.data.feedbackSessionMetrics.binaryConfusionMatrix.entries.map(
          (item: BinaryConfusionMatrixEntry) => {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const fScore = jobMetricsQuery.data.feedbackSessionMetrics.fScore.find(({ label }) => label === item.label);
            if (fScore === undefined) {
              throw new Error(`fScore is missing for label ${item.label}`);
            }

            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const recall = jobMetricsQuery.data.feedbackSessionMetrics.recall.find(({ label }) => label === item.label);
            if (recall === undefined) {
              throw new Error(`recall is missing for label ${item.label}`);
            }

            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const precision = jobMetricsQuery.data.feedbackSessionMetrics.precision.find(({ label }) => label === item.label);
            if (precision === undefined) {
              throw new Error(`precision is missing for label ${item.label}`);
            }

            const entry: JobInsightsTableEntry = {
              // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
              label: item.label,
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
              tp: item.tp,
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
              tn: item.tn,
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
              fp: item.fp,
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
              fn: item.fn,
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
              fScore: fScore.value,
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
              recall: recall.value,
              // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
              precision: precision.value
            };

            return entry;
          }
        );

        setTableData(result);
      }
    },
    [jobMetricsQuery.data]
  );

  return tableData;
}
