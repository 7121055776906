import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiListStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiList: {
    styleOverrides: {
      root: {
        padding: '0px',
        border: `1px solid ${palette.info[600]}`,
        borderRadius: '4px',
        background: palette.secondary[900],

        '&.MuiList--naked': {
          border: 0,
          borderRadius: 0,
          background: 'none'
        },

        '& .MuiMenuItem-root': {
          color: palette.secondary[300],
          padding: 0,
          fontWeight: 600,
          fontSize: '14px',
          lineHeight: '24px',
          background: palette.secondary[900],

          '& > *': {
            padding: '16px 20px',
            width: '100%',
            color: palette.secondary[300]
          },

          '&:first-of-type': {
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px'
          },

          '&:last-of-type': {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px'
          },

          '&:not(:last-child)': {
            borderBottom: `1px solid ${palette.secondary[700]}`
          },

          '&.MuiMenuItem-root--simple': {
            background: palette.secondary[1000],
            fontWeight: 400
          },

          '&.MuiMenuItem-root--small': {
            height: '48px',
            whiteSpace: 'wrap',

            '& > *': {
              padding: '8px 8px 8px 12px',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px'
            }
          },

          '&.MuiMenuItem-root--smaller': {
            height: '42px',
            whiteSpace: 'wrap',

            '& > *': {
              padding: '8px 8px 8px 12px',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '20px'
            }
          },

          '&:hover': {
            color: palette.secondary[100],
            background: palette.secondary[800],

            '& > *': {
              color: palette.secondary[100]
            }
          }
        }
      }
    }
  }
});
