import { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Internal500Error from '@arcanna/pages/ErrorPages/500';
import { config } from 'src/config';

type Props = RouteComponentProps & {
  children: ReactNode;
};

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch() {
    // console.error('Uncaught error:', error, errorInfo);
  }

  goHome = () => {
    this.setState({
      hasError: false
    });
    this.props.history.push(config.routes.homepage);
  };

  public render() {
    if (this.state.hasError) {
      return <Internal500Error goHome={this.goHome} />;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
