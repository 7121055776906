import DPUnifiedContextProvider from './DPUnifiedContext/DPUnifiedContextProvider';
import DPUnifiedTable from './DPUnifiedTable';

function DecisionPointsUnified() {
  return (
    <DPUnifiedContextProvider>
      <DPUnifiedTable />
    </DPUnifiedContextProvider>
  );
}

export default DecisionPointsUnified;
