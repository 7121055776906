import { useTimeMetrics } from '../../../../../../data-access';
import { getHumanReadableTime } from '../../../../../../utils/humanReadableTime';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ValueUnitList from './ValueUnitComp';
import TimeMetric from './TimeMetric';

type TMedianDecisionTimeMetricProps = {
  jobId: number;
};

function MedianDecisionTimeMetric({ jobId }: TMedianDecisionTimeMetricProps) {
  const { t } = useTranslation(['job']);
  const timeMetricsQuery = useTimeMetrics(+jobId);

  // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
  const medianDecisionTime = getHumanReadableTime(timeMetricsQuery?.data?.median_decision_time, 'seconds').slice(-3).reverse();

  return (
    <Col className="p-t-32">
      <TimeMetric
        text={t('job:jobTimeMetrics.medianDecisionTime')}
        tooltipText={t('job:jobTimeMetrics:medianDecisionTimeInfo')}
      />
      <Row>
        <ValueUnitList valueUnitArray={medianDecisionTime} />
      </Row>
    </Col>
  );
}

export default MedianDecisionTimeMetric;
