import { JsonObject, JsonProperty } from 'json2typescript';
import { PlatformBuildRecord } from './PlatfromBuildRecord';
import { PlatformKibanaRecord } from './PlatformKibanaRecord';
import { PlatformFeaturesRecord } from './PlatformFeaturesRecord';

@JsonObject('PlatformInfoRecord')
export class PlatformInfoRecord {
  @JsonProperty('build', PlatformBuildRecord)
  build?: PlatformBuildRecord;

  @JsonProperty('features', PlatformFeaturesRecord, true)
  features?: PlatformFeaturesRecord;

  @JsonProperty('kibana', PlatformKibanaRecord)
  kibana?: PlatformKibanaRecord;
}
