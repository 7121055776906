import { JsonObject, JsonProperty } from 'json2typescript';
import { CodeBlockLimitsRecord } from 'src/_srcMUI/models/CodeBlock/CodeBlockLimitsRecord';

@JsonObject('CodeBlockSettingsRecord')
export class CodeBlockSettingsRecord {
  @JsonProperty('limits', CodeBlockLimitsRecord)
  limits: CodeBlockLimitsRecord;
}

type TConstructorParams = {
  limits: CodeBlockLimitsRecord;
};

export function constructCodeBlockLimitsRecord({ limits }: TConstructorParams): CodeBlockSettingsRecord {
  const instance = new CodeBlockSettingsRecord();
  instance.limits = limits;

  return instance;
}
