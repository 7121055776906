import { Col, Row, Tooltip, Typography } from 'antd';
import style from './JobOverviewMetrics.module.css';
import { InfoCircleOutlined } from '@ant-design/icons';

type TimeMetricProps = {
  text: string;
  tooltipText: string;
  customClassName?: string;
};

export default function TimeMetric({ text, tooltipText, customClassName = '' }: TimeMetricProps) {
  return (
    <Row className={customClassName}>
      <Col>
        <Typography.Text type="secondary" className="color-nb-300">
          {text}
        </Typography.Text>
      </Col>
      <Col>
        <Tooltip title={tooltipText} className={style.tooltip}>
          <InfoCircleOutlined />
        </Tooltip>
      </Col>
    </Row>
  );
}
