import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useLocation } from 'react-router-dom';
import { config } from '../config';
import HttpStatusCode from 'http-status-codes';
import { useAuth } from '../components/pages/Main/Login/Auth.context';

type ReactQueryProviderProps = {
  children: React.ReactNode;
};
export function ReactQueryProvider({ children }: ReactQueryProviderProps) {
  const location = useLocation();
  const { logout } = useAuth();

  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSuccess: (error: any) => {
              if (location.pathname != config.routes.login) {
                if (error?.status == HttpStatusCode.UNAUTHORIZED) {
                  logout();
                }
              }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
              if (location.pathname != config.routes.login) {
                if (error.status == HttpStatusCode.UNAUTHORIZED) {
                  logout();
                }
              }
            }
          }
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}
