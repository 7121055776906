import { TableCellProps, TableCell, styled } from '@mui/material';
import { TABLE_SIZES } from '../TableSizeSelector/TableSizeSelector.constants';
import { computeTableBodyCellCSSVariableName } from '../../Table.utils';
import { ColumnPinningPosition } from '@tanstack/react-table';

const StyledTableBodyCell = styled(TableCell, {
  shouldForwardProp(propName) {
    return (
      propName !== 'tableSize' &&
      propName !== 'isPinned' &&
      propName !== 'columnId' &&
      propName !== 'isLastLeftPinnedColumn' &&
      propName !== 'leftStartPosition' &&
      propName !== 'actionsColumnSize'
    );
  }
})<
  TableCellProps & {
    tableSize: string;
    isPinned: ColumnPinningPosition;
    columnId: string;
    isLastLeftPinnedColumn: boolean;
    leftStartPosition: number;
    actionsColumnSize: number;
  }
>(({ theme, tableSize, isPinned, columnId, actionsColumnSize, isLastLeftPinnedColumn, leftStartPosition }) => ({
  fontSize: '12px',
  color: theme.palette.secondary[100],
  lineHeight: '20px',
  padding: '4px 8px',
  border: '0',
  transition: 'width transform 0.2s ease-in-out',
  width: `var(${computeTableBodyCellCSSVariableName(columnId)})`,
  maxWidth: `var(${computeTableBodyCellCSSVariableName(columnId)})`,
  alignContent: tableSize === TABLE_SIZES.small ? 'center' : 'flex-start',

  ...(isPinned
    ? {
        boxShadow: isLastLeftPinnedColumn ? `-4px 0 4px -4px ${theme.palette.secondary[600]} inset` : 'none',
        left: isPinned === 'left' ? `${leftStartPosition + actionsColumnSize - 1}px` : undefined,
        position: isPinned ? 'sticky' : 'relative',
        zIndex: isPinned ? 1 : 0
      }
    : {
        opacity: 1,
        position: 'relative'
      })
}));

export default StyledTableBodyCell;
