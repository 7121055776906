import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { FeedbackStatisticsFilterRecord } from './FeedbackStatisticsFilterRecord';

enum RetrainSessionType {
  CURRENT = 'current',
  PREVIOUS = 'previous',
  OVERALL = 'overall'
}

@JsonObject('FeedbackStatisticsRequest')
export class FeedbackStatisticsRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('retrain_session', String)
  retrain_session?: string | RetrainSessionType;

  @JsonProperty('job_id', Object)
  start_date?: object;

  @JsonProperty('job_id', Object)
  end_date?: object;

  @JsonProperty('filters', [FeedbackStatisticsFilterRecord])
  filters?: FeedbackStatisticsFilterRecord[] | [];

  constructor(
    job_id: number,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
    retrain_session: string = null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'object'.
    start_date: object = null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'object'.
    end_date: object = null,
    filters: FeedbackStatisticsFilterRecord[]
  ) {
    super();
    this.job_id = job_id;
    this.filters = filters;
    this.retrain_session = retrain_session;
    // Make request with start_date, end_date
    // only if we are using the retrain session of type OVERALL
    if (this.retrain_session === RetrainSessionType.OVERALL) {
      this.start_date = start_date;
      this.end_date = end_date;
    }
  }
}
