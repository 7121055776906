import { LifeBuoyIcon } from 'src/themes/icons';
import { MenuDropdown } from '@arcanna/generic';
import { useHelpMenuItems } from './hooks';

function HelpMenu() {
  const menuItems = useHelpMenuItems();

  return (
    <MenuDropdown menuItems={menuItems} className="right-menu">
      <LifeBuoyIcon />
      <span>Help</span>
    </MenuDropdown>
  );
}

export default HelpMenu;
