import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiTypographyStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiTypography: {
    styleOverrides: {
      root: {
        color: palette.secondary[100]
      },
      body1: {
        fontSize: '20px',
        lineHeight: '28px'
      },
      body2: {
        fontSize: '16px',
        lineHeight: '24px'
      },
      h3: {
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '32px',
        letterSpacing: '-0.12px'
      },
      h4: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '28px'
      },
      subtitle1: {
        fontSize: '14px',
        lineHeight: '22px'
      },
      subtitle2: {
        fontSize: '12px',
        lineHeight: '20px'
      }
    }
  }
});
