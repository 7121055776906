import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EventExplorerFiltersFieldsRecord')
export class EventExplorerFiltersFieldsRecord {
  @JsonProperty('source', String)
  source?: string | undefined;

  @JsonProperty('name', String)
  name?: string | undefined;

  @JsonProperty('operators', [String])
  operators?: string[] = [];
}
