import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BucketsBreakdown')
export class BucketsBreakdown {
  @JsonProperty('label_id', String)
  label_id: string;

  @JsonProperty('count', Number)
  count: number;
}
