import * as React from 'react';
import Sider from 'antd/es/layout/Sider';
import style from './JobNavigation.module.css';
import { useParams } from 'react-router-dom';
import { useJobInfo } from '../../../../../data-access';
import DecisionIntelligenceNavItems from './DecisionIntelligenceNavItems';
import RCANavItems from './RCANavItems';
import { isDecisionIntelligenceCategory, JobCategory } from '../helper';

export enum JOB_NAVIGATION_KEYS {
  JOB_OVERVIEW = 'job-overview',
  JOB_OVERVIEW_RCA = 'job-overview-rca',
  JOB_FEEDBACK = 'job-feedback',
  JOB_TRAIN = 'job-train',
  JOB_EXPLORER = 'job-explorer',
  JOB_SETTINGS = 'job-settings',
  JOB_CLUSTERS = 'job-clusters',
  JOB_FLOWS = 'job-flows'
}

export function JobNavigation() {
  const { id: jobId } = useParams<{ id: string }>();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const jobInfoQuery = useJobInfo(+jobId);

  const jobCategory = jobInfoQuery.data?.info?.jobDetails?.category_id;

  return (
    <Sider
      width={52}
      className={style.sider}
      onMouseEnter={() => {
        setIsExpanded(true);
      }}
      onMouseLeave={() => {
        setIsExpanded(false);
      }}
    >
      {jobCategory === JobCategory.RCA && <RCANavItems isExpanded={isExpanded} jobId={jobId} />}
      {isDecisionIntelligenceCategory(jobCategory) && <DecisionIntelligenceNavItems isExpanded={isExpanded} jobId={jobId} />}
    </Sider>
  );
}
