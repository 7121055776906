import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('TimeMetricsResponse')
export class TimeMetricsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('time_units_saved_by_arcanna', Number)
  timeUnitsSavedByArcanna: number;

  @JsonProperty('time_gained_back', Number)
  timeGainedBack: number;

  @JsonProperty('median_decision_time', Number)
  median_decision_time: number;
}
