import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from 'src/components/shared/models/common/CommonRequest';

@JsonObject('UnifiedDecisionPointsListRequest')
export class UnifiedDecisionPointsListRequest extends CommonRequest {
  @JsonProperty('job_ids', [Number])
  job_ids?: number[];

  constructor(job_ids: number[]) {
    super();
    this.job_ids = job_ids;
  }
}
