import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { IntegrationSummaryRecord } from '../../components/shared/models/integration/IntegrationSummaryRecord';
import { IntegrationSummaryRequest } from '../../components/shared/models/integration/IntegrationSummaryRequest';
import { IntegrationSummaryResponse } from '../../components/shared/models/integration/IntegrationSummaryResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';

type TIntegrationSummaryFilters = {
  roles: string[];
  states: string[];
};

type useIntegrationsSummaryType = {
  includeInternal?: boolean;
  tag?: string;
  filters?: TIntegrationSummaryFilters;
  useInfinityCache?: boolean;
};

export function useIntegrationsSummary({ includeInternal = false, tag, filters }: useIntegrationsSummaryType) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = tag == null ? config.api.integrations : config.api.integrationSummary.replace(':parameterType', tag);

  return useQuery<IntegrationSummaryResponse, Error>(
    [
      url,
      {
        event_count: config.defaultEventsCount,
        include_internal: includeInternal,
        filters
      }
    ],
    () =>
      axios
        .post<{
          resource: IntegrationSummaryResponse;
        }>(url, new IntegrationSummaryRequest(config.defaultEventsCount, includeInternal, undefined, filters))
        .then((responseData: AxiosResponse<{ resource: IntegrationSummaryResponse }>) => {
          const response = jsonConvert.deserializeObject(responseData.data.resource, IntegrationSummaryResponse);

          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          response.entries.sort((item1: IntegrationSummaryRecord, item2: IntegrationSummaryRecord) => item1.id - item2.id);

          return {
            ...response,
            // @ts-expect-error TS(2488): Type 'IntegrationSummaryRecord[] | undefined' must...
            entries: [...response.entries]
          };
        })
  );
}
