import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { FeatureDistribution } from '../../../../../../../../shared/models/retrain-history/JobMetricsResponse';
import { PercentageBar } from '../../../../../../../../shared/components';
import { Tooltip } from 'antd';

type UsedValuesInTrainingProps = {
  featureDistribution: FeatureDistribution;
};
export default function UsedValuesInTraining({ featureDistribution }: UsedValuesInTrainingProps) {
  const { t } = useTranslation(['overview']);
  const featureDistributionOccurences = featureDistribution.occurences ?? [];
  const totalOccurences = featureDistributionOccurences.length;
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const usedOccurences = featureDistributionOccurences.filter((occ) => occ.usedCount > 0).length;
  const percentage = totalOccurences > 0 ? ((usedOccurences * 100) / totalOccurences).toFixed(2) : '0.00';

  return (
    <div className="flex flex-col m-t-16">
      <div className="flex flex-sp callout color-nb-200 m-b-4">
        <Tooltip title={t('overview:usedValuesInTraining')}>
          <span className={styles.occurenceText}>{t('overview:usedValuesInTraining')}</span>
        </Tooltip>
        <span>
          {usedOccurences} ({percentage}%)
        </span>
      </div>
      <PercentageBar percentage={percentage} />
    </div>
  );
}
