import { JsonObject, JsonProperty } from 'json2typescript';
import moment from 'moment';
import { CommonRequest } from '../common/CommonRequest';
import { ChartRecord } from './ChartRecord';
import { Filter } from '../usecase/Filter';
import { VisualizationStateType } from '../../components/QueryVisualisation/Visualizations.context';

@JsonObject('ChartsRequest')
export class ChartsRequest extends CommonRequest {
  @JsonProperty('charts', Array<ChartRecord>())
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'ChartR...
  charts: Array<ChartRecord> = undefined;

  constructor(id: string, visualizationState: VisualizationStateType) {
    super();

    this.charts = new Array<ChartRecord>();
    let filters: Filter[] = [];
    if (visualizationState.page) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const entry = visualizationState.page.visualizations.find((vis) => vis.id === id);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (!entry.disable_filters) {
        filters = filters.concat(Filter.filterPropsToFilterArray(visualizationState.filtersProps));
        filters = filters.concat(Filter.filterPropsToFilterArray(visualizationState.clickableFilters));
      }
    }
    this.charts.push(
      new ChartRecord(
        id,
        visualizationState.intervalStart.toDate(),
        visualizationState.intervalEnd.toDate(),
        moment(visualizationState.intervalStart).format('Z'),
        filters
      )
    );
  }
}
