import { JsonObject, JsonProperty } from 'json2typescript';
import { EventExplorerBatchColumnRecord } from './EventExplorerBatchColumnRecord';
import { EventExplorerBatchRecordChild } from './EventExplorerBatchRecordChild';

@JsonObject('EventExplorerBatchRecord')
export class EventExplorerBatchRecord {
  @JsonProperty('row_id', String)
  row_id: string | undefined;

  @JsonProperty('group_id', String)
  group_id?: string | undefined;

  @JsonProperty('result_index', String)
  result_index: string | undefined;

  @JsonProperty('label', String)
  label: string | undefined;

  @JsonProperty('nn_label', String)
  nn_label: string | undefined;

  @JsonProperty('uses_last_model', Boolean)
  uses_last_model: boolean | undefined;

  @JsonProperty('columns', [EventExplorerBatchColumnRecord])
  columns: EventExplorerBatchColumnRecord[] = [];

  @JsonProperty('child_entries', [EventExplorerBatchRecordChild])
  child_entries?: EventExplorerBatchRecordChild[] = [];
}
