import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { fetchJobInfo } from './useJobInfo';
import { JobStatusRecord } from '../../components/shared/models/job/JobStatusRecord';

const BUCKETS_MODEL_UPDATE_INTERVAL = 3000;

function shouldRefetch(status: JobStatusRecord): boolean {
  return status === undefined || status.buckets_model_update_in_progress === undefined || status.buckets_model_update_in_progress;
}

export function useJobBucketsModelUpdate(jobId: number, forceRefetch = false) {
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, JobStatusRecord | null>(
    [config.api.jobInfo.replace(':id', `${jobId}`)],
    () => fetchJobInfo(jobId, jsonConvert),
    {
      // @ts-expect-error TS(2322): Type '(status: JobStatusRecord) => number | false'...
      refetchInterval: (status: JobStatusRecord) => {
        // refetch if buckets_model_update_in_progress is True
        if (shouldRefetch(status) || forceRefetch) {
          return BUCKETS_MODEL_UPDATE_INTERVAL;
        }
        // stop the refetch interval
        return false;
      },
      // @ts-expect-error TS(2322): Type '(data: JobInfoResponse) => JobStatusRecord |...
      select: (data: JobInfoResponse) => data.info.status,
      enabled: Boolean(jobId)
    }
  );
}
