import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMutation } from 'react-query';
import { config } from '../../config';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { JobCleaningResultsRequest } from '../../components/shared/models/job/JobCleaningResultsRequest';
import { JobUpdateResponse } from '../../components/shared/models/job/JobUpdateResponse';
import { REQUEST_STATUS_NOK } from '../../components/shared/hooks/useFetch';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useMemo } from 'react';

export function useJobCleanResults() {
  const { t } = useTranslation(['job']);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  return useMutation((payload: JobCleaningResultsRequest) => axios.post(config.api.jobCleaningResults, payload), {
    onSuccess: (response) => {
      const responseData = jsonConvert.deserializeObject(response.data.resource, JobUpdateResponse);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (responseData.request.status === REQUEST_STATUS_NOK) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        showErrorNotification(t('common:error'), responseData.request.reason);
        return;
      }
      showSuccessNotification(t('cleanResults.successMessage'), t('cleanResults.successDescription'));
    },
    onError: () => {
      showErrorNotification(t('cleanResults.errorMessage'), t('cleanResults.genericErrorDescription'));
    }
  });
}
