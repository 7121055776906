import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PlatformBuildRecord')
export class PlatformBuildRecord {
  @JsonProperty('uptime', String)
  uptime: string | undefined;

  @JsonProperty('version', String)
  version: string | undefined;

  @JsonProperty('new_version_available', Boolean)
  newVersionAvailable?: boolean;

  @JsonProperty('new_version_tag', String)
  newVersionTag?: string;
}
