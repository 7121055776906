import { getComponentOverrides } from '../_common';
import { createTheme } from '@mui/material';
import siscaleColors from './palette';
import { TPaletteOverrides } from '../branding.types';
import type {} from '@mui/x-date-pickers/themeAugmentation';

const siscalePalette: TPaletteOverrides = {
  primary: {
    light: siscaleColors.main.turquoise[300],
    main: siscaleColors.main.turquoise[400],
    dark: siscaleColors.main.turquoise[600],
    contrastText: siscaleColors.main.navyBlue[800],
    ...siscaleColors.main.turquoise
  },
  secondary: {
    light: siscaleColors.main.navyBlue[700],
    main: siscaleColors.main.navyBlue[800],
    dark: siscaleColors.main.navyBlue[1000],
    contrastText: siscaleColors.main.navyBlue[200],
    ...siscaleColors.main.navyBlue
  },
  error: {
    light: siscaleColors.utilitarian.red[500],
    main: siscaleColors.utilitarian.red[600],
    dark: siscaleColors.utilitarian.red[800],
    contrastText: siscaleColors.main.navyBlue[0],
    ...siscaleColors.utilitarian.red
  },
  warning: {
    light: siscaleColors.utilitarian.orange[500],
    main: siscaleColors.utilitarian.orange[600],
    dark: siscaleColors.utilitarian.orange[800],
    contrastText: siscaleColors.main.navyBlue[0],
    ...siscaleColors.utilitarian.orange
  },
  info: {
    light: siscaleColors.utilitarian.blue[500],
    main: siscaleColors.utilitarian.blue[600],
    dark: siscaleColors.utilitarian.blue[800],
    contrastText: siscaleColors.main.navyBlue[0],
    ...siscaleColors.utilitarian.blue
  },
  success: {
    light: siscaleColors.utilitarian.green[400],
    main: siscaleColors.utilitarian.green[500],
    dark: siscaleColors.utilitarian.green[700],
    contrastText: '',
    ...siscaleColors.utilitarian.green
  },
  grey: {
    A100: siscaleColors.main.navyBlue[100],
    A200: siscaleColors.main.navyBlue[200],
    A400: siscaleColors.main.navyBlue[400],
    A700: siscaleColors.main.navyBlue[700],
    50: siscaleColors.main.navyBlue[100],
    ...siscaleColors.main.navyBlue
  },
  background: {
    default: siscaleColors.main.navyBlue[1000],
    paper: siscaleColors.main.navyBlue[900]
  },
  tertiary: {
    light: siscaleColors.main.ceruleanBlue[500],
    main: siscaleColors.main.ceruleanBlue[600],
    dark: siscaleColors.main.ceruleanBlue[800],
    contrastText: siscaleColors.main.ceruleanBlue[100],
    ...siscaleColors.main.ceruleanBlue
  },
  vividBlue: {
    light: siscaleColors.utilitarian.vividBlue[100],
    dark: siscaleColors.utilitarian.vividBlue[900],
    main: siscaleColors.utilitarian.vividBlue[500],
    contrastText: siscaleColors.utilitarian.vividBlue[800],
    ...siscaleColors.utilitarian.vividBlue
  }
};

const muiTheme = createTheme({
  palette: siscalePalette,
  typography: {
    fontFamily: 'Inter'
  },
  components: {
    ...getComponentOverrides(siscalePalette),
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-weight: 100;
          src: url('../../assets/fonts/Inter-Thin.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 200;
          src: url('../../assets/fonts/Inter-ExtraLight.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 300;
          src: url('../../assets/fonts/Inter-Light.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 400;
          src: url('../../assets/fonts/Inter-Regular.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 500;
          src: url('../../assets/fonts/Inter-Medium.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 600;
          src: url('../../assets/fonts/Inter-SemiBold.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 700;
          src: url('../../assets/fonts/Inter-Bold.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 800;
          src: url('../../assets/fonts/Inter-ExtraBold.ttf') format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-weight: 900;
          src: url('../../assets/fonts/Inter-Black.ttf') format('truetype');
        }
      `
    }
  }
});

export default muiTheme;
