import { Alert, Col, Input, Row, Spin, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import Button from '../../../../shared/components/Button/Button';
import { useJobFeedbackColumns } from './JobFeedbackColumns.hook';
import { permissions } from '../../../../shared/static/ComponentsPermissions';
import PermissionsCheck from '../../../../shared/components/Permissions/Permissions';
import { searchText } from '../../../../../utils/searchText';
import { JobFeedbackColumnsSummaryDetails } from '../../../../shared/models/job/JobFeedbackColumnsSummaryDetails';
import { useJobNavigate } from '../../../../shared/hooks/useJobNavigate';

export function JobFeedbackColumns() {
  const { Search } = Input;
  const { t } = useTranslation(['common', 'job']);
  const {
    handleUpdateFeedbackColumns,
    jobInfoError,
    jobInfoLoading,
    jobId,
    jobStructureLoading,
    getFeedbackColumnsColumns,
    rowSelection,
    getTableRowClassName,
    structureData,
    setStructureData,
    initialStructureData,
    loadingUpdateFeedbackColumns,
    setSearchTextValue
  } = useJobFeedbackColumns();

  const { navigateToJobFeedbackFlow } = useJobNavigate();

  return (
    <>
      <Row justify="space-between" className="p-t-10">
        <Col>
          <Search
            placeholder={t('job:feedbackColumns.searchLabel')}
            size="middle"
            style={{
              width: 250
            }}
            onSearch={(value: string) => {
              if (value) {
                setStructureData(searchText<JobFeedbackColumnsSummaryDetails>(initialStructureData, value, 'name'));
                setSearchTextValue(value);
                return;
              }
              setStructureData(initialStructureData);
              setSearchTextValue(value);
            }}
          />
        </Col>
        <Col span={6} />
      </Row>
      {jobInfoError && (
        <Row className="p-b-24 p-t-24">
          <Col span="24">
            <Alert key="job-edit-error" message={t('common:error')} description={jobInfoError} type="error" showIcon />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Table
            rowKey="name"
            rowClassName={getTableRowClassName}
            loading={jobStructureLoading}
            bordered
            dataSource={structureData}
            columns={getFeedbackColumnsColumns()}
            showSorterTooltip={false}
            rowSelection={{
              preserveSelectedRowKeys: false,
              type: 'checkbox',
              ...rowSelection
            }}
          />
        </Col>
      </Row>
      <Row className="p-b-24 p-t-24">
        <Col span="12">
          <Spin tip={t('common:loading')} spinning={jobInfoLoading} key="job-edit-spin" size="small">
            <Row key="job-edit-footer" justify="space-between">
              <Col span={8} className="flex">
                <PermissionsCheck
                  permissions={[permissions.jobUpdate]}
                  action={permissions.action.disabledAction}
                  testId={'job-edit-feedback-footer'}
                >
                  <Button
                    onClick={() => handleUpdateFeedbackColumns()}
                    icon={loadingUpdateFeedbackColumns ? <LoadingOutlined /> : <SaveOutlined />}
                    size="middle"
                    type="primary"
                  >
                    <span className="m-l-8">{t('job:feedbackColumns.saveFeedbackColumns')}</span>
                  </Button>
                </PermissionsCheck>
                <Button
                  type="grey"
                  className="m-l-8"
                  size="middle"
                  onClick={() => navigateToJobFeedbackFlow(jobId)}
                  text={t('job:feedbackColumns.backToFeedback')}
                />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </>
  );
}
