import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { CustomFieldRule } from './CustomFieldRule';

@JsonObject('CustomFieldSummaryResponse')
export class CustomFieldSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('name', String)
  name: string;

  @JsonProperty('input_field', String)
  input_field: string;

  @JsonProperty('possible_values', [String])
  possible_values: string[];

  @JsonProperty('custom_path', String)
  custom_path?: string = undefined;

  @JsonProperty('default_value', String)
  default_value?: string;

  @JsonProperty('default_original_value', Boolean)
  default_original_value?: boolean;

  @JsonProperty('rules', [CustomFieldRule])
  rules: CustomFieldRule[];
}
