import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { CurrentFeedbackBreakdownResponse } from '../../components/shared/models/retrain-statistics/CurrentFeedbackBreakdownResponse';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { CurrentFeedbackBreakdownRequest } from '../../components/shared/models/retrain-statistics/CurrentFeedbackBreakdownRequest';

export function useCurrentFeedbackBreakdown(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery([config.api.currentFeedbackBreakdown, jobId], () =>
    axios
      .post<{
        resource: CurrentFeedbackBreakdownResponse;
      }>(config.api.currentFeedbackBreakdown, new CurrentFeedbackBreakdownRequest(jobId))
      .then((responseData: AxiosResponse<{ resource: CurrentFeedbackBreakdownResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, CurrentFeedbackBreakdownResponse)
      )
  );
}
