import { TFunction } from 'i18next';
import { TABLE_SIZES } from './TableSizeSelector.constants';

export const getTableSizeSelectorOptions = (t: TFunction, hasMinimizedView?: boolean) => {
  const sizes = [
    {
      key: t('common:table.tableSizeSmall'),
      dataTestId: 'table-size-option-small',
      label: t('common:table.tableSizeSmall'),
      value: TABLE_SIZES.small
    },
    {
      key: t('common:table.tableSizeAuto'),
      dataTestId: 'table-size-option-auto',
      label: t('common:table.tableSizeAuto'),
      value: TABLE_SIZES.auto
    },
    {
      key: t('common:table.tableSizeCustom'),
      dataTestId: 'table-size-option-custom',
      label: t('common:table.tableSizeCustom'),
      value: TABLE_SIZES.custom
    }
  ];

  if (hasMinimizedView) {
    sizes.push({
      key: t('common:table.tableSizeMinimized'),
      dataTestId: 'table-size-option-minimized',
      label: t('common:table.tableSizeMinimized'),
      value: TABLE_SIZES.minimized
    });
  }

  return sizes;
};
