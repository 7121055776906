import { json } from '@codemirror/lang-json';
import { Stack } from '@mui/material';
import { CopyToClipboardButton } from '@arcanna/components';
import { CodeMirror } from '@arcanna/components';

type TEventJsonTabProps = {
  jsonValue: string;
  isLoading: boolean;
};

function EventJsonTab({ jsonValue, isLoading }: TEventJsonTabProps) {
  return (
    <Stack gap={2}>
      <CopyToClipboardButton
        value={jsonValue}
        buttonProps={{
          sx: {
            width: '140px'
          },
          disabled: isLoading
        }}
      />
      <CodeMirror
        mode="light"
        value={jsonValue}
        extensions={[json()]}
        lineWrapping
        height="calc(100vh - 300px)"
        isEditable={false}
        isLoading={isLoading}
      />
    </Stack>
  );
}

export default EventJsonTab;
