import { TableHeadProps, styled, TableHead } from '@mui/material';

const StyledTableHead = styled(TableHead, {
  shouldForwardProp(propName) {
    return propName !== 'actionsColumnSize';
  }
})<
  TableHeadProps & {
    actionsColumnSize: number;
  }
>(({ theme, actionsColumnSize }) => ({
  position: 'sticky',
  top: '0',
  margin: '0 0 0 0',
  zIndex: 2,
  background: theme.palette.secondary[800],

  '.table-head-cell': {
    borderBottom: 0,
    padding: '0 8px',
    fontSize: '12px',
    lineHeight: '20px',
    position: 'sticky',
    left: 0,
    background: theme.palette.secondary[800],
    zIndex: 1,
    width: `${actionsColumnSize}px`
  }
}));

export default StyledTableHead;
