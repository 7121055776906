import { matchPath } from 'react-router-dom';
import { RouteType } from '../components/shared/models/route/RouteType';

export function getCurrentRoute(path: string, routes: RouteType[]): RouteType | null {
  for (let i = 0; i < routes.length; i += 1) {
    let route = routes[i];
    if (path === route.path || matchPath(path, { path: route.path, exact: route.exact })) {
      return route;
    }

    if (route.children) {
      // @ts-expect-error TS(2322): Type 'RouteType | null' is not assignable to type ...
      route = getCurrentRoute(path, route.children);
      if (route) {
        return route;
      }
    }
  }
  return null;
}
