import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { JobConfigValidationRecord } from './JobConfigValidationRecord';

@JsonObject('JobUpdateResponse')
export class JobUpdateResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse | undefined;

  @JsonProperty('config_validation', [JobConfigValidationRecord], true)
  config_validation?: JobConfigValidationRecord[];
}
