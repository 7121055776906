import { TComponentsOverrides, TPaletteOverrides } from '../../branding.types';

export const getMuiDialogStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiDialog: {
    styleOverrides: {
      paper: () => ({
        backgroundColor: palette.background.paper,
        padding: '24px',
        border: `1px solid ${palette.secondary[700]}`,
        borderRadius: '4px'
      }),
      paperFullScreen: {
        border: 'none',
        borderRadius: 0
      },
      root: {
        zIndex: 1001
      }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        padding: 0
      }
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 0
      },
      dividers: {
        borderTop: 0,
        borderBottomStyle: 'solid'
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 0,
        '& > :not(:first-of-type)': {
          marginLeft: '10px'
        }
      }
    }
  }
});
