import _ from 'lodash';
import { TRHFPill } from './RHFPillField.types';

export const getPillId = (alLPills: TRHFPill[]) => {
  const pillIds = alLPills.map((pill) => pill.id);
  const possiblePillIds = [...Array(alLPills.length + 1).keys()].map((number) => `class_${number}`);

  const unusedPillIds = _.differenceWith(possiblePillIds, pillIds, _.isEqual);

  return unusedPillIds[0];
};
