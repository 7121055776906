import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('EventExplorerEventInfoRecord')
export class EventExplorerEventInfoRecord {
  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('value', String)
  value?: string | undefined;

  @JsonProperty('type', String)
  type?: string | undefined;

  constructor(name: string, value: string, type = 'string') {
    this.name = name;
    this.value = value;
    this.type = type;
  }
}
