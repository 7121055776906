import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiAvatarStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiAvatar: {
    styleOverrides: {
      root: {
        height: '36px',
        width: '36px',
        color: palette.secondary[0],
        fontWeight: 600,
        textDecoration: 'none',
        fontSize: '14px',
        borderRadius: '50%',
        background: palette.secondary[500]
      }
    }
  }
});
