import { InlineNotification } from '@arcanna/components';
import useRollbackInProgress from 'src/components/pages/Main/Jobs/OverviewRevamp/JobSummary/useRollbackInProgress';
import { useTranslation } from 'react-i18next';

function RollbackInProgressNotification({ jobId }: { jobId: number }) {
  const { isRollbackInProgress, progress } = useRollbackInProgress({ jobId });

  const { t } = useTranslation(['job']);

  const text = progress === 100 ? t('job:notifications.jobRollbackAlmostFinished') : t('job:notifications.jobRollbackInProgress');

  if (isRollbackInProgress) {
    return <InlineNotification type="loading" title={text} percentage={progress} subtitle={t('job:notifications.stayTuned')} />;
  }
  return null;
}

export default RollbackInProgressNotification;
