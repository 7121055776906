import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { TimeIntervalRecord } from './TimeIntervalRecord';
import { FilterProperty } from '../../components/Visualisation/FilterProps';
import { MappingRecord } from './MappingRecord';

@JsonObject('VisualizationRecord')
export class VisualizationRecord extends TimeIntervalRecord {
  @JsonProperty('id', String)
  id: string | undefined;

  @JsonProperty('type', String)
  type: string | undefined;

  @JsonProperty('name', String)
  name: string | undefined;

  @JsonProperty('tooltip', String, true)
  tooltip: string | undefined;

  @JsonProperty('style', Any)
  // eslint-disable-next-line
  style: any | undefined;

  @JsonProperty('query', Any)
  // eslint-disable-next-line
  query: any | undefined;

  // eslint-disable-next-line
  @JsonProperty('hits', Array<any>(), true)
  // eslint-disable-next-line
  hits: Array<any> | undefined;

  @JsonProperty('aggs', Any, true)
  // eslint-disable-next-line
  aggs: any | undefined;

  @JsonProperty('mappings', Array<MappingRecord>(), true)
  mappings?: Array<MappingRecord> | [];

  @JsonProperty('filters', Array, true)
  filters?: Array<FilterProperty> | undefined;

  @JsonProperty('disable_filters', Boolean, true)
  disable_filters?: boolean | undefined;

  static getFilterFieldsFromQuery(props: VisualizationRecord, filterProps: Array<string>): Array<string> {
    const aggregations = props.aggs;
    for (const key in aggregations) {
      if (aggregations[key].terms != null) {
        filterProps.push(aggregations[key].terms.field);
      }
      if (aggregations[key].aggs != null) {
        this.getFilterFieldsFromQuery(aggregations[key], filterProps);
      }
    }
    return filterProps;
  }
}
