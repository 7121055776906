import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { PlatformSummaryRequest } from '../components/shared/models/platform/PlatformSummaryRequest';
import { PlatformSummaryResponse } from '../components/shared/models/platform/PlatformSummaryResponse';
import { config } from '../config';
import { getJsonConvert } from '../components/shared/utilities/json-convert';

export function usePlatformSummary() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery([config.api.platformSummary], () =>
    axios
      .post<{ resource: PlatformSummaryResponse }>(config.api.platformSummary, new PlatformSummaryRequest())
      .then((responseData: AxiosResponse<{ resource: PlatformSummaryResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, PlatformSummaryResponse)
      )
  );
}
