import { JsonObject, JsonProperty } from 'json2typescript';
import TransitionMulticlassMappingRecord from './TransitionMulticlassMappingRecord';

@JsonObject('JobCreateParameterRecord')
export class JobCreateParameterRecord {
  @JsonProperty('field', String)
  field?: string = undefined;

  @JsonProperty('value', String || Object)
  value?: string | object = undefined;

  @JsonProperty('multiclass_mapping', Object)
  // eslint-disable-next-line
  multiclass_mapping?: Record<string, any> = undefined;

  @JsonProperty('transitions_mapping', [TransitionMulticlassMappingRecord])
  transitions_mapping?: TransitionMulticlassMappingRecord[] = undefined;

  constructor(
    field: string,
    value: string,
    // eslint-disable-next-line
    multiclass_mapping: Record<string, any>,
    transition_multiclass_mapping?: TransitionMulticlassMappingRecord[]
  ) {
    this.field = field;
    this.value = value;
    this.multiclass_mapping = multiclass_mapping;
    this.transitions_mapping = transition_multiclass_mapping;
  }
}
