import { createContext, useContext, useState } from 'react';
import moment from 'moment';
import { FiltersProps, FilterProperty } from '../Visualisation/FilterProps';
import { UsecaseRecord } from '../../models/usecase/UsecaseRecord';

// eslint-disable-next-line
export const VisualizationsContext = createContext<VisualizationsContextType>(null as any);

export type VisualizationStateType = {
  applyTrigger: number;
  loadedVisuals: number;
  filtersProps: FiltersProps;
  clickableFilters: FiltersProps; // This is a subset of filtersProps
  intervalStart: moment.Moment;
  intervalEnd: moment.Moment;
  page: UsecaseRecord;
};

export type VisualizationsContextType = {
  state: VisualizationStateType;
  updateApplyTrigger: () => void;
  setFiltersProps: (filtersProps: FiltersProps) => void;
  setClickableFilters: (filterProps: FiltersProps) => void;
  appendClickableFilters: (filterProps: FilterProperty) => void;
  removeClickableFilters: (filterKey: string) => void;
  setIntervalStart: (intervalStart: moment.Moment) => void;
  setIntervalEnd: (intervalStart: moment.Moment) => void;
  setPage: (page: UsecaseRecord) => void;
  updateExport: () => void;
  setZeroToLoadedVisuals: () => void;
};

// eslint-disable-next-line
export function VisualizationsProvider(props: any) {
  const [state, setState] = useState<VisualizationStateType>({
    applyTrigger: 0,
    loadedVisuals: 0,
    filtersProps: { filters: new Array<FilterProperty>() },
    clickableFilters: { filters: new Array<FilterProperty>() },
    intervalStart: moment().subtract(3, 'hours'),
    intervalEnd: moment(),
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'UsecaseReco...
    page: null
  });

  const updateApplyTrigger = () => {
    setState((current) => ({
      ...current,
      applyTrigger: current.applyTrigger + 1
    }));
  };

  const updateExport = () => {
    setState((current) => ({
      ...current,
      loadedVisuals: current.loadedVisuals + 1
    }));
  };

  const setFiltersProps = (filtersProps: FiltersProps) => {
    setState((current) => ({
      ...current,
      filtersProps
    }));
  };

  const setClickableFilters = (filtersProps: FiltersProps) => {
    setState((current) => ({
      ...current,
      clickableFilters: filtersProps
    }));
  };

  const appendClickableFilters = (filterProperty: FilterProperty) => {
    if (state.clickableFilters.filters.find((f) => f.key === filterProperty.key) == null) {
      state.clickableFilters.filters.push(filterProperty);
    }
    setState((current) => ({
      ...current,
      clickableFilters: state.clickableFilters
    }));
  };

  const removeClickableFilters = (filterKey: string) => {
    state.clickableFilters.filters = state.clickableFilters.filters.filter((f) => f.key !== filterKey);
    setState((current) => ({
      ...current,
      clickableFilters: state.clickableFilters
    }));
  };

  const setIntervalStart = (intervalStart: moment.Moment) => {
    setState((current) => ({
      ...current,
      intervalStart
    }));
  };

  const setIntervalEnd = (intervalEnd: moment.Moment) => {
    setState((current) => ({
      ...current,
      intervalEnd
    }));
  };

  const setZeroToLoadedVisuals = () => {
    setState((current) => ({
      ...current,
      loadedVisuals: 0
    }));
  };

  const setPage = (page: UsecaseRecord) => {
    setState((current) => ({
      ...current,
      page
    }));
  };

  const value: VisualizationsContextType = {
    state,
    updateApplyTrigger,
    setFiltersProps,
    setClickableFilters,
    appendClickableFilters,
    removeClickableFilters,
    setIntervalStart,
    setIntervalEnd,
    setPage,
    updateExport,
    setZeroToLoadedVisuals
  };

  return <VisualizationsContext.Provider value={value}>{props.children}</VisualizationsContext.Provider>;
}

export function useVisualizationsContext(): VisualizationsContextType {
  return useContext(VisualizationsContext);
}
