import { useMemo, useState } from 'react';
import { Row, Col } from 'antd';

export interface TextBoxProps {
  body: string;
  // eslint-disable-next-line
  style: any;
}

export function TextBox(props: TextBoxProps) {
  // eslint-disable-next-line
  const [style, setStyle] = useState<any>({ color: 'transparent', 'font-weight': 'normal', 'text-align': 'left' });

  useMemo(() => {
    if (props.style !== undefined) {
      setStyle(props.style);
    }
  }, [props.style]);

  return (
    <>
      <div
        style={{
          backgroundColor: style.color,
          paddingTop: '12px',
          fontWeight: style['font-weight'],
          textAlign: style['text-align']
        }}
      >
        <Row>
          <Col span={24} className="flex flex-center flex-col flex-align-center">
            {props.body}
          </Col>
        </Row>
      </div>
    </>
  );
}
