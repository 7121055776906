import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import { FilterProperty } from './FilterProps';
import { useUtils } from '../../hooks/useUtils';

export interface PiechartProps {
  // eslint-disable-next-line
  data: Array<any>;
  colors: Array<string>;
  filterFields: Array<string>;
}

export function Piechart(props: PiechartProps) {
  const visualizationContext = useVisualizationsContext();
  const { overrideOwnStyleAttributes } = useUtils();

  const ownStyleAttributes = {
    height: '300px'
  };

  // eslint-disable-next-line
  const elementClicked = (el: any) => {
    const value = el.name != null ? el.name : el.value;
    const filterProps: FilterProperty = {
      key: props.filterFields[0],
      source: props.filterFields[0],
      values: [],
      selected: value
    };

    visualizationContext.appendClickableFilters(filterProps);
    visualizationContext.updateApplyTrigger();
  };

  const legendFormatter = (value: string) => (
    <span
      style={{
        width: '140px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        marginBottom: '-8px',
        paddingRight: '12px',
        textAlign: 'left'
      }}
    >
      {value}
    </span>
  );

  if (props.data != null && props.colors != null) {
    overrideOwnStyleAttributes(ownStyleAttributes, props);

    return (
      <div style={{ height: ownStyleAttributes.height }}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Legend
              wrapperStyle={{
                width: '180px',
                position: 'absolute',
                left: '60%',
                top: '30px'
              }}
              formatter={legendFormatter}
              onClick={elementClicked}
            />
            <Tooltip itemStyle={{ color: 'rgba(255, 255, 255, 0.65)' }} />
            <Pie
              data={props.data}
              cx={100}
              cy={150}
              labelLine
              outerRadius={90}
              fill="#8884d8"
              dataKey="value"
              onClick={elementClicked}
            >
              {props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={props.colors[index % props.colors.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return <></>;
}
