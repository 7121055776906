import { Space } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ReactNode, useCallback } from 'react';
import { ArcannaTableCellFilterButton } from '..';
import { ArcannaCopyToClipboardButton } from '../ArcannaCopyToClipboardButton';
import { FiltersFieldsRecord } from '../../models/filters/FiltersFieldsRecord';

import { EventExplorerStaticRuleFilterRecord } from '../../models/event-explorer/EventExplorerFiltersValuesRequest';
import {
  EFilterOperatorWithValue,
  EFilterOperatorWithoutValue,
  TFilterItem
} from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.types';
import {
  getAreFilterItemsEqual,
  getIsOperatorWithValue
} from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.utils';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

type ArcannaTableColumnHoverFiltersProps = {
  value: string | undefined;
  columnName: string;
  filterFields: FiltersFieldsRecord[];
  appliedAdvancedFilters: EventExplorerStaticRuleFilterRecord[];
  onAddFilter: (filter: TFilterItem) => void;
};
export function ArcannaTableColumnHoverFilters({
  value,
  appliedAdvancedFilters,
  columnName,
  onAddFilter
}: ArcannaTableColumnHoverFiltersProps) {
  const getIsFilterPresent = useCallback(
    (newFilter: TFilterItem) => {
      return Boolean(
        appliedAdvancedFilters.find((appliedFilter) => {
          return getAreFilterItemsEqual(appliedFilter as TFilterItem, newFilter);
        })
      );
    },
    [appliedAdvancedFilters]
  );

  const renderFilterButton = (
    operatorWithValue: EFilterOperatorWithValue,
    operatorWithoutValue: EFilterOperatorWithoutValue,
    icon: ReactNode
  ) => {
    const operator = !_.isNil(value) ? operatorWithValue : operatorWithoutValue;

    const filterToApply: TFilterItem = {
      id: uuidv4(),
      label: columnName,
      field: columnName,
      operator: operator,
      ...(getIsOperatorWithValue(operator)
        ? {
            value: value
          }
        : {}),
      status: 'active'
    } as TFilterItem;

    return !getIsFilterPresent(filterToApply) ? (
      <ArcannaTableCellFilterButton operator={operator} onClick={() => onAddFilter(filterToApply)} icon={icon} />
    ) : (
      <></>
    );
  };

  return (
    <Space
      size={0}
      style={{
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
      }}
    >
      <div>{renderFilterButton(EFilterOperatorWithValue.is, EFilterOperatorWithoutValue.notExists, <PlusCircleOutlined />)}</div>
      <div>{renderFilterButton(EFilterOperatorWithValue.isNot, EFilterOperatorWithoutValue.exists, <MinusCircleOutlined />)}</div>

      <div>
        <ArcannaCopyToClipboardButton value={value ?? ''} />
      </div>
    </Space>
  );
}
