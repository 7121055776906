import { Row } from '@tanstack/react-table';
import { TCheckboxState } from '../../../Checkbox';

export const getCheckboxState = (row: Row<unknown>): TCheckboxState => {
  if (row.getIsSelected()) {
    return 'checked';
  }

  return 'default';
};
