import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobFeedbackColumnsSummaryRequest')
export class JobFeedbackColumnsSummaryRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number = undefined;

  @JsonProperty('columns_type', String)
  columns_type?: string = undefined;

  constructor(job_id: number, columns_type: string) {
    super();
    this.job_id = job_id;
    this.columns_type = columns_type;
  }
}
