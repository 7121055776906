import { getMuiSwitchStyles } from './MuiSwitch';
import { getMuiBreadcrumbStyles } from './MuiBreadcrumb';
import { getMuiMenuStyles } from './MuiMenu';
import { getMuiChipStyles } from './MuiChip';
import { getMuiAutocompleteStyles } from './MuiAutocomplete';
import { getMuiDividerStyles } from './MuiDivider';
import { getMuiSkeletonStyles } from './MuiSkeleton';
import { getMuiTypographyStyles } from './MuiTypography';
import { getMuiPaginationStyles } from './MuiPagination';
import { getMuiSelectStyles } from './MuiSelect';
import { getMuiSvgIconStyles } from './MuiSvgIcon';
import { getMuiTableStyles } from './MuiTable';
import { getMuiCardStyles } from './MuiCard';
import { getMuiListStyles } from './MuiList';
import { getMuiAvatarStyles } from './MuiAvatar';
import { TComponentsOverrides, TPaletteOverrides } from '../../branding.types';
import { getMuiButtonStyles } from './MuiButton';
import { getMuiDrawerStyles } from './MuiDrawer';
import { getMuiTabsStyles } from './MuiTabs';
import { getMuiTextFieldStyles } from './MuiTextField';
import { getMuiToggleButtonStyles } from './MuiToggleButton';
import { getMuiDialogStyles } from './MuiDialog';
import { getMuiTooltipStyles } from './MuiTooltip';
import { getMuiIconButtonStyles } from './MuiIconButton';
import { getMuiDatePickerStyles } from './MuiDatePicker';
import { getMuiRadioStyles } from './MuiRadio';

export const getComponentOverrides = (palette: TPaletteOverrides): TComponentsOverrides => ({
  ...getMuiButtonStyles(palette),
  ...getMuiIconButtonStyles(palette),
  ...getMuiDrawerStyles(palette),
  ...getMuiTabsStyles(palette),
  ...getMuiAvatarStyles(palette),
  ...getMuiListStyles(palette),
  ...getMuiCardStyles(palette),
  ...getMuiSvgIconStyles(),
  ...getMuiTableStyles(palette),
  ...getMuiSelectStyles(palette),
  ...getMuiPaginationStyles(palette),
  ...getMuiTypographyStyles(palette),
  ...getMuiSkeletonStyles(palette),
  ...getMuiTextFieldStyles(palette),
  ...getMuiToggleButtonStyles(palette),
  ...getMuiDialogStyles(palette),
  ...getMuiTooltipStyles(palette),
  ...getMuiDividerStyles(palette),
  ...getMuiAutocompleteStyles(palette),
  ...getMuiChipStyles(palette),
  ...getMuiMenuStyles(),
  ...getMuiRadioStyles(),
  ...getMuiBreadcrumbStyles(palette),
  ...getMuiDatePickerStyles(palette),
  ...getMuiSwitchStyles(palette)
});
