import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { UpdateCustomFieldRequest } from '../../../components/shared/models/custom-field';
import { showErrorNotification, showSuccessNotification } from '../../../components/shared/utilities/notification';
import { config } from '../../../config';

export function useUpdateCustomField() {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  return useMutation((payload: UpdateCustomFieldRequest) => axios.post(config.api.updateCustomField, payload), {
    onSuccess: () => {
      showSuccessNotification(t('actionCompleted'), t('saveCustomFieldSuccess'));
    },
    onError: () => {
      showErrorNotification(t('error'), t('saveCustomFieldError'));
    },
    onSettled: (_, __, variables: UpdateCustomFieldRequest) => {
      queryClient.invalidateQueries([config.api.jobFeatureSelectionSummary, { job_id: variables.job_id }]);
    }
  });
}
