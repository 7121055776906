import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobInfoConfigRecord')
export class JobInfoConfigRecord {
  @JsonProperty('step', String)
  step: string;

  @JsonProperty('index', Number)
  index: number;

  @JsonProperty('order', Number)
  order: number;

  @JsonProperty('is_enabled', Boolean)
  is_enabled?: boolean;
}
