import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

export enum FeedbackSession {
  CURRENT = 'current',
  OVERALL = 'overall',
  PREVIOUS = 'previous'
}

@JsonObject('FeedbackStatisticsLabelsUsedRequest')
export class FeedbackStatisticsLabelsUsedRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  @JsonProperty('feedback_session', String)
  feedback_session?: FeedbackSession;

  constructor(job_id: number, feedback_session: FeedbackSession) {
    super();
    this.feedback_session = feedback_session;
    this.job_id = job_id;
  }
}
