import { Stack, Tooltip, useTheme } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';
import { useTranslation } from 'react-i18next';

function EmptyStringTooltip() {
  const { t } = useTranslation('common');
  const theme = useTheme();

  return (
    <Tooltip title={t('emptyStringTooltip')} placement="left" arrow enterDelay={1000} enterNextDelay={1000}>
      <Stack
        component="span"
        direction="row"
        gap={0.5}
        color={theme.palette.secondary[400]}
        sx={{ display: 'inline-flex', alignItems: 'center' }}
      >
        &quot;&quot;
        <Icon name={EIcon.AlertTriangle} fontSize="small" />
      </Stack>
    </Tooltip>
  );
}

export default EmptyStringTooltip;
