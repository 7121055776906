import { createContext, useCallback, useContext, useState } from 'react';

import axios, { CancelTokenSource } from 'axios';
import { ActionListRecord } from '../../../shared/models/job/ActionListRecord';
import { JobSummaryRecord } from '../../../shared/models/job/JobSummaryRecord';
import { JobSummaryRetrainInfo } from '../../../shared/models/job/JobSummaryRetrainInfo';
import { IntegrationJobParameterRecord } from '../../../shared/models/integration/job/IntegrationJobParameterRecord';
import { BtnType } from '../../../shared/components/Button/Button';
import { useJobNavigate } from '../../../shared/hooks/useJobNavigate';

// eslint-disable-next-line
export const JobContext = createContext<JobContextType>(null as any);

export type JobStateType = {
  id: number;
  loading: boolean;
  // eslint-disable-next-line
  editMode: any;
  tableData: JobSummaryRecord[];
  tablePage: number;
  tablePageSize: number;
  tableTotalSize: number;
  searchTerm: string;
  categoryId: string;
  subcategoryId: string;
  hasSelectedJobs: boolean;
  hasOneSelectedJob: boolean;
  hasStoppedJobs: boolean;
  hasStartedJobs: boolean;
  sortByColumn: string;
  sortDirection: string;
  initialTableData: JobSummaryRecord[];
  reloadJobData: boolean;
  showAction: boolean;
  action: ActionListRecord;
  selectedJob: JobSummaryRecord;
  hasFeaturesSelected: boolean;
  isFeatureSelectionEnabled: boolean;
  featureSelectionAvailabilityMessage: string;
  reloadRetrainInfo: boolean;
  retrainInfo: JobSummaryRetrainInfo;
  wizardIndex: number;
  integrationJobParameters: {
    input: IntegrationJobParameterRecord[];
    processor: IntegrationJobParameterRecord[];
    automation: IntegrationJobParameterRecord[];
    output: IntegrationJobParameterRecord[];
  };
  source: CancelTokenSource;
};

export type JobContextType = {
  state: JobStateType;
  flushJobState: () => void;
  setLoading: (loading: boolean) => void;
  // eslint-disable-next-line
  setEditMode: (editMode: any) => void;
  setTableData: (tableData: JobSummaryRecord[]) => void;
  setTablePage: (tablePage: number) => void;
  setTablePageSize: (tablePageSize: number) => void;
  setTableTotalSize: (tableTotalSize: number) => void;
  setSearchTerm: (searchTerm: string) => void;
  setCategoryId: (categoryId: string) => void;
  setSubcategoryId: (subcategoryId: string) => void;
  setHasSelectedJobs: (hasSelectedJobs: boolean) => void;
  setHasOneSelectedJob: (hasOneSelectedJob: boolean) => void;
  setHasStoppedJobs: (hasStoppedJobs: boolean) => void;
  setHasStartedJobs: (hasStartedJobs: boolean) => void;
  setInitialTableData: (initialTableData: JobSummaryRecord[]) => void;
  setSortByColumn: (sortByColumn: string) => void;
  setSortDirection: (sortDirection: string) => void;
  setReloadJobData: (reloadJobData: boolean) => void;
  setShowAction: (showAction: boolean) => void;
  setAction: (action: ActionListRecord) => void;
  setSelectedJob: (selectedJob: JobSummaryRecord) => void;
  setHasFeaturesSelected: (hasFeaturesSelected: boolean) => void;
  setIsFeatureSelectionEnabled: (isFeatureSelectionEnabled: boolean) => void;
  setFeatureSelectionAvailabilityMessage: (featureSelectionAvailabilityMessage: string) => void;
  setReloadRetrainInfo: (reloadRetrainInfo: boolean) => void;
  setRetrainInfo: (retrainInfo: JobSummaryRetrainInfo) => void;
  setWizardIndex: (wizardIndex: number) => void;
  setIntegrationJobParameters: (parameterType: string, jobParametersByType: IntegrationJobParameterRecord[]) => void;
  getIntegrationJobParameters: (parameterType: string) => IntegrationJobParameterRecord[];
  getJobAppHeaderTitle: (currentRouteTitle: string) => string;
  setNewSource: () => void;
  getJobTitle: () => string | null;
  getJobDashboardButtonType: () => string;
  getJobRetrainButtonType: () => string;
  getJobFeedbackButtonType: () => string;
  getJobEventExplorerButtonType: () => string;
  navigateToJobs: () => void;
};

const jobInitialState: JobStateType = {
  id: Math.random(),
  loading: true,
  editMode: {},
  tableData: [],
  tablePage: 1,
  tablePageSize: 25,
  tableTotalSize: 10000,
  searchTerm: '',
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  categoryId: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  subcategoryId: null,
  hasSelectedJobs: false,
  hasOneSelectedJob: false,
  hasStoppedJobs: false,
  hasStartedJobs: false,
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  sortByColumn: undefined,
  sortDirection: 'asc',
  initialTableData: [],
  reloadJobData: false,
  showAction: false,
  action: new ActionListRecord(),
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'JobSummaryR...
  selectedJob: null,
  hasFeaturesSelected: false,
  isFeatureSelectionEnabled: false,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  featureSelectionAvailabilityMessage: null,
  reloadRetrainInfo: false,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'JobSummaryR...
  retrainInfo: null,
  wizardIndex: 0,
  integrationJobParameters: {
    input: [],
    processor: [],
    automation: [],
    output: []
  },
  source: axios.CancelToken.source()
};

// eslint-disable-next-line
export function JobProvider(props: any) {
  const [state, setState] = useState<JobStateType>(jobInitialState);
  const { navigateToJobsByCategoryAndSearch } = useJobNavigate();

  const getJobTitle = useCallback(() => {
    if (state.selectedJob === null) {
      return null;
    }
    return `${state.selectedJob.title}`;
  }, [state.selectedJob]);

  const getJobDashboardButtonType = useCallback((): BtnType => {
    if (state.selectedJob && state.selectedJob.status && state.selectedJob.status.state === 'STARTED') {
      return 'success';
    }
    return 'grey';
  }, [state.selectedJob]);

  const getJobRetrainButtonType = useCallback((): BtnType => {
    if (
      state.selectedJob &&
      state.selectedJob.status &&
      state.selectedJob.status.state === 'STARTED' &&
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      state.selectedJob.status.feedbackDocumentsCount > 0
    ) {
      return 'success';
    }
    return 'grey';
  }, [state.selectedJob]);

  const getJobFeedbackButtonType = useCallback((): BtnType => {
    if (state.selectedJob && state.selectedJob.status && state.selectedJob.status.state === 'STARTED') {
      return 'success';
    }
    return 'grey';
  }, [state.selectedJob]);

  const getJobEventExplorerButtonType = useCallback((): BtnType => {
    if (state.selectedJob && state.selectedJob.status && state.selectedJob.status.state === 'STARTED') {
      return 'success';
    }
    return 'grey';
  }, [state.selectedJob]);

  const flushJobState = useCallback(() => {
    setState(jobInitialState);
  }, []);

  const setLoading = useCallback((loading: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      loading
    }));
  }, []);

  // eslint-disable-next-line
  const setEditMode = useCallback((editMode: any) => {
    setState((current: JobStateType) => ({
      ...current,
      editMode
    }));
  }, []);

  const setTableData = useCallback((tableData: JobSummaryRecord[]) => {
    setState((current: JobStateType) => ({
      ...current,
      tableData
    }));
  }, []);

  const setTablePage = useCallback((tablePage: number) => {
    setState((current: JobStateType) => ({
      ...current,
      tablePage
    }));
  }, []);

  const setTablePageSize = useCallback((tablePageSize: number) => {
    setState((current: JobStateType) => ({
      ...current,
      tablePageSize
    }));
  }, []);

  const setTableTotalSize = useCallback((tableTotalSize: number) => {
    setState((current: JobStateType) => ({
      ...current,
      tableTotalSize
    }));
  }, []);

  const setSearchTerm = useCallback((searchTerm: string) => {
    setState((current: JobStateType) => ({
      ...current,
      searchTerm
    }));
  }, []);

  const setCategoryId = useCallback((categoryId: string) => {
    setState((current: JobStateType) => ({
      ...current,
      categoryId
    }));
  }, []);

  const setSubcategoryId = useCallback((subcategoryId: string) => {
    setState((current: JobStateType) => ({
      ...current,
      subcategoryId
    }));
  }, []);

  const setSortByColumn = useCallback((sortByColumn: string) => {
    setState((current: JobStateType) => ({
      ...current,
      sortByColumn
    }));
  }, []);

  const setSortDirection = useCallback((sortDirection: string) => {
    setState((current: JobStateType) => ({
      ...current,
      sortDirection
    }));
  }, []);

  const setHasSelectedJobs = useCallback((hasSelectedJobs: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      hasSelectedJobs
    }));
  }, []);

  const setHasOneSelectedJob = useCallback((hasOneSelectedJob: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      hasOneSelectedJob
    }));
  }, []);

  const setHasStoppedJobs = useCallback((hasStoppedJobs: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      hasStoppedJobs
    }));
  }, []);

  const setHasStartedJobs = useCallback((hasStartedJobs: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      hasStartedJobs
    }));
  }, []);

  const setInitialTableData = useCallback((initialTableData: JobSummaryRecord[]) => {
    setState((current: JobStateType) => ({
      ...current,
      initialTableData
    }));
  }, []);

  const setNewSource = useCallback(() => {
    setState((current: JobStateType) => ({
      ...current,
      source: axios.CancelToken.source()
    }));
  }, []);

  const setReloadJobData = useCallback((reloadJobData: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      reloadJobData
    }));
  }, []);

  const setShowAction = useCallback((showAction: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      showAction
    }));
  }, []);

  const setAction = useCallback((action: ActionListRecord) => {
    setState((current: JobStateType) => ({
      ...current,
      showAction: true,
      action
    }));
  }, []);

  const setSelectedJob = useCallback((selectedJob: JobSummaryRecord) => {
    setState((current: JobStateType) => ({
      ...current,
      selectedJob
    }));
  }, []);

  const setHasFeaturesSelected = useCallback((hasFeaturesSelected: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      hasFeaturesSelected
    }));
  }, []);

  const setIsFeatureSelectionEnabled = useCallback((isFeatureSelectionEnabled: boolean) => {
    setState((current: JobStateType) => ({
      ...current,
      isFeatureSelectionEnabled
    }));
  }, []);

  const setFeatureSelectionAvailabilityMessage = useCallback((featureSelectionAvailabilityMessage: string) => {
    setState((current: JobStateType) => ({
      ...current,
      featureSelectionAvailabilityMessage
    }));
  }, []);

  const setReloadRetrainInfo = useCallback((reloadRetrainInfo: boolean) => {
    // @ts-expect-error TS(2345): Argument of type '(current: JobStateType) => { ret...
    setState((current: JobStateType) => ({
      ...current,
      retrainInfo: null,
      reloadRetrainInfo
    }));
  }, []);

  const setRetrainInfo = useCallback((retrainInfo: JobSummaryRetrainInfo) => {
    setState((current: JobStateType) => ({
      ...current,
      retrainInfo
    }));
  }, []);

  const setWizardIndex = useCallback((wizardIndex: number) => {
    setState((current: JobStateType) => ({
      ...current,
      wizardIndex
    }));
  }, []);

  const setIntegrationJobParameters = useCallback(
    (parameterType: string, jobParametersByType: IntegrationJobParameterRecord[]) => {
      const jobParameters = Object.assign(state.integrationJobParameters);
      jobParameters[parameterType] = jobParametersByType;

      setState((current: JobStateType) => ({
        ...current,
        integrationJobParameters: jobParameters
      }));
    },
    [state.integrationJobParameters]
  );

  const getIntegrationJobParameters = useCallback(
    (parameterType: string): IntegrationJobParameterRecord[] => {
      const jobParameters = Object.assign(state.integrationJobParameters);
      return jobParameters[parameterType];
    },
    [state.integrationJobParameters]
  );

  const getJobAppHeaderTitle = useCallback(
    (currentRouteTitle: string) => {
      if (state.selectedJob === null) {
        return currentRouteTitle;
      }
      return `${currentRouteTitle} / ${state.selectedJob.title}`;
    },
    [state.selectedJob]
  );

  const navigateToJobs = useCallback(() => {
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
    setSortByColumn(undefined);
    // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
    setSortDirection(undefined);
    // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
    setSelectedJob(null);
    navigateToJobsByCategoryAndSearch(state.categoryId, state.subcategoryId, state.searchTerm);
  }, [
    state.categoryId,
    state.subcategoryId,
    state.searchTerm,
    navigateToJobsByCategoryAndSearch,
    setSortByColumn,
    setSortDirection,
    setSelectedJob
  ]);

  const value: JobContextType = {
    state,
    flushJobState,
    setLoading,
    setEditMode,
    setTableData,
    setTablePage,
    setTableTotalSize,
    setTablePageSize,
    setSearchTerm,
    setCategoryId,
    setSubcategoryId,
    setHasSelectedJobs,
    setHasOneSelectedJob,
    setHasStoppedJobs,
    setHasStartedJobs,
    setInitialTableData,
    setSortByColumn,
    setSortDirection,
    setShowAction,
    setAction,
    setReloadJobData,
    setSelectedJob,
    setHasFeaturesSelected,
    setIsFeatureSelectionEnabled,
    setFeatureSelectionAvailabilityMessage,
    setReloadRetrainInfo,
    setRetrainInfo,
    setWizardIndex,
    setIntegrationJobParameters,
    getIntegrationJobParameters,
    getJobAppHeaderTitle,
    setNewSource,
    getJobTitle,
    getJobDashboardButtonType,
    getJobRetrainButtonType,
    getJobFeedbackButtonType,
    getJobEventExplorerButtonType,
    navigateToJobs
  };

  const { children } = props;
  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
}

export function useJobContext(): JobContextType {
  return useContext(JobContext);
}
