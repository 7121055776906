import { SvgIcon, SvgIconProps } from '@mui/material';
import { EIcon } from './Icon.types';
import { SVG_ICONS } from './Icon.constants';

type TIconProps = SvgIconProps & {
  name: EIcon;
};

function Icon({ name, ...otherProps }: TIconProps) {
  const config = SVG_ICONS[name];

  return (
    config && (
      <SvgIcon viewBox={config.viewBox} {...otherProps}>
        {config.icon}
      </SvgIcon>
    )
  );
}

export default Icon;
