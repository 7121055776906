import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobIntegrationThrottleRecord')
export class JobIntegrationThrottleRecord {
  @JsonProperty('enabled', Boolean)
  enabled?: boolean = false;

  @JsonProperty('ttl', Number)
  ttl?: number = 3600;

  @JsonProperty('interval', String)
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un...
  interval?: string = null;

  @JsonProperty('fields', [String])
  fields?: string[] = [];

  constructor(enabled: boolean, ttl: number, interval: string, fields: string[]) {
    this.enabled = enabled;
    this.ttl = ttl;
    this.interval = interval;
    this.fields = fields;
  }
}
