import { useState, useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { Category, GeoMap, GeoMapProps } from '../Visualisation/GeoMap';
import { MultiLayerRecord } from '../../models/usecase/MultiLayerRecord';
import { useVisualizationsContext } from './Visualizations.context';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';

/* Transforms dot notation like this "destination.geo" to obj[destination][geo] */
// eslint-disable-next-line
function accessDotNotation(obj: any, key: string) {
  return key.split('.').reduce((o, i) => o[i], obj);
}

export function QueryGeoMap(props: MultiLayerRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<MultiLayerRecord>();
  // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
  const [markers, setMarkers] = useState<GeoMapProps>(null);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], MultiLayerRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  /* Returns position list for a specific map layer */
  // eslint-disable-next-line
  function getPositionPoints(layerProps: any) {
    if (layerProps.type === 'point') {
      let positions = layerProps.hits.map(
        // eslint-disable-next-line
        (hit: any) => {
          const geospatialField = accessDotNotation(hit._source, layerProps.geospatial_field);
          if (typeof geospatialField === 'string' || geospatialField instanceof String) {
            return [Number(geospatialField.split(', ')[0]), Number(geospatialField.split(', ')[1])];
          }
          if (geospatialField.location != null) {
            return [
              // eslint-disable-next-line no-underscore-dangle
              geospatialField.location.lat,
              // eslint-disable-next-line no-underscore-dangle
              geospatialField.location.lon
            ];
          }
          return [];
        }
      );
      // eslint-disable-next-line
      positions = positions.filter((p: Array<any>) => p.length > 0);
      return positions;
    }
    return [];
  }

  /* Returns lines list for a specific map layer */
  // eslint-disable-next-line
  function getLines(layerProps: any) {
    // eslint-disable-next-line
    const lines: Array<any> = [];
    if (layerProps.type === 'line') {
      const aggregations = layerProps.aggs;
      for (const key in aggregations) {
        const { buckets } = aggregations[key];
        for (let j = 0; j < buckets.length; j += 1) {
          for (const subKey in buckets[j]) {
            const subBuckets = buckets[j][subKey].buckets;
            if (subBuckets !== undefined) {
              for (let k = 0; k < subBuckets.length; k += 1) {
                const dstStr = buckets[j].key;
                const srcStr = subBuckets[k].key;
                const line = [dstStr.split(', '), srcStr.split(', ')];
                lines.push(line);
              }
            }
          }
        }
      }
    }
    return lines;
  }

  // eslint-disable-next-line
  function getHeatmap(layerProps: any) {
    const heatmaps = Array<[number, number, number]>();
    if (layerProps.type === 'heatmap') {
      const aggregations = layerProps.aggs;

      let firstEntryCount = 0;

      for (const key in aggregations) {
        for (const subKey1 in aggregations[key].buckets) {
          for (const subKey2 in aggregations[key].buckets[subKey1]) {
            const heatmapEntry = Array<number>();
            const entry = aggregations[key].buckets[subKey1][subKey2];
            if (entry.location != null) {
              if (firstEntryCount === 0) {
                firstEntryCount = entry.count;
              }
              heatmapEntry.push(entry.location.lat);
              heatmapEntry.push(entry.location.lon);
              heatmapEntry.push(entry.count / firstEntryCount);
              heatmaps.push([heatmapEntry[0], heatmapEntry[1], heatmapEntry[2]]);
            }
          }
        }
      }
    }
    return heatmaps;
  }

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    const categories: Array<Category> = [];
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    for (let i = 0; i < visualizationRecord.layers.length; i += 1) {
      const category = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        name: visualizationRecord.layers[i].name,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        color: visualizationRecord.layers[i].style.color,
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        positions: getPositionPoints(visualizationRecord.layers[i]),
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        heatmaps: getHeatmap(visualizationRecord.layers[i]),
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        lines: getLines(visualizationRecord.layers[i])
      };
      categories.push(category);
    }

    setMarkers({
      categories,
      style: props.style
    });

    setLoading(false);
    visualisationContext.updateExport();

    // eslint-disable-next-line
  }, [visualizationRecord]);

  const { name } = props;
  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{name}</div>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        <GeoMap key={name + Math.random()} {...markers} />
      </Spin>
    </>
  );
}
