import Joi from 'joi';
import { TFunction } from 'react-i18next';
import { TIdeaBoxFormValues } from './IdeaBoxDialog.types';

export const getIdeaBoxFormSchema = (t: TFunction) =>
  Joi.object({
    topic: Joi.string()
      .required()
      .messages({
        'any.required': t('topicRequired'),
        'string.empty': t('topicRequired')
      }),
    message: Joi.string()
      .required()
      .messages({
        'any.required': t('messageRequired'),
        'string.empty': t('messageRequired')
      })
  });

export const getIdeaBoxDefaultValues = (): TIdeaBoxFormValues => ({
  topic: '',
  message: ''
});
