import { Stack, StackProps, styled } from '@mui/material';

const StyledRHFCustomItemList = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'isError';
  }
})<StackProps & { isError: boolean }>(({ theme, isError }) => ({
  '.list-wrapper': {
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },

  '.list-item': {
    borderRadius: '4px',
    border: `1px solid ${theme.palette.secondary[700]}`,
    background: theme.palette.secondary[800],
    height: '64px',
    padding: 0
  },

  '.icon-wrapper': {
    background: theme.palette.secondary[900],
    height: '100%',
    width: '64px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px'
  },

  '.add-button': {
    all: 'unset',
    outline: 0,
    padding: '10px 12px',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '4px',
    border: `1px dashed ${theme.palette.secondary[600]}`,
    color: theme.palette.secondary[200],

    ...(isError && {
      borderColor: theme.palette.error[600]
    })
  }
}));

export default StyledRHFCustomItemList;
