import { NavLink } from 'react-router-dom';
import style from './JobNavigation.module.css';
import _ from 'lodash';

type withNavigationItemPropsType = {
  color?: string;
  active?: boolean;
  activeColor?: string;
  to: string;
  dataTestId: string;
};

// eslint-disable-next-line
export const withNavigationItem = (Icon: Function) => (props: withNavigationItemPropsType) => {
  return (
    <NavLink
      to={props.to}
      className={style.navigationIcon}
      activeClassName={style.activeNavigationIcon}
      // @ts-expect-error TS(2322): Type '() => boolean | undefined' is not assignable...
      isActive={() => props.active}
      data-test-id={props.dataTestId}
    >
      {/* eslint-disable-next-line */}
      <Icon {..._.omit(props, 'dataTestId', 'active', 'activeColor')} color={props.active ? props.activeColor : props.color} />
    </NavLink>
  );
};
