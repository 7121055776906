import { useEffect } from 'react';
import { useAuth } from 'src/components/pages/Main/Login/Auth.context';

type TUsePageLeaveBlockerProps = {
  message: string;
  isBlocked: boolean;
};

export function usePageLeaveBlocker({ isBlocked, message }: TUsePageLeaveBlockerProps) {
  const { state: authState } = useAuth();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isBlocked || authState.isLoggingOut) {
        return;
      }
      event.preventDefault();
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocked, message, authState.isLoggingOut]);
}
