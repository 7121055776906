import { EJobTrainingState } from 'src/components/shared/models/job/EJobTrainingState';
import { JobRetrainStartingTag } from './components/JobRetrainStartingTag';
import { JobRetrainCollectingFeedbackTag } from './components/JobRetrainCollectingFeedbackTag';
import { JobRetrainInProgressTag } from './components/JobRetrainInProgressTag';
import { JobRetrainStateErrorTag } from './components/JobRetrainStateErrorTag';
import { JobAutomaticRetrainTag } from './components/JobAutomaticRetrainTag';
import { ReactNode } from 'react';

type TJobRetrainStateProps = {
  retrainState: EJobTrainingState | undefined;
  message?: string;
};

function JobRetrainState({ retrainState, message }: TJobRetrainStateProps) {
  const jobRetrainStateTagMap: Record<EJobTrainingState, ReactNode> = {
    [EJobTrainingState.STARTED]: <JobRetrainStartingTag />,
    [EJobTrainingState.COLLECTING_FEEDBACK]: <JobRetrainCollectingFeedbackTag />,
    [EJobTrainingState.RETRAIN_IN_PROGRESS]: <JobRetrainInProgressTag />,
    [EJobTrainingState.ERROR]: <JobRetrainStateErrorTag message={message} />,
    [EJobTrainingState.AUTOMATIC_RETRAIN]: <JobAutomaticRetrainTag />,
    [EJobTrainingState.FINISHED]: null
  };

  const component = jobRetrainStateTagMap[retrainState as EJobTrainingState];

  return retrainState && component ? <>{component}</> : null;
}

export default JobRetrainState;
