import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('FeedbackStatisticsConsensusResponse')
export class FeedbackStatisticsConsensusResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('similar', Number)
  similar?: number;

  @JsonProperty('different', Number)
  different?: number;
}
