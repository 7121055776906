import styles from './styles.module.css';

type PercentageBarProps = {
  percentage: string;
};
export function PercentageBar({ percentage }: PercentageBarProps) {
  return (
    <div className={styles.root}>
      <span className={styles.bar} />
      <span className={styles.barPercentage} style={{ width: `${percentage}%` }} />
    </div>
  );
}

export default PercentageBar;
