import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { FeedbackBatchBucketRow } from './FeedbackBatchBucketRow';

@JsonObject('FeedbackBucketAlertsResponse')
export class FeedbackBucketAlertsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('expanded_columns', [String])
  expanded_columns?: string[] = [];

  @JsonProperty('rows', [FeedbackBatchBucketRow])
  rows?: FeedbackBatchBucketRow[] = [];

  @JsonProperty('total', Number)
  total?: number;
}
