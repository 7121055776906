import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { FieldInsightsGeneralStatistics } from './FieldInsightsGeneralStatistics';
import { FieldInsightsTopValue } from './FieldInsightsTopValue';
import { NumericSummary } from './NumericSummary';

@JsonObject('FieldInsightsResponse')
export class FieldInsightsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('top_values', [FieldInsightsTopValue])
  top_values: FieldInsightsTopValue[] = [];

  @JsonProperty('general_statistics', FieldInsightsGeneralStatistics)
  general_statistics: FieldInsightsGeneralStatistics;

  @JsonProperty('numeric_summary', NumericSummary)
  numeric_summary?: NumericSummary;
}
