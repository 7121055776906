import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('IntegrationSummaryFilters')
export class IntegrationSummaryFilters {
  @JsonProperty('states', [String])
  states?: string[] = undefined;

  @JsonProperty('roles', [String])
  roles?: string[] = undefined;
}

@JsonObject('IntegrationSummaryRequest')
export class IntegrationSummaryRequest extends CommonRequest {
  @JsonProperty('event_count', Number)
  event_count?: number = undefined;

  @JsonProperty('include_internal', Boolean)
  include_internal?: boolean = undefined;

  @JsonProperty('job_category_id', Number)
  job_category_id?: number = undefined;

  @JsonProperty('filters', IntegrationSummaryFilters)
  filters?: IntegrationSummaryFilters = undefined;

  constructor(eventCount: number, include_internal?: boolean, job_category_id?: number, filters?: IntegrationSummaryFilters) {
    super();
    this.event_count = eventCount;
    this.include_internal = include_internal;
    this.job_category_id = job_category_id;
    this.filters = filters;
  }
}
