import { useMemo, useState, useEffect } from 'react';
import moment, { unitOfTime } from 'moment';
import { Button, Col, Modal } from 'antd';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import { TimeRange, TimeRangeProps } from './TimeRange';

export function TimeFilterFragment() {
  const visualizationContext = useVisualizationsContext();
  const [defaultAmount, setDefaultAmount] = useState<number>(3);
  const [defaultUnit, setDefaultUnit] = useState<unitOfTime.Base>('h');
  const [timeRangeProps, setTimeRangeProps] = useState<TimeRangeProps>();

  useMemo(() => {
    if (visualizationContext.state.page.default_timerange != null) {
      const myamount = visualizationContext.state.page.default_timerange.find((f) => f.amount);
      const myunits = visualizationContext.state.page.default_timerange.find((f) => f.units);

      if (myamount != null && myunits != null) {
        // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig...
        setDefaultAmount(myamount.amount);
        // @ts-expect-error TS(2345): Argument of type 'Base | undefined' is not assigna...
        setDefaultUnit(myunits.units);
      }
    }
  }, [visualizationContext.state.page.default_timerange]);

  // Set time range in visualisation context (to be used by visuals)
  useEffect(() => {
    const start = moment().subtract(defaultAmount, defaultUnit);
    const end = moment();
    visualizationContext.setIntervalStart(start);
    visualizationContext.setIntervalEnd(end);

    // Callback for timerange
    setTimeRangeProps({
      intervalStart: start,
      intervalEnd: end,
      onIntervalChanged(dates: [moment.Moment, moment.Moment]) {
        // @ts-expect-error TS(2345): Argument of type 'Moment | null' is not assignable...
        visualizationContext.setIntervalStart(dates != null ? dates[0] : null);
        // @ts-expect-error TS(2345): Argument of type 'Moment | null' is not assignable...
        visualizationContext.setIntervalEnd(dates != null ? dates[1] : null);
      }
    });
    // eslint-disable-next-line
  }, [defaultAmount, defaultUnit]);

  // Callback for applying selected filters
  const applyFilters = () => {
    visualizationContext.setZeroToLoadedVisuals();
    visualizationContext.updateApplyTrigger();
  };

  const applyFiltersOrDisplayModal = () => {
    if (visualizationContext.state.intervalStart === null || visualizationContext.state.intervalEnd == null) {
      Modal.error({
        title: ' No Time interval is selected',
        content: 'Please select a valid interval and click Apply'
      });
    } else {
      applyFilters();
    }
  };

  return (
    <>
      <Col span="15">
        <div style={{ marginTop: 4, textAlign: 'right', marginRight: 5 }}>
          Time range (default: {defaultAmount}
          {defaultUnit})
        </div>
      </Col>
      <Col span="9" className="flex flex-end primary-item">
        {timeRangeProps != null && <TimeRange {...timeRangeProps} />}
        &nbsp;&nbsp;
        <Button id="apply_filter_btn" onClick={applyFiltersOrDisplayModal} type="primary">
          Apply
        </Button>
      </Col>
    </>
  );
}
