import { Dialog, DialogProps, styled } from '@mui/material';

const StyledIdeaBoxDialog = styled(Dialog)<DialogProps>(() => ({
  '& .MuiDialog-paper': {
    padding: '24px 40px'
  },
  '.topicInput': {
    marginTop: 0,
    paddingBottom: '24px'
  },
  '.messageInput': {
    margin: 0
  }
}));

export default StyledIdeaBoxDialog;
