import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('JobIsRetrainAvailableResponse')
export class JobIsRetrainAvailableResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('enabled', Boolean)
  enabled?: boolean = false;
}
