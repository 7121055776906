import { withTransaction } from '@elastic/apm-rum-react';
import { Usecase } from './Usecase';
import { VisualizationsProvider } from '../../../shared/components/QueryVisualisation/Visualizations.context';

// eslint-disable-next-line
function UsecaseWrapper(props: any) {
  return (
    <VisualizationsProvider>
      <Usecase {...props} />
    </VisualizationsProvider>
  );
}
export default withTransaction('UsecaseWrapper', 'component')(UsecaseWrapper);
