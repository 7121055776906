import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ELinkGeneratorPages } from '../RedirectLinkGenerator.types';
import { constructClusterClusterIdRedirectURL, constructExplorerClusterIdRedirectURL } from '../RedirectLinkGenerator.utils';
import { Spinner } from '@arcanna/generic';

type TRedirectRCAFromJobIdAndClusterIdProps = {
  pageId: ELinkGeneratorPages;
  clusterId: string;
  jobId: string;
};

function RedirectRCAFromJobIdAndClusterId({ pageId, clusterId, jobId }: TRedirectRCAFromJobIdAndClusterIdProps) {
  const history = useHistory();

  useEffect(() => {
    if (pageId === ELinkGeneratorPages.clusterWithClusterId) {
      return history.replace(constructClusterClusterIdRedirectURL({ jobId: jobId, clusterId }));
    }
    if (pageId === ELinkGeneratorPages.explorerWithClusterId) {
      return history.replace(constructExplorerClusterIdRedirectURL({ jobId: jobId, clusterId }));
    }
  }, [pageId, jobId, history, clusterId]);

  return <Spinner isOverlay />;
}

export default RedirectRCAFromJobIdAndClusterId;
