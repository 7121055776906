import { SVGProps } from 'react';

type LifeBuoyIconProps = SVGProps<SVGSVGElement> & {
  fill?: string;
};
export function LifeBuoyIcon({ fill = 'none', ...props }: LifeBuoyIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="life-buoy-01" clipPath="url(#clip0_14539_16696)">
        <path
          id="Icon"
          // eslint-disable-next-line max-len
          d="M6.09088 6.0909L3.286 3.28601M3.286 12.7141L6.11202 9.88808M9.90742 9.90922L12.7123 12.7141M12.7123 3.28601L9.88586 6.11245M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00004 14.6667C4.31814 14.6667 1.33337 11.6819 1.33337 8.00004C1.33337 4.31814 4.31814 1.33337 8.00004 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004ZM10.6667 8.00004C10.6667 9.4728 9.4728 10.6667 8.00004 10.6667C6.52728 10.6667 5.33337 9.4728 5.33337 8.00004C5.33337 6.52728 6.52728 5.33337 8.00004 5.33337C9.4728 5.33337 10.6667 6.52728 10.6667 8.00004Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14539_16696">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
