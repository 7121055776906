export function TrainIcon({ color }: { color?: string }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line
        d="M13.3333 6.66666V4.16666L15.8333 1.66666L16.6667 3.33333L18.3333 4.16666L15.8333 6.66666H13.3333ZM13.3333 6.66666L10 9.99995M18.3334 10C18.3334 14.6024 14.6024 18.3333 10 18.3333C5.39765 18.3333 1.66669 14.6024 1.66669 10C1.66669 5.39762 5.39765 1.66666 10 1.66666M14.1667 10C14.1667 12.3012 12.3012 14.1667 10 14.1667C7.69883 14.1667 5.83335 12.3012 5.83335 10C5.83335 7.69881 7.69883 5.83333 10 5.83333"
        stroke={color ? color : 'var(--secondary-color-nb-300)'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
