/* eslint-disable max-len */
function DeleteArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.3333 6.00008L7.33333 10.0001M7.33333 6.00008L11.3333 10.0001M1.81333 8.64008L4.69333 12.4801C4.928 12.793 5.04533 12.9494 5.19403 13.0622C5.32574 13.1622 5.47488 13.2368 5.63385 13.2822C5.81334 13.3334 6.00889 13.3334 6.4 13.3334H11.4667C12.5868 13.3334 13.1468 13.3334 13.5746 13.1154C13.951 12.9237 14.2569 12.6177 14.4487 12.2414C14.6667 11.8136 14.6667 11.2535 14.6667 10.1334V5.86675C14.6667 4.74664 14.6667 4.18659 14.4487 3.75877C14.2569 3.38244 13.951 3.07648 13.5746 2.88473C13.1468 2.66675 12.5868 2.66675 11.4667 2.66675H6.4C6.00889 2.66675 5.81334 2.66675 5.63385 2.71801C5.47488 2.76341 5.32574 2.83798 5.19403 2.93792C5.04533 3.05075 4.928 3.20719 4.69333 3.52008L1.81333 7.36008C1.6412 7.58959 1.55514 7.70434 1.52196 7.83037C1.49268 7.94162 1.49268 8.05855 1.52196 8.16979C1.55514 8.29582 1.6412 8.41058 1.81333 8.64008Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteArrow;
