import { createContext, useCallback, useContext, useState } from 'react';
import { CustomLabel } from '../../../../shared/models/job/AdvancedJobSettings';
import JobOverview from './JobOverview';

export type JobOverviewPeriod = 'last24h' | 'last7days' | 'last30days' | 'alltime';

export enum JobOverviewPeriodOptions {
  LAST_24H = 'last24h',
  LAST_7DAYS = 'last7days',
  LAST_30DAYS = 'last30days',
  ALL_TIME = 'alltime'
}

export const JobOverviewContext = createContext<JobOverviewContextType | undefined>(undefined);

export type JobOverviewStateType = {
  loading: boolean;
  period: JobOverviewPeriodOptions;
  startDate: Date;
  intialisedSelectedLabels: boolean;
  selectedLabels: CustomLabel[];
  editTimeMetrics: boolean;
};

export type JobOverviewContextType = {
  state: JobOverviewStateType;
  setPeriod: (period: JobOverviewPeriodOptions) => void;
  setSelectedLabels: (selectedLabels: CustomLabel[]) => void;
  setStartDateByPeriod: (period: JobOverviewPeriodOptions) => void;
  setEditTimeMetrics: (editTimeMetrics: boolean) => void;
};

const JobOverviewInitialState: JobOverviewStateType = {
  loading: false,
  period: JobOverviewPeriodOptions.LAST_24H,
  startDate: new Date(Date.now() - 24 * 3600 * 1000),
  intialisedSelectedLabels: false,
  selectedLabels: [],
  editTimeMetrics: false
};

export function JobOverviewProvider() {
  const [state, setState] = useState<JobOverviewStateType>(JobOverviewInitialState);

  const setPeriod = useCallback((period: JobOverviewPeriodOptions) => {
    setState((current: JobOverviewStateType) => ({
      ...current,
      period
    }));
  }, []);

  const setSelectedLabels = useCallback((selectedLabels: CustomLabel[]) => {
    setState((current: JobOverviewStateType) => ({
      ...current,
      intialisedSelectedLabels: true,
      selectedLabels
    }));
  }, []);

  const setStartDateByPeriod = useCallback((period: JobOverviewPeriodOptions) => {
    let startDate: Date | null = null;
    switch (period) {
      case JobOverviewPeriodOptions.LAST_24H:
        startDate = new Date(Date.now() - 24 * 3600 * 1000);
        break;
      case JobOverviewPeriodOptions.LAST_30DAYS:
        startDate = new Date(Date.now() - 24 * 30 * 3600 * 1000);
        break;
      case JobOverviewPeriodOptions.LAST_7DAYS:
        startDate = new Date(Date.now() - 24 * 7 * 3600 * 1000);
        break;
    }
    // @ts-expect-error TS(2345): Argument of type '(current: JobOverviewStateType) ...
    setState((current: JobOverviewStateType) => ({
      ...current,
      startDate
    }));
  }, []);

  const setEditTimeMetrics = useCallback((editTimeMetrics: boolean) => {
    setState((current: JobOverviewStateType) => ({
      ...current,
      editTimeMetrics
    }));
  }, []);

  const value: JobOverviewContextType = {
    state,
    setPeriod,
    setSelectedLabels,
    setStartDateByPeriod,
    setEditTimeMetrics
  };
  return (
    <JobOverviewContext.Provider value={value}>
      <JobOverview />
    </JobOverviewContext.Provider>
  );
}

export function useJobOverviewContext(): JobOverviewContextType {
  const context = useContext(JobOverviewContext);

  if (context === undefined) {
    throw new Error('useJobOverviewContext must be used within JobOverview context');
  }

  return context;
}
