import { Stack, StackProps, styled } from '@mui/material';

const StyledTable = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'tableSize' && propName !== 'isLoading';
  }
})<StackProps & { isLoading: boolean }>(({ isLoading, theme }) => ({
  overflowY: 'auto',
  marginBottom: 1,
  maxHeight: '62vh',
  position: 'relative',
  border: `1px solid ${theme.palette.secondary[800]}`,
  borderRadius: '4px',

  ...(isLoading
    ? {
        border: `1px solid ${theme.palette.secondary[700]}`
      }
    : {}),

  '.MuiTableCell-root.MuiTableCell-head': {
    padding: '0 8px 0 4px',
    height: '40px'
  }
}));

export default StyledTable;
