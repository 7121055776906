import { JsonObject, JsonProperty } from 'json2typescript';
import { LicenseInfoFeatures } from './LicenseInfoFeatures';

@JsonObject('LicenseInfoRecord')
export class LicenseInfoRecord {
  @JsonProperty('active', Boolean)
  active: boolean | undefined;

  @JsonProperty('plan', String)
  plan: string | undefined;

  @JsonProperty('is_expired', Boolean)
  is_expired: boolean | undefined;

  @JsonProperty('features', LicenseInfoFeatures)
  features: LicenseInfoFeatures | undefined;

  @JsonProperty('expiration_date', String)
  expiration_date: string | undefined;
}
