import { Radio, RadioProps } from '@mui/material';
import { RadioCheckedIcon, RadioDisabledIcon, RadioUncheckedIcon } from './RadioIcons';
import { ChangeEvent } from 'react';

export type TRadioButtonProps = RadioProps & {
  isChecked?: boolean;
  onChange?: (isChecked: boolean, event?: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

function RadioButton({ isChecked, onChange, disabled, ...otherProps }: TRadioButtonProps) {
  if (disabled) {
    return <RadioDisabledIcon />;
  }

  return (
    <Radio
      checked={isChecked}
      onChange={(event) => onChange && onChange(event.target.checked, event)}
      icon={<RadioUncheckedIcon />}
      checkedIcon={<RadioCheckedIcon />}
      {...otherProps}
    />
  );
}

export default RadioButton;
