import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('IntegrationSummaryMetadata')
export class IntegrationSummaryMetadata {
  @JsonProperty('subcategories', [String])
  subcategories?: string[] = [];

  @JsonProperty('states', [String])
  states?: string[] = [];

  @JsonProperty('roles', [String])
  roles?: string[] = [];
}
