import { Button, Row, Select, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useJobInfo } from '../../../../../data-access/job/useJobInfo';
import AlertTag from '../../../../shared/components/AlertTag';
import { CustomLabel } from '@arcanna/components';
import { CustomLabel as CustomLabelData } from '../../../../shared/models/job/AdvancedJobSettings';
import { getLabelInfoByName } from './helper';
import { useJobOverviewContext } from './JobOverview.context';
import style from './JobOverviewLabels.module.css';

const { Option } = Select;

type JobOverviewLabelsProps = {
  jobId: number;
};

export default function JobOverviewLabels({ jobId }: JobOverviewLabelsProps) {
  const { t } = useTranslation('job');

  const { state: jobOverviewState, setSelectedLabels } = useJobOverviewContext();

  const [unselectedLabels, setUnselectedLabels] = useState<CustomLabelData[]>([]);
  const jobInfoQuery = useJobInfo(jobId);
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const jobLabels = useMemo(() => jobInfoQuery.data?.info.advancedSettings.customLabels, [jobInfoQuery.data]);

  useEffect(() => {
    if (jobInfoQuery.data && !jobOverviewState.intialisedSelectedLabels) {
      // @ts-expect-error TS(2345): Argument of type 'CustomLabel[] | undefined' is no...
      setSelectedLabels(jobLabels);
    }
    // eslint-disable-next-line
  }, [jobInfoQuery.data, jobOverviewState.selectedLabels, setSelectedLabels]);

  const onRemoveLabel = useCallback(
    (labelId: string) => () => {
      if (jobLabels) {
        const labelInfo = jobLabels.find((label: CustomLabelData) => label.id == labelId);
        if (labelInfo) {
          setUnselectedLabels([...unselectedLabels, labelInfo]);
          const currentLabels = jobOverviewState.selectedLabels.filter((label) => label.id != labelId);
          setSelectedLabels(currentLabels);
        }
      }
    },
    // eslint-disable-next-line
    [jobLabels, jobOverviewState.selectedLabels, setUnselectedLabels, setSelectedLabels]
  );

  const onSelectLabel = useCallback(
    // eslint-disable-next-line
    (e: any) => {
      const labelName = e.target.innerText;
      if (jobLabels) {
        const labelInfo = getLabelInfoByName(jobLabels, labelName);
        if (labelInfo) {
          const currentLabels = jobOverviewState.selectedLabels.concat(labelInfo);
          setSelectedLabels(currentLabels);
          setUnselectedLabels((current) => current.filter((option) => option.name != labelName));
        }
      }
    },
    [jobLabels, jobOverviewState.selectedLabels, setSelectedLabels, setUnselectedLabels]
  );

  const clearSelectedLabels = () => {
    setSelectedLabels([]);
    // @ts-expect-error TS(2345): Argument of type 'CustomLabel[] | undefined' is no...
    setUnselectedLabels(jobLabels);
  };

  return (
    <>
      <Row>
        <Typography.Title className="body-2 color-nb-100">{t('jobTimeseries.sectionTitle')}</Typography.Title>
      </Row>
      <Row className={style.labelsSelectWrapper}>
        <Select
          size="small"
          className={style.labelsSelect}
          placeholder={t('jobOverview.selectLabels')}
          value={[]}
          defaultValue={[]}
          onClick={onSelectLabel}
          notFoundContent={t('jobOverview.allLabelsSelected')}
        >
          {unselectedLabels.map((label) => (
            <Option key={label.id}>
              <div className="p-6">
                <CustomLabel name={label.name} hexColor={label.hexColor} />
              </div>
            </Option>
          ))}
        </Select>
      </Row>

      <Row className={style.labelsWrapper}>
        <Button className={style.clear} onClick={clearSelectedLabels}>
          Clear
        </Button>
        {jobOverviewState.selectedLabels?.map((label: CustomLabelData) => (
          <AlertTag key={'alert-tag-' + label.id} label={label} onRemoveHandler={onRemoveLabel} />
        ))}
      </Row>
    </>
  );
}
