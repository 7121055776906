import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { JobOverviewSplitStatsRequest } from '../../components/shared/models/job/JobOverviewSplitStatsRequest';
import { config } from '../../config';
import { JobOverviewSplitStatsResponse } from '../../components/shared/models/job/JobOverviewSplitStatsResponse';

type UseJobOverviewSplitStats = {
  jobId: number;
  dateFrom: Date;
};

// TODO: remove after overview revamp is in place
export function useJobOverviewSplitStats({ jobId, dateFrom }: UseJobOverviewSplitStats) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const requestPayload = new JobOverviewSplitStatsRequest(jobId, dateFrom);

  return useQuery(
    [config.api.jobOverviewSplitStats, requestPayload],
    () =>
      axios
        .post<{ resource: JobOverviewSplitStatsResponse }>(config.api.jobOverviewSplitStats, requestPayload)
        .then((responseData: AxiosResponse<{ resource: JobOverviewSplitStatsResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobOverviewSplitStatsResponse)
        ),
    {
      enabled: Boolean(jobId)
    }
  );
}
