import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('LicenseInfoFeatures')
export class LicenseInfoFeatures {
  @JsonProperty('team_mode', Boolean)
  teamMode: boolean | undefined;

  @JsonProperty('user_defined_fields', Boolean)
  userDefinedFields: boolean | undefined;

  @JsonProperty('third_party_enrichment', Boolean)
  thirdPartyEnrichment: boolean | undefined;

  @JsonProperty('exposer_investigate', Boolean)
  exposerInvestigate: boolean | undefined;

  @JsonProperty('max_jobs', Number)
  maxJobs: number | undefined;
}
