import { useMemo } from 'react';
import { Row, Col, Card } from 'antd';

import { useRouteMatch } from 'react-router-dom';
import { useVisualizationsContext } from '../../../../shared/components/QueryVisualisation/Visualizations.context';
import { useCaseHook } from '../../Usecase/Usecase.hook';

import styles from './WifiInsights.module.css';
import { VisualizationRecord } from '../../../../shared/models/usecase/VisualizationRecord';
import { QueryMetric } from '../../../../shared/components/QueryVisualisation/QueryMetric';
import { CircleBackground } from './CircleBackground';
import { CircleMetricWrapper } from './CircleMetricWrapper';
import { QueryMetricsPercentDerivative } from '../../../../shared/components/QueryVisualisation/QueryMetricsPercentDerivative';
import { CirclePercentageBackground } from './CirclePercentageBackground';
import { MetricsProvider } from '../../../../shared/components/QueryVisualisation/QueryMetric.context';
import { ExportPdf } from '../../../../shared/components/Export/ExportPdf';
import { TimeFilterFragment } from '../../../../shared/components/Visualisation/TimeFilterFragment';

export function MobileWifi() {
  const visualizationContext = useVisualizationsContext();
  const match = useRouteMatch('/usecases/usecase/:useCaseId');
  // @ts-expect-error TS(2339): Property 'params' does not exist on type 'match<{}...
  const { params } = match;
  // eslint-disable-next-line
  const usecaseId = (params as any).useCaseId;
  const { getMetadata } = useCaseHook();

  useMemo(() => {
    getMetadata(usecaseId);
  }, [usecaseId, getMetadata]);

  // TODO: Move to hook
  function getVisualisationRecordById(id: string): VisualizationRecord {
    // @ts-expect-error TS(2322): Type 'VisualizationRecord | undefined' is not assi...
    return visualizationContext.state.page.visualizations.find((record: VisualizationRecord) => record.id === id);
  }

  // Render
  if (visualizationContext.state.page != null) {
    return (
      <>
        <div id="usecase-canvas" className={styles['wifi-insight']}>
          <Row style={{ marginBottom: '10px' }}>
            <Col span="24" className="flex flex-end">
              {/* @ts-expect-error TS(2322): Type '{ length?: number | undefined; toString?: ((... */}
              <ExportPdf {...visualizationContext.state.page.exports} />
            </Col>
          </Row>
          <Row>
            <TimeFilterFragment />
          </Row>

          <Row style={{ marginTop: '10px' }}>
            {/* Vehicles */}
            <Col span="12">
              <Card>
                <Row>
                  <Col span="9">
                    <h2 className={styles['orange-title']}>Vehicles</h2>
                    <Row style={{ paddingTop: '35px' }}>
                      <Col span="12">
                        <img src="/wifi_insights/vehicles_asset.png" alt="Vehicles" className={styles['card-logo']} />
                      </Col>
                      <Col span="12">
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('vehicles_total_no')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                  <Col span="15">
                    <Row>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricsPercentDerivative
                            {...getVisualisationRecordById('vehicles_week_to_week_percentage_variantion')}
                          />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper color="black-color">
                          <QueryMetric {...getVisualisationRecordById('vehicles_transportation_companies_no')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper color="black-color">
                          <QueryMetric {...getVisualisationRecordById('vehicles_per_company')} />
                        </CircleMetricWrapper>
                      </Col>

                      <Col span="8">
                        <CircleBackground backgroundColor="orange-background" />
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('companies_using_captive_portal')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="orange-background" />
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('captive_portal_unique_users')} />
                        </CircleMetricWrapper>
                      </Col>

                      <Col span="8">
                        <MetricsProvider>
                          <CirclePercentageBackground />
                          <CircleMetricWrapper className={`${styles['inside-donut']}`}>
                            <QueryMetric {...getVisualisationRecordById('vehicles_online')} />
                          </CircleMetricWrapper>
                        </MetricsProvider>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Mobile Traffic */}
            <Col span="12">
              <Card>
                <Row>
                  <Col span="9">
                    <h2 className={styles['orange-title']}>Traffic</h2>
                    <Row style={{ paddingTop: '35px' }}>
                      <Col span="12">
                        <img src="/wifi_insights/traffic_asset.png" alt="Traffic" className={styles['card-logo']} />
                      </Col>
                      <Col span="12">
                        <CircleMetricWrapper color="orange-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_mobile_total_traffic')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>

                  <Col span="15">
                    <Row>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetricsPercentDerivative
                            {...getVisualisationRecordById('wifi_mobile_traffic_percent_variation')}
                          />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper color="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_mobile_avg_traffic_per_vechicle')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper color="black-color">
                          <QueryMetric {...getVisualisationRecordById('wifi_mobile_avg_traffic_per_company')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetric {...getVisualisationRecordById('wifi_mobile_traffic_in_roaming')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground />
                        <CircleMetricWrapper>
                          <QueryMetric {...getVisualisationRecordById('wifi_mobile_vechicles_in_roaming')} />
                        </CircleMetricWrapper>
                      </Col>
                      <Col span="8">
                        <CircleBackground backgroundColor="purple-background" />
                        <CircleMetricWrapper>
                          <QueryMetric {...getVisualisationRecordById('wifi_mobile_time_on_non_lte')} />
                        </CircleMetricWrapper>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  return <></>;
}
