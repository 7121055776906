import { RHFTextField } from '@arcanna/forms';
import { getDataTestIdFormat } from 'src/utils/getDataTestIdFormat';
import { getFormParameterName } from '../RHFSmartGeneratedField.utils';
import { TRHFSmartGeneratedFieldProps } from '../RHFSmartGeneratedField';

function TextParameter({ parameter, formSectionName }: TRHFSmartGeneratedFieldProps) {
  return (
    <RHFTextField
      name={getFormParameterName(parameter, formSectionName)}
      label={parameter.caption}
      tooltipText={parameter.description}
      hidden={parameter.visible === false}
      required={parameter.required}
      multiline
      minRows={3}
      maxRows={5}
      placeholder={parameter.placeholder || 'Value'}
      dataTestId={getDataTestIdFormat(`parameter-field-${parameter.field}`)}
      inputProps={{
        maxLength: 1200
      }}
    />
  );
}

export default TextParameter;
