import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { config } from '../../config';

export default function useRefetchLicense(user_session_id?: string) {
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries([config.api.licenseInfo]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_session_id]);
}
