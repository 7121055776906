import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RefreshTokenResponse')
export class RefreshTokenResponse {
  @JsonProperty('refresh_token', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  refresh_token: string = undefined;

  @JsonProperty('access_token', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  access_token: string = undefined;
}
