import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { FeedbackStatisticsBreakdownResponse } from '../../components/shared/models/feedback/FeedbackStatisticsBreakdownResponse';
import { FeedbackStatisticsRequest } from '../../components/shared/models/feedback/FeedbackStatisticsRequest';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { JobRetrainFilters } from '../../components/shared/models/retrain-statistics/JobRetrain';
import { ErrorResponse } from '../../components/shared/models/error/ErrorResponse';

export function useRetrainEventsBreakdownStatistics({ jobId, retrainSession, startDate, endDate, filters }: JobRetrainFilters) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<FeedbackStatisticsBreakdownResponse & { totalEvents: number }, { data: ErrorResponse }>(
    [
      config.api.feedbackStatisticsEventsBreakdown,
      {
        jobId,
        retrainSession,
        startDate,
        endDate,
        filters
      }
    ],
    () =>
      axios
        .post<{ resource: FeedbackStatisticsBreakdownResponse }>(
          config.api.feedbackStatisticsEventsBreakdown,
          new FeedbackStatisticsRequest(jobId, retrainSession, startDate, endDate, filters)
        )
        .then((responseData: AxiosResponse<{ resource: FeedbackStatisticsBreakdownResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, FeedbackStatisticsBreakdownResponse)
        )
        .then((eventsBreakdown: FeedbackStatisticsBreakdownResponse) => {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          const totalEvents = eventsBreakdown.changed + eventsBreakdown.confirmed;
          return {
            totalEvents,
            ...eventsBreakdown
          };
        }),
    {
      enabled: jobId !== undefined
    }
  );
}
