import { TOption } from '@arcanna/generic';
import { TFunction } from 'react-i18next';
import { PeriodOptions } from 'src/components/shared/models/filters/period';

export const getTimeOptions = (t: TFunction): TOption[] => [
  {
    value: PeriodOptions.ALL_TIME,
    label: t('feedback:timeSelectorOptions.allTime')
  },
  {
    value: PeriodOptions.LAST_30DAYS,
    label: t('feedback:timeSelectorOptions.last30Days')
  },
  {
    value: PeriodOptions.LAST_7DAYS,
    label: t('feedback:timeSelectorOptions.last7Days')
  },
  {
    value: PeriodOptions.LAST_24H,
    label: t('feedback:timeSelectorOptions.last24h')
  }
];

export const getStartDateByPeriod = (period: PeriodOptions) => {
  switch (period) {
    case PeriodOptions.LAST_24H:
      return new Date(Date.now() - 24 * 3600 * 1000);
    case PeriodOptions.LAST_7DAYS:
      return new Date(Date.now() - 7 * 24 * 3600 * 1000);
    case PeriodOptions.LAST_30DAYS:
      return new Date(Date.now() - 30 * 24 * 3600 * 1000);
    case PeriodOptions.ALL_TIME:
      // temporary solution, if null is passed here a lot of side effect will trigger and the app is unusable
      return new Date(Date.now() - 20 * 12 * 30 * 24 * 3600 * 1000);
    default:
      return null;
  }
};
