import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('UserPermissionsResponse')
export class UserPermissionsResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse | undefined;

  @JsonProperty('permissions', [String])
  permissions?: string[] = [];
}
