import { Table } from 'antd';
import type { TableProps } from 'antd/es/table';
import cx from 'classnames';
import styles from './styles.module.css';
import smallStyles from './stylesSmall.module.css';
import middleStyles from './stylesMiddle.module.css';

export type TableStyle = 'shrinked' | 'expanded';

type ArcannaCustomTableProps = {
  arcannaSize?: 'large' | 'middle' | 'small';
  hoverableRows?: boolean;
  headBodySpace?: boolean;
  tableInExpandedRowHasOtherBckg?: boolean;
  columnBreakAnywhere?: boolean;
  fixedHeight?: boolean;
  hideEmptyBody?: boolean;
  tableStyle?: TableStyle;
};

// eslint-disable-next-line
type ArcannaTableProps<RecordType = any> = TableProps<RecordType> & ArcannaCustomTableProps;
export function ArcannaTable({
  className,
  headBodySpace = true,
  hideEmptyBody = false,
  tableInExpandedRowHasOtherBckg,
  columnBreakAnywhere,
  fixedHeight = false,
  arcannaSize = 'large',
  tableStyle = 'expanded',
  ...props
}: ArcannaTableProps) {
  const conditionalStyles = {
    [smallStyles.smallTableInExpandedRowBckg]: tableInExpandedRowHasOtherBckg,
    [styles.headBodySpace]: headBodySpace,
    [styles.hideEmptyBody]: hideEmptyBody,
    [smallStyles.smallTable]: arcannaSize == 'small',
    [middleStyles.middleTable]: arcannaSize == 'middle',
    [smallStyles.smallTableInExpandedRowBckg]: tableInExpandedRowHasOtherBckg,
    [styles.tableFixedHeight]: fixedHeight,
    [smallStyles.smallTableColumnBreakAnywhere]: columnBreakAnywhere,
    // [styles.noHoverableRows]: arcannaSize == "small" || arcannaSize == "middle",
    [styles.shrinked]: tableStyle === 'shrinked'
  };

  return <Table {...props} className={cx([styles.table, conditionalStyles, className])} />;
}

export default ArcannaTable;
