import * as React from 'react';
import { RouteType } from '../../components/shared/models/route/RouteType';
import { config } from '../../config';
import { GoToKibana } from '../../components/shared/components/Kibana/GoToKibana';
import { IntegrationsIcon, IntegrationsLogo, UserKeyIcon, UserKeyLogo } from '../siscale/icons';
import { JobFeedbackColumns } from '../../components/pages/Main/Jobs/FeedbackColumns/JobFeedbackColumns';
import { permissions } from '../../components/shared/static/ComponentsPermissions';
import { LicenseFeatures } from '../../components/shared/models/license/LicenseFeatures';
import { LicenseCheckActions } from '../../components/shared/models/license/LicenseCheckActions';
import { JobProvider } from '../../components/pages/Main/Jobs/Job.context';
import { JobOverviewProvider } from '../../components/pages/Main/Jobs/OverviewRevamp/JobOverview.context';
import JobOverviewWrapper from '../../components/pages/Main/Jobs/Overview/JobOverviewWrapper'; // temporary for RCA
import { FlowsIcon } from '../icons/FlowsIcon';
import RedirectLinkGenerator from '@arcanna/pages/RedirectLinkGenerator';
import UnifiedJobsNavigation from 'src/components/shared/components/UnifiedJobsNavigation';
import DecisionPointsUnified from '@arcanna/pages/DecisionPointsUnified';
import JobFlowsContextProvider from '@arcanna/pages/JobFlows/JobFlowsContext/JobFlowsContextProvider';

const {
  HomeIcon,
  DashboardsIcon,
  DashboardJobIcon,
  FeedbackIcon,
  ReportIcon,
  ReportLogo,
  ExportCsvLogo,
  ManagementIcon,
  ManagementLogo,
  ClientDataManagementIcon,
  ClientDataManagementLogo,
  CsvEditorIcon,
  UserManagementIcon,
  UserManagementLogo,
  AIJobsManagementIcon,
  FileDoneOutlined,
  AIJobsManagementLogo,
  KibanaIcon,
  KibanaLogo
  // eslint-disable-next-line
} = require('./icons');

// MUI
const Homepage = React.lazy(() => import('@arcanna/pages/Dashboard'));
const AddJob = React.lazy(() => import('@arcanna/pages/AddJob'));
const Monitoring = React.lazy(() => import('@arcanna/pages/Monitoring/MonitoringDashboard'));
const MonitoringDrilldown = React.lazy(() => import('@arcanna/pages/Monitoring/MonitoringDrilldown'));
const PageNotFound = React.lazy(() => import('@arcanna/pages/ErrorPages/404'));
const CodeBlock = React.lazy(() => import('@arcanna/pages/Flows/components/CodeBlock'));
const Feedback = React.lazy(() => import('@arcanna/pages/Feedback/Feedback'));
const FeedbackUnified = React.lazy(() => import('@arcanna/pages/Feedback/FeedbackUnified'));
const PlatformSettings = React.lazy(() => import('@arcanna/pages/PlatformSettings'));
const Integrations = React.lazy(() => import('@arcanna/pages/Integrations'));
const JobFlows = React.lazy(() => import('@arcanna/pages/JobFlows'));
const IntegrationCreate = React.lazy(() => import('@arcanna/pages/IntegrationCreate'));
const IntegrationEdit = React.lazy(() => import('@arcanna/pages/IntegrationEdit'));
const ChangePassword = React.lazy(() => import('@arcanna/pages/ChangePassword'));
const UserManagement = React.lazy(() => import('@arcanna/pages/UserManagement'));
const ApiKeys = React.lazy(() => import('@arcanna/pages/ApiKeys'));
const Explorer = React.lazy(() => import('@arcanna/pages/Explorer'));

// older ones
const UsecaseWrapper = React.lazy(() => import('../../components/pages/Main/Usecase/UsecaseWrapper'));
const ChartsWrapper = React.lazy(() => import('../../components/pages/Main/Charts/ChartsWrapper'));
const JobRetrain = React.lazy(() => import('../../components/pages/Main/Jobs/Retrain'));
const Csv = React.lazy(() => import('../../components/pages/Main/CsvEditor/Csv'));
const CsvEditor = React.lazy(() => import('../../components/pages/Main/CsvEditor/CsvEditor'));
const ReportingWrapper = React.lazy(() => import('../../components/pages/Main/Reporting/ReportingWrapper'));
const ReportingEditorWrapper = React.lazy(() => import('../../components/pages/Main/Reporting/ReportingEditorWrapper'));
const ReportingPdfWrapper = React.lazy(() => import('../../components/pages/Main/Reporting/ReportingPdfWrapper'));
const JobFeatureSelection = React.lazy(() => import('../../components/pages/Main/Jobs/FeatureSelection'));
const ReportingAddScheduledWrapper = React.lazy(
  () => import('../../components/pages/Main/Reporting/ReportingAddScheduledWrapper')
);
const JobEventExplorerColumnsWrapper = React.lazy(
  () => import('../../components/pages/Main/Jobs/EventExplorerColumns/JobEventExplorerColumnsWrapper')
);
const ChartViewerWrapper = React.lazy(() => import('../../components/pages/Main/Charts/ChartViewerWrapper'));
const Management = React.lazy(() => import('../../components/pages/Main/Management/Management'));
const EditJob = React.lazy(() => import('../../components/pages/Main/Jobs/Edit'));
const JobAddFlow = React.lazy(() => import('../../components/pages/JobFlows/JobAddFlow'));
const Jobs = React.lazy(() => import('@arcanna/pages/JobsList'));

export const staticRoutes: Array<RouteType> = [
  {
    name: 'Home',
    title: 'Home',
    path: config.routes.homepage,
    component: Homepage,
    isInMenu: true,
    icon: HomeIcon,
    exact: true,
    isHome: true,
    isPrivate: true
  },
  {
    name: 'License',
    title: 'PlatformManagement Settings',
    path: config.routes.platformManagement,
    requiredPermissions: [permissions.licenseUpdate],
    component: PlatformSettings,
    isInMenu: false,
    exact: true,
    isPrivate: true
  },
  {
    name: 'Monitoring',
    title: 'Monitoring',
    path: config.routes.monitoring,
    requiredPermissions: [permissions.userRead],
    component: Monitoring,
    isInMenu: true,
    icon: HomeIcon,
    exact: true,
    isPrivate: true
  },
  {
    name: 'Arcanna Redirect',
    title: 'Arcanna Redirect',
    path: config.routes.redirect,
    requiredPermissions: [permissions.userRead],
    component: RedirectLinkGenerator,
    isInMenu: false,
    exact: true,
    isPrivate: true
  },
  {
    name: 'Monitoring drilldown',
    title: 'Monitoring drilldown',
    path: config.routes.monitoringDrilldown,
    component: MonitoringDrilldown,
    requiredPermissions: [permissions.userRead],
    isInMenu: false,
    isPrivate: true
  },
  {
    name: 'Dashboards',
    title: 'Dashboards',
    path: config.routes.useCases,
    component: UsecaseWrapper,
    icon: DashboardsIcon,
    isInMenu: true,
    isInLandingPage: false,
    logoPath: '/landing_page/logos/use_cases.png',
    requiredPermissions: [permissions.useCaseRead],
    children: [
      {
        name: 'Charts',
        title: 'Charts',
        path: config.routes.charts,
        component: ChartsWrapper,
        icon: DashboardsIcon,
        isInMenu: true,
        isInLandingPage: false,
        logoPath: '/landing_page/logos/use_cases.png',
        requiredPermissions: [permissions.chartsRead]
      },
      {
        name: 'ChartViewer',
        title: 'ChartViewer',
        path: config.routes.chartViewer,
        component: ChartViewerWrapper,
        icon: DashboardsIcon,
        isInMenu: false,
        isInLandingPage: false,
        requiredPermissions: [permissions.chartsRead]
      }
    ],
    isPrivate: true
  },
  {
    name: 'CSV Exports',
    title: 'CSV Exports',
    path: config.routes.exportCsv,
    icon: ReportIcon,
    component: ReportingWrapper,
    requiredPermissions: [permissions.exportRead],
    isInMenu: true,
    isInLandingPage: true,
    logo: ExportCsvLogo,
    isPrivate: true
  },
  {
    name: 'Reporting',
    title: 'Reporting',
    path: config.routes.reporting,
    icon: ReportIcon,
    component: ReportingPdfWrapper,
    requiredPermissions: [permissions.reportRead],
    isInMenu: true,
    isInLandingPage: true,
    logo: ReportLogo,
    isPrivate: true
  },
  {
    name: 'Reporting Editor',
    title: 'Reporting Editor',
    path: config.routes.reportingEditor,
    icon: FileDoneOutlined,
    component: ReportingEditorWrapper,
    requiredPermissions: [permissions.exportRead, permissions.exportUpdate],
    isInMenu: false,
    exact: true,
    isInLandingPage: false,
    logo: ReportLogo,
    isPrivate: true
  },
  {
    name: 'Add new export CSV',
    title: 'Add new export CSV',
    path: config.routes.reportingAdd,
    icon: FileDoneOutlined,
    component: ReportingAddScheduledWrapper,
    requiredPermissions: [permissions.exportRead, permissions.exportWrite],
    isInMenu: false,
    exact: true,
    isInLandingPage: false,
    logo: ReportLogo,
    isPrivate: true
  },
  {
    name: 'AI Jobs Management',
    title: 'AI Jobs Management',
    path: config.routes.jobsAll,
    component: Jobs,
    isInMenu: true,
    requiredPermissions: [permissions.jobRead],
    exact: false,
    isPrivate: true,
    logo: AIJobsManagementLogo,
    icon: AIJobsManagementIcon,
    provider: JobProvider,
    children: [
      {
        name: 'Job overview',
        title: 'Job overview',
        path: config.routes.jobOverView,
        component: JobOverviewProvider,
        requiredPermissions: [permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: DashboardJobIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Job overview RCA',
        title: 'Job overview RCA',
        path: config.routes.jobOverViewRCA,
        component: JobOverviewWrapper,
        requiredPermissions: [permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: DashboardJobIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Feedback',
        title: 'Feedback',
        path: config.routes.feedbackFlow,
        component: Feedback,
        requiredPermissions: [permissions.jobRead, permissions.feedbackRead],
        isInMenu: false,
        isPrivate: true,
        icon: FeedbackIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Feedback unified',
        title: 'Feedback unified',
        path: config.routes.feedbackUnified,
        component: FeedbackUnified,
        requiredPermissions: [permissions.jobRead, permissions.feedbackRead],
        isInMenu: false,
        isPrivate: true,
        sider: UnifiedJobsNavigation
      },
      {
        name: 'Decision Points unified',
        title: 'Decision Points unified',
        path: config.routes.decisionPointsUnified,
        component: DecisionPointsUnified,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        sider: UnifiedJobsNavigation
      },
      {
        name: 'Event explorer',
        title: 'Event explorer',
        path: config.routes.eventExplorer,
        component: Explorer,
        requiredPermissions: [permissions.jobRead, permissions.eventExplorerRead],
        isInMenu: false,
        isPrivate: true,
        icon: FeedbackIcon,
        hasSideNavigation: true,
        exact: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Retrain',
        title: 'Retrain',
        path: config.routes.retrainJob,
        component: JobRetrain,
        requiredPermissions: [permissions.jobRead, permissions.feedbackRead, permissions.retrainRead],
        isInMenu: false,
        isPrivate: true,
        icon: FeedbackIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Job Feature Selection',
        title: 'Job Feature Selection',
        path: config.routes.featureSelectionJob,
        component: JobFeatureSelection,
        requiredPermissions: [permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Job Feedback columns selection',
        title: 'Job Feedback columns selection',
        path: config.routes.columnsSelection,
        component: JobFeedbackColumns,
        requiredPermissions: [permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasJobStatusToggle: true
      },
      {
        name: 'Job event explorer columns selection',
        title: 'Job event explorer columns selection',
        path: config.routes.eventExplorerColumnsSelection,
        component: JobEventExplorerColumnsWrapper,
        requiredPermissions: [permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Edit Job',
        title: 'Edit Job',
        path: config.routes.editJob,
        component: EditJob,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate, permissions.integrationRead],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: true,
        hasJobStatusToggle: true
      },
      {
        name: 'Create job',
        title: 'Create job',
        path: config.routes.jobCreate,
        component: AddJob,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate, permissions.integrationRead],
        isInMenu: false,
        isPrivate: true,
        icon: AIJobsManagementIcon,
        hasSideNavigation: false
      },
      {
        name: 'Not found',
        title: 'Not found',
        path: '*',
        component: PageNotFound,
        isInMenu: false,
        isPrivate: false
      }
    ]
  },
  {
    name: 'Flows',
    title: 'Flows',
    path: config.routes.flowsWrapper,
    requiredPermissions: [permissions.jobRead, permissions.jobUpdate],
    isInMenu: false,
    isPrivate: true,
    icon: FlowsIcon,
    provider: JobFlowsContextProvider,
    hasSideNavigation: true,
    exact: false,
    renderOnlyChildren: true,
    children: [
      {
        name: 'Flows',
        title: 'Flows',
        path: config.routes.flows,
        component: JobFlows,
        requiredPermissions: [permissions.jobRead, permissions.jobUpdate],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Add flow',
        title: 'Add flow',
        path: config.routes.flowsComponents.contextEnrichment.add({ jobId: ':id' }),
        component: JobAddFlow,
        requiredPermissions: [permissions.jobWrite, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Edit flow',
        title: 'Edit flow',
        path: config.routes.flowsComponents.contextEnrichment.edit({
          jobId: ':id',
          contextName: ':contextName',
          contextId: ':contextId'
        }),
        component: JobAddFlow,
        requiredPermissions: [permissions.jobUpdate, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Add Code block',
        title: 'Add Code block',
        path: config.routes.flowsComponents.codeBlock.add({ jobId: ':id' }),
        component: CodeBlock,
        requiredPermissions: [permissions.jobWrite, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      },
      {
        name: 'Edit Code block',
        title: 'Edit Code block',
        path: config.routes.flowsComponents.codeBlock.edit({
          jobId: ':id',
          codeBlockId: ':codeBlockId'
        }),
        component: CodeBlock,
        requiredPermissions: [permissions.jobWrite, permissions.jobRead],
        isInMenu: false,
        isPrivate: true,
        icon: FlowsIcon,
        hasSideNavigation: true,
        exact: true
      }
    ]
  },
  {
    name: 'Management',
    title: 'Management',
    path: config.routes.management,
    icon: ManagementIcon,
    component: Management,
    requiredPermissions: [permissions.jobRead],
    isInMenu: true,
    isInLandingPage: true,
    logo: ManagementLogo,
    isPrivate: true,
    children: [
      {
        name: 'Client Data Management',
        title: 'Client Data Management',
        path: config.routes.csv,
        component: Csv,
        requiredPermissions: [permissions.csvRead, permissions.csvUpdate, permissions.csvDelete],
        isInMenu: true,
        isInLandingPage: true,
        exact: true,
        isPrivate: true,
        logo: ClientDataManagementLogo,
        icon: ClientDataManagementIcon
      },
      {
        name: 'User management',
        title: 'User management',
        path: config.routes.users,
        component: UserManagement,
        requiredPermissions: [permissions.userRead],
        isInMenu: false,
        isInLandingPage: true,
        requiredLicenseFeature: LicenseFeatures.TEAM_MODE,
        noLicenseAction: LicenseCheckActions.DISABLE,
        exact: true,
        isPrivate: true,
        logo: UserManagementLogo,
        icon: UserManagementIcon
      },
      {
        name: 'AI Jobs Management',
        title: 'AI Jobs Management',
        path: config.routes.jobsAll,
        component: Jobs,
        isInMenu: true,
        isInLandingPage: true,
        requiredPermissions: [permissions.jobRead],
        exact: true,
        isPrivate: true,
        logo: AIJobsManagementLogo,
        icon: AIJobsManagementIcon
      },
      {
        name: 'Go to Kibana',
        title: 'Go to Kibana',
        path: config.routes.kibana,
        component: GoToKibana,
        isInMenu: true,
        isInLandingPage: true,
        requiredPermissions: [permissions.kibanaLogin],
        exact: true,
        isPrivate: true,
        logo: KibanaLogo,
        icon: KibanaIcon
      }
    ]
  },
  {
    name: 'Client Data Management',
    title: 'Client Data Management',
    path: config.routes.csv,
    component: Csv,
    requiredPermissions: [permissions.csvRead, permissions.csvUpdate, permissions.csvDelete],
    isInMenu: true,
    isInLandingPage: false,
    exact: true,
    isPrivate: true,
    logo: ClientDataManagementLogo,
    icon: ClientDataManagementIcon
  },
  {
    name: 'CsvEditor',
    title: 'CsvEditor',
    path: config.routes.csvEditor,
    component: CsvEditor,
    requiredPermissions: [permissions.csvRead, permissions.csvUpdate, permissions.csvDelete],
    isInMenu: false,
    exact: true,
    isPrivate: true,
    icon: CsvEditorIcon
  },
  {
    name: 'Go to Kibana',
    title: 'Go to Kibana',
    path: config.routes.kibana,
    component: GoToKibana,
    isInMenu: true,
    isInLandingPage: false,
    requiredPermissions: [permissions.kibanaLogin],
    exact: true,
    isPrivate: true,
    logo: KibanaLogo,
    icon: KibanaIcon
  },
  {
    name: 'User management',
    title: 'User management',
    path: config.routes.users,
    component: UserManagement,
    isInMenu: false,
    isInLandingPage: false,
    requiredLicenseFeature: LicenseFeatures.TEAM_MODE,
    noLicenseAction: LicenseCheckActions.DISABLE,
    exact: true,
    isPrivate: true,
    logo: UserManagementLogo,
    icon: UserManagementIcon
  },
  {
    name: 'Api keys',
    title: 'Api keys',
    path: config.routes.apiKeys,
    component: ApiKeys,
    requiredPermissions: [permissions.userKeyRead],
    isInMenu: false,
    isInLandingPage: false,
    exact: true,
    isPrivate: true,
    logo: UserKeyLogo,
    icon: UserKeyIcon
  },
  {
    name: 'Integrations',
    title: 'Integrations',
    path: config.routes.integrations.base,
    component: Integrations,
    isInMenu: true,
    isInLandingPage: false,
    requiredPermissions: [permissions.integrationRead],
    exact: true,
    isPrivate: true,
    logo: IntegrationsLogo,
    icon: IntegrationsIcon
  },
  {
    name: 'Add integration',
    title: 'Add integration',
    path: config.routes.integrations.add(':id'),
    component: IntegrationCreate,
    requiredPermissions: [permissions.integrationRead, permissions.integrationWrite],
    isInMenu: false,
    isPrivate: true,
    icon: IntegrationsLogo
  },
  {
    name: 'Edit integration',
    title: 'Edit integration',
    path: config.routes.integrations.edit(':id'),
    component: IntegrationEdit,
    requiredPermissions: [permissions.integrationRead, permissions.integrationUpdate],
    isInMenu: false,
    isPrivate: true,
    icon: IntegrationsLogo
  },
  {
    name: 'ChangePassword',
    title: 'ChangePassword',
    path: config.routes.auth.changePassword,
    isInMenu: false,
    isPrivate: true,
    layout: ChangePassword,
    exact: true
  },
  {
    name: 'Not found',
    title: 'Not found',
    path: '*',
    component: PageNotFound,
    isInMenu: false,
    isPrivate: false
  }
];
