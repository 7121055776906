import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { JobUpdateResponse } from '../../components/shared/models/job/JobUpdateResponse';
import { REQUEST_STATUS_NOK } from '../../components/shared/hooks/useFetch';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useMemo } from 'react';
import { JobTimeMetricsRequest } from '../../components/shared/models/job/JobTimeMetricsRequest';

export function useJobTimeMetrics({ jobId }: { jobId: number }) {
  const { t } = useTranslation(['job']);
  const jsonConvert = useMemo(() => getJsonConvert(), []);
  const queryClient = useQueryClient();

  return useMutation((payload: JobTimeMetricsRequest) => axios.post(config.api.jobMetricsUpdate, payload), {
    onSuccess: (response) => {
      queryClient.invalidateQueries([config.api.jobInfo.replace(':id', jobId?.toString())]);

      const responseData = jsonConvert.deserializeObject(response.data.resource, JobUpdateResponse);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (responseData.request.status === REQUEST_STATUS_NOK) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        showErrorNotification(t('common:error'), responseData.request.reason);
        return;
      }
      showSuccessNotification(t('jobTimeMetrics.successMessage'), t('jobTimeMetrics.successDescription'));
    },
    onError: () => {
      showErrorNotification(t('jobTimeMetrics.errorMessage'), t('jobTimeMetrics.genericErrorDescription'));
    }
  });
}
