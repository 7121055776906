import { InlineNotification } from '@arcanna/components';
import { useTranslation } from 'react-i18next';
import StartIcon from '../../../../../../../themes/icons/StartIcon';
import DecisionPointsButton from '../../../Feedback/Flow/DecisionPointsButton';
import { useStartJob, useJobStatus } from '../../../../../../../data-access';
import { JobStatusChangeRequest } from '../../../../../../shared/models/job/JobStatusChangeRequest';
import { enumFromValue } from '../../../../../../shared/utilities/enumFromValue';
import { JobStateEnum } from '../../../../../../shared/models';
import { StartNotificationType } from '../hooks/useEstablishStartNotificationType';
import { StartedState } from '../../../../../../shared/components/JobState/JobState';
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

type TJobStartNotificationProps = {
  jobId: number;
  fromPage?: string;
  notificationType?: StartNotificationType;
};
export default function JobStartNotification({ jobId, fromPage, notificationType }: TJobStartNotificationProps) {
  const { t } = useTranslation(['feedback', 'job']);

  const [isBusy, setIsBusy] = useState(false);

  const jobStatus = useJobStatus(jobId);

  const jobState = jobStatus?.data?.state;
  const cloningJob = jobState ? enumFromValue(jobState, JobStateEnum) === JobStateEnum.CLONING : false;

  const jobStartMutation = useStartJob();
  const onStartJob = async () => {
    setIsBusy(true);
    await jobStartMutation.mutateAsync(new JobStatusChangeRequest(jobId));
  };

  useEffect(() => {
    if (jobStatus?.data?.state && StartedState.includes(enumFromValue(jobState, JobStateEnum))) {
      setIsBusy(false);
    }
    // eslint-disable-next-line
  }, [jobStatus?.data?.state]);

  switch (notificationType) {
    case StartNotificationType.JOB_NOT_STARTED: {
      return (
        <InlineNotification
          type="info"
          title={t('job:notifications:noEventsIngested')}
          subtitle={`Click ${t('job:startJob')} to ingest events.`}
          actionButton={{
            icon: isBusy ? <LoadingOutlined /> : <StartIcon fill="var(--secondary-color-nb-1000)" />,
            onClick: () => onStartJob(),
            disabled: cloningJob || isBusy,
            text: t('job:startJob')
          }}
        ></InlineNotification>
      );
    }
    case StartNotificationType.COLLECTING_DATA: {
      return <InlineNotification type="loading" title={t('job:jobState:collectingData')} />;
    }
    case StartNotificationType.NO_DECISION_POINTS: {
      return (
        <InlineNotification
          type="info"
          title={t('job:notifications:noDecisionPoints')}
          subtitle={`${t('job:notifications:selectDecisionPoints')} Decision Points.`}
        >
          <DecisionPointsButton jobId={jobId} fromPage={fromPage} />
        </InlineNotification>
      );
    }
    default: {
      return null;
    }
  }
}
