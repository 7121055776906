import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('IntegrationParameterOptionsRecord')
export class IntegrationParameterOptionsRecord {
  @JsonProperty('field', String)
  field: string | undefined;

  @JsonProperty('value', String)
  value: string | undefined;
}
