/* eslint-disable max-len */
function FolderPlus() {
  return (
    <svg width="80" height="61" viewBox="0 0 80 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M74.1921 3.12012V14.6143H5.75751V12.2223C5.75751 10.0132 7.54837 8.2223 9.75751 8.2223H38.6283L42.1162 2.92142C42.8561 1.79703 44.1118 1.12012 45.4578 1.12012H72.1921C73.2967 1.12012 74.1921 2.01555 74.1921 3.12012Z"
        fill="url(#paint0_linear_14950_23609)"
        stroke="#00C5A3"
      />
      <g filter="url(#filter0_b_14950_23609)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.1749 14.6143H4.00097C1.53245 14.6143 -0.346872 16.8282 0.0540872 19.264L6.35317 57.5296C6.67127 59.462 8.34167 60.8799 10.3001 60.8799H40.1749H42.9805H69.6485C71.6069 60.8799 73.2773 59.462 73.5954 57.5296L79.8945 19.264C80.2955 16.8282 78.4162 14.6143 75.9476 14.6143H42.9805H40.1749Z"
          fill="url(#paint1_linear_14950_23609)"
        />
      </g>
      <path
        d="M39.4929 32.1245V43.3696M33.8703 37.7471H45.1154"
        stroke="#DAFCF7"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_b_14950_23609"
          x="-8"
          y="6.61426"
          width="95.9486"
          height="62.2656"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_14950_23609" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_14950_23609" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_14950_23609"
          x1="76.6369"
          y1="0.966176"
          x2="73.9218"
          y2="14.7756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22293E" />
          <stop offset="1" stopColor="#22293E" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14950_23609"
          x1="96.0194"
          y1="66.1812"
          x2="11.9061"
          y2="-13.041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00C5A3" stopOpacity="0" />
          <stop offset="1" stopColor="#00C5A3" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default FolderPlus;
