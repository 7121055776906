/* eslint-disable max-len */
function PinLeft() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5.58447 10.4114L1.81323 14.1827M7.79626 4.42845L6.75567 5.46904C6.67079 5.55392 6.62834 5.59636 6.57999 5.63009C6.53707 5.66002 6.49078 5.68479 6.44206 5.7039C6.38718 5.72543 6.32832 5.7372 6.21061 5.76074L3.76764 6.24934C3.13277 6.37631 2.81533 6.4398 2.66683 6.60717C2.53745 6.75297 2.47837 6.9481 2.50514 7.14119C2.53586 7.36282 2.76477 7.59173 3.22258 8.04954L7.9464 12.7734C8.40422 13.2312 8.63312 13.4601 8.85476 13.4908C9.04784 13.5176 9.24297 13.4585 9.38878 13.3291C9.55614 13.1806 9.61963 12.8632 9.7466 12.2283L10.2352 9.78533C10.2587 9.66762 10.2705 9.60876 10.292 9.55388C10.3111 9.50517 10.3359 9.45887 10.3659 9.41595C10.3996 9.3676 10.442 9.32516 10.5269 9.24027L11.5675 8.19968C11.6218 8.14541 11.6489 8.11828 11.6787 8.09458C11.7052 8.07354 11.7333 8.05454 11.7627 8.03775C11.7957 8.01886 11.831 8.00374 11.9016 7.97351L13.5645 7.26083C14.0496 7.05292 14.2922 6.94896 14.4024 6.78097C14.4987 6.63407 14.5332 6.45507 14.4983 6.28289C14.4584 6.08599 14.2718 5.89938 13.8985 5.52617L10.4698 2.09741C10.0966 1.72419 9.90995 1.53758 9.71305 1.49767C9.54087 1.46276 9.36187 1.49724 9.21497 1.59359C9.04698 1.70377 8.94302 1.94634 8.73511 2.43148L8.02243 4.09438C7.9922 4.16492 7.97708 4.2002 7.95819 4.23327C7.9414 4.26265 7.9224 4.29071 7.90136 4.31721C7.87767 4.34704 7.85053 4.37418 7.79626 4.42845Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PinLeft;
