export function ClustersIcon({ color }: { color?: string }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line
        d="M3.33317 15.0001V14.8334C3.33317 13.4333 3.33317 12.7332 3.60565 12.1984C3.84534 11.728 4.22779 11.3456 4.69819 11.1059C5.23297 10.8334 5.93304 10.8334 7.33317 10.8334H12.6665C14.0666 10.8334 14.7667 10.8334 15.3015 11.1059C15.7719 11.3456 16.1543 11.728 16.394 12.1984C16.6665 12.7332 16.6665 13.4333 16.6665 14.8334V15.0001M3.33317 15.0001C2.4127 15.0001 1.6665 15.7463 1.6665 16.6667C1.6665 17.5872 2.4127 18.3334 3.33317 18.3334C4.25365 18.3334 4.99984 17.5872 4.99984 16.6667C4.99984 15.7463 4.25365 15.0001 3.33317 15.0001ZM16.6665 15.0001C15.746 15.0001 14.9998 15.7463 14.9998 16.6667C14.9998 17.5872 15.746 18.3334 16.6665 18.3334C17.587 18.3334 18.3332 17.5872 18.3332 16.6667C18.3332 15.7463 17.587 15.0001 16.6665 15.0001ZM9.99984 15.0001C9.07936 15.0001 8.33317 15.7463 8.33317 16.6667C8.33317 17.5872 9.07936 18.3334 9.99984 18.3334C10.9203 18.3334 11.6665 17.5872 11.6665 16.6667C11.6665 15.7463 10.9203 15.0001 9.99984 15.0001ZM9.99984 15.0001V6.66675M4.99984 6.66675H14.9998C15.7764 6.66675 16.1647 6.66675 16.471 6.53988C16.8794 6.37072 17.2038 6.04627 17.373 5.63789C17.4998 5.3316 17.4998 4.94332 17.4998 4.16675C17.4998 3.39018 17.4998 3.00189 17.373 2.69561C17.2038 2.28723 16.8794 1.96277 16.471 1.79362C16.1647 1.66675 15.7764 1.66675 14.9998 1.66675H4.99984C4.22327 1.66675 3.83498 1.66675 3.5287 1.79362C3.12032 1.96277 2.79586 2.28723 2.6267 2.69561C2.49984 3.00189 2.49984 3.39018 2.49984 4.16675C2.49984 4.94332 2.49984 5.3316 2.6267 5.63789C2.79586 6.04627 3.12032 6.37072 3.5287 6.53988C3.83498 6.66675 4.22327 6.66675 4.99984 6.66675Z"
        stroke={color ? color : 'var(--secondary-color-nb-300)'}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
