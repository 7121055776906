import { useTranslation } from 'react-i18next';
import { useJobNavigate } from 'src/components/shared/hooks/useJobNavigate';
import PermissionsCheck from 'src/components/shared/components/Permissions/Permissions';
import { permissions } from 'src/components/shared/static/ComponentsPermissions';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { EJobTrainingState } from 'src/components/shared/models/job/EJobTrainingState';
import { Button } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';
import { useFeatureSelectionSummary, useJobStatus, useRetrainState } from 'src/data-access';
import { isRetrainInProgress } from '../../Retrain/Retrain.utils';

type DecisionPointsProps = {
  jobId: number;
  fromPage?: string;
};
export default function DecisionPointsButton({ jobId, fromPage }: DecisionPointsProps) {
  const { t } = useTranslation(['feedback', 'job']);
  const { navigateToJobFeatureSelection } = useJobNavigate();

  const [hasProcessedEvents, setHasProcessedEvents] = useState(false);
  const [hasFeatures, setHasFeatures] = useState(false);
  const [isAutomaticRetrainInProgress, setIsAutomaticRetrainInProgress] = useState(false);
  const [isTrainingInProgress, setIsTrainingInProgress] = useState(false);
  const [isBucketsModelUpdateInProgress, setIsBucketsModelUpdateInProgress] = useState(false);

  const retrainStateQuery = useRetrainState({ jobId, retrainMutationStatus: 'none' });
  const jobStatusQuery = useJobStatus(jobId);
  const featureSelectionSummaryQuery = useFeatureSelectionSummary({ jobId: jobId, skipInactive: false });

  const establishTooltip = () => {
    if (!hasProcessedEvents) {
      return t('job:featureSelection:featureSelectionDisabledNoEvents');
    }
    if (!hasFeatures) {
      return t('job:featureSelection:featureSelectionDisabledNoSummary');
    }
    return '';
  };

  useEffect(() => {
    // @ts-expect-error TS(2345): Argument of type 'boolean | "" | undefined' is not...
    setIsTrainingInProgress(isRetrainInProgress(retrainStateQuery?.data));
  }, [retrainStateQuery.data]);

  useEffect(() => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    setHasProcessedEvents(jobStatusQuery.data?.processedDocumentsCount > 0);
    setIsAutomaticRetrainInProgress(jobStatusQuery.data?.retrain_state === EJobTrainingState.AUTOMATIC_RETRAIN);
    // @ts-expect-error TS(2345): Argument of type 'boolean | undefined' is not assi...
    setIsBucketsModelUpdateInProgress(jobStatusQuery.data?.buckets_model_update_in_progress);
  }, [jobStatusQuery.data]);

  useEffect(() => {
    // eslint-disable-next-line
    setHasFeatures((featureSelectionSummaryQuery.data as any)?.length > 0);
  }, [featureSelectionSummaryQuery.data]);

  return (
    <PermissionsCheck permissions={[permissions.jobUpdate]} action={permissions.action.disabledAction}>
      <Tooltip placement="bottom" title={establishTooltip()}>
        <Button
          data-test-id="decision-points-btn"
          variant="contained"
          color="primary"
          size="small"
          className={'p-0'}
          endIcon={<Icon name={EIcon.ArtificialIntelligenceSpark} />}
          disabled={
            !hasProcessedEvents ||
            !hasFeatures ||
            isAutomaticRetrainInProgress ||
            isTrainingInProgress ||
            isBucketsModelUpdateInProgress
          }
          onClick={() => navigateToJobFeatureSelection(`${jobId}`, fromPage)}
          style={{ minWidth: '164px' }}
        >
          {t('decisionPointsButtonLabel')}
        </Button>
      </Tooltip>
    </PermissionsCheck>
  );
}
