import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../../../pages/Main/Login/Auth.context';
import { config } from '../../../../config';

// eslint-disable-next-line
export function PrivateRoute({ children, ...rest }: any) {
  const { state: authState } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState.isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: config.routes.login,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
