import { JsonObject, JsonProperty } from 'json2typescript';
import { CustomFieldMetadata } from './CustomFieldMetadata';

@JsonObject('CustomFieldRule')
export class CustomFieldRule {
  @JsonProperty('operator', String)
  operator: string;

  @JsonProperty('is_interval', Boolean)
  is_interval: boolean;

  @JsonProperty('comparison_values', [String])
  comparison_values: string[];

  @JsonProperty('map_value', String)
  map_value: string | null;

  @JsonProperty('original_value', Boolean)
  original_value?: boolean;

  @JsonProperty('metadata', CustomFieldMetadata)
  metadata?: CustomFieldMetadata;
}
