import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CloneTask')
export class CloneTask {
  @JsonProperty('progress', Number)
  progress?: number = undefined;
}

@JsonObject('JobCloningStatus')
export class JobCloningStatus {
  @JsonProperty('clone_buckets', CloneTask)
  cloneBuckets?: CloneTask = undefined;

  @JsonProperty('clone_events', CloneTask)
  cloneEvents?: CloneTask = undefined;

  @JsonProperty('clone_clusters', CloneTask)
  cloneClusters?: CloneTask = undefined;

  @JsonProperty('event_sampling', CloneTask)
  eventSampling?: CloneTask = undefined;
}
