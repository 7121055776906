import React from 'react';
import { EIcon, Icon, Spinner } from '@arcanna/generic';
import { Stack, Typography, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';

type TDateRangePickerProps = {
  startDate?: Moment | null;
  endDate?: Moment | null;
  setDates?: (startDate: Date | null, endDate: Date | null) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  clearableStartDate?: boolean;
};

function DateRangePicker({
  startDate,
  endDate,
  setDates,
  isDisabled,
  isLoading,
  clearableStartDate = true
}: TDateRangePickerProps) {
  const { palette } = useTheme();

  const [isStartOpen, setIsStartOpen] = React.useState<boolean>(false);
  const [isEndOpen, setIsEndOpen] = React.useState<boolean>(false);

  const handleStartDateChange = (newValue: Moment | null) => {
    let newStartDate = newValue;
    let newEndDate = endDate;

    if (!newValue) {
      newStartDate = null;
      newEndDate = null;
    } else if (endDate && endDate.isBefore(newValue)) {
      newEndDate = newValue.clone().add(1, 'minute');
    }

    if (setDates) {
      setDates(newStartDate ? newStartDate.toDate() : null, newEndDate ? newEndDate.toDate() : null);
    }
  };

  const handleEndDateChange = (newValue: Moment | null) => {
    let newStartDate = startDate;
    let newEndDate = newValue;

    if (!newValue) {
      newEndDate = null;
    } else if (startDate && startDate.isAfter(newValue)) {
      newStartDate = newValue.clone().subtract(1, 'minute');
    }

    if (setDates) {
      setDates(newStartDate ? newStartDate.toDate() : null, newEndDate ? newEndDate.toDate() : null);
    }
  };

  return (
    <Stack
      position="relative"
      width="480px"
      height="36px"
      py="6px"
      border="1px solid"
      borderRadius="4px"
      borderColor={palette.secondary[700]}
      bgcolor={palette.secondary[900]}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      color={palette.secondary[400]}
      sx={{
        '.MuiInputBase-root': {
          height: '36px',
          px: '16px'
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        opacity: isDisabled || isLoading ? 0.5 : 1
      }}
    >
      {isLoading ? (
        <Spinner isOverlay />
      ) : (
        <>
          <DateTimePicker
            open={isStartOpen}
            onClose={() => setIsStartOpen(false)}
            value={startDate || null}
            onChange={handleStartDateChange}
            slotProps={{
              actionBar: {
                actions: clearableStartDate ? ['clear', 'accept', 'cancel'] : ['accept', 'cancel']
              },
              textField: {
                placeholder: 'Start date',
                inputProps: {
                  readOnly: true,
                  onClick: () => {
                    setIsStartOpen(true);
                    setIsEndOpen(false);
                  },
                  sx: {
                    cursor: 'pointer'
                  }
                }
              },
              openPickerIcon: {
                sx: {
                  color: palette.secondary[400]
                },
                onClick: () => {
                  setIsStartOpen(true);
                  setIsEndOpen(false);
                }
              }
            }}
            sx={{
              width: '230px'
            }}
            format="DD MMM YYYY HH:mm:ss"
            ampm={false}
          />
          <Icon name={EIcon.SwapRight} />
          {startDate ? (
            <DateTimePicker
              open={isEndOpen}
              onClose={() => setIsEndOpen(false)}
              value={endDate || null}
              onChange={handleEndDateChange}
              minDateTime={startDate || undefined}
              slotProps={{
                actionBar: {
                  actions: ['clear', 'accept', 'cancel']
                },
                textField: {
                  placeholder: 'End date',
                  inputProps: {
                    readOnly: true,
                    onClick: () => {
                      setIsEndOpen(true);
                      setIsStartOpen(false);
                    },
                    sx: {
                      cursor: 'pointer'
                    }
                  }
                },
                openPickerIcon: {
                  sx: {
                    color: palette.secondary[400]
                  },
                  onClick: () => {
                    setIsEndOpen(true);
                    setIsStartOpen(false);
                  }
                }
              }}
              format="DD MMM YYYY HH:mm:ss"
              ampm={false}
            />
          ) : (
            <Typography
              variant="subtitle1"
              color={palette.secondary[500]}
              sx={{ pl: '16px', width: '230px', cursor: 'not-allowed' }}
            >
              End date
            </Typography>
          )}
        </>
      )}
    </Stack>
  );
}

export default DateRangePicker;
