import { Stack, StackProps, styled } from '@mui/material';

const StyledCustomLabelPill = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'isMenuOpen';
  }
})<StackProps & { isMenuOpen?: boolean }>(({ theme, isMenuOpen }) => ({
  width: 'fit-content',
  backgroundColor: theme.palette.secondary[1000],
  height: '32px',
  borderRadius: '24px',
  padding: '4px 8px',
  border: '1px solid',
  borderColor: isMenuOpen ? theme.palette.info.main : theme.palette.secondary[600],

  '.icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '99px',
    backgroundColor: theme.palette.secondary[800],
    cursor: 'pointer'
  },

  '.editIcon': {
    '&:hover': {
      color: theme.palette.secondary[400]
    }
  },

  '.deleteIcon': {
    '&:hover': {
      color: theme.palette.error.main
    }
  },

  '.pillIcon': {
    fontSize: '8px'
  }
}));

export default StyledCustomLabelPill;
