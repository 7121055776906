import { SVGProps } from 'react';

type SettingsIconProps = SVGProps<SVGSVGElement> & {
  fill?: string;
};
export function SettingsIcon({ fill = 'none', ...props }: SettingsIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="settings-02">
        <g id="Icon">
          <path
            //eslint-disable-next-line max-len
            d="M7.82924 16.1426L8.31628 17.2379C8.46106 17.564 8.69734 17.841 8.99647 18.0355C9.29559 18.2299 9.64471 18.3334 10.0015 18.3333C10.3582 18.3334 10.7073 18.2299 11.0065 18.0355C11.3056 17.841 11.5419 17.564 11.6867 17.2379L12.1737 16.1426C12.3471 15.7539 12.6387 15.4298 13.007 15.2166C13.3777 15.0028 13.8065 14.9118 14.232 14.9564L15.4237 15.0833C15.7784 15.1208 16.1364 15.0546 16.4543 14.8927C16.7721 14.7309 17.0362 14.4802 17.2144 14.1713C17.3929 13.8625 17.4779 13.5085 17.4592 13.1524C17.4405 12.7962 17.3188 12.4531 17.1089 12.1648L16.4033 11.1953C16.1521 10.8476 16.0178 10.429 16.02 9.99996C16.0199 9.57212 16.1554 9.15525 16.407 8.80922L17.1126 7.83977C17.3225 7.55142 17.4442 7.20835 17.4629 6.85219C17.4816 6.49602 17.3966 6.14208 17.2181 5.83329C17.0399 5.52432 16.7758 5.2737 16.458 5.11182C16.1401 4.94993 15.7821 4.88373 15.4274 4.92126L14.2357 5.04811C13.8102 5.0928 13.3814 5.00173 13.0107 4.78792C12.6417 4.5735 12.35 4.24776 12.1774 3.85737L11.6867 2.762C11.5419 2.43594 11.3056 2.15889 11.0065 1.96446C10.7073 1.77003 10.3582 1.66657 10.0015 1.66663C9.64471 1.66657 9.29559 1.77003 8.99647 1.96446C8.69734 2.15889 8.46106 2.43594 8.31628 2.762L7.82924 3.85737C7.65668 4.24776 7.36497 4.5735 6.99591 4.78792C6.62526 5.00173 6.19647 5.0928 5.77091 5.04811L4.57554 4.92126C4.22081 4.88373 3.86282 4.94993 3.54497 5.11182C3.22711 5.2737 2.96305 5.52432 2.7848 5.83329C2.60632 6.14208 2.52128 6.49602 2.54002 6.85219C2.55876 7.20835 2.68046 7.55142 2.89035 7.83977L3.59591 8.80922C3.84753 9.15525 3.98302 9.57212 3.98295 9.99996C3.98302 10.4278 3.84753 10.8447 3.59591 11.1907L2.89035 12.1601C2.68046 12.4485 2.55876 12.7916 2.54002 13.1477C2.52128 13.5039 2.60632 13.8578 2.7848 14.1666C2.96323 14.4754 3.22732 14.7259 3.54513 14.8878C3.86294 15.0496 4.22084 15.1159 4.57554 15.0787L5.76721 14.9518C6.19276 14.9071 6.62155 14.9982 6.99221 15.212C7.36265 15.4258 7.65571 15.7516 7.82924 16.1426Z"
            stroke="currentColor"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            //eslint-disable-next-line max-len
            d="M9.99998 12.5C11.3807 12.5 12.5 11.3807 12.5 9.99996C12.5 8.61925 11.3807 7.49996 9.99998 7.49996C8.61926 7.49996 7.49998 8.61925 7.49998 9.99996C7.49998 11.3807 8.61926 12.5 9.99998 12.5Z"
            stroke="currentColor"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
