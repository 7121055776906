import { useTranslation } from 'react-i18next';
import { FeatureDistribution } from '../../../../../../../../shared/models/retrain-history/JobMetricsResponse';
import styles from './styles.module.css';
import { Tooltip } from 'antd';

type AvailableValuesProps = {
  featureDistribution: FeatureDistribution;
};
export default function AvailableValues({ featureDistribution }: AvailableValuesProps) {
  const { t } = useTranslation(['overview']);

  const total = (featureDistribution.occurences ?? []).length;

  return (
    <div className="flex flex-sp callout color-nb-100">
      <Tooltip title={t('overview:availableValues')}>
        <span className={styles.overflowText}>{t('overview:availableValues')}</span>
      </Tooltip>
      <span>{total} (100%)</span>
    </div>
  );
}
