import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DPUnifiedContext } from './DPUnifiedContext';
import { TDPUnifiedContext, TSelectedFilterType } from './DPUnifiedContext.type';
import { useUnifiedDecisionPointsList, useUpdateUnifiedDecisionPointsList } from '@arcanna/requests/Jobs';
import {
  TUnifiedDecisionPointsResponse,
  UnifiedDecisionPointsListFeatureRecord,
  UnifiedDecisionPointsListUpdate,
  UnifiedDecisionPointsListUpdateRecord
} from '@arcanna/models/Jobs';
import { TCheckboxState } from '@arcanna/generic';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { getIsBucketModelUpdateInProgress, getIsDPDisabled } from '../DPUnifiedTable/DPUnifiedTable.utils';
import { useJobsSummary } from 'src/data-access';
import { JobCategory } from 'src/components/pages/Main/Jobs/helper';
import { JobSummaryRecord } from 'src/components/shared/models/job/JobSummaryRecord';
import { config } from 'src/config';
import { useQueryClient } from 'react-query';

type TDPUnifiedContextProviderProps = {
  children: ReactNode;
};

function DPUnifiedContextProvider({ children }: TDPUnifiedContextProviderProps) {
  const { ids } = useParams<{ ids: string }>();
  const jobIds = useMemo(() => ids.split(',').map((id: string) => Number(id)), [ids]);
  const queryClient = useQueryClient();

  const { mutateAsync: updateUnifiedDPList, isLoading: isUpdateLoading } = useUpdateUnifiedDecisionPointsList();

  const [activeSelectedFilter, setActiveSelectedFilter] = useState<TSelectedFilterType>('all');

  const { data, isLoading: isDPLoading } = useUnifiedDecisionPointsList({ jobIds });

  const [tableData, setTableData] = useState<TUnifiedDecisionPointsResponse[]>([]);
  const [initialTableData, setInitialTableData] = useState<TUnifiedDecisionPointsResponse[]>([]);

  const filteredTableData = useMemo(() => {
    if (activeSelectedFilter === 'selected') {
      return tableData.filter((row) =>
        Object.keys(_.omit(row, 'fieldName')).some((jobId) => row[Number(jobId)]?.isDecisionPoint === true)
      );
    }

    if (activeSelectedFilter === 'notSelected') {
      return tableData.filter(
        (row) => !Object.keys(_.omit(row, 'fieldName')).some((jobId) => row[Number(jobId)]?.isDecisionPoint === true)
      );
    }

    return tableData;
  }, [tableData, activeSelectedFilter]);

  const { data: jobsSummary, isLoading: isJobInfosLoading } = useJobsSummary({
    categoryId: JobCategory.DECISION_INTELLIGENCE,
    tablePageSize: 1000
  });

  const jobInfos = useMemo(
    () => (jobsSummary?.entries ?? []).filter((jobSummaryItem) => jobSummaryItem.id && jobIds.includes(jobSummaryItem.id)),
    [jobIds, jobsSummary]
  );

  useEffect(() => {
    setTableData(data?.features ?? []);
    setInitialTableData(data?.features ?? []);
  }, [data?.features, setTableData]);

  const invalidateDP = useCallback(() => {
    queryClient.invalidateQueries([config.api.jobFeatureSelectionUnifiedSummary]);
  }, [queryClient]);

  useEffect(() => {
    // we should refetch this always regardless of the id, but we should keep the staleTime to avoid re-renders on the same page
    // this is a fix for SAI-4501 until we find a better approach (zustand maybe)
    return () => invalidateDP();
  }, [invalidateDP]);

  const handleCheckboxChange = useCallback(
    (state: TCheckboxState, fieldName: string, jobId: number | undefined) => {
      setTableData((prevTableData) =>
        prevTableData.map((row) => {
          if (row.fieldName === fieldName) {
            return jobId
              ? { ...row, [jobId]: { ...row[jobId], isDecisionPoint: state === 'checked' } }
              : {
                  ...row,
                  ..._.mapValues(_.omit(row, 'fieldName'), (record, key) => {
                    const jobSummary = jobInfos.find((jobInfo) => String(jobInfo.id) === key);

                    return getIsDPDisabled(record, jobSummary).isDisabled
                      ? record
                      : {
                          ...record,
                          isDecisionPoint: state === 'checked'
                        };
                  })
                };
          }

          return row;
        })
      );
    },
    [jobInfos]
  );

  const handleUpdateDP = useCallback(() => {
    const updatesList = jobInfos.reduce((acc, jobSummary: JobSummaryRecord) => {
      const jobId = jobSummary.id;

      if (!jobId || getIsBucketModelUpdateInProgress(jobSummary.status)) {
        return acc;
      }

      const activeFeatures = tableData.reduce((accumulator, tableRow) => {
        const record = tableRow[jobId];

        if (record?.isDecisionPoint) {
          accumulator.push(new UnifiedDecisionPointsListFeatureRecord('', tableRow.fieldName, record.fieldType ?? '', '', true));
        }

        return accumulator;
      }, [] as UnifiedDecisionPointsListFeatureRecord[]);

      if (activeFeatures.length) {
        acc.push(new UnifiedDecisionPointsListUpdateRecord(jobId, activeFeatures));
      }

      return acc;
    }, [] as UnifiedDecisionPointsListUpdateRecord[]);

    updateUnifiedDPList(new UnifiedDecisionPointsListUpdate(updatesList));
  }, [tableData, updateUnifiedDPList, jobInfos]);

  const value: TDPUnifiedContext = useMemo(
    () => ({
      tableSlice: {
        isDPLoading: isDPLoading,
        DPRows: filteredTableData
      },
      jobsSlice: {
        jobIds,
        jobInfos,
        isJobInfosLoading
      },
      filtersSlice: {
        activeSelectedFilter,
        setActiveSelectedFilter
      },
      handleCheckboxChange,
      handleUpdateDP,
      isUpdateLoading,
      isUpdateDisabled: _.isEqual(initialTableData, tableData)
    }),
    [
      jobIds,
      filteredTableData,
      jobInfos,
      isJobInfosLoading,
      handleCheckboxChange,
      isDPLoading,
      activeSelectedFilter,
      setActiveSelectedFilter,
      handleUpdateDP,
      isUpdateLoading,
      initialTableData,
      tableData
    ]
  );

  return <DPUnifiedContext.Provider value={value}>{children}</DPUnifiedContext.Provider>;
}

export default DPUnifiedContextProvider;
