import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackStatisticsBreakdownItem')
export class FeedbackStatisticsBreakdownItem {
  @JsonProperty('confirmed', Number)
  confirmed?: number;

  @JsonProperty('changed', Number)
  changed?: number;

  @JsonProperty('id', String)
  id?: string;

  @JsonProperty('name', String)
  name?: string;

  @JsonProperty('hex_color', String)
  hex_color?: string;
}
