import { ReactElement } from 'react';
import { Space } from 'antd';
import cx from 'classnames';

import s from './style.module.css';

export type BtnSizeType = 'small' | 'middle' | 'large';
export type BtnType =
  | 'transparent'
  | 'grey'
  | 'success'
  | 'warning'
  | 'secondary'
  | 'primary'
  | 'info'
  | 'outlined'
  | 'primaryNew'
  | 'secondaryNew'
  | 'tertiaryNew'
  | 'link';
export type BtnHtmlType = 'button' | 'submit' | 'reset';
export type BtnShape = 'normal' | 'round';
export type BtnIconPosition = 'right' | 'left';

type BtnPropsType = {
  size?: BtnSizeType;
  type?: BtnType;
  icon?: ReactElement;
  iconPosition?: BtnIconPosition;
  iconSpace?: number;
  onClick?: () => void;
  text?: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  noMgIcon?: boolean;
  htmlType?: BtnHtmlType;
  shape?: BtnShape;
  active?: boolean;
  children?: ReactElement | ReactElement[];
  style?: React.CSSProperties;
  dataTestId?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
};

const defaultPropsValues: BtnPropsType = {
  size: 'middle',
  icon: <></>,
  iconPosition: 'left',
  iconSpace: 8,
  // eslint-disable-next-line
  onClick: () => {},
  text: '',
  title: '',
  disabled: false,
  noMgIcon: true,
  htmlType: 'button',
  shape: 'normal',
  active: false,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ReactElemen...
  children: null,
  type: 'primaryNew'
};

const getProps = (btnProps: BtnPropsType): BtnPropsType => ({
  ...defaultPropsValues,
  ...btnProps
});

export function Button(btnProps: BtnPropsType = defaultPropsValues): ReactElement {
  const props: BtnPropsType = getProps(btnProps);
  const btnSizesClasses = {
    small: s.btnSmall,
    middle: s.btnMiddle,
    large: s.btnLarge
  };

  const btnTypesClasses = {
    grey: [s.btnGrey],
    transparent: [s.btnTransparent],
    secondary: [s.btnSecondary],
    success: [s.btnSuccess],
    warning: [s.btnWarning],
    primary: [s.btnPrimary],
    info: [s.btnInfo],
    outlined: [s.btnOutlined],
    primaryNew: [s.primaryNew],
    secondaryNew: [s.secondaryNew],
    tertiaryNew: [s.tertiaryNew],
    link: [s.link]
  };

  const isIconOnly = !props.children && !props.text && props.icon;
  return (
    <button
      title={props.title}
      className={cx([
        s.btn,
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        btnSizesClasses[props.size],
        ...btnTypesClasses[props.type || 'primary'],
        props.className || '',
        props.shape === 'round' ? s.btnRound : '',
        props.active ? s.btnActive : '',
        isIconOnly ? s.btnIcon : ''
      ])}
      type={props.htmlType || 'submit'}
      disabled={props.disabled}
      onClick={props.onClick}
      style={{ ...props.style, pointerEvents: props.disabled ? 'none' : 'auto' }}
      data-test-id={props.dataTestId}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onFocus={props.onFocus}
    >
      <Space direction="horizontal" size={props.iconSpace}>
        {props.iconPosition === 'left' ? props.icon : null}

        {props.children || props.text}

        {props.iconPosition !== 'left' ? props.icon : null}
      </Space>
    </button>
  );
}

export default Button;
