import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { useQuery } from 'react-query';
import { IntegrationsResponse } from '../../components/shared/models/integration/integrations/IntegrationsResponse';
import { IntegrationsRequest } from '../../components/shared/models/integration/integrations/IntegrationsRequest';

export function useIntegrations() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.integrations;

  return useQuery<IntegrationsResponse, Error>([config.api.integrations], () =>
    axios
      .post<{ resource: IntegrationsResponse }>(url, new IntegrationsRequest(config.defaultEventsCount, false))
      .then((responseData: AxiosResponse<{ resource: IntegrationsResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, IntegrationsResponse)
      )
  );
}
