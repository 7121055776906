import { JsonObject, JsonProperty } from 'json2typescript';
import { LOCAL_STORAGE_TOKEN_NAME } from '../../../pages/Main/Login/AuthConstants';
import { CustomFieldRule } from './CustomFieldRule';

@JsonObject('UpdateCustomFieldRequest')
export class UpdateCustomFieldRequest {
  @JsonProperty('user_session_id', String)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'string...
  user_session_id: string = undefined;

  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('name', String)
  name: string;

  @JsonProperty('input_field', String)
  input_field: string;

  @JsonProperty('possible_values', [String])
  possible_values: string[];

  @JsonProperty('default_value', String)
  default_value: string;

  @JsonProperty('default_original_value', Boolean)
  default_original_value?: boolean;

  @JsonProperty('rules', [CustomFieldRule])
  rules: CustomFieldRule[];

  constructor(
    job_id: number,
    name: string,
    input_field: string,
    possible_values: string[],
    default_value: string,
    default_original_value: boolean,
    rules: CustomFieldRule[]
  ) {
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
    this.user_session_id = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
    this.job_id = job_id;
    this.name = name;
    this.input_field = input_field;
    this.possible_values = possible_values;
    this.default_value = default_value;
    this.default_original_value = default_original_value;
    this.rules = rules;
  }
}
