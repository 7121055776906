import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { FeedbackBatchBucketRow } from './FeedbackBatchBucketRow';

@JsonObject('FeedbackBatchBucketResponse')
export class FeedbackBatchBucketResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('dynamic_columns', [String])
  dynamic_columns?: string[] = [];

  @JsonProperty('rows', [FeedbackBatchBucketRow])
  rows?: FeedbackBatchBucketRow[] = [];

  @JsonProperty('total', Number)
  total?: number;
}
