import { TNotificationType } from '../Notifications.types';
import { Stack, StackProps, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { getNotificationColorsByType, getNotificationIconByType } from '../Notifications.utils';
import { EIcon, Icon, Spinner } from '@arcanna/generic';
import StyledSystemNotificationTextArea from './StyledSystemNotificationTextArea';

type TSystemNotificationProps = {
  type: TNotificationType;
  title: string;
  subtitle?: string;
  isClosable?: boolean;
  hasSpinner?: boolean;
} & StackProps;

export default function SystemNotification({
  type,
  title,
  subtitle,
  isClosable,
  hasSpinner,
  ...otherProps
}: TSystemNotificationProps) {
  const { palette } = useTheme();
  const [showNotification, setShowNotification] = useState<boolean>(true);

  const onClose = () => {
    setShowNotification(false);
  };

  const icon = getNotificationIconByType(type, palette);
  const notificationColors = getNotificationColorsByType(type, palette);
  if (showNotification) {
    return (
      <Stack width="100%" flexDirection="row" justifyContent="flex-start" borderRadius="0px 4px 4px 0px" {...otherProps}>
        <Stack flexDirection="row" fontSize="24px" padding="24px 24px" bgcolor={palette.secondary[700]}>
          {icon}
        </Stack>
        <StyledSystemNotificationTextArea backgroundColor={notificationColors.backgroundColor}>
          <Stack flexDirection="row" justifyContent="flex-start" alignItems="center" gap="8px" fontSize="24px">
            {hasSpinner && <Spinner />}
            <Typography variant="body2" fontWeight={500} color={notificationColors.titleColor}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="body2" color={notificationColors.subtitleColor}>
                {subtitle}
              </Typography>
            )}
          </Stack>
          {isClosable && (
            <Icon
              name={EIcon.ActionClose}
              onClick={onClose}
              htmlColor={notificationColors.closeColor}
              width="24px"
              height="24px"
              sx={{
                fontSize: '16px',
                padding: '2px',
                cursor: 'pointer',
                marginRight: '4px',
                '&:hover': {
                  color: palette.secondary[300]
                }
              }}
            />
          )}
        </StyledSystemNotificationTextArea>
      </Stack>
    );
  }

  return <></>;
}
