export default function Switch() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="switch-vertical-02">
        <path
          id="Icon"
          /* eslint-disable-next-line max-len */
          d="M4.66667 2.6665V13.3332M4.66667 13.3332L2 10.6665M4.66667 13.3332L7.33333 10.6665M11.3333 13.3332V2.6665M11.3333 2.6665L8.66667 5.33317M11.3333 2.6665L14 5.33317"
          stroke="#AFBAC5"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
