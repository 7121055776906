import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { config } from '../../config';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { JobRollbackToModel } from '../../components/shared/models/job/JobRollbackToModel';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';

export default function useRollbackToModel(jobId: string) {
  const { t } = useTranslation(['job', 'common']);
  const queryClient = useQueryClient();

  return useMutation(
    (payload: JobRollbackToModel) =>
      axios.post<{ resource: { request: CommonResponse } }>(
        config.api.jobRollbackModel.replace(':jobId', jobId).replace(':modelId', payload.model_id),
        new CommonRequest()
      ),
    {
      onSuccess: () => {
        showSuccessNotification(t('common:actionCompleted'), t('job:updateSuccessfully'));
      },
      onError: () => {
        showErrorNotification(t('common:actionCompleted'), 'Rollback did not succeed.');
      },
      onSettled: () => {
        queryClient.invalidateQueries([config.api.jobAllRetrainHistory.replace(':jobId', jobId)]);
        queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
      }
    }
  );
}
