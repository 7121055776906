export enum EStepStatus {
  Validating = 'validating',
  Success = 'success',
  Error = 'error'
}

export type TStep = {
  status: EStepStatus;
  title: string;
  validationDescription: string;
  successDescription: string;
  errorDescription: string;
};
