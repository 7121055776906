import { TComponentsOverrides } from '../../branding.types';

export const getMuiSvgIconStyles = (): TComponentsOverrides => ({
  MuiSvgIcon: {
    styleOverrides: {
      fontSizeSmall: {
        fontSize: '16px'
      },
      fontSizeMedium: {
        fontSize: '20px'
      }
    }
  }
});
