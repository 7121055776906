import { Pagination, PaginationProps, Select, Typography } from 'antd';
import cx from 'classnames';
import styles from './styles.module.css';
import { ChevronDownIcon } from '../../../../themes/icons';

type ArcannaPaginationProps = PaginationProps & {
  custom?: boolean;
};
export function ArcannaPagination({ custom = false, ...props }: ArcannaPaginationProps) {
  const range = [
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    props.total === 0 ? 0 : (props.current - 1) * props.pageSize + 1,

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    props.current * props.pageSize > props.total ? props.total : props.current * props.pageSize
  ];

  return (
    <div className={cx({ [styles.root]: custom })}>
      {custom ? (
        <div className={styles.stats}>
          {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
          {props.total > 10 && props.showSizeChanger ? (
            <Select
              popupClassName={styles.pageSizeChangerDropdown}
              size="small"
              options={[
                { value: 10, label: '10 / page' },
                { value: 20, label: '20 / page' },
                { value: 50, label: '50 / page' },
                { value: 100, label: '100 / page' }
              ]}
              value={props.pageSize}
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin...
              onChange={(pageSize) => props.onShowSizeChange(props.current, pageSize)}
              suffixIcon={<ChevronDownIcon />}
            />
          ) : null}
          <Typography.Text className={cx(['callout', styles.total])}>
            <span className="color-nb-100">{`${range[0]?.toLocaleString()}-${range[1]?.toLocaleString()}`}</span>
            <span className="color-nb-300">out of</span>
            <span className="color-nb-100">{props.total?.toLocaleString()}</span>
            <span className="color-nb-300">entries</span>
          </Typography.Text>
        </div>
      ) : null}

      <Pagination hideOnSinglePage {...props} showSizeChanger={custom ? false : props.showSizeChanger} />
    </div>
  );
}

export default ArcannaPagination;
