import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';

@JsonObject('TimeReducedGraphPoint')
export class TimeReducedGraphPoint {
  @JsonProperty('date', String)
  date: string;

  @JsonProperty('time_units', Number)
  timeUnits: number;

  @JsonProperty('accumulated_time_units', Number)
  accumulatedTimeUnits: number;
}

@JsonObject('AccumulativeData')
export class AccumulativeTimeReducedData {
  @JsonProperty('job_title', String)
  jobTitle: string;

  @JsonProperty('job_id', Number)
  jobId: number;

  @JsonProperty('has_data', Boolean)
  hasData: boolean;

  @JsonProperty('graph_points', [TimeReducedGraphPoint])
  graphPoints: TimeReducedGraphPoint[];
}

@JsonObject('AccumulativeTimeReducedResponse')
export class AccumulativeTimeReducedResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('data', [AccumulativeTimeReducedData])
  data: AccumulativeTimeReducedData[];
}
