import { CodeBlockSettingsRecord } from '@arcanna/models/CodeBlock/CodeBlockSettingsRecord';
import { JsonObject, JsonProperty } from 'json2typescript';
import { JobStatusRecord } from './JobStatusRecord';
import { JobCreateInputRecord } from './JobCreateInputRecord';
import { JobInfoDetailRecord } from './JobInfoDetailRecord';
import { JobEditInputRecord } from './JobEditInputRecord';
import { IntegrationTypes } from '../integration/IntegrationTypes';
import { AdvancedJobSettings } from './AdvancedJobSettings';
import { JobTimeMetricsRecord } from './JobTimeMetricsRecord';
import { JobInfoProcessorRecord } from './JobInfoProcessorRecord';
import { JobInfoConfigRecord } from './JobInfoConfigRecord';
import { EnvVariableRecord } from '@arcanna/models/CodeBlock';

@JsonObject('CustomCodeIntegrationRecord')
export class CustomCodeIntegrationRecord {
  @JsonProperty('custom_code_id', String)
  customCodeId: string;

  @JsonProperty('integration_title', String)
  integrationTitle: string;

  @JsonProperty('description', String)
  description: string | null;

  @JsonProperty('enabled', Boolean)
  enabled: boolean;

  @JsonProperty('input_test', String)
  inputTest: string;

  @JsonProperty('source_code', String)
  sourceCode: string;

  @JsonProperty('env_variables', [EnvVariableRecord])
  envVariables: EnvVariableRecord[];

  @JsonProperty('settings', CodeBlockSettingsRecord)
  settings: CodeBlockSettingsRecord;

  @JsonProperty('code_signature', String)
  codeSignature: string;
}

@JsonObject('CustomCodeIntegrations')
class CustomCodeIntegrations {
  @JsonProperty('integrations', [CustomCodeIntegrationRecord])
  integrations: CustomCodeIntegrationRecord[] | null;
}

@JsonObject('JobInfoRecord')
export class JobInfoRecord {
  @JsonProperty('id', Number)
  id: number | undefined;

  @JsonProperty('title', String)
  title: string | undefined;

  @JsonProperty('description', String)
  description: string | undefined;

  @JsonProperty('status', JobStatusRecord)
  status?: JobStatusRecord = undefined;

  @JsonProperty('job_details', JobInfoDetailRecord)
  jobDetails?: JobInfoDetailRecord = undefined;

  @JsonProperty('input', JobCreateInputRecord)
  input: JobCreateInputRecord | undefined;

  @JsonProperty('processor', JobInfoProcessorRecord)
  processor: JobInfoProcessorRecord | undefined;

  @JsonProperty('enrichment', JobCreateInputRecord)
  enrichment: JobCreateInputRecord | undefined;

  @JsonProperty('context_enrichment', JobCreateInputRecord)
  context_enrichment: JobCreateInputRecord | undefined;

  @JsonProperty('case_creation', JobCreateInputRecord)
  case_creation: JobCreateInputRecord | undefined;

  @JsonProperty('post_decision', JobCreateInputRecord)
  post_decision: JobCreateInputRecord | undefined;

  @JsonProperty('alerting', JobCreateInputRecord)
  alerting: JobCreateInputRecord | undefined;

  @JsonProperty('output', JobCreateInputRecord)
  output: JobCreateInputRecord | undefined;

  @JsonProperty('custom_code', CustomCodeIntegrations)
  custom_code: CustomCodeIntegrations;

  @JsonProperty('advanced_settings', AdvancedJobSettings)
  advancedSettings: AdvancedJobSettings;

  @JsonProperty('metrics', JobTimeMetricsRecord)
  metrics: JobTimeMetricsRecord | undefined;

  @JsonProperty('trained_models_count', Number)
  trained_models_count?: number;

  @JsonProperty('job_config', [JobInfoConfigRecord] || null)
  jobConfig: JobInfoConfigRecord[] | null;

  setInputRecord(parameterType: keyof JobInfoRecord, jobEditInputRecord: JobEditInputRecord) {
    if (parameterType === IntegrationTypes.INPUT) {
      this.input = jobEditInputRecord;
    } else if (parameterType === IntegrationTypes.PROCESSOR) {
      this.processor = jobEditInputRecord;
    } else if (parameterType === IntegrationTypes.OUTPUT) {
      this.output = jobEditInputRecord;
    } else if (parameterType === IntegrationTypes.ENRICHMENT) {
      this.enrichment = jobEditInputRecord;
    } else if (parameterType === IntegrationTypes.CASE_CREATION) {
      this.case_creation = jobEditInputRecord;
    } else if (parameterType === IntegrationTypes.POST_DECISION) {
      this.post_decision = jobEditInputRecord;
    }
  }

  getIntegrations(parameterType: string) {
    switch (parameterType) {
      case IntegrationTypes.INPUT: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return this.input.integrations;
      }
      case IntegrationTypes.PROCESSOR: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return this.processor.integrations;
      }
      case IntegrationTypes.OUTPUT: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return this.output.integrations;
      }
      case IntegrationTypes.ENRICHMENT: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return this.enrichment.integrations;
      }
      case IntegrationTypes.CASE_CREATION: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return this.case_creation.integrations;
      }
      case IntegrationTypes.POST_DECISION: {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return this.post_decision.integrations;
      }
      default: {
        return null;
      }
    }
  }
}
