import { useTranslation } from 'react-i18next';
import PermissionsCheck from '../../../../shared/components/Permissions/Permissions';
import { permissions } from '../../../../shared/static/ComponentsPermissions';
import { NavLink } from 'react-router-dom';
import { config } from '../../../../../config';
import cx from 'classnames';
import style from './JobNavigation.module.css';

export default function JobFlowsNavItem({ jobId }: { jobId: string }) {
  const { t } = useTranslation(['job']);

  return (
    <PermissionsCheck permissions={[permissions.jobUpdate]} action={permissions.action.hiddenAction}>
      <NavLink
        to={config.routes.flows.replace(':id', jobId)}
        className={cx([style.navLink, 'callout-default'])}
        activeClassName={cx([style.activeNavLink, 'callout-default'])}
        data-test-id={'menu-expanded-settings'}
      >
        {t('job:navigation.flows')}
      </NavLink>
    </PermissionsCheck>
  );
}
