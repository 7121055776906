import { SVGProps } from 'react';

type TErrorIconProps = SVGProps<SVGSVGElement> & {
  fill?: string;
};

export default function Error({ fill = 'none', ...props }: TErrorIconProps) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M14.8743 15.4891H14.8766C15.2558 15.4891 15.5641 15.1808 15.5641 14.8016C15.5641 14.6309 15.5007 14.4808 15.41 14.3673L15.4101 14.3673L15.4026 14.3584L12.6252 11.0469L15.4003 7.73769L15.4004 7.73778L15.4076 7.72875C15.4984 7.61527 15.5617 7.46516 15.5617 7.29453C15.5617 6.90885 15.2494 6.60703 14.8742 6.60703L14.8719 6.60704L13.3251 6.61407L13.0932 6.61512L12.9442 6.79276L11.0002 9.11078L9.05589 6.79058L8.9069 6.61278L8.67493 6.61172L7.12571 6.60469V6.60469H7.12344C6.74417 6.60469 6.43594 6.91292 6.43594 7.29219H6.43591L6.43597 7.29776C6.43774 7.45642 6.49374 7.6097 6.59468 7.73213L6.59467 7.73215L6.5976 7.73564L9.37681 11.0446L6.59749 14.3559L6.59739 14.3558L6.59003 14.365C6.49926 14.4785 6.43594 14.6286 6.43594 14.7992C6.43594 15.1849 6.74826 15.4867 7.12344 15.4867L7.12571 15.4867L8.67493 15.4797L8.90677 15.4786L9.05576 15.301L11 12.9827L12.9466 15.3034L13.0956 15.481L13.3274 15.482L14.8743 15.4891ZM1 11C1 5.47771 5.47771 1 11 1C16.5223 1 21 5.47771 21 11C21 16.5223 16.5223 21 11 21C5.47771 21 1 16.5223 1 11Z"
        fill="#E11D48"
        stroke="#E11D48"
      />
    </svg>
  );
}
