import axios, { AxiosResponse } from 'axios';
import { JsonConvert } from 'json2typescript';
import * as React from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { LicenseInfoResponse } from '../../components/shared/models/license/LicenseInfoResponse';
import { LicenseInfoFeatures } from '../../components/shared/models/license/LicenseInfoFeatures';
import { LicenseFeature, LicenseFeatures } from '../../components/shared/models/license/LicenseFeatures';

export const routeShouldBeGenerated = (features: LicenseInfoFeatures, feature: LicenseFeature) => {
  if (feature === undefined) {
    return true;
  }
  if (features) {
    switch (feature) {
      case LicenseFeatures.USER_DEFINED_FIELDS:
        return features.userDefinedFields;
      case LicenseFeatures.TEAM_MODE:
        return features.teamMode;
      case LicenseFeatures.EXPOSER_INVESTIGATE:
        return features.exposerInvestigate;
      case LicenseFeatures.THIRD_PARTY_ENRICHMENT:
        return features.thirdPartyEnrichment;
      case LicenseFeatures.MAX_JOBS:
        return features.maxJobs;
      default:
        return false;
    }
  }
  return false;
};

export function fetchLicenseInfo(jsonConvert: JsonConvert) {
  return axios
    .post<{ resource: LicenseInfoResponse }>(config.api.licenseInfo, new CommonRequest())
    .then((responseData: AxiosResponse<{ resource: LicenseInfoResponse }>) =>
      jsonConvert.deserializeObject(responseData.data.resource, LicenseInfoResponse)
    );
}

export function useLicenseActive() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<LicenseInfoResponse, Error, boolean>([config.api.licenseInfo], () => fetchLicenseInfo(jsonConvert), {
    // @ts-expect-error TS(2322): Type '(data: LicenseInfoResponse) => boolean | und...
    select: (data: LicenseInfoResponse) => data.license.active,
    cacheTime: Infinity,
    staleTime: Infinity
  });
}

export function useLicensePlan() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, string>([config.api.licenseInfo], () => fetchLicenseInfo(jsonConvert), {
    // @ts-expect-error TS(2322): Type '(data: LicenseInfoResponse) => string | unde...
    select: (data: LicenseInfoResponse) => data.license.plan,
    cacheTime: Infinity,
    staleTime: Infinity
  });
}

export function useLicenseExpirationDate() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, string>([config.api.licenseInfo], () => fetchLicenseInfo(jsonConvert), {
    // @ts-expect-error TS(2322): Type '(data: LicenseInfoResponse) => string | unde...
    select: (data: LicenseInfoResponse) => data.license.expiration_date,
    cacheTime: Infinity,
    staleTime: Infinity
  });
}

export function useLicenseIsExpired() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, boolean>([config.api.licenseInfo], () => fetchLicenseInfo(jsonConvert), {
    // @ts-expect-error TS(2322): Type '(data: LicenseInfoResponse) => boolean | und...
    select: (data: LicenseInfoResponse) => data.license.is_expired,
    cacheTime: Infinity,
    staleTime: Infinity
  });
}

export function useLicenseFeatures() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, LicenseInfoFeatures>([config.api.licenseInfo], () => fetchLicenseInfo(jsonConvert), {
    // @ts-expect-error TS(2322): Type '(data: LicenseInfoResponse) => LicenseInfoFe...
    select: (data: LicenseInfoResponse) => data?.license?.features,
    cacheTime: Infinity,
    staleTime: Infinity
  });
}

export function useLicenseFeatureValue(feature: LicenseFeatures) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const { t } = useTranslation(['job']);

  let selectFeature;
  switch (feature) {
    case LicenseFeatures.USER_DEFINED_FIELDS:
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      selectFeature = (data: LicenseInfoResponse) => data?.license?.features.userDefinedFields;
      break;
    case LicenseFeatures.TEAM_MODE:
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      selectFeature = (data: LicenseInfoResponse) => data?.license?.features.teamMode;
      break;
    case LicenseFeatures.EXPOSER_INVESTIGATE:
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      selectFeature = (data: LicenseInfoResponse) => data?.license?.features.exposerInvestigate;
      break;
    case LicenseFeatures.MAX_JOBS:
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      selectFeature = (data: LicenseInfoResponse) => data?.license?.features.maxJobs;
      break;
    case LicenseFeatures.THIRD_PARTY_ENRICHMENT:
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      selectFeature = (data: LicenseInfoResponse) => data?.license?.features.thirdPartyEnrichment;
      break;
    default:
      throw Error(t('job:unknownLicenseFeature'));
  }

  return useQuery<JobInfoResponse, Error, number | boolean | string | object>(
    [config.api.licenseInfo],
    () => fetchLicenseInfo(jsonConvert),
    {
      // @ts-expect-error TS(2322): Type '((data: LicenseInfoResponse) => boolean | un...
      select: selectFeature,
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
}
