import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { config } from '../../config';
import { FeedbackBucketDetailsResponse } from '../../components/shared/models/feedback/FeedbackBucketDetailsResponse';
import { FeedbackBucketDetailsRequest } from '../../components/shared/models/feedback/FeedbackBucketDetailsRequest';

export function useFeedbackBucketDetails(jobId: number, bucketId: string, refetchIntervalMillis: number | false = false) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<FeedbackBucketDetailsResponse, Error>(
    [config.api.feedbackBucketDetails.replace(':bucketId', bucketId)],
    () =>
      axios
        .post<{
          resource: FeedbackBucketDetailsResponse;
        }>(config.api.feedbackBucketDetails.replace(':bucketId', bucketId), new FeedbackBucketDetailsRequest(jobId))
        .then((responseData: AxiosResponse<{ resource: FeedbackBucketDetailsResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, FeedbackBucketDetailsResponse)
        ),
    {
      enabled: Boolean(jobId && bucketId),
      refetchInterval: () => refetchIntervalMillis
    }
  );
}
