import { Any, JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
export type BatchTableSort = {
  column: string;
  order: string;
};

@JsonObject('EventExplorerBatchRequest')
export class EventExplorerBatchRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number | undefined;

  @JsonProperty('size', Number)
  size?: number | undefined;

  @JsonProperty('from', Number)
  from?: number | undefined;

  @JsonProperty('sort_by_column', String)
  sort_by_column?: string | undefined;

  @JsonProperty('sort_order', Number)
  sort_order?: string | undefined;

  @JsonProperty('start_date', String)
  start_date?: string | undefined;

  @JsonProperty('end_date', String)
  end_date?: string | undefined;

  @JsonProperty('filters', Any)
  // eslint-disable-next-line
  filters?: any | undefined;

  constructor(
    job_id: number,
    size: number,
    from: number,
    start_date: string,
    end_date: string,
    tableSort: BatchTableSort,
    // eslint-disable-next-line
    filters: any
  ) {
    super();
    this.job_id = job_id;
    this.size = size;
    this.from = from;
    this.start_date = start_date;
    this.end_date = end_date;
    this.sort_by_column = tableSort.column;
    this.sort_order = tableSort.order || 'desc';
    this.filters = filters;
  }
}
