import moment from 'moment';
import { config } from '../../../config';
import { isNumeric } from '../../../utils/numeral';

export const formatDateTime = (date: Date | string | number | undefined): string => {
  if (!date) {
    return '';
  }
  let myDate: Date | string | number = date;
  if (typeof myDate === 'string' && isNumeric(myDate)) {
    myDate = Number(myDate);
  }
  const dateMoment = moment(myDate);
  return dateMoment.format(config.defaultDateTimeFormat);
};
