import { Stack, StackProps, styled } from '@mui/material';

const StyledEmptyState = styled(Stack, {
  shouldForwardProp(propName) {
    return propName !== 'isCard';
  }
})<
  StackProps & {
    isCard?: boolean;
  }
>(({ theme, isCard }) => ({
  // fontSize for icons to inherit
  fontSize: '80px',
  flexDirection: 'row',
  gap: '24px',

  ...(isCard && {
    backgroundColor: theme.palette.secondary[900],
    border: `1px solid ${theme.palette.secondary[800]}`,
    padding: '24px',
    borderRadius: '4px'
  }),

  '.suggestionDot': {
    display: 'inline-block',
    borderRadius: '50%',
    width: '5px',
    height: '5px',
    backgroundColor: theme.palette.secondary[300]
  }
}));

export default StyledEmptyState;
