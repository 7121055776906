import { matchPath, useLocation } from 'react-router-dom';
import { config } from 'src/config';
import { EUNIFIED_JOBS_NAVIGATION_KEYS } from './UnifiedJobsNavigation.types';

export function useActiveNavigationItem() {
  const location = useLocation();

  if (matchPath(location.pathname, { path: config.routes.feedbackUnified })) {
    return EUNIFIED_JOBS_NAVIGATION_KEYS.FEEDBACK;
  }
  if (matchPath(location.pathname, { path: config.routes.decisionPointsUnified })) {
    return EUNIFIED_JOBS_NAVIGATION_KEYS.DECISION_POINTS;
  }
}
