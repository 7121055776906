import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { IntegrationTypes } from './IntegrationTypes';

type JobIntegration = {
  id: number;
  title?: string;
  subcategory_id: number;
  isValid: boolean;
};

@JsonObject('IntegrationParametersOptionsRequest')
export class IntegrationParametersOptionsRequest extends CommonRequest {
  @JsonProperty('input_subcategory', Number)
  input_subcategory?: number = undefined;

  @JsonProperty('processor_subcategory', Number)
  processor_subcategory?: number = undefined;

  @JsonProperty('output_subcategory', Number)
  output_subcategory?: number = undefined;

  @JsonProperty('current_subcategory', Number)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'number...
  current_subcategory: number = undefined;

  @JsonProperty('automation_subcategory', Number)
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'number...
  automation_subcategory: number = undefined;

  constructor(integrations: Map<string, JobIntegration>, current_subcategory: number) {
    super();
    this.current_subcategory = current_subcategory;
    integrations.forEach((integration: JobIntegration, type: string) => {
      if (type === IntegrationTypes.INPUT) {
        this.input_subcategory = integration.subcategory_id;
      } else if (type === IntegrationTypes.PROCESSOR) {
        this.processor_subcategory = integration.subcategory_id;
      } else if (type === IntegrationTypes.OUTPUT) {
        this.output_subcategory = integration.subcategory_id;
      }
    });
  }
}
