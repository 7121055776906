/* eslint-disable max-len */
function ArtificialIntelligenceSpark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.00019 7.35225V11.8894C7.99898 12.3085 7.91316 12.723 7.74788 13.1081C7.5826 13.4933 7.34126 13.841 7.03833 14.1306C6.7354 14.4202 6.37712 14.6457 5.98496 14.7935C5.59279 14.9413 5.17483 15.0084 4.75611 14.9908C4.33739 14.9731 3.92654 14.8711 3.54819 14.6909C3.16984 14.5107 2.83177 14.2559 2.55426 13.9418C2.27674 13.6278 2.06549 13.261 1.93317 12.8633C1.80085 12.4657 1.75018 12.0454 1.78419 11.6277C1.13619 10.9317 0.727051 9.57053 0.727051 8.43453C0.727051 6.8871 1.48705 5.38196 2.57505 4.78882C2.5161 4.24832 2.62049 3.70256 2.87475 3.22197C3.129 2.74138 3.52145 2.34801 4.00145 2.09263C4.48144 1.83726 5.02696 1.7316 5.56759 1.78928C6.10823 1.84696 6.61917 2.06535 7.03448 2.41625"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.01275 6.63916C3.61275 6.54773 2.70875 5.95345 2.57275 4.79688"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.61717 9.99561C2.21031 10.3167 1.88574 11.0436 1.78174 11.6265"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.8895C8.00121 12.3086 8.08703 12.7231 8.25231 13.1082C8.41759 13.4934 8.65893 13.8412 8.96186 14.1308C9.26479 14.4204 9.62307 14.6458 10.0152 14.7936C10.4074 14.9414 10.8254 15.0085 11.2441 14.9909C11.6628 14.9732 12.0737 14.8713 12.452 14.691C12.8304 14.5108 13.1684 14.256 13.4459 13.942C13.7235 13.6279 13.9347 13.2611 14.067 12.8634C14.1993 12.4658 14.25 12.0455 14.216 11.6278C14.864 10.9318 15.272 9.57065 15.272 8.43465C15.272 7.93865 15.1943 7.44722 15.0526 6.98779"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3828 9.99561C13.7897 10.3167 14.1142 11.0436 14.2182 11.6265"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.07344 4.9225C7.67344 4.85165 7.67344 4.27679 8.07344 4.20708C8.78391 4.08367 9.44153 3.75143 9.9624 3.25274C10.4833 2.75405 10.8438 2.11151 10.998 1.40708L11.022 1.29622C11.1089 0.899647 11.6734 0.896219 11.7637 1.29279L11.7923 1.42193C11.9524 2.12318 12.3162 2.76146 12.838 3.25659C13.3597 3.75171 14.0162 4.08159 14.7249 4.20479C15.1283 4.2745 15.1283 4.85393 14.7249 4.92479C14.0162 5.04799 13.3597 5.37787 12.838 5.873C12.3162 6.36812 11.9524 7.0064 11.7923 7.70765L11.7637 7.83565C11.6723 8.23108 11.1089 8.22879 11.022 7.83222L10.998 7.7225C10.8438 7.01807 10.4833 6.37553 9.9624 5.87684C9.44153 5.37815 8.78391 5.04592 8.07344 4.9225Z"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArtificialIntelligenceSpark;
