import { useJobStatus } from '../../../../../../../data-access';
import SystemNotification from '../../../../../../../_srcMUI/shared/components/Notifications/SystemNotification';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type TJobInvalidNotificationProps = {
  jobId: number;
};

export default function JobInvalidNotification({ jobId }: TJobInvalidNotificationProps) {
  const { t } = useTranslation(['job']);
  const [isJobInvalid, setIsJobInvalid] = useState(false);

  const jobStatus = useJobStatus(jobId);

  const jobInvalidFlag = jobStatus?.data?.invalid;
  const jobInvalidReason = jobStatus?.data?.invalid_reason;

  useEffect(
    function establishIfJobIsInvalid() {
      setIsJobInvalid(jobInvalidFlag ? jobInvalidFlag : false);
    },
    // eslint-disable-next-line
    [jobStatus?.data, jobStatus.isLoading]
  );

  return isJobInvalid ? (
    <SystemNotification
      title={t('job:notifications:jobEnteredInvalidState')}
      subtitle={jobInvalidReason}
      type="warning"
      sx={{ marginBottom: '16px' }}
    />
  ) : null;
}
