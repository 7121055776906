import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './JobOverviewMetrics.module.css';
import { EmptyFolderPlusIcon } from '../../../../../../themes/icons';
import { useJobOverviewContext } from '../JobOverview.context';
import MedianDecisionTimeMetric from './MedianDecisionTimeMetric';

type TTimeMetricsEmptyStateNoMetricsProps = {
  jobId: number;
};

export default function TimeMetricsEmptyStateNoMetrics({ jobId }: TTimeMetricsEmptyStateNoMetricsProps) {
  const { t } = useTranslation(['job']);
  const { setEditTimeMetrics } = useJobOverviewContext();

  return (
    <>
      <Row>
        <Typography.Text className="body-2 color-nb-100">{t('job:jobTimeMetrics:title')}</Typography.Text>
      </Row>
      <Row>
        <Col>
          <Row className={style.emptyIcon}>
            <EmptyFolderPlusIcon />
          </Row>
        </Col>
        <Col style={{ paddingRight: 24 }}>
          <Row>
            <Typography.Text className={style.noDataHeaderText}>{t('job:jobTimeMetrics:emptyStateHeader')}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text type="secondary" className={style.noDataText}>
              {
                <Typography.Text style={{ cursor: 'pointer' }} underline={true} onClick={() => setEditTimeMetrics(true)}>
                  {t('job:jobTimeMetrics:emptyStateClickHere')}
                </Typography.Text>
              }
              {t('job:jobTimeMetrics:emptyStateText')}
            </Typography.Text>
          </Row>
        </Col>
        <MedianDecisionTimeMetric jobId={jobId} />
      </Row>
    </>
  );
}
