import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackStatisticsUserRecord')
export class FeedbackStatisticsUserRecord {
  @JsonProperty('username', String)
  username?: string;

  @JsonProperty('events_given_feedback', Number)
  events_given_feedback?: number;

  @JsonProperty('labels_confirmed', Number)
  labels_confirmed?: number;

  @JsonProperty('labels_changed', Number)
  labels_changed?: number;

  @JsonProperty('consensus', Number)
  consensus?: number;
}
