import * as React from 'react';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { useQuery } from 'react-query';
import { config } from '../../config';
import { FeedbackBatchBucketResponse } from '../../components/shared/models/feedback/FeedbackBatchBucketResponse';
import axios, { AxiosResponse } from 'axios';
import { FeedbackBatchRequest } from '../../components/shared/models/feedback/FeedbackBatchRequest';

export function useFeedbackBucketsCountTotal(jobId: number) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const payload = new FeedbackBatchRequest(jobId);
  // @ts-expect-error TS(2769): No overload matches this call.
  return useQuery<number, Error>([config.api.feedbackBucketsBatch + `${jobId}-count-total`], () =>
    axios
      .post(config.api.feedbackBucketsBatch, payload)
      .then((responseData: AxiosResponse<{ resource: FeedbackBatchBucketResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, FeedbackBatchBucketResponse)
      )
      .then((response: FeedbackBatchBucketResponse) => response.total)
  );
}
