import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';
import { IntegrationCreateParameter } from './IntegrationCreateParameter';

@JsonObject('JobUsageRecord')
export class JobUsageRecord {
  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('roles', [String])
  roles: string[];
}

@JsonObject('IntegrationReadRecord')
export class IntegrationReadRecord extends CommonRequest {
  @JsonProperty('id', Number)
  id?: number = undefined;

  @JsonProperty('category_id', Number)
  category_id?: number = undefined;

  @JsonProperty('subcategory_id', Number)
  subcategory_id?: number = undefined;

  @JsonProperty('title', String)
  title?: string = undefined;

  @JsonProperty('is_valid', Boolean)
  isValid?: boolean | undefined;

  @JsonProperty('job_usages', [JobUsageRecord])
  job_usages?: JobUsageRecord[];

  @JsonProperty('parameters', Array<IntegrationCreateParameter>())
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'Integr...
  parameters: Array<IntegrationCreateParameter> = undefined;

  constructor(
    category_id: number,
    subcategory_id: number,
    title: string,
    parameters: Array<IntegrationCreateParameter>,
    job_usages?: JobUsageRecord[]
  ) {
    super();
    this.category_id = category_id;
    this.subcategory_id = subcategory_id;
    this.title = title;
    this.parameters = parameters;
    this.job_usages = job_usages;
  }
}
