/* eslint-disable max-len */
function Stars01() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_22435_1446)">
        <path
          d="M4.33325 8.66659L4.85622 9.71253C5.03322 10.0665 5.12171 10.2435 5.23994 10.3969C5.34485 10.533 5.46686 10.655 5.60295 10.7599C5.75633 10.8781 5.93332 10.9666 6.28731 11.1436L7.33325 11.6666L6.28731 12.1896C5.93332 12.3666 5.75633 12.455 5.60295 12.5733C5.46686 12.6782 5.34485 12.8002 5.23994 12.9363C5.12171 13.0897 5.03322 13.2667 4.85622 13.6206L4.33325 14.6666L3.81028 13.6206C3.63329 13.2667 3.54479 13.0897 3.42656 12.9363C3.32166 12.8002 3.19965 12.6782 3.06355 12.5733C2.91018 12.455 2.73318 12.3666 2.3792 12.1896L1.33325 11.6666L2.3792 11.1436C2.73318 10.9666 2.91017 10.8781 3.06355 10.7599C3.19965 10.655 3.32166 10.533 3.42656 10.3969C3.54479 10.2435 3.63329 10.0665 3.81028 9.71253L4.33325 8.66659Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99992 1.33325L10.7857 3.3762C10.9737 3.86502 11.0677 4.10943 11.2139 4.31501C11.3434 4.49722 11.5026 4.65642 11.6848 4.78598C11.8904 4.93216 12.1348 5.02616 12.6236 5.21417L14.6666 5.99992L12.6236 6.78567C12.1348 6.97367 11.8904 7.06768 11.6848 7.21386C11.5026 7.34342 11.3434 7.50262 11.2139 7.68482C11.0677 7.89041 10.9737 8.13482 10.7857 8.62364L9.99992 10.6666L9.21417 8.62364C9.02616 8.13482 8.93216 7.89041 8.78598 7.68482C8.65642 7.50262 8.49722 7.34342 8.31501 7.21386C8.10943 7.06768 7.86502 6.97367 7.3762 6.78567L5.33325 5.99992L7.3762 5.21417C7.86502 5.02616 8.10943 4.93216 8.31501 4.78598C8.49722 4.65642 8.65642 4.49722 8.78598 4.31501C8.93216 4.10943 9.02616 3.86502 9.21417 3.3762L9.99992 1.33325Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_22435_1446">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Stars01;
