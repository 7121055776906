/* eslint-disable max-len */

function Play() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.05829 0.0224625C1.72045 0.0221283 1.38809 0.107873 1.09257 0.271605C0.789519 0.426076 0.534214 0.660106 0.354024 0.948611C0.173835 1.23712 0.0755542 1.56922 0.0697174 1.90932V14.1025V14.1116C0.0755542 14.4517 0.173835 14.7838 0.354024 15.0723C0.534214 15.3608 0.789519 15.5949 1.09257 15.7493C1.39345 15.9164 1.73255 16.0026 2.0767 15.9994C2.42086 15.9962 2.75829 15.9037 3.056 15.731L12.9189 9.63046C13.2232 9.47708 13.479 9.24217 13.6577 8.95192C13.8364 8.66168 13.9309 8.32751 13.9308 7.98668C13.9307 7.64585 13.8359 7.31175 13.657 7.02163C13.4781 6.73151 13.2222 6.49678 12.9177 6.34361L3.05486 0.288748C2.75188 0.113873 2.40812 0.0220206 2.05829 0.0224625Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Play;
