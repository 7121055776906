import _ from 'lodash';

const generateRandomString = () => {
  const length = _.random(8, 14);
  const charset = 'abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ123456789~!@#$%^&*()|[]{}';
  const charsetArray = charset.split('');
  const passwordArray = _.times(length, () => _.sample(charsetArray));
  return passwordArray.join('');
};

export { generateRandomString };
