import { createContext, useContext, useState } from 'react';

import { CsvEditorStructureRecord } from '../../../shared/models/csv-editor/CsvEditorStructureRecord';
import { CsvEditorBatchRecord } from '../../../shared/models/csv-editor/CsvEditorBatchRecord';

// eslint-disable-next-line
export const CsvEditorContext = createContext<CsvEditorContextType>(null as any);

export type CsvEditorStateType = {
  id: number;
  expanded: boolean;
  loading: boolean;
  readonly: boolean;
  structureData: CsvEditorStructureRecord;
  tableData: CsvEditorBatchRecord[];
  totalNumberOfItems: number;
  reloadTableData: boolean;
  // eslint-disable-next-line
  editRows: Object;
  editRowsCount: number;
  editAll: boolean;
  showAddNewModal: boolean;
  // eslint-disable-next-line
  structureColumns: any[];
};

export type CsvEditorContextType = {
  state: CsvEditorStateType;
  setExpanded: (expanded: boolean) => void;
  setLoading: (loading: boolean) => void;
  setDates: (startDate: Date, endDate: Date, excludeProcessed: boolean, readonly: boolean) => void;
  setStructureData: (structureData: CsvEditorStructureRecord) => void;
  setTableData: (tableData: CsvEditorBatchRecord[], totalNumberOfItems?: number) => void;
  setEditRow: (editId: string, editRow: CsvEditorBatchRecord) => void;
  setEditAll: (editAll: boolean, reloadTableData: boolean, loading: boolean) => void;
  setTotalNumberOfItems: (totalNumberOfItems: number) => void;
  setShowAddNewModal: (showAddNewModal: boolean) => void;
  setReloadTableData: (reloadTableData: boolean) => void;
  setStructureColumns: (structureColumn: CsvEditorStructureRecord[]) => void;
};

// eslint-disable-next-line
export function CsvEditorProvider(props: any) {
  const [state, setState] = useState<CsvEditorStateType>({
    id: Math.random(),
    expanded: false,
    loading: false,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'boolean'.
    readonly: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'CsvEditorSt...
    structureData: null,
    tableData: [],
    reloadTableData: false,
    editRows: {},
    editRowsCount: 0,
    editAll: false,
    totalNumberOfItems: 0,
    showAddNewModal: false,
    structureColumns: []
  });

  const setExpanded = (expanded: boolean) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      expanded
    }));
  };

  const setLoading = (loading: boolean) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      loading
    }));
  };

  const setDates = (startDate: Date, endDate: Date, excludeProcessed: boolean, readonly: boolean) => {
    // @ts-expect-error TS(2345): Argument of type '(current: CsvEditorStateType) =>...
    setState((current: CsvEditorStateType) => ({
      ...current,
      readonly,
      structureData: null,
      tableData: [],
      editRows: {},
      editRowsCount: 0,
      editAll: false,
      reloadTableData: true
    }));
  };

  const setStructureData = (structureData: CsvEditorStructureRecord) => {
    // @ts-expect-error TS(2345): Argument of type '(current: CsvEditorStateType) =>...
    setState((current: CsvEditorStateType) => ({
      ...current,
      structureData,
      tableData: [],
      editRows: {},
      editRowsCount: 0,
      editAll: null
    }));
  };

  const setTableData = (tableData: CsvEditorBatchRecord[], totalNumberOfItems = 0) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      tableData,
      editAll: false,
      reloadTableData: false,
      loading: false,
      totalNumberOfItems
    }));
  };

  const setTotalNumberOfItems = (totalNumberOfItems: number) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      totalNumberOfItems
    }));
  };

  const setEditRow = (editId: string, editRow: CsvEditorBatchRecord) => {
    const newEditRows = Object.assign(state.editRows);
    newEditRows[editId] = editRow;

    const editRowsCount = Object.entries(newEditRows).length;
    setState((current: CsvEditorStateType) => ({
      ...current,
      editRows: newEditRows,
      editRowsCount,
      editAll: false
    }));
  };

  const setEditAll = (editAll: boolean, reloadTableData: boolean, loading: boolean) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      editAll,
      reloadTableData,
      loading
    }));
  };

  const setShowAddNewModal = (showAddNewModal: boolean) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      showAddNewModal
    }));
  };

  const setStructureColumns = (structureColumns: CsvEditorStructureRecord[]) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      structureColumns
    }));
  };

  const setReloadTableData = (reloadTableData: boolean) => {
    setState((current: CsvEditorStateType) => ({
      ...current,
      reloadTableData
    }));
  };

  const value: CsvEditorContextType = {
    state,
    setExpanded,
    setLoading,
    setDates,
    setStructureData,
    setTableData,
    setEditRow,
    setEditAll,
    setTotalNumberOfItems,
    setShowAddNewModal,
    setStructureColumns,
    setReloadTableData
  };

  const { children } = props;

  return <CsvEditorContext.Provider value={value}>{children}</CsvEditorContext.Provider>;
}

export function useCsvEditorContext(): CsvEditorContextType {
  return useContext(CsvEditorContext);
}
