import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Spinner, StateChip } from '@arcanna/generic';

export function JobRetrainStartingTag() {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return <StateChip color={theme.palette.warning[600]} text={t('job:jobRetrainState.startedRetraining')} icon={<Spinner />} />;
}
