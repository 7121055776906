import Icon, {
  HomeOutlined,
  FundOutlined,
  FileDoneOutlined,
  UserSwitchOutlined,
  FileExcelOutlined,
  UsergroupAddOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  UserOutlined,
  BarChartOutlined,
  AlertOutlined,
  WifiOutlined,
  SecurityScanOutlined,
  DatabaseOutlined,
  BulbOutlined,
  KeyOutlined,
  ApiOutlined,
  ProfileOutlined
} from '@ant-design/icons';

import { ReactComponent as KibanaSVG } from './icons/Kibana-Larger.svg';
import { ReactComponent as PlaySVG } from './icons/Play.svg';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

export const HomeIcon = (props: IconComponentProps) => <HomeOutlined {...props} />;
export const DashboardsIcon = (props: IconComponentProps) => <FundOutlined {...props} />;
export const UseCaseIcon = (props: IconComponentProps) => <AppstoreOutlined {...props} />;
export const ReportIcon = (props: IconComponentProps) => <FileDoneOutlined {...props} />;
export const ExportCsvIcon = (props: IconComponentProps) => <FileExcelOutlined {...props} />;
export const ManagementIcon = (props: IconComponentProps) => <UserSwitchOutlined {...props} />;
export const ManageEquipmentIcon = (props: IconComponentProps) => <UserSwitchOutlined {...props} />;
export const UserManagementIcon = (props: IconComponentProps) => <UsergroupAddOutlined {...props} />;
export const CyberDefenceIcon = (props: IconComponentProps) => <SecurityScanOutlined {...props} />;
export const AIJobsManagementIcon = (props: IconComponentProps) => <ProfileOutlined {...props} />;
export const DataSourcesIcon = (props: IconComponentProps) => <DatabaseOutlined {...props} />;
export const IntegrationsIcon = (props: IconComponentProps) => <ApiOutlined {...props} />;
export const ClientDataManagementIcon = (props: IconComponentProps) => <FileExcelOutlined {...props} />;
export const LogoutIcon = (props: IconComponentProps) => <LogoutOutlined {...props} />;
export const AvatarIcon = (props: IconComponentProps) => <UserOutlined {...props} />;
export const DashboardJobIcon = (props: IconComponentProps) => <BarChartOutlined {...props} />;
export const FeedbackIcon = (props: IconComponentProps) => <AlertOutlined {...props} />;
export const RetrainIcon = (props: IconComponentProps) => <BulbOutlined {...props} />;
export const CsvEditorIcon = (props: IconComponentProps) => <FileExcelOutlined {...props} />;
export const WifiIcon = (props: IconComponentProps) => <WifiOutlined {...props} />;
export const KibanaIcon = (props: IconComponentProps) => <Icon component={KibanaSVG} {...props} />;
export const PlayIcon = (props: IconComponentProps) => <Icon component={PlaySVG} {...props} />;
export const UserKeyIcon = (props: IconComponentProps) => <KeyOutlined {...props} />;

export const ReportLogo = (props: IconComponentProps) => <span {...props} />;
export const ExportCsvLogo = (props: IconComponentProps) => <span {...props} />;
export const ManagementLogo = (props: IconComponentProps) => <span {...props} />;
export const ClientDataManagementLogo = (props: IconComponentProps) => <span {...props} />;
export const UserManagementLogo = (props: IconComponentProps) => <span {...props} />;
export const CyberDefenceLogo = (props: IconComponentProps) => <span {...props} />;
export const AIJobsManagementLogo = (props: IconComponentProps) => <span {...props} />;
export const DataSourcesLogo = (props: IconComponentProps) => <span {...props} />;
export const IntegrationsLogo = (props: IconComponentProps) => <span {...props} />;
export const StaticRulesLogo = (props: IconComponentProps) => <span {...props} />;
export const KibanaLogo = (props: IconComponentProps) => <span {...props} />;
export const UserKeyLogo = (props: IconComponentProps) => <span {...props} />;
