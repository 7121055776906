import { ReactNode, useCallback, useState } from 'react';
import { jobNotificationsContext, JobNotificationsContextType, JobNotificationsStateType } from './JobNotifications.context';

const jobNotificationsInitialState: JobNotificationsStateType = {
  bucketsModelUpdateStarted: false,
  bucketsModelUpdateFinished: false
};

export function JobNotificationsProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useState<JobNotificationsStateType>(jobNotificationsInitialState);

  const setBucketsModelUpdateStarted = useCallback((bucketsModelUpdateStarted: boolean) => {
    setState((current: JobNotificationsStateType) => ({
      ...current,
      bucketsModelUpdateStarted
    }));
  }, []);

  const setBucketsModelUpdateFinished = useCallback((bucketsModelUpdateFinished: boolean) => {
    setState((current: JobNotificationsStateType) => ({
      ...current,
      bucketsModelUpdateFinished
    }));
  }, []);

  const contextValue: JobNotificationsContextType = {
    state,
    setBucketsModelUpdateStarted,
    setBucketsModelUpdateFinished
  };
  return <jobNotificationsContext.Provider value={contextValue}>{children}</jobNotificationsContext.Provider>;
}
