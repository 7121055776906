import { useTranslation } from 'react-i18next';
import { StateChip } from '@arcanna/generic';
import { useTheme } from '@mui/material';

function JobReadyToSelectFeaturesTag() {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return <StateChip color={theme.palette.info[400]} text={t('job:jobState.readyToSelectFeatures')} />;
}

export default JobReadyToSelectFeaturesTag;
