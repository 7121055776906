import { Row, Col, Table } from 'antd';
import { useUtils } from '../../hooks/useUtils';

export interface ColumnProps {
  title: string;
  dataIndex: string;
  key: string;
}

export interface TableProps {
  // eslint-disable-next-line
  dataSource: Array<any>;
  columns: Array<ColumnProps>;
}

export function TableView(props: TableProps) {
  const utils = useUtils();

  // eslint-disable-next-line
  const rowStyle = (record: any): string => utils.colorClassNameForKey(record);

  if (props.dataSource != null) {
    return (
      <div>
        <Row>
          <Col span={24} className="flex flex-center flex-col flex-align-center">
            <Table rowClassName={rowStyle} dataSource={props.dataSource} columns={props.columns} showSorterTooltip={false} />
          </Col>
        </Row>
      </div>
    );
  }

  return <></>;
}
