import { Button, FormHelperText, List, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';
import StyledRHFCustomItemList from './StyledRHFCustomItemList.styles';
import { Label } from '@arcanna/generic';
import Item from './Item';
import { useTranslation } from 'react-i18next';

type TRHFCustomItemListProps<T> = {
  name: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  onAdd: () => void;
  onEdit?: (item: T) => void;
  isSingle?: boolean;
  renderItemTitle?: (listItem: T) => ReactNode;
};

export type TRHFCustomItem = { icon: ReactNode; name: string };

function RHFCustomItemList<T extends TRHFCustomItem>({
  name,
  required,
  tooltipText,
  label,
  onAdd,
  onEdit,
  isSingle = false,
  renderItemTitle
}: TRHFCustomItemListProps<T>) {
  const { t } = useTranslation(['common']);
  const {
    control,
    formState: { isSubmitting },
    setValue
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledRHFCustomItemList isError={!!error} direction="column" gap={0.5}>
          {label && <Label required={required} text={label} tooltipText={tooltipText} />}
          {field?.value?.length ? (
            <List className="list-wrapper">
              {field?.value?.map((listItem: T, index: number) => (
                <Item
                  key={index}
                  item={listItem}
                  // @ts-expect-error TS(2322): Type 'false | (() => void)' is not assignable to t...
                  onDelete={
                    !isSingle &&
                    (() => {
                      const newValues = [...field.value];
                      newValues.splice(index, 1);

                      setValue(name, newValues, { shouldDirty: true, shouldValidate: true });
                    })
                  }
                  isDisabled={isSubmitting}
                  title={
                    renderItemTitle ? renderItemTitle(listItem) : <Typography variant="subtitle1">{listItem.name}</Typography>
                  }
                  onEdit={onEdit}
                  dataTestId={`${listItem.name.replace(/\s+/g, '-').toLowerCase()}-edit-btn`}
                />
              ))}
            </List>
          ) : (
            <button data-test-id={`${name}-add-button`} className="add-button" type="button" onClick={onAdd}>
              {t('add+')}
            </button>
          )}
          {!isSingle && Boolean(field?.value?.length) && (
            <Button
              variant="contained"
              type="button"
              color="secondary"
              onClick={onAdd}
              size="small"
              sx={{ alignSelf: 'flex-start' }}
            >
              {t('add+')}
            </Button>
          )}
          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </StyledRHFCustomItemList>
      )}
    />
  );
}

export default RHFCustomItemList;
