import { EditorView } from '@codemirror/view';
import { alpha } from '@mui/material';
import { Palette } from '@mui/material/styles';

type TGetCodeEditorThemeParams = {
  palette: Palette;
  hasError?: boolean;
  guttersBorderRadius?: string;
  contentBorderRadius?: string;
  mode: 'light' | 'dark';
};

const getCodeMirrorTheme = ({
  palette,
  hasError,
  guttersBorderRadius = '4px 0 0 4px',
  contentBorderRadius = '4px 4px 4px 4px',
  mode
}: TGetCodeEditorThemeParams) => {
  const errorBackgroundColor = alpha(palette.error.main, 0.05);
  const defaultBackgroundColor = mode === 'dark' ? palette.background.default : palette.secondary[900];
  const backgroundColor = hasError ? errorBackgroundColor : defaultBackgroundColor;
  const defaultActiveLineBackgroundColor = mode === 'dark' ? palette.secondary[900] : palette.secondary[800];
  const activeLineBackgroundColor = hasError ? errorBackgroundColor : defaultActiveLineBackgroundColor;
  const activeLineGutterTextColor = mode === 'dark' ? '#ff9e64' : palette.secondary[300];
  const borderColor = hasError ? palette.error.main : palette.secondary[700];

  return EditorView.theme({
    '&': {
      backgroundColor: backgroundColor,
      border: '1px solid',
      borderColor: borderColor,
      borderRadius: contentBorderRadius,
      fontFamily: 'JetBrains Mono',
      fontSize: '12px',
      lineHeight: '18px',
      padding: '16px'
    },
    '.cm-gutters': {
      backgroundColor: backgroundColor,
      color: palette.secondary[500],
      border: 'none',
      borderRadius: guttersBorderRadius,
      margin: 0
    },
    '.cm-gutterElement': {
      margin: 0
    },
    '.cm-activeLineGutter': {
      backgroundColor: activeLineBackgroundColor,
      color: activeLineGutterTextColor
    },
    '.cm-activeLine': {
      backgroundColor: activeLineBackgroundColor
    },
    '.cm-cursor': {
      borderLeft: 'solid 2px #EFF'
    },
    '.cm-lintPoint:after': {
      borderBottom: `4px solid ${palette.error.main}`
    },
    '.cm-selectionLayer': {
      backgroundColor: palette.secondary[700]
    },
    '.cm-selectionBackground': {
      backgroundColor: palette.secondary[700]
    },
    '.cm-diagnostic': {
      backgroundColor: palette.secondary[900],
      color: palette.error.light,
      border: '1px solid',
      borderColor: palette.secondary[700],
      boderRadius: '4px'
    },
    '.cm-panels-bottom': {
      borderRadius: '4px'
    },
    '.cm-panel button': {
      color: palette.secondary[500]
    },
    '.cm-diagnostic-info': {
      borderLeft: '5px solid',
      borderRadius: '4px'
    },
    '.cm-search': {
      backgroundColor: palette.secondary[900],
      borderRadius: '4px',
      border: '1px solid',
      borderColor: palette.secondary[700]
    },
    '.cm-textfield': {
      backgroundColor: palette.secondary[900],
      color: palette.secondary[100],
      outline: 'none',
      border: '1px solid',
      borderColor: palette.secondary[700],
      borderRadius: '4px',
      '&:focus': {
        borderColor: palette.info.main
      }
    },
    '.cm-search input[type="checkbox"]': {
      appearance: 'none',
      webkitAppearance: 'none',
      mozAppearance: 'none',
      width: '20px',
      height: '20px',
      border: '1px solid',
      borderColor: palette.secondary[700],
      borderRadius: '4px',
      position: 'relative',
      cursor: 'pointer'
    },
    '.cm-search input[type="checkbox"]:checked': {
      backgroundColor: palette.primary.main,
      border: '1px solid',
      borderColor: palette.secondary[900]
    },
    '.cm-button': {
      backgroundColor: palette.secondary[800],
      color: palette.text.primary,
      border: '1px solid',
      borderColor: palette.secondary[700],
      borderRadius: '4px',
      cursor: 'pointer',
      backgroundImage: 'none',
      '&:hover': {
        backgroundColor: palette.secondary[700],
        borderColor: palette.secondary[500]
      }
    },
    '.cm-tooltip-autocomplete': {
      backgroundColor: palette.secondary[900],
      color: palette.secondary[100],
      border: '1px solid',
      borderColor: palette.secondary[700],
      borderRadius: '4px',
      '& > ul': {
        padding: '20px',
        margin: 0,
        listStyle: 'none'
      },
      '& > ul > li': {
        cursor: 'pointer',
        '&:hover': {
          color: palette.secondary[100],
          backgroundColor: palette.secondary[800]
        }
      },
      '& > ul > li[aria-selected]': {
        backgroundColor: palette.secondary[800],
        color: palette.grey.A100
      }
    }
  });
};

export { getCodeMirrorTheme };
