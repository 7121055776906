import { useMemo } from 'react';
import { useEventInfo } from 'src/data-access';

export function useEventClusterId(eventId: string, jobId: string): { clusterId: string | undefined } {
  const eventInfoQuery = useEventInfo({ jobId: Number(jobId), eventId: eventId, includeInternal: true });

  const clusterId = useMemo(
    () => eventInfoQuery?.data?.entries?.find((entry) => entry.name === 'arcanna.clustering.cluster_id')?.value,
    [eventInfoQuery]
  );

  return {
    clusterId
  };
}
