import { JobCategory } from 'src/components/pages/Main/Jobs/helper';
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { TJobFlowsContext } from './JobFlowsContext.type';

export const initialJobFlowsContext: TJobFlowsContext = {
  state: {
    jobId: undefined,
    jobCategory: JobCategory.DECISION_INTELLIGENCE,
    editIndex: undefined,
    initialIntegrations: [],
    integrations: [],
    cache: false,
    codeBlockToEdit: undefined,
    contextEnrichmentToEdit: undefined,
    enabledIntegrations: [],
    initialEnabledIntegrations: []
  },
  setJobId: () => {},
  setEditIndex: () => {},
  setIntegrations: () => {},
  setInitialIntegrations: () => {},
  addIntegration: () => {},
  setIntegrationOnEditEnd: () => {},
  addDraggableIntegration: () => {},
  setDraggableIntegration: () => {},
  setCache: () => {},
  setCodeBlockToEdit: () => {},
  setContextEnrichmentToEdit: () => {},
  setEnabledIntegrations: () => {},
  setEnabledIntegrationByIndex: () => {},
  setEnabledIntegrationNextToLast: () => {},
  setInitialEnabledIntegrations: () => {},
  setJobCategory: () => {},
  isFormDirty: false
};

export const JobFlowsContext = createContext<TJobFlowsContext>(initialJobFlowsContext);
