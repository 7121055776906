import { Tag, TagProps } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

type IntegrationRoleTagProps = TagProps & { role: string };

export default function IntegrationRoleTag({ role, ...rest }: IntegrationRoleTagProps) {
  const { t } = useTranslation('integration');

  return (
    <Tag className={styles.root} {...rest}>
      {t(role)}
    </Tag>
  );
}
