import axios, { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { JobUpdateRequest } from '../../components/shared/models/job/JobUpdateRequest';
import { JobUpdateResponse } from '../../components/shared/models/job/JobUpdateResponse';
import { REQUEST_STATUS_NOK } from '../../components/shared/hooks/useFetch';
import { useTranslation } from 'react-i18next';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';

type Response = AxiosResponse<{ resource: JobUpdateResponse }>;

type UseJobSave = {
  jobId: number;
};
export function useSaveJob({ jobId }: UseJobSave) {
  const { t } = useTranslation(['job', 'common']);
  const queryClient = useQueryClient();

  return useMutation<Response, Response, JobUpdateRequest>(
    (payload: JobUpdateRequest) =>
      axios.post<{ resource: JobUpdateResponse }>(config.api.jobUpdate.replace(':id', `${jobId}`), payload),
    {
      onSuccess: (responseData: Response) => {
        const jobUpdate = responseData.data.resource;
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        if (jobUpdate.request.status === REQUEST_STATUS_NOK) {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          showErrorNotification(t('common:actionCompleted'), t('common:updateError') + jobUpdate.request.reason);
          return;
        }
        showSuccessNotification(t('common:actionCompleted'), t('job:updateSuccessfully'));
        queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
        queryClient.invalidateQueries([config.api.jobsSummary]);
        queryClient.invalidateQueries([config.api.jobActivityLog.replace(':id', `${jobId}`)]);
      },
      onError: (responseError: Response) => {
        showErrorNotification(t('common:actionCompleted'), t('job:updateError') + responseError.data.resource?.request?.reason);
      }
    }
  );
}
