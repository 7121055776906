import { Space, Typography } from 'antd';
import style from './JobOverviewMetrics.module.css';
import { formatNumber } from '../../../../../../utils/numeral';

export type TValueUnitProps = {
  value: number;
  unit: string;
  hasMultipleValues?: boolean;
};

function ValueUnit({ value, unit, hasMultipleValues }: TValueUnitProps) {
  let valueToDisplay;
  if (unit === 'seconds' && !hasMultipleValues) {
    valueToDisplay = formatNumber(value, 2);
  } else {
    valueToDisplay = formatNumber(value);
  }

  return (
    <Space size={2} align="baseline">
      <span className={style.metricsNumber}>{valueToDisplay}</span>
      <Typography.Text type="secondary" className={style.smallText}>
        {unit}
      </Typography.Text>
    </Space>
  );
}

export type TValueUnitListProps = {
  valueUnitArray: TValueUnitProps[];
};

function ValueUnitList({ valueUnitArray }: TValueUnitListProps) {
  const hasMultipleValues = valueUnitArray.length > 1;

  return (
    <Space size={4} align="baseline">
      {valueUnitArray.map((item: TValueUnitProps) => (
        <ValueUnit key={`${item.value}-${item.unit}`} value={item.value} unit={item.unit} hasMultipleValues={hasMultipleValues} />
      ))}
    </Space>
  );
}

export default ValueUnitList;
