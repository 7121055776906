export function useRouteMatch() {
  function isParam(param: string) {
    return param[0] === ':';
  }

  function checkUrlHasSlashAtEnd(url: string) {
    return url !== '/' && url[url.length - 1] === '/';
  }

  function isRouteMatch(urlPath: string, routePath: string) {
    if (routePath === '*') return true;

    const urlHasSlashAtEnd = checkUrlHasSlashAtEnd(urlPath);
    const urlSplit = (urlHasSlashAtEnd ? urlPath.substring(0, urlPath.length - 1) : urlPath).split('/');
    const routeSplit = routePath.split('/');

    if (urlSplit.length !== routeSplit.length) return false;

    let isMatch = true;

    for (let i = 0; i < urlSplit.length; i++) {
      const subPathsDoNotMatch = urlSplit[i] !== routeSplit[i];
      const subPathsMatch = urlSplit[i] === routeSplit[i];

      if ((subPathsDoNotMatch && isParam(routeSplit[i])) || subPathsMatch) {
        continue;
      }
      isMatch = false;
      break;
    }
    return isMatch;
  }

  return { isRouteMatch };
}
