import { forwardRef, Ref } from 'react';
import { Divider, InputNumber as AntInputNumber } from 'antd';
import type { InputNumberProps } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

const InputNumber = forwardRef(({ step = 1, ...props }: InputNumberProps, ref: Ref<HTMLInputElement>) => {
  return (
    <AntInputNumber
      ref={ref}
      className={styles.root}
      addonAfter={
        <>
          <MinusOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const previousValue: number = Number(props.value) ? Number(props.value) : 0;
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin...
              props.onChange(previousValue - Number(step));
            }}
          />
          <Divider type="vertical" />
          <PlusOutlined
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const previousValue: number = Number(props.value) ? Number(props.value) : 0;
              // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin...
              props.onChange(previousValue + Number(step));
            }}
          />
        </>
      }
      controls={false}
      {...props}
    />
  );
});

export default InputNumber;
