import { SVGProps } from 'react';

export type ForbiddenIconProps = SVGProps<SVGSVGElement>;

export function ForbiddenIcon({ ...props }: ForbiddenIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M8 0.25C3.71978 0.25 0.25 3.71978 0.25 8C0.25 12.2802 3.71978 15.75 8 15.75C12.2802 15.75 15.75 12.2802 15.75 8C15.75 3.71978 12.2802 0.25 8 0.25ZM12.4194 3.58059C14.7062 5.86731 14.8272 9.43375 12.9183 11.8576L4.14237 3.08172C6.567 1.17219 10.1333 1.29444 12.4194 3.58059ZM3.58059 12.4194C1.29384 10.1327 1.17278 6.56625 3.08172 4.14237L11.8576 12.9183C9.433 14.8278 5.86672 14.7056 3.58059 12.4194Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ForbiddenIcon;
