import { useState } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import classNames from 'classnames';

const { RangePicker } = DatePicker;

export interface TimeRangeProps {
  dateFormat?: string;
  intervalStart: moment.Moment;
  intervalEnd: moment.Moment;
  onIntervalChanged: (dates: [moment.Moment, moment.Moment], dateStrings: [string, string]) => void;
}

export const defaultDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const elasticSearchDateFormat = 'YYYY-MM-DDTHH:mm:ss';

export function TimeRange(props: TimeRangeProps) {
  let { dateFormat } = props;
  const [open, setOpen] = useState<boolean>(false);

  if (dateFormat == null) {
    dateFormat = defaultDateFormat;
  }

  const dropdownCn = classNames({
    hidden: !open
  });
  return (
    <>
      <RangePicker
        // eslint-disable-next-line
        onChange={props.onIntervalChanged as any}
        showTime
        style={{ width: '100%' }}
        className="primary-item"
        ranges={{
          // eslint-disable-next-line
          Today: [moment().startOf('day') as any, moment().endOf('day') as any],
          // eslint-disable-next-line
          'Last 7 days': [moment().subtract(7, 'days') as any, moment() as any],
          // eslint-disable-next-line
          'Last Month': [moment().subtract(1, 'month') as any, moment() as any]
        }}
        // eslint-disable-next-line
        defaultValue={[props.intervalStart as any, props.intervalEnd as any]}
        format={dateFormat}
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
        }}
        dropdownClassName={dropdownCn}
      />
    </>
  );
}
