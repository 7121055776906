import { JsonObject, JsonProperty } from 'json2typescript';
import { LOCAL_STORAGE_TOKEN_NAME } from 'src/components/pages/Main/Login/AuthConstants';

@JsonObject('RequestCommon')
class RequestCommon {
  @JsonProperty('user_session_id', String)
  userSessionId?: string | null | undefined;

  constructor() {
    this.userSessionId = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
  }
}

export default RequestCommon;
