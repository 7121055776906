import React from 'react';
import { Gauge } from '@ant-design/charts';
import * as util from 'util';

export interface GaugeChartProps {
  value: number;
}

export function GaugeChart(props: GaugeChartProps): React.ReactElement {
  if (!props.value) {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ReactElemen...
    return null;
  }

  const { value } = props;
  const config = {
    percent: value / 100,
    range: {
      ticks: [0, 1],
      color: ['l(0) 0:#148801 1:#FF2C00'],
      width: 18
    },
    height: 300,
    axis: {
      label: {
        formatter: (text: string) => `${Number(text) * 100}%`
      },
      tickInterval: 0.25,
      tickLine: {
        length: -12
      },
      subTickLine: {
        length: -8
      }
    },
    indicator: {
      pointer: {
        style: {
          stroke: '#fff'
        }
      },
      pin: {
        style: {
          stroke: '#D0D0D0'
        }
      }
    },
    statistic: {
      content: {
        formatter: () => util.format('%s %%', Math.floor(value * 100) / 100),
        style: {
          fontSize: '26px',
          lineHeight: '26px',
          color: '#FFFFFF',
          fontWeight: 'bold',
          opacity: 1
        }
      }
    }
  };

  return (
    <>
      <div style={{ height: '300px' }}>
        <Gauge {...config} />
      </div>
    </>
  );
}
