import { staticRoutes } from './StaticRoutes';
import { HomepageType, UseThemeType } from '../theme.hook';
import { RouteType } from '../../components/shared/models/route/RouteType';

export const useTheme = (): UseThemeType => {
  const getStaticRoutes = (): Array<RouteType> => staticRoutes;
  const getMetaTitle = (): string => 'Infra AI Platform';
  const getMetaDescription = (): string =>
    'Infra AI Platform' + ' | Observability' + ' | Alert triage' + ' | Threat Intelligence' + ' | Orange';
  const getHomepageType = (): HomepageType => 'cards';
  return {
    getStaticRoutes,
    getMetaTitle,
    getMetaDescription,
    getHomepageType
  };
};
