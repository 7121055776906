/* eslint-disable max-len */
function CodeBlockModal() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="36" height="36" rx="18" fill="#CBD5E1" fillOpacity="0.1" />
      <path
        d="M22.167 22.1667L26.3337 18L22.167 13.8333M13.8337 13.8333L9.66699 18L13.8337 22.1667M19.667 10.5L16.3337 25.5"
        stroke="#CBD5E1"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CodeBlockModal;
