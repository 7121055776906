import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import randomColor from 'randomcolor';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { useVisualizationsContext } from './Visualizations.context';
import { getJsonConvert } from '../../utilities/json-convert';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { PiechartMultiple, PierchartMultipleProps } from '../Visualisation/PiechartMultiple';
import { PiechartProps } from '../Visualisation/Piechart';

interface ColorMap {
  // eslint-disable-next-line
  colors: any;
  seedIncrement: number;
}

export function QueryPiechartMultiple(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const [pieData, setPieData] = useState<PierchartMultipleProps>(undefined);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  function getColorForName(colorMap: ColorMap, name: string) {
    if (colorMap.colors[name] == null) {
      colorMap.colors[name] = randomColor({
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        seed: Number(visualizationRecord.style['color-seed']) + colorMap.seedIncrement,
        hue: 'orange'
      });
      colorMap.seedIncrement += 1;
    }
    return colorMap.colors[name];
  }

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    let slices: Array<any> = [];
    for (const key in aggregations) {
      slices = slices.concat(aggregations[key].buckets);
    }

    const categories = Array<string>();
    const charts = Array<PiechartProps>();
    const colorMap: ColorMap = {
      colors: {},
      seedIncrement: 0
    };

    const filterFields = VisualizationRecord.getFilterFieldsFromQuery(visualizationRecord.query, []);

    slices.forEach((slice) => {
      // eslint-disable-next-line
      const data = new Array<any>();
      const colors = new Array<string>();

      categories.push(slice.key);
      let childElement = null;
      for (const key in slice) {
        if (key !== 'key' && key !== 'doc_count') {
          childElement = slice[key];
          break;
        }
      }

      // eslint-disable-next-line
      childElement.buckets.forEach((childSlice: any) => {
        data.push({ name: childSlice.key, value: childSlice.doc_count });
        colors.push(getColorForName(colorMap, childSlice.key));
      });

      charts.push({
        data,
        colors,
        filterFields: filterFields.slice(1)
      });
    });

    setPieData({
      charts,
      categories,
      categoriesFilterField: filterFields[0]
    });

    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <p>&nbsp;</p>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        <PiechartMultiple key={props.name + Math.random()} {...pieData} />
      </Spin>
    </>
  );
}
