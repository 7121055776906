import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';

@JsonObject('CustomFieldNextSequenceNumberResponse')
export class CustomFieldNextSequenceNumberResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('next_name', String)
  next_name: string;
}
