import { UseQueryResult, useQuery } from 'react-query';
import axios from 'axios';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { GeneralStatisticsResponse } from '../../components/shared/models/homepage/GeneralStatisticsResponse';
import { Component } from '../../components/shared/models/homepage/Component';
import { GeneralStatisticsRequestData } from '../../components/shared/models/homepage/GeneralStatistic';
import { GeneralStatisticsComponent } from '../../components/shared/models/homepage/GeneralStatisticsComponent';
import { config } from '../../config';

const queryFn = async (): Promise<GeneralStatisticsRequestData> => {
  const res = await axios.post<{ resource: GeneralStatisticsResponse }>(config.api.componentAvailability, new CommonRequest());

  return res.data.resource.availability.reduce<GeneralStatisticsRequestData>(
    (result: GeneralStatisticsRequestData, item: GeneralStatisticsComponent) =>
      item.component === Component.Integration
        ? { ...result, integration: { active: item.active, total: item.total } }
        : { ...result, job: { active: item.active, total: item.total } },
    {
      job: { active: 0, total: 0 },
      integration: { active: 0, total: 0 }
    }
  );
};

export function useGeneralStatistics() {
  const res = useQuery([config.api.componentAvailability], queryFn);

  return res as UseQueryResult<GeneralStatisticsRequestData | undefined> & { data: undefined | GeneralStatisticsRequestData };
}
