import { useState } from 'react';
import { Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFeedbackStatisticsSplitByUser } from '../../../../../../../data-access';
import { ArcannaPagination, ArcannaTable } from '../../../../../../shared/components';
import styles from './styles.module.css';
import { EmptyFolderPlusIcon } from '../../../../../../../themes/icons';
import { config } from '../../../../../../../config';
import { FeedbackSession } from '../../../../../../shared/models/feedback/FeedbackStatisticsLabelsUsedRequest';
const PAGE_SIZE = 10;
type FeedbackStatisticsSplitByUserProps = {
  jobId: number;
  feedbackSession: FeedbackSession;
};
export function FeedbackStatisticsSplitByUser({ jobId, feedbackSession }: FeedbackStatisticsSplitByUserProps) {
  const { t } = useTranslation(['overview']);
  const [page, setPage] = useState(1);

  // eslint-disable-next-line
  const columns: ColumnsType<any> = [
    {
      title: t('overview:username'),
      dataIndex: 'username',
      render: (username: string) => <span className="color-nb-100 callout">{username}</span>
    },
    {
      title: t('overview:feedbackGiven'),
      dataIndex: 'events_given_feedback',
      render: (events_given_feedback: number) => <span className="color-nb-100 callout">{events_given_feedback}</span>
    },
    {
      title: t('overview:consensus'),
      width: '50%',
      dataIndex: 'consensus',
      render: (consensus: number) => (
        <div className={styles.consensusWrapper}>
          <span className="color-nb-100 callout" style={{ width: 40, textAlign: 'right' }}>{`${consensus.toFixed(0)}%`}</span>
          <div className="w-100" style={{ position: 'relative' }}>
            <span
              className={cx(styles.consensusBar, {
                [styles.redConsensusBar]: consensus >= 0 && consensus < 40,
                [styles.orangeConsensusBar]: consensus >= 40 && consensus < 60,
                [styles.blueConsensusBar]: consensus >= 60 && consensus < 80,
                [styles.greenConsensusBar]: consensus >= 80
              })}
            />
            <span
              className={cx(styles.consensusBarPercentage, {
                [styles.redConsensusBarPercentage]: consensus >= 0 && consensus < 40,
                [styles.orangeConsensusBarPercentage]: consensus >= 40 && consensus < 60,
                [styles.blueConsensusBarPercentage]: consensus >= 60 && consensus < 80,
                [styles.greenConsensusBarPercentage]: consensus >= 80
              })}
              style={{ width: `${consensus.toFixed(0)}%` }}
            />
          </div>
        </div>
      )
    }
  ];

  const feedbackStatisticsByUserQuery = useFeedbackStatisticsSplitByUser({
    jobId,
    retrainSession: feedbackSession,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    startDate: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    endDate: null,
    filters: []
  });

  const handlePaginationChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div className={styles.tableDataWrapper}>
      <ArcannaTable
        loading={feedbackStatisticsByUserQuery.isLoading}
        arcannaSize="middle"
        rowKey="username"
        headBodySpace
        pagination={false}
        bordered={false}
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        hideEmptyBody={feedbackStatisticsByUserQuery.data?.entries.length === 0}
        columns={columns}
        dataSource={feedbackStatisticsByUserQuery.data?.entries}
      />

      {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
      {feedbackStatisticsByUserQuery.data?.entries.length > 0 ? (
        <div className="m-t-8">
          <ArcannaPagination
            custom
            current={page}
            pageSize={PAGE_SIZE}
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            total={feedbackStatisticsByUserQuery.data?.entries.length}
            onChange={handlePaginationChange}
          />
        </div>
      ) : null}

      {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
      {feedbackStatisticsByUserQuery.data?.entries.length === 0 ? (
        <div className={styles.emptyTableRoot}>
          <EmptyFolderPlusIcon />

          <Space direction="vertical" size={4} className="flex flex-col flex-center flex-align-start">
            <span className="color-nb-100 body-1">{t('overview:startByGivingFeedback')}</span>
            <Space size={3} align="center">
              <span className="body-2 color-nb-300">{t('overview:youCanGiveFeedbackFrom')}</span>
              <Link className="body-2 color-nb-0 underlined" to={config.routes.feedbackFlow.replace(':id', `${jobId}`)}>
                {t('overview:feedbackSection')}
              </Link>
            </Space>
          </Space>
        </div>
      ) : null}
    </div>
  );
}
export default FeedbackStatisticsSplitByUser;
