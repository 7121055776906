import { Modal, ModalProps, styled } from '@mui/material';

const StyledModal = styled(Modal)<ModalProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .closeButton': {
    width: '32px',
    height: '32px',
    minWidth: '32px',
    padding: '0',
    marginRight: 24
  },
  '& .container': {
    border: `1px solid ${theme.palette.secondary[700]}`,
    backgroundColor: theme.palette.secondary[900],
    borderRadius: 4
  },
  '& .modalHeader': {
    padding: 24,
    borderBottom: `1px solid ${theme.palette.secondary[700]}`
  },
  '& .modalFooter': {
    borderTop: `1px solid ${theme.palette.secondary[700]}`,
    padding: 24
  },
  '& .modalBody': {
    padding: 24,
    maxHeight: '75vh',
    overflowY: 'auto'
  }
}));

export default StyledModal;
