import styles from './WifiInsights.module.css';

export interface CircleBackgroundProps {
  backgroundColor?: string;
}

export function CircleBackground(props: CircleBackgroundProps) {
  return (
    <>
      <div className={`${styles['circle-wrapper']}`}>
        <div className={`${styles.circle} ${props.backgroundColor}`} />
      </div>
    </>
  );
}
