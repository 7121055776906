/* eslint-disable max-len */
function CheckboxIndeterminateIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="4" fill="#10B981" />
      <path d="M4.5 8H11.5" stroke="#1B2236" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default CheckboxIndeterminateIcon;
