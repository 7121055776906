/* eslint-disable max-len */
function Send() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_14866_3096)">
        <path
          d="M6.99976 9.00012L13.9998 2.00012M7.08482 9.21883L8.83689 13.7242C8.99124 14.1211 9.06841 14.3195 9.17962 14.3774C9.27602 14.4277 9.39085 14.4277 9.48731 14.3776C9.59858 14.3198 9.67599 14.1215 9.8308 13.7247L14.2244 2.46625C14.3641 2.10813 14.434 1.92907 14.3958 1.81465C14.3626 1.71528 14.2846 1.6373 14.1852 1.60411C14.0708 1.56588 13.8918 1.63576 13.5336 1.77552L2.27513 6.16908C1.87842 6.32389 1.68006 6.4013 1.62225 6.51257C1.57214 6.60903 1.57221 6.72386 1.62243 6.82026C1.68037 6.93146 1.87882 7.00864 2.27572 7.16299L6.78105 8.91506C6.86162 8.94639 6.9019 8.96206 6.93582 8.98625C6.96589 9.0077 6.99218 9.03399 7.01362 9.06406C7.03782 9.09798 7.05349 9.13826 7.08482 9.21883Z"
          stroke="currentColor"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_14866_3096">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Send;
