import { config } from '../../../config';
import { brandingConfig } from './branding.constants';
import { TBrandConfig, TBranding } from './branding.types';

type TGetBranding = TBrandConfig & {
  activeBrand: TBranding;
};

function getBranding(): TGetBranding {
  const activeBrand = (config.branding.folder as TBranding) ?? 'siscale';
  const activeBrandConfig = brandingConfig[activeBrand] || {};

  return {
    activeBrand,
    ...activeBrandConfig
  } as TGetBranding;
}

export default getBranding;
