import styles from './RetrainHistoryDrawer.module.css';
import { CloseOutlined } from '@ant-design/icons';
import { Drawer, Spin, Typography } from 'antd';
import useModelsHistoryTable from './useModelsHistoryTable';
import { ArcannaTable } from '../../../../../shared/components';
import { useTranslation } from 'react-i18next';

type RetrainHistoryDrawerProps = {
  jobId: number;
  open: boolean;
  onClose: () => void;
};
export default function RetrainHistoryDrawer({ jobId, open, onClose }: RetrainHistoryDrawerProps) {
  const { columns, tableData, isRollbackInProgress } = useModelsHistoryTable({ jobId });
  const { t } = useTranslation(['overview']);

  return (
    <Drawer
      maskClosable={false}
      title={<Typography.Text className={styles.headerTxt}>{t('aiModelDetail')}</Typography.Text>}
      placement="right"
      width="1088px"
      open={open}
      headerStyle={{ borderBottom: '1px solid var(--secondary-color-nb-800)', height: 64, padding: '16px 76px 16px 40px' }}
      footerStyle={{ border: 'none', paddingLeft: 0 }}
      bodyStyle={{ overflowX: 'hidden', overflowY: 'auto', padding: '24px 76px 0 40px' }}
      drawerStyle={{ overflow: 'hidden', paddingLeft: 0, paddingRight: 0, backgroundColor: 'var(--secondary-color-nb-1000)' }}
      destroyOnClose
      onClose={onClose}
      closeIcon={<CloseOutlined className={styles.closeIcon} size={16} />}
    >
      <Spin spinning={isRollbackInProgress} tip={t('rollbackIsInProgress')}>
        <ArcannaTable
          className={styles.table}
          pagination={false}
          headBodySpace
          bordered={false}
          dataSource={tableData}
          columns={columns}
          rowKey="modelId"
        />
      </Spin>
      <div className="m-t-16">
        <Typography.Text className={styles.entriesNumbers}>1-{tableData?.length} </Typography.Text>
        <Typography.Text className={styles.entriesText}>{t('outOf')} </Typography.Text>
        <Typography.Text className={styles.entriesNumbers}>{tableData?.length} </Typography.Text>
        <Typography.Text className={styles.entriesText}>{t('entries')}</Typography.Text>
      </div>
    </Drawer>
  );
}
