import { InlineNotification } from '@arcanna/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useJobNavigate } from '../../../../../../shared/hooks/useJobNavigate';
import useBucketsUpdateInProgress from '../hooks/useBucketsUpdateInProgress';
import { useFeedbackBucketsCountTotal } from '../../../../../../../data-access';
import useIsRetrainAvailable from '../../../../../../../data-access/job/useIsRetrainAvailable';
import { config } from '../../../../../../../config';
import { useQueryClient } from 'react-query';
import { showInfoNotification } from '../../../../../../shared/utilities/notification';
import { useJobNotificationsContext } from '../JobNotifications.context';

type TBucketsUpdateProps = {
  jobId: number;
  reload?: () => void;
  fromPage?: string;
};

export function BucketsUpdateNotification({
  jobId,
  reload = () => {
    return;
  },
  fromPage
}: TBucketsUpdateProps) {
  const { t } = useTranslation(['feedback', 'retrain']);
  const queryClient = useQueryClient();

  const [showAllBucketsUpdated, setShowAllBucketsUpdated] = useState<boolean>(false);
  const bucketsUpdateInProgressStatus = useBucketsUpdateInProgress(jobId);
  const totalNumberOfBucketsQuery = useFeedbackBucketsCountTotal(jobId);
  const [hasUpdateStarted, setHasUpdateStarted] = useState<boolean>(false);
  const isRetrainAvailableQuery = useIsRetrainAvailable(jobId);
  const retrainEnabled = isRetrainAvailableQuery.data ? isRetrainAvailableQuery.data.enabled : false;
  const { setBucketsModelUpdateStarted } = useJobNotificationsContext();

  const { navigateToJobFeedbackFlow, navigateToJobOverview } = useJobNavigate();

  function hideBucketsUpdateNotification() {
    setHasUpdateStarted(false);
    navigateToJobOverview(`${jobId}`);
  }

  useEffect(() => {
    if (bucketsUpdateInProgressStatus?.buckets_update_in_progress && !hasUpdateStarted) {
      setHasUpdateStarted(true);
    }
    // eslint-disable-next-line
  }, [bucketsUpdateInProgressStatus]);

  useEffect(() => {
    if (bucketsUpdateInProgressStatus?.buckets_update_percent === 100 && hasUpdateStarted && !showAllBucketsUpdated) {
      setShowAllBucketsUpdated(true);
      setBucketsModelUpdateStarted(true);
      showInfoNotification(t('job:bucketUpdater.started'), t('job:bucketUpdater.modelUpdateDescription'));
      isRetrainAvailableQuery.refetch({});
      totalNumberOfBucketsQuery.refetch({});
      queryClient.invalidateQueries([config.api.feedbackFiltersFields + jobId, config.api.unified.filterFields]);
      reload();
    } else if (hasUpdateStarted && fromPage === t('job:navigation.feedback')) {
      queryClient.resetQueries([config.api.feedbackBucketsBatch]);
    } else {
      setShowAllBucketsUpdated(false);
    }
    // eslint-disable-next-line
  }, [bucketsUpdateInProgressStatus?.buckets_update_percent]);

  const feedbackActionButtonProps = {
    text: t('feedback:goToFeedbackPageLabel'),
    onClick: () => navigateToJobFeedbackFlow(`${jobId}`),
    dataTestId: 'go-to-feedback-btn'
  };

  if (hasUpdateStarted) {
    return (
      <>
        {!showAllBucketsUpdated && (
          <InlineNotification
            type="loading"
            title={t('job:notifications:creatingBuckets')}
            subtitle={t('job:notifications:stayTuned')}
            actionButton={fromPage !== t('job:navigation.feedback') ? feedbackActionButtonProps : undefined}
            percentage={bucketsUpdateInProgressStatus?.buckets_update_percent}
          />
        )}
        {showAllBucketsUpdated && retrainEnabled && (
          <InlineNotification
            type="success"
            title={t('job:notifications:decisionPointsUpdated')}
            subtitle={t('job:notifications:bucketsAvailableToTrain', { numberOfBuckets: totalNumberOfBucketsQuery.data })}
            isClosable
            actionButton={
              fromPage !== t('job:navigation.overview')
                ? {
                    text: t('retrain:trainNow'),
                    onClick: hideBucketsUpdateNotification,
                    dataTestId: 'train-now-btn'
                  }
                : undefined
            }
          />
        )}
        {showAllBucketsUpdated && !retrainEnabled && (
          <InlineNotification
            type="success"
            title={t('job:notifications:decisionPointsUpdated')}
            actionButton={fromPage !== t('job:navigation.feedback') ? feedbackActionButtonProps : undefined}
            isClosable
          />
        )}
      </>
    );
  } else if (fromPage === t('job:navigation.decisionPoints') && !bucketsUpdateInProgressStatus?.has_features_selected) {
    return <InlineNotification type="info" title={t('job:notifications:onceYouSelectDecisionPoints')} />;
  }
  return null;
}
