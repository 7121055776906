import { JsonObject, JsonProperty } from 'json2typescript';
import { RequestCommon } from '@arcanna/models/utils';

@JsonObject('CodeBlockGetRequest')
export class CodeBlockGetRequest extends RequestCommon {
  @JsonProperty('custom_code_id', String)
  codeBlockId: string;

  @JsonProperty('job_id', Number)
  jobId: number;
}

type TConstructorParams = {
  codeBlockId: CodeBlockGetRequest['codeBlockId'];
  jobId: CodeBlockGetRequest['jobId'];
};

export function constructCodeBlockGetRequest({ codeBlockId, jobId }: TConstructorParams) {
  const instance = new CodeBlockGetRequest();
  instance.codeBlockId = codeBlockId;
  instance.jobId = jobId;
  return instance;
}
