import Sider from 'antd/es/layout/Sider';
import { useMemo, useState } from 'react';
import style from '../../../pages/Main/Jobs/Navigation/JobNavigation.module.css';
import { Menu } from 'antd';
import UnifiedFeedbackNavItem from './components/UnifiedFeedbackNavItem';
import { useParams } from 'react-router-dom';
import { EUNIFIED_JOBS_NAVIGATION_KEYS } from './UnifiedJobsNavigation.types';
import { withNavigationItem } from 'src/components/pages/Main/Jobs/Navigation/NavigationItem.hoc';
import { FeedbackIcon } from '../../icons/FeedbackIcon';
import { config } from 'src/config';
import { useActiveNavigationItem } from './useActiveNavigationItem.hook';
import { DecisionPointIcon } from '../../icons/DecisionPointIcon';
import UnifiedDecisionPointsNavItem from './components/UnifiedDecisionPointsNavItem';

const iconItemStyle = {
  overflow: 'visible',
  height: 'auto',
  lineHeight: 'normal',
  marginTop: 24,
  padding: 0
};

function UnifiedJobsNavigation() {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { ids } = useParams<{ ids: string }>();
  const activeKey = useActiveNavigationItem();

  const FeedbackItem = withNavigationItem(FeedbackIcon);
  const DecisionPointsItem = withNavigationItem(DecisionPointIcon);

  const iconItems = useMemo(
    () => [
      {
        icon: (
          <FeedbackItem
            to={config.routes.feedbackUnified.replace(':ids', ids)}
            active={activeKey === EUNIFIED_JOBS_NAVIGATION_KEYS.FEEDBACK}
            activeColor={'#F0F6FC'}
            dataTestId={'menu-item-feedback'}
          />
        ),
        key: EUNIFIED_JOBS_NAVIGATION_KEYS.FEEDBACK,
        style: iconItemStyle
      },
      {
        icon: (
          <DecisionPointsItem
            to={config.routes.decisionPointsUnified.replace(':ids', ids)}
            active={activeKey === EUNIFIED_JOBS_NAVIGATION_KEYS.DECISION_POINTS}
            activeColor={'#F0F6FC'}
            dataTestId={'menu-item-decision-points'}
          />
        ),
        key: EUNIFIED_JOBS_NAVIGATION_KEYS.DECISION_POINTS,
        style: iconItemStyle
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ids, activeKey]
  );

  return (
    <Sider
      width={52}
      className={style.sider}
      onMouseEnter={() => {
        setIsExpanded(true);
      }}
      onMouseLeave={() => {
        setIsExpanded(false);
      }}
    >
      <Menu
        style={{ borderRight: '0px', overflow: 'display' }}
        theme="dark"
        // @ts-expect-error TS(2769): No overload matches this call.
        selectedKeys={[activeKey]}
        defaultSelectedKeys={['job-overview']}
        mode="vertical"
        items={iconItems}
      />
      {isExpanded ? (
        <div className={style.expandSider}>
          <UnifiedFeedbackNavItem jobIds={ids} />
          <UnifiedDecisionPointsNavItem jobIds={ids} />
        </div>
      ) : null}
    </Sider>
  );
}

export default UnifiedJobsNavigation;
