import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('EventExplorerEventInfoRequest')
export class EventExplorerEventInfoRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number = undefined;

  @JsonProperty('include_internal', Boolean)
  include_internal?: boolean = false;

  constructor(job_id: number, include_internal: boolean) {
    super();
    this.job_id = job_id;
    this.include_internal = include_internal;
  }
}
