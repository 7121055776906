import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackQuickFilters')
export class FeedbackQuickFilters {
  @JsonProperty('requiring_attention', [String])
  requiring_attention?: string[];

  @JsonProperty('feedback', String)
  feedback?: string;

  @JsonProperty('training_status', String)
  training_status?: string;

  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
  constructor(requiring_attention: string[] = null, feedback: string = null, training_status: string = null) {
    this.requiring_attention = requiring_attention;
    this.feedback = feedback;
    this.training_status = training_status;
  }
}
