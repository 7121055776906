import { InlineNotification } from '@arcanna/components';
import { Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

type TModalFooterSuccessProps = {
  numberOfEvents?: number;
  successMessage: string;
  successDescription?: string;
  confirmButtonText?: string;
  continueButtonText?: string;
  onConfirm?: () => void;
  onContinue?: () => void;
  onClose: () => void;
};

function ModalStepsFooterSuccess(props: TModalFooterSuccessProps) {
  const { t } = useTranslation(['common']);

  return (
    <div>
      <InlineNotification
        type="success"
        title={props.successMessage}
        subtitle={props.successDescription}
        textPosition="vertical"
      />
      <Stack direction="row" justifyContent="space-between" alignItems="center" mt="24px">
        <Button size="small" key="close" onClick={props.onClose} variant="tertiary" color="secondary">
          {t('common:cancel')}
        </Button>
        {props.confirmButtonText && (
          <Stack direction="row" gap={1} justifyContent="flex-end" alignItems="center">
            <Button
              size="small"
              key="confirm"
              data-test-id="save-integration-health-check"
              onClick={props.onConfirm}
              color="secondary"
              variant="contained"
            >
              <Typography>{props.confirmButtonText}</Typography>
            </Button>
            {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
            {props.continueButtonText && props.numberOfEvents > 0 && (
              <div>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  key="show-results"
                  data-test-id="show-events"
                  onClick={props.onContinue}
                >
                  {props.continueButtonText}
                </Button>
              </div>
            )}
          </Stack>
        )}
      </Stack>
    </div>
  );
}

export default ModalStepsFooterSuccess;
