import { useState, useMemo } from 'react';
import { Row, Col, Select } from 'antd';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import styles from './Visualisation.module.css';
import { FiltersProps, FilterProperty } from './FilterProps';

const { Option } = Select;

export function Filters(props: FiltersProps) {
  const visualizationContext = useVisualizationsContext();

  const [colSpan, setColSpan] = useState<number>(6);

  useMemo(() => {
    if (props.filters != null && props.filters.length === 1) {
      setColSpan(24);
    }
  }, [props, setColSpan]);

  function onFilterChange(key: string) {
    return function onFilterChangeCurry(value: string) {
      // Check if filters have previously been applied
      const appliedFilters =
        visualizationContext.state.filtersProps.filters !== undefined &&
        visualizationContext.state.filtersProps.filters.length > 0
          ? visualizationContext.state.filtersProps
          : props;
      // Udate selected value in props.filters
      // @ts-expect-error TS(2322): Type 'FilterProperty | undefined' is not assignabl...
      const filterProperty: FilterProperty = appliedFilters.filters.find((entry: FilterProperty) => entry.key === key);
      filterProperty.selected = value;

      // Set in visualizationContext
      visualizationContext.setFiltersProps(appliedFilters);
    };
  }

  if (props != null && props.filters != null) {
    return (
      <>
        <div />
        <Row>
          {props.filters.map((filter: FilterProperty) => (
            <Col span={colSpan} key={filter.key}>
              <Row className={styles.filter}>
                <Col className={styles.filterLabel} span="6">
                  {filter.key}
                  &nbsp;&nbsp;
                </Col>
                <Col span="18">
                  <Select onChange={onFilterChange(filter.key)} className={styles.filterSelect}>
                    {filter.values.map((value) => (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </>
    );
  }

  return <></>;
}
