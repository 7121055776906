import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { config } from '../../../../../../../config';
import { useJobInfo } from '../../../../../../../data-access';
import { JobStatusRecord } from '../../../../../../shared/models/job/JobStatusRecord';

const REFETCH_INTERVAL_MILLIS = 5000;

export function isBucketsUpdateInProgress(status: JobStatusRecord | undefined): boolean {
  return status === undefined || status.buckets_update_in_progress === undefined || status.buckets_update_in_progress;
}

export default function useBucketsUpdateInProgress(jobId: number) {
  const [shouldRefetch, setShouldRefetch] = useState<false | number>(false);
  const jobInfoQuery = useJobInfo(jobId, shouldRefetch);
  const queryClient = useQueryClient();

  const bucketsUpdateInProgress = isBucketsUpdateInProgress(jobInfoQuery.data?.info?.status);

  useEffect(() => {
    if (bucketsUpdateInProgress) {
      setShouldRefetch(REFETCH_INTERVAL_MILLIS);
    } else if (shouldRefetch) {
      setShouldRefetch(false);
      queryClient.invalidateQueries([config.api.feedbackBucketsBatch]);
      queryClient.invalidateQueries([config.api.feedbackFiltersFields + jobId]);
    }
    // eslint-disable-next-line
  }, [bucketsUpdateInProgress, jobInfoQuery.data?.info?.status, queryClient]);

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  return jobInfoQuery.data?.info.status;
}
