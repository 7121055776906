import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiPaginationStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiPaginationItem: {
    styleOverrides: {
      outlined: {
        minWidth: '36px',
        height: '36px',
        color: palette.secondary[300],
        borderColor: palette.secondary[700],

        '&.Mui-selected': {
          backgroundColor: 'initial',
          color: palette.secondary[100],
          borderColor: palette.secondary[400]
        }
      }
    }
  }
});
