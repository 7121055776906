import { IconButton, InputAdornment, Menu, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Icon, EIcon, Checkbox } from '@arcanna/generic';
import { InlineNotification } from '@arcanna/components';
import { MouseEvent, useMemo, useState } from 'react';
import { Table } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

type TTableColumnsSelectorProps<T> = {
  tableInstance: Table<T>;
};

function TableColumnsSelector<T>({ tableInstance }: TTableColumnsSelectorProps<T>) {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const theme = useTheme();
  const [searchPhrase, setSearchPhrase] = useState<string>('');

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const allTableColumns = tableInstance.getAllColumns();

  const filteredColumns = useMemo(() => {
    return allTableColumns.filter((column) => {
      if (typeof column.columnDef.header !== 'string') {
        return false;
      }

      return column.columnDef.header.toString().toLowerCase().includes(searchPhrase.toLowerCase());
    });
  }, [searchPhrase, allTableColumns]);

  const allFilteredColumnsThatCanHide = filteredColumns.filter((column) => column.getCanHide());
  const allFilteredColumnsThatCanHideAndAreVisible = allFilteredColumnsThatCanHide.filter((column) => column.getIsVisible());

  return (
    <>
      <Tooltip arrow placement="top" title={t('common:table.hideShowColumnsTooltip')}>
        <IconButton
          onClick={handleClick}
          sx={{
            height: '16px',
            width: '16px',
            color: theme.palette.secondary[200]
          }}
        >
          <Icon fontSize="small" name={EIcon.Columns} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Stack
          direction="column"
          width="360px"
          maxWidth="100%"
          padding="14px 12px"
          border={`1px solid ${theme.palette.secondary[600]}`}
          bgcolor={theme.palette.secondary[900]}
          borderRadius="4px"
          gap="16px"
        >
          <Typography variant="subtitle1">
            {t('common:table.columnsWithPlaceholder', { columnsLength: filteredColumns.length })}
          </Typography>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name={EIcon.ActionSearch} htmlColor={theme.palette.secondary[300]} />
                </InputAdornment>
              ),
              sx: { height: '32px' }
            }}
            placeholder="Search"
            sx={{ width: '100%' }}
            value={searchPhrase}
            onChange={(event) => setSearchPhrase(event.target.value)}
          />
          <Stack
            direction="column"
            gap="16px"
            maxHeight="210px"
            overflow="auto"
            borderBottom={filteredColumns.length ? `1px solid ${theme.palette.secondary[700]}` : 'none'}
            borderTop={`1px solid ${theme.palette.secondary[700]}`}
            paddingY={2}
          >
            {filteredColumns.map(
              (column) =>
                typeof column.columnDef.header === 'string' && (
                  <Checkbox
                    state={column.getIsVisible() ? 'checked' : 'default'}
                    disabled={!column.getCanHide()}
                    key={column.id}
                    label={column.columnDef.header}
                    onChange={(state) => {
                      column.toggleVisibility(state === 'checked');
                    }}
                  />
                )
            )}
            {!filteredColumns.length && <InlineNotification type="info" title={t('common:noResults')} />}
          </Stack>
          {filteredColumns.length ? (
            <Checkbox
              state={
                allFilteredColumnsThatCanHideAndAreVisible.length === allFilteredColumnsThatCanHide.length ? 'checked' : 'default'
              }
              disabled={!allFilteredColumnsThatCanHide.length}
              label={t('common:table.hideShowAll')}
              onChange={(state) => {
                allFilteredColumnsThatCanHide.forEach((column) => {
                  column.toggleVisibility(state === 'checked');
                });
              }}
            />
          ) : (
            <></>
          )}
        </Stack>
      </Menu>
    </>
  );
}

export default TableColumnsSelector;
