import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FeedbackBatchBucketColumn')
export class FeedbackBatchBucketColumn {
  @JsonProperty('name', String)
  name?: string;

  @JsonProperty('value', String)
  value?: string;

  @JsonProperty('is_feature', Boolean)
  is_feature: boolean;
}
