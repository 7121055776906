import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobAlertsTimeseriesRecordLabelSplit')
export class JobAlertsTimeseriesRecordLabelSplit {
  @JsonProperty('name', String)
  name?: string = undefined;

  @JsonProperty('count', Number)
  count?: number = undefined;
}
