/* eslint-disable max-len */
function FaceSmile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_11580_71147)">
        <path
          d="M5.33333 9.33337C5.33333 9.33337 6.33333 10.6667 8 10.6667C9.66667 10.6667 10.6667 9.33337 10.6667 9.33337M10 6.00004H10.0067M6 6.00004H6.00667M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8.00004C1.33333 4.31814 4.3181 1.33337 8 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004ZM10.3333 6.00004C10.3333 6.18414 10.1841 6.33337 10 6.33337C9.8159 6.33337 9.66667 6.18414 9.66667 6.00004C9.66667 5.81595 9.8159 5.66671 10 5.66671C10.1841 5.66671 10.3333 5.81595 10.3333 6.00004ZM6.33333 6.00004C6.33333 6.18414 6.18409 6.33337 6 6.33337C5.8159 6.33337 5.66667 6.18414 5.66667 6.00004C5.66667 5.81595 5.8159 5.66671 6 5.66671C6.18409 5.66671 6.33333 5.81595 6.33333 6.00004Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11580_71147">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FaceSmile;
