import axios from 'axios';
import { config } from '../../../config';

export function useRotateSecondaryUrls() {
  const rotateBackendUrl = async () => {
    let selectedBackendUrl = null;
    let selectedIndex = -1;
    for (let i = 0; i < config.backendSecondaryUrlsArray.length; i++) {
      const backendUrl = config.backendSecondaryUrlsArray[i];
      const axiosInstance = axios.create({});
      try {
        const { data } = await axiosInstance.get(backendUrl);
        if (data != null) {
          selectedBackendUrl = backendUrl;
          selectedIndex = i;
          break;
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error('Cannot access ', backendUrl);
      }
    }
    // If a new workable backendUrl was found then make it active
    if (selectedBackendUrl != null) {
      config.backendSecondaryUrlsArray.push(config.backendUrl);
      config.backendUrl = selectedBackendUrl;
      config.backendSecondaryUrlsArray.splice(selectedIndex, 1);
      localStorage.setItem('backendUrl', config.backendUrl);
    }
    return selectedBackendUrl;
  };

  return {
    rotateBackendUrl
  };
}
