/* eslint-disable max-len */
function Integration() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_5922_1090)">
        <path
          d="M5.36426 8.90039L11.0211 14.5572L9.6069 15.9715C8.0448 17.5336 5.51214 17.5336 3.95004 15.9715V15.9715C2.38795 14.4094 2.38795 11.8767 3.95004 10.3146L5.36426 8.90039Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
        <path
          d="M8.89978 5.36426L14.5566 11.0211L15.9708 9.6069C17.5329 8.0448 17.5329 5.51214 15.9708 3.95004V3.95004C14.4088 2.38795 11.8761 2.38795 10.314 3.95004L8.89978 5.36426Z"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinejoin="round"
        />
        <line x1="2.53577" y1="17.8099" x2="3.66714" y2="16.6785" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" />
        <path
          d="M17.8091 2.53613L16.6777 3.6675M11.2684 11.5517L10.9855 11.8346M8.43995 8.72332L8.1571 9.00616"
          stroke="currentColor"
          strokeWidth="1.4"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5922_1090">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Integration;
