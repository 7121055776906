import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { config } from '../../../config';

export class ApiService {
  private axiosApiInstance: AxiosInstance;

  constructor() {
    this.axiosApiInstance = axios.create({
      baseURL: config.backendUrl
      // eslint-disable-next-line
    } as any);

    this.setRequestHeaders();
  }

  setRequestHeaders() {
    // eslint-disable-next-line
    (axios.defaults as any).baseURL = config.backendUrl;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = config.backendUrl;
    axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
    axios.defaults.headers.common.xsrfCookieName = 'xsrfCookieName';
    axios.defaults.headers.common['X-Rest-Api-Token'] = config.restApiToken;
  }

  // eslint-disable-next-line
  loadRequestInterceptor(successCallback: any, errorCallback: any) {
    this.axiosApiInstance.interceptors.request.use(
      // eslint-disable-next-line
      (request: any) => {
        successCallback(request);
        return request;
      },
      // eslint-disable-next-line
      (err: any) => {
        errorCallback(err);
        return Promise.reject(err);
      }
    );
  }

  // eslint-disable-next-line
  loadResponseInterceptor(successCallback: any, errorCallback: any) {
    this.axiosApiInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        successCallback(response);
        return response;
      },
      (err) => errorCallback(err)
    );
  }

  getInstance() {
    return this.axiosApiInstance;
  }
}
