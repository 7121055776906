/* eslint-disable max-len */
function ActivityHeart() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3333 7.66667H9.66668L8.66668 9.66667L7.33334 5.66667L6.33334 7.66667H5.66668M7.99544 3.42388C6.66254 1.8656 4.43984 1.44643 2.76981 2.87334C1.09977 4.30026 0.864655 6.68598 2.17614 8.3736C3.16674 9.64829 5.98087 12.2073 7.29868 13.3833C7.54093 13.5994 7.66206 13.7075 7.8039 13.7501C7.92703 13.787 8.06386 13.787 8.18699 13.7501C8.32883 13.7075 8.44996 13.5994 8.69221 13.3833C10.01 12.2073 12.8242 9.64829 13.8147 8.3736C15.1262 6.68598 14.9198 4.28525 13.2211 2.87334C11.5223 1.46144 9.32835 1.8656 7.99544 3.42388Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ActivityHeart;
