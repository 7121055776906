import { IntegrationCreateParameter } from '../../../../../../../components/shared/models/integration/IntegrationCreateParameter';
import {
  getMappings,
  getParameterKey,
  useParameterAllowedValues,
  useParameterTypes
} from '../../../../../../../components/pages/IntegrationCreate/hooks/useParameterInfo';
import { showErrorNotification } from '../../../../../../../components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { IntegrationCreateRequest } from '../../../../../../../components/shared/models/integration/IntegrationCreateRequest';

export default function useCreatePayload({ subcategoryId }: { subcategoryId: number }) {
  const parameterTypes = useParameterTypes({ subcategoryId });
  const parameterAllowedValues = useParameterAllowedValues({ subcategoryId });
  const { t } = useTranslation(['integration', 'common']);

  const getCreatePayload = useCallback(
    (values: unknown) => {
      const parameters: IntegrationCreateParameter[] = [];
      try {
        // @ts-expect-error TS(2769): No overload matches this call.
        Object.keys(values)
          .filter((value) => value !== 'title')
          .forEach((fieldName: string) => {
            const fieldType = parameterTypes[fieldName];
            // eslint-disable-next-line
            const allValues = values as any;
            // eslint-disable-next-line
            const currentValue: string = allValues[fieldName].toString();
            // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st...
            const parameterKey: string = getParameterKey({
              allowedValues: parameterAllowedValues,
              fieldName: fieldName,
              value: currentValue
            });
            const mappings = getMappings(fieldName, fieldType, allValues, function onErorr() {
              showErrorNotification(t('common:error'), 'Cannot have unfilled mappings');
            });
            parameters.push({
              field: fieldName,
              value: currentValue,
              type: parameterTypes[fieldName],
              key: parameterKey,
              mappings: mappings
            });
          });
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error:', error);
      }

      // eslint-disable-next-line
      return new IntegrationCreateRequest(subcategoryId, (values as any).title, parameters);
    },
    [subcategoryId, parameterTypes, parameterAllowedValues, t]
  );

  return {
    getCreatePayload
  };
}
