import { TPaletteOverrides, TComponentsOverrides } from '../../branding.types';

export const getMuiDatePickerStyles = (palette: TPaletteOverrides): TComponentsOverrides => ({
  MuiPickersPopper: {
    styleOverrides: {
      paper: {
        border: `1px solid ${palette.info[600]}`
      }
    }
  },
  MuiMultiSectionDigitalClock: {
    styleOverrides: {
      root: {
        maxHeight: '308px'
      }
    }
  },
  MuiDateCalendar: {
    styleOverrides: {
      root: {
        maxHeight: '308px'
      }
    }
  },
  MuiMultiSectionDigitalClockSection: {
    styleOverrides: {
      root: {
        border: `1px solid ${palette.secondary[600]}`,
        borderRadius: 0,
        borderBottom: 0
      },
      item: {
        margin: '0 7px',
        width: '40px',
        height: '28px',
        '&:first-of-type': {
          marginTop: 0
        }
      }
    }
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      root: {
        margin: 0,
        height: '42px',
        minHeight: '42px',
        color: palette.secondary[300],
        borderBottom: `1px solid ${palette.secondary[600]}`
      },
      switchViewButton: {
        color: palette.secondary[300]
      }
    }
  },
  MuiPickersArrowSwitcher: {
    styleOverrides: {
      button: {
        color: palette.secondary[300]
      }
    }
  },
  MuiDayCalendar: {
    styleOverrides: {
      weekDayLabel: {
        color: palette.secondary[100]
      },
      root: { color: palette.secondary[300] }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        color: palette.secondary[300]
      },
      today: {
        '&:not(.MuiSelected)': {
          borderColor: palette.info[600]
        }
      }
    }
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        color: palette.secondary[300]
      },
      yearButton: {
        height: '32px'
      }
    }
  },
  MuiPickersMonth: {
    styleOverrides: {
      root: {
        color: palette.secondary[300]
      },
      monthButton: {
        height: '32px'
      }
    }
  },
  MuiPickersLayout: {
    styleOverrides: {
      contentWrapper: {
        borderBottom: `1px solid ${palette.secondary[600]}`
      },
      root: {
        '& .MuiPickersLayout-actionBar': {
          gap: '16px',
          padding: '4px 24px 4px 16px',
          justifyContent: 'space-between',

          '> *:first-of-type': {
            justifySelf: 'flex-start'
          }
        },

        '& .MuiButtonBase-root.MuiButton-root': {
          color: palette.secondary[300]
        }
      }
    }
  }
});
