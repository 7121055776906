export const jobKeys = {
  all: ['job'] as const,

  updateSetings: (jobId: number) => [...jobKeys.all, 'updateSetings', jobId] as const,
  bulkStart: () => [...jobKeys.all, 'bulkStart'] as const,
  bulkStop: () => [...jobKeys.all, 'bulkStop'] as const,
  bulkDelete: () => [...jobKeys.all, 'bulkDelete'] as const,

  infos: (jobIds: number[]) => [...jobKeys.all, 'info', jobIds.join(',')] as const
};
