import { useMemo } from 'react';
import { Space, Typography } from 'antd';
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { useJobInfo, useJobMetrics } from '../../../../../../../../data-access';
import { RocCurveEntry } from '../../../../../../../shared/models/retrain-history/JobMetricsResponse';
import { CustomLabel } from '@arcanna/components';
import { EmptyFolderIcon } from '../../../../../../../../themes/icons';
import { Stack, Tooltip, useTheme } from '@mui/material';
import { EIcon, Icon } from '@arcanna/generic';

type DynamicTprKeys = {
  [key in `tpr_${string}`]: number;
};

type LineChartEntry = {
  fpr: number;
} & DynamicTprKeys;

const xAxis = [
  { fpr: 0.0, tpr: 0.0 },
  { fpr: 0.1, tpr: 0.1 },
  { fpr: 0.2, tpr: 0.2 },
  { fpr: 0.3, tpr: 0.3 },
  { fpr: 0.4, tpr: 0.4 },
  { fpr: 0.5, tpr: 0.5 },
  { fpr: 0.6, tpr: 0.6 },
  { fpr: 0.7, tpr: 0.7 },
  { fpr: 0.8, tpr: 0.8 },
  { fpr: 0.9, tpr: 0.9 },
  { fpr: 1.0, tpr: 1.0 }
];

type RocCurveProps = {
  jobId: number;
  modelId: string;
};
export default function RocCurve({ jobId, modelId }: RocCurveProps) {
  const { t } = useTranslation(['overview']);
  const theme = useTheme();
  const jobInfoQuery = useJobInfo(jobId);
  const jobMetricsQuery = useJobMetrics(jobId, modelId);

  const labelsWithRates: RocCurveEntry[] = useMemo(
    function filterOutLabelsWithoutRates() {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (jobMetricsQuery.data?.feedbackSessionMetrics.rocCurve.entries) {
        return jobMetricsQuery.data?.feedbackSessionMetrics.rocCurve.entries.filter((entry) => {
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          const hasTpr = entry.tpr.find((v) => v !== 0);
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          const hasFpr = entry.fpr.find((v) => v !== 0);

          return hasTpr || hasFpr;
        });
      }

      return [];
    },
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    [jobMetricsQuery.data?.feedbackSessionMetrics.rocCurve.entries]
  );

  const data: LineChartEntry[] = useMemo(
    function createChartDataStructure() {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      if (jobMetricsQuery.data?.feedbackSessionMetrics.rocCurve.entries) {
        return jobMetricsQuery.data.feedbackSessionMetrics.rocCurve.entries.reduce(
          (result: LineChartEntry[], entry: RocCurveEntry) => {
            return result.map((lineChartEntry, index) => ({
              ...lineChartEntry,
              // @ts-expect-error TS(2532): Object is possibly 'undefined'.
              [`tpr_${entry.label}`]: entry.tpr[index]
            }));
          },
          xAxis
        );
      }

      return [];
    },
    [jobMetricsQuery.data]
  );

  const findCustomLabel = (label: string) => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    return jobInfoQuery.data?.info.advancedSettings.customLabels.find((customLabel) => customLabel.id === label);
  };

  return (
    <>
      <Typography className="color-nb-100 callout m-t-24 m-b-16">
        <Stack direction="row" spacing={1} alignItems="center">
          <span>{t('overview:rocCurvePlot')}</span>
          <Tooltip title={t('overview:rocCurvePlotInfo')} placement="top" arrow>
            <Stack color={theme.palette.secondary[200]}>
              <Icon fontSize="small" name={EIcon.InfoCircle} />
            </Stack>
          </Tooltip>
        </Stack>
      </Typography>
      <div className={styles.root}>
        {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
        {jobMetricsQuery.data?.feedbackSessionMetrics.rocCurve.entries ? (
          <div className={styles.graph}>
            <Space size={8} wrap>
              {labelsWithRates.map((entry) => {
                // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
                const customLabel = findCustomLabel(entry.label);
                return (
                  <CustomLabel
                    key={entry.label}
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    hexColor={customLabel.hexColor}
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    name={customLabel.name}
                    textStyle={{ color: 'var(--secondary-color-nb-100)' }}
                  />
                );
              })}
            </Space>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={300}
                margin={{
                  top: 24,
                  right: 20,
                  left: 10,
                  bottom: 40
                }}
                data={data}
              >
                <CartesianGrid type="monotone" stroke="var(--secondary-color-nb-700)" />
                <XAxis
                  dataKey="fpr"
                  type="number"
                  tickCount={11}
                  axisLine={{ stroke: 'var(--secondary-color-nb-400)' }}
                  tick={{ fill: 'var(--secondary-color-nb-400)' }}
                >
                  <Label
                    value="False positive rate"
                    offset={-20}
                    position="insideBottom"
                    style={{ fill: 'var(--secondary-color-nb-400)' }}
                  />
                </XAxis>
                <YAxis
                  dataKey="tpr"
                  type="number"
                  tickCount={11}
                  axisLine={{ stroke: 'var(--secondary-color-nb-400)' }}
                  tick={{ fill: 'var(--secondary-color-nb-400)' }}
                >
                  <Label
                    value="True positive rate"
                    angle={-90}
                    position="insideLeft"
                    style={{ fill: 'var(--secondary-color-nb-400)', textAnchor: 'middle' }}
                  />
                </YAxis>
                {labelsWithRates.map((entry) => {
                  return (
                    <Line
                      dot={false}
                      key={entry.label}
                      type="monotone"
                      dataKey={`tpr_${entry.label}`}
                      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
                      stroke={findCustomLabel(entry.label)?.hexColor}
                    />
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className={styles.emptyState}>
            <EmptyFolderIcon />

            <Space direction="vertical" size={4} className="flex flex-col flex-center flex-align-start">
              <span className="color-nb-100 body-1">{t('overview:nothing')}</span>
            </Space>
          </div>
        )}
      </div>
    </>
  );
}
