import * as React from 'react';
import { useQuery } from 'react-query';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';
import { CAN_NOT_START_ANOTHER_RETRAIN, EJobTrainingState } from '../../components/shared/models/job/EJobTrainingState';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { enumFromValue } from '../../components/shared/utilities/enumFromValue';
import { config } from '../../config';
import { fetchJobInfo } from './useJobInfo';

const REFRESH_INTERVAL_MILLISECONDS = 3000;

type UseRetrainState = {
  jobId: number;
  retrainMutationStatus: 'idle' | 'loading' | 'error' | 'success' | 'none';
};
export function useRetrainState({ jobId, retrainMutationStatus }: UseRetrainState) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobInfoResponse, Error, EJobTrainingState | null>(
    [config.api.jobInfo.replace(':id', `${jobId}`)],
    () => fetchJobInfo(jobId, jsonConvert),
    {
      // @ts-expect-error TS(2322): Type '(retrainState: EJobTrainingState) => false |...
      refetchInterval: (retrainState: EJobTrainingState) => {
        if (CAN_NOT_START_ANOTHER_RETRAIN.includes(retrainState) || retrainMutationStatus === 'loading') {
          return REFRESH_INTERVAL_MILLISECONDS;
        }
        return false;
      },
      select: (data: JobInfoResponse) => {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        return data.info.status.retrain_state ? enumFromValue(data.info.status.retrain_state, EJobTrainingState) : null;
      },
      enabled: Boolean(jobId)
    }
  );
}
