import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { FiltersProps, FilterProperty } from '../Visualisation/FilterProps';
import { Filters } from '../Visualisation/Filters';
import { FiltersRecord } from '../../models/usecase/FiltersRecord';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';
import { useVisualizationsContext } from './Visualizations.context';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';

export function QueryFilters(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [filterRecord, setFilterRecord] = useState<FiltersRecord>();
  const [filtersProps, setFilterProps] = useState<FiltersProps>();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setFilterRecord(jsonConvert.deserializeObject(response.data.charts[0], FiltersRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!filterRecord) {
      return;
    }

    const filters = new Array<FilterProperty>();
    for (let i = 0; i < filterRecord.available_filters.length; i += 1) {
      const availableFilter = filterRecord.available_filters[i];
      if (filterRecord.aggs != null) {
        // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
        const { buckets } = filterRecord.aggs[availableFilter.name];
        let values = Array<string>();
        values.push('');
        // eslint-disable-next-line
        values = values.concat(buckets.map((entry: any) => entry.key));
        filters.push({
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
          key: availableFilter.name,
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
          source: availableFilter.source,
          values,
          selected: ''
        });
      }
    }

    setFilterProps({
      filters
    });
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [filterRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <br />
        {/* @ts-expect-error TS(2322): Type '{ filters?: FilterProperty[] | undefined; }'... */}
        <Filters {...filtersProps} />
      </Spin>
    </>
  );
}
