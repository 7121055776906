/* eslint-disable react/prop-types */
import React, { ReactElement } from 'react';
import { permissions } from '../../static/ComponentsPermissions';
import { useApp } from '../../../App/App.context';

type PermPropsType = {
  action?: string;
  permissions?: string[];
  children?: ReactElement;
  testId?: string;
};

const defaultPropsValues: PermPropsType = {
  action: 'hidden',
  permissions: [],
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'ReactElemen...
  children: null,
  // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un...
  testId: null
};

const getProps = (permProps: PermPropsType): PermPropsType => ({
  ...defaultPropsValues,
  ...permProps
});

export default function PermissionsCheck(permProps: PermPropsType = defaultPropsValues): ReactElement {
  const props: PermPropsType = getProps(permProps);
  const { hasAccessFor } = useApp();
  // @ts-expect-error TS(2345): Argument of type 'string[] | undefined' is not ass...
  const hasPermission = hasAccessFor(props.permissions);
  if (hasPermission === false && props.action === permissions.action.hiddenAction) {
    return <></>;
  }

  let result = props.children;
  if (hasPermission === false && props.action === permissions.action.disabledAction) {
    // @ts-expect-error TS(2769): No overload matches this call.
    result = React.cloneElement(props.children, { disabled: true });
  }

  return <span {...(props.testId && { ['data-test-id']: props.testId })}>{result}</span>;
}
