import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { config } from 'src/config';
import {
  TUnifiedDecisionPointsResponse,
  TUnifiedDecisionPointsResponseUnparsed,
  UnifiedDecisionPointsListRequest
} from '@arcanna/models/Jobs';
import _ from 'lodash';

const DEFAULT_STALE_TIME = 300000; // 5 minutes

const URL = config.api.jobFeatureSelectionUnifiedSummary;

const fetchUnifiedDecisionPointsList = (jobIds: number[]) =>
  axios
    .post<{ resource: { features: TUnifiedDecisionPointsResponseUnparsed[] } }>(URL, new UnifiedDecisionPointsListRequest(jobIds))
    .then((responseData: AxiosResponse<{ resource: { features: TUnifiedDecisionPointsResponseUnparsed[] } }>) => {
      return {
        features: responseData.data.resource.features.map((feature) => ({
          fieldName: feature.field_name,
          ..._.mapValues(_.omit(feature, 'field_name'), (record) =>
            _.isEmpty(record)
              ? record
              : {
                  isDecisionPoint: record.is_decision_point,
                  isCustomField: record.is_custom_field,
                  sampleValue: record.sample_value,
                  fieldType: record.field_type
                }
          )
        }))
      };
    });

const useUnifiedDecisionPointsList = ({ jobIds, enabled }: { jobIds: number[]; enabled?: boolean }) => {
  return useQuery<{ features: TUnifiedDecisionPointsResponse[] }, Error>([URL], () => fetchUnifiedDecisionPointsList(jobIds), {
    enabled,
    staleTime: DEFAULT_STALE_TIME
  });
};

export default useUnifiedDecisionPointsList;
