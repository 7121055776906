import { divMod } from './numeral';

enum TimeUnits {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days'
}

type TimeDivisionProps = {
  value: number;
  unit: string;
};

export const getHumanReadableTime = (totalTime: number, timeUnits: string) => {
  const SEC_MIN_DIV = 60;
  const HOUR_DIV = 24;
  let quotientTimeUnit: string = timeUnits;
  let quotientTime: number = totalTime;
  const timesList: TimeDivisionProps[] = [];

  if (quotientTimeUnit === TimeUnits.SECONDS && quotientTime >= SEC_MIN_DIV) {
    const toMinutes = divMod(quotientTime, SEC_MIN_DIV);
    quotientTime = toMinutes.quotient;
    quotientTimeUnit = TimeUnits.MINUTES;

    timesList.push({
      value: toMinutes.remainder,
      unit: TimeUnits.SECONDS
    });
  }

  if (quotientTimeUnit === TimeUnits.MINUTES && quotientTime >= SEC_MIN_DIV) {
    const toHours = divMod(quotientTime, SEC_MIN_DIV);
    quotientTime = toHours.quotient;
    quotientTimeUnit = TimeUnits.HOURS;

    timesList.push({
      value: toHours.remainder,
      unit: TimeUnits.MINUTES
    });
  }

  if (quotientTimeUnit === TimeUnits.HOURS && quotientTime >= HOUR_DIV) {
    const toDays = divMod(quotientTime, HOUR_DIV);
    quotientTime = toDays.quotient;
    quotientTimeUnit = TimeUnits.DAYS;

    timesList.push({
      value: toDays.remainder,
      unit: TimeUnits.HOURS
    });
  }

  timesList.push({
    value: quotientTime,
    unit: quotientTimeUnit
  });

  return timesList;
};
