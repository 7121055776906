import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { JobFeatureSelectionSummaryRequest } from '../../../components/shared/models/job/JobFeatureSelectionSummaryRequest';
import { JobFeatureSelectionSummaryResponse } from '../../../components/shared/models/job/JobFeatureSelectionSummaryResponse';
import { config } from '../../../config';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { JobFeatureSelectionSummaryDetails } from '../../../components/shared/models/job/JobFeatureSelectionSummaryDetails';

type UseSelectedInputField = {
  jobId: number;
  selectedInputName: string;
};
export function useSelectedInputField({ jobId, selectedInputName }: UseSelectedInputField) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<JobFeatureSelectionSummaryResponse, Error, JobFeatureSelectionSummaryDetails | undefined>(
    [config.api.jobFeatureSelectionSummary, { jobId }],
    () =>
      axios
        .post<{
          resource: JobFeatureSelectionSummaryResponse;
        }>(config.api.jobFeatureSelectionSummary, new JobFeatureSelectionSummaryRequest(jobId))
        .then((responseData: AxiosResponse<{ resource: JobFeatureSelectionSummaryResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, JobFeatureSelectionSummaryResponse)
        ),
    {
      staleTime: Infinity,
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      select: (data: JobFeatureSelectionSummaryResponse) => data.features.find((feature) => feature.name === selectedInputName)
    }
  );
}
