import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MigrationStatus')
export class MigrationStatus {
  @JsonProperty('upgrade_in_progress', Boolean)
  upgradeInProgress?: boolean | false;

  @JsonProperty('last_upgrade_version', String)
  lastUpgradeVersion: string | undefined;

  @JsonProperty('last_upgrade_date', String)
  lastUpgradeDate: string | undefined;

  @JsonProperty('last_upgrade_status', String)
  lastUpgradeStatus: string | undefined;
}
