import { IntegrationTypes } from 'src/components/shared/models/integration/IntegrationTypes';

class IntegrationPriority {
  static BEFORE_PROCESSOR_INTEGRATION_TYPES = [
    IntegrationTypes.ENRICHMENT.toString(),
    IntegrationTypes.CUSTOM_CODE.toString(),
    IntegrationTypes.CONTEXT_ENRICHMENT.toString()
  ];

  static AFTER_PROCESSOR_INTEGRATION_TYPES = [
    IntegrationTypes.POST_DECISION.toString(),
    IntegrationTypes.CASE_CREATION.toString()
  ];
}

export default IntegrationPriority;