import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import { VisualizationRecord } from '../../models/usecase/VisualizationRecord';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useVisualizationsContext } from './Visualizations.context';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';
import { TableProps, TableView, ColumnProps } from '../Visualisation/TableView';

/*
  Linearize a json.
  Transform all fields from a json to a list with dot notation elements.

  E.g:
  [{
    "time": "value",
    "location": {
      "geo": {
        "lat": 45.55,
        "long": 38.61
      }
    }
  }]

  To:
  [{
    "time": "value",
    "location.geo.lat": 45.55,
    "location.geo.long": 38.61
  }]
*/
// eslint-disable-next-line
function toDotNotation(obj: any, current: string, res: any) {
  for (const key in obj) {
    const value = obj[key];
    const newKey = current ? `${current}.${key}` : key; // joined key with dot
    if (Array.isArray(value) && value.length > 0) {
      // eslint-disable-next-line
      res[newKey] = value[0];
    } else if (value && typeof value === 'object') {
      toDotNotation(value, newKey, res); // it's a nested object, so do it again
    } else {
      res[newKey] = value; // it's not an object, so set the property
    }
  }
}

/*
  Find recursively in an object a given key by the 'source' argument.
  Otherwise return null
*/
// eslint-disable-next-line
export function accessFirstOccurence(obj: any, source: string): any {
  let result = null;
  if (obj[source] != null) {
    if (obj[source].value != null) {
      return obj[source].value;
    }
    if (obj[source].buckets != null && obj[source].buckets.length > 0) {
      return obj[source].buckets[0].key;
    }
  } else {
    for (const key in obj) {
      if (typeof obj[key] === 'object') {
        result = accessFirstOccurence(obj[key], source);
        if (result != null) {
          return result;
        }
      }
    }
  }
  return result;
}

export function QueryTable(props: VisualizationRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<VisualizationRecord>();
  // @ts-expect-error TS(2345): Argument of type 'undefined' is not assignable to ...
  const [tableData, setTableData] = useState<TableProps>(undefined);
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], VisualizationRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    const { hits } = visualizationRecord;
    const aggregations = visualizationRecord.aggs;
    // eslint-disable-next-line
    const dataSource = Array<any>();
    const columns = Array<ColumnProps>();

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    if (hits.length > 0) {
      // Results are hits from a document
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      hits.forEach((hit) => {
        const fieldsWithDotNotation = {};
        toDotNotation(hit._source, '', fieldsWithDotNotation);
        toDotNotation(hit.fields, '', fieldsWithDotNotation);
        dataSource.push(fieldsWithDotNotation);
      });
    } else {
      // Results are some aggregations
      // eslint-disable-next-line
      let buckets: Array<any> = [];
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      let aggregationKey: string = null;
      for (const key in aggregations) {
        aggregationKey = key;
        buckets = buckets.concat(aggregations[key].buckets);
      }

      buckets.forEach((bucketEntry) => {
        // eslint-disable-next-line
        const dataSourceEntry: any = {};
        dataSourceEntry[aggregationKey] = bucketEntry.key;
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        // eslint-disable-next-line
        visualizationRecord.mappings.forEach((mapping: any) => {
          const value = accessFirstOccurence(bucketEntry, mapping.source);
          if (value != null) {
            dataSourceEntry[mapping.source] = value;
          }
        });
        dataSource.push(dataSourceEntry);
      });
    }

    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    visualizationRecord.mappings.forEach((mapping) => {
      columns.push({
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
        key: mapping.source,
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
        dataIndex: mapping.source,
        // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
        title: mapping.name
      });
    });

    setTableData({
      columns,
      dataSource
    });
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div className="usecase-card-title">{props.name}</div>
        <br />
        <TableView key={props.name} {...tableData} />
      </Spin>
    </>
  );
}
