import { Button, ListItem, Stack } from '@mui/material';
import { ConfirmPopover, Icon, EIcon } from '@arcanna/generic';
import { ReactNode, useRef, useState } from 'react';
import { TRHFCustomItem } from './RHFCustomItemList';

type ItemProps<T> = {
  item: T;
  title: ReactNode;
  isDisabled: boolean;
  onDelete?: () => void;
  onEdit?: (item: T) => void;
  dataTestId?: string;
};

function Item<T extends TRHFCustomItem>({ item, title, onDelete, onEdit, isDisabled, dataTestId }: ItemProps<T>) {
  const anchorRef = useRef();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  return (
    <>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ListItem className="list-item" ref={anchorRef}>
        <Stack alignItems="center" justifyContent="center" className="icon-wrapper">
          {item.icon}
        </Stack>
        <Stack flexGrow={1} alignItems="center" justifyContent="space-between" direction="row" paddingX={2}>
          {title}
          <Stack direction="row" alignItems="center" gap={2}>
            {onEdit && (
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                disabled={isDisabled}
                className="MuiButton-root--icon"
                data-test-id={dataTestId}
                onClick={() => onEdit(item)}
              >
                <Icon name={EIcon.Edit16} fontSize="small" />
              </Button>
            )}
            {onDelete && (
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                disabled={isDisabled}
                className="MuiButton-root--icon"
                onClick={() => setIsConfirmOpen(true)}
              >
                <Icon name={EIcon.Delete} fontSize="small" />
              </Button>
            )}
          </Stack>
        </Stack>
      </ListItem>
      <ConfirmPopover
        // @ts-expect-error TS(2322): Type 'null | undefined' is not assignable to type ...
        anchor={isConfirmOpen ? anchorRef?.current : null}
        // @ts-expect-error TS(2322): Type '(() => void) | undefined' is not assignable ...
        onConfirm={onDelete}
        onClose={() => setIsConfirmOpen(false)}
        text="Are you sure you want to delete this integration?"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      />
    </>
  );
}

export default Item;
