import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { JobSummaryRecord } from './JobSummaryRecord';

@JsonObject('JobSummaryResponse')
export class JobSummaryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('entries', [JobSummaryRecord])
  entries?: JobSummaryRecord[] = [];

  @JsonProperty('total_count', Number)
  totalCount: 0;
}
