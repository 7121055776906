import { DPUnifiedContext } from './DPUnifiedContext';
import { useContext } from 'react';
import { TDPUnifiedContext } from './DPUnifiedContext.type';

export function useDPUnifiedContext(): TDPUnifiedContext {
  const context = useContext(DPUnifiedContext);

  if (context === undefined) {
    throw new Error('useDPUnifiedContext must be used within DPUnifiedContextProvider');
  }

  return context;
}
