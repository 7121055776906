import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('JobAlertsTimeseriesIntervalRecord')
export class JobAlertsTimeseriesIntervalRecord {
  @JsonProperty('unit_seconds', Number)
  unit_seconds: number;

  @JsonProperty('unit_friendly_format', String)
  unit_friendly_format: string;

  @JsonProperty('date_start', String)
  date_start: string;

  @JsonProperty('date_end', String)
  date_end: string;
}
