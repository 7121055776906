import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { FeedbackStatisticsLabelsConsensusStatsRecord } from './FeedbackStatisticsLabelsConsensusStatsRecord';

@JsonObject('FeedbackStatisticsLabelsUsedResponse')
export class FeedbackStatisticsLabelsUsedResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse;

  @JsonProperty('consensus_stats', [FeedbackStatisticsLabelsConsensusStatsRecord])
  consensus_stats?: [FeedbackStatisticsLabelsConsensusStatsRecord];

  @JsonProperty('unlabeled', Number)
  unlabeled?: number;
}
