import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { Label } from '@arcanna/generic';
import { useState } from 'react';
import { Stack, useTheme } from '@mui/material';

type TRHFDateTimePickerProps = {
  name: string;
  label?: string;
  tooltipText?: string;
  required?: boolean;
  disabled?: boolean;
  dataTestId?: string;
};

function RHFDateTimePicker({ name, label, tooltipText, required, disabled, dataTestId, ...otherProps }: TRHFDateTimePickerProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    control,
    formState: { isSubmitting },
    trigger
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Stack>
          <DateTimePicker
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            sx={{ width: '100%' }}
            // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
            label={<Label text={label} required={required} tooltipText={tooltipText} />}
            slotProps={{
              actionBar: {
                actions: ['clear', 'today', 'accept']
              },
              textField: {
                onClick: () => setIsOpen(true),
                error: !!error,
                helperText: error?.message,
                onBlur: () => {
                  if (!isOpen) {
                    trigger(name);
                  }
                },
                inputProps: {
                  'data-test-id': dataTestId
                }
              },
              openPickerButton: {
                onClick: () => setIsOpen(true)
              },
              openPickerIcon: {
                sx: {
                  color: theme.palette.secondary[300]
                }
              }
            }}
            ampm={false}
            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            disabled={isSubmitting || disabled}
            timeSteps={{
              hours: 1,
              minutes: 1,
              seconds: 1
            }}
            format="DD/MM/YYYY HH:mm:ss"
            {...field}
            {...otherProps}
          />
        </Stack>
      )}
    />
  );
}

export default RHFDateTimePicker;
