import Joi from 'joi';
import { EFilterOperatorMultiple } from '../../AdvancedFilters.types';
import { TFunction } from 'react-i18next';

export const getAdvancedFilterFormSchema = (t: TFunction) =>
  Joi.object({
    field: Joi.string()
      .required()
      .messages({
        'string.base': t('advancedFilters.fieldRequired'),
        'any.required': t('advancedFilters.fieldRequired'),
        'string.empty': t('advancedFilters.fieldRequired')
      }),
    operator: Joi.string()
      .required()
      .messages({
        'any.required': t('advancedFilters.operatorRequired'),
        'string.empty': t('advancedFilters.operatorRequired')
      }),
    value: Joi.any().optional(),
    multipleValues: Joi.alternatives().conditional('operator', {
      is: Joi.string().valid(...Object.values(EFilterOperatorMultiple)),
      then: Joi.array().items(Joi.any().optional()),
      otherwise: Joi.any().optional()
    })
  });
