import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DeAuthRequest')
export class DeAuthRequest {
  @JsonProperty('user_session_id', String)
  user_session_id?: string = undefined;

  constructor(user_session_id: string) {
    this.user_session_id = user_session_id;
  }
}
