import { JsonProperty, JsonObject } from 'json2typescript';
import { FiltersProps, FilterProperty } from '../../components/Visualisation/FilterProps';

@JsonObject('Filter')
export class Filter {
  @JsonProperty('source')
  source: string | undefined;

  @JsonProperty('name')
  name: string | undefined;

  constructor(source: string, name: string) {
    this.source = source;
    this.name = name;
  }

  static filterPropsToFilterArray(filterProps: FiltersProps): Array<Filter> {
    let selectedFilters: Array<Filter> = [];
    if (filterProps != null) {
      selectedFilters = filterProps.filters
        .map((filter: FilterProperty) => new Filter(filter.source, filter.selected))
        .filter((filter: Filter) => filter.name !== '');
    }
    return selectedFilters;
  }
}
