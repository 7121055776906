import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('FieldInsightsRequest')
export class FieldInsightsRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('feature', String)
  feature: string;

  constructor(job_id: number, feature: string) {
    super();
    this.job_id = job_id;
    this.feature = feature;
  }
}
