import { JsonObject, JsonProperty } from 'json2typescript';
import { JobCreateIntegrationRecord } from './JobCreateIntegrationRecord';

@JsonObject('JobCreateInputRecord')
export class JobCreateInputRecord {
  @JsonProperty('integrations', [JobCreateIntegrationRecord])
  integrations?: JobCreateIntegrationRecord[] = [];

  constructor(integrations: JobCreateIntegrationRecord[]) {
    this.integrations = integrations;
  }
}
