import { useEffect, useState } from 'react';
import { useRetrainEventsBreakdownStatistics } from '../../../../../../data-access';
import { FeedbackSession } from '../../../../../shared/models/feedback/FeedbackStatisticsLabelsUsedRequest';

type NewlyLabeledStatistics = {
  confirmed: number;
  changed: number;
  confirmedPercent: number;
  changedPercent: number;
};

type UseNewlyLabeledStatistics = {
  jobId: number;
  feedbackSession: FeedbackSession;
};
export function useNewlyLabeledStatistics({ jobId, feedbackSession }: UseNewlyLabeledStatistics) {
  const [state, setState] = useState<NewlyLabeledStatistics>({
    confirmed: 0,
    changed: 0,
    confirmedPercent: 0,
    changedPercent: 0
  });

  const retrainEventsBreakdownStatisticsQuery = useRetrainEventsBreakdownStatistics({
    jobId,
    retrainSession: feedbackSession,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    startDate: null,
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'Date'.
    endDate: null,
    filters: []
  });

  useEffect(() => {
    if (retrainEventsBreakdownStatisticsQuery.data) {
      const confirmedValue = retrainEventsBreakdownStatisticsQuery.data.confirmed;
      const changedValue = retrainEventsBreakdownStatisticsQuery.data.changed;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const total = confirmedValue + changedValue;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const confirmedPercent = total > 0 ? (confirmedValue / total) * 100.0 : 0.0;
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const changedPercent = total > 0 ? (changedValue / total) * 100.0 : 0.0;

      setState({
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
        confirmed: confirmedValue,
        // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ...
        changed: changedValue,
        confirmedPercent,
        changedPercent
      });
    }
  }, [retrainEventsBreakdownStatisticsQuery.data]);

  return [state];
}
