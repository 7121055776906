import { TBrandingConfig } from './branding.types';
import * as siscaleConfig from './siscale';
import * as orangeConfig from './orange';

export const brandingConfig: TBrandingConfig = {
  siscale: {
    theme: siscaleConfig.muiTheme
  },
  orange: {
    theme: orangeConfig.muiTheme
  }
};
