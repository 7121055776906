import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FieldInsightStatistic')
export class FieldInsightStatistic {
  @JsonProperty('count', Number)
  count: number;

  @JsonProperty('percent', Number)
  percent: number;
}
