import * as React from 'react';
import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { ActionCategoriesResponse } from '../../components/shared/models/homepage/ActionCategoriesResponse';
import { ActionCategory } from '../../components/shared/models/homepage/ActionCategory';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { CommonRequest } from '../../components/shared/models/common/CommonRequest';
import { config } from '../../config';

export type ActionCategoriesQuery = (ActionCategoriesResponse & { categoryLabelMap: Record<string, string> }) | undefined;

function mapNameToDescription(response: ActionCategoriesResponse): Record<string, string> {
  return response.categories.reduce(
    (result: Record<string, string>, category: ActionCategory) => ({
      ...result,
      [category.name]: category.aggregation_description
    }),
    {}
  );
}

function formatResponse(response: ActionCategoriesResponse): ActionCategoriesQuery {
  return { ...response, categoryLabelMap: mapNameToDescription(response) };
}

export function useActionCategories() {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery<ActionCategoriesQuery, Error>(
    [config.api.actionCategories],
    () =>
      axios
        .post<{ resource: ActionCategoriesResponse }>(config.api.actionCategories, new CommonRequest())
        .then((responseData: AxiosResponse<{ resource: ActionCategoriesResponse }>) =>
          formatResponse(jsonConvert.deserializeObject(responseData.data.resource, ActionCategoriesResponse))
        ),
    {
      staleTime: Infinity
    }
  );
}
