import axios from 'axios';
import { config } from '../../config';
import { useMutation, useQueryClient } from 'react-query';
import { LicenseLoadRequest } from '../../components/shared/models/license/LicenseLoadRequest';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { useTranslation } from 'react-i18next';

export default function useLicenseLoad() {
  const { t } = useTranslation(['platform-management', 'common']);
  const queryClient = useQueryClient();

  return useMutation((payload: LicenseLoadRequest) => axios.post<{ resource: CommonResponse }>(config.api.licenseLoad, payload), {
    onSuccess: () => {
      showSuccessNotification(t('common:actionCompleted'), t('loadLicenseSuccess'));
    },
    onError: () => {
      showErrorNotification(t('error'), t('loadLicenseError'));
    },
    onSettled: () => {
      queryClient.invalidateQueries([config.api.licenseInfo]);
    }
  });
}
