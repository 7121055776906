import * as React from 'react';

import { useQuery } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { IntegrationReadRequest } from '../../components/shared/models/integration/IntegrationReadRequest';
import { IntegrationReadResponse } from '../../components/shared/models/integration/IntegrationReadResponse';

type UseIntegrationList = {
  integrationId: number | undefined;
};
export function useIntegrationInfo({ integrationId }: UseIntegrationList) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  const url = config.api.integrationList.replace(':integrationId', `${integrationId}`);

  return useQuery<IntegrationReadResponse, Error>(
    [url, { integrationId }],
    () =>
      axios
        .post<{ resource: IntegrationReadResponse }>(url, new IntegrationReadRequest(1))
        .then((responseData: AxiosResponse<{ resource: IntegrationReadResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, IntegrationReadResponse)
        ),
    {
      enabled: Boolean(integrationId)
    }
  );
}
