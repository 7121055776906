import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { PiechartProps } from './Piechart';
import { FilterProperty } from './FilterProps';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';
import { useUtils } from '../../hooks/useUtils';

export interface PierchartNestedProps {
  innerLayer: PiechartProps;
  outerLayer: PiechartProps;
}

export function PiechartNested(props: PierchartNestedProps) {
  const visualizationContext = useVisualizationsContext();
  const { overrideOwnStyleAttributes } = useUtils();

  const ownStyleAttributes = {
    height: '400px',
    legendHeight: '90px'
  };

  function getLayerForValue(value: string) {
    // eslint-disable-next-line
    if (props.innerLayer.data.find((el: any) => el.name === value) != null) {
      return props.innerLayer;
    }

    return props.outerLayer;
  }

  // eslint-disable-next-line
  const elementClicked = (el: any) => {
    const value = el.name != null ? el.name : el.value;
    const layer: PiechartProps = getLayerForValue(value);
    const filterProps: FilterProperty = {
      key: layer.filterFields[0],
      source: layer.filterFields[0],
      values: [],
      selected: value
    };

    visualizationContext.appendClickableFilters(filterProps);
    visualizationContext.updateApplyTrigger();
  };

  const legendFormatter = (value: string) => (
    <span
      style={{
        width: 'auto',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        marginBottom: '-8px',
        paddingRight: '12px',
        textAlign: 'center'
      }}
    >
      {value}
    </span>
  );

  // eslint-disable-next-line
  const renderInnerLabels = (entry: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, payload } = entry;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 40) * cos;
    const sy = cy + (outerRadius + 40) * sin;
    const mx = cx + (outerRadius + 60) * cos;
    const my = cy + (outerRadius + 60) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    return (
      <g>
        <path d={`M${cx},${cy}L${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={payload.fill}>
          {payload.name}
        </text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {payload.value}
        </text>
      </g>
    );
  };

  if (props.innerLayer != null && props.innerLayer.data != null) {
    overrideOwnStyleAttributes(ownStyleAttributes, props);

    return (
      <div style={{ height: ownStyleAttributes.height }}>
        <ResponsiveContainer width="100%">
          <PieChart width={400} height={400}>
            <Pie
              data={props.innerLayer.data}
              cx="50%"
              cy="50%"
              label={renderInnerLabels}
              labelLine={false}
              outerRadius="40%"
              fill="#8884d8"
              dataKey="value"
              onClick={elementClicked}
              legendType="none"
            >
              {props.innerLayer.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={props.innerLayer.colors[index % props.innerLayer.colors.length]} />
              ))}
            </Pie>
            <Pie
              data={props.outerLayer.data}
              cx="50%"
              cy="50%"
              labelLine
              innerRadius="50%"
              outerRadius="60%"
              fill="#8884d8"
              dataKey="value"
              onClick={elementClicked}
            >
              {props.outerLayer.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={props.outerLayer.colors[index % props.outerLayer.colors.length]} />
              ))}
            </Pie>
            <Tooltip itemStyle={{ color: 'rgba(255, 255, 255, 0.65)' }} />
            <Legend
              wrapperStyle={{
                width: '100%',
                overflowY: 'auto',
                height: ownStyleAttributes.legendHeight,
                left: 0,
                paddingBottom: '10px'
              }}
              formatter={legendFormatter}
              onClick={elementClicked}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return <></>;
}
