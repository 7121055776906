import * as React from 'react';
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { FeedbackStatisticsConsensusResponse } from '../../components/shared/models/feedback/FeedbackStatisticsConsensusResponse';
import { FeedbackStatisticsRequest } from '../../components/shared/models/feedback/FeedbackStatisticsRequest';
import { JobRetrainFilters, RetrainSession } from '../../components/shared/models/retrain-statistics/JobRetrain';
import { config } from '../../config';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';

type UseRetrainConsensusProps = Pick<JobRetrainFilters, 'jobId' | 'startDate' | 'endDate'>;

export function useRetrainConsensus({ jobId, startDate, endDate }: UseRetrainConsensusProps) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);

  return useQuery(
    [
      config.api.feedbackStatisticsConsensus,
      {
        jobId,
        retrainSession: RetrainSession.CURRENT,
        startDate,
        endDate,
        filters: []
      }
    ],
    () =>
      axios
        .post<{ resource: FeedbackStatisticsConsensusResponse }>(
          config.api.feedbackStatisticsConsensus,
          new FeedbackStatisticsRequest(jobId, RetrainSession.CURRENT, startDate, endDate, [])
        )
        .then((responseData: AxiosResponse<{ resource: FeedbackStatisticsConsensusResponse }>) =>
          jsonConvert.deserializeObject(responseData.data.resource, FeedbackStatisticsConsensusResponse)
        )
        .then((data) => ({
          similar: data.similar,
          different: data.different
        }))
  );
}
