import { useTranslation } from 'react-i18next';
import { Stack, Typography, useTheme } from '@mui/material';
import { Icon, EIcon } from '@arcanna/generic';

function IdeaBoxTitle() {
  const { t } = useTranslation('idea-box');
  const { palette } = useTheme();

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" gap={1}>
      <Stack direction="row" gap="3px">
        <Typography color={palette.grey.A100} fontSize="20px" fontWeight={600} lineHeight="28px">
          {t('ideaBox')}
        </Typography>
        <Icon name={EIcon.MessageAlertSquare} color="primary" fontSize="medium" />
      </Stack>
      <Stack direction="column">
        <Typography color={palette.secondary[300]} fontSize="14px" lineHeight="22px">
          {t('description1')}
        </Typography>
        <Typography color={palette.secondary[300]} fontSize="14px" lineHeight="22px">
          {t('description2')}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default IdeaBoxTitle;
