import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { Spinner, StateChip } from '@arcanna/generic';

function JobRollbackInProgressTag() {
  const { t } = useTranslation(['job']);
  const theme = useTheme();

  return <StateChip color={theme.palette.grey[400]} text={t('job:jobState.rollbackInProgress')} icon={<Spinner />} />;
}

export default JobRollbackInProgressTag;
