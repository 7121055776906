import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getJsonConvert } from 'src/components/shared/utilities/json-convert';
import { fetchJobInfo } from 'src/data-access';
import { jobKeys } from './keys';
import { showErrorNotification } from 'src/components/shared/utilities/notification';
import { JobInfoResponse } from 'src/components/shared/models/job/JobInfoResponse';
import { useTranslation } from 'react-i18next';

// TODO: replace with jobInfo endpoint that accepts array of jobIds.
const useJobsInfos = ({ jobIds }: { jobIds: number[] }) => {
  const { t } = useTranslation();
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  // QUERY
  return useQuery<JobInfoResponse[]>(
    jobKeys.infos(jobIds),
    () => Promise.all(jobIds.map((id) => fetchJobInfo(id, jsonConvert))),
    {
      onError: () => {
        showErrorNotification(t('requests:codeBlock.info.error.title'), t('requests:codeBlock.info.error.subtitle'));
      },
      staleTime: Infinity
    }
  );
};

export default useJobsInfos;
