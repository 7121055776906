import { TableState, Updater } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { getTablePreferencesFromLS, setTablePreferencesToLS } from './useSaveTablePreferences.utils';
import _ from 'lodash';
import { TABLE_SIZES } from '../../components/TableSizeSelector/TableSizeSelector.constants';

export type TTablePreferences = Pick<
  TableState,
  'columnOrder' | 'columnPinning' | 'columnSizing' | 'columnSizingInfo' | 'columnVisibility'
> & {
  tableSize: string;
  customRowSizeValue: number;
};

type TUseSaveTablePreferencesProps = {
  tableKey: string;
};

export type TUseSaveTablePreferences = {
  tableState: TableState;
  setTableState: (updaterOrValue: Updater<TableState>) => void;
  tableSize: string;
  setTableSize: React.Dispatch<React.SetStateAction<string>>;
  customRowSizeValue: number;
  setCustomRowSizeValue: React.Dispatch<React.SetStateAction<number>>;
};

function useSaveTablePreferences({ tableKey }: TUseSaveTablePreferencesProps): TUseSaveTablePreferences {
  const [tableState, setTableState] = useState<TableState>(() => {
    const tablePreferencesFromLS = getTablePreferencesFromLS(tableKey);

    return {
      columnOrder: tablePreferencesFromLS?.columnOrder ?? [],
      columnPinning: tablePreferencesFromLS?.columnPinning ?? {},
      columnSizing: tablePreferencesFromLS?.columnSizing ?? {},
      columnSizingInfo: tablePreferencesFromLS?.columnSizingInfo ?? {
        columnSizingStart: [],
        isResizingColumn: false,
        deltaOffset: null,
        deltaPercentage: null,
        startOffset: null,
        startSize: null
      },
      columnVisibility: tablePreferencesFromLS?.columnVisibility ?? {},
      rowPinning: {},
      columnFilters: [],
      globalFilter: '',
      sorting: [],
      expanded: {},
      grouping: [],
      pagination: {
        pageIndex: 0,
        pageSize: 0
      },
      rowSelection: {}
    };
  });
  const [tableSize, setTableSize] = useState<string>(() => {
    const tablePreferencesFromLS = getTablePreferencesFromLS(tableKey);

    return tablePreferencesFromLS?.tableSize ?? TABLE_SIZES.small;
  });

  const [customRowSizeValue, setCustomRowSizeValue] = useState<number>(() => {
    const tablePreferencesFromLS = getTablePreferencesFromLS(tableKey);

    return tablePreferencesFromLS?.customRowSizeValue ?? 2;
  });

  const tablePreferences = _.pick(tableState, [
    'columnOrder',
    'columnPinning',
    'columnSizing',
    'columnSizingInfo',
    'columnVisibility'
  ]) as TTablePreferences;

  useEffect(() => {
    setTablePreferencesToLS({
      tableKey,
      partialTableData: {
        ...tablePreferences,
        tableSize,
        customRowSizeValue
      }
    });
  }, [tablePreferences, tableKey, tableSize, customRowSizeValue]);

  return useMemo(
    () => ({
      tableState,
      setTableState,
      tableSize,
      setTableSize,
      customRowSizeValue,
      setCustomRowSizeValue
    }),
    [tableState, setTableState, tableSize, setTableSize, customRowSizeValue, setCustomRowSizeValue]
  );
}

export default useSaveTablePreferences;
