import { Row, Col } from 'antd';
import styles from './Visualisation.module.css';

export interface MetricCircleProps {
  value: number;
  label: string;
  color: string;
}

export function MetricCircle(props: MetricCircleProps) {
  return (
    <>
      <div className={styles.circleMetricWrapper}>
        <div>
          <div>
            <div>
              <Row>
                <Col span={24} className="flex flex-center flex-col flex-align-center">
                  <h2>{props.value}</h2>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="flex flex-center flex-col flex-align-center">
                  {props.label}
                </Col>
              </Row>
            </div>
            <svg height="220" width="220">
              <circle cx="110" cy="110" r="100" stroke={props.color} strokeWidth="13" fill="transparent" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
