import { useEffect, useState, useMemo } from 'react';
import { Spin } from 'antd';
import styles from './QueryVisualisation.module.css';

import { Metric } from '../Visualisation/Metric';
import { useFetch } from '../../hooks/useFetch';
import { getBackendEndpoint } from '../../utilities/api';
import { useVisualizationsContext } from './Visualizations.context';
import { ChartsRequest } from '../../models/charts/ChartsRequest';
import { getJsonConvert } from '../../utilities/json-convert';
import { MultiLayerRecord } from '../../models/usecase/MultiLayerRecord';
import { humanReadableValue } from './QueryMetric';

export function QueryMetricsSum(props: MultiLayerRecord) {
  const visualisationContext = useVisualizationsContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [visualizationRecord, setVisualizationRecord] = useState<MultiLayerRecord>();
  // eslint-disable-next-line
  const [buckets, setBuckets] = useState(Array<any>());
  const jsonConvert = useMemo(() => getJsonConvert(), []);

  const { post } = useFetch({
    path: getBackendEndpoint('/charts'),
    load: false
  });

  useEffect(() => {
    setLoading(true);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig...
    post(new ChartsRequest(props.id, visualisationContext.state))
      // eslint-disable-next-line
      .then((response: any) => {
        setVisualizationRecord(jsonConvert.deserializeObject(response.data.charts[0], MultiLayerRecord));
      })
      // eslint-disable-next-line
      .catch(() => {});
    // eslint-disable-next-line
  }, [post, visualisationContext.state.applyTrigger]);

  useEffect(() => {
    if (!visualizationRecord) {
      return;
    }

    let totalSum = 0;
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    for (let i = 0; i < visualizationRecord.layers.length; i += 1) {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const aggregations = visualizationRecord.layers[i].aggs;
      for (const key in aggregations) {
        totalSum += aggregations[key].value;
      }
    }
    setBuckets([
      {
        key: props.name,
        doc_count: humanReadableValue(totalSum, props),
        style: visualizationRecord.style
      }
    ]);
    setLoading(false);
    visualisationContext.updateExport();
    // eslint-disable-next-line
  }, [visualizationRecord]);

  // Render differently base on orientation
  if (props.style.orientation === 'horizontal') {
    return (
      <>
        <Spin tip="Loading..." spinning={loading}>
          <div>
            <div className={styles.horizontalMetricContainer}>
              {buckets.map((bucket) => (
                <div key={bucket.key}>
                  <Metric
                    value={bucket.doc_count}
                    label={bucket.key}
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    tooltip={visualizationRecord.tooltip}
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    style={visualizationRecord.style}
                  />
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </>
    );
  }

  return (
    <>
      <Spin tip="Loading..." spinning={loading}>
        <div>
          <div>
            {buckets.map((bucket) => (
              <Metric
                key={bucket.key}
                value={bucket.doc_count}
                label={bucket.key}
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                tooltip={visualizationRecord.tooltip}
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                style={visualizationRecord.style}
              />
            ))}
          </div>
        </div>
      </Spin>
    </>
  );
}
