import { useJobInfo } from '../../../../../../data-access';
import { JobStateEnum } from '../../../../../shared/models';
import { useEffect, useState } from 'react';
import { config } from '../../../../../../config';
import { useQueryClient } from 'react-query';

const REFETCH_INTERVAL_MILLIS = 3000;
export default function useRollbackInProgress({ jobId }: { jobId: number }) {
  const [shouldRefetch, setShouldRefetch] = useState<false | number>(false);
  const jobInfoQuery = useJobInfo(jobId, shouldRefetch);
  const [progress, setProgress] = useState<number>(0);
  const queryClient = useQueryClient();

  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const isRollbackInProgress = jobInfoQuery.data?.info.status.state === JobStateEnum.ROLLING_BACK;

  useEffect(() => {
    if (isRollbackInProgress) {
      setShouldRefetch(REFETCH_INTERVAL_MILLIS);
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      setProgress(jobInfoQuery.data?.info.status.rollback_progress || 0);
    } else {
      setShouldRefetch(false);
      setProgress(0);
      queryClient.resetQueries([config.api.feedbackBucketsBatch]);
    }
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  }, [isRollbackInProgress, jobInfoQuery.data?.info.status.rollback_progress, queryClient]);

  return { progress, isRollbackInProgress };
}
