import axios, { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { config } from '../../config';
import { useTranslation } from 'react-i18next';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { JobStatusChangeRequest } from '../../components/shared/models/job/JobStatusChangeRequest';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';

export function useStartJob() {
  const { t } = useTranslation(['job', 'common']);
  const queryClient = useQueryClient();

  const onStartError = (error: { response: AxiosResponse<{ resource: { request: CommonResponse } }> }) => {
    showErrorNotification(t('common:actionCompleted'), t('job:startedError') + error.response.data.resource.request.reason);
  };

  const onStartSettled = () => {
    queryClient.invalidateQueries([config.api.jobsSummary]);
  };

  return useMutation(
    (payload: JobStatusChangeRequest) => axios.post<{ resource: { request: CommonResponse } }>(config.api.startJob, payload),
    {
      onSuccess: (result: AxiosResponse<{ resource: { request: CommonResponse } }>, payload) => {
        queryClient.invalidateQueries([config.api.jobInfo.replace(':id', payload.job_id?.toString() ?? '')]);

        if (!result.data.resource) {
          showErrorNotification(t('common:actionCompleted'), t('job:startedError'));
          return;
        }
        showSuccessNotification(t('common:actionCompleted'), t('job:startedSuccessfully'));
      },
      onError: onStartError,
      onSettled: onStartSettled
    }
  );
}
