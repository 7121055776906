import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { PiechartProps } from './Piechart';
import { FilterProperty } from './FilterProps';
import { useVisualizationsContext } from '../QueryVisualisation/Visualizations.context';

export interface PierchartMultipleProps {
  categories: Array<string>;
  categoriesFilterField: string;
  charts: Array<PiechartProps>;
}

export function PiechartMultiple(props: PierchartMultipleProps) {
  const visualizationContext = useVisualizationsContext();

  function getFilterKeyForValue(value: string) {
    if (props.categories.indexOf(value) >= 0) {
      return props.categoriesFilterField;
    }

    return props.charts[0].filterFields[0];
  }

  function elementClicked(value: string) {
    // eslint-disable-next-line
    return (el: any) => {
      let usedValue = value;
      if (usedValue == null) {
        usedValue = el.name != null ? el.name : el.value;
      }
      const filterKey = getFilterKeyForValue(value);
      const filterProps: FilterProperty = {
        key: filterKey,
        source: filterKey,
        values: [],
        selected: usedValue
      };

      visualizationContext.appendClickableFilters(filterProps);
      visualizationContext.updateApplyTrigger();
    };
  }

  // eslint-disable-next-line
  const uniquePropsLegend = (payload: any) => {
    // Use a dictionary like structure to have the same key only one time
    // in the legend
    // eslint-disable-next-line
    const uniquePropsDict: any = {};
    // eslint-disable-next-line
    payload.forEach((entry: any) => {
      if (uniquePropsDict[entry.value] == null) {
        uniquePropsDict[entry.value] = entry;
      } else {
        // Sum up the total count
        uniquePropsDict[entry.value].payload.value += entry.payload.value;
      }
    });

    // Flatten the dictionary structure to a list
    const flattenedProps = [];
    for (const key in uniquePropsDict) {
      flattenedProps.push(uniquePropsDict[key]);
    }

    // Sorted based on value count
    // eslint-disable-next-line
    return flattenedProps.sort((entry1: any, entry2: any) => entry2.payload.value - entry1.payload.value);
  };

  // eslint-disable-next-line
  const renderLegend = (data: any) => {
    const { payload } = data;
    const uniqueProps = uniquePropsLegend(payload);

    return (
      <ul>
        {/* eslint-disable-next-line */}
        {uniqueProps.map((entry: any, index: number) => (
          <li
            key={`item-${index}`}
            style={{ listStyleType: 'none' }}
            onClick={elementClicked(entry.value)}
            onKeyPress={elementClicked(entry.value)}
            role="tab"
            tabIndex={index}
          >
            <div
              style={{
                width: '125px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                marginBottom: '-8px',
                paddingRight: '12px',
                textAlign: 'left'
              }}
            >
              <span style={{ display: 'inline-block' }}>
                <svg width="12" height="12">
                  <rect width="12" height="12" fill={entry.color} />
                </svg>
              </span>
              <span>
                &nbsp;
                {entry.value}
              </span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  if (props.charts != null && props.charts.length > 0) {
    return (
      <div>
        <div
          style={{
            display: 'inline-block',
            width: '30px',
            height: '700px',
            paddingTop: '20px'
          }}
        >
          {props.categories.map((category, index) => (
            <p
              key={`label-${index}`}
              style={{
                writingMode: 'vertical-rl',
                textOrientation: 'mixed',
                margin: '0px',
                height: '135px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                paddingBottom: '20px',
                width: '20px',
                cursor: 'pointer',
                outline: 'none'
              }}
              onClick={elementClicked(category)}
              onKeyDown={elementClicked(category)}
              role="presentation"
              tabIndex={index}
            >
              {category}
            </p>
          ))}
        </div>
        <div style={{ display: 'inline-block' }}>
          <PieChart width={220} height={700}>
            <Legend
              wrapperStyle={{
                width: '180px',
                position: 'absolute',
                left: '80px',
                top: '30px',
                height: '400px',
                overflowY: 'auto'
              }}
              content={renderLegend}
            />
            {props.charts.map((pieProps, pieIndex) => (
              <Pie
                key={`pie-${pieIndex}`}
                data={pieProps.data}
                cx={50}
                cy={65 + pieIndex * 130}
                labelLine
                outerRadius={50}
                fill="#8884d8"
                dataKey="value"
                // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param...
                onClick={elementClicked(null)}
              >
                {pieProps.data.map((slice, sliceIndex) => (
                  <Cell key={`cell-${sliceIndex}`} fill={pieProps.colors[sliceIndex % pieProps.colors.length]} />
                ))}
              </Pie>
            ))}
            <Tooltip />
          </PieChart>
        </div>
      </div>
    );
  }

  return <></>;
}
