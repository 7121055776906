import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios, { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { config } from '../../config';
import { JobRetrainRequest } from '../../components/shared/models/retrain-statistics/JobRetrainRequest';
import { getJsonConvert } from '../../components/shared/utilities/json-convert';
import { showErrorNotification, showSuccessNotification } from '../../components/shared/utilities/notification';
import { CommonResponse } from '../../components/shared/models/common/CommonResponse';
import { ErrorResponse } from '../../components/shared/models/error/ErrorResponse';
import { JobInfoResponse } from '../../components/shared/models/job/JobInfoResponse';

export function useRetrainJob() {
  const { t } = useTranslation(['common', 'job']);
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const queryClient = useQueryClient();

  return useMutation(
    // @ts-expect-error TS(2769): No overload matches this call.
    (jobId: number) => axios.post<{ resource: { request: CommonResponse } }>(config.api.jobRetrain, new JobRetrainRequest(jobId)),
    {
      onMutate: async (jobId: number) => {
        const jobInfoQueryKey = [config.api.jobInfo.replace(':id', `${jobId}`)];
        await queryClient.cancelQueries(jobInfoQueryKey);
        const previousJobInfo = queryClient.getQueryData<JobInfoResponse>(jobInfoQueryKey);
        queryClient.setQueryData(
          jobInfoQueryKey,
          // @ts-expect-error TS(2345): Argument of type '(prevJobInfo: JobInfoResponse) =...
          (prevJobInfo: JobInfoResponse) =>
            ({
              ...prevJobInfo,
              info: {
                ...prevJobInfo.info,
                status: {
                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  ...prevJobInfo.info.status,
                  retrain_state: 'RETRAIN_IN_PROGRESS'
                }
              }
            }) as JobInfoResponse
        );

        return {
          previousJobInfo
        };
      },
      onSuccess: (responseData: AxiosResponse<{ resource: { request: CommonResponse } }>, jobId) => {
        if (!responseData.data) {
          return;
        }
        if (!responseData.data.resource) {
          showErrorNotification(t('common:actionCompleted'), t('job:retrainError'));
          return;
        }

        const jobRetrainResponse = jsonConvert.deserializeObject(responseData.data.resource.request, CommonResponse);

        if (jobRetrainResponse.status === 'OK') {
          queryClient.invalidateQueries([config.api.feedbackStatisticsEventsBreakdown]);
          queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
          queryClient.invalidateQueries([config.api.feedbackStatisticsConsensus]);
          queryClient.invalidateQueries([config.api.feedbackStatisticsUsers]);
          queryClient.invalidateQueries([config.api.isJobRetrainAvailable, { jobId }]);

          showSuccessNotification(t('common:actionCompleted'), t('job:retrainSuccessfully'));
        } else {
          showErrorNotification(t('common:actionCompleted'), t('job:retrainError') + jobRetrainResponse.reason);
        }
      },
      onError: (error: { data: ErrorResponse }, jobId: number, context: { previousJobInfo: JobInfoResponse }) => {
        showErrorNotification(t('common:error'), t('common:retrainError') + error.data.resource.request.reason);

        const jobInfoQueryKey = [config.api.jobInfo.replace(':id', `${jobId}`)];
        queryClient.setQueryData(jobInfoQueryKey, context.previousJobInfo);
      },
      onSettled: (_, __, jobId) => {
        queryClient.invalidateQueries([config.api.feedbackStatisticsEventsBreakdown]);
        queryClient.invalidateQueries([config.api.jobInfo.replace(':id', `${jobId}`)]);
        queryClient.invalidateQueries([config.api.feedbackStatisticsConsensus]);
        queryClient.invalidateQueries([config.api.feedbackStatisticsUsers]);
        queryClient.invalidateQueries([config.api.isJobRetrainAvailable, { jobId }]);
      }
    }
  );
}
