/* eslint-disable max-len */
function Refresh() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
      <path
        d="M11.3333 1.81738V4.72647H8.42419"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666626 9.57462V6.66553H3.57572"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.8836 4.24144C2.1295 3.54655 2.54742 2.92527 3.09837 2.43558C3.64932 1.94589 4.31534 1.60375 5.03428 1.44108C5.75322 1.27841 6.50166 1.30051 7.20975 1.50533C7.91784 1.71015 8.56251 2.091 9.0836 2.61235L11.3333 4.72629M0.666626 6.66568L2.91632 8.77962C3.43741 9.30097 4.08208 9.68183 4.79017 9.88664C5.49826 10.0915 6.24669 10.1136 6.96564 9.95089C7.68458 9.78822 8.3506 9.44608 8.90155 8.95639C9.4525 8.4667 9.87042 7.84542 10.1163 7.15053"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Refresh;
