import { JsonObject, JsonProperty } from 'json2typescript';

import { CommonResponse } from '../common/CommonResponse';
import { JobAlertHistoryArtifactRecord } from './JobAlertHistoryArtifactRecord';
import { JobAlertHistoryLogRecord } from './JobAlertHistoryLogRecord';

@JsonObject('JobAlertHistoryResponse')
export class JobAlertHistoryResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('log_messages', [JobAlertHistoryLogRecord])
  log_messages?: JobAlertHistoryLogRecord[] = [];

  @JsonProperty('artifacts', [JobAlertHistoryArtifactRecord])
  artifacts?: JobAlertHistoryArtifactRecord[] = [];
}
