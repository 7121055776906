import { InlineNotification } from '@arcanna/components';
import { Box, Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TModalFooterErrorProps = {
  errorMessage: string;
  errorDescription: string;
  onClose: () => void;
};

function ModalStepsFooterError({ errorMessage, errorDescription, onClose }: TModalFooterErrorProps) {
  const { t } = useTranslation(['common']);

  return (
    <Stack direction="column" gap={2} justifyContent="flex-start">
      <InlineNotification type="error" title={errorMessage} subtitle={errorDescription} textPosition="vertical" />
      <Box>
        <Button size="small" variant="tertiary" onClick={onClose}>
          {t('common:cancel')}
        </Button>
      </Box>
    </Stack>
  );
}

export default ModalStepsFooterError;
