export function getDataTestIdFormat(dataTestId: string | undefined | string[]) {
  if (dataTestId === undefined) {
    return '';
  }

  let newDataTestId = dataTestId;
  if (Array.isArray(newDataTestId)) {
    newDataTestId = newDataTestId.join('-');
  }

  newDataTestId = newDataTestId.toLocaleLowerCase();
  // Remove punctuation
  newDataTestId = newDataTestId.replaceAll(/[.,!?]/g, '');
  // Turn _ into -
  newDataTestId = newDataTestId.replaceAll('_', '-');
  // Turn spaces into -
  newDataTestId = newDataTestId.split(' ').join('-');
  // Remove potential - at start or end of string
  newDataTestId = newDataTestId.replace(/^-+/g, '');
  newDataTestId = newDataTestId.replace(/-+$/g, '');

  return newDataTestId;
}
