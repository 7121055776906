import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useJobInfo } from '../../../../../../data-access';
import { useApp } from '../../../../../App/App.context';
import { getCurrentRoute } from '../../../../../../utils';
import { useJobContext } from '../../Job.context';

export function useJobTitle(jobId: number) {
  const { state: appState, setAppHeaderTitle } = useApp();
  const location = useLocation();
  const currentRoute = getCurrentRoute(location.pathname, appState.routes);
  const jobInfoQuery = useJobInfo(jobId);

  const { setSelectedJob, getJobAppHeaderTitle } = useJobContext();

  React.useEffect(() => {
    if (jobInfoQuery.data) {
      // @ts-expect-error TS(2345): Argument of type 'JobInfoRecord | undefined' is no...
      setSelectedJob(jobInfoQuery.data.info);
    }
  }, [jobInfoQuery.data, setSelectedJob]);

  React.useEffect(() => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    setAppHeaderTitle(getJobAppHeaderTitle(currentRoute.title), location.pathname);
    // @ts-expect-error TS(2531): Object is possibly 'null'.
  }, [setAppHeaderTitle, getJobAppHeaderTitle, currentRoute.title, location.pathname]);
}
