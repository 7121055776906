import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonResponse } from '../common/CommonResponse';
import { Action } from './Action';

@JsonObject('ActionListResponse')
export class ActionListResponse {
  @JsonProperty('request', CommonResponse)
  request?: CommonResponse = undefined;

  @JsonProperty('actions', [Action])
  actions: Action[];
}
