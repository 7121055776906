import { useTranslation } from 'react-i18next';
import { Spinner, StateChip } from '@arcanna/generic';
import { useCalculateCloneProgress } from 'src/data-access/job/useCalculateCloneProgress';
import { useTheme } from '@mui/material';

export default function JobCloningTag({ jobId }: { jobId: number }) {
  const { t } = useTranslation(['job']);

  const theme = useTheme();
  const cloneStatus = useCalculateCloneProgress({ jobId });

  return (
    <StateChip
      color={theme.palette.info[400]}
      text={t('job:jobState.cloningStatus', {
        percentageText: cloneStatus.data?.progress !== undefined ? Math.round(cloneStatus.data?.progress) + '%' : ''
      })}
      icon={<Spinner />}
    />
  );
}
