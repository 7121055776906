import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('NumericSummary')
export class NumericSummary {
  @JsonProperty('max', Number)
  max: number;

  @JsonProperty('min', Number)
  min: number;

  @JsonProperty('median', Number)
  median: number;
}
