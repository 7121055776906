import { JsonObject, JsonProperty } from 'json2typescript';
import { EnvVariableRecord } from '@arcanna/models/CodeBlock';
import { RequestCommon } from '@arcanna/models/utils';
import { CodeBlockSettingsRecord } from 'src/_srcMUI/models/CodeBlock/CodeBlockSettingsRecord';

@JsonObject('CodeBlockRunRequest')
export class CodeBlockRunRequest extends RequestCommon {
  @JsonProperty('source_code', String)
  sourceCode: string;

  @JsonProperty('input_test', String)
  inputTest: string;

  @JsonProperty('integration_type_id', Number)
  integrationTypeId: number;

  @JsonProperty('env_variables', [EnvVariableRecord])
  envVariables: EnvVariableRecord[];

  @JsonProperty('settings', CodeBlockSettingsRecord)
  settings: CodeBlockSettingsRecord;
}

type TConstructorParams = {
  sourceCode: CodeBlockRunRequest['sourceCode'];
  inputTest: CodeBlockRunRequest['inputTest'];
  integrationTypeId: CodeBlockRunRequest['integrationTypeId'];
  envVariables: CodeBlockRunRequest['envVariables'];
  settings: CodeBlockRunRequest['settings'];
};

export function constructCodeBlockRunRequest({
  sourceCode,
  inputTest,
  integrationTypeId,
  envVariables,
  settings
}: TConstructorParams): CodeBlockRunRequest {
  const instance = new CodeBlockRunRequest();
  instance.sourceCode = sourceCode;
  instance.inputTest = inputTest;
  instance.integrationTypeId = integrationTypeId;
  instance.envVariables = envVariables;
  instance.settings = settings;

  return instance;
}
