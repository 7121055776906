import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('JobAlertsTimeseriesRequest')
export class JobAlertsTimeseriesRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id: number;

  @JsonProperty('bucket_id', String)
  bucket_id?: string;

  @JsonProperty('date_form', String)
  date_from: string;

  @JsonProperty('date_to', String)
  date_to: string;

  constructor(job_id: number, date_from: Date, date_to?: Date, bucket_id?: string) {
    super();
    this.job_id = job_id;
    this.bucket_id = bucket_id;
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    this.date_from = date_from ? date_from.toISOString() : undefined;
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ...
    this.date_to = date_to ? date_to.toISOString() : undefined;
  }
}
