/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { TDPUnifiedContext } from './DPUnifiedContext.type';

export const DPUnifiedContext = createContext<TDPUnifiedContext>({
  tableSlice: {
    isDPLoading: false,
    DPRows: []
  },
  jobsSlice: {
    jobIds: [],
    jobInfos: [],
    isJobInfosLoading: false
  },
  filtersSlice: {
    activeSelectedFilter: 'all',
    setActiveSelectedFilter: () => {}
  },
  handleCheckboxChange: () => {},
  handleUpdateDP: () => {},
  isUpdateLoading: false,
  isUpdateDisabled: false
});
