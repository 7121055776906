import { JsonObject, JsonProperty } from 'json2typescript';
import { CommonRequest } from '../common/CommonRequest';

@JsonObject('FeedbackBucketDetailsRequest')
export class FeedbackBucketDetailsRequest extends CommonRequest {
  @JsonProperty('job_id', Number)
  job_id?: number;

  constructor(jobId: number) {
    super();
    this.job_id = jobId;
  }
}
