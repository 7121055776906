import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useQuery } from 'react-query';
import { PipelineResponse } from '../../../components/shared/models';
import { CommonRequest } from '../../../components/shared/models/common/CommonRequest';
import { getJsonConvert } from '../../../components/shared/utilities/json-convert';
import { config } from '../../../config';

export function usePipeline(jobId: number | null = null) {
  const jsonConvert = React.useMemo(() => getJsonConvert(), []);
  const replaceString = jobId !== null ? `/${jobId}` : '';
  const apiUrl = config.api.overallStatisticsPipeline.replace('/:jobId', replaceString.toString());

  return useQuery([apiUrl], () =>
    axios
      .post<{ resource: PipelineResponse }>(apiUrl, new CommonRequest())
      .then((responseData: AxiosResponse<{ resource: PipelineResponse }>) =>
        jsonConvert.deserializeObject(responseData.data.resource, PipelineResponse)
      )
  );
}
