import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('Action')
export class Action {
  @JsonProperty('id', String)
  id: string;

  @JsonProperty('component', String)
  component: string;

  @JsonProperty('component_id', Number)
  component_id: number;

  @JsonProperty('category_name', String)
  category_name: string;

  @JsonProperty('category_description', String)
  category_description: string;

  @JsonProperty('title', String)
  title: string;

  @JsonProperty('details', String)
  details: string;

  @JsonProperty('created_at', String)
  created_at: string;

  @JsonProperty('active', Boolean)
  active: boolean;

  @JsonProperty('component_title', String)
  componentTitle?: string = undefined;
}
