import { useState, useEffect, useCallback } from 'react';
import { Map, Circle, Polyline, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { List } from 'antd';
import L, { LatLngTuple } from 'leaflet';
import 'leaflet.heat';

import GeoMapLegend from './GeoMapLegend';
import { config } from '../../../../config';

export interface Category {
  name: string;
  color: string;
  positions: Array<Array<number>>;
  heatmaps?: Array<[number, number, number]>;
  lines?: Array<Array<LatLngTuple>>;
}

export interface GeoMapProps {
  categories: Array<Category>;
  // eslint-disable-next-line
  style: any;
}

function randomKey(position: number[]) {
  return `${position[0]},${position[1]}:${Math.random().toString(36).substring(7)}`;
}

export function GeoMap(props: GeoMapProps) {
  const [categories, setCategories] = useState<Array<Category>>();
  const [mapRef, setMapRef] = useState<Map>();

  const addHeatmap = useCallback(() => {
    if (mapRef != null) {
      if (props.categories != null) {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        categories.forEach((category: Category) => {
          if (category.heatmaps != null && category.heatmaps.length > 0) {
            L.heatLayer(category.heatmaps, {
              radius: 25,
              minOpacity: 0.2
            }).addTo(mapRef.leafletElement);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [mapRef]);

  const openStreetMapServiceDefined = useCallback(() => {
    const isUndefined = config.openStreetMapService == null || config.openStreetMapService.length === 0;
    return !isUndefined;
  }, []);

  useEffect(() => {
    setCategories(props.categories);
  }, [props.categories]);

  if (categories != null) {
    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'LatLngBound...
    let bounds: L.LatLngBounds = null;

    categories.forEach((category) => {
      category.positions.forEach((position) => {
        if (bounds == null) {
          const coords: [number, number] = [position[0], position[1]];
          bounds = L.latLngBounds(coords, coords);
        } else {
          bounds = bounds.extend([position[0], position[1]]);
        }
      });
    });

    if (bounds == null) {
      // By default Europe Bounds
      bounds = L.latLngBounds([50.505, -29.09], [52.505, 29.09]);
    }

    if (props.categories != null) {
      const style = {
        height: '315px'
      };

      if (props.style.height) {
        style.height = props.style.height;
      }

      addHeatmap();

      return (
        <>
          <div style={style}>
            <Map
              style={style}
              bounds={bounds}
              attributionControl={false}
              scrollWheelZoom={false}
              ref={(ref: Map) => {
                setMapRef(ref);
              }}
            >
              <GeoMapLegend>
                <List style={{ backgroundColor: '#000', opacity: 0.75 }} bordered>
                  {categories.map((category) => (
                    <List.Item key={category.color}>
                      <i style={{ backgroundColor: category.color }}>&nbsp;&nbsp;&nbsp;&nbsp;</i>
                      &nbsp;&nbsp;
                      {category.name}
                    </List.Item>
                  ))}
                </List>
              </GeoMapLegend>

              {openStreetMapServiceDefined() && <TileLayer url={config.openStreetMapService} />}
              {!openStreetMapServiceDefined() && <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />}
              <List>
                {categories.map((category) => {
                  if (category.positions.length > 0) {
                    return category.positions.map((position) => (
                      <Circle key={randomKey(position)} center={[position[0], position[1]]} color={category.color} radius={10} />
                    ));
                  }

                  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                  if (category.lines.length > 0) {
                    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                    return category.lines.map((line) => (
                      <Polyline key={randomKey([line[0][0], line[0][1]])} positions={line} color={category.color} opacity={0.2} />
                    ));
                  }

                  return <span key={randomKey([0, 0])} />;
                })}
              </List>
            </Map>
          </div>
        </>
      );
    }
  }

  return <></>;
}
