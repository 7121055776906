import { FeatureOccurence } from '../../../../../../../../shared/models/retrain-history/JobMetricsResponse';
import { Occurence } from './Occurence';

type OccurencesListProps = {
  occurences: FeatureOccurence[];
  displayAllOccurences: boolean;
};
export default function OccurencesList({ occurences, displayAllOccurences }: OccurencesListProps) {
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const totalUsedCount = occurences.reduce((sum, curr) => sum + curr.usedCount, 0);
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  const sortedOccurences = occurences.slice(0).sort((a, b) => b.usedCount - a.usedCount);

  return (
    <div>
      {(displayAllOccurences ? sortedOccurences : sortedOccurences.slice(0, 5)).map((occurence: FeatureOccurence) => (
        <Occurence key={occurence.value} occurence={occurence} totalUsedCount={totalUsedCount} />
      ))}
    </div>
  );
}
