export default function CaretUp() {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        /* eslint-disable-next-line max-len */
        d="M6.38926 4.17646L8.87097 7.01315C9.16385 7.34691 8.92582 7.87109 8.48133 7.87109H3.5179C3.41842 7.87118 3.32103 7.84259 3.23738 7.78875C3.15373 7.7349 3.08738 7.65809 3.04626 7.56751C3.00515 7.47692 2.99102 7.37641 3.00556 7.278C3.0201 7.17959 3.0627 7.08746 3.12826 7.01264L5.60997 4.17698C5.65854 4.1214 5.71844 4.07686 5.78564 4.04634C5.85285 4.01581 5.9258 4.00002 5.99961 4.00002C6.07342 4.00002 6.14638 4.01581 6.21359 4.04634C6.28079 4.07686 6.34069 4.1214 6.38926 4.17698V4.17646Z"
        fill="currentColor"
      />
    </svg>
  );
}
