import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CodeBlockLimitsRecord')
export class CodeBlockLimitsRecord {
  @JsonProperty('cpu_time_limit_seconds', Number)
  cpuTimeSeconds: number;

  @JsonProperty('memory_limit_mb', Number)
  memoryLimitMB: number;
}

type TConstructorParams = {
  cpuTimeSeconds: CodeBlockLimitsRecord['cpuTimeSeconds'];
  memoryLimitMB: CodeBlockLimitsRecord['memoryLimitMB'];
};

export function constructCodeBlockLimitsRecord({ cpuTimeSeconds, memoryLimitMB }: TConstructorParams): CodeBlockLimitsRecord {
  const instance = new CodeBlockLimitsRecord();
  instance.cpuTimeSeconds = cpuTimeSeconds;
  instance.memoryLimitMB = memoryLimitMB;

  return instance;
}
