import { EIcon, Icon, Spinner } from '@arcanna/generic';
import { Button, ButtonProps } from '@mui/material';
import { Modal, ModalProps, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type TAlertDialogProps = Omit<ModalProps, 'children'> & {
  title?: string;
  onClose: () => void;
  handleSubmit: () => void;
  submitButtonProps?: Omit<ButtonProps, 'onClick'>;
  modalBody?: string;
  isLoading?: boolean;
};

function AlertDialog({
  title,
  onClose,
  handleSubmit,
  submitButtonProps,
  modalBody,
  isLoading,
  ...modalProps
}: TAlertDialogProps) {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  return (
    <Modal
      {...modalProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...modalProps.sx
      }}
    >
      <Stack
        sx={{
          width: '420px',
          border: `1px solid ${theme.palette.secondary[700]}`,
          backgroundColor: theme.palette.secondary[900],
          borderRadius: 1
        }}
        data-test-id={'alert-dialog'}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ borderBottom: `1px solid ${theme.palette.secondary[700]}`, padding: '12px' }}
        >
          <Icon name={EIcon.Warning} fontSize="large" />
          <Typography variant="h4">{title ?? t('common:holdUp')}</Typography>
        </Stack>
        <Stack sx={{ borderBottom: `1px solid ${theme.palette.secondary[700]}`, padding: '24px 12px', minHeight: '80px' }}>
          <Typography variant="body2" color={theme.palette.secondary[300]}>
            {modalBody ?? t('common:confirmDelete')}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" padding="12px" gap={2}>
          <Button size="small" variant="contained" color="secondary" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button
            size="small"
            variant="contained"
            color={isLoading ? 'secondary' : 'error'}
            endIcon={isLoading ? <Spinner /> : <Icon name={EIcon.Delete} />}
            onClick={handleSubmit}
            disabled={isLoading}
            {...submitButtonProps}
          >
            {t('common:delete')}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}

export default AlertDialog;
