import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { ReactNode } from 'react';
import {
  EFilterOperatorWithValue,
  EFilterOperatorWithoutValue
} from 'src/_srcMUI/shared/components/Filters/AdvancedFilters/AdvancedFilters.types';
import { Tooltip } from '@mui/material';

type ArcannaTableCellFilterButtonProps = {
  operator: EFilterOperatorWithValue | EFilterOperatorWithoutValue;
  icon: ReactNode;
  onClick: (operator: EFilterOperatorWithValue | EFilterOperatorWithoutValue) => void;
};
export function ArcannaTableCellFilterButton({ operator, icon, onClick }: ArcannaTableCellFilterButtonProps) {
  const { t } = useTranslation(['common']);

  return (
    <Tooltip
      title={t(`common:tableCellFilters_${operator.split(' ').join('_')}`, { defaultValue: operator })}
      placement="top"
      arrow
    >
      <button
        type="button"
        className={styles.btn}
        onClick={(e) => {
          e.stopPropagation();
          onClick(operator);
        }}
      >
        {icon}
      </button>
    </Tooltip>
  );
}
