import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('FieldInsightsTopValue')
export class FieldInsightsTopValue {
  @JsonProperty('value', String)
  value: string;

  @JsonProperty('count', Number)
  count: number;

  @JsonProperty('percent', Number)
  percent: number;
}
