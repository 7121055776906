export enum JobStateEnum {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  READY_TO_SELECT_FEATURES = 'READY_TO_SELECT_FEATURES',
  COLLECTING_DATA = 'COLLECTING_DATA',
  ROLLING_BACK = 'ROLLING_BACK',
  CLONING = 'CLONING',
  STARTED = 'STARTED'
}
