export default function CloseIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2642_34367)">
        <rect width="20" height="20" rx="10" fill="#20273B" />
        <path d="M13 7L7 13" stroke="#8592A2" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 7L13 13" stroke="#8592A2" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#2A3247" />
      <defs>
        <clipPath id="clip0_2642_34367">
          <rect width="20" height="20" rx="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
