import axios, { AxiosError, AxiosResponse } from 'axios';
import { CommonResponse } from '../components/shared/models/common/CommonResponse';
import * as AuthConstants from '../components/pages/Main/Login/AuthConstants';
import { config } from '../config';
import { useRotateSecondaryUrls } from '../components/shared/hooks/useRotateSecondaryUrls';

const INVALID_SESSION = 'Invalid session';

// eslint-disable-next-line
const rotateUrlsService = useRotateSecondaryUrls();

// eslint-disable-next-line
(axios.defaults as any).baseURL = config.backendUrl;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = config.backendUrl;
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';
axios.defaults.headers.common.xsrfCookieName = 'xsrfCookieName';
axios.defaults.headers.common['X-Rest-Api-Token'] = config.restApiToken;

const axiosInstanceForHealthCheck = axios.create();

export function logout() {
  localStorage.removeItem(AuthConstants.LOCAL_STORAGE_TOKEN_NAME);
  window.location.assign('/login');
}

export async function rotateAndLogoutOnServerDown() {
  const backendUrl = await rotateUrlsService.rotateBackendUrl();
  if (backendUrl != null) {
    // The backend url was changed, force a logout as the
    // session is not persistent from one server instance to another.
    logout();
  }
}

axios.interceptors.response.use(
  (response: AxiosResponse<{ resource: { request: CommonResponse } }>) => {
    const reason = response.data?.resource?.request?.reason;
    if (reason != null && reason.indexOf(INVALID_SESSION) >= 0) {
      logout();
    }
    return response;
  },
  async (error: AxiosError) => {
    if (!error.response) {
      try {
        await axiosInstanceForHealthCheck.get(config.backendUrl);
        // Case when the backend is up but the request failed because of long response time
        return Promise.reject(error);
      } catch {
        // Case when the backend is down
        await rotateAndLogoutOnServerDown();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error.response);
  }
);
