/* eslint-disable max-len */
function Variable() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M13.2706 14C14.1642 12.1926 14.6666 10.1553 14.6666 8C14.6666 5.84474 14.1642 3.80744 13.2706 2M2.7292 2C1.83567 3.80744 1.33325 5.84474 1.33325 8C1.33325 10.1553 1.83567 12.1926 2.7292 14M11.0323 5.75H10.9726C10.537 5.75 10.1231 5.94134 9.83962 6.27381L6.25639 10.4762C5.97291 10.8087 5.55906 11 5.12345 11H5.06367M5.8098 5.75H6.7392C7.07231 5.75 7.36506 5.97198 7.45658 6.29396L8.63953 10.456C8.73105 10.778 9.0238 11 9.35691 11H10.2863"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Variable;
