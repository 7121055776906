import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { config } from 'src/config';
import style from '../../../../pages/Main/Jobs/Navigation/JobNavigation.module.css';

type UnifiedFeedbackNavItemProps = {
  jobIds: string;
};
export default function UnifiedFeedbackNavItem({ jobIds }: UnifiedFeedbackNavItemProps) {
  const { t } = useTranslation();

  return (
    <NavLink
      to={config.routes.feedbackUnified.replace(':ids', jobIds)}
      className={cx([style.navLink, 'callout-default'])}
      activeClassName={cx([style.activeNavLink, 'callout-default'])}
    >
      {t('common:feedback')}
    </NavLink>
  );
}
