/* eslint-disable max-len */
function Pin() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.00012 10L8.00012 14.6667M5.33346 4.87208V6.2925C5.33346 6.43117 5.33346 6.50051 5.31985 6.56684C5.30777 6.62568 5.2878 6.68262 5.26047 6.73611C5.22966 6.7964 5.18635 6.85055 5.09972 6.95884L4.0532 8.26699C3.60937 8.82177 3.38746 9.09916 3.38721 9.33261C3.38699 9.53564 3.4793 9.7277 3.63797 9.85436C3.82042 10 4.17566 10 4.88612 10H11.1141C11.8246 10 12.1798 10 12.3623 9.85436C12.5209 9.7277 12.6133 9.53564 12.613 9.33261C12.6128 9.09916 12.3909 8.82177 11.947 8.26699L10.9005 6.95884C10.8139 6.85055 10.7706 6.7964 10.7398 6.73611C10.7125 6.68262 10.6925 6.62568 10.6804 6.56684C10.6668 6.50051 10.6668 6.43117 10.6668 6.2925V4.87208C10.6668 4.79533 10.6668 4.75696 10.6711 4.71911C10.675 4.68549 10.6814 4.65222 10.6903 4.61957C10.7003 4.58282 10.7146 4.54719 10.7431 4.47593L11.415 2.79615C11.611 2.30609 11.709 2.06106 11.6682 1.86436C11.6324 1.69235 11.5302 1.5414 11.3838 1.44433C11.2163 1.33333 10.9524 1.33333 10.4246 1.33333H5.57563C5.04782 1.33333 4.78391 1.33333 4.61646 1.44433C4.47003 1.5414 4.36783 1.69235 4.33209 1.86436C4.29122 2.06106 4.38923 2.30609 4.58525 2.79615L5.25717 4.47593C5.28567 4.54719 5.29992 4.58282 5.30995 4.61957C5.31886 4.65222 5.32526 4.68549 5.32912 4.71911C5.33346 4.75696 5.33346 4.79533 5.33346 4.87208Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Pin;
